import { ChatbotLastQuestion } from '@components/Chatbot/types'
import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useEffect, useRef, useState } from 'react'

const useChatbotLastQuestion = () => {
  const queryClient = useQueryClient()
  const queryCache = queryClient.getQueryCache()
  const unsubscribeFnRef = useRef<() => void>()

  const [chatbotLastQuestion, setChatbotLastQuestion] = useState(
    queryClient.getQueryData<ChatbotLastQuestion>(['chatbotLastQuestion'])
  )

  const updateChabotLastQuestion = useCallback(
    (args: any) => {
      if (
        args?.action?.type === 'invalidate' &&
        args.query.queryKey[0] === 'chatbotLastQuestion'
      ) {
        setChatbotLastQuestion(
          queryClient.getQueryData<ChatbotLastQuestion>(['chatbotLastQuestion'])
        )
      }
    },
    [queryClient]
  )

  useEffect(() => {
    setChatbotLastQuestion(
      queryClient.getQueryData<ChatbotLastQuestion>(['chatbotLastQuestion'])
    )

    unsubscribeFnRef.current = queryCache.subscribe(updateChabotLastQuestion)

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [queryCache, updateChabotLastQuestion, queryClient])

  return chatbotLastQuestion
}

export default useChatbotLastQuestion
