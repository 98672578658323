import { RefObject, useCallback, useEffect, useState } from 'react'

export type UseMouseHoverType = ({
  elementRef,
  track,
}: {
  elementRef: RefObject<HTMLElement>
  track?: boolean
}) => boolean

const useMouseHover: UseMouseHoverType = ({ elementRef, track = true }) => {
  const [isHovered, setIsHovered] = useState<boolean>(false)

  const onMouseEnter = useCallback(() => {
    setIsHovered(true)
  }, [])

  const onMouseLeave = useCallback(() => {
    setIsHovered(false)
  }, [])

  useEffect(() => {
    const hoverElement = elementRef.current

    if (track && hoverElement) {
      hoverElement.addEventListener('mouseenter', onMouseEnter)
      hoverElement.addEventListener('mouseleave', onMouseLeave)
    }
    return () => {
      if (track && hoverElement) {
        hoverElement.removeEventListener('mouseenter', onMouseEnter)
        hoverElement.removeEventListener('mouseleave', onMouseLeave)
      }
    }
  }, [elementRef, onMouseEnter, onMouseLeave, track])

  return isHovered
}

export default useMouseHover
