import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import {
  CookWidget,
  CookWidgetJSON,
  JSONTypeForCookWidget,
} from '@widgets/types'
import JSONRenderer from '@components/JSONRenderer'

export interface ArticleSidebarContentAPIProps {
  content?: CookWidgetJSON[]
}

const StyledArticleSidebarContent = styled.div`
  ${({
    theme: {
      spacing: { spacing20 },
    },
  }) => css`
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-row-gap: ${spacing20};
  `}
`

const ArticleSidebarContent: FunctionComponent<
  ArticleSidebarContentAPIProps
> = (props) => (
  <StyledArticleSidebarContent>
    <JSONRenderer>{props.content}</JSONRenderer>
  </StyledArticleSidebarContent>
)

export { StyledArticleSidebarContent }

const widget = {
  kind: ['article-sidebar-content'],
  component: ArticleSidebarContent,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
