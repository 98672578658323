import {
  LiveEventDataHockey,
  LiveEventDataHockeyStatus,
  LiveEventDataHockeyTeam,
  LiveEventGoals,
} from './liveEvent'
import {
  LIVE_EVENT_DATA_HOCKEY_MATCH_STATUS,
  LIVE_EVENT_DATA_STATUS_TYPES,
  LIVE_EVENT_DATA_TEAM_QUALIFIER_TYPES,
  formatScheduledAt,
  formatScheduledAtShort,
} from '@utils/formatters/common'
import translate from '@i18n'

export interface LiveEventTransformedHockeyTeam {
  abbreviation: string
  logoUrl: string
  name: string
  score: number
}

export function formatPeriods(
  periods: LiveEventDataHockey['sport_event_status']['regular_periods']
) {
  if (periods?.length > 0) {
    return periods.reduce((previousValue, currentValue, currentIndex) => {
      const periodScore = `${previousValue}${currentValue.home_score}:${currentValue.away_score}`

      if (currentIndex !== periods.length - 1) {
        return `${periodScore} | `
      }

      return periodScore
    }, '')
  }

  return ''
}

export function formatTeam(status: LiveEventDataHockeyStatus) {
  return ({
    qualifier,
    abbreviation,
    sdpTeamLogoOptimized,
    name,
  }: LiveEventDataHockeyTeam): LiveEventTransformedHockeyTeam => {
    let score = 0

    if (qualifier === LIVE_EVENT_DATA_TEAM_QUALIFIER_TYPES.HOME) {
      score = status.home_score ?? 0
    } else if (qualifier === LIVE_EVENT_DATA_TEAM_QUALIFIER_TYPES.AWAY) {
      score = status.away_score ?? 0
    }

    return {
      abbreviation,
      logoUrl: sdpTeamLogoOptimized,
      name,
      score,
    }
  }
}

export interface LiveEventTransformedHockey {
  extraTimeIndicator?: string
  isFinished: boolean
  isLive: boolean
  isScheduled: boolean
  goalsInfo?: LiveEventGoals
  periods: string
  scoreExplanation: string
  sdpScheduledAtShort: string
  statusTitle: string
  subStatusTitle: string
  tournamentName: string
  teams: LiveEventTransformedHockeyTeam[]
}

export function transformMatch(
  match: LiveEventDataHockey
): LiveEventTransformedHockey {
  const { competitors } = match.sport_event
  const { match_status, overtime_period, regular_periods, score_explanation } =
    match.sport_event_status

  const isEnded = match.sdpStatus === LIVE_EVENT_DATA_STATUS_TYPES.FINISHED
  const isLive = match.sdpIsLive || false
  const isScheduled = !isLive && !isEnded
  const statusTitle = isLive
    ? translate('liveEvent.live')
    : isEnded
      ? ''
      : formatScheduledAt(match.sdpScheduledAt)

  let subStatusTitle
  switch (match_status) {
    case LIVE_EVENT_DATA_HOCKEY_MATCH_STATUS.OVERTIME:
      subStatusTitle = translate('matchStatus.overtime')
      break
    case LIVE_EVENT_DATA_HOCKEY_MATCH_STATUS.PENALTIES:
      subStatusTitle = translate('matchStatus.penaltiesRunning')
      break
    case LIVE_EVENT_DATA_HOCKEY_MATCH_STATUS.AET:
      subStatusTitle = translate('matchStatus.afterExtraTime')
      break
    case LIVE_EVENT_DATA_HOCKEY_MATCH_STATUS.AP:
      subStatusTitle = translate('matchStatus.afterPenalty')
      break
    default:
      subStatusTitle = isLive
        ? translate('liveEvent.live')
        : isEnded
          ? translate('matchStatus.ended')
          : formatScheduledAt(match.sdpScheduledAt)
  }

  return JSON.parse(
    JSON.stringify({
      goalsInfo: match.goalsInfo,
      periods: formatPeriods(
        overtime_period
          ? regular_periods.concat(overtime_period)
          : regular_periods
      ),
      tournamentName: match.sdpTournamentName,
      scoreExplanation: score_explanation,
      statusTitle,
      subStatusTitle,
      sdpScheduledAtShort: formatScheduledAtShort(match.sdpScheduledAt),
      isFinished: isEnded,
      isLive,
      isScheduled,
      teams: competitors
        ? competitors.map(formatTeam(match.sport_event_status))
        : [],
    })
  )
}

const transformLiveEvent = transformMatch

export { transformLiveEvent }
