import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'

export interface CueLiveProps {
  children: ReactNode
}

const StyledCueLive = styled.div`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    position: relative;
    padding-bottom: ${spacing16};
  `}
`

const CueLive: FunctionComponent<CueLiveProps> = ({ children }) => (
  <StyledCueLive>{children}</StyledCueLive>
)

export { StyledCueLive }

export default CueLive
