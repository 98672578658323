import {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import BlickBitesFastlaneContext from '@contexts/blickBitesFastlaneContext'
import { InPortal, createHtmlPortalNode } from 'react-reverse-portal'
import BlickBitesVideoPlayer from './BlickBitesVideoPlayer'
import { getJWPlayer } from '@utils/videoPlayer'

const BlickBitesFastlaneContextProvider: FunctionComponent<{
  children?: ReactNode
}> = ({ children }) => {
  const [videoId, setVideoId] = useState<string | undefined>(undefined)

  const [isPlayerReady, setIsPlayerReady] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [areCaptionsAvailable, setAreCaptionsAvailable] = useState(false)

  const [muted, setMuted] = useState(true)
  const [captionsEnabled, setCaptionsEnabled] = useState(true)
  const [isPaused, setIsPaused] = useState(false)

  const widgetId = `video-player-portal-node`

  const VideoPlayerPortalNode = useMemo(
    () =>
      createHtmlPortalNode({
        attributes: {
          class: 'video-player-portal-node',
          style: 'width: 100%; height: 100%;',
        },
      }),
    []
  )

  const onVideoPlayerInit = useCallback(() => {
    setIsPlayerReady(true)
    setIsLoading(true)
  }, [])

  const onVideoPlayerRemove = useCallback(() => {
    setIsPlayerReady(false)
    setIsLoading(false)
  }, [])

  const onPlayerError = useCallback(() => {
    setIsLoading(false)
    setAreCaptionsAvailable(false)
  }, [])

  const onPause = useCallback(() => {
    setIsPaused(true)
  }, [])

  useEffect(() => {
    const player = getJWPlayer(widgetId)

    if (!isPlayerReady || !player) {
      return
    }

    player.on('pause', onPause)

    return () => {
      player.off('pause', onPause)
    }
  }, [isPlayerReady, onPause, widgetId])

  const onPlay = useCallback(() => {
    setIsPaused(false)
  }, [])

  useEffect(() => {
    const player = getJWPlayer(widgetId)

    if (!isPlayerReady || !player) {
      return
    }

    player.on('play', onPlay)

    return () => {
      player.off('play', onPlay)
    }
  }, [isPlayerReady, onPlay, widgetId])

  const onPlayAttemptFailed = useCallback(() => {
    setIsPaused(false)
    setIsLoading(false)
  }, [])

  useEffect(() => {
    const player = getJWPlayer(widgetId)

    if (!isPlayerReady || !player) {
      return
    }

    player.on('playAttemptFailed', onPlayAttemptFailed)

    return () => {
      player.off('playAttemptFailed', onPlayAttemptFailed)
    }
  }, [isPlayerReady, onPause, onPlayAttemptFailed, widgetId])

  const onFirstFrame = useCallback(() => {
    const player = getJWPlayer(widgetId)

    if (!player || !isPlayerReady) {
      return
    }

    setIsLoading(false)
    setAreCaptionsAvailable(player.getCaptionsList().length > 1)
  }, [isPlayerReady, widgetId])

  useEffect(() => {
    const player = getJWPlayer(widgetId)

    if (!isPlayerReady || !player) {
      return
    }

    player.on('firstFrame', onFirstFrame)

    return () => {
      player.off('firstFrame', onFirstFrame)
    }
  }, [isPlayerReady, onFirstFrame, widgetId])

  const loadVideo = useCallback(
    (newVideoId: string) => {
      if (newVideoId !== videoId && !isLoading) {
        setAreCaptionsAvailable(false)
        setIsLoading(true)
        setVideoId(newVideoId)
      }
    },
    [isLoading, videoId]
  )

  return (
    <BlickBitesFastlaneContext.Provider
      value={{
        widgetId,
        videoId,
        isPlayerReady,
        isLoading,
        areCaptionsAvailable,
        muted,
        captionsEnabled,
        isPaused,
        VideoPlayerPortalNode,
        setMuted,
        setCaptionsEnabled,
        loadVideo,
      }}>
      {children}
      {VideoPlayerPortalNode && (
        <InPortal node={VideoPlayerPortalNode}>
          <BlickBitesVideoPlayer
            widgetId={widgetId}
            videoId={videoId}
            onInit={onVideoPlayerInit}
            onRemove={onVideoPlayerRemove}
            onError={onPlayerError}
          />
        </InPortal>
      )}
    </BlickBitesFastlaneContext.Provider>
  )
}

export default BlickBitesFastlaneContextProvider
