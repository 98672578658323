import { dayjs } from '@utils/date'
import config from '@config'
import { PlayerComponent } from '@widgets/Video/types'
import { Chapter, TimelinePointer } from './types'

const {
  video: {
    chapters: { dvrWindow },
  },
} = config

const diffFromPointer = (
  pointerTimestamp: string,
  chapterTimestamp: string
): number => {
  return (
    dayjs(pointerTimestamp).diff(dayjs(chapterTimestamp), 'millisecond') / 1000
  )
}

/**
 * Resolves the closest pointer based on the player's current playing time
 * @param pointers
 * @param currentTime
 */
const findClosestTimelinePointerByCurrentTime = (
  pointers: TimelinePointer[],
  currentTime: number
): TimelinePointer => {
  return pointers.reduce((best, curr) => {
    return Math.abs(currentTime - curr.timelineSecond) <
      Math.abs(currentTime - best.timelineSecond)
      ? curr
      : best
  })
}

/**
 * Resolves the closest pointer available based on the chapter's timeString
 * @param pointers
 * @param chapterTimeString
 */
const findClosestTimelinePointerByChapterTimeString = (
  pointers: TimelinePointer[],
  chapterTimeString: string
): TimelinePointer => {
  return pointers.reduce((best, curr) => {
    return Math.abs(diffFromPointer(curr.timeString, chapterTimeString)) <
      Math.abs(diffFromPointer(best.timeString, chapterTimeString))
      ? curr
      : best
  })
}

/**
 * normalizes player current time to avoid seeking outside the live window
 * @param currentTime
 * @param player
 */
export const normalizePlayerCurrentTimeValue = (
  currentTime: number,
  player: PlayerComponent
): number => {
  // The furthest seekable end or Infinity.
  // @ts-ignore
  const seekableEnd = player?.getCurrentTime() + Math.abs(player?.getPosition())
  // The earliest seekable start or 0.
  // @ts-ignore
  const seekableStart = seekableEnd - Math.abs(player?.getDuration())

  return currentTime < seekableStart
    ? seekableStart
    : currentTime > seekableEnd
      ? seekableEnd
      : currentTime
}

/**
 * returns the new timeline position by calculating
 * the difference in seconds from the pointers we set onPlaySuccess
 * @param chapterTimeString
 * @param timelinePointers
 */
export const calculateChapterTimelinePosition = (
  chapterTimeString: string,
  timelinePointers: TimelinePointer[]
): number => {
  if (timelinePointers.length > 0) {
    const closestTimelinePointer =
      findClosestTimelinePointerByChapterTimeString(
        timelinePointers,
        chapterTimeString
      )

    const diff = diffFromPointer(
      closestTimelinePointer.timeString,
      chapterTimeString
    )

    return closestTimelinePointer.timelineSecond - diff
  }
  return 0
}

/**
 * converts the currentTime of the player to a dateTimeString
 * in order to calculate visually the transition to a new chapter
 * @param currentTime
 * @param timelinePointers
 */
export const convertPlayerCurrentTimeToTimestamp = (
  currentTime: number,
  timelinePointers: TimelinePointer[]
): string | null => {
  const closestTimelinePointer = findClosestTimelinePointerByCurrentTime(
    timelinePointers,
    currentTime
  )

  const diff = currentTime - closestTimelinePointer.timelineSecond

  return dayjs(closestTimelinePointer.timeString)
    .add(diff, 'second')
    .utc()
    .format()
}

const isBetween = (
  timestamp: string,
  startTimestamp: string,
  endTimestamp: string
): boolean => {
  return dayjs(timestamp)
    .add(1, 'second')
    .isBetween(startTimestamp, endTimestamp, 'seconds', '[)')
}

const oneMinInFuture = (): string => dayjs().add(1, 'minute').utc().format()

/**
 * checks if timeString is inside the live window
 * @param timeString
 * @param dvrWindowOffset
 */
const isInsideLiveWindow = (
  timeString: string,
  dvrWindowOffset = 0
): boolean => {
  return isBetween(
    timeString,
    dayjs()
      .subtract(dvrWindow + dvrWindowOffset, 'minutes')
      .utc()
      .format(),
    dayjs().format()
  )
}

export const findActiveChapter = (timestamp: string, chapters?: Chapter[]) => {
  return chapters?.find((chapter, index, chapters) =>
    isBetween(
      timestamp,
      chapter.timestamp,
      chapters[index + 1]?.timestamp || oneMinInFuture()
    )
  )
}

const removeObsoleteTimelinePointers = (
  timelinePointers: TimelinePointer[]
): TimelinePointer[] => {
  let wasFiltered = false

  const newTimelinePointers = timelinePointers.filter(
    (timelinePointer: TimelinePointer) => {
      const isPointerInsideLiveWindow = isInsideLiveWindow(
        timelinePointer.timeString,
        15
      )

      if (!wasFiltered && !isPointerInsideLiveWindow) {
        wasFiltered = true
      }

      return isPointerInsideLiveWindow
    }
  )
  // return previous array if it wasn't filtered (performance improvement)
  return wasFiltered ? newTimelinePointers : timelinePointers
}

export const newFilteredTimelinePointersArray = (
  oldArray: TimelinePointer[],
  timelinePointer: TimelinePointer
) => {
  if (oldArray[0]?.source === 'program-date-time') {
    return [timelinePointer]
  } else {
    return [...removeObsoleteTimelinePointers(oldArray), timelinePointer]
  }
}

export const getTimelinePointer = ({
  metadata,
  metadataTime,
}: any): TimelinePointer | undefined => {
  const privTimecode =
    metadata?.PRIV?.['com.elementaltechnologies.timestamp.utc']
  if (privTimecode) {
    return {
      timeString: new TextDecoder().decode(new Uint8Array(privTimecode)),
      timelineSecond: metadataTime,
      source: 'PRIV',
    }
  }
}
