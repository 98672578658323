import { desktopCSS } from '@measures/responsive'
import { css } from 'styled-components'

export const overlay = css`
  &.btv-fullscreen-overlay-enabled {
    &.btv-flag-fullscreen-overlay {
      .jw-fullscreen-disallowed {
        display: none;
      }

      .jw-video {
        // workaround to keep video aspect ratio in fullscreen
        // we need '!important' to overrride element 'style' property
        object-fit: contain !important;
      }
    }
  }
  ${desktopCSS(css`
    .jw-video {
      object-fit: contain !important;
    }
  `)}
`
