import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { desktopCSS } from '@measures/responsive'

export interface TitleProps {
  children: string
}

const StyledTitle = styled.div`
  ${({
    theme: {
      color: {
        primary: { primary02: primary02Color },
      },
      typography: {
        headings: {
          xxsmall: { bundledCSS: xxsmallHeadingsCSS },
          xsmall: { bundledCSS: xsmallHeadingCSS },
        },
      },
      utils: { ellipsis },
    },
  }) => css`
    position: relative;
    ${xxsmallHeadingsCSS};
    ${desktopCSS(css`
      ${xsmallHeadingCSS};
    `)};
    color: ${primary02Color};

    ${ellipsis('100%', 4)};
  `}
`

const Title: FunctionComponent<TitleProps> = ({ children }) => (
  <StyledTitle>{children}</StyledTitle>
)

export default Title
