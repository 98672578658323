import { useCallback } from 'react'

/**
 * Combines multiple refs into a single ref.
 */
const useRefDelegate = <T>(...refs: Array<(node: T | null) => void>) =>
  useCallback((node: T | null) => {
    refs.map((ref) => ref(node))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, refs)

export default useRefDelegate
