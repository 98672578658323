import { ConfigProps } from '@config/types'

const config = ({ staticAssetPageUrl, staticPageUrlSuffix }: ConfigProps) =>
  ({
    widgetUrl: `${staticAssetPageUrl}/usersnap${staticPageUrlSuffix}`,
    cuiAnswerWidgetKey: '61f70378-8237-4a4d-a51c-2e4cf3c7bcac',
    rateLimitLocalStorageKey: 'usersnapFeedbackCount',
    rateLimitPerMinute: 10,
  }) as const satisfies Record<string, unknown>

export default config
