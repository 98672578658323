import config from '@config'
import { AnimatedPreviewValue } from './types'

const {
  video: { videoMetadataUrl },
} = config

const getVideoPreviewURL = (videoId: string, width: 320 | 640 = 640): string =>
  videoId ? `${videoMetadataUrl}${videoId}/poster.mp4?width=${width}` : ''

const getNextCircularIndex = (arrayLength: number, index = 0): number =>
  (((index + 1) % arrayLength) + arrayLength) % arrayLength

const getNextCircularItem = <T = any>(array: T[], startIndex = 0): T | null =>
  array.length ? array[getNextCircularIndex(array.length, startIndex)] : null

const isTheSameInstance = (
  animatedPreview1: AnimatedPreviewValue,
  animatedPreview2: AnimatedPreviewValue
): boolean =>
  animatedPreview1?.wrapperRef.current === animatedPreview2?.wrapperRef.current

export { getNextCircularItem, isTheSameInstance, getVideoPreviewURL }
