import { FunctionComponent } from 'react'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import config from '@config'
import EmbeddedContent from '@components/EmbeddedContent'
import { VimeoProps } from '@widgets/Video/Vimeo/types'
import styled from 'styled-components'
import CMPPlaceholder from '@components/CMPPlaceholder'
import useCMPCookieCategories from '@hooks/useCMPCookieCategories'

const {
  vimeo: { staticPageUrl },
} = config

const StyledVimeoContainer = styled.div`
  position: relative;
  margin-top: 16px;
  margin-bottom: 16px;
`

const StyledVimeoMainElementWrapper = styled.div`
  padding-top: 56.25%;
  width: 100%;
  position: relative;
  overflow: hidden;
`

const StyledVimeoMainElement = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const StyledEmbeddedContent = styled(EmbeddedContent)`
  height: 100% !important;
`

const Vimeo: FunctionComponent<VimeoProps> = ({ videoId }) => (
  <StyledVimeoContainer>
    <StyledVimeoMainElementWrapper>
      <StyledVimeoMainElement>
        {useCMPCookieCategories('allCategoriesEnabledChanged') ? (
          <StyledEmbeddedContent
            url={`${staticPageUrl}${encodeURIComponent(videoId)}`}
          />
        ) : (
          <CMPPlaceholder />
        )}
      </StyledVimeoMainElement>
    </StyledVimeoMainElementWrapper>
  </StyledVimeoContainer>
)

const widget = {
  kind: ['widget', 'video', 'vimeo'],
  component: Vimeo,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
