import { PageMetadata } from '@hooks/usePageMetadata'

const getPageViewType = (metadata: PageMetadata) => {
  const { url, type } = metadata
  const publicationIndependentUrl = url?.startsWith?.('/fr/')
    ? url.substring(3)
    : url
  switch (publicationIndependentUrl) {
    case '/':
      return 'home'
    case '/dossiers/':
      return 'dossier'
    default:
      return type
  }
}

export { getPageViewType }
