import { SearchMessageProps } from '@widgets/Search/types'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { mobileCSS } from '@measures/responsive'

const Wrapper = styled.div`
  margin: 0 auto;
`

const Text = styled.div`
  ${({
    theme: {
      typography: {
        headings: {
          large: { bundledCSS: largeHeadingCSS },
          medium: { bundledCSS: mediumHeadingCSS },
        },
      },
      color: {
        tertiary: { grey900 },
      },
      spacing: { spacing24, spacing40 },
    },
  }) => css`
    text-align: center;
    margin-top: ${spacing24};
    margin-bottom: ${spacing40};
    ${largeHeadingCSS}
    ${mobileCSS(mediumHeadingCSS)}
    color: ${grey900};
  `}
`

const SearchMessage: FunctionComponent<SearchMessageProps> = ({
  children,
  className,
}) => (
  <Wrapper className={className}>
    <Text>{children}</Text>
  </Wrapper>
)

export default SearchMessage
