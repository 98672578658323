import { QueryClient } from '@tanstack/react-query'

type TriggerAureusImpression = (args: {
  aureusOfferId: string | undefined
  queryClient: QueryClient
}) => void

const triggerAureusImpression: TriggerAureusImpression = ({
  aureusOfferId,
  queryClient,
}) => {
  if (!aureusOfferId) {
    return
  }

  //! We should only track one impression per offerId (per Page)
  //! Resetting the impressions so it triggers on a new page happens
  //! in getServerSideProps().
  if (
    (queryClient.getQueryData<Record<string, unknown>>([
      'aureusOfferTracked',
    ]) ?? {})[aureusOfferId]
  ) {
    return
  }

  queryClient.setQueryData(['aureusOfferTracked'], {
    ...(queryClient.getQueryData<Record<string, unknown>>([
      'aureusOfferTracked',
    ]) ?? {}),
    [aureusOfferId]: true,
  })

  window.dlApi.cmd.push((dlApi: any) => {
    dlApi.registerAureusOffers([aureusOfferId])
  })
}

export { triggerAureusImpression }
