import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { desktopCSS } from '@measures/responsive'
import { TeaserToiCommercialAPIProps } from '@widgets/TeaserToiCommercial'

interface BulletPointsProps {
  accentColor: string
  children: Required<TeaserToiCommercialAPIProps>['bulletpoints']
  className?: string
}

type StyledBulletPointProps = Pick<BulletPointsProps, 'accentColor'>

const StyledBulletPoints = styled.ul`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => css`
    position: relative;
    padding: 0;
    margin: 0;
    list-style: none;
    appearance: none;
    display: grid;
    grid-row-gap: ${spacing8};
    align-items: flex-start;
  `}
`

const StyledBulletPoint = styled.li<StyledBulletPointProps>`
  ${({
    theme: {
      spacing: { spacing4 },
      color: {
        tertiary: { grey100: grey100Color },
      },
      typography: {
        subheads: {
          medium2: {
            bundledCSS: medium2SubheadingCSS,
            fontSize: medium2FontSize,
            lineHeight: medium2LineHeight,
          },
          large2: {
            bundledCSS: large2SubheadingCSS,
            fontSize: large2FontSize,
            lineHeight: large2LineHeight,
          },
        },
      },
    },
    accentColor,
  }) => css`
    appearance: none;
    display: flex;
    column-gap: ${spacing4};
    ${medium2SubheadingCSS};
    ${desktopCSS(css`
      ${large2SubheadingCSS};
    `)};
    color: ${grey100Color};

    &:before {
      transform: translateY(
        calc((${medium2FontSize} * ${medium2LineHeight} - 4px) / 2)
      );
      content: '';
      display: inline-block;
      flex: none;
      width: 4px;
      height: 4px;
      background-color: ${accentColor};
      border-radius: 50%;
      ${desktopCSS(css`
        width: 6px;
        height: 6px;
        transform: translateY(
          calc((${large2FontSize} * ${large2LineHeight} - 6px) / 2)
        );
      `)};
    }
  `}
`

const BulletPoints: FunctionComponent<BulletPointsProps> = ({
  accentColor,
  children,
  className,
}) => {
  return (
    <StyledBulletPoints className={className}>
      {children.map((itemText, index) => (
        <StyledBulletPoint key={index} accentColor={accentColor}>
          {itemText}
        </StyledBulletPoint>
      ))}
    </StyledBulletPoints>
  )
}

export default BulletPoints
