import Pagination from '@components/Pagination'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { JSONWidgetType as AdRingierJSONWidgetType } from '@widgets/AdRingier'
import { FunctionComponent, useMemo } from 'react'
import { ListItemTeaserAPIProps } from '@components/TeaserListItem/types'
import JSONRenderer from '@components/JSONRenderer'
import styled from 'styled-components'

const SectionPaginatedContentWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(6, minmax(0px, 1fr));
  grid-row-gap: 20px;

  > * {
    grid-column: span 6;
  }
`

export type SectionPaginatedContentWidgetProps = {
  activePage: number
  ad1Index: number
  ad1: AdRingierJSONWidgetType
  items: ListItemTeaserAPIProps[]
  link: {
    href: string
    target: string
  }
  kind: string[]
  title: string
  totalPages: number
}

const SectionPaginatedContentWidget: FunctionComponent<
  SectionPaginatedContentWidgetProps
> = (props) => {
  const {
    totalPages,
    activePage,
    link: { href },
    items,
    ad1,
    ad1Index,
  } = props

  const itemsWithAds = useMemo(() => {
    const content: (ListItemTeaserAPIProps | AdRingierJSONWidgetType)[] = [
      ...items,
    ]

    content.splice(ad1Index, 0, ad1)

    return content
  }, [items, ad1, ad1Index])

  return (
    <SectionPaginatedContentWrapper>
      <JSONRenderer>{itemsWithAds}</JSONRenderer>
      <Pagination
        type="link"
        {...{
          totalPages,
          activePage,
          firstPageHref: href,
          paginationHref: href.endsWith('/') ? `${href}page` : `${href}/page`,
        }}
      />
    </SectionPaginatedContentWrapper>
  )
}

const widget = {
  kind: ['section-paginated-content'],
  component: SectionPaginatedContentWidget,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
