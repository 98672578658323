import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { desktopCSS } from '@measures/responsive'

export interface ArticleMainContentProps {
  children: ReactNode
}

const StyledArticleMainContent = styled.div`
  ${({
    theme: {
      spacing: { spacing16, spacing24 },
    },
  }) => css`
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-row-gap: ${spacing16};

    ${desktopCSS(css`
      grid-row-gap: ${spacing24};
    `)}
  `}
`

const ArticleMainContent: FunctionComponent<ArticleMainContentProps> = ({
  children,
}) => <StyledArticleMainContent>{children}</StyledArticleMainContent>

export { StyledArticleMainContent }

export default ArticleMainContent
