import { mobileCSS } from '@measures/responsive'
import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'

const StyledContainer = styled.div`
  ${({
    theme: {
      spacing: { spacing20, spacing4 },
    },
  }) => css`
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin: ${spacing20} 0;
    ${mobileCSS(css`
      margin: ${spacing4} 0;
    `)}
  `}
`

const Container: FunctionComponent<{ children?: ReactNode }> = ({
  children,
}) => <StyledContainer>{children}</StyledContainer>

export default Container
