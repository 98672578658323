import Blink from '@components/Blink'
import useTrackingUtils from '@components/Header/useTrackingUtils'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

interface SecondLevelLinkProps {
  children: string
  href: string
}

const StyledSecondLevelLink = styled(Blink)`
  ${({
    theme: {
      typography: {
        labels: {
          label2: { bundledCSS: label2LabelsCSS },
        },
      },
      color: {
        primary: { blickRed: blickRedColor },
      },
    },
  }) => css`
    ${label2LabelsCSS}
    color: ${blickRedColor};

    appearance: none;
    text-decoration: none;

    display: grid;
    grid-auto-flow: column;
    justify-items: start;
    align-items: center;
    overflow-x: scroll;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  `}
`

const SecondLevelLink: FunctionComponent<SecondLevelLinkProps> = ({
  href,
  children,
}) => {
  const { navbarClickTrackingFn } = useTrackingUtils({
    title: children,
    url: href,
  })
  return (
    <StyledSecondLevelLink
      href={href}
      ariaLabel={children}
      onClick={navbarClickTrackingFn}>
      {children}
    </StyledSecondLevelLink>
  )
}

export default SecondLevelLink
