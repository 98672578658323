import { ConfigProps } from '@config/types'

const config = ({
  subscriptionCheckerUrl,
  plusSubscriptionServiceUrl,
  baseUrl,
}: ConfigProps) =>
  ({
    subscriptionsUrl: `${subscriptionCheckerUrl}/api/v1/subscriptions`,
    conversionUrl: `${subscriptionCheckerUrl}/api/v1/conversion`,
    enableAutoRenewalUrl: `${plusSubscriptionServiceUrl}/subscription/autoRenew`,
    offerPagePath: '/blickplus/abos/',
    faqsLink:
      'https://faq.blick.ch/s/article/Wie-verschenke-ich-einen-Artikel?language=de',
    blickPlusSectionUrl: `${baseUrl}/blickplus/`,
    daysBeforeSubscriptionExpires: 5,
    internalAccessId: '999999',
    footerBannerClassname: 'pianoFixedDiv',
    paywallClassName: 'piano-paywall-container',
  }) as const satisfies Record<string, unknown>

export default config
