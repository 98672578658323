import {
  FunctionComponent,
  Fragment,
  useState,
  useContext,
  useCallback,
} from 'react'
import config from '@config'
import CommentingItemList from './CommentingItemList'
import CommentingWrapper from './CommentingWrapper'
import Comment from './Comment'
import CommentingInfoBox from './CommentingInfoBox'
import PostedComment from './PostedComment'
import CommentingActionPopup from './CommentingActionPopup'
import CommentingPromptWrapper from './CommentingPromptWrapper'
import CommentingPrompt from './CommentingPrompt'
import APIContext from '@contexts/commenting/apiContext'
import { Comment as CommentType } from './types'
import GenericContext from '@contexts/commenting/genericContext'
import AnswerApiContextProvider from '@contexts/commenting/answerApiContextProvider'
import { stripHtml } from '@hooks/useTracking/utils'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import usePageMetadata from '@hooks/usePageMetadata'
import BlickPlusInfoBox from './BlickPlusInfoBox'
import JSONRenderer from '@components/JSONRenderer'
import SectionHeader from '@components/SectionHeader'
import { useTheme } from 'styled-components'

const {
  community: { commentsPerPage },
} = config

const CommentingWidget: FunctionComponent = () => {
  const { comments, fetchNextPage, moreToShow, postedComment, postComment } =
    useContext(APIContext)
  const {
    articleId,
    postingEnabled,
    showPrompts,
    showBlickPlusInfoBox,
    title,
  } = useContext(GenericContext)
  const theme = useTheme()
  const [postingComment, setPostingComment] = useState(false)
  const { commentAd } = usePageMetadata()

  const articleClickMoreCommentHandler = useCallback<TrackingFnType>(
    ({ pageMetadata }) => ({
      event: 'article_click_more_comment',
      eventCategory: 'article',
      eventLabel: `${pageMetadata.id}:${stripHtml(pageMetadata.title)}`,
      eventAction: 'more_comment',
    }),
    []
  )

  const handleArticleClickMoreCommentTracking = useTracking(
    articleClickMoreCommentHandler
  )

  const hasComments = comments?.length > 0

  const onShowMore = () => {
    if (moreToShow) {
      handleArticleClickMoreCommentTracking()
      fetchNextPage()
    }
  }

  return (
    <CommentingWrapper>
      <SectionHeader title={title} accentColor={theme.color.primary.blickRed} />
      {showBlickPlusInfoBox && <BlickPlusInfoBox />}
      {!postingEnabled && !hasComments && <CommentingInfoBox />}
      {postedComment && <PostedComment comment={postedComment} />}
      {postingEnabled && showPrompts && !postingComment && !postedComment && (
        <CommentingPromptWrapper>
          <CommentingPrompt
            type="comment"
            onClick={() => setPostingComment(true)}
          />
        </CommentingPromptWrapper>
      )}
      {postingComment && (
        <CommentingActionPopup
          type="comment"
          actionType="reply"
          onConfirm={(textValue = '') => {
            setPostingComment(false)
            postComment({ body: textValue, articleId })
          }}
          onCancel={() => setPostingComment(false)}
        />
      )}
      {hasComments && (
        <CommentingItemList
          type="comment"
          moreToShow={moreToShow}
          onShowMore={onShowMore}
          rerenderKey={articleId}>
          {(comments as CommentType[])?.map((comment, index) => {
            const mappedCommentPage = Math.floor(index / commentsPerPage)
            const isFirstPage = mappedCommentPage === 0
            const isFirstCommentOnPage = index % commentsPerPage === 0

            return (
              <Fragment key={`comment-${comment.id}`}>
                <AnswerApiContextProvider answers={comment.answers}>
                  <Comment postingEnabled={postingEnabled} comment={comment} />
                </AnswerApiContextProvider>
                {!isFirstPage && isFirstCommentOnPage && (
                  <>
                    <JSONRenderer key={`comment-${comment.id}`}>
                      {{
                        kind: ['ad-ringier'],
                        ...commentAd,
                        containerId: `${index}`,
                      }}
                    </JSONRenderer>
                  </>
                )}
              </Fragment>
            )
          })}
        </CommentingItemList>
      )}
    </CommentingWrapper>
  )
}

export default CommentingWidget
