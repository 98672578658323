import styled, { css } from 'styled-components'
import { mobileCSS, tabletCSS, desktopCSS } from '@measures/responsive'
import NavigationArrowsComponent from '@components/Swiper/NavigationArrows'

const NavigationArrows = styled(NavigationArrowsComponent)<{
  amountOfTilesToShow: number
  itemsCount: number
}>`
  ${({ amountOfTilesToShow, itemsCount }) => css`
    ${itemsCount <= 1 &&
    mobileCSS(css`
      display: none;
    `)}
    ${itemsCount <= 3 &&
    tabletCSS(css`
      display: none;
    `)}
    ${itemsCount <= amountOfTilesToShow &&
    desktopCSS(css`
      display: none;
    `)}
  `}
`

export default NavigationArrows
