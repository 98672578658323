import { ComponentType } from 'react'
import * as Sentry from '@sentry/nextjs'

import { CookWidget } from '@widgets/types'
import * as RegistryWidgets from '@widgets/index'

export type RegistryComponent = ComponentType<any>

export type Registry = Record<string, RegistryComponent>

export type WidgetKind = readonly string[]

let registry: Registry = {}

const isValidKind = (kind: any): kind is WidgetKind =>
  !!kind?.every((item: unknown) => typeof item === 'string')

const findKind = (kind: WidgetKind): string | undefined => {
  if (kind.length === 0) {
    return
  }
  const registryKind = kind.join('.')

  if (registry[registryKind]) {
    return registryKind
  } else {
    return findKind(kind.slice(0, -1))
  }
}

const addToRegistry = (kind: unknown, component: RegistryComponent) => {
  if (!isValidKind(kind)) {
    console.error(
      `Kind ${JSON.stringify(kind)} provided during registration is invalid`
    )
    return
  }

  const registryKind = kind.join('.')

  registry[registryKind] = component
}

const initRegistry = () => {
  if (!registry || Object.keys(registry).length === 0) {
    registry = {}
    Object.values<CookWidget>(RegistryWidgets).forEach(
      ({ kind, component }) => {
        const arrayOfKinds = Array.isArray(kind[0]) ? kind : [kind]
        arrayOfKinds.forEach((oneOfTheKinds) => {
          addToRegistry(oneOfTheKinds, Sentry.withErrorBoundary(component, {}))
        })
      }
    )
  }
}

const getFromRegistry = (kind: unknown): RegistryComponent | undefined => {
  if (!isValidKind(kind)) {
    return
  }

  const initialKind = kind.join('.')
  const registryKind = findKind(kind)

  if (typeof registryKind === 'undefined') {
    return
  }

  if (registryKind !== initialKind) {
    registry[initialKind] = registry[registryKind]
  }

  return registry[initialKind]
}

export { getFromRegistry, initRegistry }
