import { useCallback, useEffect, useRef, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import usePageMetadata from './usePageMetadata'
import useBodyScroll from '@hooks/useBodyScroll'

type UseUserMenuVisibilityType = () => boolean

const useUserMenuVisibility: UseUserMenuVisibilityType = () => {
  const queryClient = useQueryClient()
  const queryCache = queryClient.getQueryCache()
  const unsubscribeFnRef = useRef<() => void>()
  const pageMetadata = usePageMetadata()
  const { lockBodyScroll, unlockBodyScroll } = useBodyScroll()

  const [userMenuVisible, setUserMenuVisible] = useState(
    () => !!queryClient.getQueryData<boolean>(['userMenuVisible'])
  )

  const updateUserMenuVisibility = useCallback(
    (args: any) => {
      if (
        ['invalidate'].includes(args?.action?.type) &&
        args.query.queryKey[0] === 'userMenuVisible'
      ) {
        setUserMenuVisible(
          !!queryClient.getQueryData<boolean>(['userMenuVisible'])
        )
      }
    },
    [queryClient]
  )

  useEffect(() => {
    if (userMenuVisible) {
      lockBodyScroll()
    } else {
      unlockBodyScroll()
    }
  }, [lockBodyScroll, unlockBodyScroll, userMenuVisible])

  useEffect(() => {
    setUserMenuVisible(!!queryClient.getQueryData<boolean>(['userMenuVisible']))
  }, [pageMetadata, queryClient])

  useEffect(() => {
    unsubscribeFnRef.current = queryCache.subscribe(updateUserMenuVisibility)

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [queryCache, updateUserMenuVisibility])

  return userMenuVisible
}

export default useUserMenuVisibility
