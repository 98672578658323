import { FunctionComponent } from 'react'
import EmbeddedContent from '@components/EmbeddedContent'
import config from '@config'

const {
  socialembeds: { twitterEmbedStaticPageUrl },
} = config

interface TwitterComponentProps {
  url: string
}

const TwitterComponent: FunctionComponent<TwitterComponentProps> = ({
  url,
}) => <EmbeddedContent url={`${twitterEmbedStaticPageUrl}${url}`} />

export default TwitterComponent
