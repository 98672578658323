import dynamic from 'next/dynamic'
import Footer from '../Footer'
import { Link } from '@utils/cook/types'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { EuromillionsData } from '../types'
import Header from '../Header'
import Numbers from '../Numbers'

interface EuromillionsProps {
  data: EuromillionsData
  link: Link
}

const Table = dynamic(() => import('../Table'))

const StyledEuromillions = styled.div`
  ${({
    theme: {
      color: {
        tertiary: { grey100 },
      },
      spacing: { spacing20 },
    },
  }) => css`
    padding: ${spacing20} ${spacing20} 15px ${spacing20};
    background-color: ${grey100};
    margin-bottom: ${spacing20};
  `}
`

const Euromillions: FunctionComponent<EuromillionsProps> = ({ data, link }) => {
  const {
    numbers,
    'adjunct-numbers': adjunctNumbers,
    'formatted-jackpot': jackpot,
    'next-draw-date': nextDate,
    'draw-date': drawDate,
  } = data
  return (
    <StyledEuromillions>
      <Header title="EuroMillions" drawDate={drawDate} id="euromillions" />
      <Numbers numbers={numbers} adjunctNumbers={adjunctNumbers} />
      <Table lottoData={data} id="euromillions" />
      <Footer link={link} jackpot={jackpot} nextDate={nextDate} />
    </StyledEuromillions>
  )
}

export default Euromillions
