import { createContext } from 'react'
import { UseMutateFunction } from '@tanstack/react-query'
import {
  Comment,
  QueryCommentingData,
  Reaction,
  Reactions,
} from '@widgets/Commenting/types'

interface APIContextType {
  //! TODO: Fix type here
  comments: Comment[] | any
  fetchNextPage: () => void
  moreToShow: boolean
  postedComment: Comment
  postComment: UseMutateFunction<
    (payload: { articleId: string; body: string }) => Promise<Response>,
    unknown,
    { articleId: string; body: string },
    unknown
  >
  reportComment: (payload: { commentId: number; reason: string }) => void
  deleteComment: UseMutateFunction<
    Response,
    unknown,
    { commentId: number },
    { previousCommentingData: QueryCommentingData | undefined }
  >
  deletePostedComment: (payload: { commentId: number }) => void
  reactions?: Reactions
  initialReactions?: Reactions
  reactOnComment: (payload: {
    commentId: number
    reaction: Reaction
    set: boolean
  }) => Promise<Response | undefined>
}

const APIContext = createContext<APIContextType>({} as APIContextType)

export default APIContext
