import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

const StyledCounter = styled.div`
  ${({
    theme: {
      typography: {
        headings: {
          small: { bundledCSS: smallHeadingCSS },
        },
      },
      color: {
        primary: { blickRed: blickRedColor },
      },
    },
  }) => css`
    ${smallHeadingCSS};
    color: ${blickRedColor};
  `}
`

interface CounterProps {
  counterLabel: string
}

const Counter: FunctionComponent<CounterProps> = ({ counterLabel }) => {
  return <StyledCounter>{counterLabel}</StyledCounter>
}

export default Counter
