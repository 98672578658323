import { FunctionComponent } from 'react'
import { VideoPlayerProps } from './types'
import useVideoPlayerProps from '@hooks/useVideoPlayerProps'
import VideoPlayerComponent from '@components/Video/VideoPlayer'
import VideoRecommendations from './recommendations/VideoRecommendations'
import { isVideoRecommendationsEnabled } from './recommendations/utils'

const VideoPlayer: FunctionComponent<VideoPlayerProps> = ({
  videoId,
  jwVideoId,
  geoblocking,
  isAnimatedPreviewDisabled,
  onThumbnailData,
  widgetId,
  ...rest
}) => {
  const props = useVideoPlayerProps({
    ...rest,
    geoblocking,
    videoId: videoId || '',
    jwVideoId: jwVideoId || '',
    isAnimatedPreviewDisabled,
    onThumbnailData,
    widgetId,
  })

  const { isOverlay, viewportRef, showRecommendationsOverlay, ...playerProps } =
    props

  if (!videoId) {
    return null
  }

  return (
    <>
      {isVideoRecommendationsEnabled() ? (
        <VideoRecommendations
          widgetId={widgetId}
          isOverlay={isOverlay}
          showRecommendationsOverlay={showRecommendationsOverlay}
          isBlickTV={props.isBlickTV}
          onRecommendationsOverlay={props.onRecommendationsOverlay}
          hasNextPlaylistItem={props.hasNextPlaylistItem}
        />
      ) : null}

      <VideoPlayerComponent {...playerProps} ref={viewportRef} />
    </>
  )
}

export default VideoPlayer
