import { FunctionComponent } from 'react'
import {
  CookWidget,
  CookWidgetJSON,
  JSONTypeForCookWidget,
} from '@widgets/types'
import JSONRenderer from '@components/JSONRenderer'
import ArticleMainContentComponent from '@components/ArticleMainContent'

export interface ArticleMainContentAPIProps {
  content?: CookWidgetJSON[]
}

const ArticleMainContent: FunctionComponent<ArticleMainContentAPIProps> = (
  props
) => (
  <ArticleMainContentComponent>
    <JSONRenderer>{props.content}</JSONRenderer>
  </ArticleMainContentComponent>
)

const widget = {
  kind: ['article-main-content'],
  component: ArticleMainContent,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
