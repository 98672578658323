import { Fragment, FunctionComponent, useCallback, useMemo } from 'react'
import styled, { css, useTheme } from 'styled-components'

import { desktopCSS } from '@measures/responsive'
import { printCSS } from '@utils/style'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import JSONRenderer from '@components/JSONRenderer'

import { useRecommendations } from '@hooks/useRecommendations'
import SectionHeader from '@components/SectionHeader'
import RecommendationTeaserContext from '@contexts/recommendationTeaser'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import useViewportTracking from '@hooks/useViewportTracking'
import usePageMetadata from '@hooks/usePageMetadata'

export interface RecommendationsWidgetAPIProps {
  id: 'recommendations-top-container' | 'recommendations-bottom-container'
  title: string
}

const RecommendationsWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing16, spacing24, spacing32 },
    },
  }) => css`
    display: grid;
    grid-gap: ${spacing16};

    grid-template-columns: minmax(0, 1fr);

    ${printCSS(css`
      display: none;
    `)}

    ${desktopCSS(css`
      grid-row-gap: ${spacing24};
      grid-column-gap: ${spacing32};
      grid-template-columns: repeat(2, minmax(0, 1fr));

      > *:first-child {
        grid-column: 1 / -1;
      }
    `)}
  `}
`

const Recommendations: FunctionComponent<RecommendationsWidgetAPIProps> = ({
  title,
  id,
}) => {
  const theme = useTheme()
  const { url } = usePageMetadata()

  const { data: apiRecommendationData } = useRecommendations()

  const recommendations = useMemo(
    () =>
      id === 'recommendations-top-container'
        ? apiRecommendationData?.slice(0, 4)
        : apiRecommendationData?.slice(4, 8),
    [apiRecommendationData, id]
  )

  const onImpressionTracking = useCallback<TrackingFnType>(
    () => ({
      event: 'content_impression',
      placement: `article-${id}`,
      link_id: url,
    }),
    [id, url]
  )

  const onImpression = useTracking(onImpressionTracking)

  const viewportRef = useViewportTracking({
    onImpression,
    track: true,
  })

  if (!recommendations || recommendations?.length === 0) {
    return null
  }

  return (
    <RecommendationTeaserContext.Provider
      value={{ isRecommendation: true, placement: id }}>
      <RecommendationsWrapper ref={viewportRef}>
        <SectionHeader
          title={title}
          accentColor={theme.color.primary.blickRed}
          isSubheader={true}
        />
        {recommendations.map((recommendedItem) => (
          <Fragment key={recommendedItem.articleId}>
            <JSONRenderer key={recommendedItem.articleId}>
              {{ ...recommendedItem, track: true }}
            </JSONRenderer>
          </Fragment>
        ))}
      </RecommendationsWrapper>
    </RecommendationTeaserContext.Provider>
  )
}

const widget = {
  kind: ['recommendations'],
  component: Recommendations,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
