import styled, { css } from 'styled-components'
import ScoreboardGoalTeamContent from './ScoreboardGoalTeamContent'
import { LIVE_EVENT_DATA_SPORT_TYPES } from '@utils/formatters/common'
import useScoreboard from '@hooks/useScoreboard'

const ScoreboardGoalWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing16 },
      color: {
        tertiary: { grey300: grey300Color },
      },
    },
  }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: ${spacing16};
    border-top: 1px solid ${grey300Color};
    padding-top: ${spacing16};
  `}
`

const ScoreboardGoalContentSection = () => {
  const { formattedData } = useScoreboard<
    | typeof LIVE_EVENT_DATA_SPORT_TYPES.SOCCER
    | typeof LIVE_EVENT_DATA_SPORT_TYPES.HOCKEY
  >()

  const { goalsInfo } = formattedData
  const isGoalSection = goalsInfo?.home?.length || goalsInfo?.away?.length

  if (!isGoalSection) {
    return null
  }

  return (
    <ScoreboardGoalWrapper>
      <ScoreboardGoalTeamContent goals={goalsInfo.home} isAwayTeam={false} />
      <ScoreboardGoalTeamContent goals={goalsInfo.away} isAwayTeam={true} />
    </ScoreboardGoalWrapper>
  )
}
export default ScoreboardGoalContentSection
