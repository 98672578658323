import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { LinkCarouselTile } from '@widgets/LinkCarousel/types'
import FreeRatioImage from '@components/FreeRatioImage'
import { ImageConstraints } from '@components/FreeRatioImage/types'

const LOGO_CONSTRAINTS = {
  desktop: {
    width: 104,
    height: 84,
  },
  tablet: {
    width: 104,
    height: 84,
  },
  mobile: {
    width: 104,
    height: 84,
  },
  mobileSmall: {
    width: 104,
    height: 84,
  },
} as const satisfies ImageConstraints

export interface LinkCarouselLogoProps {
  image: LinkCarouselTile['image']
}

const StyledLinkCarouselLogo = styled.div`
  align-items: center;
  background-color: transparent;
  display: flex;
  justify-content: center;
  height: 84px;
`

const LinkCarouselLogo: FunctionComponent<LinkCarouselLogoProps> = ({
  image,
}) => {
  const { alt, crops, height, src, width } = image || {}

  return (
    <StyledLinkCarouselLogo>
      {!!image && (
        <FreeRatioImage
          alt={alt}
          constraints={LOGO_CONSTRAINTS}
          crops={crops ?? {}}
          originalDimensions={{ width, height }}
          src={src}
        />
      )}
    </StyledLinkCarouselLogo>
  )
}

export default LinkCarouselLogo
