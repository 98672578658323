import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

interface NameProps {
  children: string
  isClickable: boolean
  shouldWrap?: boolean
  hasInvertedColors: boolean
}

const StyledName = styled.span<NameProps>`
  ${({
    theme: {
      color: {
        primary: { primary01, primary02 },
      },
      typography: {
        subheads: {
          small1: { bundledCSS: small1SubheadsCSS },
        },
      },
    },
    isClickable,
    shouldWrap,
    hasInvertedColors,
  }) => css`
    ${small1SubheadsCSS};

    ${!shouldWrap &&
    css`
      white-space: nowrap;
    `}

    ${isClickable &&
    css`
      color: ${hasInvertedColors ? primary02 : primary01};
      text-decoration: underline;
    `}
  `}
`

const Name: FunctionComponent<NameProps> = ({
  children,
  isClickable,
  shouldWrap,
  hasInvertedColors,
}) => (
  <StyledName
    isClickable={isClickable}
    shouldWrap={shouldWrap}
    hasInvertedColors={hasInvertedColors}>
    {children}
  </StyledName>
)

export default Name
