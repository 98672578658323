import { DirectionType } from './types'

const ZOOM_TOLERANCE_PIXEL_AMOUNT = 5
const TOLERANCE_PIXEL_AMOUNT = 2

const getChildElementOffsets = (children: HTMLCollection, vertical: boolean) =>
  Array.from(children ?? []).map((child, _index, array) =>
    child
      ? (child as HTMLElement)[vertical ? 'offsetTop' : 'offsetLeft'] -
        (array[0] as HTMLElement)[vertical ? 'offsetTop' : 'offsetLeft']
      : -1
  )

const findCurrentIndexPredicate =
  (direction: DirectionType, scrollAmount: number) =>
  (childOffset: number, index: number, array: number[]): boolean => {
    const isWithinTolerance =
      scrollAmount < childOffset + ZOOM_TOLERANCE_PIXEL_AMOUNT &&
      scrollAmount > childOffset - ZOOM_TOLERANCE_PIXEL_AMOUNT

    if (isWithinTolerance) {
      return true
    } else if (direction === 'next') {
      const nextIndex = array.findIndex(
        (offset) => offset >= scrollAmount + ZOOM_TOLERANCE_PIXEL_AMOUNT
      )
      return index === nextIndex
    } else if (direction === 'prev') {
      const currentIndexArray = [...array].reverse()

      const currentIndex =
        array.length -
        (currentIndexArray.findIndex(
          (offset) => offset < scrollAmount - ZOOM_TOLERANCE_PIXEL_AMOUNT
        ) -
          1) -
        1

      return currentIndex === index
    }

    return false
  }

const getCurrentIndex = (
  direction: DirectionType,
  vertical: boolean,
  itemsContainerElement?: HTMLElement
): number => {
  if (!itemsContainerElement) {
    return -1
  }

  const scrollAmount =
    itemsContainerElement[vertical ? 'scrollTop' : 'scrollLeft'] ?? 0

  const childElementOffsets = getChildElementOffsets(
    itemsContainerElement?.children,
    vertical
  )

  return childElementOffsets.findIndex(
    findCurrentIndexPredicate(direction, scrollAmount)
  )
}

const getNextIndex = ({
  direction,
  itemsContainerElement,
  slidesPerGroup,
  vertical,
}: {
  direction: DirectionType
  itemsContainerElement: HTMLElement | null
  slidesPerGroup: number
  vertical: boolean
}): number => {
  if (!itemsContainerElement) {
    return -1
  }

  const currentIndex = getCurrentIndex(
    direction,
    vertical,
    itemsContainerElement
  )

  const lastIndex = itemsContainerElement.children.length - 1

  if (direction === 'next') {
    return currentIndex === -1 || currentIndex + slidesPerGroup > lastIndex
      ? lastIndex
      : currentIndex + slidesPerGroup
  }

  if (direction === 'prev') {
    return currentIndex === -1 || currentIndex - slidesPerGroup < 0
      ? 0
      : currentIndex - slidesPerGroup
  }

  return -1
}

const getArrowsVisibility = (
  itemsContainerElement: HTMLElement | null,
  vertical: boolean
) => {
  if (itemsContainerElement && itemsContainerElement.children.length) {
    const scrollValue =
      itemsContainerElement[vertical ? 'scrollTop' : 'scrollLeft']
    const maxScrollValue = vertical
      ? itemsContainerElement.scrollHeight - itemsContainerElement.clientHeight
      : itemsContainerElement.scrollWidth - itemsContainerElement.clientWidth
    const isScrollable = maxScrollValue > 0

    if (isScrollable) {
      if (scrollValue > maxScrollValue - TOLERANCE_PIXEL_AMOUNT) {
        return 'prev'
      }

      if (scrollValue < TOLERANCE_PIXEL_AMOUNT) {
        return 'next'
      }

      return 'both'
    }
  }

  return 'none'
}

export {
  getChildElementOffsets,
  getNextIndex,
  getCurrentIndex,
  getArrowsVisibility,
}
