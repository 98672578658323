import { ConfigProps } from '@config/types'

const config = ({ authUrl, pianoEnv }: ConfigProps) =>
  ({
    tokenUrl: `${authUrl}/piano-token`,
    applicationId:
      pianoEnv === 'production'
        ? '77U9mvKhpu'
        : pianoEnv === 'pre-production'
          ? 'r5a0Qylupu'
          : '5QrSF9r9su',
    pianoEndPoint: `https://${
      pianoEnv === 'sandbox' ? 'sandbox' : 'buy'
    }.tinypass.com/api/v3`,
    pianoSdkUrl: `https://${
      pianoEnv === 'sandbox' ? 'sandbox' : 'cdn'
    }.tinypass.com/api/tinypass.min.js`,
    pianoARIScriptUrl: 'https://cdn.cxense.com/ari.js',
    experienceCookieName: '__pianoParams',
    cxenseSiteId: '1140849009057005855',
    modalZIndex: 10000001,
    blickPlusOfferCardName: 'plusOfferTemplate',
    regwallTemplateCardName: 'regwallTemplate',
    freeToReadArticleCookieName: '__freeToReadArticle',
    blickCookiesDomain: '.blick.ch',
  }) as const satisfies Record<string, unknown>

export default config
