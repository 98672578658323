import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { AuthorProps } from '@widgets/Author/types'
import SingleAuthorWrapper from '@widgets/Author/SingleAuthors'
import { mobileCSS } from '@measures/responsive'

interface GridContainerProps {
  children: AuthorProps[]
}

const Wrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing8, spacing16 },
    },
  }) => css`
    display: grid;
    grid-gap: ${spacing16};
    grid-template-columns: repeat(3, 1fr);
    ${mobileCSS(css`
      grid-template-columns: unset;
      padding: 0 50px;
      grid-gap: ${spacing8};
    `)}
  `}
`

const GridContainer: FunctionComponent<GridContainerProps> = ({ children }) => (
  <Wrapper>
    {children.map((author) => (
      <SingleAuthorWrapper {...author} key={author.name} />
    ))}
  </Wrapper>
)

export default GridContainer
