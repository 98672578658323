import config from '@config'
import { TranslationsDE } from './de'
import { TranslationsFR } from './fr'

export type Translations = TranslationsDE | TranslationsFR

const {
  publication: { publication },
} = config

/* eslint-disable-next-line @typescript-eslint/no-require-imports */
const translations: Translations = require(
  `./${publication === 'romandie' ? 'fr' : 'de'}`
).default

function translate<T extends keyof Translations>(
  key: T,
  args?: Record<string, string>
): Translations[T] {
  if (!args) {
    return translations[key] ?? ''
  }

  const res = translations[key].replace(
    /{{(.+?)}}/g,
    (_: any, argsKey: string) => {
      const res = args[argsKey] || ''
      return res
    }
  ) as Translations[T]

  return res
}

export default translate
