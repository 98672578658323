import contextArticleTextbox, {
  ArticleTextboxContextType,
} from '@contexts/articleTextbox'
import useHeightTransition from '@hooks/useHeightTransition'
import { desktopCSS } from '@measures/responsive'
import { FunctionComponent, ReactNode, useCallback, useContext } from 'react'
import styled, { css } from 'styled-components'

const { ArticleTextboxContext } = contextArticleTextbox

const StyledContentWrapper = styled.div<
  Pick<ArticleTextboxContextType, 'isExpanded'>
>`
  ${({
    theme: {
      spacing: { spacing2, spacing20, spacing4 },
      color: {
        primary: { blickRed },
      },
    },
    isExpanded,
  }) => css`
    padding: 0 ${spacing20};
    ${isExpanded &&
    css`
      border-left: ${spacing2} solid ${blickRed};
      ${desktopCSS(css`
        border-left: ${spacing4} solid ${blickRed};
      `)}
    `}
    overflow: hidden;
    transition: height 0.5s ease-out;
  `}
`

const ExpandableContentWrapper: FunctionComponent<{ children?: ReactNode }> = ({
  children,
}) => {
  const { isExpanded } = useContext(ArticleTextboxContext)

  const getIsVisible = useCallback(() => isExpanded, [isExpanded])
  const { componentRef } = useHeightTransition({
    getIsVisible,
    collapsedHeight: 115,
  })

  return (
    <StyledContentWrapper ref={componentRef} isExpanded={isExpanded}>
      {children}
    </StyledContentWrapper>
  )
}

export default ExpandableContentWrapper
