import config from '@config'
import SchemaHTMLElement from '@components/SchemaHTMLElement'

const {
  schema: { parentOrganizationId, organizationAddressId },
} = config

const OrganizationSchema = () => {
  return (
    <SchemaHTMLElement
      scriptKey="organization"
      schemaObject={{
        '@type': 'Organization',
        '@id': parentOrganizationId,
        url: 'https://www.ringier.com/',
        name: 'Ringier',
        legalName: 'Ringier AG',
        alternateName: 'ringier.com',
        description:
          'Ringier is a family-owned media group with brands in Europe and Africa that focus on media, e-commerce, marketplaces and entertainment.',
        email: 'info@ringier.ch',
        telephone: '+41442596111',
        address: {
          '@id': organizationAddressId,
        },
        sameAs: [
          'https://www.linkedin.com/company/ringier/',
          'https://twitter.com/ringier_ag',
          'https://www.facebook.com/ringierag/',
          'https://www.youtube.com/user/RingierComm',
          'https://www.instagram.com/ringier_ag',
        ],
      }}
    />
  )
}

export default OrganizationSchema
