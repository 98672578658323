import LeserreporterContext from '@contexts/leserreporter'
import { useContext } from 'react'

const useLeserreporterContext = () => {
  const leserreporterContextData = useContext(LeserreporterContext)
  if (!leserreporterContextData) {
    throw new Error('Not allowed to use leserreporter context')
  }
  return leserreporterContextData
}

export default useLeserreporterContext
