import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'

export interface CatchwordProps {
  children?: ReactNode
}

const StyledCatchword = styled.div`
  ${({
    theme: {
      color: {
        tertiary: { grey400: grey400Color },
      },
      typography: {
        subheads: {
          small1: { bundledCSS: small1SubheadingCSS },
        },
      },
      spacing: { spacing4 },
      utils: { ellipsis },
    },
  }) => css`
    ${ellipsis('100%', 1)};
    ${small1SubheadingCSS};
    color: ${grey400Color};
    margin-bottom: ${spacing4};
  `}
`

const Catchword: FunctionComponent<CatchwordProps> = ({ children }) => (
  <StyledCatchword>{children}</StyledCatchword>
)

export default Catchword
