import { Children, FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'

export interface SponsoredLabelProps {
  children?: ReactNode
}

export interface StyledSponsoredLabelProps {
  hasLogo: boolean
}

const StyledSponsoredLabel = styled.div<StyledSponsoredLabelProps>`
  ${({
    theme: {
      spacing: { spacing8 },
      color: {
        tertiary: { grey800: grey800Color },
      },
      typography: {
        eyebrows: {
          small1: { bundledCSS: small1EyebrowCSS },
        },
      },
    },
    hasLogo,
  }) => css`
    ${small1EyebrowCSS};
    text-transform: uppercase;
    margin-bottom: ${spacing8};
    color: ${grey800Color};
    ${hasLogo &&
    css`
      line-height: 20px;
      > :nth-child(2) {
        vertical-align: top;
        display: inline-block;
      }
    `}
  `}
`

const Spacer = styled.div`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => css`
    display: inline-block;
    width: ${spacing8};
  `}
`

const SponsoredLabel: FunctionComponent<SponsoredLabelProps> = ({
  children,
}) => {
  const childrenAsArray = Children.toArray(children)
  const hasLogo = childrenAsArray.length > 1
  return (
    <StyledSponsoredLabel hasLogo={hasLogo}>
      {[childrenAsArray[0], <Spacer key="spacer" />, childrenAsArray[1]]}
    </StyledSponsoredLabel>
  )
}

export default SponsoredLabel
