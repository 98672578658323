import { JWPlayer, BlickTVInputProps } from '../types'
import {
  removeCaptionsUnavailableButton,
  addCaptionsUnavailableButton,
  toggleCaptionsButtons,
} from '../utils/controls'
import { CaptionsChangedParamExtended } from '../types/jwplayer-extended'

/**
 * Init the custom captions controls
 * @param jwplayer JWPlayer
 */
export const initCaptionsControls = (
  player: JWPlayer,
  {
    onCaptionsEnabled,
    onCaptionsDisabled,
  }: {
    onCaptionsEnabled: BlickTVInputProps['onCaptionsEnabled']
    onCaptionsDisabled: BlickTVInputProps['onCaptionsDisabled']
  }
): void => {
  /** default initial state: captions ON (currentTrack > 0) */
  const initialCaptionsTrack = 1

  let previousCaptionsEnabled = initialCaptionsTrack > 0
  let canUseCallbacks = false

  player.on('ready', () => {
    addCaptionsUnavailableButton(player)
  })

  // Fired when the list of available captions tracks changes.
  // This event is the ideal time to set default captions with the API.
  player.on('captionsList', (param: jwplayer.CaptionsListParam) => {
    // `tracks` will always have an array of at least 1 item due to off
    if (param.tracks.length > 1) {
      removeCaptionsUnavailableButton(player)
      /** default initial state: captions on */
      player.setCurrentCaptions(initialCaptionsTrack)
      toggleCaptionsButtons(player, {
        areCaptionsEnabled: initialCaptionsTrack > 0,
      })
    }
    // we can start using callback after initial caption state setup is finished
    canUseCallbacks = true
  })

  // Toggle control buttons if the active captions track is changed
  player.on('captionsChanged', (param: CaptionsChangedParamExtended) => {
    /** if the currentTrack is 0 => captions are disabled */
    const areCaptionsEnabled = param.track > 0

    // check if captionsEnabled state was changed, otherwise we dont
    // need to toggle ON/OFF buttons and call callbacks
    if (previousCaptionsEnabled !== areCaptionsEnabled) {
      // we finished setup and can use callbacks
      // this is to prevent extra calls on initial caption state set
      if (canUseCallbacks) {
        if (areCaptionsEnabled) {
          onCaptionsEnabled?.()
        } else {
          onCaptionsDisabled?.()
        }
      }

      toggleCaptionsButtons(player, { areCaptionsEnabled })

      previousCaptionsEnabled = areCaptionsEnabled
    }
  })
}
