import { ConfigProps } from '@config/types'

const config = ({ publication }: ConfigProps) =>
  ({
    dlApiScriptFetchUrl:
      'https://lib.onet.pl/s.csr/build/dlApi/dl.boot.dlapi.min.js',
    dlApiUrl: 'https://lib.onet.pl/s.csr/build/dlApi/dl.boot.min.js',
    tenant: `blick_ch${publication === 'romandie' ? '_fr' : ''}`,
    tenantId: '5469564',
    cmpLang: publication === 'romandie' ? 'fr-FR' : 'de-DE',
    adsNoBanner: 0,
    no_gemius: 1,
    async: 1,
    noDfp: 1,
    cmd: [],
  }) as const satisfies Record<string, unknown>

export default config
