import { FunctionComponent } from 'react'
import PianoFixedDiv from '@components/PianoFixedDiv'
import { AppProps } from 'next/app'
import Header from '@components/Header'
import { enhanceComponentWithErrorBoundary } from '@utils/sentry'
import { CatchAllPageServerProps } from '../../pages/[[...pageUrl]]'

export interface BlickPlusOfferPageProps {
  Component: AppProps['Component']
  restOfPageProps?: Partial<CatchAllPageServerProps>
}

const [HeaderWithErrorBoundary] = [Header].map((Component) =>
  enhanceComponentWithErrorBoundary(Component)
)

const BlickPlusOfferPage: FunctionComponent<BlickPlusOfferPageProps> = ({
  Component,
  restOfPageProps,
}) => {
  return (
    <>
      <HeaderWithErrorBoundary
        forceDisableSecondLevelNavigation={true}
        forceCenter={true}
      />
      <Component {...restOfPageProps} />
      <PianoFixedDiv />
    </>
  )
}

export default BlickPlusOfferPage
