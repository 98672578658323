import Blink from '@components/Blink'
import config from '@config'
import translate from '@i18n'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

const {
  gdpr: { dataPrivacyUrl },
} = config

const StyledBlink = styled(Blink)`
  ${({
    theme: {
      color: {
        primary: { primary01 },
        tertiary: { grey300 },
      },
      spacing: { spacing20, spacing40 },
      typography: {
        subheads: {
          small2: { bundledCSS: small2SubheadingCSS },
        },
      },
    },
  }) => css`
    color: ${primary01};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: ${spacing40} ${spacing20};
    border-top: 1px solid ${grey300};
    text-decoration: none;
    ${small2SubheadingCSS};
  `}
`

const GDPRLink: FunctionComponent = () => {
  return (
    <StyledBlink
      href={dataPrivacyUrl}
      ariaLabel={translate('config.gdprTitle')}>
      {translate('config.gdprTitle')}
    </StyledBlink>
  )
}

export default GDPRLink
