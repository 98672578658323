import HomeSection from './HomeSection'
import FirstLevelSection from './FirstLevelSection'
import OtherSection from './OtherSection'

const exports = {
  HomeSection,
  FirstLevelSection,
  OtherSection,
}

export default exports
