import { FunctionComponent } from 'react'
import styled from 'styled-components'
import ScoreboardMatchStatus from './ScoreboardMatchStatus'
import ScoreboardMatchResultSkiOrFormula1 from './ScoreboardMatchResultSkiOrFormula1'

const StyledScoreboardMatchResult = styled.div`
  align-items: center;
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
`

const ScoreboardSkiOrFormula1: FunctionComponent = () => {
  return (
    <StyledScoreboardMatchResult>
      <ScoreboardMatchStatus />
      <ScoreboardMatchResultSkiOrFormula1 />
    </StyledScoreboardMatchResult>
  )
}

export default ScoreboardSkiOrFormula1
