import { FunctionComponent } from 'react'
import { CreditProps } from '@widgets/LeadImage/types'
import Blink from '@components/Blink'
import styled, { css } from 'styled-components'
import translate from '@i18n'

const StyledLink = styled(Blink)`
  ${({
    theme: {
      color: {
        primary: { primary02 },
      },
    },
  }) => css`
    color: ${primary02};
  `}
`

const StyledWrapper = styled.div`
  ${({
    theme: {
      typography: {
        subheads: {
          xsmall2: { bundledCSS: subheadingXSmall2CSS },
        },
      },
      spacing: { spacing8, spacing4, spacing2 },
      color: {
        primary: { primary02 },
      },
      utils: { ellipsis },
    },
  }) => css`
    position: absolute;
    bottom: 0;
    right: 0;
    transform: rotate(-90deg) translate(100%, 0);
    transform-origin: right bottom;
    padding: ${spacing4} ${spacing8};
    ${subheadingXSmall2CSS};
    color: ${primary02};
    user-select: none;
    display: inline-block;
    box-sizing: border-box;
    text-shadow: 0 0 ${spacing2} rgba(17, 17, 17, 0.6);
    ${ellipsis('100%', 1)};
    max-width: calc(100% * 2 / 3);
  `}
`

const Credit: FunctionComponent<CreditProps> = ({ link, text }) => {
  if (!text) return null
  return (
    <StyledWrapper>
      {`${translate('gallery.photo')}: `}
      {link ? <StyledLink {...link}>{text}</StyledLink> : text}
    </StyledWrapper>
  )
}

export default Credit
