import { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import useIsBlickPlusOfferShown from '@hooks/useIsBlickPlusOfferShown'
import JSONRenderer from '@components/JSONRenderer'
import useSubscriptionStatus from '@hooks/useSubscriptionStatus'
import { GenericWidget } from '@widgets/Body'
import { bodyStyle } from '@widgets/Body/bodyStyle'
import useCMPHasUserInteracted from '@hooks/useCMPHasUserInteracted'

interface PlusContentWrapperProps {
  children: GenericWidget[] | null
}

const StyledPaywallWrapper = styled.div`
  ${bodyStyle}
`

const PlusContentWrapper: FunctionComponent<PlusContentWrapperProps> = ({
  children,
}) => {
  const [hideWidget, setHideWidget] = useState<boolean>(false)
  const { subscriptionStatus } = useSubscriptionStatus()
  const isBlickPlusOfferShown = useIsBlickPlusOfferShown()
  const hasUserInteracted = useCMPHasUserInteracted()

  useEffect(() => {
    // Piano is loaded after the user has given consent (see PR 1274), and the "paid widgets" are hidden after Piano paywall is shown.
    // When the CMP popup is initially shown, it is possible to scroll over the article in the background.
    // That's why we need to hide the paid content until the user has given the consent.
    if (!hasUserInteracted) {
      setHideWidget(true)
    } else {
      const shouldHide =
        isBlickPlusOfferShown &&
        // We also need to check subscription status because he/she should see plus content, in case he/she has a print abo
        subscriptionStatus === 'notSubscribed'
      setHideWidget(shouldHide)
    }
  }, [isBlickPlusOfferShown, subscriptionStatus, hasUserInteracted])

  if (!children) {
    return null
  }

  return (
    // We need a classname here, to reference the paywall content in the schema for SEO
    <StyledPaywallWrapper className="paywallContent">
      {hideWidget ? null : <JSONRenderer>{children}</JSONRenderer>}
    </StyledPaywallWrapper>
  )
}

export default PlusContentWrapper
