import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent } from 'react'
import {
  HeadlineLeadAPIProps,
  isInHerotellingPageHeadlineLeadAPIProps,
} from './types'
import useArticleSchema from '@hooks/useArticleSchema'
import useIsInHerotellingPage from '@hooks/useIsInHerotellingPage'
import HerotellingHeadlineLead from '@components/HeadlineLead/HerotellingHeadlineLead'
import DefaultHeadlineLead from '@components/HeadlineLead/NormalHeadlineLead'

const HeadlineLead: FunctionComponent<HeadlineLeadAPIProps> = (props) => {
  useArticleSchema({ description: props.lead })

  if (
    isInHerotellingPageHeadlineLeadAPIProps(props, useIsInHerotellingPage())
  ) {
    return <HerotellingHeadlineLead {...props} />
  } else {
    return <DefaultHeadlineLead {...props} />
  }
}

const widget = {
  kind: ['widget', 'headline-lead'],
  component: HeadlineLead,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
