import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent } from 'react'
import { HeadlineProps } from '@widgets/Headline/types'
import HeadlineComponents from '@components/Headline'

const { ArticleHeader, HeadlineWrapper } = HeadlineComponents

const Headline: FunctionComponent<HeadlineProps> = ({ catchword, title }) => {
  return (
    <ArticleHeader>
      <HeadlineWrapper catchword={catchword}>{title}</HeadlineWrapper>
    </ArticleHeader>
  )
}

const widget = {
  kind: ['widget', 'headline'],
  component: Headline,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
