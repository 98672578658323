import { Virtualizer } from '@tanstack/virtual-core'

const scrollToRight = ({
  virtualizer,
}: {
  virtualizer: Virtualizer<HTMLDivElement, Element>
}) => {
  const scrollOffset = virtualizer.scrollOffset
  const virtualItems = virtualizer.getVirtualItems()

  if (scrollOffset !== null && virtualizer?.scrollElement !== null) {
    const offset = scrollOffset + virtualizer.scrollElement.clientWidth
    const currentItem = virtualItems.find(
      (item) => item.start < offset && item.end >= offset
    )
    if (currentItem) {
      virtualizer.scrollToOffset(currentItem.start)
    } else {
      virtualizer.scrollToOffset(virtualizer.getTotalSize())
    }
  }
}

const scrollToLeft = ({
  virtualizer,
}: {
  virtualizer: Virtualizer<HTMLDivElement, Element>
}) => {
  const scrollOffset = virtualizer.scrollOffset
  const virtualItems = virtualizer.getVirtualItems()
  if (scrollOffset !== null && virtualizer?.scrollElement !== null) {
    const offset = scrollOffset - virtualizer.scrollElement.clientWidth
    const currentItem = virtualItems.find(
      (item) => item.start < offset && item.end >= offset
    )

    if (currentItem) {
      virtualizer.scrollToOffset(currentItem.end)
    } else {
      virtualizer.scrollToOffset(0)
    }
  }
}

export { scrollToLeft, scrollToRight }
