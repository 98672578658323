import { FunctionComponent, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { TextTrackProps } from '../../types'
import Captions from '../Captions'
import Graphics from '../Graphics'
import TextTracksPortal from './TextTracksPortal'
import useIsCasting from '@hooks/useIsCasting'

export const StyledTextTracksWrapper = styled.div<{
  isCasting: boolean
}>`
  ${({
    theme: {
      spacing: { spacing16 },
    },
    isCasting,
  }) => {
    return css`
      display: ${isCasting ? 'none' : 'inline-flex'};
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      pointer-events: none;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: ${spacing16};

      .btv-flag-recommendations-overlay-enabled & {
        visibility: hidden;
      }

      .jw-flag-ads-dai & {
        display: none;
      }
    `
  }}
`

const TextTracks: FunctionComponent<TextTrackProps> = ({
  player,
  onGraphicsCueChange,
  onCaptionsCueChange,
}) => {
  const container = player.getContainer().querySelector('.jw-captions')
  const [renderTextTracks, setRenderTextTracks] = useState<boolean>(false)
  const isCasting = useIsCasting()

  useEffect(() => {
    const renderTextTracksComponents = () => {
      setRenderTextTracks(true)
    }
    // we use 'captionsList' event because after this event
    // player container and '.jw-captions' node are ready
    // player.on('captionsList', renderTextTracksComponents)

    player.on('firstFrame', renderTextTracksComponents)

    return () => {
      // player.off('captionsList', renderTextTracksComponents)

      player.off('firstFrame', renderTextTracksComponents)
    }
  }, [player])

  return renderTextTracks && container ? (
    <TextTracksPortal jwCaptionsNode={container as HTMLElement}>
      <StyledTextTracksWrapper isCasting={isCasting}>
        <Captions player={player} onCaptionsCueChange={onCaptionsCueChange} />
        <Graphics player={player} onGraphicsCueChange={onGraphicsCueChange} />
      </StyledTextTracksWrapper>
    </TextTracksPortal>
  ) : null
}

export default TextTracks
