import styled, { css } from 'styled-components'
import { FunctionComponent, ReactNode } from 'react'
import { printCSS } from '@utils/style'

interface LayoutCardProps {
  className?: string
  children?: ReactNode
}

const StyledLayoutCardWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing8 },
      color: {
        tertiary: { grey400 },
      },
    },
  }) => css`
    border-radius: ${spacing8};
    border: 1px solid ${grey400};
    height: max-content;
    overflow: hidden;
    ${printCSS(css`
      border: none;
    `)}
  `}
`

const LayoutCard: FunctionComponent<LayoutCardProps> = ({
  className,
  children,
}) => (
  <StyledLayoutCardWrapper className={className}>
    {children}
  </StyledLayoutCardWrapper>
)

export default LayoutCard
