import translate from '@i18n'
import errorCategoryMap, {
  GeoBlockedErrorCode,
  ErrorCategoryMapValue,
  CategorizedErrorBehaviour,
  CategorizedErrorTranslation,
} from './config'

// Determines if the error is recoverable or not for the user and assigns the right translations.
type LevelType =
  | Pick<ErrorCategoryMapValue, 'behaviour' | 'translationCategory'>
  | undefined

const returnTypeFromErrorCodeDigit = (
  itemLevel: ErrorCategoryMapValue | undefined,
  stringErrorCode: string,
  sliceStart: number,
  sliceEnd: number
) => {
  return itemLevel?.[stringErrorCode.slice(sliceStart, sliceEnd)] as
    | ErrorCategoryMapValue
    | undefined
}

export const geoBlockedError: jwplayer.ErrorParam = {
  type: 'error',
  code: GeoBlockedErrorCode,
  message: translate(`video.error.geolocationNonRecoverable.text`),
  sourceError: null,
}

const getErrorCategory = (
  errorCode: number
): {
  behaviour: CategorizedErrorBehaviour
  translationCategory: CategorizedErrorTranslation
} => {
  const stringErrorCode = `${errorCode}`

  let errorCategory: ErrorCategoryMapValue = {
    behaviour: errorCategoryMap.behaviour,
    translationCategory: errorCategoryMap.translationCategory,
  }

  if (stringErrorCode.length !== 6) {
    return errorCategory
  }

  errorCategory =
    returnTypeFromErrorCodeDigit(errorCategoryMap, stringErrorCode, 0, 3) ??
    errorCategory

  errorCategory =
    returnTypeFromErrorCodeDigit(errorCategory, stringErrorCode, 3, 4) ??
    errorCategory

  errorCategory =
    returnTypeFromErrorCodeDigit(errorCategory, stringErrorCode, 4, 5) ??
    errorCategory

  errorCategory =
    (errorCategory?.[stringErrorCode.slice(5, 6)] as LevelType) ?? errorCategory

  return errorCategory
}

export default getErrorCategory
