import { FunctionComponent } from 'react'
import Head from 'next/head'
import config from '@config'
import usePageMetadata from '@hooks/usePageMetadata'

const {
  backend: { baseUrl },
} = config

const HTMLLinks: FunctionComponent = () => {
  const { url, canonical } = usePageMetadata()

  return (
    <Head>
      <link
        key="canonical"
        rel="canonical"
        href={canonical || `${baseUrl}${url}`}
      />
    </Head>
  )
}

export default HTMLLinks
