import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import ScoreboardTeamLogo from './ScoreboardTeamLogo'
import ScoreboardTeamContent from './ScoreboardTeamContent'
import useScoreboard from '@hooks/useScoreboard'
import { LIVE_EVENT_DATA_SPORT_TYPES } from '@utils/formatters/common'
import { desktopCSS } from '@measures/responsive'

export type ScoreboardTeamProps = { isAwayTeam?: boolean }

const StyledTeam = styled.div<{ isScheduled: boolean }>`
  ${({
    theme: {
      spacing: { spacing40, spacing32 },
    },
    isScheduled,
  }) => css`
    flex-basis: 100%;
    margin-top: ${isScheduled ? spacing40 : spacing32};
    ${desktopCSS(css`
      align-self: ${isScheduled ? 'end' : 'center'};
    `)}
  `}
`

const StyledTeamName = styled.div`
  ${({
    theme: {
      color: {
        primary: { primary01: primary01Color },
      },
      typography: {
        eyebrows: {
          small2: { bundledCSS: small2EyebrowsCSS },
          medium2: { bundledCSS: medium2EyebrowsCSS },
        },
      },
      spacing: { spacing8 },
      utils: { ellipsis },
    },
  }) => css`
    ${small2EyebrowsCSS};
    ${desktopCSS(css`
      margin: 0 ${spacing8};
      ${medium2EyebrowsCSS};
      line-height: 1.3;
    `)};
    color: ${primary01Color};
    text-transform: uppercase;
    margin-top: ${spacing8};
    line-height: 1.3;
    ${ellipsis('100%', 2)};
    text-align: center;
  `}
`

const ScoreboardTeam: FunctionComponent<ScoreboardTeamProps> = ({
  isAwayTeam,
}) => {
  const { formattedData } = useScoreboard<
    | typeof LIVE_EVENT_DATA_SPORT_TYPES.SOCCER
    | typeof LIVE_EVENT_DATA_SPORT_TYPES.HOCKEY
  >()

  const { teams, isScheduled } = formattedData

  const team = isAwayTeam ? teams[1] : teams[0]

  return (
    <StyledTeam isScheduled={isScheduled}>
      <ScoreboardTeamContent isAwayTeam={isAwayTeam}>
        {!!team.logoUrl && (
          <ScoreboardTeamLogo name={team.name} size={48} url={team.logoUrl} />
        )}
        <StyledTeamName>{team.name}</StyledTeamName>
      </ScoreboardTeamContent>
    </StyledTeam>
  )
}

export default ScoreboardTeam
