import { FunctionComponent } from 'react'
import VideoTitleComponents from '@components/Video/VideoPlayer/VideoTitle'
import { VideoAPIProps } from '@widgets/Video/types'
import { getTextWithExtraColon } from '@widgets/Video/utils'

export type VideoTitleProps = Pick<VideoAPIProps, 'title' | 'catchword'> & {
  isLiveStream?: boolean
}

const { Catchword, LiveLabel, TextWrapper, Title, Wrapper } =
  VideoTitleComponents

const VideoTitle: FunctionComponent<VideoTitleProps> = ({
  title = '',
  catchword = '',
  isLiveStream,
}) => {
  return (
    <Wrapper>
      <TextWrapper>
        <Catchword>{getTextWithExtraColon(catchword)}</Catchword>
        <Title>{title}</Title>
        {isLiveStream && <LiveLabel>Live</LiveLabel>}
      </TextWrapper>
    </Wrapper>
  )
}

export default VideoTitle
