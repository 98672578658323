import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { desktopCSS } from '@measures/responsive'
import Blink from '@components/Blink'
import config from '@config'

const {
  backend: { rootUrl },
} = config

type TabsBar = string[]

interface DossierTabsProps {
  tabsBar: TabsBar[]
  activeIndexes: number[]
}

interface ScrollableTypeTabsProps {
  tabs: TabsBar
  activeLetter: string
  activeType: string
}

type ScrollableLetterTabsProps = ScrollableTypeTabsProps

const TabsWrapper = styled.div`
  ${({
    theme: {
      color: {
        tertiary: { grey200 },
      },
      spacing: { spacing16 },
      measures: { stretchWidthOnMobile },
    },
  }) => css`
    background-color: ${grey200};
    height: 43px;
    box-sizing: border-box;

    padding: 0 ${spacing16};

    ${stretchWidthOnMobile()};
  `}
`

const ScrollableContainer = styled.div`
  ${({
    theme: {
      spacing: { spacing12, spacing24 },
    },
  }) => css`
    display: flex;
    flex-direction: row;
    gap: ${spacing12};

    ${desktopCSS(css`
      gap: ${spacing24};
    `)}

    white-space: nowrap;
    overflow-x: auto;
    text-align: center;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  `}
`

interface TabProps {
  isActive: boolean
}

const StyledBlink = styled(Blink)<TabProps>`
  ${({
    theme: {
      typography: {
        subheads: {
          medium2: { bundledCSS: subheadsMedium2CSS },
        },
      },
      color: {
        primary: { blickRed },
        tertiary: { grey800 },
      },
    },
    isActive,
  }) => css`
    ${subheadsMedium2CSS};
    color: ${grey800};
    line-height: 40px;
    text-decoration: none;

    ${isActive &&
    css`
      border-bottom: 3px solid ${blickRed};
    `}
  `}
`

const ScrollableTypeTabs: FunctionComponent<ScrollableTypeTabsProps> = ({
  tabs,
  activeLetter,
  activeType,
}) => {
  return (
    <TabsWrapper>
      <ScrollableContainer>
        {tabs.map((tab, index: number) => {
          const tabName = tab.charAt(0).toUpperCase() + tab.slice(1)
          const href = `${rootUrl}dossiers/${tab}/${activeLetter}/`
          return (
            <StyledBlink
              key={`${tabName}-${index}`}
              isActive={activeType === tab}
              href={href}>
              {tabName}
            </StyledBlink>
          )
        })}
      </ScrollableContainer>
    </TabsWrapper>
  )
}

const ScrollableLetterTabs: FunctionComponent<ScrollableLetterTabsProps> = ({
  tabs,
  activeType,
  activeLetter,
}) => {
  return (
    <TabsWrapper>
      <ScrollableContainer>
        {tabs.map((tab, index: number) => {
          const href = `${rootUrl}dossiers/${activeType}/${tab}/`
          return (
            <StyledBlink
              key={`${tab}-${index}`}
              isActive={activeLetter === tab}
              href={href}>
              {tab.toUpperCase()}
            </StyledBlink>
          )
        })}
      </ScrollableContainer>
    </TabsWrapper>
  )
}

const DossierTabs: FunctionComponent<DossierTabsProps> = ({
  tabsBar,
  activeIndexes,
}) => {
  const activeType = tabsBar[0][activeIndexes[0]]
  const activeLetter = tabsBar[1][activeIndexes[1]]

  return (
    <>
      <ScrollableTypeTabs
        tabs={tabsBar[0]}
        activeLetter={activeLetter}
        activeType={activeType}
      />
      <ScrollableLetterTabs
        tabs={tabsBar[1]}
        activeType={activeType}
        activeLetter={activeLetter}
      />
    </>
  )
}

const widget = {
  kind: ['layout', 'dossier-tabs'],
  component: DossierTabs,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
