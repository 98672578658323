import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { desktopCSS } from '@measures/responsive'
import useClientViewportType from '@hooks/useClientViewportType'

export interface RelatedMatchesWrapperMobileProps {
  children: ReactNode
}

const StyledRelatedMatchesWrapperMobile = styled.div`
  ${({
    theme: {
      spacing: { spacing24 },
    },
  }) => css`
    margin-top: ${spacing24};
    width: 100%;
    ${desktopCSS(css`
      display: none;
    `)};
  `}
`

const RelatedMatchesWrapperMobile: FunctionComponent<
  RelatedMatchesWrapperMobileProps
> = ({ children }) =>
  useClientViewportType() === 'desktop' ? null : (
    <StyledRelatedMatchesWrapperMobile>
      {children}
    </StyledRelatedMatchesWrapperMobile>
  )

export default RelatedMatchesWrapperMobile
