import { useQueryClient } from '@tanstack/react-query'

export interface FooterSkeletonLinkItem {
  text: string
  link: {
    href: string
    target: '_self' | '_blank'
    subtype?: string
    title?: string
    rel?: string
  }
}

export interface FooterSkeletonItem {
  kind: ['widget', 'footer']
  text: string
  link: {
    href: string
    target: '_self' | '_blank'
    subtype?: string
    rel?: string
    title: string
  }
  links: FooterSkeletonLinkItem[]
}

export interface BlickFooterSkeletonItem extends FooterSkeletonItem {
  logo: 'blick'
}

export interface RingierFooterSkeletonItem extends FooterSkeletonItem {
  logo: 'ringier'
}

export type FooterSkeleton = [
  BlickFooterSkeletonItem,
  RingierFooterSkeletonItem,
]

export type UseFooter = () => {
  blick: BlickFooterSkeletonItem
  ringier: RingierFooterSkeletonItem
}

const useFooter: UseFooter = () => {
  const queryClient = useQueryClient()

  const footerSkeleton = queryClient.getQueryData<FooterSkeleton>([
    'footerSkeleton',
  ]) as FooterSkeleton

  return { blick: footerSkeleton[0], ringier: footerSkeleton[1] }
}

export default useFooter
