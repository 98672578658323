import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { desktopCSS } from '@measures/responsive'
import useHasInvertedColors from '@hooks/useHasInvertedColors'

export interface TitleProps {
  children: string
}

export interface InvertedColorsStyledProps {
  hasInvertedColors: boolean
}

const StyledTitle = styled.div<InvertedColorsStyledProps>`
  ${({
    theme: {
      color: {
        primary: { primary01: primary01Color, primary02: primary02Color },
      },
      typography: {
        headings: {
          xsmall: { bundledCSS: xsmallHeadingCSS },
          xxsmall: { bundledCSS: xxsmallHeadingCSS },
        },
      },
      utils: { ellipsis },
    },
    hasInvertedColors,
  }) => css`
    ${ellipsis('100%', 4)};
    ${xsmallHeadingCSS};
    ${desktopCSS(css`
      ${xxsmallHeadingCSS};
    `)};
    color: ${hasInvertedColors ? primary02Color : primary01Color};
  `}
`

const Title: FunctionComponent<TitleProps> = ({ children }) => {
  const hasInvertedColors = useHasInvertedColors()

  return (
    <StyledTitle hasInvertedColors={hasInvertedColors}>{children}</StyledTitle>
  )
}

export default Title
