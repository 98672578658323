import { FunctionComponent } from 'react'
import config from '@config'
import SchemaHTMLElement from '@components/SchemaHTMLElement'
import { dayjs } from '@utils/date'

const {
  schema: { blogPostingUrl },
} = config

interface BlogPostingSchemaProps {
  id: number
  headline: string
  articleBody: string
  datePublished?: number
}

const BlogPostingSchema: FunctionComponent<BlogPostingSchemaProps> = ({
  id,
  headline,
  articleBody,
  datePublished,
}) => {
  const finalDatePublished = datePublished ? dayjs(datePublished).format() : ''
  return (
    <SchemaHTMLElement
      scriptKey={`blog-posting-schema-${id}`}
      schemaObject={{
        '@context': 'https://schema.org',
        '@type': 'BlogPosting',
        '@id': `${blogPostingUrl}${id}`,
        datePublished: finalDatePublished,
        headline,
        articleBody,
      }}
    />
  )
}

export default BlogPostingSchema
