import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

import translate from '@i18n'
import { desktopCSS, mobileAndTabletCSS } from '@measures/responsive'
import useSportsEvent, {
  EnrichedLiveEventTransformedHockey,
  EnrichedLiveEventTransformedSoccer,
  UseSportsEventPropsHockey,
  UseSportsEventPropsSoccer,
} from '@hooks/useSportsEvent'
import {
  TransformedDataHockey,
  TransformedDataSoccer,
  transformDataForSportsComponents,
} from '@components/SportsFooter/utils'
import Blink from '@components/Blink'
import TeamLogo from '@components/SportsFooter/TeamLogo'
import { RelatedMatchHockey, RelatedMatchSoccer } from '@widgets/RelatedMatches'
import useHasBlurryBackground from '@hooks/useHasBlurryBackground'

type TileProps = RelatedMatchSoccer | RelatedMatchHockey

type StatusType = {
  isLive?: boolean
  hasScoreExplanation?: boolean
  hasBlurryBackground: boolean
}
type TeamLogoType = { type: 'home' | 'away' }
type ScoreType = TeamLogoType & { hasBlurryBackground: boolean }
type TeamNameType = TeamLogoType & { hasBlurryBackground: boolean }

const StyledBlink = styled(Blink)<{
  isPlaceholder: boolean
  hasBlurryBackground: boolean
}>`
  ${({
    theme: {
      spacing: { spacing4, spacing8, spacing12, spacing32 },
      color: {
        tertiary: { grey400 },
      },
    },
    isPlaceholder,
    hasBlurryBackground,
  }) => css`
    appearance: none;
    text-decoration: none;

    border: 1px solid ${grey400};
    border-radius: ${spacing8};
    height: 143px;
    padding: ${spacing12};
    box-sizing: border-box;

    ${desktopCSS(css`
      width: 300px;
      ${hasBlurryBackground &&
      css`
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 4px;
        border: none;
      `};
    `)};

    ${mobileAndTabletCSS(css`
      width: 200px;
    `)};

    ${isPlaceholder &&
    css`
      border-color: transparent;
    `}

    /*
    * TN: Tournament Name
    * L1: Logo 1
    * N1: Team Name 1
    * SC1: Score 1
    * N2: Team Name 2
    * L2: Logo 2
    * SC2: Score 2
    * SP: Separator
    * ST: Status
    */
    display: grid;
    grid-template-areas:
      'TN TN TN'
      'L1 N1 SC1'
      'L2 N2 SC2'
      'SP SP SP'
      'ST ST ST';

    grid-template-rows: repeat(5, auto);
    grid-template-columns: ${spacing32} minmax(0, 1fr) auto;
    grid-row-gap: ${spacing4};
    grid-column-gap: ${spacing8};
    align-items: center;
  `}
`

const TournamentName = styled.div<{
  hasBlurryBackground: boolean
}>`
  ${({
    theme: {
      color: {
        tertiary: { grey200, grey800 },
      },
      typography: {
        bodycopy: {
          xxsmall: { bundledCSS: bodycopyXXSmall },
        },
      },
      utils: { ellipsis },
    },
    hasBlurryBackground,
  }) => css`
    grid-area: TN;
    ${bodycopyXXSmall};
    color: ${hasBlurryBackground ? grey200 : grey800};
    ${ellipsis('100%', 1)};
    display: inline-block;
  `}
`

const StyledTeamLogo = styled(TeamLogo)<TeamLogoType>`
  ${({ type }) => css`
    grid-area: ${type === 'home' ? 'L1' : 'L2'};
  `}
`

const TeamName = styled.div<TeamNameType>`
  ${({
    type,
    theme: {
      color: {
        primary: { primary01, primary02 },
      },
      typography: {
        eyebrows: {
          medium2: { bundledCSS: eyebrowsMedium2 },
        },
      },
      utils: { ellipsis },
    },
    hasBlurryBackground,
  }) => css`
    ${eyebrowsMedium2};
    color: ${hasBlurryBackground ? primary02 : primary01};
    text-transform: uppercase;
    ${ellipsis('100%', 1)};
    display: inline-block;
    grid-area: ${type === 'home' ? 'N1' : 'N2'};
  `}
`

const Score = styled.div<ScoreType>`
  ${({
    type,
    theme: {
      color: {
        primary: { primary01, primary02 },
      },
      typography: {
        headings: {
          small: { bundledCSS: headingsSmall },
        },
      },
    },
    hasBlurryBackground,
  }) => css`
    grid-area: ${type === 'home' ? 'SC1' : 'SC2'};
    color: ${hasBlurryBackground ? primary02 : primary01};
    ${headingsSmall};
  `}
`

const Separator = styled.div<{
  hasBlurryBackground: boolean
}>`
  ${({
    theme: {
      color: {
        tertiary: { grey400: grey400Color },
        primary: { primary02: primary02Color },
      },
      spacing: { spacing4 },
    },
    hasBlurryBackground,
  }) => css`
    grid-area: SP;
    width: 100%;
    height: 1px;
    background-color: ${hasBlurryBackground ? primary02Color : grey400Color};
    margin-top: ${spacing4};
    margin-bottom: ${spacing4};
  `}
`

const Status = styled.div<StatusType>`
  ${({
    isLive,
    hasScoreExplanation,
    theme: {
      color: {
        tertiary: { grey200, grey800 },
        primary: { blickRed },
      },
      typography: {
        eyebrows: {
          medium1: { bundledCSS: eyebrowsMedium1 },
          medium2: { bundledCSS: eyebrowsMedium2 },
        },
      },
    },
    hasBlurryBackground,
  }) => css`
    grid-area: ST;
    ${isLive ? eyebrowsMedium2 : eyebrowsMedium1};
    color: ${isLive ? blickRed : hasBlurryBackground ? grey200 : grey800};
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;

    ${isLive &&
    css`
      &:before {
        content: '';
        display: inline;
        width: 6px;
        height: 6px;
        background-color: ${blickRed};
        border-radius: 50%;
        margin-right: 2px;
      }
    `}

    ${hasScoreExplanation &&
    css`
      display: flex;
      justify-content: space-between;
    `}
  `}
`

const ScoreExplanation = styled.div`
  text-transform: none;
`

const Tile: FunctionComponent<TileProps> = ({ matchId, typeOfSport, link }) => {
  const { data: sportsEventData } = useSportsEvent<
    UseSportsEventPropsHockey | UseSportsEventPropsSoccer
  >({
    matchId,
    typeOfSport,
    shouldRefetch: true,
  })

  const sportsData = transformDataForSportsComponents(
    sportsEventData ??
      ({} as
        | EnrichedLiveEventTransformedHockey
        | EnrichedLiveEventTransformedSoccer)
  )

  const {
    tournamentName,
    homeTeam,
    homeTeamScore,
    awayTeam,
    awayTeamScore,
    isLive,
    scoreExplanation,
    hasEnded,
    statusTitle,
    isScoreAvailable,
  } =
    sportsData ??
    ({ homeTeam: {}, awayTeam: {} } as
      | TransformedDataSoccer
      | TransformedDataHockey)

  const hasScoreExplanation = !!scoreExplanation

  const hasBlurryBackground = useHasBlurryBackground()

  return (
    <StyledBlink
      isPlaceholder={!sportsData}
      {...link}
      hasBlurryBackground={hasBlurryBackground}>
      {!!sportsData && (
        <>
          <TournamentName hasBlurryBackground={hasBlurryBackground}>
            {tournamentName}
          </TournamentName>
          <StyledTeamLogo
            url={homeTeam.logoUrl}
            name={homeTeam.name}
            imageWidth={32}
            type="home"
          />
          <TeamName type="home" hasBlurryBackground={hasBlurryBackground}>
            {homeTeam.name}
          </TeamName>
          {isScoreAvailable && (
            <Score type="home" hasBlurryBackground={hasBlurryBackground}>
              {homeTeamScore}
            </Score>
          )}
          <StyledTeamLogo
            url={awayTeam.logoUrl}
            name={awayTeam.name}
            imageWidth={32}
            type="away"
          />
          <TeamName type="away" hasBlurryBackground={hasBlurryBackground}>
            {awayTeam.name}
          </TeamName>
          {isScoreAvailable && (
            <Score type="away" hasBlurryBackground={hasBlurryBackground}>
              {awayTeamScore}
            </Score>
          )}
          <Separator hasBlurryBackground={hasBlurryBackground} />
          {hasEnded && (
            <Status
              hasScoreExplanation={hasScoreExplanation}
              hasBlurryBackground={hasBlurryBackground}>
              {translate('liveEvent.finished')}
              {statusTitle}
              <ScoreExplanation>
                {hasScoreExplanation && scoreExplanation}
              </ScoreExplanation>
            </Status>
          )}
          {!hasEnded && (
            <Status isLive={isLive} hasBlurryBackground={hasBlurryBackground}>
              {statusTitle}
            </Status>
          )}
        </>
      )}
    </StyledBlink>
  )
}

export default Tile
