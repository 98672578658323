import EmbeddedContent from '@components/EmbeddedContent'
import config from '@config'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent } from 'react'
import styled from 'styled-components'

const {
  heimspiel: { heimspielRankingStaticPageUrl },
} = config

const StyledHeimspielRanking = styled.div`
  min-height: 512px;
`

const HeimspielRanking: FunctionComponent = () => (
  <StyledHeimspielRanking>
    <EmbeddedContent
      bypassCMP={true}
      maxHeight={500}
      url={heimspielRankingStaticPageUrl}
    />
  </StyledHeimspielRanking>
)

const widget = {
  kind: ['widget', 'heimspiel-ranking'],
  component: HeimspielRanking,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
