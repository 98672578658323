import { desktopCSS } from '@measures/responsive'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

const StyledTitle = styled.div`
  ${({
    theme: {
      typography: {
        headings: {
          xsmall: { bundledCSS: headingXSmall },
          small: { bundledCSS: headingSmall },
        },
      },
      color: {
        primary: { primary01 },
      },
      spacing: { spacing8 },
      utils: { ellipsis },
    },
  }) => css`
    margin-bottom: ${spacing8};
    ${ellipsis('100%', 3)};
    color: ${primary01};

    ${headingXSmall}

    ${desktopCSS(css`
      ${headingSmall}
      ${ellipsis('100%', 2)}
    `)};
  `}
`

const Title: FunctionComponent<{ children: string }> = ({
  children: title,
}) => <StyledTitle>{title}</StyledTitle>

export default Title
