import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent, useCallback, useState } from 'react'
import { ScoreboardAPIProps } from './types'
import { isValidScoreboardProps } from './utils'
import styled, { css } from 'styled-components'
import JSONRenderer from '@components/JSONRenderer'
import Scoreboard from '@components/Scoreboard'
import SportsPartner from '@components/SportsPartner'
import ScoreboardLink from '@components/Scoreboard/ScoreboardLink'
import { useQueryClient } from '@tanstack/react-query'
import {
  EnrichedLiveEventTransformedFormula1,
  EnrichedLiveEventTransformedHockey,
  EnrichedLiveEventTransformedSki,
  EnrichedLiveEventTransformedSoccer,
  EnrichedLiveEventTransformedTennis,
} from '@hooks/useSportsEvent'
import config from '@config'

const {
  publication: { publication },
} = config

const Wrapper = styled.div``

const StyledScoreboard = styled.div`
  ${({
    theme: {
      color: {
        tertiary: { grey100 },
      },
      measures: { stretchWidthOnMobile },
    },
  }) => {
    return css`
      background-color: ${grey100};
      display: flex;
      flex-direction: column;

      ${stretchWidthOnMobile()}
    `
  }}
`

const StyledSportsPartner = styled(SportsPartner)`
  margin: 0;
`

const ScoreboardWidget: FunctionComponent<ScoreboardAPIProps> = (props) => {
  const { link, video, zattoo, streaming, partner } = props
  const queryClient = useQueryClient()

  const [displaySportsPartner, setDisplaySportsPartner] = useState(() => {
    if (publication === 'romandie') {
      return true
    }
    const sportsEventData = queryClient.getQueryData<
      | EnrichedLiveEventTransformedSoccer
      | EnrichedLiveEventTransformedHockey
      | EnrichedLiveEventTransformedSki
      | EnrichedLiveEventTransformedFormula1
      | EnrichedLiveEventTransformedTennis
    >(['sportsEvent', props.typeOfSport, props.matchId])
    return !sportsEventData?.formattedData?.isFinished
  })

  const updateDisplaySportsPartner = useCallback<
    (matchFinished: boolean) => void
  >((matchFinished) => {
    if (publication !== 'romandie') {
      setDisplaySportsPartner(!matchFinished)
    }
  }, [])

  const videoComponent = !!video?.videoId
    ? { ...video, isInScoreboardContent: true }
    : null

  const zattooComponent = zattoo
    ? { ...zattoo, isInScoreboardContent: true }
    : null

  if (!isValidScoreboardProps(props)) {
    return null
  }

  const { matchId, typeOfSport } = props

  const showScoreboard = matchId !== '0'
  return (
    <Wrapper>
      {link && <ScoreboardLink link={link} />}
      <StyledScoreboard>
        {showScoreboard && (
          <Scoreboard
            matchId={matchId}
            streaming={streaming}
            typeOfSport={typeOfSport}
            updateDisplaySportsPartner={updateDisplaySportsPartner}
          />
        )}
        <JSONRenderer>{videoComponent}</JSONRenderer>
        <JSONRenderer>{zattooComponent}</JSONRenderer>
        {displaySportsPartner && <StyledSportsPartner partner={partner} />}
      </StyledScoreboard>
    </Wrapper>
  )
}

const widget = {
  kind: ['widget', 'sport', 'scoreboard'],
  component: ScoreboardWidget,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
