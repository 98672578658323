const commonConstraints = {
  tablet: {
    width: 768,
    height: 512,
  },
  mobile: {
    width: 412,
    height: 437,
  },
  mobileSmall: {
    width: 320,
    height: 437,
  },
}

const commonConstraintsForSingleGallery = {
  tablet: {
    width: 768,
    height: 10000,
  },
  mobile: {
    width: 412,
    height: 10000,
  },
  mobileSmall: {
    width: 320,
    height: 10000,
  },
}

const defaultConstraints = {
  desktop: {
    width: 632,
    height: 421,
  },
  ...commonConstraints,
}

const constraintsForStoryTelling = {
  desktop: {
    width: 994,
    height: 662,
  },
  ...commonConstraints,
}
const constraintsForRecipe = {
  desktop: {
    width: 576,
    height: 420,
  },
  ...commonConstraints,
}

const constraintsForSingleGallery = {
  desktop: {
    width: 632,
    /* this height value is set to 10000, since single images can have different ratios
    and we need only the width to calculate constraint dimensions */
    height: 10000,
  },
  ...commonConstraintsForSingleGallery,
}

const constraintsForStoryTellingForSingleGallery = {
  desktop: {
    width: 994,
    height: 10000,
  },
  ...commonConstraintsForSingleGallery,
}

const constraintsForRecipeForSingleGallery = {
  desktop: {
    width: 630,
    height: 10000,
  },
  ...commonConstraintsForSingleGallery,
}

const constraints = {
  default: {
    default: defaultConstraints,
    noHeightConstraint: constraintsForSingleGallery,
  },
  noDesktopSidebar: {
    default: constraintsForStoryTelling,
    noHeightConstraint: constraintsForStoryTellingForSingleGallery,
  },
  inRecipe: {
    default: constraintsForRecipe,
    noHeightConstraint: constraintsForRecipeForSingleGallery,
  },
}

export { constraints }
