import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { mobileCSS } from '@measures/responsive'
import useIsIngredientsCardMainElement from '@hooks/useIsIngredientsCardMainElement'
import { printCSS } from '@utils/style'

interface WrapperProps {
  isMainElement: boolean
}

const StyledWrapper = styled.div<WrapperProps>`
  ${({
    isMainElement,
    theme: {
      spacing: { spacing32, spacing40, spacing16 },
    },
  }) => css`
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    margin-top: ${isMainElement ? spacing40 : spacing16};

    ${mobileCSS(css`
      grid-auto-flow: unset;
      margin-top: ${spacing32};
      grid-template-columns: repeat(2, 1fr);
    `)}
    ${printCSS(css`
      grid-auto-flow: column;
      grid-template-columns: none;
    `)}
  `}
`

const RecipeInfoWrapper: FunctionComponent<{ children?: ReactNode }> = ({
  children,
}) => {
  const isMainElement = useIsIngredientsCardMainElement()
  return <StyledWrapper isMainElement={isMainElement}>{children}</StyledWrapper>
}

export default RecipeInfoWrapper
