import { useEffect, useMemo } from 'react'
import throttle from 'lodash.throttle'

import useVideoTracking from '@hooks/useVideoTracking'
import { getJWPlayer } from '@utils/videoPlayer'
import { PLAYER_TYPES } from '@widgets/Video/utils'

type UseBlickBitesTracking = ({
  enabled,
  isPlayerReady,
  biteId,
  widgetId,
  videoId,
  videoTitle,
  videoDuration,
  isInViewport,
}: {
  enabled: boolean
  isPlayerReady: boolean
  biteId: string
  widgetId: string
  videoId: string
  videoTitle: string
  videoDuration: number
  isInViewport: boolean
}) => void

const useBlickBitesTracking: UseBlickBitesTracking = ({
  enabled = true,
  isPlayerReady,
  biteId,
  widgetId,
  videoId,
  videoTitle,
  videoDuration,
  isInViewport,
}) => {
  const { onPlaySuccess, onEnd, onProgress } = useVideoTracking({
    widgetId,
    biteId,
    isBlickTV: false,
    autoplay: true,
    playerType: PLAYER_TYPES.BITE,
    videoId: videoId,
    jwVideoId: videoId,
    title: videoTitle,
    duration: videoDuration,
    isInViewport: isInViewport,
    isInBlickBitesFastlane: true,
    allowMultipleOnEvents: true,
  })

  const onTimeHandler = useMemo(
    () =>
      throttle(
        ({ position }: jwplayer.TimeParam) => {
          onProgress?.({
            currentTime: position,
          })
        },
        1000,
        { trailing: false }
      ),
    [onProgress]
  )

  useEffect(() => {
    const player = getJWPlayer(widgetId)

    if (!enabled || !isPlayerReady || !player) {
      return
    }

    player.on('time', onTimeHandler)

    return () => {
      player.off('time', onTimeHandler)
    }
  }, [isPlayerReady, widgetId, onTimeHandler, enabled])

  useEffect(() => {
    // cleanup function
    return () => {
      onTimeHandler?.cancel()
    }
  }, [onTimeHandler])

  useEffect(() => {
    const player = getJWPlayer(widgetId)

    if (!enabled || !isPlayerReady || !player) {
      return
    }

    player.on('firstFrame', onPlaySuccess)

    return () => {
      player.off('firstFrame', onPlaySuccess)
    }
  }, [enabled, isPlayerReady, onPlaySuccess, widgetId])

  useEffect(() => {
    const player = getJWPlayer(widgetId)

    if (!enabled || !isPlayerReady || !player) {
      return
    }

    player.on('complete', onEnd)

    return () => {
      player.off('complete', onEnd)
    }
  }, [enabled, isPlayerReady, onEnd, widgetId])
}

export default useBlickBitesTracking
