import { useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { SocialMediaType } from '@widgets/AuthorHeadline/types'

type UseAuthorSchemaType = ({
  socialMedia,
}: {
  socialMedia?: SocialMediaType[]
}) => void

const useAuthorSchema: UseAuthorSchemaType = ({ socialMedia }) => {
  const queryClient = useQueryClient()

  useEffect(() => {
    queryClient.setQueryData(['authorSchemaData'], {
      links: socialMedia
        ?.filter((item) => item.type !== 'mail')
        ?.map((item) => item.src),
    })
  }, [queryClient, socialMedia])
}

export default useAuthorSchema
