import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'

const StyledCatchword = styled.span`
  ${({
    theme: {
      typography: {
        headings: {
          xsmall: { bundledCSS: xsmallHeading },
        },
      },
      color: {
        tertiary: { grey500: grey500Color },
      },
      spacing: { spacing8 },
    },
  }) => {
    return css`
      ${xsmallHeading};
      color: ${grey500Color};
      margin-right: ${spacing8};
    `
  }}
`

const Catchword: FunctionComponent<{ children?: ReactNode }> = ({
  children,
}) => <StyledCatchword>{children}</StyledCatchword>

export default Catchword
