import { ConfigProps } from '@config/types'

const config = ({ publication, sportApiUrl }: ConfigProps) =>
  ({
    baseUrl: sportApiUrl,
    eventUrlTemplate: `/${
      publication === 'romandie' ? 'fr' : 'de'
    }/live-event/{SPORT}/{EVENT_ID}`,
    standingsUrlTemplate: `/${
      publication === 'romandie' ? 'fr' : 'de'
    }/standings/{SPORT}/{EVENT_ID}`,
  }) as const satisfies Record<string, unknown>

export default config
