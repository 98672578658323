import { createContext } from 'react'

type RecommendationPlacement =
  | 'recommendations-top-container'
  | 'recommendations-bottom-container'

export type RecommendationTeaserContextType = {
  isRecommendation: boolean
  placement?: RecommendationPlacement
}

const RecommendationTeaserContext =
  createContext<RecommendationTeaserContextType>({ isRecommendation: false })

export default RecommendationTeaserContext
