import config from '@config'
import { Link } from '@utils/cook/types'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent } from 'react'
import { useQuery } from '@tanstack/react-query'
import styled, { css } from 'styled-components'
import Euromillions from './Euromillions'
import Swisslotto from './Swisslotto'
import { LottoData } from './types'

const {
  lotto: { baseUrl },
} = config

interface LottoProps {
  euromillionsLink: {
    link: Link
  }
  swisslottoLink: { link: Link }
}

const LottoWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    padding-top: ${spacing16};
  `}
`

const getLottoData = async () => await (await fetch(baseUrl)).json()

const Lotto: FunctionComponent<LottoProps> = ({
  euromillionsLink,
  swisslottoLink,
}) => {
  const { data: lottoData } = useQuery<LottoData>({
    queryKey: ['lottoData'],
    queryFn: () => getLottoData(),
  })

  if (!lottoData) {
    return null
  }

  return (
    <LottoWrapper>
      <Euromillions
        link={euromillionsLink.link}
        data={lottoData.euromillions}
      />
      <Swisslotto link={swisslottoLink.link} data={lottoData.swisslotto} />
    </LottoWrapper>
  )
}

const widget = {
  kind: ['widget', 'lotto', 'full'],
  component: Lotto,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
