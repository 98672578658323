import { FunctionComponent } from 'react'
import styled, { css, useTheme } from 'styled-components'
import SvgIcon from '../SvgIcon'
import { SvgIconName } from '@components/SvgIcon/types'

interface Props {
  type?: SvgIconName
}

const StyledPlayIcon = styled(SvgIcon)``

const PlayIconContainer = styled.div`
  ${({
    theme: {
      color: {
        primary: { blickRed: blickRedColor },
        secondary: { darkRed: darkRedColor },
      },
      spacing: { spacing64, spacing80 },
    },
  }) => css`
    background-color: ${blickRedColor};
    border-radius: ${spacing64};
    width: ${spacing80};
    height: ${spacing80};
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: ${darkRedColor};
    }
  `}
`

const PlayIcon: FunctionComponent<Props> = ({
  type = 'video-play-sharp-filled',
}) => {
  const theme = useTheme()

  return (
    <PlayIconContainer>
      <StyledPlayIcon
        className="play-icon"
        iconName={type}
        size={36}
        color={theme.color.primary.primary02}
      />
    </PlayIconContainer>
  )
}

export default PlayIcon
