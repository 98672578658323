import { RefObject, useCallback, useEffect } from 'react'

type UseClickOutsideProps = {
  track?: boolean
  elementRef?: RefObject<HTMLElement>
  onClickOutside?: () => void
}

export type UseClickOutsideType = ({
  track,
  elementRef,
  onClickOutside,
}: UseClickOutsideProps) => void

const useClickOutside: UseClickOutsideType = ({
  track = true,
  elementRef,
  onClickOutside,
}) => {
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        elementRef?.current &&
        !elementRef?.current.contains(event.target as HTMLElement)
      ) {
        onClickOutside?.()
      }
    },
    [elementRef, onClickOutside]
  )

  useEffect(() => {
    const clickElement = elementRef?.current

    if (track && clickElement) {
      document.addEventListener('mousedown', handleClickOutside)
    }
    return () => {
      if (track && clickElement) {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }
  }, [elementRef, handleClickOutside, track])
}

export default useClickOutside
