import {
  ChangeEvent,
  FormEvent,
  FunctionComponent,
  useCallback,
  useRef,
  useState,
} from 'react'
import BulletpointChoices from './BulletpointChoices'
import RedAlert from './RedAlert'
import UploadFiles from './UploadFiles'
import config from '@config'
import { Data, Validation } from '../types'
import UserData from './UserData'
import DescriptionTextarea from './DescriptionTextarea'
import AnonymousCheckbox from './AnonymousCheckbox'
import ConsentCheckbox from './ConsentCheckbox'
import SecondaryCTAButton from '@components/Buttons/SecondaryCTAButton'
import styled, { css } from 'styled-components'
import SubmittedOverlay from './SubmittedOverlay'
import {
  defaultValidation,
  isValidSubmission,
  validateData,
} from './validation'
import nanoid from '@utils/random'
import { encodeFormData } from './utils'
import useUser from '@hooks/useUser'
import LeserreporterContext from '@contexts/leserreporter'

const {
  ugc: { uploadUrl },
} = config

const Container = styled.div`
  ${({
    theme: {
      spacing: { spacing40 },
    },
  }) => css`
    position: relative;
    margin-top: ${spacing40};
  `}
`
const FormTitle = styled.div`
  ${({
    theme: {
      typography: {
        subheads: {
          medium1: { bundledCSS: subheadMedium1 },
        },
      },
      spacing: { spacing12, spacing28 },
    },
  }) => css`
    ${subheadMedium1};
    margin: ${spacing28} 0 ${spacing12} 0;
  `}
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`

interface StyledSubmitButtonProps {
  successfullySubmitted: boolean
}

const StyledSecondaryCTAButton = styled(
  SecondaryCTAButton
)<StyledSubmitButtonProps>`
  ${({ successfullySubmitted }) => css`
    align-self: center;
    ${successfullySubmitted && 'visibility: none;'}
  `}
`

const Form: FunctionComponent = () => {
  const user = useUser()
  const [data, setData] = useState<Data>({
    uuid: nanoid(),
    category: 'news' as const,
    name: user ? `${user.first_name} ${user.last_name}` : '',
    phone: '',
    description: '',
    files: [],
    anonymous: false,
    device: 'web' as const,
  })
  const [validation, setValidation] = useState<Validation>(defaultValidation)
  const [consent, setConsent] = useState<boolean>(false)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [successfullySubmitted, setSuccessfullySubmitted] =
    useState<boolean>(false)
  const submitButtonRef = useRef<HTMLButtonElement>(null)

  const handleChange = (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { target } = event
    const newValue =
      target.type === 'checkbox'
        ? (target as ChangeEvent<HTMLInputElement>['target']).checked
        : target.value

    const newData = {
      ...data,
      [target.name]: newValue,
    }
    setData(newData)
  }

  const validate = useCallback(() => {
    if (submitted) {
      const validationObj = validateData({ ...data, consent })
      setValidation(validationObj)
    }
  }, [consent, data, submitted])

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setSubmitted(true)
    const validationObj = validateData({
      ...data,
      consent,
    })
    setValidation(validationObj)

    if (isValidSubmission(validationObj)) {
      // Prevent the submit button from being clicked multiple times
      if (submitButtonRef.current) {
        submitButtonRef.current.disabled = true
      }
      const encodedFormBody = encodeFormData(data)
      fetch(uploadUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: encodedFormBody,
      })
        .then((response) => response.json())
        .then((response) => {
          // In case the user didn't upload any files, the response will be null
          // So in this case we don't upload anything but we need to set successfullySubmitted to true
          const urlsToUpload = !!response ? Object.values(response) : []
          Promise.all(
            urlsToUpload.map((url, index) =>
              fetch(url as URL, {
                method: 'PUT',
                body: data.files[index],
              })
            )
          )
            .then(() => {
              setSuccessfullySubmitted(true)
            })
            .catch((err) => {
              console.log('There was an error in the PUT:', err)
              // Re-enable the button in case of an error on the backend
              if (submitButtonRef.current) {
                submitButtonRef.current.disabled = false
              }
            })
        })
        .catch((err) => {
          console.log('There was an error in the POST:', err)
          // Re-enable the button in case of an error on the backend
          if (submitButtonRef.current) {
            submitButtonRef.current.disabled = false
          }
        })
    }
  }

  const addFile = (event: ChangeEvent<HTMLInputElement>) => {
    const newFile = event.target.files?.[0]
    if (newFile) {
      const newData = {
        ...data,
        files: [...data.files, newFile],
      }
      setData(newData)
      if (submitted) {
        const validationObj = validateData({ ...newData, consent })
        setValidation(validationObj)
      }
    }
  }

  const removeFile = (index: number) => {
    const newFiles = data.files.filter((_, i) => i !== index)
    const newData = {
      ...data,
      files: newFiles,
    }
    setData(newData)
    if (submitted) {
      const validationObj = validateData({ ...newData, consent })
      setValidation(validationObj)
    }
  }

  const handleChangeConsent = (event: ChangeEvent<HTMLInputElement>) => {
    const newConsentValue = event.target.checked
    setConsent(newConsentValue)
    if (submitted) {
      const validationObj = validateData({ ...data, consent: newConsentValue })
      setValidation(validationObj)
    }
  }

  return (
    <Container>
      <LeserreporterContext.Provider
        value={{
          data: data,
          handleChange,
          addFile,
          removeFile,
          validation,
          validate,
          consent,
          handleChangeConsent,
        }}>
        <SubmittedOverlay submitted={successfullySubmitted} />
        <FormTitle>Bitte wähle deine Rubrik</FormTitle>
        <StyledForm onSubmit={handleSubmit}>
          <BulletpointChoices />
          {data.category === 'news' && <RedAlert />}
          <UploadFiles />
          <DescriptionTextarea />
          <UserData />
          <AnonymousCheckbox />
          <ConsentCheckbox />
          <StyledSecondaryCTAButton
            ref={submitButtonRef}
            size="large"
            type="submit"
            successfullySubmitted={successfullySubmitted}>
            Bild/Video einsenden
          </StyledSecondaryCTAButton>
        </StyledForm>
      </LeserreporterContext.Provider>
    </Container>
  )
}

export default Form
