//! Reason for the existence of this file:
//! https://styled-components.com/docs/faqs#vendor-prefixes-are-omitted-by-default
//! https://styled-components.com/docs/faqs#shouldforwardprop-is-no-longer-provided-by-default

import { ShouldForwardProp, StyleSheetManager } from 'styled-components'
import isPropValid from '@emotion/is-prop-valid'

// This implements the default behavior from styled-components v5
const shouldForwardProp: ShouldForwardProp<'web'> = (propName, target) => {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName)
  }
  // For other elements, forward all props
  return true
}

const CustomStyleSheetManager: typeof StyleSheetManager = ({ children }) => (
  <StyleSheetManager enableVendorPrefixes shouldForwardProp={shouldForwardProp}>
    {children}
  </StyleSheetManager>
)

export default CustomStyleSheetManager
