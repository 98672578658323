import { LiveEventDataSki, LiveEventDataSkiCompetitor } from './liveEvent'
import {
  formatScheduledAt,
  LIVE_EVENT_DATA_SKI_STAGE_TYPES,
  LIVE_EVENT_DATA_STATUS_TYPES,
  LiveEventTransformedCompetitor,
} from '@utils/formatters/common'
import translate from '@i18n'

function formatCompetitor() {
  return (
    competitor: LiveEventDataSkiCompetitor,
    i: number
  ): LiveEventTransformedCompetitor => {
    // Position 1: Time, Position 2 or 3: Gap
    let time
    if (i <= 0) {
      time = competitor.time
    } else {
      time = competitor.gap
    }
    return {
      logo: competitor.countryLogoSrc,
      name: competitor.name,
      position: competitor.position ? parseInt(competitor.position, 10) : i + 1,
      time: time ? time : '',
    }
  }
}

export type LiveEventTransformedSki = {
  raceTypeName: string // eg. Kombination
  raceGPName: string // eg. Wengen
  competitors: LiveEventTransformedCompetitor[]
  countryLogoSrc: string
  isCancelled: boolean
  isLive: boolean
  isPending: boolean
  isBetweenRuns: boolean
  isFinished: boolean
  genderType: string
  statusTitle: string
  statusInfo?: string
}

export function transformLiveEvent(
  stage: LiveEventDataSki
): LiveEventTransformedSki {
  const { status, sdpCountryLogoSrc, sdpTypeName, sdpGenderType } = stage

  const isCancelled = status === LIVE_EVENT_DATA_STATUS_TYPES.CANCELLED
  const isFinished = status === LIVE_EVENT_DATA_STATUS_TYPES.FINISHED
  const isPending = status === LIVE_EVENT_DATA_STATUS_TYPES.PENDING
  const isBetweenRuns = status === LIVE_EVENT_DATA_STATUS_TYPES.BETWEEN_RUNS

  const isKombination = (
    [LIVE_EVENT_DATA_SKI_STAGE_TYPES.KOMBINATION] as string[]
  ).includes(sdpTypeName)

  const isSlalom = (
    [LIVE_EVENT_DATA_SKI_STAGE_TYPES.SLALOM] as string[]
  ).includes(sdpTypeName)

  const isRiesenslalom = (
    [LIVE_EVENT_DATA_SKI_STAGE_TYPES.RIESENSLALOM] as string[]
  ).includes(sdpTypeName)

  const isLive = stage.sdpIsLive

  const statusTitle = isPending
    ? formatScheduledAt(stage.sdpScheduledAt)
    : isLive
      ? translate('liveEvent.live')
      : ''

  const raceTypeName = stage.sdpTypeName
  const raceGPName = stage.sdpGPName

  // create alternative status label (1.Lauf) when more than one run
  let statusInfo
  if (
    (isLive || isBetweenRuns) &&
    (isKombination || isSlalom || isRiesenslalom)
  ) {
    if (stage.result.resultLists && stage.result.resultLists.length <= 1) {
      statusInfo = translate('liveEvent.skiFirstRun')
    } else {
      statusInfo = translate('liveEvent.skiSecondRun')
    }
  }

  // select the competitors when it has some and take the correct ones
  let competitors: LiveEventDataSkiCompetitor[] = []

  if (
    isPending &&
    stage.result.resultLists &&
    stage.result.resultLists.length
  ) {
    competitors = stage.result.resultLists[0].competitors || []
  }

  if (
    stage.result.resultLists &&
    stage.result.resultLists.length &&
    stage.result.resultLists[0].competitors
  ) {
    // Run 2, but not yet, overall-list >> only overall-list matters
    if (stage.result.resultLists.length === 2) {
      competitors = []
    } else {
      competitors = stage.result.resultLists[0].competitors
    }
  }

  return JSON.parse(
    JSON.stringify({
      isCancelled,
      isFinished,
      isPending,
      isBetweenRuns,
      isLive,
      genderType: sdpGenderType,
      countryLogoSrc: sdpCountryLogoSrc,
      raceTypeName,
      raceGPName,
      statusTitle,
      statusInfo,
      competitors: competitors ? competitors.map(formatCompetitor()) : [],
    })
  )
}
