import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent } from 'react'
import config from '@config'
import EmbeddedContent from '@components/EmbeddedContent'

const {
  usersnap: { widgetUrl },
} = config

interface UsersnapProps {
  id: string
}

const Usersnap: FunctionComponent<UsersnapProps> = ({ id }) => (
  <EmbeddedContent
    url={`${widgetUrl}?usersnapId=${id}`}
    id={id}
    maxHeight={500}
    maxWidth={510}
    idFieldName="usersnapId"
    heightFieldName="usersnapHeight"
  />
)

const widget = {
  kind: ['widget', 'tool', 'usersnap'],
  component: Usersnap,
} as const satisfies CookWidget
export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
