import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import SharingAndCommentWrapper from './SharingAndCommentWrapper'
import { mobileCSS } from '@measures/responsive'

const Wrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing12 },
    },
  }) => css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: ${spacing12};

    ${mobileCSS(css`
      flex-direction: column;
    `)}
  `}
`

const ArticleMetadata: FunctionComponent<{ children?: ReactNode }> = ({
  children,
}) => {
  return <Wrapper>{children}</Wrapper>
}

export { SharingAndCommentWrapper }
export default ArticleMetadata
