import { FunctionComponent } from 'react'
import { AppProps } from 'next/app'
import { CatchAllPageServerProps } from '../../pages/[[...pageUrl]]'

export interface ErrorPageProps {
  Component: AppProps['Component']
  restOfPageProps?: Partial<CatchAllPageServerProps>
}

const ErrorPage: FunctionComponent<ErrorPageProps> = ({
  Component,
  restOfPageProps,
}) => {
  return <Component {...restOfPageProps} />
}

export default ErrorPage
