import EmbeddedContent from '@components/EmbeddedContent'
import config from '@config'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent } from 'react'
import styled from 'styled-components'
import {
  HeimspielLivetickerProps,
  ValidHeimspielLivetickerProps,
} from './types'
import { isValidHeimspielLivetickerProps } from './utils'
import useCMPCookieCategories from '@hooks/useCMPCookieCategories'

type CreateEmbedUrlType = (
  ...args: [matchId: ValidHeimspielLivetickerProps['matchId']]
) => string

const {
  heimspiel: { heimspielLivetickerStaticPageUrl },
} = config

const StyledHeimspielLiveticker = styled.div`
  min-height: 512px;
`

const createEmbeddedUrl: CreateEmbedUrlType = (matchId) =>
  `${heimspielLivetickerStaticPageUrl}?matchId=${matchId}`

const HeimspielLiveticker: FunctionComponent<HeimspielLivetickerProps> = (
  props
) => {
  const allCategoriesEnabled = useCMPCookieCategories(
    'allCategoriesEnabledChanged'
  )

  if (!isValidHeimspielLivetickerProps(props)) {
    return null
  }

  const { matchId } = props

  const url = new URL(createEmbeddedUrl(matchId))

  url.searchParams.set(
    'is_third_party_content_allowed',
    `${allCategoriesEnabled}`
  )

  return (
    <StyledHeimspielLiveticker>
      {allCategoriesEnabled === undefined ? null : (
        <EmbeddedContent bypassCMP={true} maxHeight={500} url={url.href} />
      )}
    </StyledHeimspielLiveticker>
  )
}

const widget = {
  kind: ['widget', 'heimspiel-liveticker'],
  component: HeimspielLiveticker,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
