import { ConfigProps } from '@config/types'

const config = ({ publication, searchUrl }: ConfigProps) =>
  ({
    apiUrl: `${searchUrl}/search`,
    sectionUrl: publication === 'romandie' ? '/fr/chercher/' : '/suche/',
    textHighlightClass: '@blick/search__highlighted-text',
    textHighlightColor: 'rgba(255,204,0,0.5)',
  }) as const satisfies Record<string, unknown>

export default config
