import { FunctionComponent, useCallback, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { desktopCSS, mobileCSS, tabletCSS } from '@measures/responsive'

import Swiper, { ArrowsVisibilityType, SwiperClass } from '@components/Swiper'
import NavigationArrows from '@components/Swiper/NavigationArrows'
import SectionHeader from '@components/SectionHeader'
import JSONRenderer from '@components/JSONRenderer'
import {
  HeroModuleAPIProps,
  HeroModuleRelatedItemsProps,
} from '@widgets/HeroModule'

type RelatedItemsDesktopProps = {
  title: HeroModuleRelatedItemsProps['relatedItemsTitle']
  accentColor: HeroModuleAPIProps['accentColor']
  items: HeroModuleRelatedItemsProps['relatedItems']
}

const StyledSwiper = styled(Swiper)<{ itemsCount: number }>`
  ${({
    theme: {
      spacing: { spacing8, spacing16 },
    },
    itemsCount,
  }) => css`
    margin-top: ${spacing16};

    > .swiper-items-container {
      grid-gap: ${spacing8};
      grid-auto-flow: column;
      grid-template-rows: repeat(${itemsCount > 2 ? 2 : itemsCount}, auto);
      grid-auto-columns: 100%;
    }
  `}
`

const StyledNavigationArrows = styled(NavigationArrows)<{ itemsCount: number }>`
  ${({ itemsCount }) => css`
    ${itemsCount <= 1 &&
    mobileCSS(css`
      display: none;
    `)}
    ${itemsCount <= 2 &&
    tabletCSS(css`
      display: none;
    `)}
    ${itemsCount <= 2 &&
    desktopCSS(css`
      display: none;
    `)}
  `}
`

const RelatedItemsDesktop: FunctionComponent<RelatedItemsDesktopProps> = ({
  title,
  accentColor,
  items,
}) => {
  const swiperRef = useRef<SwiperClass | null>(null)
  const [arrowsVisibility, setArrowsVisibility] =
    useState<ArrowsVisibilityType>()

  const onInit = useCallback(
    (swiper: any) => {
      swiperRef.current = swiper
    },
    [swiperRef]
  )

  const onArrowsVisibility = useCallback((arrow: ArrowsVisibilityType) => {
    setArrowsVisibility(arrow)
  }, [])

  return (
    <>
      {!!title && (
        <SectionHeader
          accentColor={accentColor}
          isSubheader={true}
          title={title}
          arrowsVisibility={arrowsVisibility}>
          <StyledNavigationArrows
            swiperRef={swiperRef}
            arrowsVisibility={arrowsVisibility}
            itemsCount={items?.length ?? 0}
          />
        </SectionHeader>
      )}
      <StyledSwiper
        spaceTopBottom={0}
        slidesPerGroup={2}
        onInit={onInit}
        onArrowsVisibility={onArrowsVisibility}
        slides={items?.map((item) => (
          <JSONRenderer key={item.articleId}>{item}</JSONRenderer>
        ))}
        itemsCount={items?.length ?? 0}
      />
    </>
  )
}

export default RelatedItemsDesktop
