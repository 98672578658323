import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import config from '@config'
import styled, { css } from 'styled-components'
import { LiveEventTransformedHockey } from '@utils/formatters/hockey'
import { LiveEventTransformedSoccer } from '@utils/formatters/soccer'

interface StyledScoreboardTeamLogoProps {
  hasError: boolean
  size: number
}

interface StyledImageProps {
  hasError: boolean
}

export interface ScoreboardProps {
  data: LiveEventTransformedHockey | LiveEventTransformedSoccer
}

export interface ScoreboardTeamLogoProps {
  url: ScoreboardProps['data']['teams'][number]['logoUrl']
  name: ScoreboardProps['data']['teams'][number]['name']
  size: number
}

const {
  backend: { imageUrl },
} = config

const StyledScoreboardTeamLogo = styled.div<StyledScoreboardTeamLogoProps>`
  ${({
    hasError,
    size,
    theme: {
      color: {
        tertiary: { grey400: grey400color },
      },
    },
  }) => css`
    ${hasError &&
    css`
      background-color: ${grey400color};
      border-radius: 50%;
      min-width: ${size}px;
    `}
    height: ${size}px;
    width: ${size}px;
    min-width: ${size}px;
  `}
`

const StyledImage = styled.img<StyledImageProps>`
  ${({ hasError }) =>
    hasError &&
    css`
      display: none;
    `};
  height: 100%;
  width: 100%;
  object-fit: contain;
`

const ScoreboardTeamLogo: FunctionComponent<ScoreboardTeamLogoProps> = ({
  url,
  name,
  size,
}) => {
  const [error, setError] = useState<boolean>(false)
  const imageRef = useRef<HTMLImageElement>(null)

  const onError = useCallback(() => {
    setError(true)
  }, [])

  useEffect(() => {
    const currentImage = imageRef.current
    currentImage?.addEventListener('error', onError)
    return () => {
      currentImage?.removeEventListener('error', onError)
    }
  }, [onError])

  const imageSource = `${imageUrl}${url}?ratio=free&imwidth=${size}`

  return (
    <StyledScoreboardTeamLogo hasError={error} size={size}>
      {url && (
        <StyledImage
          ref={imageRef}
          hasError={error}
          srcSet={`${imageSource}&imdensity=1 1x, ${imageSource}&imdensity=2 2x, ${imageSource}&imdensity=3 3x`}
          alt={name}
          loading="lazy"
        />
      )}
    </StyledScoreboardTeamLogo>
  )
}

export default ScoreboardTeamLogo
