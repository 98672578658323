import { FunctionComponent } from 'react'
import { createImage } from '@utils/schema'
import SchemaHTMLElement from '@components/SchemaHTMLElement'

interface ImageObjectSchemaProps {
  url: string
  width?: number
  height?: number
  caption?: string
}

const ImageObjectSchema: FunctionComponent<ImageObjectSchemaProps> = ({
  url,
  width,
  height,
  caption,
}) => (
  <SchemaHTMLElement
    scriptKey={`image-object-${url}`}
    schemaObject={createImage(url, width, height, caption)}
  />
)

export default ImageObjectSchema
