import {
  FunctionComponent,
  memo,
  useMemo,
  useRef,
  useCallback,
  useEffect,
  useState,
  ReactNode,
} from 'react'
import styled, { css } from 'styled-components'
import isEqualReact from 'fast-deep-equal/es6/react'
import Placeholder from './Placeholder'
import { calculateActualDimensions, generatePictureSources } from './utils'
import { ImageConstraints, OriginalImageDimensions } from './types'
import useViewportType from '@hooks/useViewport/useViewportType'
import useViewportDimensions from '@hooks/useViewport/useViewportDimensions'
import { Crops } from '@utils/cook/types'

export interface FreeRatioImageProps {
  src: string
  alt: string
  originalDimensions: OriginalImageDimensions
  constraints: ImageConstraints
  crops: Crops<'free'>
  loading?: HTMLImageElement['loading']
  isLazy?: boolean
  className?: string
  children?: ReactNode
}

interface StyledPictureProps {
  hasError: boolean
}

const StyledPicture = styled.picture<StyledPictureProps>`
  ${({ hasError }) => {
    if (hasError) {
      return css`
        display: none;
      `
    }

    return css`
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      > img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    `
  }}
`

const FreeRatioImage: FunctionComponent<FreeRatioImageProps> = ({
  src,
  alt,
  originalDimensions,
  constraints,
  crops,
  loading,
  isLazy = true,
  className,
  children,
}) => {
  const freeCrop = crops['free']
  const croppedOriginalDimensions = freeCrop
    ? { width: freeCrop.width, height: freeCrop.height }
    : originalDimensions
  const viewportType = useViewportType()
  const { width: viewportWidth } = useViewportDimensions()

  const actualDimensions = useMemo(
    () =>
      calculateActualDimensions({
        constraints,
        originalDimensions: {
          width: croppedOriginalDimensions.width,
          height: croppedOriginalDimensions.height,
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      viewportType,
      viewportWidth,
      croppedOriginalDimensions.width,
      croppedOriginalDimensions.height,
      constraints,
    ]
  )
  const {
    desktop: { width: desktopWidth },
    tablet: { width: tabletWidth },
    mobile: { width: mobileWidth },
    mobileSmall: { width: mobileSmallWidth },
  } = actualDimensions

  const [error, setError] = useState<boolean>(false)
  const imageRef = useRef<HTMLImageElement>(null)

  const onError = useCallback(() => {
    setError(true)
  }, [])

  useEffect(() => {
    const currentImage = imageRef.current
    currentImage?.addEventListener('error', onError)
    return () => {
      currentImage?.removeEventListener('error', onError)
    }
  }, [onError])

  if (src === '/incoming/31574--missing.png') {
    return (
      <Placeholder dimensions={actualDimensions} className={className}>
        {children}
      </Placeholder>
    )
  }

  const {
    desktop: {
      mediaQuery: mediaQueryDesktop,
      dpr1: pictureSourceDesktopDPR1,
      dpr2: pictureSourceDesktopDPR2,
      dpr3: pictureSourceDesktopDPR3,
    },
    tablet: {
      mediaQuery: mediaQueryTablet,
      dpr1: pictureSourceTabletDPR1,
      dpr2: pictureSourceTabletDPR2,
      dpr3: pictureSourceTabletDPR3,
    },
    mobileRegular: {
      mediaQuery: mediaQueryMobileRegular,
      dpr1: pictureSourceMobileRegularDPR1,
      dpr2: pictureSourceMobileRegularDPR2,
      dpr3: pictureSourceMobileRegularDPR3,
    },
    mobileSmall: {
      mediaQuery: mediaQueryMobileSmall,
      dpr1: pictureSourceMobileSmallDPR1,
      dpr2: pictureSourceMobileSmallDPR2,
      dpr3: pictureSourceMobileSmallDPR3,
    },
  } = generatePictureSources({
    src,
    crops,
    widths: {
      desktop: desktopWidth,
      tablet: tabletWidth,
      mobile: mobileWidth,
      mobileSmall: mobileSmallWidth,
    },
  })

  return (
    <Placeholder dimensions={actualDimensions} className={className}>
      <StyledPicture hasError={error}>
        <source
          media={mediaQueryDesktop}
          srcSet={`${pictureSourceDesktopDPR1} 1x, ${pictureSourceDesktopDPR2} 2x, ${pictureSourceDesktopDPR3} 3x`}
        />
        <source
          media={mediaQueryTablet}
          srcSet={`${pictureSourceTabletDPR1} 1x, ${pictureSourceTabletDPR2} 2x, ${pictureSourceTabletDPR3} 3x`}
        />
        <source
          media={mediaQueryMobileRegular}
          srcSet={`${pictureSourceMobileRegularDPR1} 1x, ${pictureSourceMobileRegularDPR2} 2x, ${pictureSourceMobileRegularDPR3} 3x`}
        />
        <source
          media={mediaQueryMobileSmall}
          srcSet={`${pictureSourceMobileSmallDPR1} 1x, ${pictureSourceMobileSmallDPR2} 2x, ${pictureSourceMobileSmallDPR3} 3x`}
        />
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          ref={imageRef}
          alt={alt}
          width={actualDimensions['desktop']['width']}
          height={actualDimensions['desktop']['height']}
          {...(loading ? { loading } : isLazy ? { loading: 'lazy' } : {})}
        />
      </StyledPicture>
      {children}
    </Placeholder>
  )
}

const MemoizedFreeRatioImage = memo(
  FreeRatioImage,
  (
    { src: prevSrc, children: prevChildren },
    { src: nextSrc, children: nextChildren }
  ) => prevSrc === nextSrc && isEqualReact(prevChildren, nextChildren)
)

MemoizedFreeRatioImage.displayName = 'MemoizedFreeRatioImage'

export default MemoizedFreeRatioImage
