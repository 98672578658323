import config from '@config'
import { breakpoints } from '@measures/responsive'
import {
  ActualImageDimensions,
  ActualImageViewportDimensions,
  GeneratedPictureSources,
  GeneratePictureSourcesProps,
  HandleCropsProps,
  ImageConstraint,
  ImageConstraints,
  OriginalImageDimensions,
  StringifiedCrops,
} from './types'

const sourceMediaQueryDesktop = breakpoints.desktop
const sourceMediaQueryTablet = breakpoints.tablet
const sourceMediaQueryMobileRegular = breakpoints.regularMobile
const sourceMediaQueryMobileSmall = breakpoints.smallMobile

const {
  backend: { imageUrl: backendImageUrl },
} = config

const handleCrops = ({
  desktop,
  tablet,
  mobile,
}: HandleCropsProps): StringifiedCrops => ({
  desktop: desktop
    ? `&x=${desktop.x}&y=${desktop.y}&width=${desktop.width}&height=${desktop.height}`
    : '',
  tablet: tablet
    ? `&x=${tablet.x}&y=${tablet.y}&width=${tablet.width}&height=${tablet.height}`
    : '',
  mobile: mobile
    ? `&x=${mobile.x}&y=${mobile.y}&width=${mobile.width}&height=${mobile.height}`
    : '',
})

const generatePictureSources = ({
  src,
  crops,
  widths,
}: GeneratePictureSourcesProps): GeneratedPictureSources => {
  const baseImageUrl = `${backendImageUrl}${src}`

  const cropDesktop = crops['free']
  const cropTablet = crops['free']
  const cropMobile = crops['free']

  const {
    desktop: stringifiedCropDesktop,
    tablet: stringifiedCropTablet,
    mobile: stringifiedCropMobile,
  } = handleCrops({
    desktop: cropDesktop,
    tablet: cropTablet,
    mobile: cropMobile,
  })

  const {
    desktop: imageWidthDesktop,
    tablet: imageWidthTablet,
    mobile: imageWidthMobile,
    mobileSmall: imageWidthMobileSmall = imageWidthMobile,
  } = widths

  return {
    desktop: {
      mediaQuery: sourceMediaQueryDesktop,
      dpr1: `${baseImageUrl}?imdensity=1&ratio=free${stringifiedCropDesktop}&imwidth=${imageWidthDesktop}`,
      dpr2: `${baseImageUrl}?imdensity=2&ratio=free${stringifiedCropDesktop}&imwidth=${imageWidthDesktop}`,
      dpr3: `${baseImageUrl}?imdensity=3&ratio=free${stringifiedCropDesktop}&imwidth=${imageWidthDesktop}`,
    },
    tablet: {
      mediaQuery: sourceMediaQueryTablet,
      dpr1: `${baseImageUrl}?imdensity=1&ratio=free${stringifiedCropTablet}&imwidth=${imageWidthTablet}`,
      dpr2: `${baseImageUrl}?imdensity=2&ratio=free${stringifiedCropTablet}&imwidth=${imageWidthTablet}`,
      dpr3: `${baseImageUrl}?imdensity=3&ratio=free${stringifiedCropTablet}&imwidth=${imageWidthTablet}`,
    },
    mobileRegular: {
      mediaQuery: sourceMediaQueryMobileRegular,
      dpr1: `${baseImageUrl}?imdensity=1&ratio=free${stringifiedCropMobile}&imwidth=${imageWidthMobile}`,
      dpr2: `${baseImageUrl}?imdensity=2&ratio=free${stringifiedCropMobile}&imwidth=${imageWidthMobile}`,
      dpr3: `${baseImageUrl}?imdensity=3&ratio=free${stringifiedCropMobile}&imwidth=${imageWidthMobile}`,
    },
    mobileSmall: {
      mediaQuery: sourceMediaQueryMobileSmall,
      dpr1: `${baseImageUrl}?imdensity=1&ratio=free${stringifiedCropMobile}&imwidth=${imageWidthMobileSmall}`,
      dpr2: `${baseImageUrl}?imdensity=2&ratio=free${stringifiedCropMobile}&imwidth=${imageWidthMobileSmall}`,
      dpr3: `${baseImageUrl}?imdensity=3&ratio=free${stringifiedCropMobile}&imwidth=${imageWidthMobileSmall}`,
    },
  }
}

const calculateSingleConstraintDimensions = ({
  constraint,
  originalDimensions,
}: {
  constraint: ImageConstraint
  originalDimensions: OriginalImageDimensions
}): ActualImageViewportDimensions => {
  const { width: originalWidth, height: originalHeight } = originalDimensions

  const biggerDimension = originalWidth >= originalHeight ? 'width' : 'height'
  const smallerDimension = originalWidth >= originalHeight ? 'height' : 'width'
  const biggerRatio =
    originalDimensions[smallerDimension] / originalDimensions[biggerDimension]
  const smallerRatio =
    originalDimensions[biggerDimension] / originalDimensions[smallerDimension]

  const actualViewportDimensions = {
    [biggerDimension]: originalDimensions[biggerDimension],
    [smallerDimension]: originalDimensions[smallerDimension],
  } as unknown as Record<'width' | 'height', number>

  if (actualViewportDimensions[biggerDimension] > constraint[biggerDimension]) {
    actualViewportDimensions[biggerDimension] = constraint[biggerDimension]
    actualViewportDimensions[smallerDimension] = Math.floor(
      actualViewportDimensions[biggerDimension] * biggerRatio
    )
  }

  if (
    actualViewportDimensions[smallerDimension] > constraint[smallerDimension]
  ) {
    actualViewportDimensions[smallerDimension] = constraint[smallerDimension]
    actualViewportDimensions[biggerDimension] = Math.floor(
      actualViewportDimensions[smallerDimension] * smallerRatio
    )
  }

  return actualViewportDimensions
}

const calculateActualDimensions = ({
  constraints,
  originalDimensions,
}: {
  constraints: ImageConstraints
  originalDimensions: OriginalImageDimensions
}): ActualImageDimensions => ({
  desktop: calculateSingleConstraintDimensions({
    constraint: constraints['desktop'],
    originalDimensions,
  }),
  tablet: calculateSingleConstraintDimensions({
    constraint: constraints['tablet'],
    originalDimensions,
  }),
  mobile: calculateSingleConstraintDimensions({
    constraint: constraints['mobile'],
    originalDimensions,
  }),
  mobileSmall: calculateSingleConstraintDimensions({
    constraint: constraints['mobileSmall'],
    originalDimensions,
  }),
})

export {
  generatePictureSources,
  calculateActualDimensions,
  calculateSingleConstraintDimensions,
}
