const ALLOWED_SPORTS = {
  soccer: 'soccer',
  hockey: 'hockey',
} as const

const OUTCOME_TYPES = {
  BEST: 'BEST',
  BETTER: 'BETTER',
  GOOD: 'GOOD',
  A_BIT_GOOD: 'A_BIT_GOOD',
  WORST: 'WORST',
  WORSE: 'WORSE',
  BAD: 'BAD',
  A_BIT_BAD: 'A_BIT_BAD',
} as const

export { ALLOWED_SPORTS, OUTCOME_TYPES }
