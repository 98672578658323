import { css } from 'styled-components'

//TODO: do we really need this ???
const globalVideoPlayerStyles = css`
  body.video-overlay-shown {
    background-color: #000;
    overflow: hidden;
  }
`

export default globalVideoPlayerStyles
