import { FunctionComponent } from 'react'
import styled, { css, useTheme } from 'styled-components'
import { desktopCSS, mobileAndTabletCSS } from '@measures/responsive'
import Blink from '@components/Blink'
import SvgIcon from '@components/SvgIcon'
import config from '@config'
import useTrackingUtils from '../useTrackingUtils'
import useIsImmersiveHeaderActive from '@hooks/useIsImmersiveHeaderActive'

const {
  backend: { rootUrl },
} = config

const showReactStrictModeIndicator =
  process.env.NODE_ENV !== 'production' &&
  !process.env.STORYBOOK &&
  process.env.NEXT_PUBLIC_REACT_DEV_MODE === 'strict'

const StyledBlink = styled(Blink)`
  ${({
    theme: {
      typography: {
        subheads: {
          xsmall1: { bundledCSS: xsmall1SubheadsCSS },
        },
      },
      color: {
        primary: { primary02: primary02Color },
        secondary: { green: greenColor },
      },
    },
  }) => css`
    position: relative;
    //! We add this because otherwise the image included produces a
    //! final height of .5px more than the desired
    line-height: 0;
    user-select: none;
    align-self: center;

    ${showReactStrictModeIndicator &&
    css`
      &:after {
        content: 'strict';
        ${xsmall1SubheadsCSS};
        font-size: 9px;
        color: ${primary02Color};

        letter-spacing: 5px;
        background-color: ${greenColor};
        position: absolute;
        text-transform: uppercase;
        text-align: center;
        bottom: 0;
        left: 14px;
        height: 9px;
        width: calc(100% - 36px);
        box-sizing: border-box;
        padding-left: 8px;
        display: block;
      }
    `}
  `}
`

const StyledBlickLogo = styled(SvgIcon)<{ isImmersiveHeaderActive: boolean }>`
  ${({
    theme: {
      spacing: { spacing40, spacing96 },
      color: {
        primary: { primary02: primary02Color },
      },
    },
    isImmersiveHeaderActive,
  }) => css`
    height: 34px;
    max-width: ${spacing96};

    ${desktopCSS(css`
      height: ${spacing40};
      max-width: 110px;
    `)}

    ${isImmersiveHeaderActive &&
    css`
      ${mobileAndTabletCSS(css`
        fill: ${primary02Color};
      `)}
    `}
  `}
`

const Logo: FunctionComponent = () => {
  const isImmersiveHeaderActive = useIsImmersiveHeaderActive()
  const { navbarClickTrackingFn } = useTrackingUtils({
    title: 'Blick logo',
    url: rootUrl,
  })
  const theme = useTheme()

  return (
    <StyledBlink
      href={rootUrl}
      onClick={navbarClickTrackingFn}
      ariaLabel="Blick">
      <StyledBlickLogo
        iconName="logo-blick-2024"
        size={''}
        color={theme.color.primary.blickRed}
        isImmersiveHeaderActive={isImmersiveHeaderActive}
      />
    </StyledBlink>
  )
}

export default Logo
