/**
 * css styles that are commonly used
 */
import { css } from 'styled-components'

export const iconSizeRepeat = css`
  background-size: 32px 32px;
  background-repeat: no-repeat;
  max-height: 32px;
`

export const iconSizeLargeRepeat = css`
  background-size: 40px 40px;
  background-repeat: no-repeat;
  max-height: 40px;
`

export const iconSize = css`
  .jw-icon {
    width: 48px;
    height: 48px;
  }

  .jw-icon-display {
    width: 48px;
    height: 48px;

    .jw-svg-icon {
      width: 40px;
      height: 40px;
      max-height: 40px;
    }
  }

  .jw-svg-icon {
    width: 32px;
    height: 32px;
  }
`
