import config from '@config'
import { StoryAPIProps, ToplistType } from './types'

const {
  recommendation: { apiUrl },
  publication: { publication },
} = config

const getToplist = async (id: string, type: ToplistType) => {
  let responseData = []
  const finalPath =
    type === 'most-read' ? `v2/mostread/${id}/?pub=${publication}` : type
  try {
    responseData = (
      await (
        await fetch(`${apiUrl}/${finalPath}`, {
          credentials: 'include',
        })
      ).json()
    ).data
      ?.filter?.((story: any) => Object.keys(story).length > 0)
      ?.slice(0, 3)
  } catch (err) {
    console.error(err)
  }
  return responseData
}

const isValidToplistStories = (stories?: any[]): stories is StoryAPIProps[] =>
  (stories as any[])?.filter?.((story: any) => Object.keys(story).length > 0)
    ?.length > 2

export { getToplist, isValidToplistStories }
