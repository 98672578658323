import useABTestValue from '@hooks/useABTestValue'
import JSONRenderer from '@components/JSONRenderer'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent } from 'react'
import styled from 'styled-components'

interface Props {
  id: 'containerPiano1' | 'containerPiano2'
}

const StyledDiv = styled.div`
  &:empty {
    display: none;
  }
`

const ContainerPiano: FunctionComponent<Props> = ({ id }) => {
  const widgetStringifiedFeed = useABTestValue(id)

  const widgetFeed = widgetStringifiedFeed
    ? JSON.parse(widgetStringifiedFeed)
    : null

  if (!widgetFeed) {
    return <StyledDiv id={id} />
  }

  return <JSONRenderer>{widgetFeed}</JSONRenderer>
}

const widget = {
  kind: ['widget', 'container-piano'],
  component: ContainerPiano,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
