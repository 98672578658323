import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

import { CommentingItemWrapperProps, CommentType } from './types'

interface ItemWrapperProps {
  type: CommentType
}

const ItemWrapper = styled.div<ItemWrapperProps>`
  ${({
    type,
    theme: {
      spacing: { spacing24, spacing20 },
    },
  }) => css`
    margin-top: ${spacing24};

    &:first-child {
      margin-top: 0;
    }

    ${type === 'answer' &&
    css`
      box-sizing: border-box;
      padding-left: ${spacing20};
      position: relative;
    `}
  `}
`

const CommentingItemWrapper: FunctionComponent<CommentingItemWrapperProps> = ({
  children,
  ...rest
}) => <ItemWrapper {...rest}>{children}</ItemWrapper>

export default CommentingItemWrapper
