import SearchComponents from '@components/Search'
import config from '@config'
import translate from '@i18n'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { useRouter } from 'next/router'
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import {
  SearchResponseAPIProps,
  SearchResultsData,
  SearchWidgetProps,
} from './types'
import { getListItemTeasers, getResultNumberRange, getResults } from './utils'
import styled, { css } from 'styled-components'
import Pagination from '@components/Pagination'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import useURLParam from '@hooks/useURLParam'
import { mobileCSS } from '@measures/responsive'
import SearchPageContext from '@contexts/searchPageContext'
import JSONRenderer from '@components/JSONRenderer'

const {
  search: { sectionUrl },
} = config

const StyledPaginationContainer = styled.div`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    margin: ${spacing16} auto 0;
    width: 100%;
    ${mobileCSS(css`
      margin-top: 0;
    `)}
  `}
`

const {
  Container,
  SearchInput,
  ResultsContainer,
  ResultsStatus,
  ResultsHeader,
  SearchMessage,
} = SearchComponents

const rangeZeroResult = getResultNumberRange(0)

const SearchWidget: FunctionComponent<SearchWidgetProps> = ({ ad: adInfo }) => {
  const queryClient = useQueryClient()
  const router = useRouter()
  const q = useURLParam('q')
  const searchTerm = !!q && typeof q === 'string' ? q : ''

  const [activePage, setActivePage] = useState<number>(0)

  const onNoResultTrack = useCallback<
    TrackingFnType<{ searchTerm: string | undefined }>
  >(({ extraData }) => {
    const { searchTerm } = extraData
    return {
      event: 'search_zero_result',
      eventCategory: 'search',
      eventLabel: searchTerm,
      eventAction: 'zero_result',
      search_term: searchTerm?.toLowerCase(),
      q_search_result_range: rangeZeroResult,
    }
  }, [])
  const trackingOnSearch = useCallback<TrackingFnType<{ searchTerm: string }>>(
    ({ extraData }) => {
      const { searchTerm } = extraData
      return {
        event: 'search',
        eventCategory: 'search',
        eventLabel: searchTerm.toLowerCase(),
        eventAction: 'submitted',
        search_term: searchTerm.toLowerCase(),
      }
    },
    []
  )

  const handleSearchTrack = useTracking(trackingOnSearch)

  const noResultTrack = useTracking(onNoResultTrack)

  const { isLoading, isError, data } = useQuery<SearchResponseAPIProps>({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['searchData', searchTerm, activePage],
    queryFn: () => getResults(searchTerm, activePage, noResultTrack),
    enabled: !!searchTerm,
    gcTime: 0,
  })

  const results = data?.content || []
  const totalPages = data?.total_pages || 0
  const totalElements = data?.total_elements || 0
  const requestPage = data?.page || 0

  const showPagination = !isLoading && !isError && totalPages > 1
  const resultsToShow = results
  const resultsAvailable = !isLoading && !isError && resultsToShow.length > 0
  const showAd = !isLoading && !isError && resultsToShow.length > 2

  const resultsHeader = isLoading
    ? translate('search.loading')
    : totalElements === 1
      ? translate('search.singleSearchResult')
      : translate('search.searchResults', {
          totalElements: `${totalElements}`,
        })

  const updateSearchTerm = (value: string) => {
    const trimmedSearchTerm = value.trim()
    if (trimmedSearchTerm !== '' && trimmedSearchTerm !== searchTerm) {
      setActivePage(0) // always reset to first page when new search term is submitted
      handleSearchTrack({ searchTerm: trimmedSearchTerm })
      router.push(
        {
          pathname: sectionUrl,
          query: { q: trimmedSearchTerm },
        },
        undefined,
        { shallow: true }
      )
    }
  }

  const updatePage = useCallback((page: number) => {
    setActivePage(page - 1)
    window.scrollTo(0, 0)
  }, [])

  const { firstPartOfResults, restOfResults } = useMemo(
    () => getListItemTeasers(resultsToShow, resultsAvailable),
    [resultsToShow, resultsAvailable]
  )

  useEffect(() => {
    queryClient.setQueryData<SearchResultsData>(['searchResults'], {
      totalElements,
      totalPages,
      searchResultRange: getResultNumberRange(totalElements || 0),
      searchTerm,
    })

    return () => {
      queryClient.removeQueries({ queryKey: ['searchResults'], exact: true })
    }
  }, [queryClient, searchTerm, totalElements, totalPages])

  return (
    <SearchPageContext.Provider value={true}>
      <Container>
        <ResultsHeader>
          <SearchInput initialValue={searchTerm} onSubmit={updateSearchTerm} />
          {!!resultsHeader && <ResultsStatus>{resultsHeader}</ResultsStatus>}
        </ResultsHeader>
        <ResultsContainer>
          {((!resultsAvailable && !isLoading) || isError) && (
            <SearchMessage>
              {translate(
                isError ? 'search.errorMessage' : 'search.noResultsExplanation'
              )}
            </SearchMessage>
          )}
          <JSONRenderer>{firstPartOfResults}</JSONRenderer>
          {showAd && (
            <JSONRenderer key={`${searchTerm}${activePage}`}>
              {{
                ...adInfo,
                containerId: `${activePage}`,
              }}
            </JSONRenderer>
          )}
          <JSONRenderer>{restOfResults}</JSONRenderer>
        </ResultsContainer>
        {/* Due to the paginationHfers starting from 1
          we need to make adjustments when setting the
          pagination active & fetch request page */}
        {showPagination && (
          <StyledPaginationContainer>
            <Pagination
              type="button"
              activePage={requestPage + 1}
              totalPages={totalPages}
              setPage={updatePage}
            />
          </StyledPaginationContainer>
        )}
      </Container>
    </SearchPageContext.Provider>
  )
}

const widget = {
  kind: ['widget', 'search'],
  component: SearchWidget,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
