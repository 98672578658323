import { FunctionComponent } from 'react'
import FreeRatioImage from '@components/FreeRatioImage'
import { ImageConstraints } from '@components/FreeRatioImage/types'
import { TeaserStandardHorizontalSponsoredCommercialProps } from '@widgets/TeaserStandardHorizontal'

type SponsoredLogoProps = Exclude<
  TeaserStandardHorizontalSponsoredCommercialProps['commercial']['logo'],
  undefined
>

const CONSTRAINTS = {
  desktop: { width: 72, height: 20 },
  tablet: { width: 72, height: 20 },
  mobile: { width: 72, height: 20 },
  mobileSmall: { width: 72, height: 20 },
} as const satisfies ImageConstraints

const SponsoredLogo: FunctionComponent<SponsoredLogoProps> = ({
  src,
  alt,
  height,
  width,
  crops,
}) => {
  return (
    <FreeRatioImage
      src={src}
      alt={alt}
      crops={crops}
      originalDimensions={{ width, height }}
      constraints={CONSTRAINTS}
    />
  )
}

export default SponsoredLogo
