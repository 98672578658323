import { useRouter } from 'next/router'
import { useCallback, useEffect } from 'react'
import useMount from '@hooks/useMount'
import { PageData, PageMetadata } from '@hooks/usePageMetadata'
import { useQueryClient } from '@tanstack/react-query'
import { HeaderTopics } from './useHeaderTopics'

export type UseExecuteOnClientNavigation = (
  fn: (pageMetadata: PageMetadata, topics: HeaderTopics) => void
) => void

const useExecuteOnClientNavigation: UseExecuteOnClientNavigation = (fn) => {
  const queryClient = useQueryClient()
  const router = useRouter()
  const isMounted = useMount()
  const mountAwareFn = useCallback<
    (url: string, { shallow }: { shallow: boolean }) => void
  >(
    (_url, { shallow }) => {
      //! Do not execute this actions when shallow navigation happens
      if (shallow) {
        return
      }

      if (isMounted()) {
        const pageData = queryClient.getQueryData<PageData>(['page'])
        const pageMetadata = pageData?.metadata as PageMetadata
        const headerTopics = (pageData?.topics || []) as HeaderTopics

        fn(pageMetadata, headerTopics)
      }
    },
    [fn, isMounted, queryClient]
  )

  useEffect(() => {
    router.events.on('routeChangeComplete', mountAwareFn)

    return () => {
      router.events.off('routeChangeComplete', mountAwareFn)
    }
  }, [router, mountAwareFn])
}

export default useExecuteOnClientNavigation
