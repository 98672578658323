import { createContext } from 'react'
import type { Dayjs } from '@utils/date'

interface CalendarDay {
  day: any
  isSelectable: boolean
  isOfSelectedMonth: boolean
  isSelected: boolean
  dayUrl: string
}

interface SelectedMonth {
  month: string
  year: string
}

export interface WebarchiveCalendarContextType {
  nextMonth: Dayjs | null
  previousMonth: Dayjs
  days: CalendarDay[]
  selectedMonth: SelectedMonth
  onMonthSelect: (month: Dayjs) => void
  selectedDay: Dayjs
}

const WebarchiveCalendarContext = createContext<
  WebarchiveCalendarContextType | Record<string, unknown>
>({})

export default WebarchiveCalendarContext
