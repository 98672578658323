import {
  useEffect,
  FunctionComponent,
  useState,
  useCallback,
  useRef,
} from 'react'
import Script from 'next/script'
import { useQueryClient, QueryClient } from '@tanstack/react-query'
import { getLocalStorageItem, setLocalStorageItem } from '@utils/localStorage'
import usePageMetadata, { PageMetadata } from '@hooks/usePageMetadata'
import useExecuteOnClientNavigation from '@hooks/useExecuteOnClientNavigation'
import config from '@config'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import useIsRiAdTagManagerScriptLoadError from '@hooks/useIsRiAdTagManagerScriptLoadError'
import useIsRiAdTagManagerScriptLoadSuccess from '@hooks/useIsRiAdTagManagerScriptLoadSuccess'

const {
  abTest: { windowKey },
  backend: { deploymentEnv },
  notifications: { onesignalSdkUrl, appId, playerIdStorageKey },
  oneSignal: { localStorageTagsKey, numberOfPageVisitsToSendTag },
} = config

const logOneSignal = (queryClient: QueryClient, ...args: any): void => {
  const showOneSignalLogs =
    queryClient.getQueryData<'show'>([windowKey, 'showOneSignalLogs']) ===
    'show'

  if (showOneSignalLogs) {
    console.log('OneSignal: ', ...args)
  }
}

const getSectionUniqueNames = (pageMetadata: PageMetadata): string[] =>
  // if there are more than 3 section unique names, we only want to use second and third
  // (this is because the first section unique name is the homepage, which is not a topic)
  // (and we dont want to go deeper than 3 levels of sections)
  pageMetadata?.sectionUniqueNames?.slice(1, 3) ?? []

const OneSignalManager: FunctionComponent = () => {
  const [isOneSignalSDKLoaded, setIsOneSignalSDKLoaded] =
    useState<boolean>(false)
  const firstTimeTagUserCalledRef = useRef<boolean>(true)

  const queryClient = useQueryClient()
  const pageMetadata = usePageMetadata()

  const trackingNotificationPermissionChange = useCallback<
    TrackingFnType<{ method: string; push_action: string }>
  >(
    ({ extraData }) => ({
      event: 'manage_push',
      method: extraData.method,
      push_action: extraData.push_action,
    }),
    []
  )

  const handleNotificationPermissionChangeTrack = useTracking(
    trackingNotificationPermissionChange
  )

  const sendOneSignalTag = useCallback(
    (key: string, value: number) => {
      if (window.OneSignal) {
        window.OneSignal.push(() => {
          window.OneSignal.isPushNotificationsEnabled((isEnabled: boolean) => {
            logOneSignal(
              queryClient,
              'OneSignal isPushNotificationsEnabled: ',
              isEnabled
            )

            if (isEnabled) {
              window.OneSignal.sendTag(key, value, (tagsSent: string) => {
                // Callback called when tags have finished sending
                logOneSignal(queryClient, 'OneSignal tags sent: ', tagsSent)
              })
            }
          })
        })
      }
    },
    [queryClient]
  )

  const increasePageVisits = useCallback((page: string) => {
    const osTags =
      (getLocalStorageItem(localStorageTagsKey) as Record<string, number>) || {}
    const pageVisits = (osTags[page] ?? 0) + 1

    osTags[page] = pageVisits

    setLocalStorageItem(localStorageTagsKey, osTags)

    return pageVisits
  }, [])

  const tagUserWithPageTopics = useCallback(
    (pageMetadata: PageMetadata) => {
      const sectionUniqueNames = getSectionUniqueNames(pageMetadata)

      if (sectionUniqueNames?.length) {
        sectionUniqueNames.forEach((page) => {
          const pageVisits = increasePageVisits(page)

          if (pageVisits >= numberOfPageVisitsToSendTag) {
            sendOneSignalTag(page, pageVisits)
          }
        })
      }
    },
    [increasePageVisits, sendOneSignalTag]
  )

  useEffect(() => {
    if (firstTimeTagUserCalledRef.current) {
      firstTimeTagUserCalledRef.current = false

      tagUserWithPageTopics(pageMetadata)
    }
  }, [pageMetadata, tagUserWithPageTopics])

  useExecuteOnClientNavigation(tagUserWithPageTopics)

  const onOneSignalSDKLoaded = useCallback(() => {
    setIsOneSignalSDKLoaded(true)
  }, [])

  useEffect(() => {
    if (isOneSignalSDKLoaded) {
      window.OneSignal.push(() => {
        window.OneSignal.on('subscriptionChange', (isSubscribed: boolean) => {
          if (isSubscribed) {
            window.OneSignal.getUserId().then((userId: string) => {
              setLocalStorageItem(playerIdStorageKey, userId)
            })
          } else {
            setLocalStorageItem(playerIdStorageKey, '')
          }
        })

        // Event occurs when the user's subscription changes to a new value.
        window.OneSignal.on(
          'notificationPermissionChange',
          (permissionChange: { to: 'granted' | 'denied' | 'default' }) => {
            // If to is "granted", the user has allowed notifications.
            // If to is "denied", the user has blocked notifications.
            // If to is "default", the user has clicked the 'X' button to dismiss the prompt.
            const currentPermission = permissionChange.to

            handleNotificationPermissionChangeTrack({
              method: 'native prompt',
              push_action:
                currentPermission === 'granted'
                  ? 'allow'
                  : currentPermission === 'denied'
                    ? 'block'
                    : 'close',
            })

            logOneSignal(
              queryClient,
              'New permission state:',
              currentPermission
            )
          }
        )

        // popoverAllowClick - The "Continue" button on the Slide Prompt was clicked.
        window.OneSignal.on('popoverAllowClick', () => {
          handleNotificationPermissionChangeTrack({
            method: 'slide prompt',
            push_action: 'activate',
          })

          logOneSignal(queryClient, 'Slide Prompt click result: activate')
        })

        // popoverCancelClick - The "No Thanks" button on the Slide Prompt was clicked.
        window.OneSignal.on('popoverCancelClick', () => {
          handleNotificationPermissionChangeTrack({
            method: 'slide prompt',
            push_action: 'close',
          })

          logOneSignal(queryClient, 'Slide Prompt click result: close')
        })

        window.OneSignal.init({
          appId,
        })
      })
    }
  }, [
    isOneSignalSDKLoaded,
    queryClient,
    handleNotificationPermissionChangeTrack,
  ])

  return [
    useIsRiAdTagManagerScriptLoadSuccess(),
    useIsRiAdTagManagerScriptLoadError(),
  ].some(Boolean) && ['stg', 'prod'].includes(deploymentEnv) ? (
    <Script
      id="one-signal-sdk"
      strategy="afterInteractive"
      defer={true}
      src={onesignalSdkUrl}
      onLoad={onOneSignalSDKLoaded}
    />
  ) : null
}

export default OneSignalManager
