import {
  DehydratedState,
  QueryClient,
  QueryClientConfig,
  QueryKey,
} from '@tanstack/react-query'

const STRUCTURAL_SHARING_DISABLED_QUERIES: QueryKey[] = [
  ['sidebar-ad-ref'],
  ['active-animated-preview'],
  ['animated-previews-list'],
  ['full-screen-portal-rendered-node-ref'],
  ['modal-overlay-portal-rendered-node-ref'],
  ['commenting-widget-ref'],
  ['cliffhanger-widget-ref'],
  ['trigger-jwplayer-script-loading-func'],
]

const defaultConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      gcTime: Infinity,
      staleTime: Infinity,
      retry: false,
      networkMode: 'offlineFirst',
    },
    mutations: {
      gcTime: Infinity,
      networkMode: 'offlineFirst',
    },
  },
}

//! Because on certain environments (test, stg, prod) we are caching
//! the server response of the getServerSideProps() method, depending
//! on the page visited, the timestamp of the query data generated at
//! that point in time might be earlier than the timestamp of a previous
//! url cached request, even if the current request from the client is newer.
//! This is causing problems when react-query is trying to merge the query data
//! provide from the server after the cache hydration on the client, as it in
//! some cases ignores the data of the newer request in favor of the existing data
//! (because the cached timestamp is older that the one on the client).
const dehydrateQueryStateWithNowTimestamp = (
  dehydratedQueryState?: DehydratedState
): DehydratedState | undefined =>
  dehydratedQueryState
    ? {
        ...dehydratedQueryState,
        queries: dehydratedQueryState.queries.map((query) => ({
          ...query,
          state: { ...query.state, dataUpdatedAt: Date.now() },
        })),
      }
    : dehydratedQueryState

const disableStructuralSharingForQueries = (queryClient: QueryClient) => {
  STRUCTURAL_SHARING_DISABLED_QUERIES.forEach((query) => {
    queryClient.setQueryDefaults(query, {
      structuralSharing: false,
    })
  })
}

export {
  defaultConfig,
  dehydrateQueryStateWithNowTimestamp,
  disableStructuralSharingForQueries,
}
