import { ConfigProps } from '@config/types'

const config = ({ publication }: ConfigProps) =>
  ({
    googleNewsUrl:
      publication === 'romandie'
        ? 'https://news.google.com/publications/CAAqBwgKMMSapgswrKW-Aw'
        : 'https://news.google.com/publications/CAAiEO0blL6qrYjj-vkJj2W5ymIqFAgKIhDtG5S-qq2I4_r5CY9lucpi',
  }) as const satisfies Record<string, unknown>

export default config
