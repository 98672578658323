/**
 * https://developer.jwplayer.com/jwplayer/docs/jw8-css-skin-reference#display
 */
import { css } from 'styled-components'
import { iconSize, iconSizeLargeRepeat } from './common'

import svgSprite from '@assets/images/video-player-sprite.svg'

export const display = css`
  .jw-display {
    ${iconSize}
    .jw-display-icon-container {
      margin: 0 28px;
    }

    .jw-icon-display {
      background-color: #00000080;
      border-radius: 24px;
    }

    /** unwanted icons */

    .jw-display-icon-rewind,
    .jw-display-icon-next {
      display: none;
    }

    /** updating icons */

    .jw-svg-icon-play path,
    .jw-svg-icon-pause path,
    .jw-svg-icon-replay path {
      display: none;
    }

    .jw-svg-icon-play {
      background: url(${svgSprite}#play);
      ${iconSizeLargeRepeat}
    }

    .jw-svg-icon-pause {
      background: url(${svgSprite}#pause);
      ${iconSizeLargeRepeat}
    }

    .jw-svg-icon-replay {
      background: url(${svgSprite}#arrow-rotate-left);
      ${iconSizeLargeRepeat}
    }
  }

  &.jw-flag-user-inactive {
    .jw-display {
      display: none;
    }
  }
`
