import { default as Default } from './Default'
import { default as Filler } from './Filler'
import { default as Logo } from './Logo'

const exports = {
  Default,
  Filler,
  Logo,
}

export default exports
