import { FunctionComponent, useCallback, useState } from 'react'
import { TextTrackProps } from '../../types'
import useOnVTTCueChange, {
  UseOnVTTCueChange,
} from '../TextTracks/useOnVTTCueChange'
import useVTTCuesFromPlaylistTrack from '../TextTracks/useVTTCuesFromPlaylistTrack'
import CaptionsElements from './CaptionsElements'

type VodCaptionsProps = Omit<TextTrackProps, 'onGraphicsCueChange'> & {
  disabled?: boolean
}

const VodCaptions: FunctionComponent<VodCaptionsProps> = ({
  player,
  disabled,
  onCaptionsCueChange,
}) => {
  const [captions, setCaptions] = useState<string[]>()

  const vttCues = useVTTCuesFromPlaylistTrack({ player, trackKind: 'captions' })

  const onCueChange = useCallback<
    Parameters<UseOnVTTCueChange>[0]['onCueChange']
  >(
    (text) => {
      setCaptions(text)
      onCaptionsCueChange?.(text)
    },
    [onCaptionsCueChange]
  )

  useOnVTTCueChange({ player, vttCues, onCueChange })

  return disabled ? null : <CaptionsElements captions={captions} />
}

export default VodCaptions
