import { useRef, useCallback, useEffect } from 'react'
import useTracking from '@hooks/useTracking'
import {
  VideoTrackingParams,
  VolatileTrackingData,
  PlayerType,
  UseVideoPlayerPropsInput,
  UseTrackingOutputProps,
} from '@widgets/Video/types'
import {
  videoImpressionHandler,
  videoPlayHandler,
  videoPlayerSuccessHandler,
  videoAdRequestHandler,
  videoAdStartedHandler,
  videoAdEndedHandler,
  videoProgressHandler,
  videoProgress5SecIntervalHandler,
  videoProgress10SecIntervalHandler,
  videoQuartileHandler,
  videoEndedHandler,
  videoErrorHandler,
  videoMuteHandler,
  videoUnmuteHandler,
  videoCaptionsEnableHandler,
  videoCaptionsDisableHandler,
  videoCloseFloatingPlayerHandler,
  videoToFloatingPlayerHandler,
  videoOrientationChangeHandler,
} from './eventMapper'
import { getPlayerStatus, extractErrorObj } from './utils'
import { captureException } from '@utils/sentry'
import { CurrentTimeParam } from '@components/Video/VideoPlayer/JwLibrary/types'
import { ViewportOrientationValues } from '@hooks/useViewport/types'
import { PLAYER_MODES, PLAYER_TYPES } from '@widgets/Video/utils'

export type UseVideoTrackingInputProps = Pick<
  UseVideoPlayerPropsInput,
  | 'isBlickTV'
  | 'position'
  | 'autoplay'
  | 'videoId'
  | 'jwVideoId'
  | 'title'
  | 'duration'
  | 'isInScoreboardContent'
  | 'widgetId'
> & {
  playerType: PlayerType
  isInViewport?: boolean | null
  startMuted?: boolean
  isInBlickBitesFastlane?: boolean
  biteId?: string
  allowMultipleOnEvents?: boolean
}

const useVideoTracking = ({
  isBlickTV,
  isInBlickBitesFastlane,
  autoplay,
  widgetId,
  position,
  playerType = PLAYER_TYPES.VOD,
  videoId = '',
  jwVideoId = '',
  title: videoTitle = '',
  biteId,
  duration = 0,
  isInScoreboardContent,
  isInViewport,
  startMuted = false,
  allowMultipleOnEvents = false,
}: UseVideoTrackingInputProps): UseTrackingOutputProps => {
  const previousCurrentTime = useRef<number>(-1)

  const previousWatchedEventFiringTime = useRef<number>(0)
  const previousWatched5EventFiringTime = useRef<number>(0)
  const previousWatched10EventFiringTime = useRef<number>(0)

  const shouldTriggerOnPlaySuccess = useRef<boolean>(true)
  const hasEnded = useRef<boolean>(false)

  const trackImpression = useTracking(videoImpressionHandler)
  const trackPlay = useTracking(videoPlayHandler)
  const trackPlayerSuccess = useTracking(videoPlayerSuccessHandler)
  const trackAdRequest = useTracking(videoAdRequestHandler)
  const trackAdStarted = useTracking(videoAdStartedHandler)
  const trackAdEnded = useTracking(videoAdEndedHandler)
  const trackProgress = useTracking(videoProgressHandler)
  const trackProgress5SecInterval = useTracking(
    videoProgress5SecIntervalHandler
  )
  const trackProgress10SecInterval = useTracking(
    videoProgress10SecIntervalHandler
  )
  const trackQuartileCompletion = useTracking(videoQuartileHandler)
  const trackEnded = useTracking(videoEndedHandler)
  const trackError = useTracking(videoErrorHandler)
  const trackMute = useTracking(videoMuteHandler)
  const trackUnmute = useTracking(videoUnmuteHandler)
  const trackCaptionsEnabled = useTracking(videoCaptionsEnableHandler)
  const trackCaptionsDisabled = useTracking(videoCaptionsDisableHandler)
  const trackCloseFloatingPlayer = useTracking(videoCloseFloatingPlayerHandler)
  const trackToFloatingPlayer = useTracking(videoToFloatingPlayerHandler)
  const trackOrientationChange = useTracking(videoOrientationChangeHandler)

  const trackingParamsRef = useRef<VolatileTrackingData>({
    quartileState: 0,
    secondsWatched: 0,
    videoTitle,
    casting: false,
  })

  const isInViewportRef = useRef<boolean | null | undefined>(isInViewport)

  useEffect(() => {
    isInViewportRef.current = isInViewport
  }, [isInViewport])

  const getVolatileData = useCallback(
    () => ({
      ...trackingParamsRef.current,
    }),
    []
  )

  const getStaticData = useCallback(() => {
    return {
      autoplay: !!autoplay,
      duration,
      playerType,
      videoId,
      jwVideoId,
      ...(isBlickTV ? { position } : {}),
      isInMatchcenter: !!isInScoreboardContent,
      biteId,
    }
  }, [
    autoplay,
    duration,
    isBlickTV,
    playerType,
    position,
    videoId,
    jwVideoId,
    isInScoreboardContent,
    biteId,
  ])

  const getVideoTrackingParameters = useCallback(
    (options?: { withoutPlayerStatus?: boolean }): VideoTrackingParams => {
      const { withoutPlayerStatus } = options || {}
      const defaultPlayerStatus = {
        audio: !startMuted,
        captions: true,
        playerMode: PLAYER_MODES.INLINE,
        floating: false,
      }

      const playerStatus = withoutPlayerStatus
        ? null
        : getPlayerStatus({
            widgetId,
            isInBlickBitesFastlane,
          })

      return {
        ...getVolatileData(),
        ...getStaticData(),
        ...(playerStatus || defaultPlayerStatus),
      }
    },
    [
      getStaticData,
      getVolatileData,
      widgetId,
      startMuted,
      isInBlickBitesFastlane,
    ]
  )

  // video-impression
  const onImpression = useCallback(() => {
    trackImpression({
      ...getVideoTrackingParameters({
        withoutPlayerStatus: true,
      }),
    })
  }, [getVideoTrackingParameters, trackImpression])

  // video_play_click
  const onVideoPosterClick = useCallback(() => {
    trackPlay({
      ...getVideoTrackingParameters({ withoutPlayerStatus: true }),
    })
  }, [getVideoTrackingParameters, trackPlay])

  const onPlaySuccess = useCallback(() => {
    if (shouldTriggerOnPlaySuccess.current || allowMultipleOnEvents) {
      shouldTriggerOnPlaySuccess.current = false

      trackPlayerSuccess({
        ...getVideoTrackingParameters(),
      })
    }
  }, [allowMultipleOnEvents, getVideoTrackingParameters, trackPlayerSuccess])

  // video_ads_ad_request
  const onAdRequest = useCallback(() => {
    trackAdRequest({
      ...getVideoTrackingParameters(),
    })
  }, [getVideoTrackingParameters, trackAdRequest])

  // video_ads_ad_started
  const onAdStarted = useCallback(() => {
    trackAdStarted({
      ...getVideoTrackingParameters(),
    })
  }, [getVideoTrackingParameters, trackAdStarted])

  // video_ads_ad_end
  const onAdEnded = useCallback(() => {
    trackAdEnded({
      ...getVideoTrackingParameters(),
    })
  }, [getVideoTrackingParameters, trackAdEnded])

  const onSeek = useCallback<UseTrackingOutputProps['onSeek']>((data) => {
    previousCurrentTime.current = Math.ceil(data?.offset)
  }, [])

  const trackVideoWatched = useCallback(() => {
    const secondsWatched = trackingParamsRef.current.secondsWatched

    //! Because the event is not always triggered on *every* second
    //! and we only deal with Integer seconds, it might be possible
    //! that we jump from e.g. modulo 14 to modulo 1, and we miss
    //! modulo 0, in which case we wanted to trigger the event.
    if (
      secondsWatched !== 0 &&
      secondsWatched - previousWatchedEventFiringTime.current >= 15
    ) {
      previousWatchedEventFiringTime.current =
        secondsWatched % 15 === 0
          ? secondsWatched
          : secondsWatched - (secondsWatched % 15)

      trackProgress({
        ...getVideoTrackingParameters(),
      })
    }
  }, [getVideoTrackingParameters, trackProgress])

  const trackVideoWatched5 = useCallback(() => {
    const secondsWatched = trackingParamsRef.current.secondsWatched

    if (
      secondsWatched !== 0 &&
      secondsWatched - previousWatched5EventFiringTime.current >= 5
    ) {
      previousWatched5EventFiringTime.current =
        secondsWatched % 5 === 0
          ? secondsWatched
          : secondsWatched - (secondsWatched % 5)

      trackProgress5SecInterval({
        ...getVideoTrackingParameters(),
      })
    }
  }, [getVideoTrackingParameters, trackProgress5SecInterval])

  const trackVideoWatched10 = useCallback(() => {
    const secondsWatched = trackingParamsRef.current.secondsWatched

    if (
      secondsWatched !== 0 &&
      secondsWatched - previousWatched10EventFiringTime.current >= 10
    ) {
      previousWatched10EventFiringTime.current =
        secondsWatched % 10 === 0
          ? secondsWatched
          : secondsWatched - (secondsWatched % 10)

      trackProgress10SecInterval({
        ...getVideoTrackingParameters(),
      })
    }
  }, [getVideoTrackingParameters, trackProgress10SecInterval])

  // video_watched
  const onProgress = useCallback(
    (data: CurrentTimeParam) => {
      const currentTime = Math.ceil(data.currentTime)

      // previousCurrentTime was not set yet
      if (previousCurrentTime.current === -1) {
        previousCurrentTime.current = currentTime
        return
      }

      // keeps how many seconds pass from previous `onProgress` call
      const currentSecondsWatched = currentTime - previousCurrentTime.current

      // total video seconds watched
      trackingParamsRef.current.secondsWatched += currentSecondsWatched

      switch (playerType) {
        case PLAYER_TYPES.VOD:
          trackVideoWatched10()
          break
        case PLAYER_TYPES.BITE:
          trackVideoWatched5()
          break
        default:
          trackVideoWatched()
          break
      }

      previousCurrentTime.current = currentTime
    },
    [playerType, trackVideoWatched, trackVideoWatched5, trackVideoWatched10]
  )

  const setCasting = useCallback((isCasting: boolean) => {
    trackingParamsRef.current.casting = isCasting
  }, [])

  // video_5 | video_25 | video_50 | video_75 | video_95 | video_end
  const onQuartileCompletion = useCallback(
    (payload: { percent: number }) => {
      trackingParamsRef.current.quartileState = payload.percent

      trackQuartileCompletion({
        ...getVideoTrackingParameters(),
      })
    },
    [getVideoTrackingParameters, trackQuartileCompletion]
  )

  // video_end
  const onEnd = useCallback(() => {
    // reset previousCurrentTime to the initial state
    previousCurrentTime.current = -1

    if (!hasEnded.current || allowMultipleOnEvents) {
      hasEnded.current = true
      trackingParamsRef.current.quartileState = 'end'

      trackEnded({
        ...getVideoTrackingParameters(),
      })
    }
  }, [allowMultipleOnEvents, getVideoTrackingParameters, trackEnded])

  // video_error
  const onError = useCallback(
    (errorData: any) => {
      const { data, message, code, type } = extractErrorObj(errorData)
      const errorObj = data
        ? {
            errorCode: data[0]?.error_code ?? '',
            errorMessage: data[0]?.message ?? message ?? '',
            errorLog: JSON.stringify({ data, message }),
          }
        : {
            errorCode: code,
            errorMessage: message,
            errorLog: JSON.stringify({ type, message, code }),
          }

      console.error('Video Error:', errorObj)

      captureException(new Error(`JW Player Error ${code}. ${message}`), {
        extra: errorData,
        tags: {
          section: 'video',
        },
      })

      // Send error to Google Analytics
      trackError({
        ...getVideoTrackingParameters(),
        ...errorObj,
      })
    },
    [getVideoTrackingParameters, trackError]
  )

  // video_audio_on
  const onMute = useCallback(() => {
    if (isInViewportRef.current) {
      trackMute({
        ...getVideoTrackingParameters(),
      })
    }
  }, [getVideoTrackingParameters, trackMute])

  // video_audio_off
  const onUnmute = useCallback(() => {
    trackUnmute({
      ...getVideoTrackingParameters(),
    })
  }, [getVideoTrackingParameters, trackUnmute])

  // video_captions_on
  const onCaptionsEnabled = useCallback(() => {
    trackCaptionsEnabled({
      ...getVideoTrackingParameters(),
    })
  }, [getVideoTrackingParameters, trackCaptionsEnabled])

  // video_captions_off
  const onCaptionsDisabled = useCallback(() => {
    trackCaptionsDisabled({
      ...getVideoTrackingParameters(),
    })
  }, [getVideoTrackingParameters, trackCaptionsDisabled])

  const onOrientationChange = useCallback(
    (orientation: ViewportOrientationValues) => {
      trackOrientationChange({
        ...getVideoTrackingParameters(),
        orientation,
      })
    },
    [getVideoTrackingParameters, trackOrientationChange]
  )

  const setNewHeadline = useCallback((headline: string) => {
    trackingParamsRef.current.videoTitle = headline
  }, [])

  const onPip = useCallback(
    ({ pip }: { pip: boolean }) => {
      if (pip) {
        trackToFloatingPlayer({
          ...getVideoTrackingParameters(),
          floating: !!pip,
        })
      } else {
        trackCloseFloatingPlayer({
          ...getVideoTrackingParameters(),
          floating: !!pip,
        })
      }
    },
    [
      getVideoTrackingParameters,
      trackCloseFloatingPlayer,
      trackToFloatingPlayer,
    ]
  )

  return {
    onImpression,
    onPlaySuccess,
    onAdRequest,
    onAdStarted,
    onAdEnded,
    onProgress,
    onQuartileCompletion,
    onEnd,
    onSeek,
    onError,
    onMute,
    onUnmute,
    onCaptionsEnabled,
    onCaptionsDisabled,
    onOrientationChange,
    setNewHeadline,
    setCasting,
    onPip,
    onVideoPosterClick,
  }
}

export default useVideoTracking
