import Blink from '@components/Blink'
import SvgIcon from '@components/SvgIcon'
import { FunctionComponent, useCallback } from 'react'
import styled, { css } from 'styled-components'
import config from '@config'
import useHeaderSearchBar from '@hooks/useHeaderSearchBar'
import { desktopCSS } from '@measures/responsive'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import useSubscriptionStatus from '@hooks/useSubscriptionStatus'

const {
  subscriptions: { offerPagePath: aboPagePath, blickPlusSectionUrl },
} = config

const StyledLink = styled(Blink)<{ isLinkVisible: boolean }>`
  ${({
    theme: {
      color: {
        primary: { blickRed: blickRedColor },
      },
      spacing: { spacing4 },
    },
    isLinkVisible,
  }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 24px;
    background: ${blickRedColor};
    border-radius: 20px;
    user-select: none;
    appearance: none;
    text-decoration: none;

    ${desktopCSS(css`
      width: 120px;
      gap: ${spacing4};
    `)}

    ${isLinkVisible &&
    css`
      ${desktopCSS(css`
        width: 0;
      `)}
    `}
  `}
`

const Label = styled.div`
  ${({
    theme: {
      typography: {
        subheads: {
          xsmall1: { bundledCSS: xsmall1SubheadsCSS },
        },
      },
      color: {
        primary: { blickRed: blickRedColor, primary02: primary02Color },
      },
    },
  }) => css`
    display: none;
    ${xsmall1SubheadsCSS};
    background: ${blickRedColor};
    color: ${primary02Color};

    ${desktopCSS(css`
      display: block;
    `)}
  `}
`

const OfferPageLink: FunctionComponent = () => {
  const { isSearchBarCollapsed } = useHeaderSearchBar()
  const { subscriptionStatus } = useSubscriptionStatus()

  const trackingOnClick = useCallback<TrackingFnType>(
    () => ({
      event: 'plus_lp_click',
      button_label: 'Bplus',
    }),
    []
  )

  const handleTrack = useTracking(trackingOnClick)

  return (
    <StyledLink
      isLinkVisible={!isSearchBarCollapsed}
      href={
        subscriptionStatus === 'subscribed' ? blickPlusSectionUrl : aboPagePath
      }
      onClick={handleTrack}>
      <SvgIcon iconName="blick-plus-logo" size={24} />
      <Label>
        {subscriptionStatus === 'subscribed' ? 'Alle Artikel' : 'Abonnieren'}
      </Label>
    </StyledLink>
  )
}

export default OfferPageLink
