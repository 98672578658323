import AnswersAPIContext from '@contexts/commenting/answerApiContext'
import APIContext from '@contexts/commenting/apiContext'
import GenericContext from '@contexts/commenting/genericContext'
import useUser from '@hooks/useUser'
import translate from '@i18n'
import { FunctionComponent, useContext, useState } from 'react'
import CommentingActionPopup from './CommentingActionPopup'
import CommentingConfirmationMessage from './CommentingConfirmationMessage'
import CommentingItem from './CommentingItem'
import CommentingItemWrapper from './CommentingItemWrapper'
import ListOfAnswers from './ListOfAnswers'
import { CommentProps } from './types'
import { getTitle } from './utils'

const Comment: FunctionComponent<CommentProps> = ({
  postingEnabled,
  comment,
}) => {
  const {
    id,
    created,
    body,
    answers,
    author_type,
    user: {
      first_name,
      last_name,
      status_id: commentAuthorStatusId,
      sub: commentAuthorSub,
      gender,
    },
    number_of_answers,
  } = comment

  const userData = useUser()
  const currentUserSub = userData?.sub || ''

  const { reportComment, deleteComment } = useContext(APIContext)
  const { requireValidUser } = useContext(GenericContext)
  const { postedAnswer } = useContext(AnswersAPIContext)

  const [isPostingAnswer, setIsPostingAnswer] = useState(false)

  const [isDeletingComment, setIsDeletingComment] = useState(false)
  const [isReportingComment, setIsReportingComment] = useState(false)

  const [
    reportingConfirmationMessageShown,
    setReportingConfirmationMessageShown,
  ] = useState(false)

  const isOwnItem = currentUserSub === commentAuthorSub

  return (
    <CommentingItemWrapper type="comment" itemId={id}>
      <CommentingItem
        type="comment"
        authorType={author_type}
        gender={gender}
        deleting={isDeletingComment}
        isOwnItem={isOwnItem}
        authorName={getTitle({
          name: `${first_name} ${last_name}`,
          statusId: commentAuthorStatusId,
        })}
        datePublished={created}
        textContent={body}
        allowReports
        allowReplies={postingEnabled}
        onDelete={requireValidUser(() => setIsDeletingComment(true))}
        onReport={requireValidUser(() => setIsReportingComment(true))}
        onReply={requireValidUser(() => {
          if (!postedAnswer) {
            setIsPostingAnswer(true)
          }
        })}
        commentOrAnswer={comment}
      />
      {reportingConfirmationMessageShown && (
        <CommentingConfirmationMessage
          onTimeout={() => setReportingConfirmationMessageShown(false)}>
          {translate('commenting.reportedMessage')}
        </CommentingConfirmationMessage>
      )}
      {isDeletingComment && (
        <CommentingActionPopup
          type="comment"
          actionType="delete"
          onConfirm={() => {
            setIsDeletingComment(false)
            deleteComment({ commentId: id })
          }}
          onCancel={() => setIsDeletingComment(false)}
        />
      )}
      {isReportingComment && (
        <CommentingActionPopup
          type="comment"
          actionType="report"
          onConfirm={(textValue = '') => {
            setIsReportingComment(false)
            setReportingConfirmationMessageShown(true)
            reportComment({ commentId: id, reason: textValue })
          }}
          onCancel={() => setIsReportingComment(false)}
        />
      )}

      <ListOfAnswers
        answers={answers}
        isPostingAnswer={isPostingAnswer}
        setIsPostingAnswer={setIsPostingAnswer}
        moreToShow={number_of_answers > answers?.length}
        fatherCommentId={id}
      />
    </CommentingItemWrapper>
  )
}

export default Comment
