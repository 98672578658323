import { useCallback, useEffect } from 'react'
import { JWPlayer } from '../../types'
import { MetadataParamExtended } from '../../types/jwplayer-extended'

export type UseOnMetaCueChange = ({
  player,
  onCueChange,
}: {
  player: JWPlayer
  onCueChange: (text?: string) => void
}) => void

const useOnMetaCueChange: UseOnMetaCueChange = ({ player, onCueChange }) => {
  const onMetaHandler = useCallback<(event: MetadataParamExtended) => void>(
    (event) => {
      if (
        event?.metadataType === 'id3' &&
        event?.metadata?.PRIV?.['com.blick.tv.gfx']
      ) {
        const graphicsDataEntry = event.metadata.PRIV['com.blick.tv.gfx']
        const entryValue = new TextDecoder().decode(
          new Uint8Array(graphicsDataEntry)
        )

        onCueChange(entryValue)
      }
    },
    [onCueChange]
  )

  useEffect(() => {
    player.on('meta', onMetaHandler)

    return () => {
      player.off('meta', onMetaHandler)
    }
  }, [onMetaHandler, player])
}

export default useOnMetaCueChange
