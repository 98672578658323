import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { mobileCSS } from '@measures/responsive'
import translate from '@i18n'

const StyledWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing40, spacing32 },
    },
  }) => {
    return css`
      margin-top: ${spacing40};
      display: flex;
      flex-direction: column;
      ${mobileCSS(css`
        margin-top: ${spacing32};
      `)}
    `
  }}
`

const StyledTitle = styled.span`
  ${({
    theme: {
      typography: {
        headings: {
          medium: { bundledCSS: headingMediumCSS },
        },
      },
      spacing: { spacing20, spacing12 },
    },
  }) => {
    return css`
      ${headingMediumCSS};
      margin-bottom: ${spacing20};
      ${mobileCSS(css`
        margin-bottom: ${spacing12};
      `)}
    `
  }}
`

const IngredientsWrapper: FunctionComponent<{ children?: ReactNode }> = ({
  children,
}) => {
  return (
    <StyledWrapper>
      <StyledTitle>{translate('recipe.ingredients')}</StyledTitle>
      {children}
    </StyledWrapper>
  )
}

export default IngredientsWrapper
