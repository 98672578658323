import { FunctionComponent, MouseEventHandler, ReactNode } from 'react'
import { SvgIconName } from '@components/SvgIcon/types'
import styled, { css } from 'styled-components'
import SvgIcon from '@components/SvgIcon'

export interface CommonCTAProps {
  children: string | ReactNode
  size?: 'small' | 'large'
  disabled?: boolean
  className?: string
  iconName?: SvgIconName
  iconLeft?: boolean
}

interface StandardButtonProps {
  type?: 'button'
  onClick: MouseEventHandler<HTMLButtonElement>
}
interface SubmitButtonProps {
  type: 'submit'
  onClick?: MouseEventHandler<HTMLButtonElement>
}

interface ResetButtonProps {
  type: 'reset'
  onClick?: MouseEventHandler<HTMLButtonElement>
}

export type ButtonProps =
  | StandardButtonProps
  | SubmitButtonProps
  | ResetButtonProps

export const commonCTAStyles = css<
  Required<Pick<CommonCTAProps, 'size' | 'iconLeft'>>
>`
  ${({
    theme: {
      typography: {
        subheads: {
          small1: { bundledCSS: small1SubheadsCSS },
        },
      },
      spacing: { spacing4, spacing8, spacing12, spacing16, spacing24 },
    },
    size,
    iconLeft,
  }) => css`
    ${small1SubheadsCSS};
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: center;

    gap: ${spacing8};

    appearance: none;
    text-decoration: none;
    border: 0;
    cursor: pointer;
    box-sizing: border-box;

    border-radius: 50px;

    ${size === 'small'
      ? css`
          height: 40px;
          padding: ${spacing4} ${spacing16};
        `
      : css`
          height: 48px;
          padding: ${spacing12} ${spacing24};
        `};

    ${iconLeft &&
    css`
      flex-direction: row-reverse;
    `}
  `}
`

const TextWrapper = styled.div<{ size: CommonCTAProps['size'] }>`
  line-height: ${({ size }) => (size === 'large' ? 24 : 16)}px;
`

export const TextWithIconWrapper: FunctionComponent<
  Required<Pick<CommonCTAProps, 'children' | 'size' | 'iconName'>>
> = ({ size, iconName, children }) => (
  <>
    <TextWrapper size={size}>{children}</TextWrapper>
    <SvgIcon iconName={iconName} size={size === 'large' ? 24 : 16} />
  </>
)
