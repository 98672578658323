import { RefObject } from 'react'

export type ScrollToEndType = ({
  containerRef,
}: {
  containerRef: RefObject<HTMLDivElement>
}) => void

const scrollToEnd: ScrollToEndType = ({ containerRef }) => {
  if (!containerRef.current) {
    return
  }
  containerRef.current.scrollLeft =
    containerRef.current.scrollWidth - containerRef.current.clientWidth
}

const scrollToStart: ScrollToEndType = ({ containerRef }) => {
  if (!containerRef.current) {
    return
  }

  containerRef.current.scrollLeft = 0
}

export { scrollToEnd, scrollToStart }
