import { useState, useEffect } from 'react'
import { DeviceType, getDeviceType } from '@utils/deviceType'

const useDeviceType = (): DeviceType | null => {
  const [deviceType, setDeviceType] = useState<DeviceType | null>(null)

  useEffect(() => {
    const newDeviceType = getDeviceType()
    setDeviceType(newDeviceType)
  }, [])

  return deviceType
}

export default useDeviceType
