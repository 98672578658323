import { ConfigProps } from '@config/types'

const config = ({}: ConfigProps) =>
  ({
    streamUrl:
      'https://embed.zattoo.com/?partner_id=permanent-embed&channel={channel}',
    channelPlaceholderUrl: 'https://thumb.zattic.com/{channel}/1024x576.jpg',
  }) as const satisfies Record<string, unknown>

export default config
