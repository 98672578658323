import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import translate from '@i18n'

const StyledToast = styled.div`
  ${({
    theme: {
      typography: {
        bodycopy: {
          small2: { bundledCSS: small2BodycopyCSS },
        },
      },
      color: {
        primary: { primary02: primary02Color },
        tertiary: { grey800 },
      },
      spacing: { spacing8, spacing12 },
    },
  }) => {
    return css`
      ${small2BodycopyCSS};
      position: fixed;
      bottom: 24px;
      left: 50%;
      transform: translate(-50%, 0);
      border-radius: 25px;
      background-color: ${grey800};
      color: ${primary02Color};
      display: flex;
      justify-content: center;
      padding: ${spacing8} ${spacing12};
      z-index: 2;
    `
  }}
`

const ToastMessage: FunctionComponent = () => (
  <StyledToast>{translate('social.copyToast')}</StyledToast>
)

export default ToastMessage
