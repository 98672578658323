import { layout } from '@measures/responsive'

const {
  desktop: { minWidth: minWidthDesktop },
  tablet: { minWidth: minWidthTablet },
} = layout

const INITIAL_VIEWPORT_TYPE = 'desktop'
const INITIAL_VIEWPORT_ORIENTATION = 'landscape'
const INITIAL_DIMENSIONS = { width: 1280, height: 1024 }

const DEBOUNCE_TIMEOUT = 300

const getViewportType = (width: number) =>
  width < minWidthTablet
    ? 'mobile'
    : width < minWidthDesktop
      ? 'tablet'
      : 'desktop'

export {
  getViewportType,
  INITIAL_VIEWPORT_TYPE,
  INITIAL_DIMENSIONS,
  INITIAL_VIEWPORT_ORIENTATION,
  DEBOUNCE_TIMEOUT,
}
