import { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import Title from './Title'
import Catchword from './Catchword'

const TitleCatchwordWrapper = styled.h1`
  display: flex;
  flex-direction: column;
  text-align: inherit;
  margin: inherit;
  width: 100%;
`
const TitleWrapper = styled.div`
  display: flex;
`

type TitleCatchwordProps = {
  title: string
  catchword: string
  children?: ReactNode
}

const TitleCatchword: FunctionComponent<TitleCatchwordProps> = ({
  title,
  catchword,
  children,
}) => {
  return (
    <>
      {title || catchword ? (
        <TitleCatchwordWrapper>
          <Catchword catchword={catchword} />
          <TitleWrapper>{!!title && <Title title={title} />}</TitleWrapper>
        </TitleCatchwordWrapper>
      ) : null}
      {children}
    </>
  )
}

export default TitleCatchword
