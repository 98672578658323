import translate from '@i18n'
import { reactionMapping } from './constants'
import {
  CommentAuthorType,
  CommentSuperUserAuthorType,
  CommentingItemProps,
  OwnReactionValues,
  Reaction,
  Reactions,
} from './types'
import { QueryClient } from '@tanstack/react-query'
import { RefObject } from 'react'

const createHasOwnReaction =
  (commentId: number, reactions: Reactions) =>
  (reaction: Reaction): boolean =>
    reactions[commentId] === reaction

const getIsAuthorTypeSuperUser = (
  authorType: CommentAuthorType
): authorType is CommentSuperUserAuthorType =>
  ['ADMIN', 'MODERATOR', 'JOURNALIST'].includes(authorType)

const getGenderAdjustedSuperUserTitle = (
  authorType: CommentSuperUserAuthorType,
  gender: CommentingItemProps['gender']
) =>
  getIsAuthorTypeSuperUser(authorType)
    ? translate(
        `commenting.authorType.${
          authorType.toLowerCase() as Lowercase<CommentSuperUserAuthorType>
        }${gender === 'female' ? '.female' : ''}` as const
      )
    : ''

const getOwnReactionValues = (
  commentId: number,
  reactions?: Reactions
): OwnReactionValues => {
  const hasOwnReaction = reactions
    ? createHasOwnReaction(commentId, reactions)
    : () => false
  return {
    heart: hasOwnReaction(reactionMapping.heart),
    bolt: hasOwnReaction(reactionMapping.bolt),
    happy: hasOwnReaction(reactionMapping.happy),
    sad: hasOwnReaction(reactionMapping.sad),
    critical: hasOwnReaction(reactionMapping.critical),
  }
}

const getTitle = ({
  name = '',
  statusId = 0,
}: {
  name: string
  statusId?: number
  authorType?: CommentAuthorType
}) => (statusId === 3 ? translate('commenting.deletedUserName') : name)

const charactersLeftText = (numberOfChars: number) => {
  let label = ''
  if (numberOfChars < 0) {
    label =
      numberOfChars === -1
        ? translate('commenting.oneCharacterOverflow', {
            numberOfCharacters: Math.abs(numberOfChars).toString(),
          })
        : translate('commenting.numberOfCharactersOverflow', {
            numberOfCharacters: Math.abs(numberOfChars).toString(),
          })
  } else {
    label =
      numberOfChars === 1
        ? translate('commenting.oneCharacterLeft', {
            numberOfCharacters: numberOfChars.toString(),
          })
        : translate('commenting.numberOfCharactersLeft', {
            numberOfCharacters: numberOfChars.toString(),
          })
  }
  return label
}

const scrollToCommentingWidget = (queryClient: QueryClient) => {
  const commentingWidgetRef = queryClient.getQueryData<
    RefObject<HTMLDivElement>
  >(['commenting-widget-ref'])

  if (!commentingWidgetRef) {
    return
  }

  try {
    commentingWidgetRef.current?.scrollIntoView({ behavior: 'smooth' })
  } catch {
    commentingWidgetRef.current?.scrollIntoView()
  }
}

export {
  getIsAuthorTypeSuperUser,
  getGenderAdjustedSuperUserTitle,
  getOwnReactionValues,
  getTitle,
  charactersLeftText,
  scrollToCommentingWidget,
}
