import config from '@config'
import usePageMetadata from '@hooks/usePageMetadata'
import { isVideoRecommendationsEnabled } from '@widgets/Video/recommendations/utils'
import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'

import { JSONWidgetType as TeaserStandardVerticalVideoJSONWidgetType } from '@widgets/TeaserStandardVerticalVideo'
import { JSONWidgetType as TeaserStandardHorizontalJSONWidgetType } from '@widgets/TeaserStandardHorizontal'

export type RecommendedItem = TeaserStandardHorizontalJSONWidgetType

export type VideoRecommendedItem = TeaserStandardVerticalVideoJSONWidgetType

export interface RecommendationsQueryData {
  data: RecommendedItem[]
  'embedded-videos'?: VideoRecommendedItem[]
}

export type NormalizedRecommendationsQueryData =
  Required<RecommendationsQueryData> & { isPlaceholderData?: boolean }

export type UseRecommendations = (arg?: {
  defaultData?: RecommendedItem[]
  forceDisable?: boolean
}) => NormalizedRecommendationsQueryData

const {
  recommendation: { apiUrl },
  publication: { publication },
} = config

const useRecommendations: UseRecommendations = ({
  defaultData,
  forceDisable,
} = {}) => {
  const { id: articleId, hasVideo } = usePageMetadata()

  const hasVideoNormalized = !!hasVideo && isVideoRecommendationsEnabled()
  const placeholderData = useMemo<NormalizedRecommendationsQueryData>(
    () => ({
      data: defaultData ?? [],
      'embedded-videos': [],
    }),
    [defaultData]
  )

  const { data: queryData, isPlaceholderData } =
    useQuery<RecommendationsQueryData>({
      // eslint-disable-next-line @tanstack/query/exhaustive-deps
      queryKey: ['recommendations', articleId],
      placeholderData,
      queryFn: async () =>
        (
          await fetch(
            `${apiUrl}/v2/recommended-content/${articleId}/?pub=${publication}${
              hasVideoNormalized ? '&video=true' : ''
            }`,
            {
              credentials: 'include',
            }
          )
        ).json(),
      enabled: !forceDisable && !!articleId,
    })

  return useMemo<NormalizedRecommendationsQueryData>(
    () => ({
      ...(isPlaceholderData || !queryData?.data
        ? placeholderData
        : (queryData as NormalizedRecommendationsQueryData)),
      isPlaceholderData,
    }),
    [isPlaceholderData, queryData, placeholderData]
  )
}

export { useRecommendations }
