import { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div``

const ArticleHeader: FunctionComponent<{
  children?: ReactNode
}> = ({ children }) => <Wrapper>{children}</Wrapper>

export default ArticleHeader
