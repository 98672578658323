import useTracking, { TrackingFnType } from '@hooks/useTracking'
import { stripHtml } from '@hooks/useTracking/utils'
import { FunctionComponent, useCallback, useContext } from 'react'
import SocialButton from './Button'
import translate from '@i18n'
import { getSocialEmbedConfig, getUrlEncodedUTMParameters } from './utils'
import { isMobileUserAgent } from '@measures/responsive'
import EndOfArticleContext from '@contexts/endOfArticle'

interface WhatsappProps {
  shareUrl: string
  shareTitle: string
  shareUrlHash?: string
  utmContent?: string
  label?: string
}

const Whatsapp: FunctionComponent<WhatsappProps> = ({
  shareUrl,
  shareTitle,
  shareUrlHash,
  utmContent,
  label,
}) => {
  const endOfArticle = useContext(EndOfArticleContext)
  const trackingOnClick = useCallback<TrackingFnType>(
    () => ({
      event: 'element_click',
      element: `article_share_${endOfArticle ? 'bottom' : 'top'}`,
      method: 'whatsapp',
      utm_content: utmContent,
    }),
    [endOfArticle, utmContent]
  )
  const trackingOnClickLegacy = useCallback<TrackingFnType>(
    ({ pageMetadata }) => ({
      event: 'article_share',
      eventCategory: 'article',
      eventAction: `share_whatsapp_${endOfArticle ? 'bottom' : 'top'}`,
      eventLabel: `${pageMetadata.id}:${stripHtml(pageMetadata.title)}`,
      utm_content: utmContent,
    }),
    [endOfArticle, utmContent]
  )
  const trackingFunction = useTracking(trackingOnClick)
  const trackingFunctionLegacy = useTracking(trackingOnClickLegacy)

  const onClick = useCallback(() => {
    trackingFunction()
    // The *Legacy tracking functions will be deleted when the tracking team is ready to switch to the new events
    trackingFunctionLegacy()
    const { utmSource, utmMedium, utmCampaign } =
      getSocialEmbedConfig('whatsapp')

    const utm = getUrlEncodedUTMParameters(
      utmMedium,
      utmCampaign,
      utmSource,
      utmContent
    )
    const shareMessage = translate('shareMessage')
    const whatsAppShare = isMobileUserAgent()
      ? 'whatsapp://'
      : 'https://web.whatsapp.com/'
    return window.open(
      `${whatsAppShare}send?text=${shareMessage}%0A${shareTitle}%0A${shareUrl}${utm}${shareUrlHash ?? ''}`,
      '_blank',
      'noopener noreferrer nofollow'
    )
  }, [
    trackingFunction,
    trackingFunctionLegacy,
    utmContent,
    shareTitle,
    shareUrl,
    shareUrlHash,
  ])

  return (
    <SocialButton type="whatsapp" onClick={onClick}>
      {label}
    </SocialButton>
  )
}

export default Whatsapp
