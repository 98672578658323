import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

const StyledDivider = styled.div`
  ${({
    theme: {
      color: {
        tertiary: { grey400 },
      },
    },
  }) => css`
    background-color: ${grey400};
    width: 1px;
    height: 18px;
  `}
`

const Divider: FunctionComponent = () => <StyledDivider />

export default Divider
