import { FunctionComponent } from 'react'
import SingleAuthorWrapper from '@widgets/Author/SingleAuthors'
import MultipleAuthorsWrapper from '@widgets/Author/MultipleAuthors'
import { shouldDisplayImages } from '@widgets/Author/utils'
import AuthorsWithoutImages from '@widgets/Author/Common/AuthorsWithoutImages'
import { AuthorAPIProps } from './types'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import GridContainer from '@widgets/Author/Common/GridContainer'

const Author: FunctionComponent<AuthorAPIProps> = ({
  authors,
  type,
  contentOriginImage,
  contentOrigin,
}) => {
  if (!authors?.length) {
    return null
  }
  if (type === 'team') {
    return <GridContainer>{authors}</GridContainer>
  }
  const isSingleAuthor = authors.length === 1

  if (isSingleAuthor) {
    return (
      <SingleAuthorWrapper
        {...authors[0]}
        {...{ contentOrigin, contentOriginImage }}
      />
    )
  }
  if (!shouldDisplayImages(authors)) {
    return (
      <AuthorsWithoutImages {...{ contentOrigin, contentOriginImage }}>
        {authors}
      </AuthorsWithoutImages>
    )
  }

  return (
    <MultipleAuthorsWrapper {...{ contentOrigin, contentOriginImage }}>
      {authors}
    </MultipleAuthorsWrapper>
  )
}

const widget = {
  kind: ['widget', 'inlineElement', 'author'],
  component: Author,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
