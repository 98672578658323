import config from '@config'
import {
  Answer,
  APICommentingAnswersData,
  APICommentingData,
  QueryCommentingData,
} from '@widgets/Commenting/types'
import { FunctionComponent, ReactNode, useContext } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import AnswerAPIContext from './answerApiContext'
import GenericCommentingContext from './genericContext'

const {
  community: { baseUrl, postUrl, deleteUrl, reportUrl, getAnswersUrl },
} = config

interface AnswerAPIContextProviderProps {
  answers: Answer[]
  children?: ReactNode
}

const postAnswer = async ({
  articleId,
  fatherId,
  body,
}: {
  articleId: string
  fatherId: number
  body: string
}) => {
  const bodyPayload = {
    discussion_type_id: articleId,
    father_id: fatherId,
    body,
  }

  const response = await fetch(`${baseUrl}${postUrl}`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyPayload),
  })
  if (response.status >= 400 && response.status < 600) {
    throw new Error(`Server responded with ${response.status}`)
  }
  return await response.json()
}

const reportAnswer = async ({
  answerId,
  reason,
}: {
  answerId: number
  reason: string
}) => {
  const response = await fetch(`${baseUrl}${reportUrl}`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      text: reason,
      comment_id: answerId,
    }),
  })
  if (response.status >= 400 && response.status < 600) {
    throw new Error(`Server responded with ${response.status}`)
  }
  return await response.json()
}

const deleteAnswer = async ({
  answerId,
}: {
  answerId: number
  fatherId: number
}) => {
  const deleteFullUrl = deleteUrl.replace('{id}', answerId.toString())
  const response = await fetch(`${baseUrl}${deleteFullUrl}`, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  })
  if (response.status >= 400 && response.status < 600) {
    throw new Error(`Server responded with ${response.status}`)
  }
  return response
}

const deleteAnswerFromCommentingData = (
  answerId: number,
  fatherId: number,
  oldCommentingData: QueryCommentingData
) => {
  const oldPages = oldCommentingData.pages

  const newPages = oldPages.map((page) => {
    const newContent = page.content.map((comment) => {
      if (comment.id === fatherId) {
        return {
          ...comment,
          answers: comment.answers.filter((answer) => answer.id !== answerId),
        }
      }
      return comment
    })
    return { ...page, content: newContent }
  })

  return { ...oldCommentingData, pages: newPages }
}

const addAnswersToCommentingData = (
  newAnswers: Answer[],
  fatherId: number,
  oldCommentingData: { pages: APICommentingData[] },
  pageNumber: number
) => {
  const oldPages = oldCommentingData.pages

  const newPages = oldPages.map((page) => {
    const newContent = page.content.map((comment) => {
      if (comment.id === fatherId) {
        const updatedAnswers =
          pageNumber === 0
            ? [...newAnswers]
            : [...comment.answers, ...newAnswers]
        return {
          ...comment,
          answers: updatedAnswers,
        }
      }
      return comment
    })
    return { ...page, content: newContent }
  })

  return { ...oldCommentingData, pages: newPages }
}

const getMoreAnswers = async ({
  fatherId,
  articleId,
  page,
}: {
  fatherId: number
  articleId: string
  page: number
}): Promise<APICommentingAnswersData> => {
  const getAnswersFullUrl = getAnswersUrl.replace('{id}', fatherId.toString())
  const response = await fetch(
    `${baseUrl}${getAnswersFullUrl}/?page=${page}&discussion_type_id=${articleId}`
  )
  if (response.status >= 400 && response.status < 600) {
    throw new Error(`Server responded with ${response.status}`)
  }
  return await response.json()
}

const AnswerAPIContextProvider: FunctionComponent<
  AnswerAPIContextProviderProps
> = ({ answers, children }) => {
  const queryClient = useQueryClient()
  const { articleId } = useContext(GenericCommentingContext)

  const postAnswerMutation = useMutation({ mutationFn: postAnswer })

  const { mutate: deleteAnswerMutate } = useMutation({
    mutationFn: deleteAnswer,
    onMutate: async ({ answerId, fatherId }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: ['commentingData', articleId],
      })

      // Snapshot the previous value
      const previousCommentingData = queryClient.getQueryData<
        QueryCommentingData | undefined
      >(['commentingData', articleId])
      // Optimistically update to the new value
      queryClient.setQueryData<QueryCommentingData | undefined>(
        ['commentingData', articleId],
        (oldData) => {
          if (!oldData) {
            return
          }
          return deleteAnswerFromCommentingData(answerId, fatherId, oldData)
        }
      )

      // Return a context object with the snapshotted value
      return { previousCommentingData }
    },
    onError: (error) => {
      console.error('An error happened while deleting an answers:', error)
    },
  })

  const { mutate: mutateGetAnswers } = useMutation({
    mutationFn: getMoreAnswers,
    onMutate: async ({ articleId }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: ['commentingData', articleId],
      })
    },
    onSuccess: async (result, variables) => {
      queryClient.setQueryData<QueryCommentingData | undefined>(
        ['commentingData', articleId],
        (oldData) => {
          if (!oldData) {
            return
          }
          return addAnswersToCommentingData(
            result.content,
            variables.fatherId,
            oldData,
            variables.page
          )
        }
      )
    },
    onError: (error) => {
      console.error('An error happened while loading more answers:', error)
    },
  })

  const deletePostedAnswer = (payload: {
    answerId: number
    fatherId: number
  }) => {
    postAnswerMutation.reset()
    deleteAnswer(payload)
  }

  return (
    <AnswerAPIContext.Provider
      value={{
        answers,
        postedAnswer: postAnswerMutation.data,
        postAnswer: postAnswerMutation.mutate,
        deletePostedAnswer,
        deleteAnswer: deleteAnswerMutate,
        reportAnswer,
        getMoreAnswers: mutateGetAnswers,
      }}>
      {children}
    </AnswerAPIContext.Provider>
  )
}

export default AnswerAPIContextProvider
