import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

const StyledMatchScoreContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-items: center;
`

const StyledMatchScore = styled.div`
  ${({
    theme: {
      color: {
        primary: { primary01: primary01Color },
      },
      spacing: { spacing4, spacing8 },
      typography: {
        headings: {
          xxxlarge: { bundledCSS: xxxlargeHeadingCSS },
        },
      },
    },
  }) => css`
    color: ${primary01Color};
    text-align: center;
    ${xxxlargeHeadingCSS};
    letter-spacing: ${spacing4};
    margin-bottom: ${spacing8};
  `}
`

const StyledMatchScoreExplanation = styled.div`
  ${({
    theme: {
      color: {
        tertiary: { grey800: grey800color },
      },
      typography: {
        eyebrows: {
          medium2: { bundledCSS: medium2EyebrowCSS },
        },
      },
      spacing: { spacing12 },
    },
  }) => css`
    color: ${grey800color};
    ${medium2EyebrowCSS};
    text-align: center;
    margin-bottom: ${spacing12};
  `}
`

export type ScoreboardMatchScoreProps = {
  awayScore: number
  homeScore: number
  explanation?: string
}

const ScoreboardMatchScore: FunctionComponent<ScoreboardMatchScoreProps> = ({
  awayScore,
  homeScore,
  explanation,
}) => (
  <StyledMatchScoreContainer>
    <StyledMatchScore>
      {homeScore}:{awayScore}
    </StyledMatchScore>
    {!!explanation && (
      <StyledMatchScoreExplanation>{explanation}</StyledMatchScoreExplanation>
    )}
  </StyledMatchScoreContainer>
)

export default ScoreboardMatchScore
