import useLeserreporterContext from '@hooks/useLeserreporterContext'
import { ChangeEvent, FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

const StyledBulletpointChoices = styled.div`
  ${({
    theme: {
      color: {
        tertiary: { grey300: grey300Color },
      },
    },
  }) => css`
    display: flex;
    flex-direction: column;

    :not(:last-child) {
      border-bottom: 1px solid ${grey300Color};
    }
  `}
`

const StyledLabel = styled.label`
  ${({
    theme: {
      typography: {
        bodycopy: {
          small2: { bundledCSS: bodyCopySmall2CSS },
        },
      },
      spacing: { spacing2, spacing8, spacing4, spacing16, spacing20 },
      color: {
        primary: {
          blickRed: blickRedColor,
          primary02: primary02Color,
          primary01: primary01Color,
        },
      },
    },
  }) => css`
    padding: ${spacing16} 0;
    ${bodyCopySmall2CSS};
    display: flex;
    align-items: center;

    input[type='radio'] {
      appearance: none;
      -webkit-appearance: none; /*value*/ /*for Safari and Chrome */
      -moz-appearance: none;
      min-width: ${spacing20};
      width: ${spacing20};
      height: ${spacing20};
      border-radius: 50%;
      border: ${spacing4} solid ${primary02Color};
      box-shadow: 0 0 0 ${spacing2} ${primary01Color};
      margin: 0 ${spacing8} ${spacing2} 0;

      &:checked {
        background-color: ${blickRedColor};
        box-shadow: 0 0 0 ${spacing2} ${blickRedColor};
      }
    }
  `}
`

const BulletpointChoices: FunctionComponent = () => {
  const {
    data: { category },
    handleChange,
  } = useLeserreporterContext()

  return (
    <StyledBulletpointChoices
      onChange={handleChange as (event: ChangeEvent<HTMLInputElement>) => void}>
      <StyledLabel>
        <input
          type="radio"
          value="news"
          name="category"
          defaultChecked={category === 'news'}
        />
        Ich möchte ein Leserreporter-Foto oder -Video schicken.
      </StyledLabel>
      <StyledLabel>
        <input
          type="radio"
          value="community"
          name="category"
          defaultChecked={category === 'community'}
        />
        Ich antworte auf einen Community-Aufruf.
      </StyledLabel>
      <StyledLabel>
        <input
          type="radio"
          value="tip"
          name="category"
          defaultChecked={category === 'tip'}
        />
        Ich habe spannende Infos für eine Story.
      </StyledLabel>
    </StyledBulletpointChoices>
  )
}

export default BulletpointChoices
