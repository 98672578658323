import Blink from '@components/Blink'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import { setCookieValue } from '@utils/cookie'
import { FunctionComponent, useCallback } from 'react'
import styled, { css } from 'styled-components'
import config from '@config'

type Locale = 'de' | 'fr'

interface StyledClickableProps {
  isSelected: boolean
}

const {
  publication: { publication },
} = config

const currentLanguage: Locale = publication === 'romandie' ? 'fr' : 'de'

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  justify-content: center;
  overflow: hidden;

  width: 80px;
`

const StyledClickable = styled(Blink)<StyledClickableProps>`
  ${({
    theme: {
      color: {
        primary: { blickRed, primary01 },
      },
      typography: {
        subheads: {
          small1: { bundledCSS: small1SubheadingCSS },
        },
      },
    },
    isSelected,
  }) => css`
    color: ${isSelected ? blickRed : primary01};
    ${small1SubheadingCSS};
    text-decoration: none;
    display: grid;
    align-items: center;
    justify-content: center;
    user-select: none;
  `}
`

const Divider = styled.div`
  ${({
    theme: {
      color: {
        tertiary: { grey400 },
      },
      spacing: { spacing12 },
    },
  }) => css`
    background-color: ${grey400};
    width: 1px;
    height: 17px;
    margin: 0 ${spacing12};
  `}
`

const LanguageSwitcher: FunctionComponent<{ className?: string }> = ({
  className,
}) => {
  const trackingOnClick = useCallback<TrackingFnType<{ language: Locale }>>(
    ({ extraData }) => ({
      event: 'language_switch_home',
      eventCategory: 'language_switch',
      eventLabel: 'home',
      eventAction: extraData.language,
      relatedArticle: 'home',
    }),
    []
  )

  const handleTrack = useTracking(trackingOnClick)

  const onLanguageClick = useCallback(
    (language: Locale) => {
      // Track and set the cookie only if the user is switching the language
      if (currentLanguage !== language) {
        handleTrack({ language })
        setCookieValue({ name: 'bllang', value: language, expireDate: 'MAX' })
      }
    },
    [handleTrack]
  )

  return (
    <Container className={className}>
      <StyledClickable
        key="de"
        onClick={() => onLanguageClick('de')}
        href="/"
        ariaLabel="Ändern Sie die Sprache in Deutsch"
        isSelected={currentLanguage === 'de'}>
        DE
      </StyledClickable>
      <Divider />
      <StyledClickable
        key="fr"
        onClick={() => onLanguageClick('fr')}
        href="/fr/"
        ariaLabel="Changer la langue en français"
        isSelected={currentLanguage === 'fr'}>
        FR
      </StyledClickable>
    </Container>
  )
}

export default LanguageSwitcher
