import { useState, FunctionComponent } from 'react'
import dynamic from 'next/dynamic'
import HeadlineLeadComponents from '@components/HeadlineLead'
import Social from '@components/Social'
import config from '@config'
import Timestamps from '@components/Timestamps'
import { NormalHeadlineLeadAPIProps } from '@widgets/HeadlineLead/types'
import CommentButton from '@components/HeadlineLead/CommentButton'
import SharingAndTextToSpeechWrapper from './SharingAndTextToSpeechWrapper'

const TextToSpeechPlayer = dynamic(() => import('@components/TextToSpeech/'), {
  ssr: false,
})

const {
  publication: { publication },
} = config

const {
  ArticleHeader,
  ArticleMetadata,
  ArticleLead,
  TitleCatchword,
  SharingAndCommentWrapper,
} = HeadlineLeadComponents

const NormalHeadlineLead: FunctionComponent<NormalHeadlineLeadAPIProps> = ({
  catchword,
  title,
  lead,
  displayCommentsOnArticle,
  publishedDate,
  updatedDate,
}) => {
  const shouldDisplayComments =
    displayCommentsOnArticle && publication !== 'romandie'
  const [togglePlayer, setTogglePlayer] = useState(false)
  return (
    <ArticleHeader>
      <TitleCatchword title={title} catchword={catchword}>
        {!!lead && <ArticleLead>{lead}</ArticleLead>}
      </TitleCatchword>
      <ArticleMetadata>
        <Timestamps publishedDate={publishedDate} updatedDate={updatedDate} />
        <SharingAndTextToSpeechWrapper>
          <SharingAndCommentWrapper>
            <Social
              endOfArticle={false}
              setTogglePlayer={setTogglePlayer}
              togglePlayer={togglePlayer}
            />
            {shouldDisplayComments && <CommentButton />}
          </SharingAndCommentWrapper>
          {togglePlayer && (
            <TextToSpeechPlayer setPlayerVisibility={setTogglePlayer} />
          )}
        </SharingAndTextToSpeechWrapper>
      </ArticleMetadata>
    </ArticleHeader>
  )
}

export default NormalHeadlineLead
