import { ConfigProps } from '@config/types'

const config = ({}: ConfigProps) =>
  ({
    timeFormat: 'mm:ss',
    timeInputFormat: 'seconds',
    dateFormat: 'YYYY-MM-DDTHH:mm:ss.sssZ',
  }) as const satisfies Record<string, unknown>

export default config
