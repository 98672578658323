import { ImageRatios } from '@components/ResponsiveImageV2/types'

const IMAGE_RATIOS: ImageRatios = {
  desktop: '3_2',
  tablet: '3_2',
  mobile: '3_2',
}

const IMAGE_WIDTHS = {
  desktop: 964,
  tablet: 664,
  mobile: 430,
  mobileSmall: 320,
}

export { IMAGE_RATIOS, IMAGE_WIDTHS }
