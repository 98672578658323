import styled, { css } from 'styled-components'

interface VerticalLineProps {
  accentColor?: string
}

const StyledVerticalLine = styled.div<Pick<VerticalLineProps, 'accentColor'>>`
  ${({
    theme: {
      color: {
        primary: { blickRed: blickRedColor },
      },
    },
    accentColor,
  }) => css`
    position: absolute;
    left: 0;
    background-color: ${accentColor ?? blickRedColor};

    height: 100%;
    width: 2px;
  `};
`

export default StyledVerticalLine
