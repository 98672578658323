import { FunctionComponent, useCallback, useEffect, useRef } from 'react'
import { useQueryClient } from '@tanstack/react-query'

import {
  AnimatedPreviewType,
  AnimatedPreviewValue,
} from '@components/AnimatedPreview/types'
import { isTheSameInstance } from '@components/AnimatedPreview/utils'

const AnimatedPreviewManager: FunctionComponent = () => {
  const queryClient = useQueryClient()
  const queryCache = queryClient.getQueryCache()
  const unsubscribeFnRef = useRef<() => void>()

  const getActiveAnimatedPreview = useCallback(
    () =>
      queryClient.getQueryData<AnimatedPreviewValue>([
        'active-animated-preview',
      ]),
    [queryClient]
  )

  const removeActiveAnimatedPreview = useCallback(
    () =>
      queryClient.removeQueries({
        queryKey: ['active-animated-preview'],
        exact: true,
      }),
    [queryClient]
  )

  const updateActiveAnimatedPreviewValue = useCallback(
    (args: any) => {
      if (
        args?.action?.type === 'invalidate' &&
        args.query.queryKey[0] === 'animated-previews-list'
      ) {
        const animatedPreviewsList = args.query.state
          .data as AnimatedPreviewType[]
        const activeAnimatedPreview = getActiveAnimatedPreview()

        // check if active preview is in the list and wasn't removed
        const isActivePreviewInTheList = !!(
          activeAnimatedPreview &&
          animatedPreviewsList.find((item) =>
            isTheSameInstance(item, activeAnimatedPreview)
          )
        )

        if (!isActivePreviewInTheList) {
          // remove active preview if it is not in the list anymore
          removeActiveAnimatedPreview()
        }
      }
    },
    [getActiveAnimatedPreview, removeActiveAnimatedPreview]
  )

  useEffect(() => {
    unsubscribeFnRef.current = queryCache.subscribe(
      updateActiveAnimatedPreviewValue
    )

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [queryCache, updateActiveAnimatedPreviewValue])

  return null
}

export default AnimatedPreviewManager
