import { TrackingFnType } from '@hooks/useTracking'

export const trackQuestionSummaryImpression: TrackingFnType = () => ({
  event: 'question_widget_impression',
})

export const trackQuestionSummaryClickHandler: TrackingFnType<{
  label: string
}> = ({ extraData: { label } }) => ({
  event: 'question_widget_click',
  button_label: label,
})
