import { FunctionComponent } from 'react'
import Head from 'next/head'
import { useTheme } from 'styled-components'

const ThemeColorMetaTag: FunctionComponent = () => (
  <Head>
    <meta
      key="theme-color"
      name="theme-color"
      content={useTheme().color.primary.primary02}
    />
  </Head>
)

export default ThemeColorMetaTag
