import useEnableUserGeolocationIndicator from '@hooks/useEnableUserGeolocationIndicator'
import useIsSwissGeolocation from '@hooks/useIsSwissGeolocation'
import styled, { css } from 'styled-components'
import { mobileCSS } from '@measures/responsive'

const Title = styled.span`
  position: absolute;
  top: 20px;
  left: 140px;
  ${mobileCSS(css`
    left: 120px;
    max-width: 100px;
    top: 16px;
  `)}
`

const GeolocationIndicator = () => {
  const showUserGeolocationIndicator = useEnableUserGeolocationIndicator()
  const isSwissGeolocation = useIsSwissGeolocation()
  return showUserGeolocationIndicator ? (
    <Title>{`User is ${
      !isSwissGeolocation ? 'not ' : ''
    }in Switzerland`}</Title>
  ) : null
}

export default GeolocationIndicator
