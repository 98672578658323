import { useCallback, useEffect, useState } from 'react'
import { JWPlayer, CaptionsTrack } from '../../types'
import { getCaptionsTrack } from '../../utils/player'

export type UseVTTCuesFromCaptionsTrack = ({
  player,
  onCueChange,
}: {
  player: JWPlayer
  onCueChange: (text?: string[]) => void
}) => VTTCue[] | undefined

const useVTTCuesFromCaptionsTrack: UseVTTCuesFromCaptionsTrack = ({
  player,
  onCueChange,
}) => {
  const [vttCues, setVttCues] = useState<VTTCue[]>()
  const [captionsTrack, setCaptionsTrack] = useState<CaptionsTrack>()

  const initCaptionsTrack = useCallback(() => {
    const playerCaptionsTrack = getCaptionsTrack(player)

    setCaptionsTrack(playerCaptionsTrack)
  }, [player])

  const onCaptionsTrackCueChange = useCallback(
    (event: Event) => {
      const { activeCues } = event.target as TextTrack
      const activeCuesText = activeCues
        ? [...Array.from(activeCues)]?.map((cue) => (cue as VTTCue).text)
        : undefined

      onCueChange(activeCuesText)
    },
    [onCueChange]
  )

  useEffect(() => {
    if (captionsTrack) {
      // In Safari, captionsTrack is instance of TextTrack and has `cuechange` event
      if (captionsTrack.addEventListener) {
        captionsTrack.addEventListener('cuechange', onCaptionsTrackCueChange)

        return () => {
          captionsTrack?.removeEventListener(
            'cuechange',
            onCaptionsTrackCueChange
          )
        }
      }

      if (captionsTrack.data) {
        setVttCues(captionsTrack.data)
      }
    }

    return () => {}
  }, [captionsTrack, onCaptionsTrackCueChange])

  useEffect(() => {
    // captionsTrack track might be already available on mount
    // otherwise load it on 'subtitlesTracks' event
    initCaptionsTrack()
  }, [initCaptionsTrack])

  useEffect(() => {
    player.on('subtitlesTracks', initCaptionsTrack)

    return () => {
      player.off('subtitlesTracks', initCaptionsTrack)
    }
  }, [initCaptionsTrack, player])

  return vttCues
}

export default useVTTCuesFromCaptionsTrack
