import { useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'
import { VideoRefType } from '@widgets/Video/types'
import { VIDEO_TYPES } from '@widgets/Video/utils'
import { getJWPlayer, getYoutubePlayer } from '@utils/videoPlayer'

type UseVideoRefsQueryReturnType = (
  callbackFn: (videos: VideoRefType[]) => VideoRefType[]
) => void

type UseVideoRefsQueryType = () => {
  addVideoPlayer: (video: VideoRefType) => void
  activateVideoPlayer: (widgetId: VideoRefType['widgetId']) => void
  removeVideoPlayer: (widgetId: VideoRefType['widgetId']) => void
}

const pauseVideo = (video: VideoRefType) => {
  if (video.type === VIDEO_TYPES.YOUTUBE) {
    getYoutubePlayer(video.widgetId)?.pauseVideo?.()
  } else {
    getJWPlayer(video.widgetId)?.pause()
  }
}

const isTheSamePlayerInstance = (
  widgetId: VideoRefType['widgetId'],
  otherWidgetId: VideoRefType['widgetId']
) => widgetId === otherWidgetId

const useVideoRefsQuery: UseVideoRefsQueryType = () => {
  const queryClient = useQueryClient()

  const setVideoRefs = useCallback<UseVideoRefsQueryReturnType>(
    (callbackFn) => {
      const currentVideoRefs =
        queryClient.getQueryData<ReturnType<typeof callbackFn>>([
          'video-refs',
        ]) ?? []

      const newVideoRefs = callbackFn(currentVideoRefs)

      queryClient.setQueryData(['video-refs'], newVideoRefs)
      queryClient.invalidateQueries({ queryKey: ['video-refs'], exact: true })
    },
    [queryClient]
  )

  const addVideoPlayer = useCallback(
    (video: VideoRefType) => {
      setVideoRefs((currentVideoRefs) => {
        const player = currentVideoRefs.find((videoRef) =>
          isTheSamePlayerInstance(videoRef.widgetId, video.widgetId)
        )
        // check if player already exists in the list and do not add it again
        return player ? currentVideoRefs : [...currentVideoRefs, video]
      })
    },
    [setVideoRefs]
  )

  const removeVideoPlayer = useCallback(
    (widgetId: VideoRefType['widgetId']) => {
      setVideoRefs((currentVideoRefs) =>
        currentVideoRefs.filter(
          (video) => !isTheSamePlayerInstance(video.widgetId, widgetId)
        )
      )
    },
    [setVideoRefs]
  )

  // makes player active - pauses the rest of videos
  const activateVideoPlayer = useCallback(
    (widgetId: VideoRefType['widgetId']) => {
      setVideoRefs((videoRefs) =>
        videoRefs.reduce((acc, video) => {
          const newVideo = { ...video }

          if (isTheSamePlayerInstance(video.widgetId, widgetId)) {
            newVideo.active = true
            newVideo.suspended = false
          } else {
            const jwPlayer = getJWPlayer(video.widgetId)

            // remove video player from the list if it was in PiP mode and keeped instance in memory
            if (jwPlayer && jwPlayer?.getPip?.()) {
              jwPlayer?.setPip?.(false)
              if (jwPlayer.isPlayerInMemory()) {
                return acc
              }
            }

            newVideo.active = false

            if (
              jwPlayer &&
              (jwPlayer?.getState?.() === 'playing' ||
                jwPlayer?.getState?.() === 'paused' ||
                // @ts-expect-error
                jwPlayer?.getState?.() === 'complete')
            ) {
              newVideo.suspended = true
              newVideo.position = jwPlayer?.getPosition?.()
            }

            pauseVideo(video)
          }

          return [...acc, newVideo]
        }, [] as VideoRefType[])
      )
    },
    [setVideoRefs]
  )

  return {
    addVideoPlayer,
    activateVideoPlayer,
    removeVideoPlayer,
  }
}

export default useVideoRefsQuery
