import { FunctionComponent, ReactNode } from 'react'

import styled, { css } from 'styled-components'

const StyledQuestionContainer = styled.div`
  ${({
    theme: {
      spacing: { spacing12 },
    },
  }) => css`
    display: flex;
    flex-direction: column;
    gap: ${spacing12};
  `}
`

const StyledQuestionMessage = styled.div`
  ${({
    theme: {
      typography: {
        subheads: {
          medium2: { bundledCSS: mediumBodyCopy },
        },
      },
      spacing: { spacing12, spacing16 },
      color: {
        primary: { primary01, primary02 },
        tertiary: { grey500 },
      },
    },
  }) => css`
    flex: 0 0 auto;
    margin-right: auto;
    ${mediumBodyCopy}
    border-radius: 2px 16px 16px 16px;
    border: 1px solid ${primary01};
    background-color: ${primary02};
    padding: ${spacing12} ${spacing16};
    cursor: pointer;

    &:hover {
      border-color: ${grey500};
    }
  `}
`

export const QuestionContainer: FunctionComponent<{ children: ReactNode }> = ({
  children,
}) => {
  return <StyledQuestionContainer>{children}</StyledQuestionContainer>
}

export const QuestionMessage: FunctionComponent<{
  children: ReactNode
  onClick?: () => void
}> = ({ children, onClick }) => {
  return (
    <StyledQuestionMessage onClick={onClick}>{children}</StyledQuestionMessage>
  )
}
