import { FunctionComponent, ReactNode } from 'react'
import ResponsiveImageV2 from '@components/ResponsiveImageV2'
import { ImageRatios, ImageWidths } from '@components/ResponsiveImageV2/types'
import { TeaserStandardVerticalAPIProps } from '@widgets/TeaserStandardVertical'

export type ImageProps = TeaserStandardVerticalAPIProps['image'] &
  Pick<TeaserStandardVerticalAPIProps, 'layoutTemplate'> & {
    children?: ReactNode
    className?: string
  }

export const RATIOS = {
  desktop: '3_2',
  tablet: '3_2',
  mobile: '3_2',
} as const satisfies ImageRatios

const COMPACT_WIDTHS = {
  desktop: 300,
  tablet: 300,
  mobile: 300,
  mobileSmall: 300,
} as const satisfies ImageWidths

const WIDTHS = {
  desktop: 632,
  tablet: 632,
  mobile: 430,
  mobileSmall: 320,
} as const satisfies ImageWidths

const Image: FunctionComponent<ImageProps> = ({
  src,
  alt,
  crops,
  children,
  className,
  layoutTemplate,
}) => (
  <ResponsiveImageV2
    src={src}
    alt={alt}
    crops={crops}
    ratios={RATIOS}
    widths={layoutTemplate === 'compact' ? COMPACT_WIDTHS : WIDTHS}
    className={className}>
    {children}
  </ResponsiveImageV2>
)

export default Image
