import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { desktopCSS, mobileLandscapeCSS } from '@measures/responsive'
import { splitOnLines } from '../../utils/string'

export type CaptionsElementsProps = {
  captions?: string[]
}

const CaptionsWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing4 },
    },
  }) => {
    return css`
      margin-top: ${spacing4};
      max-width: 300px;

      .jw-flag-fullscreen & {
        max-width: 300px;
      }

      ${desktopCSS(css`
        max-width: 400px;

        .jw-flag-fullscreen & {
          max-width: 600px;
        }
      `)}
    `
  }}
`

const TextWrapper = styled.div`
  ${({
    theme: {
      color: {
        transparency: { primary01Color80: primary01Color80Color },
      },
    },
  }) => {
    return css`
      text-align: center;
      background: ${primary01Color80Color};
    `
  }}
`

const CaptionsText = styled.div`
  ${({
    theme: {
      typography: {
        bodycopy: {
          xxsmall: { bundledCSS: xxsmallBodycopyCSS },
          small2: { bundledCSS: small2BodycopyCSS },
          medium2: { bundledCSS: medium2BodycopyCSS },
          xlarge: { bundledCSS: xlargeBodycopyCSS },
        },
      },
      color: {
        primary: { primary02: primary02Color },
      },
      spacing: { spacing2, spacing4 },
    },
  }) => {
    const desktopStyles = css`
      ${medium2BodycopyCSS}
      padding: 0 6px ${spacing2} 6px;

      .jw-flag-fullscreen & {
        ${xlargeBodycopyCSS}
      }
    `

    const mobileStyles = css`
      ${xxsmallBodycopyCSS}

      padding: 0 ${spacing4} ${spacing2} ${spacing4};

      .jw-flag-fullscreen & {
        ${small2BodycopyCSS}
      }
    `

    const mobileLandscapeStyles = css`
      .jw-flag-fullscreen & {
        ${medium2BodycopyCSS}
      }
    `

    return css`
      color: ${primary02Color};
      text-align: center;

      ${mobileStyles}

      ${mobileLandscapeCSS(mobileLandscapeStyles)}
      ${desktopCSS(desktopStyles)}
    `
  }}
`

const CaptionsElements: FunctionComponent<CaptionsElementsProps> = ({
  captions,
}) => {
  return captions && captions.length ? (
    <CaptionsWrapper>
      <TextWrapper>
        {captions.map((caption, parentIndex) =>
          splitOnLines(caption).map((captionText, index) =>
            captionText ? (
              <CaptionsText key={`${parentIndex}_${index}`}>
                {captionText}
              </CaptionsText>
            ) : null
          )
        )}
      </TextWrapper>
    </CaptionsWrapper>
  ) : null
}

export default CaptionsElements
