import { FunctionComponent } from 'react'
import styled, { css, useTheme } from 'styled-components'
import { desktopCSS } from '@measures/responsive'
import ItemContainer from './ItemContainer'
import CloseButton from './CloseButton'
import SvgIcon from '@components/SvgIcon'
import PrimaryCTAButton from '@components/Buttons/PrimaryCTAButton'
import translate from '@i18n'

export interface EndOfFastlaneItemProps {
  onClose: () => void
}

const StyledItemContainer = styled(ItemContainer)`
  ${desktopCSS(css`
    margin-bottom: 64px;
  `)}
`

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  z-index: 6;
  top: 16px;
  right: 16px;

  ${desktopCSS(css`
    display: none;
  `)}
`

const ContentWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing16, spacing24 },
    },
  }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${spacing24};
    padding: ${spacing16};
  `}
`

const Text = styled.div`
  ${({
    theme: {
      typography: {
        headings: {
          small: { bundledCSS: smallHeadingsCSS },
        },
      },
      color: {
        primary: { primary02: primary02Color },
      },
    },
  }) => css`
    ${smallHeadingsCSS};
    color: ${primary02Color};
    text-align: center;
  `}
`

const EndOfFastlaneItem: FunctionComponent<EndOfFastlaneItemProps> = ({
  onClose,
}) => {
  const theme = useTheme()

  return (
    <StyledItemContainer>
      <StyledCloseButton onClick={onClose} />
      <ContentWrapper>
        <SvgIcon
          iconName="party-horn"
          size={80}
          color={theme.color.primary.primary02}
        />
        <Text>{translate('blickBites.endOfFastlaneTitle')}</Text>
        <PrimaryCTAButton onClick={onClose}>
          {translate('blickBites.back')}
        </PrimaryCTAButton>
      </ContentWrapper>
    </StyledItemContainer>
  )
}

export default EndOfFastlaneItem
