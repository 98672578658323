import { FunctionComponent } from 'react'
import { AuthorHeadlineProps } from '@widgets/AuthorHeadline/types'
import styled, { css } from 'styled-components'
import { desktopCSS } from '@measures/responsive'
import SocialMedia from '@widgets/AuthorHeadline/SocialMedia'

type AuthorInfoProps = Omit<AuthorHeadlineProps, 'image' | 'bio'>

const StyledWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing24 },
    },
  }) => {
    return css`
      display: flex;
      flex-direction: column;
      margin-left: 0;
      align-items: center;
      margin-top: ${spacing24};
      ${desktopCSS(css`
        margin-left: ${spacing24};
        align-items: normal;
        margin-top: 0;
      `)}
    `
  }}
`

const Name = styled.span`
  ${({
    theme: {
      typography: {
        headings: {
          large: { bundledCSS: largeHeadingCSS },
        },
      },
    },
  }) => {
    return css`
      ${largeHeadingCSS};
      text-align: center;
      ${desktopCSS(css`
        text-align: left;
      `)}
    `
  }}
`

const Role = styled.span`
  ${({
    theme: {
      typography: {
        subheads: {
          large2: { bundledCSS: large2SubheadingCSS },
        },
      },
      spacing: { spacing4 },
    },
  }) => {
    return css`
      ${large2SubheadingCSS};
      margin-top: ${spacing4};
      text-align: center;
      ${desktopCSS(css`
        text-align: left;
      `)}
    `
  }}
`

const AuthorInfo: FunctionComponent<AuthorInfoProps> = ({
  name,
  role,
  socialMedia,
}) => (
  <StyledWrapper>
    <Name>{name}</Name>
    {role && <Role>{role}</Role>}
    {!!socialMedia && <SocialMedia>{socialMedia}</SocialMedia>}
  </StyledWrapper>
)

export default AuthorInfo
