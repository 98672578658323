import EmbeddedContent from '@components/EmbeddedContent'
import config from '@config'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { HeimspielResultProps, ValidHeimspielResultProps } from './types'
import { isValidHeimspielResultProps } from './utils'

type CreateEmbedUrlType = (
  ...args: [matchId: ValidHeimspielResultProps['matchId']]
) => string

const {
  heimspiel: { heimspielResultStaticPageUrl },
} = config

const StyledHeimspielResult = styled.div`
  min-height: 512px;
`

const createEmbeddedUrl: CreateEmbedUrlType = (matchId) =>
  `${heimspielResultStaticPageUrl}?matchId=${matchId}`

const HeimspielResult: FunctionComponent<HeimspielResultProps> = (props) => {
  if (!isValidHeimspielResultProps(props)) {
    return null
  }

  const { matchId } = props

  return (
    <StyledHeimspielResult>
      <EmbeddedContent
        bypassCMP={true}
        maxHeight={500}
        url={createEmbeddedUrl(matchId)}
      />
    </StyledHeimspielResult>
  )
}

const widget = {
  kind: ['widget', 'heimspiel-result'],
  component: HeimspielResult,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
