import { useEffect, useState } from 'react'
import usePageMetadata from './usePageMetadata'

type UseCurrentSectionType = (title: string) => {
  isActive: boolean
}

const useCurrentSection: UseCurrentSectionType = (title) => {
  const [isActive, setIsActive] = useState<boolean>(false)
  const pageMetadata = usePageMetadata()

  useEffect(() => {
    const isActive = pageMetadata.breadcrumbs
      .map((breadcrumb) => breadcrumb.title)
      .includes(title)
    setIsActive(isActive)
  }, [title, pageMetadata])

  return {
    isActive,
  }
}

export default useCurrentSection
