import {
  LiveEventDataSoccer,
  LiveEventDataSoccerStatus,
  LiveEventDataSoccerTeam,
  LiveEventGoals,
} from './liveEvent'
import {
  LIVE_EVENT_DATA_SOCCER_MATCH_STATUS,
  LIVE_EVENT_DATA_STATUS_TYPES,
  LIVE_EVENT_DATA_TEAM_QUALIFIER_TYPES,
  formatScheduledAt,
  formatScheduledAtShort,
} from '@utils/formatters/common'
import translate from '@i18n'

export interface LiveEventTransformedSoccerTeam {
  abbreviation: string
  aggregationScore: number
  logoUrl: string
  name: string
  score: number
  scoreBeforePenalties: number
  scorePenalties: number
}

export function formatTeam(status: LiveEventDataSoccerStatus) {
  return ({
    qualifier,
    abbreviation,
    sdpTeamLogoOptimized,
    name,
  }: LiveEventDataSoccerTeam): LiveEventTransformedSoccerTeam => {
    let aggregationScore = 0
    let score = 0
    let scoreBeforePenalties = 0
    let scorePenalties = 0

    if (qualifier === LIVE_EVENT_DATA_TEAM_QUALIFIER_TYPES.HOME) {
      aggregationScore = status.home_score_first_leg ?? 0
      score = status?.home_score ?? 0
      scoreBeforePenalties = status?.home_score_before_penalties ?? 0
      scorePenalties = status?.home_score_penalties ?? 0
    } else if (qualifier === LIVE_EVENT_DATA_TEAM_QUALIFIER_TYPES.AWAY) {
      aggregationScore = status.away_score_first_leg ?? 0
      score = status?.away_score ?? 0
      scoreBeforePenalties = status?.away_score_before_penalties ?? 0
      scorePenalties = status?.away_score_penalties ?? 0
    }

    return {
      abbreviation,
      aggregationScore,
      logoUrl: sdpTeamLogoOptimized,
      name,
      score,
      scoreBeforePenalties,
      scorePenalties,
    }
  }
}

export interface LiveEventTransformedSoccer {
  aggregateIndicator?: string
  extraTimeIndicator?: string
  hasPenalties?: boolean
  isFinished: boolean
  isLive: boolean
  isScheduled: boolean
  goalsInfo?: LiveEventGoals
  scoreExplanation: string
  statusTitle: string
  subStatusTitle: string
  sdpScheduledAtShort: string
  teams: LiveEventTransformedSoccerTeam[]
  tournamentName: string
}

export function transformMatch(
  match: LiveEventDataSoccer
): LiveEventTransformedSoccer {
  const { competitors } = match.sport_event
  const {
    has_penalties,
    extra_time_indicator,
    match_status,
    score_explanation,
  } = match.sport_event_status
  const isEnded = match.sdpStatus === LIVE_EVENT_DATA_STATUS_TYPES.FINISHED
  const isLive = match.sdpIsLive || false
  const isScheduled = !isLive && !isEnded

  const statusTitle = isLive
    ? translate('liveEvent.live')
    : isEnded
      ? ''
      : formatScheduledAt(match.sdpScheduledAt)

  let subStatusTitle
  switch (match_status) {
    case LIVE_EVENT_DATA_SOCCER_MATCH_STATUS.OVERTIME:
      subStatusTitle = translate('matchStatus.overtime')
      break
    case LIVE_EVENT_DATA_SOCCER_MATCH_STATUS.PENALTIES:
      subStatusTitle = translate('matchStatus.penaltiesRunning')
      break
    case LIVE_EVENT_DATA_SOCCER_MATCH_STATUS.AET:
      subStatusTitle = translate('matchStatus.afterExtraTime')
      break
    case LIVE_EVENT_DATA_SOCCER_MATCH_STATUS.AP:
      subStatusTitle = translate('matchStatus.afterPenalty')
      break
    default:
      subStatusTitle = isLive
        ? translate('liveEvent.live')
        : isEnded
          ? translate('matchStatus.ended')
          : formatScheduledAt(match.sdpScheduledAt)
  }

  return JSON.parse(
    JSON.stringify({
      aggregateIndicator: match.sport_event_status?.aggregate_indicator,
      goalsInfo: match.goalsInfo,
      hasPenalties: has_penalties,
      extraTimeIndicator: extra_time_indicator,
      scoreExplanation: score_explanation,
      statusTitle,
      subStatusTitle,
      sdpScheduledAtShort: formatScheduledAtShort(match.sdpScheduledAt),
      tournamentName: match.sdpTournamentName,
      isFinished: isEnded,
      isLive,
      isScheduled,
      teams: competitors
        ? competitors.map(formatTeam(match.sport_event_status))
        : [],
    })
  )
}

const transformLiveEvent = transformMatch

export { transformLiveEvent }
