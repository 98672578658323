import { FunctionComponent, useEffect, useRef } from 'react'

export interface InfiniteScrollProps {
  fetchData: () => void
  hasMore: boolean
  isLoading: boolean
  children: React.ReactNode
  root?: IntersectionObserverInit['root']
  rootMargin?: IntersectionObserverInit['rootMargin']
  threshold?: IntersectionObserverInit['threshold']
}

const InfiniteScroll: FunctionComponent<InfiniteScrollProps> = ({
  children,
  hasMore,
  isLoading = false,
  fetchData,
  root,
  rootMargin,
  threshold,
}) => {
  const observerTargetRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !isLoading) {
          fetchData()
        }
      },
      { root, rootMargin, threshold }
    )
    const localObserverTargetRef = observerTargetRef.current

    if (localObserverTargetRef) {
      observer.observe(localObserverTargetRef)
    }

    return () => {
      if (localObserverTargetRef) {
        observer.unobserve(localObserverTargetRef)
      }
    }
  }, [fetchData, hasMore, isLoading, root, rootMargin, threshold])

  return (
    <>
      {children}
      <div ref={observerTargetRef}></div>
    </>
  )
}

export default InfiniteScroll
