import dynamic from 'next/dynamic'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { allowedHtmlTags } from '@widgets/TextBody'

interface Props {
  text: string
}

const HTMLView = dynamic(() => import('@components/HTMLView'))

const SummaryContentWrapper = styled.div`
  ${({
    theme: {
      color: {
        primary: { blickRed: blickRedColor },
      },
      spacing: { spacing12, spacing16 },
      typography: {
        bodycopy: {
          small2: { bundledCSS: small2BodyCopy },
        },
      },
    },
  }) => css`
    ${small2BodyCopy}
    margin-bottom: ${spacing16};

    ul {
      list-style-type: disc;
      margin-top: 0;
      margin-left: ${spacing16};
      padding-left: ${spacing16};

      li {
        margin: 0;
        padding-bottom: ${spacing12};

        &:last-child {
          padding-bottom: 0;
        }
      }

      li::marker {
        color: ${blickRedColor};
        font-size: 20px;
        line-height: 8px;
      }
    }
  `}
`

const SummaryContent: FunctionComponent<Props> = ({ text }) => {
  return (
    <SummaryContentWrapper>
      <HTMLView content={text} allowedHtmlTags={allowedHtmlTags} />
    </SummaryContentWrapper>
  )
}

export default SummaryContent
