import styled, { css } from 'styled-components'
import { desktopCSS } from '@measures/responsive'
import { StyledSectionHeader as SectionHeaderWrapper } from '@components/SectionHeader'

const UltraModuleWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing24, spacing32 },
    },
  }) => css`
    width: 100%;
    display: grid;
    grid-gap: ${spacing24};
    align-items: flex-start;
    grid-template-columns: minmax(0, 1fr);

    ${desktopCSS(css`
      grid-gap: ${spacing32};
      grid-template-columns: repeat(12, minmax(0, 1fr));
    `)};

    ${SectionHeaderWrapper} {
      ${desktopCSS(css`
        grid-column: 1 / -1;
      `)};
    }
  `}
`

export default UltraModuleWrapper
