import EmbeddedContent from '@components/EmbeddedContent'
import config from '@config'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent } from 'react'
import styled from 'styled-components'
import {
  HeimspielStatisticsPlayerProps,
  ValidHeimspielStatisticsPlayerProps,
} from './types'
import { isValidHeimspielStatisticsPlayerProps } from './utils'

type CreateEmbedUrlType = (
  ...args: [matchId: ValidHeimspielStatisticsPlayerProps['matchId']]
) => string

const {
  heimspiel: { heimspielStatisticsPlayerStaticPageUrl },
} = config

const StyledHeimspielStatisticsPlayer = styled.div`
  min-height: 512px;
`

const createEmbeddedUrl: CreateEmbedUrlType = (matchId) =>
  `${heimspielStatisticsPlayerStaticPageUrl}?matchId=${matchId}`

const HeimspielStatisticsPlayer: FunctionComponent<
  HeimspielStatisticsPlayerProps
> = (props) => {
  if (!isValidHeimspielStatisticsPlayerProps(props)) {
    return null
  }

  const { matchId } = props

  return (
    <StyledHeimspielStatisticsPlayer>
      <EmbeddedContent
        bypassCMP={true}
        maxHeight={500}
        url={createEmbeddedUrl(matchId)}
      />
    </StyledHeimspielStatisticsPlayer>
  )
}

const widget = {
  kind: ['widget', 'heimspiel-statisticsPlayer'],
  component: HeimspielStatisticsPlayer,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
