import { FunctionComponent } from 'react'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import TruncateCharacters from '@components/TruncateCharacters'

interface ExpandableTextProps {
  text: string
}

const isValidExpandableTextProps = (props: any): props is ExpandableTextProps =>
  !!props?.text

const ExpandableText: FunctionComponent<ExpandableTextProps> = (props) => {
  if (!isValidExpandableTextProps(props)) {
    return null
  }

  return <TruncateCharacters text={props.text} />
}

const widget = {
  kind: ['widget', 'text', 'expandable'],
  component: ExpandableText,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
