import { ConfigProps } from '@config/types'

const config = ({ publication }: ConfigProps) =>
  ({
    mailtoAddress: `${
      publication === 'romandie' ? 'signalement' : 'fehler'
    }@blick.ch`,
  }) as const satisfies Record<string, unknown>

export default config
