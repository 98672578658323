import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { desktopCSS } from '@measures/responsive'

export interface SubtitleProps {
  children: string
}

const StyledSubtitle = styled.div`
  ${({
    theme: {
      spacing: { spacing8 },
      color: {
        tertiary: { grey100: grey100Color },
      },
      typography: {
        subheads: {
          medium2: { bundledCSS: medium2SubheadingCSS },
          large2: { bundledCSS: large2SubheadingCSS },
        },
      },
    },
  }) => {
    const textStyle = css`
      ${medium2SubheadingCSS};
      ${desktopCSS(css`
        ${large2SubheadingCSS};
      `)};

      color: ${grey100Color};
    `

    //! We apply these rules multiple time to override
    //! browser and body styles applying on `strong` and `p` tags.
    return css`
      position: relative;
      margin-top: ${spacing8};
      ${textStyle};

      > strong,
      > p {
        ${textStyle};
      }

      > p {
        margin: 0;
      }
    `
  }}
`

const Subtitle: FunctionComponent<SubtitleProps> = ({ children }) => {
  return <StyledSubtitle dangerouslySetInnerHTML={{ __html: children }} />
}

export default Subtitle
