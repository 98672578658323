import { desktopCSS } from '@measures/responsive'
import { BackgroundVideoType } from '@widgets/TeaserToi'
import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'

export interface VideoBackgroundProps {
  children?: ReactNode
  className?: string
  videoType: Exclude<BackgroundVideoType, undefined>
}

export type StyledVideoBackgroundProps = Pick<VideoBackgroundProps, 'videoType'>

const StyledVideoBackground = styled.div<StyledVideoBackgroundProps>`
  ${({
    theme: {
      spacing: { spacing16, spacing24 },
      color: {
        primary: { primary01: primary01Color },
      },
    },
    videoType,
  }) => css`
    user-select: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    ${videoType === 'fit-to-width' &&
    css`
      box-sizing: border-box;
      background-color: ${primary01Color};
      padding: ${spacing24} ${spacing16} 0 ${spacing16};
      ${desktopCSS(css`
        padding: ${spacing24} ${spacing24} 0 ${spacing24};
      `)};
    `};
  `}
`

const VideoAligner = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
`

const VideoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const VideoBackground: FunctionComponent<VideoBackgroundProps> = ({
  children,
  className,
  videoType,
}) => {
  return (
    <StyledVideoBackground className={className} videoType={videoType}>
      {videoType === 'stretched-to-fill' ? (
        children
      ) : (
        <VideoAligner>
          <VideoWrapper>{children}</VideoWrapper>
        </VideoAligner>
      )}
    </StyledVideoBackground>
  )
}

export default VideoBackground
