import EmbeddedContent from '@components/EmbeddedContent'
import config from '@config'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent } from 'react'
import styled from 'styled-components'
import {
  HeimspielStartingListProps,
  ValidHeimspielStartingListProps,
} from './types'
import { isValidHeimspielStartingListProps } from './utils'

type CreateEmbedUrlType = (
  ...args: [matchId: ValidHeimspielStartingListProps['matchId']]
) => string

const {
  heimspiel: { heimspielStartingListStaticPageUrl },
} = config

const StyledHeimspielStartingList = styled.div`
  min-height: 512px;
`

const createEmbeddedUrl: CreateEmbedUrlType = (matchId) =>
  `${heimspielStartingListStaticPageUrl}?matchId=${matchId}`

const HeimspielStartingList: FunctionComponent<HeimspielStartingListProps> = (
  props
) => {
  if (!isValidHeimspielStartingListProps(props)) {
    return null
  }

  const { matchId } = props

  return (
    <StyledHeimspielStartingList>
      <EmbeddedContent
        bypassCMP={true}
        maxHeight={500}
        url={createEmbeddedUrl(matchId)}
      />
    </StyledHeimspielStartingList>
  )
}

const widget = {
  kind: ['widget', 'heimspiel-startingList'],
  component: HeimspielStartingList,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
