import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { TransformedDataHockey, TransformedDataSoccer } from './utils'
import TeamLogo from './TeamLogo'

type MinimalSportsFooterProps = {
  sportsData: TransformedDataHockey | TransformedDataSoccer
  className?: string
}

type TeamLogoProps = { type: 'away' | 'home' }

const Wrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing8, spacing24 },
      color: {
        primary: { primary02 },
      },
    },
  }) => css`
    display: grid;
    grid-template-areas: 'L1 SC L2';
    grid-template-columns: ${spacing24} 1fr ${spacing24};
    align-items: center;
    color: ${primary02};
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    box-sizing: border-box;
    padding: 0 ${spacing8};
    height: 32px;
  `}
`

const StyledTeamLogo = styled(TeamLogo)<TeamLogoProps>`
  ${({ type }) => css`
    grid-area: ${type === 'home' ? 'L1' : 'L2'};
  `}
`

const MiddleInfo = styled.span`
  ${({
    theme: {
      typography: {
        headings: {
          xxsmall: { bundledCSS: headingsXXSmall },
        },
      },
    },
  }) => css`
    ${headingsXXSmall};
    grid-area: SC;
    justify-self: center;
    align-self: center;
    text-align: center;
  `}
`

const ScoreExplanation = styled.span`
  ${({
    theme: {
      typography: {
        eyebrows: {
          small2: { bundledCSS: eyebrowsSmall2 },
        },
      },
    },
  }) => css`
    ${eyebrowsSmall2};
    display: block;
  `}
`

const MinimalHockeySoccer: FunctionComponent<MinimalSportsFooterProps> = ({
  sportsData,
  className,
}) => {
  const {
    homeTeam,
    homeTeamScore,
    awayTeam,
    awayTeamScore,
    isLive,
    hasEnded,
    scoreExplanation,
  } = sportsData

  return (
    <Wrapper className={className}>
      <StyledTeamLogo
        type="home"
        url={homeTeam.logoUrl}
        name={homeTeam.name}
        imageWidth={24}
      />
      <MiddleInfo>
        {!isLive && !hasEnded ? 'VS' : homeTeamScore + ' : ' + awayTeamScore}
        {scoreExplanation && (
          <ScoreExplanation>{scoreExplanation}</ScoreExplanation>
        )}
      </MiddleInfo>
      <StyledTeamLogo
        type="away"
        url={awayTeam.logoUrl}
        name={awayTeam.name}
        imageWidth={24}
      />
    </Wrapper>
  )
}

export default MinimalHockeySoccer
