import { FunctionComponent, useCallback, useEffect, useRef } from 'react'
import styled, { useTheme } from 'styled-components'

import { isMobileUserAgent } from '@measures/responsive'

import useVideoOverlay from '@hooks/useVideoOverlay'
import Head from 'next/head'

const StyledVideoOverlayBackground = styled.div<{ isOverlay?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000000;

  ${({ isOverlay = false }) => `display: ${isOverlay ? 'block' : 'none'};`}
`

const VideoOverlayBackground: FunctionComponent = () => {
  const theme = useTheme()

  const { isOverlay } = useVideoOverlay()

  const isOverlayShownRef = useRef(false)

  const showOverlay = useCallback(() => {
    if (!isOverlayShownRef.current && isMobileUserAgent()) {
      //! Set background to black, so that when we switch to
      //! landscape we don't have white background in the
      //! safe area of an iPhone X / Xs / 11..
      document?.body?.classList?.add?.('video-overlay-shown')

      //! Scroll down to hide the tab bar on landscape mode
      window?.scrollTo?.(0, (window?.scrollY ?? 0) + 100)

      isOverlayShownRef.current = true
    }
  }, [])

  const hideOverlay = useCallback(() => {
    if (isOverlayShownRef.current && isMobileUserAgent()) {
      document?.body?.classList?.remove?.('video-overlay-shown')

      //! Scroll down to hide the tab bar on landscape mode
      window?.scrollTo?.(0, (window?.scrollY ?? 0) - 100)

      isOverlayShownRef.current = false
    }
  }, [])

  useEffect(() => {
    if (isOverlay) {
      showOverlay()
    } else {
      hideOverlay()
    }
  }, [hideOverlay, isOverlay, showOverlay])

  return (
    <>
      <StyledVideoOverlayBackground isOverlay={isOverlay} />
      <Head>
        {/* 
          //! we need `toggleThemeColor` because of Safari - Apple moved the address bar to the bottom and introduced something called a theme-color
          //! https://css-tricks.com/safari-15-new-ui-theme-colors-and-a-css-tricks-cameo/
          //! So whenever a user is in fullscreen mode it should turn to black, because otherwise some of the page is staying white
        */}
        <meta
          key="theme-color"
          name="theme-color"
          content={isOverlay ? '#000000' : theme.color.primary.primary02}
        />
      </Head>
    </>
  )
}

export default VideoOverlayBackground
