import config from '@config'
import {
  GetListItemTeasers,
  isResultItemProps,
  SearchResponseAPIProps,
} from './types'
import { ListItemTeaserAPIProps } from '@components/TeaserListItem/types'

const {
  publication: { publication },
  search: { apiUrl: searchApiUrl },
} = config

const getResults = async (
  submittedSearchText: string,
  page: number,
  noResultTrackingFn: (extraData: { searchTerm: string | undefined }) => void
): Promise<SearchResponseAPIProps> => {
  const result = await (
    await fetch(
      `${searchApiUrl}?pub=${publication}&q=${encodeURIComponent(
        submittedSearchText
      )}&page=${page}`
    )
  ).json()

  if (result?.total_elements === 0) {
    noResultTrackingFn({ searchTerm: submittedSearchText })
  }

  return result
}

const getResultNumberRange = (resultNumber: number) => {
  return (
    (resultNumber === 0 && '0') ||
    (resultNumber >= 1 && resultNumber <= 3 && '1-3') ||
    (resultNumber >= 4 && resultNumber <= 9 && '4-9') ||
    (resultNumber >= 10 && resultNumber <= 30 && '10-30') ||
    'over 30'
  )
}

const enrichListItemTeasers = (
  teasers: ListItemTeaserAPIProps[]
): ListItemTeaserAPIProps[] => {
  return teasers
    .filter((teaser) => isResultItemProps(teaser))
    .map((teaser) => ({
      kind: ['teaser-list-item'],
      ...teaser,
    }))
}

const getListItemTeasers = (
  teasers: ListItemTeaserAPIProps[],
  isResultsAvailable?: boolean
): GetListItemTeasers => {
  let firstPartOfResults: ListItemTeaserAPIProps[] = []
  let restOfResults: ListItemTeaserAPIProps[] = []

  if (isResultsAvailable) {
    firstPartOfResults = enrichListItemTeasers(teasers.slice(0, 3))
    restOfResults = enrichListItemTeasers(teasers.slice(3))
  }
  return { firstPartOfResults, restOfResults }
}

export { getResults, getResultNumberRange, getListItemTeasers }
