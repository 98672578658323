import EndOfArticleContext from '@contexts/endOfArticle'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import { stripHtml } from '@hooks/useTracking/utils'
import { FunctionComponent, useCallback, useContext } from 'react'
import SocialButton from './Button'
import { getSocialEmbedConfig, getUrlEncodedUTMParameters } from './utils'

interface TwitterProps {
  shareUrl: string
  shareTitle: string
  shareUrlHash?: string
  utmContent?: string
  label?: string
}

const Twitter: FunctionComponent<TwitterProps> = ({
  shareUrl,
  shareTitle,
  shareUrlHash,
  utmContent,
  label,
}) => {
  const endOfArticle = useContext(EndOfArticleContext)
  const trackingOnClick = useCallback<TrackingFnType>(
    () => ({
      event: 'element_click',
      element: `article_share_${endOfArticle ? 'bottom' : 'top'}`,
      method: 'twitter',
      utm_content: utmContent,
    }),
    [endOfArticle, utmContent]
  )
  const trackingOnClickLegacy = useCallback<TrackingFnType>(
    ({ pageMetadata }) => ({
      event: 'article_share',
      eventCategory: 'article',
      eventAction: `share_twitter_${endOfArticle ? 'bottom' : 'top'}`,
      eventLabel: `${pageMetadata.id}:${stripHtml(pageMetadata.title)}`,
      utm_content: utmContent,
    }),
    [endOfArticle, utmContent]
  )
  const trackingFunction = useTracking(trackingOnClick)
  const trackingFunctionLegacy = useTracking(trackingOnClickLegacy)

  const onClick = useCallback(() => {
    trackingFunction()
    // The *Legacy tracking functions will be deleted when the tracking team is ready to switch to the new events
    trackingFunctionLegacy()
    const { utmSource, utmMedium, utmCampaign } =
      getSocialEmbedConfig('twitter')

    const utm = getUrlEncodedUTMParameters(
      utmMedium,
      utmCampaign,
      utmSource,
      utmContent
    )
    return window.open(
      `https://twitter.com/intent/tweet?text=${shareTitle}&url=${shareUrl}${utm}${shareUrlHash ?? ''}`,
      '_blank',
      'noopener noreferrer nofollow'
    )
  }, [
    trackingFunction,
    trackingFunctionLegacy,
    utmContent,
    shareTitle,
    shareUrl,
    shareUrlHash,
  ])

  return (
    <SocialButton type="twitter" onClick={onClick}>
      {label}
    </SocialButton>
  )
}

export default Twitter
