import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent } from 'react'
import FacebookComponent from '@components/SocialEmbed/Facebook'

interface FacebookProps {
  url: string
}

const Facebook: FunctionComponent<FacebookProps> = ({ url }) => {
  return <FacebookComponent url={url} />
}

const widget = {
  kind: ['widget', 'social-embed', 'facebook'],
  component: Facebook,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
