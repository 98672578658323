import styled, { css } from 'styled-components'
import { desktopCSS } from '@measures/responsive'

export interface SideAdProps {
  hasRelatedItems: boolean
}

const SideAd = styled.div<SideAdProps>`
  ${({
    theme: {
      spacing: { spacing8 },
    },
    hasRelatedItems,
  }) => css`
    ${desktopCSS(css`
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      ${hasRelatedItems &&
      css`
        padding-top: ${spacing8};
      `};
    `)};
  `}
`

export default SideAd
