import { useCallback, useEffect, useRef, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { ViewportDimensions } from './types'

const useViewportDimensions = () => {
  const queryClient = useQueryClient()
  const queryCache = queryClient.getQueryCache()
  const unsubscribeFnRef = useRef<() => void>()

  const [viewportDimensions, setViewportDimensions] =
    useState<ViewportDimensions>(
      queryClient.getQueryData<ViewportDimensions>([
        'viewportDimensions',
      ]) as ViewportDimensions
    )

  const updateDimensions = useCallback(
    (args: any) => {
      if (
        ['invalidate'].includes(args?.action?.type) &&
        args.query.queryKey[0] === 'viewportDimensions'
      ) {
        const currentViewportDimensions =
          queryClient.getQueryData<ViewportDimensions>(['viewportDimensions'])
        if (
          currentViewportDimensions?.width &&
          currentViewportDimensions?.height
        ) {
          setViewportDimensions(currentViewportDimensions)
        }
      }
    },
    [queryClient]
  )

  useEffect(() => {
    const currentViewportDimensions =
      queryClient.getQueryData<ViewportDimensions>(['viewportDimensions'])
    if (currentViewportDimensions?.height && currentViewportDimensions?.width) {
      setViewportDimensions(currentViewportDimensions)
    }
    unsubscribeFnRef.current = queryCache.subscribe(updateDimensions)

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [queryCache, queryClient, updateDimensions])

  return viewportDimensions
}

export default useViewportDimensions
