import {
  FunctionComponent,
  memo,
  useRef,
  useCallback,
  useEffect,
  useState,
  ElementType,
  ReactNode,
} from 'react'
import styled, { css } from 'styled-components'
import isEqualReact from 'fast-deep-equal/es6/react'
import DefaultPlaceholder, { PlaceholderProps } from './Placeholder'
import { generatePictureSources } from './utils'
import { ImageRatios, ImageWidths } from './types'
import { AvailableImageCrops, Crops } from '@utils/cook/types'

export interface ResponsiveImageV2Props {
  src: string
  alt: string
  ratios: ImageRatios
  crops: Partial<Crops<AvailableImageCrops>>
  widths: ImageWidths
  loading?: HTMLImageElement['loading']
  disablePlaceholder?: boolean
  isLazy?: boolean
  Placeholder?: ElementType<PlaceholderProps>
  className?: string
  onErrorCallback?: () => void
  onLoadCallback?: () => void
  children?: ReactNode
}

interface StyledPictureProps {
  hasError: boolean
}

const StyledPicture = styled.picture<StyledPictureProps>`
  ${({ hasError }) =>
    hasError &&
    css`
      display: none;
    `}
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  > img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const ResponsiveImageV2: FunctionComponent<ResponsiveImageV2Props> = ({
  src,
  alt,
  ratios,
  crops,
  widths,
  loading,
  isLazy = true,
  Placeholder = DefaultPlaceholder,
  onErrorCallback,
  onLoadCallback,
  disablePlaceholder,
  className,
  children,
}) => {
  const [hasError, setHasError] = useState<boolean>(false)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const imageRef = useRef<HTMLImageElement>(null)

  const onError = useCallback(() => {
    setHasError(true)
  }, [])

  const onLoad = useCallback(() => {
    setIsLoaded(true)
  }, [])

  useEffect(() => {
    const currentImage = imageRef.current
    currentImage?.addEventListener('error', onError)
    return () => {
      currentImage?.removeEventListener('error', onError)
    }
  }, [onError])

  useEffect(() => {
    if (hasError) {
      onErrorCallback?.()
    }
  }, [hasError, onErrorCallback])

  useEffect(() => {
    const currentImage = imageRef.current
    currentImage?.addEventListener('load', onLoad)
    return () => {
      currentImage?.removeEventListener('load', onLoad)
    }
  }, [onLoad])

  useEffect(() => {
    if (isLoaded) {
      onLoadCallback?.()
    }
  }, [isLoaded, onLoadCallback])

  if (src === '/incoming/31574-v2-missing.png') {
    return (
      <Placeholder ratios={ratios} isLoaded={isLoaded} className={className}>
        {children}
      </Placeholder>
    )
  }

  const {
    desktop: {
      mediaQuery: mediaQueryDesktop,
      dpr1: pictureSourceDesktopDPR1,
      dpr2: pictureSourceDesktopDPR2,
      dpr3: pictureSourceDesktopDPR3,
    },
    tablet: {
      mediaQuery: mediaQueryTablet,
      dpr1: pictureSourceTabletDPR1,
      dpr2: pictureSourceTabletDPR2,
      dpr3: pictureSourceTabletDPR3,
    },
    mobileRegular: {
      mediaQuery: mediaQueryMobileRegular,
      dpr1: pictureSourceMobileRegularDPR1,
      dpr2: pictureSourceMobileRegularDPR2,
      dpr3: pictureSourceMobileRegularDPR3,
    },
    mobileSmall: {
      mediaQuery: mediaQueryMobileSmall,
      dpr1: pictureSourceMobileSmallDPR1,
      dpr2: pictureSourceMobileSmallDPR2,
      dpr3: pictureSourceMobileSmallDPR3,
    },
  } = generatePictureSources({
    src,
    ratios,
    crops,
    widths,
  })

  return (
    <Placeholder
      className={className}
      ratios={ratios}
      isLoaded={isLoaded}
      disablePlaceholder={disablePlaceholder}>
      <StyledPicture hasError={hasError}>
        <source
          media={mediaQueryDesktop}
          srcSet={`${pictureSourceDesktopDPR1} 1x, ${pictureSourceDesktopDPR2} 2x, ${pictureSourceDesktopDPR3} 3x`}
        />
        <source
          media={mediaQueryTablet}
          srcSet={`${pictureSourceTabletDPR1} 1x, ${pictureSourceTabletDPR2} 2x, ${pictureSourceTabletDPR3} 3x`}
        />
        <source
          media={mediaQueryMobileRegular}
          srcSet={`${pictureSourceMobileRegularDPR1} 1x, ${pictureSourceMobileRegularDPR2} 2x, ${pictureSourceMobileRegularDPR3} 3x`}
        />
        <source
          media={mediaQueryMobileSmall}
          srcSet={`${pictureSourceMobileSmallDPR1} 1x, ${pictureSourceMobileSmallDPR2} 2x, ${pictureSourceMobileSmallDPR3} 3x`}
        />
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          ref={imageRef}
          alt={alt}
          {...(loading ? { loading } : isLazy ? { loading: 'lazy' } : {})}
        />
      </StyledPicture>
      {children}
    </Placeholder>
  )
}

const MemoizedResponsiveImageV2 = memo(
  ResponsiveImageV2,
  (
    { src: prevSrc, children: prevChildren },
    { src: nextSrc, children: nextChildren }
  ) => prevSrc === nextSrc && isEqualReact(prevChildren, nextChildren)
)

MemoizedResponsiveImageV2.displayName = 'MemoizedResponsiveImageV2'

export default MemoizedResponsiveImageV2
