import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { BreadcrumbItem } from './index'
import Blink from '@components/Blink'

const commonItemStyle = css`
  position: relative;
  text-align: center;
  overflow: hidden;
`

const StyledItem = styled(Blink)`
  ${({
    theme: {
      color: {
        tertiary: { grey900 },
      },
      typography: {
        subheads: {
          small2: { bundledCSS: small2SubheadCSS },
        },
      },
    },
  }) => css`
    ${commonItemStyle};
    ${small2SubheadCSS};
    text-decoration: none;
    color: ${grey900};
  `}
`

const StyledLastItem = styled.span`
  ${({
    theme: {
      color: {
        primary: { primary01 },
      },
      typography: {
        subheads: {
          small1: { bundledCSS: small1SubheadCSS },
        },
      },
    },
  }) => css`
    ${commonItemStyle};
    ${small1SubheadCSS};
    color: ${primary01};
    margin: 0;
  `}
`

const Item: FunctionComponent<BreadcrumbItem> = ({ title, link, isLast }) => {
  if (isLast || !link) {
    return <StyledLastItem>{title}</StyledLastItem>
  }

  return <StyledItem {...link}>{title}</StyledItem>
}

export default Item
