import { Link } from '@utils/cook/types'
import { AuthorProps } from '@widgets/Author/types'

const AUTHOR_IMAGE_SIZE = 48
const AUTHOR_IMAGE_SIZE_PX = '48'
const AUTHOR_IMAGE_RATIO = '1_1'

const MAX_BRAND_LOGO_HEIGHT = 18
const MAX_BRAND_LOGO_WIDTH = 170

const MAX_BRAND_LOGO_HEIGHT_SCHWEIZERILLUSTRIERTE = 28
const MAX_BRAND_LOGO_WIDTH_SCHWEIZERILLUSTRIERTE = 265

// If there are more than 5 authors, we don't need to show images
const shouldDisplayImages = (authors: AuthorProps[]) => {
  return authors.length < 6 && authors.some((author) => !!author.image)
}

const isLink = (link?: Link) => {
  return !!link?.href
}

const CONSTRAINTS_FOR_BRAND_LOGOS = {
  desktop: {
    width: MAX_BRAND_LOGO_WIDTH,
    height: MAX_BRAND_LOGO_HEIGHT,
  },
  tablet: {
    width: MAX_BRAND_LOGO_WIDTH,
    height: MAX_BRAND_LOGO_HEIGHT,
  },
  mobile: {
    width: MAX_BRAND_LOGO_WIDTH,
    height: MAX_BRAND_LOGO_HEIGHT,
  },
  mobileSmall: {
    width: MAX_BRAND_LOGO_WIDTH,
    height: MAX_BRAND_LOGO_HEIGHT,
  },
}

const CONSTRAINTS_FOR_SCHWEIZERILLUSTRIERTE = {
  desktop: {
    width: MAX_BRAND_LOGO_WIDTH_SCHWEIZERILLUSTRIERTE,
    height: MAX_BRAND_LOGO_HEIGHT_SCHWEIZERILLUSTRIERTE,
  },
  tablet: {
    width: MAX_BRAND_LOGO_WIDTH_SCHWEIZERILLUSTRIERTE,
    height: MAX_BRAND_LOGO_HEIGHT_SCHWEIZERILLUSTRIERTE,
  },
  mobile: {
    width: MAX_BRAND_LOGO_WIDTH_SCHWEIZERILLUSTRIERTE,
    height: MAX_BRAND_LOGO_HEIGHT_SCHWEIZERILLUSTRIERTE,
  },
  mobileSmall: {
    width: MAX_BRAND_LOGO_WIDTH_SCHWEIZERILLUSTRIERTE,
    height: MAX_BRAND_LOGO_HEIGHT_SCHWEIZERILLUSTRIERTE,
  },
}

export {
  AUTHOR_IMAGE_SIZE,
  AUTHOR_IMAGE_SIZE_PX,
  AUTHOR_IMAGE_RATIO,
  CONSTRAINTS_FOR_BRAND_LOGOS,
  CONSTRAINTS_FOR_SCHWEIZERILLUSTRIERTE,
  shouldDisplayImages,
  isLink,
}
