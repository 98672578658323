import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { Link } from '@utils/cook/types'
import { desktopCSS } from '@measures/responsive'

import Blink from '@components/Blink'

interface DossierLink {
  text: string
  link: Link
}

interface ColumnListProps {
  title: string
  titleAlignment: 'top' | 'left'
  links: DossierLink[]
}

type StyledContainerProps = Pick<ColumnListProps, 'titleAlignment'>

const StyledContainer = styled.div<StyledContainerProps>`
  ${({
    theme: {
      spacing: { spacing24, spacing32 },
    },
    titleAlignment,
  }) => css`
    display: flex;
    flex-direction: row;

    ${titleAlignment === 'top' &&
    css`
      flex-direction: column;
      gap: ${spacing24};
    `}

    &:first-child {
      margin-top: ${spacing24};
    }

    ${desktopCSS(css`
      &:first-child {
        margin-top: ${spacing32};
      }
    `)}
  `}
`

const StyledLinksContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;

  ${desktopCSS(css`
    grid-template-columns: repeat(3, minmax(0, 1fr));
  `)}
`

type StyledTitleProps = StyledContainerProps

const StyledTitle = styled.span<StyledTitleProps>`
  ${({
    theme: {
      typography: {
        headings: {
          medium: { bundledCSS: headingsMediumCSS },
          xsmall: { bundledCSS: headingsXSmallCSS },
        },
      },
    },
    titleAlignment,
  }) => {
    if (titleAlignment === 'top') {
      return css`
        ${headingsMediumCSS};
        display: block;
        color: #000000;
      `
    }

    return css`
      ${headingsXSmallCSS};
      color: #000000;
      min-width: 43px;
    `
  }}
`

const StyledHr = styled.hr`
  border: 1px solid ${({ theme }) => theme.color.tertiary.grey200};
  width: 100%;

  &:last-child {
    display: none;
  }
`

const StyledBlink = styled(Blink)`
  ${({
    theme: {
      typography: {
        bodycopy: {
          small2: { bundledCSS: bodycopySmall2CSS },
        },
      },
    },
  }) => css`
    ${bodycopySmall2CSS};
    color: #000000;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    text-decoration: none;

    margin-right: 50px;
  `}
`

const ColumnList: FunctionComponent<ColumnListProps> = ({
  title,
  titleAlignment,
  links,
}) => {
  return (
    <>
      <StyledContainer titleAlignment={titleAlignment}>
        <StyledTitle titleAlignment={titleAlignment}>{title}</StyledTitle>
        <StyledLinksContainer>
          {links.map((link) => (
            <StyledBlink key={link.link.href} {...link.link}>
              {link.text}
            </StyledBlink>
          ))}
        </StyledLinksContainer>
      </StyledContainer>
      {titleAlignment !== 'top' && <StyledHr />}
    </>
  )
}

const widget = {
  kind: ['widget', 'column-list'],
  component: ColumnList,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
