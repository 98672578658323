import { useRef, useState, useCallback, useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'

export type UseIsInTeaserPreviewPage = () => boolean

const useIsInTeaserPreviewPage: UseIsInTeaserPreviewPage = () => {
  const queryClient = useQueryClient()
  const queryCache = queryClient.getQueryCache()
  const unsubscribeFnRef = useRef<() => void>()

  const [isInTeaserPreviewPage, setIsInTeaserPreviewPage] = useState<boolean>(
    () => !!queryClient.getQueryData<boolean>(['is-in-teaser-preview-page'])
  )

  const updateIsInTeaserPreviewPage = useCallback(
    (args: any) => {
      if (
        args?.action?.type === 'invalidate' &&
        args.query.queryKey[0] === 'is-in-teaser-preview-page'
      ) {
        setIsInTeaserPreviewPage(
          !!queryClient.getQueryData<boolean>(['is-in-teaser-preview-page'])
        )
      }
    },
    [queryClient]
  )

  useEffect(() => {
    setIsInTeaserPreviewPage(
      !!queryClient.getQueryData<boolean>(['is-in-teaser-preview-page'])
    )
    unsubscribeFnRef.current = queryCache.subscribe(updateIsInTeaserPreviewPage)

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [updateIsInTeaserPreviewPage, queryCache, queryClient])

  return isInTeaserPreviewPage
}

export default useIsInTeaserPreviewPage
