import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

interface TextProps {
  children: string
  className?: string
}

const StyledText = styled.div`
  ${({
    theme: {
      typography: {
        bodycopy: {
          xsmall: { bundledCSS: bodycopyXSmallCSS },
        },
      },
      color: {
        primary: { primary01 },
      },
    },
  }) => css`
    ${bodycopyXSmallCSS};
    color: ${primary01};
  `}
`
const Text: FunctionComponent<TextProps> = ({ children, className }) => (
  <StyledText className={className}>{children}</StyledText>
)

export default Text
