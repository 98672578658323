import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { mobileCSS } from '@measures/responsive'

const StyledRedAlert = styled.div`
  ${({
    theme: {
      color: {
        primary: { primary02: primary02Color, blickRed: blickRedColor },
      },
      typography: {
        bodycopy: {
          xsmall: { bundledCSS: xsmallBodycopyCSS },
        },
      },
      spacing: { spacing16, spacing40, spacing28, spacing96 },
    },
  }) => css`
    width: 100%;
    ${xsmallBodycopyCSS};
    background-color: ${blickRedColor};
    color: ${primary02Color};
    margin: ${spacing28} 0;
    text-align: center;
    padding: ${spacing16} ${spacing96};
    box-sizing: border-box;
    ${mobileCSS(css`
      padding: ${spacing16} ${spacing40};
    `)}
  `}
`

const ImportantText = styled.span`
  font-weight: 800;
`

const RedAlert: FunctionComponent = () => (
  <StyledRedAlert>
    Bist du Zeuge einer Notsituation? <br />
    Wähle immer erst den <ImportantText>Notruf</ImportantText>
    <br /> und leiste <ImportantText>Erste Hilfe</ImportantText>.
  </StyledRedAlert>
)

export default RedAlert
