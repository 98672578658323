import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent } from 'react'
import usePageMetadata from '@hooks/usePageMetadata'
import Social from '@components/Social'
import { SocialProps } from './types'

const SocialShare: FunctionComponent<SocialProps> = ({ endOfArticle }) => {
  const pageMetadata = usePageMetadata()

  const targetContentType = pageMetadata?.targetContentType

  if (!pageMetadata) {
    return null
  }

  if (targetContentType === 'video' && endOfArticle) {
    return null
  }

  return <Social endOfArticle={endOfArticle} />
}

const widget = {
  kind: ['widget', 'social', 'share'],
  component: SocialShare,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
