import config from '@config'
import { ImageSource, VideoMetadataPlaylist } from './types'

const {
  video: { videoMetadataUrl },
} = config

const fetchVideoMetadata = (jwVideoId: string) => {
  return fetch(`${videoMetadataUrl}${[jwVideoId]}`)
    .then((response) => response.json())
    .catch((error) => console.error(error))
}

const getMotionThumbnailSource = (sources?: ImageSource[]) => {
  const motionThubnailSources = sources?.filter(
    (s: ImageSource) => s.type === 'video/mp4'
  )

  if (!!motionThubnailSources?.length) {
    return motionThubnailSources.reduce((max, s) =>
      max.width > s.width ? max : s
    )?.src
  }
}

const getSmallestJpgImage = (playlist?: VideoMetadataPlaylist) => {
  const images = playlist?.images
  const jpgImages = images?.filter((image) => image.type === 'image/jpeg')

  const smallestImage = jpgImages?.reduce((smallest, current) => {
    return current.width < smallest.width ? current : smallest
  })

  return smallestImage?.src
}

const getM3U8File = (playlist?: VideoMetadataPlaylist) => {
  const sources = playlist?.sources

  const m3u8File = sources?.find(
    (source) => source.type === 'application/vnd.apple.mpegurl'
  )

  return m3u8File?.file
}

export {
  fetchVideoMetadata,
  getMotionThumbnailSource,
  getSmallestJpgImage,
  getM3U8File,
}
