import styled, { css, useTheme } from 'styled-components'
import { FunctionComponent } from 'react'
import SvgIcon from '@components/SvgIcon'

interface DatePublishedProps {
  children?: string
}

const StyledDatePublished = styled.div`
  ${({
    theme: {
      typography: {
        eyebrows: {
          small1: { bundledCSS: eyebrowSmall1CSS },
        },
      },
      spacing: { spacing2, spacing16 },
      color: {
        tertiary: { grey700 },
      },
    },
  }) => css`
    ${eyebrowSmall1CSS};
    box-sizing: border-box;
    padding-left: ${spacing16};
    text-align: right;
    color: ${grey700};
    display: flex;
    align-items: center;
    gap: ${spacing2};
  `}
`

const StyledSvgIcon = styled(SvgIcon)``

const DatePublished: FunctionComponent<DatePublishedProps> = ({ children }) => {
  const theme = useTheme()
  return (
    <StyledDatePublished suppressHydrationWarning={true}>
      <StyledSvgIcon
        iconName="clock-three"
        color={theme.color.tertiary.grey700}
        size={18}
      />
      {children}
    </StyledDatePublished>
  )
}

export default DatePublished
