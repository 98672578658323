import { extractContext } from '@components/Chatbot/utils'
import usePageMetadata from '@hooks/usePageMetadata'
import { useMemo } from 'react'

const useChatbotContext = () => {
  const pageMetadata = usePageMetadata()
  const chatbotContext = useMemo(
    () => extractContext(pageMetadata),
    [pageMetadata]
  )

  return chatbotContext
}

export default useChatbotContext
