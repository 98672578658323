import { SearchResultsContainerProps } from '@widgets/Search/types'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { mobileCSS } from '@measures/responsive'

const StyledContainer = styled.div`
  ${({
    theme: {
      spacing: { spacing16, spacing24 },
    },
  }) => css`
    position: relative;
    display: grid;
    grid-auto-flow: row;
    grid-row-gap: ${spacing24};
    ${mobileCSS(css`
      width: 100%;
      grid-row-gap: ${spacing16};
    `)}
  `}
`

const ResultContainer: FunctionComponent<SearchResultsContainerProps> = ({
  children,
}) => <StyledContainer>{children}</StyledContainer>

export default ResultContainer
