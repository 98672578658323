import { FunctionComponent, useCallback, useState } from 'react'
import { TextTrackProps, GraphicsObject } from '../../types'
import { parseGraphicsValue } from '../../utils/graphics'
import useOnVTTCueChange from '../TextTracks/useOnVTTCueChange'
import useVTTCuesFromPlaylistTrack from '../TextTracks/useVTTCuesFromPlaylistTrack'
import GraphicsElements from './GraphicsElements'

type VodGraphicsProps = Omit<TextTrackProps, 'onCaptionsCueChange'>

const VodGraphics: FunctionComponent<VodGraphicsProps> = ({
  player,
  onGraphicsCueChange,
}) => {
  const [graphicsObject, setGraphicsObject] = useState<GraphicsObject>({})

  const vttCues = useVTTCuesFromPlaylistTrack({ player, trackKind: 'metadata' })

  const onCueChange = useCallback<(values?: string[]) => void>(
    (values) => {
      // in case of multiple graphics only the last (actual) one will be processed
      // eg. if we have `headline` for the whole video duration - it will be visible until any
      // another graphic with more specific time comes - after that time `headline` will come back again
      const graphicsValue = values?.[values.length - 1]
      const graphics = parseGraphicsValue(graphicsValue)
      setGraphicsObject(graphics)
      onGraphicsCueChange?.(graphics)
    },
    [onGraphicsCueChange]
  )

  useOnVTTCueChange({ player, vttCues, onCueChange })

  return <GraphicsElements graphicsObject={graphicsObject} />
}

export default VodGraphics
