import Blink from '@components/Blink'
import SvgIcon from '@components/SvgIcon'
import { desktopCSS, mobileCSS, tabletCSS } from '@measures/responsive'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import config from '@config'
import { StoreIconsProps } from './types'
import useDeviceType from '@hooks/useDeviceType'

const {
  publication: { publication },
  appLinks: { ios, android },
} = config

const Wrapper = styled.div`
  ${desktopCSS(css`
    display: none;
  `)};

  ${tabletCSS(css`
    display: none;
  `)};

  ${mobileCSS(css`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
  `)};
`

const StyledStoreIcon = styled(SvgIcon)`
  ${({ iconName }) =>
    // The badge icons need to have the same width (Google and Apple) but, since for different
    // language the App Store icon has different sizes, we have to set dimensions manually
    publication === 'romandie'
      ? css`
          height: ${iconName.includes('app-store') ? '56px' : '49px'};
        `
      : css`
          height: ${iconName.includes('app-store') ? '53px' : '49px'};
        `}
`

const StoreIcons: FunctionComponent<StoreIconsProps> = ({ logoType }) => {
  const deviceType = useDeviceType()
  if (logoType === 'ringier') {
    return null
  }
  return (
    <Wrapper>
      {deviceType !== 'android' && (
        <Blink href={ios} target="blank">
          <StyledStoreIcon
            iconName={`app-store-badge-${publication === 'romandie' ? 'french' : 'german'}`}
            size={170}
          />
        </Blink>
      )}

      {deviceType !== 'ios' && (
        <Blink href={android} target="blank">
          <StyledStoreIcon
            iconName={`google-play-badge-${publication === 'romandie' ? 'french' : 'german'}`}
            size={170}
          />
        </Blink>
      )}
    </Wrapper>
  )
}

export default StoreIcons
