import EmbeddedContent from '@components/EmbeddedContent'
import config from '@config'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { HeimspielScheduleProps, ValidHeimspielScheduleProps } from './types'
import { isValidHeimspielScheduleProps } from './utils'

type CreateEmbedUrlType = (
  ...args: [matchId: ValidHeimspielScheduleProps['matchId']]
) => string

const {
  heimspiel: { heimspielScheduleStaticPageUrl },
} = config

const StyledHeimspielSchedule = styled.div`
  min-height: 512px;
`

const createEmbeddedUrl: CreateEmbedUrlType = (matchId) =>
  `${heimspielScheduleStaticPageUrl}?matchId=${matchId}`

const HeimspielSchedule: FunctionComponent<HeimspielScheduleProps> = (
  props
) => {
  if (!isValidHeimspielScheduleProps(props)) {
    return null
  }

  const { matchId } = props

  return (
    <StyledHeimspielSchedule>
      <EmbeddedContent
        bypassCMP={true}
        maxHeight={500}
        scrollingEnabled
        url={createEmbeddedUrl(matchId)}
      />
    </StyledHeimspielSchedule>
  )
}

const widget = {
  kind: ['widget', 'heimspiel-schedule'],
  component: HeimspielSchedule,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
