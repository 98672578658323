import config from '@config'
import useAuthentication from '@hooks/useAuthentication'
import useHeightTransition from '@hooks/useHeightTransition'
import useToggleMenuVisibility from '@hooks/useToggleMenuVisibility'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import { LoggedOutUser, User } from '@hooks/useUser'
import translate from '@i18n'
import { desktopCSS } from '@measures/responsive'
import { FunctionComponent, useCallback } from 'react'
import styled, { css, useTheme } from 'styled-components'
import CloseIcon from '../CloseIcon'
import UserMenuItem from './UserMenuItem'
import SvgIcon from '@components/SvgIcon'

const {
  gamification: { gamificationTrophies },
  publication: { publication },
  auth: { myServicesUrl },
} = config

type ExtraDataTrackingType = { trophyName: string }

const label = 'Mitspielen und coole Preise gewinnen.'

// Gamification button is delivered in the cook feed, but for the user menu
// we don't get data from cook yet. It's just "hardcoded" in the frontend.
// This flag is to quickly hide and show gamification in the user menu.
// At some point, we will deliver this data in the skeleton,
// so we don't need to do this "manually" in the frontend anymore.
const showGamificationEntry = false && publication !== 'romandie'

const showMyServices = publication !== 'romandie'

const Container = styled.div<{ isVisible: boolean }>`
  ${({
    theme: {
      color: {
        primary: { primary02: primary02Color },
      },
    },
  }) => {
    return css`
      position: absolute;
      top: 0;
      height: 0;
      right: 0;
      z-index: 2;

      overflow: hidden;
      transition: height 0.5s ease-in-out;
      display: flex;
      flex-direction: column;

      background: ${primary02Color};

      width: calc(100% - 104px);
      min-width: 265px;
      max-width: 331px;

      ${desktopCSS(css`
        top: 52px;
        width: 331px;
      `)}
    `
  }}
`

const UserMenuHeader = styled.div`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    display: flex;
    justify-content: flex-end;
    padding-right: ${spacing16};
  `}
`

const UserMenuList = styled.ul`
  width: 100%;
  padding: 0;
  margin: 0;
`

const StyledTrophyItem = styled.span`
  cursor: pointer;
  display: flex;
`

const StyledSvgIcon = styled(SvgIcon)`
  ${({
    theme: {
      spacing: { spacing4 },
    },
  }) => css`
    align-self: center;
    margin-left: ${spacing4};
  `}
`

interface UserMenuContentProps {
  isVisible: boolean
  user: Exclude<User, LoggedOutUser>
}

const UserMenuContent: FunctionComponent<UserMenuContentProps> = ({
  isVisible,
  user,
}) => {
  const theme = useTheme()
  const { toggleUserMenu } = useToggleMenuVisibility()
  const { visitUserAccount, logout } = useAuthentication()

  const getIsVisible = useCallback(() => isVisible, [isVisible])
  const { componentRef } = useHeightTransition({
    getIsVisible,
  })

  const trackingTrophyOnClick = useCallback<
    TrackingFnType<ExtraDataTrackingType>
  >(
    ({ extraData }) => ({
      event: 'game_navbar_interaction',
      eventCategory: 'Game',
      eventAction: 'userAccount',
      eventLabel: extraData.trophyName,
    }),
    []
  )

  const handleGoToTrophyTrack = useTracking(trackingTrophyOnClick)

  const trackingMyServicesOnClick = useCallback<TrackingFnType>(
    () => ({
      event: 'myServices_click',
      eventCategory: 'userAccount',
      eventAction: 'myServices_click',
    }),
    []
  )

  const handleGoToMyServicesTrack = useTracking(trackingMyServicesOnClick)

  const gotoTrophy = ({ name, url }: { name: string; url: string }) => {
    handleGoToTrophyTrack({ trophyName: name })

    window.open(url, '_blank', 'noopener')
  }

  const gotoMyServices = () => {
    handleGoToMyServicesTrack()

    window.open(myServicesUrl, '_blank', 'noopener')
  }

  const { email_only } = user

  const username = email_only
    ? user.email
    : `${user.first_name} ${user.last_name}`

  return (
    <Container isVisible={isVisible} ref={componentRef}>
      <UserMenuHeader>
        <CloseIcon onClick={toggleUserMenu} />
      </UserMenuHeader>
      <UserMenuList onClick={toggleUserMenu}>
        <UserMenuItem
          onClick={visitUserAccount}
          title={translate('login.myAccount')}>
          {username}
        </UserMenuItem>
        {showMyServices && (
          <UserMenuItem onClick={gotoMyServices} title="MyServices">
            Verwalte deine Abos
          </UserMenuItem>
        )}
        {showGamificationEntry && (
          <UserMenuItem title="EM-Tippspiel">
            <span>{label}</span>
            {gamificationTrophies.map((trophy) => (
              <StyledTrophyItem
                onClick={() => gotoTrophy(trophy)}
                key={trophy.name}>
                {trophy.name}
                <StyledSvgIcon
                  color={theme.color.primary.primary01}
                  iconName="chevron-right"
                  size={16}
                />
              </StyledTrophyItem>
            ))}
          </UserMenuItem>
        )}
        <UserMenuItem onClick={logout} title={translate('login.logout')} />
      </UserMenuList>
    </Container>
  )
}

export default UserMenuContent
