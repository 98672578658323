import PlayIcon from '@components/PlayIcon'
import ResponsiveImageV2 from '@components/ResponsiveImageV2'
import { ImageRatios, ImageWidths } from '@components/ResponsiveImageV2/types'
import { Image } from '@utils/cook/types'
import AnimatedPreview from '@components/AnimatedPreview'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import useAnimatedPreview from '@hooks/useAnimatedPreview'
import Placeholder from './Placeholder'
import { VideoWidgetAPIProps } from '@widgets/Video/types'
import translate from '@i18n'
import { desktopCSS } from '@measures/responsive'
import VideoLabel from '@components/VideoLabel'

interface VideoPosterProps {
  image?: Omit<Image<'16_9'>, 'width' | 'height'>
  fetchedPosterUrl?: string
  fetchedSourceUrl?: string
  fetchedMotionThumbnailUrl?: string
  onClick: () => void
  isMainElement?: boolean
  geoblocking: VideoWidgetAPIProps['geoblocking']
  videoId: string
  videoDuration: number
  hasTrailer?: boolean
  articleUrl: string
  isAnimatedPreviewDisabled: VideoWidgetAPIProps['isAnimatedPreviewDisabled']
  showPlayIcon?: boolean
}

type PlayIconContainerProps = Pick<VideoPosterProps, 'hasTrailer'>

const StyledVideoPoster = styled.div`
  height: 100%;
  width: 100%;
  cursor: pointer;
  position: relative;
`

const StyledPlayIconContainer = styled.div<PlayIconContainerProps>`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const StyledTrailerText = styled.span`
  ${({
    theme: {
      typography: {
        subheads: {
          medium1: { bundledCSS: subheadingMedium1CSS },
          small1: { bundledCSS: subheadingSmall1CSS },
        },
      },
      color: {
        primary: { primary02 },
      },
      spacing: { spacing16, spacing24 },
    },
  }) => css`
    color: ${primary02};
    margin-top: ${spacing16};
    ${subheadingSmall1CSS};
    ${desktopCSS(css`
      ${subheadingMedium1CSS};
      margin-top: ${spacing24};
    `)};
  `}
`

const StyledVideoLabel = styled(VideoLabel)`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    position: absolute;
    bottom: ${spacing16};
    left: ${spacing16};
  `}
`

const imageRatios: ImageRatios = {
  desktop: '16_9',
  tablet: '16_9',
  mobile: '16_9',
}

const imageWidths: ImageWidths = {
  desktop: 994,
  tablet: 768,
  mobile: 430,
  mobileSmall: 320,
}

const VideoPoster: FunctionComponent<VideoPosterProps> = ({
  image,
  fetchedPosterUrl,
  onClick,
  isMainElement,
  geoblocking,
  videoId,
  videoDuration,
  articleUrl,
  hasTrailer,
  isAnimatedPreviewDisabled,
  showPlayIcon = true,
}) => {
  const {
    src: imageSrc,
    alt: imageAlt,
    crops: imageAvailableCrops,
  } = image || ({} as Image<'16_9'>)

  const wrapperRef = useAnimatedPreview({
    video: {
      jwVideoId: videoId,
      duration: videoDuration,
      geoblocking,
      isAnimatedPreviewDisabled,
    },
    articleUrl,
  })

  return (
    <StyledVideoPoster ref={wrapperRef} onClick={onClick}>
      {imageSrc ? (
        <ResponsiveImageV2
          src={imageSrc}
          alt={imageAlt ?? ''}
          ratios={imageRatios}
          crops={imageAvailableCrops ?? {}}
          widths={imageWidths}
          Placeholder={Placeholder}
          loading={isMainElement ? 'eager' : 'lazy'}
        />
      ) : fetchedPosterUrl ? (
        <StyledImage
          src={fetchedPosterUrl}
          alt={imageAlt ?? ''}
          width="16"
          height="9"
          loading={isMainElement ? 'eager' : 'lazy'}
        />
      ) : null}
      {videoId ? (
        <AnimatedPreview videoId={videoId} articleUrl={articleUrl} />
      ) : null}
      {showPlayIcon ? (
        <StyledPlayIconContainer>
          <PlayIcon />
          <StyledVideoLabel duration={videoDuration} isInVideoPoster={true} />
          {!!hasTrailer && (
            <StyledTrailerText>{translate('video.trailer')}</StyledTrailerText>
          )}
        </StyledPlayIconContainer>
      ) : null}
    </StyledVideoPoster>
  )
}

export default VideoPoster
