import usePageMetadata, { Breadcrumb } from '@hooks/usePageMetadata'
import { FunctionComponent, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import FirstLevelLink from './FirstLevelLink'
import SecondLevelLink from './SecondLevelLink'
import Divider from '../../Divider'
import { desktopCSS } from '@measures/responsive'

const Container = styled.div`
  ${({
    theme: {
      spacing: { spacing16, spacing24 },
    },
  }) => css`
    display: grid;
    grid-template-columns: auto 1px 1fr;
    align-items: center;
    grid-gap: ${spacing16};

    ${desktopCSS(css`
      grid-gap: ${spacing24};
    `)}
  `}
`

const OtherSection: FunctionComponent = () => {
  const { url, breadcrumbs, context, title } = usePageMetadata()
  const [firstLevelSection, setFirstLevelSection] = useState<
    Breadcrumb | undefined
  >()
  const [secondLevelSection, setSecondLevelSection] = useState<
    Breadcrumb | undefined
  >()

  useEffect(() => {
    setFirstLevelSection(breadcrumbs[1])
    setSecondLevelSection(breadcrumbs[2])
  }, [url, breadcrumbs])

  if (!firstLevelSection || !secondLevelSection) {
    return null
  }

  const { href: firstLevelHref, title: firstLevelTitle } = firstLevelSection

  // For dossiers, we want to show the dossier title, as the section title would be too long
  const secondLevelTitle =
    context === 'dossier' ? title : secondLevelSection.title
  const { href: secondLevelHref } = secondLevelSection

  return (
    <Container>
      <FirstLevelLink href={firstLevelHref}>{firstLevelTitle}</FirstLevelLink>
      <Divider />
      <SecondLevelLink href={secondLevelHref}>
        {secondLevelTitle || ''}
      </SecondLevelLink>
    </Container>
  )
}

export default OtherSection
