import { useCallback, useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { createHtmlPortalNode, InPortal, OutPortal } from 'react-reverse-portal'

export type UseModalOverlayPortal = () => {
  portalNode: ReturnType<typeof createHtmlPortalNode> | null
  enterModalOverlay: () => boolean
  exitModalOverlay: () => boolean
  InPortal: typeof InPortal
  OutPortal: typeof OutPortal
}

const useModalOverlayPortal: UseModalOverlayPortal = () => {
  const queryClient = useQueryClient()
  const [portalNode, setPortalNode] = useState<ReturnType<
    typeof createHtmlPortalNode
  > | null>(null)

  useEffect(() => {
    setPortalNode(createHtmlPortalNode())
  }, [])

  const cleanupPortal = useCallback(() => {
    queryClient.setQueryData(['modal-overlay-portal-rendered-node-ref'], null)
    queryClient.invalidateQueries({
      queryKey: ['modal-overlay-portal-rendered-node-ref'],
      exact: true,
    })
  }, [queryClient])

  /**
   * close the modal overlay on unmount
   */
  useEffect(() => {
    return () => {
      cleanupPortal()
    }
  }, [cleanupPortal])

  const enterModalOverlay = useCallback(() => {
    const modalOverlayShown =
      queryClient.getQueryData(['modal-overlay-shown']) ?? false
    if (portalNode && !modalOverlayShown) {
      queryClient.setQueryData(
        ['modal-overlay-portal-rendered-node-ref'],
        portalNode
      )
      queryClient.invalidateQueries({
        queryKey: ['modal-overlay-portal-rendered-node-ref'],
        exact: true,
      })
      return true
    } else {
      return false
    }
  }, [portalNode, queryClient])

  const exitModalOverlay = useCallback(() => {
    const modalOverlayShown =
      queryClient.getQueryData(['modal-overlay-shown']) ?? false
    if (portalNode && modalOverlayShown) {
      cleanupPortal()
      return true
    } else {
      return false
    }
  }, [portalNode, queryClient, cleanupPortal])

  return {
    portalNode,
    enterModalOverlay,
    exitModalOverlay,
    InPortal,
    OutPortal,
  }
}

export default useModalOverlayPortal
