import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import translate from '@i18n'
import { FunctionComponent, useCallback } from 'react'
import config from '@config'
import useUser from '@hooks/useUser'
import EmbeddedContent from '@components/EmbeddedContent'
import LoginOverlay from '@components/Tool/LoginOverlay'
import useAuthentication from '@hooks/useAuthentication'
import useDisableThirdPartyScripts from '@hooks/useDisableThirdPartyScripts'

const {
  hearken: { widgetUrl },
} = config

interface HearkenProps {
  id: string
  loginRequired: boolean
}

const Hearken: FunctionComponent<HearkenProps> = ({
  id: hearkenId,
  loginRequired,
}) => {
  const disableThirdPartyScripts = useDisableThirdPartyScripts()
  const userData = useUser()
  const { login } = useAuthentication()
  const onLogin = useCallback(
    () => login({ prefix: `FOR_${hearkenId}` }),
    [hearkenId, login]
  )

  if (disableThirdPartyScripts) {
    return null
  }

  const hearkenUrl = `${widgetUrl}?hearkenId=${hearkenId}`
  const isLocked = loginRequired && userData?.email_only !== false

  return isLocked ? (
    <LoginOverlay
      text={`${
        userData?.email_only
          ? translate('formIO.noEmailOnlyRequiredText')
          : translate('formIO.loginRequiredText')
      }`}
      buttonText={`${
        userData?.email_only
          ? translate('formIO.noEmailOnlyRequiredButtonText')
          : translate('formIO.loginRequiredButtonText')
      }`}
      onClick={onLogin}
    />
  ) : (
    <EmbeddedContent url={hearkenUrl} withSSO={true} />
  )
}

const widget = {
  kind: ['widget', 'tool', 'hearken'],
  component: Hearken,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
