import { FunctionComponent, useCallback } from 'react'
import styled, { css } from 'styled-components'
import Blink from '@components/Blink'
import { LinkCarouselTile as LinkCarouselTileProps } from '@widgets/LinkCarousel/types'
import LinkCarouselLogo from './LinkCarouselLogo'
import useTracking, { TrackingFnType } from '@hooks/useTracking'

const StyledLinkCarouselTile = styled.div`
  ${({
    theme: {
      spacing: { spacing16, spacing8, spacing4 },
      color: {
        tertiary: { grey300 },
      },
    },
  }) => css`
    scroll-snap-align: start;
    scroll-snap-stop: always;
    height: 158px;
    width: 120px;
    box-sizing: border-box;
    padding: ${spacing16} ${spacing8};
    border-radius: ${spacing4};
    border: 1px solid ${grey300};
  `}
`

const StyledTitle = styled.div`
  ${({
    theme: {
      color: {
        primary: { primary01 },
      },
      typography: {
        labels: {
          label1: { bundledCSS: label1CSS },
        },
      },
      spacing: { spacing8 },
      utils: { ellipsis },
    },
  }) => css`
    ${label1CSS};
    color: ${primary01};
    margin-top: ${spacing8};
    text-align: center;
    ${ellipsis('100%', 2)};
  `}
`

const StyledLinkCarouselLink = styled(Blink)`
  display: grid;
  text-decoration: none;
`

const LinkCarouselTile: FunctionComponent<
  LinkCarouselTileProps & { mainTitle: string }
> = ({ link, image, mainTitle, title }) => {
  const onClickTrackingFn = useCallback<TrackingFnType>(
    () => ({
      event: 'linkcarousel_click',
      eventAction: 'click',
      eventCategory: 'linkcarousel_widget',
      eventLabel: title,
      linkcarousel_title: mainTitle,
    }),
    [mainTitle, title]
  )

  const onClick = useTracking(onClickTrackingFn)

  return (
    <StyledLinkCarouselTile>
      <StyledLinkCarouselLink
        {...link}
        ariaLabel={link.title}
        onClick={onClick}>
        <LinkCarouselLogo image={image} />
        <StyledTitle>{title}</StyledTitle>
      </StyledLinkCarouselLink>
    </StyledLinkCarouselTile>
  )
}

export default LinkCarouselTile
