import { BrandStudioRandomItemProps, RandomAdParams } from './types'

// It is possible for Brandstudio ads to have multiple different versions
// This function returns a random ad from the array of ads given
export const getRandomAd = ({
  ads,
  isMobile = false,
}: RandomAdParams): BrandStudioRandomItemProps => {
  const randomIndex = Math.floor(Math.random() * ads.length)
  const randomAd = ads[randomIndex]

  return {
    trackingClass: randomAd.trackingClass,
    adTitle: randomAd.title,
    adInfo: isMobile
      ? randomAd.adInformationMobile
      : randomAd.adInformationDesktop,
  }
}
