import { FunctionComponent } from 'react'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import LinkCarouselSections from '@components/LinkCarousel'
import { LinkCarouselProps } from './types'

const LinkCarousel: FunctionComponent<LinkCarouselProps> = (props) => (
  <LinkCarouselSections {...props} />
)

const widget = {
  kind: ['widget', 'link-carousel'],
  component: LinkCarousel,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
