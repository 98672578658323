import { FunctionComponent, useCallback, useState } from 'react'
import styled, { css, useTheme } from 'styled-components'
import config from '@config'
import translate from '@i18n'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import SummaryContainer from '@components/Summary/SummaryContainer'
import SummaryDisclaimer from '@components/Summary/SummaryDisclaimer'
import SvgIcon from '@components/SvgIcon'
import SummaryHeader from '@components/Summary/SummaryHeader'
import SecondaryCTAButton from '@components/Buttons/SecondaryCTAButton'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import useViewportTracking from '@hooks/useViewportTracking'
import { ErrorView } from '@components/Summary/SummaryError'
import {
  SkeletonSeedValues,
  SkeletonView,
} from '@components/Summary/SummaryLoadingSkeleton'
import SummaryRequestView from './SummaryRequestView'
import SummaryResultView from './SummaryResultView'
import { trackClickHandler } from './utils'

import { getAnswer, getQuestions } from '@utils/chatbot'
import { SectionSummaryProps } from './types'
import { mobileCSS } from '@measures/responsive'
import { skipToken, useQuery } from '@tanstack/react-query'
import { WidgetIdValues } from '@utils/chatbot/types'
import useModalOverlayPortal from '@hooks/useModalOverlayPortal'
import UserSnapInlineForm from '@components/UserSnap/InlineForm'

const {
  sectionSummary: { moreInfoLink, userSnapFeedbackKey },
} = config

const IconPullRight = styled.div`
  flex: 1;
  display: flex;
  justify-content: right;
  width: 100%;
  cursor: pointer;
`
const SummaryWrapper = styled.div`
  width: 100%;
  min-height: 211.19px;

  ${mobileCSS(css`
    min-height: 254.38px;
  `)}
`

const SectionSummary: FunctionComponent<SectionSummaryProps> = ({
  title = '',
  isEnabled = false,
}) => {
  const [enabled, setEnabled] = useState<boolean>(isEnabled)
  const theme = useTheme()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { portalNode, enterModalOverlay, exitModalOverlay, InPortal } =
    useModalOverlayPortal()
  const trackWidgetImpression = useTracking(() => ({
    event: 'home_summary_impression',
  }))
  const trackClick = useTracking(trackClickHandler)

  const viewportRef = useViewportTracking({
    track: true,
    onImpression: trackWidgetImpression,
  })

  const trackingOnClick = useCallback<TrackingFnType<{ label: string }>>(
    ({ extraData: { label } }) => ({
      event: 'home_summary_click',
      button_label: label,
    }),
    []
  )
  const handleTrackingOnClick = useTracking(trackingOnClick)

  const disclaimer = {
    title: translate('sectionSummary.disclaimerText'),
    links: [
      {
        label: translate('sectionSummary.disclaimerMoreInfo'),
        link: moreInfoLink,
        target: '_blank',
        onClickHandler: (label: string) => handleTrackingOnClick({ label }),
      },
      {
        label: translate('sectionSummary.disclaimerFeedback'),
        target: '_blank',
        onClickHandler: (label: string) => {
          event?.preventDefault()
          handleTrackingOnClick({ label })
          setIsModalVisible(true)
          enterModalOverlay()
        },
      },
    ],
  }

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ['sectionSummary.home'],
    queryFn: enabled
      ? async () => {
          const { questions } = await getQuestions({
            widgetId: WidgetIdValues.SectionSummary,
            context: 'home',
          })()

          if (questions.length === 0) {
            throw new Error('No data found')
          }

          const questionId = questions[0].id

          const { content, links } = await getAnswer({
            widgetId: WidgetIdValues.SectionSummary,
            context: 'home',
            questionId: questionId,
          })()

          return { content, links }
        }
      : skipToken,
  })

  const isSummariesLoaded =
    !!(data?.content && data?.content?.length > 0) && enabled && !isError

  const onCloseWidget = useCallback(() => {
    setEnabled(false)
  }, [setEnabled])

  const onShowResults = useCallback(() => {
    trackClick({ event: 'home_summary_click' })
    setEnabled(true)
  }, [trackClick, setEnabled])

  return (
    <SummaryWrapper id="section-summary">
      <SummaryContainer ref={viewportRef}>
        {!enabled && (
          <SummaryRequestView
            title={title}
            text={translate('sectionSummary.request.text')}
            onPrimaryClick={onShowResults}
          />
        )}

        <>
          {portalNode && isModalVisible && (
            <InPortal node={portalNode}>
              <UserSnapInlineForm
                setIsVisible={(isVisible) => {
                  if (!isVisible) {
                    exitModalOverlay()
                  }
                  enterModalOverlay()
                }}
                projectApiKey={userSnapFeedbackKey}
              />
            </InPortal>
          )}
        </>

        {isSummariesLoaded && (
          <SummaryResultView
            title={translate('sectionSummary.request.title')}
            trackingClickType="home_summary_click"
            links={data.links}
            content={data.content}
            disclaimer={disclaimer}
            onCloseHandler={onCloseWidget}
          />
        )}

        {isLoading && (
          <>
            <SummaryHeader
              prefixIconName="stars"
              after={
                <IconPullRight onClick={onCloseWidget}>
                  <SvgIcon
                    iconName="xmark-large"
                    size={24}
                    color={theme.color.primary.primary01}
                  />
                </IconPullRight>
              }>
              {translate('sectionSummary.request.title')}
            </SummaryHeader>

            <SkeletonView
              seed={[
                SkeletonSeedValues.BulletPoint,
                SkeletonSeedValues.BulletPoint,
                SkeletonSeedValues.BulletPoint,
                SkeletonSeedValues.Paragraph,
              ]}
            />
          </>
        )}
        {isError && enabled && (
          <ErrorView>
            <IconPullRight onClick={onCloseWidget}>
              <SvgIcon
                iconName="xmark-large"
                size={24}
                color={theme.color.primary.primary01}
              />
            </IconPullRight>
            {translate('sectionSummary.request.error')}
            <SecondaryCTAButton
              type="button"
              colorVariant="black"
              size="small"
              iconName="arrows-rotate"
              onClick={() => refetch()}>
              {translate('sectionSummary.request.error.primaryButton')}
            </SecondaryCTAButton>
            {disclaimer && <SummaryDisclaimer links={disclaimer.links} />}
          </ErrorView>
        )}
      </SummaryContainer>
    </SummaryWrapper>
  )
}

const widget = {
  kind: ['section-summary'],
  component: SectionSummary,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
