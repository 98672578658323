import {
  BrandingDayOptionsType,
  BrandingDayQueryData,
} from '@hooks/useBrandingDay'
import { FunctionComponent, RefObject, useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'

const getScrollToHeaderFn = () => '#main-header'

const Papi: FunctionComponent = () => {
  const queryClient = useQueryClient()

  useEffect(() => {
    window.Ads.helpers.getScrollToElementSelector = getScrollToHeaderFn

    const setSideBar = () => {
      const sidebarAdRef = queryClient.getQueryData<RefObject<HTMLDivElement>>([
        'sidebar-ad-ref',
      ])

      if (sidebarAdRef?.current) {
        const asideElement =
          sidebarAdRef.current.querySelector<HTMLElement>('aside')
        const iFrameElemement = asideElement?.querySelector<HTMLElement>(
          '#div_utif_appnexus-placement-sideBarAd > iframe'
        )

        if (asideElement && iFrameElemement) {
          asideElement.style.width = '100%'
          asideElement.style.height = '100%'
          iFrameElemement.style.width = '100%'
          iFrameElemement.style.height = '100%'
        }
      }
    }

    window.Ads.helpers.setBrandingDay = (options: BrandingDayOptionsType) => {
      queryClient.setQueryData<BrandingDayQueryData>(['branding-day-data'], {
        options,
      })
      if (options?.siteShift) {
        queryClient.setQueryData<boolean>(['force-center-content'], true)
        queryClient.invalidateQueries({ queryKey: ['force-center-content'] })
      }
    }

    window.Ads.helpers.setDDS = () => {
      queryClient.setQueryData<boolean>(['force-center-content'], true)
      queryClient.invalidateQueries({ queryKey: ['force-center-content'] })
    }

    const resetAll = () => {
      const brandingDayData = queryClient.getQueryData<BrandingDayQueryData>([
        'branding-day-data',
      ])
      if (brandingDayData) {
        queryClient.removeQueries({
          queryKey: ['branding-day-data'],
          exact: true,
        })
      }
      queryClient.setQueryData<boolean>(['force-center-content'], false)
      queryClient.invalidateQueries({ queryKey: ['force-center-content'] })

      const sidebarAdRef = queryClient.getQueryData<RefObject<HTMLDivElement>>([
        'sidebar-ad-ref',
      ])

      if (sidebarAdRef?.current) {
        const asideElement =
          sidebarAdRef.current.querySelector<HTMLElement>('aside')
        const iFrameElemement = asideElement?.querySelector<HTMLElement>(
          '#div_utif_appnexus-placement-sideBarAd > iframe'
        )

        if (asideElement && iFrameElemement) {
          asideElement.style.width = ''
          asideElement.style.height = ''
          iFrameElemement.style.width = ''
          iFrameElemement.style.height = ''
        }
      }
    }
    window.Ads.helpers.setSideBar = setSideBar
    window.Ads.helpers.resetAll = resetAll

    return () => {
      queryClient.removeQueries({
        queryKey: ['branding-day-data'],
        exact: true,
      })
    }
  }, [queryClient])

  return null
}

export default Papi
