import { ConfigProps } from '@config/types'

const config = ({}: ConfigProps) => {
  return {
    moreInfoLink: 'https://www.blick.ch/id19711657',
    userSnapFeedbackKey: 'd65abfb0-c6af-416f-b2bd-0323125c08e1',
  } as const
}

export default config
