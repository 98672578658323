import { FunctionComponent, useCallback, useContext } from 'react'
import styled, { css } from 'styled-components'

import { mobileCSS } from '@measures/responsive'

import useTracking, { TrackingFnType } from '@hooks/useTracking'
import useViewportTracking from '@hooks/useViewportTracking'
import { PlatformSpecificButtonProps } from './types'
import EndOfArticleContext from '@contexts/endOfArticle'
import PrimaryCTABlink from '../PrimaryCTABlink'

const StyledGamificationLink = styled(PrimaryCTABlink)`
  ${mobileCSS(css`
    display: none;
  `)};
`

const StyledIcon = styled.img``

const DesktopGamificationButton: FunctionComponent<
  PlatformSpecificButtonProps
> = ({ text, link, icon }) => {
  const endOfArticle = useContext(EndOfArticleContext)
  const trackingOnClick = useCallback<TrackingFnType>(() => {
    return {
      event: 'game_interaction',
      eventCategory: 'Game',
      eventAction: `click_${endOfArticle ? 'bottom' : 'top'}`,
      eventLabel: text,
    }
  }, [endOfArticle, text])

  const trackingOnImpression = useCallback<TrackingFnType>(() => {
    return {
      event: 'game_impression',
      eventCategory: 'Game',
      eventLabel: text,
      eventAction: `impression_${endOfArticle ? 'bottom' : 'top'}`,
    }
  }, [text, endOfArticle])

  const handleTrack = useTracking(trackingOnClick)
  const handleImpressionTrack = useTracking(trackingOnImpression)

  const viewportRef = useViewportTracking({
    onImpression: handleImpressionTrack,
    track: true,
  })

  const handleClick = () => {
    handleTrack()
  }

  return (
    <StyledGamificationLink
      size="small"
      onClick={handleClick}
      ref={viewportRef}
      {...link}>
      <StyledIcon src={icon} width="16" height="16" loading="lazy" />
      {text}
    </StyledGamificationLink>
  )
}

export default DesktopGamificationButton
