/*
 * Page numbers generator will generate numbers depending on total pages and active page
 * There are 4 outputs for desktop:
 * 1 2 3 4 5 6 7 8 -> totalPages < 10
 * 1 ... 5 6 7 8   -> totalPages > 9 && activePage > (totalPages - 3)
 * 1 2 3 4 ... 8   -> totalPages > 9 && activePage < 5
 * 1 ... 6 7 8 9 10 ... 20 -> for all other cases
 * There are 4 outputs for mobile:
 * 1 2 3 4 5 -> totalPage < 6
 * 1 2 3 4 ... -> totalPage > 5 && activePage < 4
 * 1 ... 6 7 8 -> totalPage > 6 && activePage > (totalPages - 2)
 *  * 1 ... 4 5 ... -> for all other cases
 */

import { ViewportTypeValues } from '@hooks/useViewport/types'

const getRangeArray = (start: number, end: number) => {
  const range = []
  for (let i = start; i <= end; i++) {
    range.push(i)
  }
  return range
}

const MOBILE_THRESHOLD = 4
const DESKTOP_THRESHOLD = 5
const MOBILE_VISIBLE_PAGES = 6
const DESKTOP_VISIBLE_PAGES = 10

const generatePageNumbers = (
  totalPages: number,
  activePage: number,
  viewport: ViewportTypeValues
): (number | '...')[] => {
  const pages: (number | '...')[] = []

  if (viewport === 'mobile') {
    if (totalPages < MOBILE_VISIBLE_PAGES) {
      pages.push(...getRangeArray(1, totalPages))
    } else if (activePage < MOBILE_THRESHOLD) {
      pages.push(...getRangeArray(1, 4), '...')
    } else if (activePage > totalPages - MOBILE_VISIBLE_PAGES + 2) {
      pages.push(1, '...', ...getRangeArray(totalPages - 2, totalPages))
    } else {
      pages.push(1, '...', activePage, activePage + 1, '...')
    }
  } else {
    if (totalPages < DESKTOP_VISIBLE_PAGES) {
      pages.push(...getRangeArray(1, totalPages))
    } else if (
      totalPages > DESKTOP_VISIBLE_PAGES - 1 &&
      activePage < DESKTOP_THRESHOLD
    ) {
      pages.push(...getRangeArray(1, 6), '...', totalPages)
    } else if (
      totalPages > DESKTOP_VISIBLE_PAGES - 1 &&
      activePage > totalPages - DESKTOP_THRESHOLD + 1
    ) {
      pages.push(1, '...', ...getRangeArray(totalPages - 4, totalPages))
    } else {
      pages.push(1, '...')
      for (let i = activePage - 2, max = activePage + 2; i <= max; i++) {
        pages.push(i)
      }
      pages.push('...', totalPages)
    }
  }

  return pages
}

export { generatePageNumbers }
