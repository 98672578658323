//! This file is copied (more or less) as is from @tanstack/react-query v4.
//! Please do not modify, because it will completely change the behaviour of
//! when the QueryCache is updated for existing queries on the client.
//! https://github.com/TanStack/query/issues/6240

import { useRef, useMemo, ReactNode, ReactElement } from 'react'
import { hydrate, useQueryClient, HydrateOptions } from '@tanstack/react-query'

export function useHydrate(state: unknown, options: HydrateOptions = {}) {
  const queryClient = useQueryClient()

  const optionsRef = useRef(options)
  optionsRef.current = options

  // Running hydrate again with the same queries is safe,
  // it wont overwrite or initialize existing queries,
  // relying on useMemo here is only a performance optimization.
  // hydrate can and should be run *during* render here for SSR to work properly
  useMemo(() => {
    if (state) {
      hydrate(queryClient, state, optionsRef.current)
    }
  }, [queryClient, state])
}

export interface HydrateProps {
  state?: unknown
  options?: HydrateOptions
  children?: ReactNode
}

export const Hydrate = ({ children, options, state }: HydrateProps) => {
  useHydrate(state, options)
  return children as ReactElement
}
