import { FunctionComponent } from 'react'
import { desktopCSS } from '@measures/responsive'
import styled, { css } from 'styled-components'

interface MainTextProps {
  children: string
}

const StyledMainText = styled.p`
  ${({
    theme: {
      typography: {
        bodycopy: {
          small2: { bundledCSS: bodycopySmall2CSS },
          xsmall: { bundledCSS: bodycopyXSmallCSS },
        },
      },
      spacing: { spacing8, spacing24 },
    },
  }) => {
    return css`
      ${bodycopyXSmallCSS};
      ${desktopCSS(css`
        ${bodycopySmall2CSS};
      `)};
      text-align: center;
      width: 100%;
      margin-bottom: ${spacing24};
      margin-top: ${spacing8};
    `
  }}
`

const MainText: FunctionComponent<MainTextProps> = ({ children }) => (
  <StyledMainText>{children}</StyledMainText>
)

export default MainText
