import { ConfigProps } from '@config/types'

const config = ({ publication, weatherUrl }: ConfigProps) =>
  ({
    domain: `${weatherUrl}`,
    baseUrl: `${weatherUrl}/weather/v1/`,
    fetchExpirationTime: 30,
    meteoNewsUrl:
      publication === 'romandie'
        ? 'https://meteonews.ch/fr/Carte_meteo/CCH/Suisse'
        : 'https://meteonews.ch/de/Redirect/blick/',
  }) as const satisfies Record<string, unknown>

export default config
