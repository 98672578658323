import { createContext } from 'react'

export interface StretchType {
  shouldStretch: boolean
  shouldStretchToHeaderWidth: boolean
  shouldStretchToCardWidth: boolean
}

const StretchContext = createContext<StretchType>({
  shouldStretch: false,
  shouldStretchToHeaderWidth: false,
  shouldStretchToCardWidth: false,
})

export default StretchContext
