import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import {
  desktopCSS,
  tabletCSS,
  mobileCSS,
  mobileAndTabletCSS,
} from '@measures/responsive'
import useClientViewportType from '@hooks/useClientViewportType'
import {
  HeroModuleAPIProps,
  getTOISportsFooterMinHeightForSportAndViewport,
} from '@widgets/HeroModule'

export interface DynamicContentWrapperProps {
  hideOnMobile: boolean
  typeOfSport: HeroModuleAPIProps['typeOfSport']
  isSportDataLoaded: boolean
  children: ReactNode
}

export type StyledDynamicContentWrapperProps = Omit<
  DynamicContentWrapperProps,
  'children'
>

const StyledDynamicContentWrapper = styled.div<DynamicContentWrapperProps>`
  ${({
    typeOfSport,
    isSportDataLoaded,
    hideOnMobile,
    theme: {
      color: {
        primary: { primary01 },
      },
      spacing: { spacing4, spacing16, spacing24 },
    },
  }) => {
    const baseCSS = css`
      margin-top: ${spacing24};

      &:empty {
        display: none;
      }

      ${desktopCSS(css`
        width: 100%;
        overflow: hidden;
        border-radius: ${spacing4};
      `)};

      ${mobileAndTabletCSS(css`
        width: calc(100% + 2 * ${spacing16});
        margin-left: -${spacing16};
        margin-right: -${spacing16};
        ${hideOnMobile &&
        css`
          display: none;
        `}
      `)};
    `

    if (!typeOfSport || isSportDataLoaded) {
      return baseCSS
    }

    return css`
      ${baseCSS};

      background-color: ${primary01};

      ${desktopCSS(css`
        min-height: ${getTOISportsFooterMinHeightForSportAndViewport(
          typeOfSport,
          'desktop'
        )};
      `)};

      ${tabletCSS(css`
        min-height: ${getTOISportsFooterMinHeightForSportAndViewport(
          typeOfSport,
          'tablet'
        )};
      `)};

      ${mobileCSS(css`
        min-height: ${getTOISportsFooterMinHeightForSportAndViewport(
          typeOfSport,
          'mobile'
        )};
      `)};
    `
  }}
`

const DynamicContentWrapper: FunctionComponent<DynamicContentWrapperProps> = ({
  children,
  hideOnMobile,
  isSportDataLoaded,
  typeOfSport,
}) =>
  !['server', 'desktop'].includes(useClientViewportType()) &&
  hideOnMobile ? null : (
    <StyledDynamicContentWrapper
      hideOnMobile={hideOnMobile}
      isSportDataLoaded={isSportDataLoaded}
      typeOfSport={typeOfSport}>
      {children}
    </StyledDynamicContentWrapper>
  )

export default DynamicContentWrapper
