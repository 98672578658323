import { FunctionComponent } from 'react'
import {
  CookWidget,
  JSONTypeForCookWidget,
  CookWidgetJSON,
} from '@widgets/types'
import JSONRenderer from '@components/JSONRenderer'
import LayoutCard from '@components/LayoutCard'
import styled, { css } from 'styled-components'
import { mobileCSS } from '@measures/responsive'
import RecipeIngredientsContentContext from '@contexts/recipeIngredientsContent'
import IngredientsCardMainElementContext from '@contexts/ingredientsCardMainElement'
import { printCSS } from '@utils/style'

interface LayoutCardWidgetProps {
  kind: ['layout', 'card', 'ingredients']
  content: CookWidgetJSON[]
}

const isMainElement = (content: CookWidget[]) =>
  /* RecipeInfo widget should have a higher margin-top if the card contains a video or image first
           and does not contain tags */
  content?.[0].kind?.join('.').includes('widget.gallery') ||
  (content?.[0].kind?.join('.').includes('widget.video') &&
    content.length === 2)

const StyledLayoutCard = styled(LayoutCard)`
  ${({
    theme: {
      spacing: { spacing16, spacing20 },
      measures: { layoutCardPadding },
    },
  }) => {
    return css`
      padding: 0 ${layoutCardPadding.desktop} ${spacing20};
      ${mobileCSS(css`
        padding: 0 ${layoutCardPadding.mobile} ${spacing16};
      `)}
      ${printCSS(css`
        padding: 0;
      `)}
    `
  }}
`

const LayoutCardWidget: FunctionComponent<LayoutCardWidgetProps> = ({
  content,
}) => {
  return (
    <StyledLayoutCard>
      <IngredientsCardMainElementContext.Provider
        value={isMainElement(content)}>
        <RecipeIngredientsContentContext.Provider value={true}>
          <JSONRenderer>{content}</JSONRenderer>
        </RecipeIngredientsContentContext.Provider>
      </IngredientsCardMainElementContext.Provider>
    </StyledLayoutCard>
  )
}

const widget = {
  kind: ['layout', 'card', 'ingredients'],
  component: LayoutCardWidget,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
