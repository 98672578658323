export type ZIndexType = {
  level1: number
  level2: number
  level3: number
  level4: number
  level5: number
  level6: number
  navigationLevel1: number
  navigationLevel2: number
  navigationLevel3: number
  modal1: number
  modal2: number
  modal3: number
  overAll: number
}

// sort by value
const zIndex = {
  // below most of the ads
  level1: 10,
  level2: 20,
  level3: 30,
  // above the ads
  level4: 6000001,
  level5: 6000002,
  level6: 6000003,
  navigationLevel1: 7000001,
  navigationLevel2: 7000002,
  navigationLevel3: 7000003,
  modal1: 8000001,
  modal2: 8000002,
  modal3: 8000003,
  overAll: 99999999,
} as const satisfies ZIndexType

export default zIndex
