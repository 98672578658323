import { FunctionComponent } from 'react'
import styled, { css, useTheme } from 'styled-components'
import Spinner from './Spinner'
import translate from '@i18n'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const LoadingLabel = styled.div`
  ${({
    theme: {
      typography: {
        subheads: {
          small1: { bundledCSS: subheadingSmall1CSS },
        },
      },
      spacing: { spacing4 },
    },
  }) => css`
    ${subheadingSmall1CSS};
    margin-top: ${spacing4};
  `}
`

const SpinnerAndText: FunctionComponent = () => {
  const {
    color: {
      primary: { blickRed },
    },
  } = useTheme()

  return (
    <Container>
      <Spinner size={48} color={blickRed} />
      <LoadingLabel>
        {translate('blickPlus.linkSharing.loadingLabel')}
      </LoadingLabel>
    </Container>
  )
}

export default SpinnerAndText
