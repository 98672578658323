import { css } from 'styled-components'
import { iconSizeRepeat } from './common'
import { mobileCSS } from '@measures/responsive'
import svgSprite from '@assets/images/video-player-sprite.svg'

export const playlistNavigationButtons = css`
  &.show-playlist-navigation-buttons {
    .jw-controlbar {
      .jw-icon-next,
      .jw-icon-rewind {
        display: flex !important;

        .jw-svg-icon-next path,
        .jw-svg-icon-rewind path {
          display: none;
        }

        .jw-svg-icon-next {
          background: url(${svgSprite}#forward-step);
          ${iconSizeRepeat}
        }

        .jw-svg-icon-rewind {
          background: url(${svgSprite}#backward-step);
          ${iconSizeRepeat}
        }

        ${mobileCSS(css`
          display: none !important;
        `)}
      }
    }

    .jw-display {
      ${mobileCSS(css`
        .jw-display-icon-next,
        .jw-display-icon-rewind {
          display: flex !important;
          visibility: visible !important;
          align-items: center;
          width: 48px;
          height: 48px;

          .jw-icon {
            width: 40px;
            height: 40px;
            background-color: #00000080;
            border-radius: 24px;

            .jw-svg-icon-next path,
            .jw-svg-icon-rewind path {
              display: none;
            }

            .jw-svg-icon-next {
              background: url(${svgSprite}#forward-step);
              ${iconSizeRepeat}
            }

            .jw-svg-icon-rewind {
              background: url(${svgSprite}#backward-step);
              padding: 0;
              ${iconSizeRepeat}
            }
          }
        }
      `)}
    }

    &.jw-flag-ads {
      .jw-icon-next,
      .jw-display-icon-next,
      .jw-icon-rewind,
      .jw-display-icon-rewind {
        display: none !important;
      }
    }

    &.show-playlist-navigation-buttons-disabled {
      .jw-svg-icon-rewind {
        opacity: 0.25;
      }
    }
  }
`
