import { FunctionComponent, useEffect } from 'react'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import useTracking from '@hooks/useTracking'

import { videoPlaylistShowHandler } from '@hooks/useVideoTracking/eventMapper'
import usePlaylist from '@hooks/useVideoPlaylist'
import Playlist from './Playlist'
import PlaylistDesktop from './PlaylistDesktop'

import useViewportType from '@hooks/useViewport/useViewportType'

const PlaylistVideosWidget: FunctionComponent = () => {
  const data = usePlaylist()

  const viewportType = useViewportType()
  const trackPlaylistShow = useTracking(videoPlaylistShowHandler)

  const isDesktop = viewportType === 'desktop'

  /**
   * Tracking the impression at a widget level since
   * we are moving the item around on Desktop
   */
  useEffect(() => {
    const playlistTitle = data?.title
    if (playlistTitle) {
      trackPlaylistShow({ playlistTitle })
    }
  }, [data?.title, trackPlaylistShow])

  if (!data) {
    return null
  }

  return isDesktop ? (
    <PlaylistDesktop items={data.items} title={data.title} />
  ) : (
    <Playlist items={data.items} title={data.title} />
  )
}

const widget = {
  kind: ['widget', 'playlist-videos'],
  component: PlaylistVideosWidget,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
