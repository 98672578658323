import { FunctionComponent, memo, useCallback, useEffect, useRef } from 'react'
import isEqual from 'lodash.isequal'
import { useQueryClient } from '@tanstack/react-query'

import config from '@config'
import { JWPlayer } from '../types'

import { ExtendedWindow, ReactJWPlayerProps } from './types'
import { logVideoPlayer } from '../utils/logger'
import useHasJWPlayerScriptLoaded from '@hooks/useHasJWPlayerScriptLoaded'

const {
  video: { licenseKey },
} = config

type LibPropType = ReactJWPlayerProps & {
  widgetId?: string
  onSetup?: (player: JWPlayer) => void
}

const ReactJWPlayer: FunctionComponent<LibPropType> = (props) => {
  const queryClient = useQueryClient()
  const hasPlayerInitialized = useRef(false)
  const hasJWPlayerScriptLoaded = useHasJWPlayerScriptLoaded()
  const containerRef = useRef<HTMLDivElement>(null)

  const initialize = useCallback(() => {
    if (containerRef.current) {
      const player = (window as ExtendedWindow)?.jwplayer(
        containerRef.current
      ) as JWPlayer

      if (player) {
        const { onSetup, ...playerProps } = props
        const playerSetupOptions = { ...playerProps, key: licenseKey }

        logVideoPlayer(
          { queryClient },
          'initialize::',
          player,
          playerSetupOptions
        )

        onSetup?.(player)

        player.setup(playerSetupOptions)
      }
    }
  }, [queryClient, props])

  useEffect(() => {
    if (!hasPlayerInitialized.current && hasJWPlayerScriptLoaded) {
      logVideoPlayer(
        { queryClient },
        'isJWPlayerScriptLoaded::shouldInitialize'
      )
      hasPlayerInitialized.current = true
      initialize()
    }
  }, [hasJWPlayerScriptLoaded, hasPlayerInitialized, initialize, queryClient])

  return (
    <div className={props.className} data-chromatic="ignore">
      <div id={props.widgetId} ref={containerRef} />
    </div>
  )
}

/** do not re-render the component unless the playlist changes */
const propsAreEqual = (
  prevProps: ReactJWPlayerProps,
  nextProps: ReactJWPlayerProps
): boolean => isEqual(prevProps.playlist, nextProps.playlist)

export default memo(ReactJWPlayer, propsAreEqual)
