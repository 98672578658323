import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => {
    return css`
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: ${spacing16};
    `
  }}
`

const SharingAndCommentWrapper: FunctionComponent<{ children?: ReactNode }> = ({
  children,
}) => {
  return <Wrapper>{children}</Wrapper>
}

export default SharingAndCommentWrapper
