import HeaderSearchBarContext from '@contexts/headerSearchBar'
import useExecuteOnClientNavigation from '@hooks/useExecuteOnClientNavigation'
import { desktopCSS } from '@measures/responsive'
import { FunctionComponent, ReactNode, useCallback, useState } from 'react'
import styled, { css } from 'styled-components'
import { printCSS } from '@utils/style'

const StyledButtonsContainer = styled.div`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    justify-self: flex-start;
    display: grid;
    grid-auto-flow: column;
    align-items: center;

    ${printCSS(css`
      display: none;
    `)}

    ${desktopCSS(css`
      grid-gap: ${spacing16};
    `)}
  `}
`

const ButtonsContainer: FunctionComponent<{ children?: ReactNode }> = ({
  children,
}) => {
  const [isSearchBarCollapsed, setIsSearchBarCollapsed] = useState(true)
  const collapseInputOnNavigation = useCallback(() => {
    setIsSearchBarCollapsed(true)
  }, [])
  useExecuteOnClientNavigation(collapseInputOnNavigation)

  return (
    <StyledButtonsContainer>
      <HeaderSearchBarContext.Provider
        value={{
          isSearchBarCollapsed,
          updateIsSearchBarCollapsed: setIsSearchBarCollapsed,
        }}>
        {children}
      </HeaderSearchBarContext.Provider>
    </StyledButtonsContainer>
  )
}

export default ButtonsContainer
