import Blink from '@components/Blink'
import { LogoProps } from '@components/Footer/types'
import SvgIcon from '@components/SvgIcon'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

type StyledLogoProps = Pick<LogoProps, 'logoType'>

const StyledSvgLogo = styled(SvgIcon)<StyledLogoProps>`
  ${({
    theme: {
      color: {
        primary: { blickRed: blickRedColor },
      },
    },
    logoType,
  }) => css`
    && {
      user-select: none;
      display: inline-block;
      height: ${logoType === 'blick' ? '36px' : '23px'};
      ${logoType === 'blick' && `fill: ${blickRedColor};`};
    }
  `}
`

const Logo: FunctionComponent<LogoProps> = ({ logoType, blinkProps }) => (
  <Blink
    {...blinkProps}
    ariaLabel={`${logoType === 'blick' ? 'Blick' : 'Ringier'} logo`}>
    <StyledSvgLogo
      logoType={logoType}
      iconName={logoType === 'blick' ? 'logo-blick-2024' : 'logo-ringier'}
      size={logoType === 'blick' ? '102px' : '120px'}
    />
  </Blink>
)

export default Logo
