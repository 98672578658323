import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'

export interface CatchwordProps {
  children?: ReactNode
}

const StyledCatchword = styled.div`
  ${({
    theme: {
      spacing: { spacing4 },
      color: {
        tertiary: { grey800: grey800Color },
      },
      typography: {
        subheads: {
          small1: { bundledCSS: small1SubheadingCSS },
        },
      },
      utils: { ellipsis },
    },
  }) => css`
    ${small1SubheadingCSS};
    color: ${grey800Color};
    margin-bottom: ${spacing4};
    ${ellipsis('100%', 1)};
    display: block;
  `}
`

const Catchword: FunctionComponent<CatchwordProps> = ({ children }) => {
  return <StyledCatchword>{children}</StyledCatchword>
}

export default Catchword
