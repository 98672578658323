export type SpacingType = {
  spacing2: string
  spacing4: string
  spacing8: string
  spacing12: string
  spacing16: string
  spacing20: string
  spacing24: string
  spacing28: string
  spacing32: string
  spacing40: string
  spacing56: string
  spacing64: string
  spacing80: string
  spacing96: string
}

const spacing = {
  spacing2: '2px',
  spacing4: '4px',
  spacing8: '8px',
  spacing12: '12px',
  spacing16: '16px',
  spacing20: '20px',
  spacing24: '24px',
  spacing28: '28px',
  spacing32: '32px',
  spacing40: '40px',
  spacing56: '56px',
  spacing64: '64px',
  spacing80: '80px',
  spacing96: '96px',
} as const satisfies SpacingType

export default spacing
