import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

interface TagProps {
  text: string
}

const Wrapper = styled.div`
  ${({
    theme: {
      color: {
        tertiary: { grey700 },
      },
      spacing: { spacing16, spacing12 },
    },
  }) => css`
    border: 1px solid ${grey700};
    border-radius: 50px;
    width: fit-content;
    max-width: 300px;
    display: flex;
    align-items: center;
    padding: ${spacing12} ${spacing16};
  `}
`

const StyledText = styled.div`
  ${({
    theme: {
      typography: {
        subheads: {
          small1: { bundledCSS: subheadingSmall1CSS },
        },
      },
      color: {
        tertiary: { grey700 },
      },
    },
  }) => css`
    ${subheadingSmall1CSS};
    text-transform: capitalize;
    color: ${grey700};
    word-break: break-all;
    text-align: center;
  `}
`

const Tag: FunctionComponent<TagProps> = ({ text }) => {
  return (
    <>
      {text && (
        <Wrapper>
          <StyledText>{text}</StyledText>
        </Wrapper>
      )}
    </>
  )
}

export default Tag
