import {
  FunctionComponent,
  KeyboardEvent,
  SyntheticEvent,
  useCallback,
  useRef,
} from 'react'
import styled, { css, useTheme } from 'styled-components'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import router from 'next/router'
import config from '@config'
import useIsErrorPage from '@hooks/useIsErrorPage'
import IconButton, { IconButtonProps } from '@components/Buttons/IconButton'
import translate from '@i18n'
import useHeaderSearchBar from '@hooks/useHeaderSearchBar'
import useExecuteOnClientNavigation from '@hooks/useExecuteOnClientNavigation'

interface StyledInputProps {
  isSearchBarCollapsed: boolean
}

const {
  search: { sectionUrl },
} = config

interface SearchInputProps {
  className?: string
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  align-items: center;
`

const StyledInput = styled.input<StyledInputProps>`
  ${({
    theme: {
      typography: {
        bodycopy: {
          small2: { bundledCSS: small2BodycopyCSS },
        },
      },
      color: {
        tertiary: { grey700: grey700Color, grey1000: grey1000Color },
      },
    },
    isSearchBarCollapsed,
  }) => css`
    width: 132px;
    height: 22px;

    color: ${grey1000Color};

    ::placeholder {
      color: ${grey700Color};
      opacity: 1; /* Firefox */
    }

    background-image: none;
    border: none;
    outline: none;

    ${small2BodycopyCSS};

    transition: width 0.1s ease-in-out;

    ${isSearchBarCollapsed &&
    css`
      width: 0;
      padding: 0;
    `}
  `}
`

const StyledIconButton = styled(IconButton)`
  ${({
    theme: {
      color: {
        primary: { blickRed: blickRedColor },
      },
    },
  }) => css`
    &:hover {
      svg {
        fill: ${blickRedColor};
      }
    }
  `}
`

const SearchInput: FunctionComponent<SearchInputProps> = ({ className }) => {
  const textInputElt = useRef<HTMLInputElement>(null)
  const { isSearchBarCollapsed, updateIsSearchBarCollapsed } =
    useHeaderSearchBar()
  const theme = useTheme()
  const isErrorPage = useIsErrorPage()

  const inputProps = {
    ariaLabel: translate('search.defaultButtonTitle'),
    iconName: 'magnifying-glass',
    iconColor: theme.color.primary.primary01,
    iconSize: 24,
    buttonSize: 40,
  } satisfies Omit<IconButtonProps, 'onClick'>

  const resetInputOnNavigation = useCallback(() => {
    if (textInputElt.current) {
      textInputElt.current.value = ''
    }
  }, [textInputElt])

  useExecuteOnClientNavigation(resetInputOnNavigation)

  const trackingOnNavigationClick = useCallback<
    TrackingFnType<{ searchTerm?: string }>
  >(
    ({ extraData }) => ({
      event: 'navigation_click',
      eventCategory: 'Navigation',
      eventLabel: extraData.searchTerm,
      eventAction: 'search',
    }),
    []
  )

  const handleNavigationClickTrack = useTracking(trackingOnNavigationClick)

  const trackingOnSearch = useCallback<TrackingFnType<{ searchTerm?: string }>>(
    ({ extraData }) => ({
      event: 'search',
      eventCategory: 'search',
      eventLabel: extraData.searchTerm,
      eventAction: 'submitted',
    }),
    []
  )

  const handleSearchTrack = useTracking(trackingOnSearch)

  const onSearch = useCallback(
    (searchTerm: string | undefined) => {
      if (
        typeof searchTerm !== 'undefined' &&
        searchTerm !== '' &&
        searchTerm.trim() !== ''
      ) {
        if (isErrorPage) {
          location.href = `${sectionUrl}?q=${searchTerm}`
        } else {
          handleSearchTrack({ searchTerm })
          router.push({
            pathname: sectionUrl,
            query: { q: searchTerm },
          })
          if (textInputElt?.current) {
            textInputElt.current.value = ''
          }
        }
      }
    },
    [handleSearchTrack, isErrorPage]
  )

  const toggleSearchBarCollapsed = useCallback(
    (e?: SyntheticEvent) => {
      e?.preventDefault?.()
      handleNavigationClickTrack({})
      updateIsSearchBarCollapsed((isSearchBarCollapsed) => {
        if (isSearchBarCollapsed) {
          textInputElt.current?.focus()
        }
        return !isSearchBarCollapsed
      })
    },
    [updateIsSearchBarCollapsed, handleNavigationClickTrack]
  )

  const handleSearchIconClick = useCallback(
    (e: SyntheticEvent) => {
      e?.preventDefault?.()
      textInputElt.current?.blur()

      const value = textInputElt?.current?.value
      handleNavigationClickTrack({ searchTerm: value })
      if (value) {
        onSearch(value)
      } else {
        updateIsSearchBarCollapsed(true)
      }
    },
    [
      handleNavigationClickTrack,
      onSearch,
      textInputElt,
      updateIsSearchBarCollapsed,
    ]
  )

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        // Do we need to do anything else here?
        if (textInputElt?.current) {
          textInputElt.current.value = ''
        }
        updateIsSearchBarCollapsed(true)
        textInputElt?.current?.blur()
      }
    },
    [updateIsSearchBarCollapsed]
  )

  return (
    <form className={className} onSubmit={handleSearchIconClick}>
      <Wrapper>
        {isSearchBarCollapsed ? (
          <StyledIconButton
            onClick={toggleSearchBarCollapsed}
            {...inputProps}
          />
        ) : (
          <StyledIconButton type="submit" {...inputProps} />
        )}
        <StyledInput
          isSearchBarCollapsed={isSearchBarCollapsed}
          ref={textInputElt}
          type="text"
          onKeyDown={handleKeyDown}
          placeholder={translate('search.input')}
          aria-label={translate('search.defaultButtonTitle')}
        />
      </Wrapper>
    </form>
  )
}

export default SearchInput
