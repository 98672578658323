import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { StyledDot, StyledPost } from './Post'
import VerticalLine from './VerticalLine'

interface EmptryTickerIndicatorProps {
  accentColor?: string
  children: string
}

const StyledText = styled.div`
  ${({
    theme: {
      typography: {
        bodycopy: {
          small2: { bundledCSS: small2BodyCopy },
        },
      },
      color: {
        tertiary: { grey600 },
      },
      spacing: { spacing16 },
    },
  }) => css`
    ${small2BodyCopy};
    color: ${grey600};
    padding-top: ${spacing16};
  `};
`

const EmptryTickerIndicator: FunctionComponent<EmptryTickerIndicatorProps> = ({
  accentColor,
  children,
}) => (
  <StyledPost accentColor={accentColor}>
    <VerticalLine accentColor={accentColor} />
    <StyledDot accentColor={accentColor} />
    <StyledText>{children}</StyledText>
  </StyledPost>
)

export default EmptryTickerIndicator
