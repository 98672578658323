import { useQueryClient } from '@tanstack/react-query'
import { FunctionComponent, useCallback, useEffect, useMemo } from 'react'
import {
  ChatbotLastQuestion,
  ChatbotMessage,
  ChatbotQuestionData,
} from './types'
import useChatbotVisible from '@hooks/useChatbot/useChatbotVisible'
import useChatbotLastQuestion from '@hooks/useChatbot/useChatbotLastQuestion'
import translate from '@i18n'
import useTracking from '@hooks/useTracking'
import { enrichQuestions, trackHandler } from './utils'
import { WidgetIdValues } from '@utils/chatbot/types'
import useABTestValue from '@hooks/useABTestValue'
import { getChat, getAnswer, getQuestions } from '@utils/chatbot'
import useChatbotContext from '@hooks/useChatbot/useChatbotContext'

const ChatbotManager: FunctionComponent = () => {
  const queryClient = useQueryClient()
  const chatbotVisible = useChatbotVisible()
  const lastQuestion = useChatbotLastQuestion()
  const handleTracking = useTracking(trackHandler)
  const isCUIEnabled = useABTestValue('showCUI') === 'show'
  const chatbotContext = useChatbotContext()

  const setNewMessage = useCallback(
    (newMessage: ChatbotMessage) => {
      queryClient.setQueryData(
        ['chatbotMessages'],
        (prevData: ChatbotMessage[]) =>
          prevData ? [...prevData, newMessage] : [newMessage]
      )
      queryClient.invalidateQueries({
        queryKey: ['chatbotMessages'],
      })
    },
    [queryClient]
  )

  const getInitialQuestions = useCallback(async () => {
    queryClient.setQueryData(['chatbotInitialLoading'], true)
    queryClient.invalidateQueries({
      queryKey: ['chatbotInitialLoading'],
    })

    const result = await getQuestions({
      widgetId: WidgetIdValues.QuestionWidget,
      context: chatbotContext,
    })()

    if (result) {
      const { questions } = result
      const enrichedQuestions: ChatbotQuestionData[] = enrichQuestions(
        questions,
        true,
        chatbotContext
      )
      queryClient.setQueryData<ChatbotQuestionData[]>(
        ['chatbotInitialQuestions'],
        enrichedQuestions
      )
      queryClient.invalidateQueries({
        queryKey: ['chatbotInitialQuestions'],
      })
    }

    queryClient.setQueryData(['chatbotInitialLoading'], false)
    queryClient.invalidateQueries({
      queryKey: ['chatbotInitialLoading'],
    })
  }, [queryClient, chatbotContext])

  const getQuestionAnswer = useMemo(
    () => async (question: ChatbotLastQuestion) => {
      setNewMessage({ type: 'question', text: question.data.text })
      queryClient.setQueryData(['chatbotAnswerLoading'], true)
      queryClient.invalidateQueries({
        queryKey: ['chatbotAnswerLoading'],
      })

      const answerResult = question.data.isCached
        ? await getAnswer({
            widgetId: WidgetIdValues.QuestionWidget,
            questionId: question.data.id,
            context: question.data.context,
          })()
        : await getChat({
            text: question.data.text,
            isSuggestedQuestion: question.data.isSuggestedQuestion,
            context: question.data.context,
            conversationId: question.chatbotConversationId,
          })()

      if (answerResult) {
        const { content, links, traceId, spanId, conversationId } = answerResult

        queryClient.setQueryData(['chatbotConversationId'], conversationId)
        queryClient.invalidateQueries({
          queryKey: ['chatbotConversationId'],
        })

        handleTracking({
          event: question.data.isCached
            ? 'chatbot_first_question'
            : 'chatbot_select_question',
          messageId: traceId,
          conversationId,
          isSuggestedQuestion: question.data.isSuggestedQuestion,
          questionAnswered: null,
        })

        setNewMessage({
          type: 'answer',
          content,
          links,
          traceId,
          spanId,
          conversationId,
          isSuggestedQuestion: question.data.isSuggestedQuestion,
        })
      } else {
        setNewMessage({
          type: 'answer',
          content: [
            {
              text: translate('chatbot.answerMessage.error'),
            },
          ],
        })
      }

      queryClient.setQueryData(['chatbotAnswerLoading'], false)
      queryClient.invalidateQueries({
        queryKey: ['chatbotAnswerLoading'],
      })
    },
    [queryClient, setNewMessage, handleTracking]
  )

  useEffect(() => {
    if (
      isCUIEnabled &&
      !lastQuestion &&
      chatbotVisible &&
      !process.env.STORYBOOK
    ) {
      getInitialQuestions()
    }
  }, [getInitialQuestions, chatbotVisible, lastQuestion, isCUIEnabled])

  useEffect(() => {
    if (isCUIEnabled && lastQuestion && !process.env.STORYBOOK) {
      getQuestionAnswer(lastQuestion)
    }
  }, [lastQuestion, getQuestionAnswer, isCUIEnabled])

  useEffect(() => {
    queryClient.setQueryData(['chatbotConversationId'], null)
    queryClient.invalidateQueries({
      queryKey: ['chatbotConversationId'],
    })
  }, [chatbotContext, queryClient])

  return null
}

export default ChatbotManager
