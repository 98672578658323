import { css } from 'styled-components'

const ellipsis = (width = '100%', lines = 1) => {
  if (lines > 1) {
    return css`
      display: -webkit-box;
      max-width: ${width};
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: normal;
      white-space: normal;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: ${lines};
    `
  }

  return css`
    display: inline-block;
    max-width: ${width};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
  `
}

export type UtilsType = {
  ellipsis: typeof ellipsis
}

const utils = {
  ellipsis,
}

export default utils
