import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent } from 'react'
import config from '@config'
import EmbeddedContent from '@components/EmbeddedContent'

const {
  riddle: { widgetUrl },
} = config

interface RiddleProps {
  id: string
}

const Riddle: FunctionComponent<RiddleProps> = ({ id }) => (
  <EmbeddedContent
    url={`${widgetUrl}?riddleId=${id}`}
    id={id}
    maxHeight={500}
    maxWidth={510}
    idFieldName="riddleId"
    heightFieldName="riddleHeight"
  />
)

const widget = {
  kind: ['widget', 'tool', 'riddle'],
  component: Riddle,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
