import dynamic from 'next/dynamic'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import ImageWrapper from '@widgets/AuthorHeadline/ImageWrapper'
import AuthorInfo from '@widgets/AuthorHeadline/AuthorInfo'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { AuthorHeadlineProps } from '@widgets/AuthorHeadline/types'
import { desktopCSS } from '@measures/responsive'
import { bodyStyle } from '@widgets/Body/bodyStyle'
import { allowedHtmlTags } from '@widgets/TextBody'
import useAuthorSchema from '@hooks/useAuthorSchema'

const HTMLView = dynamic(() => import('@components/HTMLView'))

const Wrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing4, spacing12, spacing24, spacing32 },
    },
  }) => css`
    display: grid;
    margin-top: ${spacing4};
    ${desktopCSS(css`
      margin-top: ${spacing32};
    `)}
    margin-bottom: ${spacing12};
    grid-gap: ${spacing24};
  `}
`

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${desktopCSS(css`
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  `)}
`

const BioWrapper = styled.div`
  ${({
    theme: {
      typography: {
        bodycopy: {
          small2: { bundledCSS: bodyCopySmall2CSS },
        },
      },
    },
  }) => {
    return css`
      ${bodyStyle};
      ${bodyCopySmall2CSS};
    `
  }}
`

const AuthorHeadline: FunctionComponent<AuthorHeadlineProps> = ({
  image,
  bio,
  ...authorInfo
}) => {
  useAuthorSchema({ socialMedia: authorInfo?.socialMedia })
  return (
    <Wrapper>
      <StyledWrapper>
        <ImageWrapper>{image}</ImageWrapper>
        <AuthorInfo {...authorInfo} />
      </StyledWrapper>
      {bio && (
        <BioWrapper>
          <HTMLView content={bio} allowedHtmlTags={allowedHtmlTags} />
        </BioWrapper>
      )}
    </Wrapper>
  )
}

const widget = {
  kind: ['widget', 'author', 'headline'],
  component: AuthorHeadline,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
