import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { Image, TeaserVideo, TargetContentType, Link } from '@utils/cook/types'

import VideoLabel from '@components/VideoLabel'
import Title from '@components/TeaserBite/Title'
import TeaserImage from '@components/TeaserBite/Image'
import TeaserAnimatedPreview from '@components/TeaserAnimatedPreview'
import useAnimatedPreview from '@hooks/useAnimatedPreview'

interface TeaserBiteVideo extends TeaserVideo {
  title: string
}

export interface TeaserBiteAPIProps {
  accentColor: string
  title: string
  section: {
    name: string
    uniqueName: string
  }
  targetContentType: TargetContentType
  video: TeaserBiteVideo
  articleId: string
  image: Image<'9_16'>
  link?: Link
  updatedDate: {
    value: string
    format: string
  }
}

const StyledTeaserBite = styled.div`
  ${({
    theme: {
      spacing: { spacing12 },
    },
  }) => css`
    display: grid;
    grid-row-gap: ${spacing12};

    position: relative;
    cursor: pointer;
  `}
`

const StyledVideoLabel = styled(VideoLabel)`
  ${({
    theme: {
      spacing: { spacing12 },
    },
  }) => css`
    position: absolute;
    z-index: 1;
    bottom: ${spacing12};
    left: ${spacing12};
  `}
`

const TeaserBite: FunctionComponent<TeaserBiteAPIProps> = ({
  image,
  targetContentType,
  link,
  title,
  video,
}) => {
  const ref = useAnimatedPreview({
    video,
    articleUrl: link?.href ?? '',
  })

  return (
    <StyledTeaserBite ref={ref}>
      <TeaserImage {...image}>
        {!!video && (
          <TeaserAnimatedPreview video={video} articleUrl={link?.href ?? ''} />
        )}
        <StyledVideoLabel
          duration={video?.duration}
          targetContentType={targetContentType}
        />
      </TeaserImage>
      <Title>{title}</Title>
    </StyledTeaserBite>
  )
}

const widget = {
  kind: ['teaser-bite'],
  component: TeaserBite,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
