import { FunctionComponent } from 'react'
import styled from 'styled-components'

const StyledFillerItem = styled.span``

const FillerItem: FunctionComponent = () => {
  return <StyledFillerItem role="presentation" />
}

export default FillerItem
