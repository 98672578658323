import { FunctionComponent, memo } from 'react'
import useFooter from '@hooks/useFooter'
import FooterComponent from './FooterComponent'
import { getFooterItems } from './utils'
import { GetFooterItemsType } from './types'
import styled, { css } from 'styled-components'
import {
  desktopCSS,
  mobileCSS,
  mobileAndTabletCSS,
  tabletCSS,
} from '@measures/responsive'
import useIsErrorPage from '@hooks/useIsErrorPage'
import { printCSS } from '@utils/style'

interface FooterWrapperProps {
  isError: boolean
}

const FooterWrapper = styled.footer<FooterWrapperProps>`
  ${({
    isError,
    theme: {
      color: {
        primary: { primary02: primary02Color },
      },
      spacing: { spacing16, spacing24, spacing40, spacing56 },
    },
  }) => css`
    ${printCSS(css`
      display: none;
    `)}
    ${isError
      ? css`
          ${desktopCSS(css`
            display: grid;
            grid-template-columns: 994px;
            justify-content: center;
          `)};

          ${mobileAndTabletCSS(css`
            display: block;
          `)};
        `
      : css`
          background-color: ${primary02Color};
          display: grid;
          grid-row-gap: ${spacing24};

          ${desktopCSS(css`
            padding: ${spacing56} 15px;
            grid-row-gap: ${spacing40};
          `)};

          ${tabletCSS(css`
            padding: ${spacing24} 68px;
          `)};

          ${mobileCSS(css`
            padding: ${spacing24} ${spacing16};
          `)};
        `}
  `}
`

const Footer: FunctionComponent<{ className?: string }> = ({ className }) => {
  const { blick: footerBlick, ringier: footerRingier } = useFooter()
  const isErrorPage = useIsErrorPage()

  const {
    columns: columnsForMobileBlick,
    rows: rowsForMobileBlick,
    items: dataForMobileBlick,
  } = footerBlick
    ? getFooterItems(footerBlick.links, 2)
    : ({} as ReturnType<GetFooterItemsType>)

  const {
    columns: columnsForNonMobileBlick,
    rows: rowsForNonMobileBlick,
    items: dataForNonMobileBlick,
  } = footerBlick
    ? getFooterItems(footerBlick.links, 3)
    : ({} as ReturnType<GetFooterItemsType>)

  const {
    columns: columnsForMobileRingier,
    rows: rowsForMobileRingier,
    items: dataForMobileRingier,
  } = footerRingier
    ? getFooterItems(footerRingier.links, 2)
    : ({} as ReturnType<GetFooterItemsType>)

  const {
    columns: columnsForNonMobileRingier,
    rows: rowsForNonMobileRingier,
    items: dataForNonMobileRingier,
  } = footerRingier
    ? getFooterItems(footerRingier.links, 3)
    : ({} as ReturnType<GetFooterItemsType>)

  return (
    <FooterWrapper isError={!!isErrorPage} className={className}>
      {!!footerBlick && (
        <FooterComponent
          title={footerBlick.text}
          logoType="blick"
          blinkProps={footerBlick.link}
          columnsForMobile={columnsForMobileBlick}
          rowsForMobile={rowsForMobileBlick}
          dataForMobile={dataForMobileBlick}
          columnsForNonMobile={columnsForNonMobileBlick}
          rowsForNonMobile={rowsForNonMobileBlick}
          dataForNonMobile={dataForNonMobileBlick}
        />
      )}
      {!!footerRingier && (
        <FooterComponent
          isExpandable={true}
          initialExpandedState="collapsed"
          title={footerRingier.text}
          logoType="ringier"
          blinkProps={footerRingier.link}
          columnsForMobile={columnsForMobileRingier}
          rowsForMobile={rowsForMobileRingier}
          dataForMobile={dataForMobileRingier}
          columnsForNonMobile={columnsForNonMobileRingier}
          rowsForNonMobile={rowsForNonMobileRingier}
          dataForNonMobile={dataForNonMobileRingier}
        />
      )}
    </FooterWrapper>
  )
}

export { FooterWrapper }

const MemoizedFooter = memo(Footer, () => true)

MemoizedFooter.displayName = 'MemoizedFooter'

export default MemoizedFooter
