import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { desktopCSS } from '@measures/responsive'
import { TeaserToiCommercialLayoutTemplateProps } from '@widgets/TeaserToiCommercial'

export interface TitleProps {
  layoutTemplate: TeaserToiCommercialLayoutTemplateProps['layoutTemplate']
  children: string
}

const StyledTitle = styled.div<Pick<TitleProps, 'layoutTemplate'>>`
  ${({
    theme: {
      color: {
        primary: { primary02: primary02Color },
      },
      typography: {
        headings: {
          medium: { bundledCSS: mediumHeadingCSS },
          large: { bundledCSS: largeHeadingCSS },
          xxlarge: { bundledCSS: xxlargeHeadingCSS },
          xxxlarge: { bundledCSS: xxxlargeHeadingCSS },
        },
      },
    },
    layoutTemplate,
  }) => {
    const textStyle = css`
      ${layoutTemplate === 'big' ? largeHeadingCSS : mediumHeadingCSS};
      ${desktopCSS(css`
        ${layoutTemplate === 'big' ? xxxlargeHeadingCSS : xxlargeHeadingCSS};
      `)};
      color: ${primary02Color};
    `

    //! We apply these rules multiple time to override
    //! browser and body styles applying on `strong` and `p` tags.
    return css`
      position: relative;
      ${textStyle};

      > strong,
      > p {
        ${textStyle};
      }

      > p {
        margin: 0;
      }
    `
  }}
`

const Title: FunctionComponent<TitleProps> = ({ layoutTemplate, children }) => {
  return (
    <StyledTitle
      layoutTemplate={layoutTemplate}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  )
}

export default Title
