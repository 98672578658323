import { FunctionComponent } from 'react'
import JSONRenderer from '@components/JSONRenderer'
import { CueLiveAdPost } from '@utils/cueLive'
import usePageMetadata from '@hooks/usePageMetadata'
import styled, { css } from 'styled-components'
import VerticalLine from './VerticalLine'
import { desktopCSS } from '@measures/responsive'

type AdPostProps = Pick<CueLiveAdPost, 'id'> & {
  accentColor?: string
}

const StyledAdPost = styled.div`
  ${({
    theme: {
      spacing: { spacing24, spacing32 },
    },
  }) => css`
    position: relative;

    padding-bottom: ${spacing24};

    ${desktopCSS(css`
      padding-bottom: ${spacing32};
    `)};
  `}
`

const AdPost: FunctionComponent<AdPostProps> = ({ id, accentColor }) => {
  const { livetickerAd } = usePageMetadata()

  const renderedWidget = {
    kind: ['ad-ringier'],
    containerId: id,
    ...livetickerAd,
  }

  return (
    <StyledAdPost>
      <VerticalLine accentColor={accentColor} />
      <JSONRenderer>{renderedWidget}</JSONRenderer>
    </StyledAdPost>
  )
}

export default AdPost
