import { mobileAndTabletCSS } from '@measures/responsive'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

const StyledLead = styled.div`
  ${({
    theme: {
      typography: {
        bodycopy: {
          small2: { bundledCSS: bodycopySmall2 },
        },
      },
      color: {
        primary: { primary01 },
      },
      utils: { ellipsis },
    },
  }) => css`
    ${bodycopySmall2}
    color: ${primary01};
    ${ellipsis('100%', 3)}

    ${mobileAndTabletCSS(css`
      display: none;
    `)}
  `}
`

const Lead: FunctionComponent<{ children: string }> = ({ children: lead }) => (
  <StyledLead>{lead}</StyledLead>
)

export default Lead
