import {
  createRef,
  FunctionComponent,
  SyntheticEvent,
  useCallback,
} from 'react'
import styled, { css } from 'styled-components'
import { desktopCSS } from '@measures/responsive'
import LanguageSwitcher from '@components/LanguageSwitcher'
import Weather from '@components/Header/FirstRow/Weather'
import Input from '@components/Input'
import translate from '@i18n'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import router from 'next/router'
import useIsErrorPage from '@hooks/useIsErrorPage'
import config from '@config'
import CloseIcon from '../CloseIcon'

const {
  search: { sectionUrl },
} = config

interface NavigationHeaderProps {
  onClose: () => void
}

const StyledWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    display: flex;
    flex-direction: column;
    padding: ${spacing16};
    gap: ${spacing16};

    ${desktopCSS(css`
      padding: 0 ${spacing16};
    `)};
  `}
`

const StyledNavigationHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`

const StyledInputWrapper = styled.form`
  ${desktopCSS(css`
    display: none;
  `)};
`
const StyledSearchInput = styled(Input)`
  height: 48px;
`

const IconsContainer = styled.div`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    flex: 1;
    display: flex;
    align-items: center;
    gap: ${spacing16};

    ${desktopCSS(css`
      display: none;
    `)};
  `}
`

const NavigationHeader: FunctionComponent<NavigationHeaderProps> = ({
  onClose,
}) => {
  const inputRef = createRef<HTMLInputElement>()
  const isErrorPage = useIsErrorPage()

  const trackingOnNavigationClick = useCallback<
    TrackingFnType<{ searchTerm?: string }>
  >(
    ({ extraData }) => ({
      event: 'navigation_click',
      eventCategory: 'Navigation',
      eventLabel: extraData.searchTerm,
      eventAction: 'search',
    }),
    []
  )

  const handleNavigationClickTrack = useTracking(trackingOnNavigationClick)

  const trackingOnSearch = useCallback<TrackingFnType<{ searchTerm?: string }>>(
    ({ extraData }) => ({
      event: 'search',
      eventCategory: 'search',
      eventLabel: extraData.searchTerm,
      eventAction: 'submitted',
    }),
    []
  )

  const handleSearchTrack = useTracking(trackingOnSearch)

  const handleSearch = useCallback(
    (e: SyntheticEvent) => {
      e?.preventDefault()
      inputRef.current?.blur()
      const searchTerm = inputRef?.current?.value
      handleNavigationClickTrack({ searchTerm })
      if (
        typeof searchTerm !== 'undefined' &&
        searchTerm !== '' &&
        searchTerm.trim() !== ''
      ) {
        if (isErrorPage) {
          location.href = `${sectionUrl}?q=${searchTerm}`
        } else {
          handleSearchTrack({ searchTerm })
          router.push({
            pathname: sectionUrl,
            query: { q: searchTerm },
          })
          if (inputRef?.current) {
            inputRef.current.value = ''
          }
        }
      }
    },
    [handleSearchTrack, isErrorPage, inputRef, handleNavigationClickTrack]
  )

  return (
    <StyledWrapper>
      <StyledNavigationHeader>
        <IconsContainer>
          <LanguageSwitcher />
          <Weather />
        </IconsContainer>
        <CloseIcon onClick={onClose} />
      </StyledNavigationHeader>
      <StyledInputWrapper onSubmit={handleSearch}>
        <StyledSearchInput
          ref={inputRef}
          placeholder={translate('search.input')}></StyledSearchInput>
      </StyledInputWrapper>
    </StyledWrapper>
  )
}

export default NavigationHeader
