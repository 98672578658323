import LayoutStep, { LayoutStepWidget } from '@components/LayoutStep'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent, useMemo } from 'react'
import styled, { css } from 'styled-components'
import translate from '@i18n'
import LayoutCard from '@components/LayoutCard'
import { mobileCSS } from '@measures/responsive'
import JSONRenderer from '@components/JSONRenderer'
import { LayoutCardProps } from '@widgets/LayoutCardSteps/types'
import { getStepsFromContent } from '@widgets/LayoutCardSteps/utils'
import { printCSS } from '@utils/style'

const StyledLayoutCard = styled(LayoutCard)`
  ${({
    theme: {
      spacing: { spacing24, spacing32, spacing40 },
      measures: { layoutCardPadding },
    },
  }) => {
    return css`
      display: grid;
      grid-row-gap: ${spacing32};
      padding: ${spacing40} ${layoutCardPadding.desktop} ${spacing32};
      ${mobileCSS(css`
        padding: ${spacing24} ${layoutCardPadding.mobile};
      `)}
      ${printCSS(css`
        padding: 0;
      `)}
    `
  }}
`

const Title = styled.span`
  ${({
    theme: {
      typography: {
        headings: {
          medium: { bundledCSS: headingMediumCSS },
        },
      },
    },
  }) => {
    return css`
      ${headingMediumCSS};
    `
  }}
`

const isContentValid = (content: any): content is LayoutStepWidget =>
  content?.length > 0

const LayoutCardSteps: FunctionComponent<LayoutCardProps> = ({ content }) => {
  const { steps, restContent } = useMemo(
    () => getStepsFromContent(content),
    [content]
  )
  if (!isContentValid(steps)) {
    return null
  }
  return (
    <StyledLayoutCard>
      <Title>{translate('recipe.instructions')}</Title>
      {steps.map(
        (step, index) =>
          step?.content && (
            <LayoutStep
              key={index}
              stepNumber={index + 1}
              totalSteps={steps.length}>
              {step.content}
            </LayoutStep>
          )
      )}
      <JSONRenderer>{restContent}</JSONRenderer>
    </StyledLayoutCard>
  )
}

const widget = {
  kind: ['layout', 'card', 'steps'],
  component: LayoutCardSteps,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
