import config from '@config'
import usePageMetadata from '@hooks/usePageMetadata'
import { getImageProps } from '@utils/schema'
import Head from 'next/head'
import { FunctionComponent } from 'react'
import { MetaTags } from './types'
import { getSocialMediaTitle } from './utils'

const {
  publication: { publication },
  backend: { baseUrl },
  metatags: { article },
} = config

const ArticleMetaTags: FunctionComponent = () => {
  const {
    htmlTitle,
    contextId,
    author,
    publishedDate,
    lastModifiedDate,
    breadcrumbs,
    metaDescription,
    socialPostImage,
    isPlus,
    type,
    url,
    schema,
    teaserTitle,
    openGraphTitle,
  } = usePageMetadata()

  if (type !== 'article') {
    return null
  }

  const fullUrl = `${baseUrl}${url}`
  const socialMediaTitle = getSocialMediaTitle({
    openGraphTitle,
    teaserTitle,
    htmlTitle,
    isPlus: !!isPlus,
  })

  const imagePropsForSocialShare = getImageProps({
    element: socialPostImage ? { image: socialPostImage } : schema,
    customRatio: '16_9',
  })[0]

  const iOSDeeplinkUrl = `blick${
    publication === 'romandie' ? 'fr' : ''
  }://article/${contextId}`

  const articleMetaTags: MetaTags[] = [
    ...article,
    { property: 'al:ios:url', content: iOSDeeplinkUrl },
    { property: 'article:author', content: author },
    { property: 'article:modified_time', content: lastModifiedDate },
    { property: 'article:published_time', content: publishedDate },
    { property: 'article:section', content: breadcrumbs?.[1]?.title },
    { property: 'article:tag', content: breadcrumbs?.[2]?.title },
    { property: 'author', content: author },
    { property: 'og:description', content: metaDescription },
    { property: 'og:image', content: imagePropsForSocialShare.url },
    { property: 'og:image:alt', content: imagePropsForSocialShare.alt },
    {
      property: 'og:image:height',
      content: imagePropsForSocialShare.height?.toString(),
    },
    {
      property: 'og:image:width',
      content: imagePropsForSocialShare.width?.toString(),
    },
    { property: 'og:site_name', content: 'Blick' },
    { property: 'og:title', content: socialMediaTitle },
    { property: 'og:type', content: 'article' },
    { property: 'og:updated_time', content: lastModifiedDate },
    { property: 'og:url', content: fullUrl },
    { property: 'twitter:app:url:iphone', content: iOSDeeplinkUrl },
    { property: 'twitter:card', content: 'summary_large_image' },
    { property: 'twitter:url', content: fullUrl },
    { property: 'twitter:title', content: socialMediaTitle },
    { property: 'twitter:description', content: metaDescription },
    { property: 'twitter:image:alt', content: imagePropsForSocialShare.alt },
    { property: 'twitter:image', content: imagePropsForSocialShare.url },
  ]

  return (
    <Head>
      {articleMetaTags.map(
        ({ key, property, content }) =>
          content && (
            <meta key={key || property} property={property} content={content} />
          )
      )}
    </Head>
  )
}

export default ArticleMetaTags
