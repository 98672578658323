import { FunctionComponent } from 'react'
import { dayjs } from '@utils/date'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import VideoZattoo from '@components/Video/Zattoo'
import { VideoZattooAPIProps } from '@components/Video/Zattoo/types'

const VideoZattooWidget: FunctionComponent<VideoZattooAPIProps> = ({
  channel,
  startTime,
  endTime,
}) => {
  const hasChannel = !!channel

  const isActiveStream = dayjs()
    .utc()
    .isBetween(dayjs(startTime).utc(), dayjs(endTime).utc())

  if (!isActiveStream || !hasChannel) {
    return null
  }

  return <VideoZattoo channel={channel} />
}

const widget = {
  kind: ['widget', 'video', 'zattoo'],
  component: VideoZattooWidget,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
