import Galleries from '@components/Gallery'
import GalleryFullScreenContextProvider from '@contexts/fullscreen/fullscreenContextProvider'
import { FunctionComponent, memo, useMemo } from 'react'
import { AdSlide, GalleryWidget } from './types'
import {
  getTransformedSlides,
  isMultiImageGallery,
  isSingleImageGallery,
} from './utils'
import MultiImagesContext from '@contexts/multiImagesContext'

const { SingleImageGallery, MultiImageGallery } = Galleries

type GalleryWidgetWrapperProps = Omit<GalleryWidget, 'kind'>

const GalleryWidgetWrapper: FunctionComponent<GalleryWidgetWrapperProps> = (
  props
) => {
  const {
    slides: rawSlides,
    adSlide,
    title: galleryTitle,
    id: galleryId,
    uuid: galleryUUID,
  } = props

  const slides = useMemo(
    () =>
      getTransformedSlides({
        slides: rawSlides ?? [],
        adSlide: adSlide ?? ({} as AdSlide),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [galleryUUID]
  )

  if (!isMultiImageGallery(props) && !isSingleImageGallery(props)) {
    return null
  }

  return (
    <GalleryFullScreenContextProvider>
      {isSingleImageGallery(props) && (
        <SingleImageGallery slide={props.slides[0]} {...props} />
      )}
      {isMultiImageGallery(props) && (
        <MultiImagesContext.Provider value={true}>
          <MultiImageGallery
            galleryTitle={galleryTitle}
            galleryId={galleryId}
            galleryUUID={galleryUUID}
            slides={slides}
          />
        </MultiImagesContext.Provider>
      )}
    </GalleryFullScreenContextProvider>
  )
}

const MemoizedGalleryWidgetWrapper = memo(
  GalleryWidgetWrapper,
  (prevProps, nextProps) => prevProps.uuid === nextProps.uuid
)

MemoizedGalleryWidgetWrapper.displayName = 'MemoizedGalleryWidgetWrapper'

export default MemoizedGalleryWidgetWrapper
