import TwitterComponent from '@components/SocialEmbed/Twitter'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent } from 'react'

interface TwitterProps {
  url: string
}

const Twitter: FunctionComponent<TwitterProps> = ({ url }) => {
  return <TwitterComponent url={url} />
}

const widget = {
  kind: ['widget', 'social-embed', 'twitter'],
  component: Twitter,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
