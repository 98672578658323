import { FunctionComponent, useEffect, useState } from 'react'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import styled, { css } from 'styled-components'
import { mobileCSS } from '@measures/responsive'
import { getRandomAd } from './utils'
import BrandStudioAdItem from './BrandStudioAdItem'
import { BrandStudioAPIProps, BrandStudioRandomItemProps } from './types'
import useCMPCookieCategories from '@hooks/useCMPCookieCategories'
import useViewportType from '@hooks/useViewport/useViewportType'

interface StyledBrandStudioContainerProps {
  inlineadlabel: string
}

const StyledBrandStudioContainer = styled.div<StyledBrandStudioContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: relative;

  ${mobileCSS(css`
    flex-direction: column;

    div.brandstudio-item--full-width {
      margin-left: 0;
    }
  `)}
`

const BrandStudio: FunctionComponent<BrandStudioAPIProps> = ({ ads }) => {
  const [randomAd, setRandomAd] = useState<BrandStudioRandomItemProps>()

  const viewportType = useViewportType()

  useEffect(() => {
    if (ads?.length > 0) {
      const randomAdData = getRandomAd({
        ads,
        isMobile: viewportType === 'mobile',
      })
      setRandomAd(randomAdData)
    }
  }, [ads, viewportType])

  return randomAd ? (
    <StyledBrandStudioContainer inlineadlabel="brand_studio">
      {randomAd?.adInfo?.map((adInfo, index) => {
        return (
          <BrandStudioAdItem
            adTitle={randomAd.adTitle}
            key={index}
            trackingClass={randomAd.trackingClass}
            adInfo={adInfo}
          />
        )
      })}
    </StyledBrandStudioContainer>
  ) : null
}

const CMPAwareBrandStudio: FunctionComponent<BrandStudioAPIProps> = (props) => {
  const allCategoriesEnabled = useCMPCookieCategories(
    'allCategoriesEnabledChanged'
  )

  return allCategoriesEnabled ? <BrandStudio {...props} /> : null
}

const widget = {
  kind: ['widget', 'ad', 'brandstudio'],
  component: CMPAwareBrandStudio,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
