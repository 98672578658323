import config from '@config'

const {
  abTest: { windowKey },
} = config

const writeToLocalStorage = (
  featureFlagStorageInfo: Record<string, unknown>
) => {
  try {
    localStorage.setItem(windowKey, JSON.stringify(featureFlagStorageInfo))
    window[windowKey]._updateVariantsFromLocalStorage()
  } catch (err) {
    //! nothing we can do!
    console.error(err)
  }
}

const resetLocalStorage = () => {
  try {
    writeToLocalStorage({})
  } catch (err) {
    //! nothing we can do!
    console.error(err)
  }
}

export { writeToLocalStorage, resetLocalStorage }
