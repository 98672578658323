import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { desktopCSS } from '@measures/responsive'
import useHasInvertedColors from '@hooks/useHasInvertedColors'

export interface CatchwordProps {
  children?: ReactNode
  hasPlusLogo: boolean
}

export interface InvertedColorsStyledProps {
  hasInvertedColors: boolean
}

export type StyledCatchwordProps = Pick<CatchwordProps, 'hasPlusLogo'> &
  InvertedColorsStyledProps

const StyledCatchword = styled.div<StyledCatchwordProps>`
  ${({
    theme: {
      spacing: { spacing4 },
      color: {
        tertiary: { grey400: grey400Color, grey800: grey800Color },
      },
      typography: {
        subheads: {
          small1: { bundledCSS: small1SubheadingCSS },
        },
      },
      utils: { ellipsis },
    },
    hasPlusLogo,
    hasInvertedColors,
  }) => css`
    ${small1SubheadingCSS};
    color: ${hasInvertedColors ? grey400Color : grey800Color};
    margin-bottom: ${spacing4};
    ${ellipsis('100%', 1)};
    display: block;
    ${desktopCSS(css`
      ${ellipsis('100%', 2)};
      ${hasPlusLogo &&
      css`
        line-height: 1.3;
      `};
    `)}
  `}
`

const Catchword: FunctionComponent<CatchwordProps> = ({
  children,
  hasPlusLogo,
}) => {
  const hasInvertedColors = useHasInvertedColors()

  return (
    <StyledCatchword
      hasPlusLogo={hasPlusLogo}
      hasInvertedColors={hasInvertedColors}>
      {children}
    </StyledCatchword>
  )
}

export default Catchword
