import config from '@config'
import blickSchemaOrgLogo from '@assets/images/logo_schemaorg.png'
import iosAppLogo from '@assets/icons/apple/apple-touch-icon-180x180.png'
import androidAppLogo from '@assets/icons/android/android-chrome-192x192.png'
import { PageMetadata } from '@hooks/usePageMetadata'
import { FilteredArticleSchemaImage } from '@components/Schema/ArticleSchema'
import { Author, AuthorProps } from '@widgets/Author/types'
import { Link } from '@utils/cook/types'

interface ImageProps {
  element: any
  customRatio?: string
  isMainImage?: boolean
}

export type BreadcrumbSchema = Record<string, any>

export type HomeSchema = Record<string, any>

export type ArticleSchema = Record<string, any>

export type VideoSchema = Record<string, any>

export type BlickLogoSchema = Record<string, any>

export interface Schema {
  breadcrumb?: BreadcrumbSchema
  home?: HomeSchema
  article?: ArticleSchema
  video?: VideoSchema
  blickLogo?: BlickLogoSchema
}

export interface TeaserImageProps {
  teaserImage: {
    src: string
    alt?: string
    crops?: any
  }
}

export interface ArticleSchemaImage {
  url?: string
  alt?: string
  width?: number
  height?: number
}

interface BreadCrumbsListItem {
  '@type': string
  position: number
  name: string
  item?: {
    '@id': string
  }
}

type ImageSet = ArticleSchemaImage[] | ArticleSchemaImage

type OperatingSystemType = 'ANDROID' | 'iOS'

interface SoftwareApplicationSchemaProps {
  operatingSystem: OperatingSystemType
  description: string
  url: string
}

interface AuthorsForAuthorSchemaProps {
  name: string
  description?: string
  imageSet: ImageSet
  link?: Link
  socialMediaLinks?: string[]
}

const {
  backend: { baseUrl, imageUrl },
  schema: { breadcrumbListSchemaUrl, organizationId, personSchemaUrl },
} = config

const imageConfig: Record<string, any> = {
  ratios: {
    '1_1': {
      name: '1_1',
      width: 1,
      height: 1,
    },
    '4_3': {
      name: '4_3',
      width: 4,
      height: 3,
    },
    '16_9': {
      name: '16_9',
      width: 16,
      height: 9,
    },
    free: {
      name: 'free',
      width: 'auto',
      height: 'auto',
    },
  },
  sizes: {
    XS: 'XS',
    S: 'S',
    M: 'M',
    L: 'L',
    XL: 'XL',
    XLL: 'XLL',
    XXL: 'XXL',
    retina: {
      XS: 'XSR',
      S: 'SR',
      M: 'MR',
      L: 'LR',
      XL: 'XLR',
      XLL: 'XLL',
      XXL: 'XXLR',
    },
  },
  widths: {
    XS: 112,
    S: 300,
    M: 375,
    L: 500,
    XL: 1000,
    XLL: 1200,
    XXL: 2000,
  },
}

const _createCommonParams = ({
  size = '',
  ratio,
  isRetina,
  pixelDensity,
}: {
  size: any
  ratio: Record<string, any>
  isRetina: boolean
  pixelDensity: number
}) => {
  const { name: ratioName = '' } = ratio
  const selectedSize =
    size[isRetina ? 'retina' : 'default'] || (isRetina ? size + 'R' : size)

  // For native apps only
  // Fix for blurry images of high density devices
  // As the Akamai Image Manager only supports integers (no floats)
  // for the imdensity parameter, we have to round here
  if (pixelDensity) {
    return (
      'imwidth=' +
      imageConfig.widths[size] +
      '&imdensity=' +
      Math.round(pixelDensity) +
      '&ratio=' +
      ratioName.toUpperCase()
    )
  }

  // get default or retina width
  const width =
    imageConfig.widths[selectedSize] ||
    imageConfig.widths[selectedSize.slice(0, -1)] * 2

  return 'imwidth=' + width + '&ratio=' + ratioName.toUpperCase()
}

const _createURL = (
  src: string,
  cropParams: string,
  commonParams: string,
  isExternal: boolean
) => {
  if (isExternal) {
    return src
  }
  const path = `${src}?${commonParams}${cropParams}`

  // Disabling token generation for the sake of performance
  // const tokenParam =
  //   edgeAuth.options.tokenName + '=' + edgeAuth.generateURLToken(path)

  return `${imageUrl}${path}`
}

export const createCropParams = ({
  crops,
  ratio,
}: {
  crops: Record<string, any>
  ratio: Record<string, any>
}) => {
  const crop = crops[ratio.name] || crops || {} // free crops can come in from API as crops: { free: {}} or crops: {}
  const { x = 0, y = 0, width, height } = crop

  return width
    ? '&x=' + x + '&y=' + y + '&width=' + width + '&height=' + height
    : ''
}

export const createSrcArray = ({
  src,
  crops = {},
  options = { ratio: {} },
  isExternal,
}: {
  src: string
  crops: Record<string, any>
  options: { ratio: Record<string, any> }
  isExternal: boolean
}) => {
  const cropParams = createCropParams({
    crops,
    ...options,
  })
  const defaultCommonParams = _createCommonParams(options as any)
  const retinaCommonParams = _createCommonParams({
    ...options,
    isRetina: true,
  } as any)

  return [
    _createURL(src, cropParams, defaultCommonParams, isExternal),
    _createURL(src, cropParams, retinaCommonParams, isExternal),
  ]
}

const createResponsiveImageSrcSet = ({
  src,
  crops = {},
  options = {},
  isExternal = false,
}: {
  src: string
  crops: Record<string, any>
  options: Record<string, any>
  isExternal: boolean
}) => {
  if (src) {
    const [defaultImageURL] = createSrcArray({
      src,
      crops,
      options,
      isExternal,
    } as any)

    if (options.asArrayOfURISource) {
      return [
        {
          uri: defaultImageURL,
          scale: 1,
        },
      ]
    }

    return defaultImageURL
  }
}

const SCHEMAORG_IMAGE_RATIOS = [
  imageConfig.ratios['16_9'],
  imageConfig.ratios['4_3'],
  imageConfig.ratios['1_1'],
]

const getImageProps = ({
  element,
  customRatio,
  isMainImage,
}: ImageProps): ArticleSchemaImage[] => {
  const src = element?.image?.src

  // We want to show only the main image (due to Google AMP rules)
  if (!src) {
    return [{} as ArticleSchemaImage]
  }

  const cSrc = Array.isArray(src) ? src[0] : src
  const alt = element?.image?.alt
  const crops = element?.image?.crops
  const ratios: any = isMainImage
    ? SCHEMAORG_IMAGE_RATIOS.map((ratio) => ratio.name)
    : [customRatio || 'free']

  return ratios.map((ratio: any) => {
    const width = crops?.[ratio]?.width
    const height = crops?.[ratio]?.height
    return {
      url:
        createResponsiveImageSrcSet({
          src: cSrc,
          crops: crops,
          options: {
            ratio: { name: ratio },
            size: imageConfig.sizes.XXL,
          },
        } as any) ?? '',
      alt,
      width,
      height,
    }
  })
}

const getTeaserImageProps = ({
  teaserImage,
}: TeaserImageProps): Array<ArticleSchemaImage> => {
  const { src, alt, crops } = teaserImage

  return (SCHEMAORG_IMAGE_RATIOS as any).map((ratio: any) => {
    const crop = crops?.[ratio.name] || crops || {} // free crops can come in from API as crops: { free: {}} or crops: {}
    let { width, height } = crop

    // If width is > then 2000 -> downscale the width to 2000 and calculate the height accordingly
    if (width && height) {
      if (width > imageConfig.widths.XXL) {
        height = Math.round(height / (width / imageConfig.widths.XXL))
        width = imageConfig.widths.XXL
      }
    }

    return {
      url:
        createResponsiveImageSrcSet({
          src: src,
          crops: crops,
          options: {
            ratio: { name: ratio.name },
            size: imageConfig.sizes.XXL,
          },
        } as any) ?? '',
      alt,
      width,
      height,
    }
  })
}

const getPrimaryImageSchema = ({
  teaserImage,
  isArticle,
}: {
  teaserImage?: TeaserImageProps['teaserImage']
  isArticle?: boolean
}) => {
  if (!isArticle || !teaserImage) {
    return defaultLogo
  }
  const { src, crops } = teaserImage || {}
  const crop = crops?.['16_9'] || crops || {}
  let { width, height } = crop

  if (width && height) {
    if (width > imageConfig.widths.XXL) {
      height = Math.round(height / (width / imageConfig.widths.XXL))
      width = imageConfig.widths.XXL
    }
  }

  const url =
    (createResponsiveImageSrcSet({
      src: src,
      crops: crops,
      options: {
        ratio: { name: '16_9' },
        size: imageConfig.sizes.XXL,
      },
    } as any) as string) || ''

  return createImage(url, width, height)
}

const defaultAuthor = 'Blick.ch'

const blickFBPage = 'https://www.facebook.com/blick'
const blickTWPage = 'https://twitter.com/blickch'
const blickInstaPage = 'https://instagram.com/blick'
const blickWikipedia = 'https://de.wikipedia.org/wiki/Blick.ch'
const blickTikTokPage = 'https://www.tiktok.com/@blick'
const blickYouTubePage = 'https://www.youtube.com/@BlickTube'
const blickBSkyPage = 'https://bsky.app/profile/blick.ch'

const getSameAsInfo = () => ({
  sameAs: [
    blickFBPage,
    blickTWPage,
    blickInstaPage,
    blickTikTokPage,
    blickYouTubePage,
    blickBSkyPage,
    blickWikipedia,
  ],
})

const blickLogoInfo = {
  //! The `src` needs to be absolute, so the request can be run as is by search engines
  src: `${baseUrl}${blickSchemaOrgLogo}`,
  width: 137,
  height: 60,
}

const createListItem = (
  position: number,
  url: string,
  name: string,
  isLastItem: boolean
) => ({
  '@type': 'ListItem',
  position,
  name,
  ...(!isLastItem
    ? {
        item: {
          '@id': `${baseUrl}${url}`,
        },
      }
    : {}),
})

const createStep = (text: string, image?: string) => ({
  '@type': 'HowToStep',
  text,
  image,
})

const createNutritionInformation = (calories?: string) => ({
  nutrition: {
    '@type': 'NutritionInformation',
    calories,
  },
})

const createBreadcrumbList = (
  listItems: ReturnType<typeof createListItem>[],
  url: string
) => ({
  '@id': url,
  '@type': 'BreadcrumbList',
  itemListElement: listItems,
})

const createAuthor = (name = defaultAuthor, link?: Link) => {
  return {
    '@type': 'Person',
    name,
    ...(!!link?.href ? { url: link.href } : {}),
  }
}

const getPreparedAuthors = (authors?: AuthorProps[]) => {
  return authors
    ? authors
        .filter((author) => !!author.link?.href || !!author.url)
        .map((author) => ({
          ...author,
          src: author.image?.src,
          socialMediaLinks: [`${baseUrl}${author.link?.href || author.url}`],
        }))
    : []
}

const getAuthorsId = (authors: AuthorProps[]) => {
  return authors.map((author) => ({
    '@id': `${personSchemaUrl}${author.url || author.link?.href}`,
  }))
}

const getPreparedImages = (teaserImage?: TeaserImageProps['teaserImage']) => {
  const imageSet = teaserImage
    ? getTeaserImageProps({
        teaserImage,
      })
    : []

  return imageSet
    .filter((image) => !!image.url)
    .map((image) => ({
      ...image,
      caption: image.alt,
      url: image.url || '',
    }))
}

const getImagesId = (images: ArticleSchemaImage[]) => {
  return images.map((image) => ({
    '@id': image.url,
  }))
}

const createAuthorForAuthorSchema = (author: AuthorsForAuthorSchemaProps) => {
  const { name, link, description, imageSet, socialMediaLinks } = author
  return {
    ...createAuthor(name, link),
    ...(description ? { description } : {}),
    ...(socialMediaLinks ? { sameAs: socialMediaLinks } : {}),
    image: imageSet,
  }
}

const createImage = (
  url: string,
  width?: number,
  height?: number,
  caption?: string
) => ({
  '@type': 'ImageObject',
  url,
  contentUrl: url,
  ...(caption ? { caption } : {}),
  ...(width ? { width } : {}),
  ...(height ? { height } : {}),
  '@id': url,
})

const defaultLogo = createImage(
  blickLogoInfo.src,
  blickLogoInfo.width,
  blickLogoInfo.height
)

const createVideoObjectSchema = (props: any) => {
  return {
    '@type': 'VideoObject',
    ...props.properties,
  }
}

const createBreadcrumbDefaultSchema = (
  pageMetadata: PageMetadata
): BreadcrumbSchema | undefined => {
  const { breadcrumbs, htmlTitle, url, context } = pageMetadata
  const isHomePage = context === 'home'
  const fullUrl = `${breadcrumbListSchemaUrl}${url}`

  if (!isHomePage && breadcrumbs && breadcrumbs.length > 0 && htmlTitle) {
    const totalBreadcrumbs = breadcrumbs.length
    const listItems: BreadCrumbsListItem[] = breadcrumbs.map((crumb, index) =>
      createListItem(
        index + 1,
        crumb.href,
        totalBreadcrumbs === index + 1 && htmlTitle ? htmlTitle : crumb.title,
        index + 1 === breadcrumbs.length
      )
    )
    return createBreadcrumbList(listItems, fullUrl)
  }
}

const generateInitialSchema = (pageMetadata: PageMetadata): Schema => ({
  breadcrumb: createBreadcrumbDefaultSchema(pageMetadata),
  blickLogo: defaultLogo,
})

const mergeArticleSchema = (
  schema: Schema,
  updatedArticleSchemaProps: Partial<ArticleSchema>
): Schema => {
  const newSchema = { ...schema }

  newSchema.article = { ...newSchema.article, ...updatedArticleSchemaProps }

  return newSchema
}

const getImageSet = (image?: TeaserImageProps['teaserImage']) => {
  if (image) {
    const imageSet = getTeaserImageProps({
      teaserImage: image,
    })

    const filteredImageSet = imageSet.filter(
      ({ url }) => !!url
    ) as FilteredArticleSchemaImage[]

    return filteredImageSet.map((image) =>
      createImage(image.url, image.width, image.height, image.alt)
    )
  }
  return defaultLogo
}

const getParsedAuthors = (authors?: Author[]) => {
  const parsedAuthors: AuthorProps[] = []
  authors?.forEach((author) => {
    if (author.name.includes(',')) {
      const parsedNames = author.name
        .replace(/ (und|et) /, ',')
        .split(',')
        .map((author) => ({ name: author }))
      parsedAuthors.push(...parsedNames)
    } else {
      parsedAuthors.push(author)
    }
  })
  const isValid = parsedAuthors.every(
    (author) =>
      !!author.name.trim()?.[0] &&
      author.name.trim()?.[0] === author.name.trim()?.[0].toUpperCase()
  )
  return isValid ? parsedAuthors : []
}

const createItemListElement = (url: string, index: number) => ({
  '@type': 'ListItem',
  url: `${baseUrl}${url}`,
  position: index + 1,
})

const createItemList = (urls: string[]) => ({
  '@type': 'ItemList',
  itemListElement: urls.map((url, index) => createItemListElement(url, index)),
})

const createSoftwareApplicationSchema = ({
  operatingSystem,
  description,
  url,
}: SoftwareApplicationSchemaProps) => ({
  '@type': 'SoftwareApplication',
  name: 'Blick',
  description,
  url,
  operatingSystem,
  applicationCategory: 'EntertainmentApplication',
  applicationSubcategory: 'NewsApplication',
  image: {
    '@id': operatingSystem === 'ANDROID' ? androidAppLogo : iosAppLogo,
  },
  //TODO add rating
  aggregateRating: {},
  offers: {
    '@type': 'Offer',
    price: '0.00',
  },
  publisher: {
    '@id': organizationId,
  },
})

const withValidSchemaContext = (
  schema: Record<string, unknown>
): Record<string, unknown> & { '@context': 'https://schema.org' } => ({
  ...schema,
  '@context': 'https://schema.org',
})

export {
  createBreadcrumbList,
  createListItem,
  createStep,
  createAuthor,
  createAuthorForAuthorSchema,
  createImage,
  createNutritionInformation,
  createVideoObjectSchema,
  getImageProps,
  generateInitialSchema,
  createBreadcrumbDefaultSchema,
  createItemList,
  createSoftwareApplicationSchema,
  mergeArticleSchema,
  getTeaserImageProps,
  getImageSet,
  getParsedAuthors,
  blickFBPage,
  blickTWPage,
  blickInstaPage,
  defaultLogo,
  getSameAsInfo,
  getPrimaryImageSchema,
  getPreparedAuthors,
  getAuthorsId,
  getPreparedImages,
  getImagesId,
  withValidSchemaContext,
}
