import { FunctionComponent } from 'react'
import ResponsiveImageV2 from '@components/ResponsiveImageV2'
import { ImageRatios, ImageWidths } from '@components/ResponsiveImageV2/types'
import { TeaserToiAPIProps } from '@widgets/TeaserToi'
import styled, { css } from 'styled-components'
import { desktopCSS } from '@measures/responsive'

export type PopProps = Required<TeaserToiAPIProps>['pop']['image'] & {
  className?: string
}

export const RATIOS = {
  desktop: '1_1',
  tablet: '1_1',
  mobile: '1_1',
} as const satisfies ImageRatios

export const WIDTHS = {
  desktop: 142,
  tablet: 109,
  mobile: 109,
  mobileSmall: 109,
} as const satisfies ImageWidths

const PopWrapper = styled.div`
  ${({
    theme: {
      color: {
        primary: { primary02: primary02Color },
      },
    },
  }) => css`
    box-sizing: border-box;
    border: 1px solid ${primary02Color};
    width: 111px;
    height: 111px;
    ${desktopCSS(css`
      width: 144px;
      height: 144px;
    `)}
  `}
`

const Pop: FunctionComponent<PopProps> = ({ src, alt, crops, className }) => {
  return (
    <PopWrapper className={className}>
      <ResponsiveImageV2
        src={src}
        alt={alt}
        crops={crops}
        ratios={RATIOS}
        widths={WIDTHS}
      />
    </PopWrapper>
  )
}

export default Pop
