import Blink from '@components/Blink'
import useCurrentSection from '@hooks/useCurrentSection'
import { MainMenuItemLevel2 } from '@hooks/useNavigation'
import usePageMetadata from '@hooks/usePageMetadata'
import { FunctionComponent, useEffect, useState } from 'react'
import styled, { css, useTheme } from 'styled-components'
import ThirdLevelMenu from './ThirdLevelMenu'
import useTrackingUtils from '../../useTrackingUtils'
import ArrowButton from '@components/Header/FirstRow/BurgerMenu/ArrowButton'

const ItemWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing20 },
    },
  }) => css`
    height: 48px;
    padding: 14px ${spacing20} 14px ${spacing20};
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
  `}
`

interface StyledBlinkProps {
  isActive: boolean
}

const StyledItem = styled.button<StyledBlinkProps>`
  ${({
    theme: {
      color: {
        primary: { blickRed, primary01 },
      },
      typography: {
        bodycopy: {
          small2: { bundledCSS: small2BodyCopyCSS },
        },
      },
    },
    isActive,
  }) => css`
    color: ${isActive ? blickRed : primary01};
    background-color: transparent;
    border: 0;
    cursor: pointer;
    padding: 0;
    text-decoration: none;
    text-align: left;
    flex-grow: 1;
    ${small2BodyCopyCSS};
  `}
`

const StyledBlink = styled(Blink)<StyledBlinkProps>`
  ${({
    theme: {
      color: {
        primary: { blickRed, primary01 },
      },
      typography: {
        bodycopy: {
          small2: { bundledCSS: small2BodyCopyCSS },
        },
      },
    },
    isActive,
  }) => css`
    color: ${isActive ? blickRed : primary01};
    text-decoration: none;
    ${small2BodyCopyCSS};
    flex: 1 auto;
  `}
`

const SecondLevelItem: FunctionComponent<MainMenuItemLevel2> = ({
  title,
  link,
  content,
}) => {
  const { isActive } = useCurrentSection(link.title || '')
  const theme = useTheme()
  const [isSubmenuVisible, setIsSubMenuVisible] = useState(isActive)
  const pageMetadata = usePageMetadata()

  const { burgerMenuClickTrackingFn } = useTrackingUtils({
    title,
    url: link.href,
  })

  useEffect(() => {
    setIsSubMenuVisible(isActive)
  }, [isActive, pageMetadata])

  const toggleSubmenu = () => {
    setIsSubMenuVisible((prevValue) => !prevValue)
  }

  return (
    <>
      <ItemWrapper>
        {!link.href ? (
          <StyledItem
            aria-label={title}
            onClick={toggleSubmenu}
            isActive={isSubmenuVisible}>
            {title}
          </StyledItem>
        ) : (
          <StyledBlink
            {...link}
            isActive={isSubmenuVisible}
            onClick={burgerMenuClickTrackingFn}>
            {title}
          </StyledBlink>
        )}
        {content?.length && (
          <ArrowButton
            isExpanded={isSubmenuVisible}
            iconColor={theme.color.primary.primary01}
            onClick={toggleSubmenu}
          />
        )}
      </ItemWrapper>
      <ThirdLevelMenu isVisible={isSubmenuVisible}>{content}</ThirdLevelMenu>
    </>
  )
}

export default SecondLevelItem
