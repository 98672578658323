import { ConfigProps } from '@config/types'

const config = ({}: ConfigProps) =>
  ({
    autoUpdateItemCount: 50,
    fetchingStep: 10,
    defaultRefetchInterval: 30,
  }) as const satisfies Record<string, unknown>

export default config
