import config from '@config'
import usePageMetadata from '@hooks/usePageMetadata'
import Head from 'next/head'
import { FunctionComponent } from 'react'
import { MetaTags } from './types'
import { getFullHtmlTitle } from './utils'
import useURLParam from '@hooks/useURLParam'

const {
  backend: { deploymentEnv },
  search: { sectionUrl: searchSectionUrl },
  metatags: { common },
} = config

const isProd = deploymentEnv === 'prod'

const CommonMetaTags: FunctionComponent = () => {
  const { noIndex, url, metaDescription, htmlTitle, type, breadcrumbs } =
    usePageMetadata()
  const isPlusMarketingLandingPage = useURLParam('mlp') === 'true'
  const commonMetaTags: MetaTags[] = [
    ...common,
    { name: 'description', content: metaDescription },
    {
      name: 'robots',
      content:
        isPlusMarketingLandingPage ||
        (isProd && (noIndex || url?.includes(searchSectionUrl)))
          ? 'noindex,nofollow,noodp,noarchive'
          : 'index,follow,noodp,noarchive,max-image-preview:large',
    },
    { name: 'section', content: breadcrumbs?.[1]?.title },
  ]

  return (
    <Head>
      <title>{getFullHtmlTitle(htmlTitle, type)}</title>
      {commonMetaTags.map(
        ({ key, property, content, name }) =>
          content && (
            <meta
              key={key || property || name}
              {...(property ? { property } : {})}
              {...(name ? { name } : {})}
              content={content}
            />
          )
      )}
    </Head>
  )
}

export default CommonMetaTags
