import { FunctionComponent } from 'react'
import useScoreboard from '@hooks/useScoreboard'
import styled, { css } from 'styled-components'
import ScoreboardMatchResultHockey from './ScoreboardMatchResultHockey'
import ScoreboardMatchResultSoccer from './ScoreboardMatchResultSoccer'
import ScoreboardMatchStatus from './ScoreboardMatchStatus'
import ScoreboardTeam from './ScoreboardTeam'
import { LIVE_EVENT_DATA_SPORT_TYPES } from '@utils/formatters/common'
import ScoreboardGoalContentSection from './ScoreboardGoalContentSection'

const StyledScoreboardMatchResult = styled.div`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    align-items: center;
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    margin: 0 ${spacing16};
  `}
`

const StyledScoreboardWrapper = styled.div`
  width: 100%;
`

const MainContentWrapper = styled.div`
  display: flex;
`
const ScoreboardHockeyOrSoccer: FunctionComponent = () => {
  const { sportsEventType } = useScoreboard<
    | typeof LIVE_EVENT_DATA_SPORT_TYPES.SOCCER
    | typeof LIVE_EVENT_DATA_SPORT_TYPES.HOCKEY
  >()

  let ScoreboardMatchResultComponent = null

  if (sportsEventType === LIVE_EVENT_DATA_SPORT_TYPES.SOCCER) {
    ScoreboardMatchResultComponent = <ScoreboardMatchResultSoccer />
  } else if (sportsEventType === LIVE_EVENT_DATA_SPORT_TYPES.HOCKEY) {
    ScoreboardMatchResultComponent = <ScoreboardMatchResultHockey />
  }

  return (
    <StyledScoreboardWrapper>
      <MainContentWrapper>
        <ScoreboardTeam />
        <StyledScoreboardMatchResult>
          <ScoreboardMatchStatus />
          {ScoreboardMatchResultComponent}
        </StyledScoreboardMatchResult>
        <ScoreboardTeam isAwayTeam />
      </MainContentWrapper>
      <ScoreboardGoalContentSection />
    </StyledScoreboardWrapper>
  )
}

export default ScoreboardHockeyOrSoccer
