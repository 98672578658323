import { useEffect, useMemo } from 'react'
import { useInView } from 'react-intersection-observer'

export type UseViewportTracking = ({
  track,
  onImpression,
}: {
  track?: boolean
  onImpression: () => void
}) => (node?: Element | null | undefined) => void

const useViewportTracking: UseViewportTracking = ({ track, onImpression }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedOnImpression = useMemo(() => onImpression, [])

  const { ref, inView } = useInView({
    triggerOnce: true,
    skip: !track,
  })

  useEffect(() => {
    if (inView) {
      memoizedOnImpression()
    }
  }, [inView, memoizedOnImpression])

  return ref
}

export default useViewportTracking
