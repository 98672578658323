import { FunctionComponent } from 'react'
import ArticleTextboxComponents from '@components/ArticleTextbox'
import { BrandBoxProps } from '../types'
import translate from '@i18n'
import ArticleTextboxContextProvider from '../ArticleTextboxContextProvider'
import TextBoxImageWrapper from '../TextBoxImageWrapper'

const {
  ActionLink,
  ContentWrapper,
  ImageComponentWrapper,
  TextWrapper,
  Title,
  Wrapper,
  PromoNoTextHalfWidth,
} = ArticleTextboxComponents

const BrandBox: FunctionComponent<BrandBoxProps> = ({
  body,
  title,
  buttonLabel,
  showButton,
  link,
  image,
  imageAlignment,
}) => {
  const noTextAndHalfWidth = !body && imageAlignment === 'half-width'

  const ButtonAndImageComponent = (
    <PromoNoTextHalfWidth>
      {showButton ? (
        <ActionLink
          blinkProps={{ ...link, inTextBox: true, brandedBox: true }}
          verticallyCentered={true}>
          {buttonLabel ? buttonLabel : translate('submit')}
        </ActionLink>
      ) : null}
      {imageAlignment === 'half-width' && (
        <ImageComponentWrapper hideWhenHalfWidthOnMobile={false}>
          <TextBoxImageWrapper
            image={image}
            noTextHalfWidth={true}
            isPromo={true}
          />
        </ImageComponentWrapper>
      )}
      {imageAlignment === 'half-width' && (
        <ImageComponentWrapper hideWhenHalfWidthOnMobile={true}>
          <TextBoxImageWrapper
            image={image}
            noTextHalfWidth={true}
            isPromo={true}
          />
        </ImageComponentWrapper>
      )}
    </PromoNoTextHalfWidth>
  )
  return (
    <>
      <ArticleTextboxContextProvider
        hasControls={false}
        imageAlignment={imageAlignment}
        isExpanded={true}
        isPromo={true}>
        <Wrapper>
          <ContentWrapper>
            <Title>{title}</Title>
            {noTextAndHalfWidth ? (
              ButtonAndImageComponent
            ) : (
              <>
                {imageAlignment === 'full-width' && (
                  <ImageComponentWrapper>
                    <TextBoxImageWrapper isPromo={true} image={image} />
                  </ImageComponentWrapper>
                )}
                {imageAlignment === 'half-width' && (
                  <ImageComponentWrapper hideWhenHalfWidthOnMobile={false}>
                    <TextBoxImageWrapper isPromo={true} image={image} />
                  </ImageComponentWrapper>
                )}
                <TextWrapper hideOnMobile={false}>{body}</TextWrapper>
                {imageAlignment === 'half-width' && (
                  <ImageComponentWrapper hideWhenHalfWidthOnMobile={true}>
                    <TextBoxImageWrapper isPromo={true} image={image} />
                  </ImageComponentWrapper>
                )}
                <TextWrapper hideOnMobile={true}>{body}</TextWrapper>
                {showButton ? (
                  <ActionLink
                    blinkProps={{ ...link, inTextBox: true, brandedBox: true }}>
                    {buttonLabel ? buttonLabel : translate('submit')}
                  </ActionLink>
                ) : null}
              </>
            )}
          </ContentWrapper>
        </Wrapper>
      </ArticleTextboxContextProvider>
    </>
  )
}

export default BrandBox
