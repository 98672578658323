import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { Ingredient } from '@widgets/Ingredients/types'
import { printCSS } from '@utils/style'

const StyledWrapper = styled.div`
  ${({
    theme: {
      color: {
        tertiary: { grey400 },
      },
    },
  }) => {
    return css`
      display: grid;
      grid-template-columns: 30% 70%;
      align-items: center;
      width: 100%;
      min-height: 48px;
      border-bottom: 1px solid ${grey400};
      word-wrap: break-word;

      &:last-child {
        border-bottom: none;
      }

      ${printCSS(css`
        min-height: 24px;
        border-bottom: none;
      `)}
    `
  }}
`

const StyledAmount = styled.span`
  ${({
    theme: {
      typography: {
        subheads: {
          medium1: { bundledCSS: subheadingMedium1CSS },
        },
      },
    },
  }) => {
    return css`
      ${subheadingMedium1CSS}
    `
  }}
`
const StyledIngredient = styled.span`
  ${({
    theme: {
      typography: {
        bodycopy: {
          small2: { bundledCSS: bodycopySmall2CSS },
        },
      },
    },
  }) => {
    return css`
      ${bodycopySmall2CSS}
    `
  }}
`

const IngredientItem: FunctionComponent<Ingredient> = ({
  amount = '',
  ingredient,
}) => (
  <StyledWrapper>
    {<StyledAmount>{amount}</StyledAmount>}
    {ingredient && <StyledIngredient>{ingredient}</StyledIngredient>}
  </StyledWrapper>
)

export default IngredientItem
