import { AdTagManagerDaiResponse } from './types'

type DefaultDaiVastUrlInputParamsType = (arg: {
  isMobile: boolean
  containerId: string
  targeting: {
    sound: boolean
  }
}) => AdTagManagerDaiResponse

export const getDefaultDaiResponse: DefaultDaiVastUrlInputParamsType = ({
  isMobile,
  containerId,
  targeting,
}) => {
  // Placeholders:
  const __TIMESTAMP__ = new Date().getTime() // Random Number
  const __CONTAINER__ = containerId // Container ID for placement registration
  const __SOUND__ = targeting.sound ? 'on' : 'off' // Player sound preset (on/off)
  const __PLAYER__ = 'JWPlayer'
  const __ScrWIDTH__ = typeof window !== 'undefined' ? window.innerWidth : 640 // Screen width
  const __ScrHEIGHT__ = typeof window !== 'undefined' ? window.innerHeight : 480 // Screen height

  const commonParameters = {
    ad_rule: '0',
    correlator: `${__TIMESTAMP__}`,
    description_url: 'https://www.blick.ch/',
    env: 'vp',
    gdfp_req: '1',
    gdpr: '0',
    gdpr_consent: '',
    idtype: '',
    impl: 's',
    is_lat: '',
    max_ad_duration: '15000',
    min_ad_duration: '0',
    nofb: '1',
    npa: '0',
    output: 'vast',
    rdid: '',
    sz: '400x300|640x480',
    tfcd: '0',
    unviewed_position_start: '1',
    pp: 'Blick_Google_DAI',
  }

  const platformParameters = {
    cust_params: `pagetype=overview&subsectionid=1&publisher=Blick.ch_DE&format=MIDROLL_1&section=${
      isMobile ? 'MobileWeb_BlickTV' : 'Desktop_BlickTV'
    }&platform=${
      isMobile ? 'MobileWeb' : 'Desktop'
    }&vwidth=${__ScrWIDTH__}&vheight=${__ScrHEIGHT__}&uac=0&urlpath=www.blick.ch&pos=1&ptid=${__CONTAINER__}&vtype=livestream&sound=${__SOUND__}&player=${__PLAYER__}&articleid=&sherlock=&syndication=&orientation=`,
    iu: `/21823152020/Blick.ch_DE/${
      isMobile ? 'MobileWeb_BlickTV' : 'Desktop_BlickTV'
    }`,
  }

  return {
    url: 'https://pubads.g.doubleclick.net/gampad/live/ads',
    queryString: {
      ...commonParameters,
      ...platformParameters,
    },
  }
}
