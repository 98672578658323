import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled, { css } from 'styled-components'
import { getJWPlayer } from '@utils/videoPlayer'
import throttle from 'lodash.throttle'

interface VideoProgressBarProps {
  widgetId: string
  isPlayerReady: boolean
}

const ProgressBar = styled.div`
  ${({
    theme: {
      color: {
        primary: { primary02: primary02Color },
      },
    },
  }) => css`
    background-color: ${primary02Color};
    width: 100%;
    height: 2px;
  `}
`

const ActiveTrack = styled.div<{ progress: number }>`
  ${({
    theme: {
      color: {
        primary: { blickRed: blickRedColor },
      },
    },
    progress,
  }) => css`
    background-color: ${blickRedColor};
    width: ${progress}%;
    height: 2px;
  `}
`

const VideoProgressBar: FunctionComponent<VideoProgressBarProps> = ({
  widgetId,
  isPlayerReady,
}) => {
  const [videoProgress, setVideoProgress] = useState(0)

  const onProgress = useCallback(
    ({ position, duration }: jwplayer.TimeParam) => {
      setVideoProgress(Math.round((position / duration) * 100))
    },
    []
  )
  const onComplete = useCallback(() => {
    setVideoProgress(100)
  }, [])

  const onTimeHandler = useMemo(
    () => throttle(onProgress, 1000, { trailing: false }),
    [onProgress]
  )

  useEffect(() => {
    // cleanup function
    return () => {
      onTimeHandler?.cancel()
    }
  }, [onTimeHandler])

  useEffect(() => {
    const player = getJWPlayer(widgetId)

    if (!isPlayerReady || !player) {
      return
    }

    player.on('time', onTimeHandler)

    return () => {
      player.off('time', onTimeHandler)
    }
  }, [isPlayerReady, onTimeHandler, widgetId])

  useEffect(() => {
    const player = getJWPlayer(widgetId)

    if (!isPlayerReady || !player) {
      return
    }

    player.on('complete', onComplete)

    return () => {
      player.off('complete', onComplete)
    }
  }, [isPlayerReady, onComplete, widgetId])

  return (
    <ProgressBar>
      <ActiveTrack progress={videoProgress} />
    </ProgressBar>
  )
}

export default VideoProgressBar
