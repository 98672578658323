import PrimaryCTAButton from '@components/Buttons/PrimaryCTAButton'
import { mobileCSS } from '@measures/responsive'
import useCMPCookieCategories from '@hooks/useCMPCookieCategories'
import translate from '@i18n'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.div<{ isVisibilityHidden: boolean }>`
  ${({
    theme: {
      color: {
        tertiary: { grey400 },
        primary: { primary02 },
      },
      spacing: { spacing16, spacing24, spacing32 },
    },
    isVisibilityHidden,
  }) => css`
    ${isVisibilityHidden &&
    css`
      visibility: hidden;
    `};
    padding: ${spacing32} ${spacing24};
    ${mobileCSS(css`
      padding: ${spacing24} ${spacing16};
    `)}
    border: 1px solid ${grey400};
    border-radius: 8px;
    background-color: ${primary02};
  `}
`

const Title = styled.div`
  ${({
    theme: {
      color: {
        primary: { primary01 },
      },
      typography: {
        headings: {
          small: { bundledCSS: smallHeading },
        },
      },
    },
  }) => css`
    color: ${primary01};
    ${smallHeading}
  `}
`

const Description = styled.div`
  ${({
    theme: {
      color: {
        primary: { primary01 },
      },
      spacing: { spacing16 },
      typography: {
        bodycopy: {
          small2: { bundledCSS: small2BodyCopy },
        },
      },
    },
  }) => css`
    margin-top: ${spacing16};
    color: ${primary01};
    ${small2BodyCopy}
  `}
`

const PrimaryButtonWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
`

const SecondaryButtonWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    margin-top: ${spacing16};
    text-align: center;
  `}
`

// TODO: btn as link
const StyledSecondaryButton = styled.button`
  ${({
    theme: {
      typography: {
        subheads: {
          small1: { bundledCSS: smallBodyCopy },
        },
      },
      color: {
        primary: { primary01 },
      },
    },
  }) => css`
    line-height: 1.5;
    ${smallBodyCopy}
    color: ${primary01};
    background-color: transparent;
    border: 0;
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 4px;
  `}
`

const CMPPlaceholder: FunctionComponent = () => {
  const isVisibilityHidden =
    useCMPCookieCategories('allCategoriesEnabledChanged') === undefined

  return (
    <Wrapper isVisibilityHidden={isVisibilityHidden}>
      <Title>{translate('cmp.placeholder.title')}</Title>
      <Description>{translate('cmp.placeholder.description')}</Description>
      <PrimaryButtonWrapper>
        <PrimaryCTAButton
          size="large"
          onClick={() => {
            window.OneTrust?.AllowAll()
          }}>
          {translate('cmp.placeholder.primaryButton')}
        </PrimaryCTAButton>
      </PrimaryButtonWrapper>
      <SecondaryButtonWrapper>
        <StyledSecondaryButton
          onClick={() => {
            window.OneTrust?.ToggleInfoDisplay()
          }}>
          {translate('cmp.placeholder.secondaryButton')}
        </StyledSecondaryButton>
      </SecondaryButtonWrapper>
    </Wrapper>
  )
}

export { Wrapper }

export default CMPPlaceholder
