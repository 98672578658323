import styled, { css } from 'styled-components'
import { desktopCSS, mobileCSS, tabletCSS } from '@measures/responsive'
import Swiper from '@components/Swiper'
import NavigationArrows from '@components/Swiper/NavigationArrows'
import Blink from '@components/Blink'

export const SliderWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing16, spacing8 },
    },
  }) => css`
    position: relative;
    margin-top: ${spacing8};
    margin-bottom: ${spacing16};
  `}
`

export const StyledSwiper = styled(Swiper)`
  ${({
    theme: {
      spacing: { spacing16, spacing24 },
    },
  }) => css`
    width: auto;
    height: auto;
    overflow: visible;
    margin-right: -${spacing16};
    margin-left: -${spacing16};

    ${desktopCSS(css`
      margin-right: -${spacing24};
      margin-left: -${spacing24};
    `)}

    > .swiper-items-container {
      scroll-snap-type: none; // Important otherwise the gap between the items is not respected
    }

    > .swiper-items-container > *:first-child {
      margin-left: ${spacing16};
      scroll-margin-left: ${spacing16};

      ${desktopCSS(css`
        margin-left: ${spacing24};
        scroll-margin-left: ${spacing24};
      `)}
    }

    > .swiper-items-container > *:last-child {
      margin-right: ${spacing16};
      scroll-margin-right: ${spacing16};

      ${desktopCSS(css`
        margin-right: ${spacing24};
        scroll-margin-right: ${spacing24};
      `)}
    }
  `}
`
export const StyledSwiperHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
`

export const StyledSwiperHeadline = styled.div`
  ${({
    theme: {
      typography: {
        subheads: {
          medium1: { bundledCSS: medium1Subhead },
        },
      },
    },
  }) => css`
    display: flex;
    align-items: center;
    ${medium1Subhead}
  `}
`

export const StyledSwiperText = styled.div`
  ${({
    theme: {
      typography: {
        bodycopy: {
          xxsmall: { bundledCSS: xxsmallBodyCopy },
        },
      },
    },
  }) => css`
    ${xxsmallBodyCopy}
  `}
`

export const StyledNavigationArrows = styled(NavigationArrows)<{
  itemsCount: number
}>`
  ${({ itemsCount }) => css`
    display: flex;
    align-items: center;

    ${itemsCount <= 1 &&
    mobileCSS(css`
      display: none;
    `)};
    ${itemsCount <= 2 &&
    tabletCSS(css`
      display: none;
    `)};
    ${itemsCount <= 4 &&
    desktopCSS(css`
      display: none;
    `)}
  `}
`

export const SwiperSlide = styled(Blink)`
  ${({
    theme: {
      color: {
        primary: { primary01, primary02 },
        secondary: { darkRed },
        tertiary: { grey400, grey600 },
      },
      spacing: { spacing8 },
      typography: {
        bodycopy: {
          xxsmall: { bundledCSS: xxsmallBodyCopy },
        },
      },
    },
  }) => css`
    display: flex;
    gap: ${spacing8};
    flex-direction: column;
    justify-content: space-between;
    text-decoration: none;
    box-sizing: border-box;
    width: 160px;
    height: auto;
    color: ${primary01};
    background-color: ${primary02};
    border: 1px solid ${grey400};
    border-radius: 4px;
    padding: ${spacing8};

    ${xxsmallBodyCopy}

    &:hover {
      text-decoration: none;
      border-color: ${grey600};

      span:last-child {
        background-color: ${darkRed};
      }
    }
  `}
`

interface GradientProps {
  pullRight?: boolean
}

export const SliderGradient = styled.div<GradientProps>`
  ${({
    pullRight = false,
    theme: {
      color: {
        tertiary: { grey100 },
      },
      spacing: { spacing16, spacing24 },
      animation: {
        transition: { fast: fastTransition },
      },
    },
  }) => css`
    position: absolute;
    top: 0;
    right: ${pullRight ? 0 : 'auto'};
    margin-left: -${spacing16};

    ${desktopCSS(css`
      margin-left: -${spacing24};
    `)}

    height: 100%;
    width: ${spacing16};

    transition: all ${fastTransition} ease-in-out;

    opacity: 1;

    background: linear-gradient(
      ${pullRight ? '270deg' : '90deg'},
      ${grey100} 0%,
      rgba(255, 255, 255, 0) 100%
    );

    ${pullRight &&
    css`
      margin-right: -${spacing16};

      ${desktopCSS(css`
        margin-right: -${spacing24};
      `)}
    `}
  `}
`
