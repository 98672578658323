import { FunctionComponent, ReactNode, useContext } from 'react'
import styled, { css } from 'styled-components'
import contextArticleTextbox, {
  ArticleTextboxContextType,
} from '@contexts/articleTextbox'
import { desktopCSS, mobileCSS } from '@measures/responsive'
import useHasInvertedColors from '@hooks/useHasInvertedColors'

const { ArticleTextboxContext } = contextArticleTextbox

type StyledContentWrapperProps = Pick<
  ArticleTextboxContextType,
  'isExpanded' | 'isPromo'
> & { hasInvertedColors?: boolean }

const StyledContentWrapper = styled.div<StyledContentWrapperProps>`
  ${({
    theme: {
      color: {
        primary: { blickRed, primary01 },
        tertiary: { grey100 },
      },
      spacing: {
        spacing2,
        spacing4,
        spacing16,
        spacing24,
        spacing20,
        spacing32,
      },
    },
    isExpanded,
    isPromo,
    hasInvertedColors,
  }) => css`
    border-left: ${isPromo ? '0' : `${spacing2} solid ${blickRed}`};
    padding: ${isPromo ? spacing32 : 0} ${isPromo ? spacing24 : spacing20};
    overflow: hidden;
    ${desktopCSS(css`
      border-left: ${isPromo ? '0' : `${spacing4} solid ${blickRed}`};
    `)}
    ${isPromo &&
    css`
      ${mobileCSS(css`
        padding: ${isPromo ? spacing20 : 0} ${isPromo ? spacing16 : spacing20};
      `)}
      background-color: ${hasInvertedColors ? primary01 : grey100};
    `}
    ${!isExpanded &&
    css`
      height: 115px;
    `}
  `}
`

const ContentWrapper: FunctionComponent<{ children?: ReactNode }> = ({
  children,
}) => {
  const { isExpanded, isPromo } = useContext(ArticleTextboxContext)
  const hasInvertedColors = useHasInvertedColors()

  return (
    <StyledContentWrapper
      isExpanded={isExpanded}
      isPromo={isPromo}
      hasInvertedColors={hasInvertedColors}>
      {children}
    </StyledContentWrapper>
  )
}

export default ContentWrapper
