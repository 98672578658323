import debounce from 'lodash.debounce'
import { useEffect, useState } from 'react'

export type PopoverPositionType = 'top-left' | 'top-right' | 'top-center'

export interface UsePopoverPositionProps {
  itemRef: React.RefObject<HTMLElement>
  popoverRef: React.RefObject<HTMLElement>
  popoverParentRef?: React.RefObject<HTMLElement>
  maxDistance?: number
}

const usePopoverPosition = ({
  itemRef,
  popoverRef,
  popoverParentRef,
  maxDistance = 80,
}: UsePopoverPositionProps): PopoverPositionType => {
  const [popoverPosition, setPopoverPosition] =
    useState<PopoverPositionType>('top-left')

  useEffect(() => {
    const calculateTooltipPosition = () => {
      if (itemRef.current && popoverRef.current && popoverParentRef?.current) {
        const parentElement = popoverParentRef.current
        const itemRect = itemRef.current.getBoundingClientRect()
        const parentElementBounds = parentElement.getBoundingClientRect()

        const rightDistance = Math.floor(
          parentElementBounds.right - itemRect.right
        )

        const leftDistance = Math.floor(
          itemRect.left - parentElementBounds.left
        )

        if (rightDistance < maxDistance) {
          setPopoverPosition('top-left')
        } else if (leftDistance < maxDistance) {
          setPopoverPosition('top-right')
        } else if (rightDistance > maxDistance && leftDistance > maxDistance) {
          setPopoverPosition('top-center')
        }
      }
    }

    calculateTooltipPosition()
    const debouncedScrollChange = debounce(calculateTooltipPosition, 60)

    window.addEventListener('resize', debouncedScrollChange)

    return () => {
      window.removeEventListener('resize', debouncedScrollChange)
    }
  }, [maxDistance, itemRef, popoverRef, popoverParentRef])

  return popoverPosition
}

export default usePopoverPosition
