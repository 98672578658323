import { FunctionComponent, useMemo } from 'react'
import config from '@config'
import { getTeaserImageProps } from '@utils/schema'
import usePageMetadata from '@hooks/usePageMetadata'
import SchemaHTMLElement from '@components/SchemaHTMLElement'

const {
  schema: { videoSchemaUrl },
  video: { seoPlayerId },
} = config

const VideoSchema: FunctionComponent = () => {
  const pageMetadata = usePageMetadata()

  const { hasVideo, schema, thirdPartyIds } = pageMetadata

  const videoDuration = schema?.video?.duration
  const videoId = schema?.video?.videoId

  const isLiveBroadcast = videoDuration === -1

  const imageSet = useMemo(() => {
    if (!schema?.image) return []

    const teaserImageProps = getTeaserImageProps({
      teaserImage: schema.image,
    })

    return teaserImageProps.filter((image) => !!image.url)
  }, [schema?.image])

  if (!hasVideo || !schema) {
    return null
  }

  const {
    metaTitle,
    metaDescription,
    publishedDate,
    liveVideoStartTime,
    liveVideoEndTime,
  } = pageMetadata

  const thumbnailUrl = imageSet.map((thumbnail) => thumbnail.url)

  return (
    <SchemaHTMLElement
      scriptKey={`video-schema-${videoId}}`}
      schemaObject={{
        '@type': 'VideoObject',
        id: `${videoSchemaUrl}${videoId || ''}`,
        name: metaTitle,
        description: metaDescription,
        caption: metaDescription,
        uploadDate: publishedDate,
        // ISO 8601-format: PT = Period and Time (always at the beginning), S = seconds
        duration: `PT${videoDuration}S`,
        contentUrl: `https://cdn.jwplayer.com/manifests/${videoId}.m3u8`,
        embedUrl: `https://cdn.jwplayer.com/players/${thirdPartyIds?.jwVideoId}-${seoPlayerId}.html`,
        thumbnailUrl,
        width: '1200',
        height: '640',
        isFamilyFriendly: 'true',
        ...(isLiveBroadcast
          ? {
              duration: undefined, // we need to remove duration for livestream video (duration -1 is invalid)
              publication: {
                '@type': 'BroadcastEvent',
                isLiveBroadcast: true,
                startDate: liveVideoStartTime,
                endDate: liveVideoEndTime,
              },
            }
          : {}),
      }}
    />
  )
}

export default VideoSchema
