import { RefObject } from 'react'
import { RegisterSlotType } from './types'

const VIDEO_TYPES = {
  SHORTFORM: 'shortform',
  LONGFORM: 'longform',
  LIVESTREAM: 'livestream',
  DEFAULT: '',
} as const

const registerSlot: RegisterSlotType = (slotObj) => {
  window.admTagMan.q.push((admTagMan) => {
    admTagMan.registerSlot(slotObj)
  })
}

const updateADMClass: (
  admClassName: string,
  elementRef: RefObject<HTMLDivElement>
) => void = (admClassName, elementRef) => {
  elementRef.current?.classList.forEach((className) => {
    if (
      ['next-adm-error', 'next-adm-empty', 'next-adm-loaded'].includes(
        className
      )
    ) {
      elementRef.current?.classList.remove(className)
    }
  })

  if (admClassName) {
    elementRef.current?.classList.add(admClassName)
  }
}

export { registerSlot, VIDEO_TYPES, updateADMClass }
