import { desktopCSS } from '@measures/responsive'
import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'

export type ScoreboardTeamContentProps = {
  children: ReactNode
  isAwayTeam?: boolean
}

const StyledScoreboardTeamContent = styled.div<{ isAwayTeam?: boolean }>`
  ${({ isAwayTeam }) => css`
    display: flex;
    align-items: center;
    flex-direction: column;
    ${desktopCSS(css`
      flex-direction: row;
      ${isAwayTeam &&
      css`
        flex-direction: row-reverse;
      `}
    `)}
    ${isAwayTeam && `text-align: right;`}
  `}
`

const ScoreboardTeamContent: FunctionComponent<ScoreboardTeamContentProps> = ({
  children,
  isAwayTeam,
}) => {
  return (
    <StyledScoreboardTeamContent isAwayTeam={isAwayTeam}>
      {children}
    </StyledScoreboardTeamContent>
  )
}

export default ScoreboardTeamContent
