import { FunctionComponent, useContext } from 'react'
import styled, { css } from 'styled-components'
import contextArticleTextbox, {
  ArticleTextboxContextType,
} from '@contexts/articleTextbox'
import { ImageComponentWrapperProps } from '@widgets/ArticleTextbox/types'
import { desktopCSS, tabletCSS, mobileCSS } from '@measures/responsive'

type StyledImageComponentWrapperProps = Pick<
  ImageComponentWrapperProps,
  'hideWhenHalfWidthOnMobile'
> &
  Pick<ArticleTextboxContextType, 'hasControls' | 'imageAlignment'>

const { ArticleTextboxContext } = contextArticleTextbox

const StyledImageComponentWrapper = styled.div<StyledImageComponentWrapperProps>`
  ${({ hasControls, imageAlignment, hideWhenHalfWidthOnMobile }) => css`
    ${desktopCSS(css`
      ${!hasControls &&
      imageAlignment === 'half-width' &&
      !hideWhenHalfWidthOnMobile &&
      css`
        display: none;
      `}
    `)}

    ${tabletCSS(css`
      ${!hasControls &&
      imageAlignment === 'half-width' &&
      !hideWhenHalfWidthOnMobile &&
      css`
        display: none;
      `}
    `)}

    ${mobileCSS(css`
      ${!hasControls &&
      imageAlignment === 'half-width' &&
      hideWhenHalfWidthOnMobile &&
      css`
        display: none;
      `}
    `)}
  `};
`

const ImageComponentWrapper: FunctionComponent<ImageComponentWrapperProps> = ({
  hideWhenHalfWidthOnMobile,
  children,
}) => {
  const { hasControls, imageAlignment } = useContext(ArticleTextboxContext)

  return (
    <StyledImageComponentWrapper
      hasControls={hasControls}
      imageAlignment={imageAlignment}
      hideWhenHalfWidthOnMobile={hideWhenHalfWidthOnMobile}>
      {children}
    </StyledImageComponentWrapper>
  )
}

export default ImageComponentWrapper
