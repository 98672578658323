import { createContext } from 'react'
import {
  Answer,
  APICommentingAnswersData,
  QueryCommentingData,
} from '@widgets/Commenting/types'
import { UseMutateFunction } from '@tanstack/react-query'

interface AnswersAPIContextType {
  answers: Answer[]
  postedAnswer: Answer
  postAnswer: UseMutateFunction<
    Promise<Comment>,
    unknown,
    { articleId: string; body: string; fatherId: number },
    unknown
  >
  reportAnswer: (payload: { answerId: number; reason: string }) => void
  deleteAnswer: UseMutateFunction<
    Response,
    unknown,
    { answerId: number; fatherId: number },
    { previousCommentingData: QueryCommentingData | undefined }
  >
  getMoreAnswers: UseMutateFunction<
    APICommentingAnswersData,
    unknown,
    {
      fatherId: number
      articleId: string
      page: number
    },
    void
  >
  deletePostedAnswer: (payload: { answerId: number; fatherId: number }) => void
}

const AnswersAPIContext = createContext<AnswersAPIContextType>(
  {} as AnswersAPIContextType
)

export default AnswersAPIContext
