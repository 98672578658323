import { ReactNode, FunctionComponent, memo, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import useSwiper, { SwiperClass, ArrowsVisibilityType } from '@hooks/useSwiper'
import useSwiperArrows from '@hooks/useSwiper/useSwiperArrows'

export type {
  SwiperClass,
  DirectionType,
  ArrowsVisibilityType,
} from '@hooks/useSwiper'

export interface SwiperProps {
  className?: string
  slides?: any[]
  children?: ReactNode
  initialSlide?: number
  slidesPerGroup?: number
  spaceBetween?: number
  spaceTopBottom?: number
  fullWidthSlides?: boolean

  onInit?: (swiper: SwiperClass) => void
  onSlideChange?: (args: { nextIndex: number }) => void
  onArrowsVisibility?: (
    arrowsVisibility: ArrowsVisibilityType,
    currentIndex: number
  ) => void
}

const StyledItemsContainer = styled.div<{
  spaceBetween: number
  spaceTopBottom: number
  fullWidthSlides: boolean
}>`
  ${({ spaceBetween, spaceTopBottom, fullWidthSlides }) => {
    return css`
      padding: ${spaceTopBottom}px 0;
      box-sizing: border-box;
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: ${fullWidthSlides ? '100%' : 'max-content'};
      grid-gap: ${spaceBetween}px;
      width: 100%;
      height: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }

      > * {
        scroll-snap-align: start;
        scroll-snap-stop: always;
      }

      > *:last-child {
        // Fix for Firefox scroll-snap issue
        // to allow scroll to the last item even there no snap point
        scroll-snap-align: end;
      }
    `
  }}
`

const StyledItemsContainerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
`

const Swiper: FunctionComponent<SwiperProps> = ({
  className,
  slides,
  children,
  fullWidthSlides = false,
  spaceBetween = 16,
  spaceTopBottom = 10,
  initialSlide = 0,
  slidesPerGroup = 1,
  onInit,
  onSlideChange,
  onArrowsVisibility,
}) => {
  const itemsContainerRef = useRef<HTMLDivElement | null>(null)

  const { swiperRef } = useSwiper({
    initialSlide,
    slidesPerGroup,
    itemsContainerRef,
    onSlideChange,
  })

  useSwiperArrows({
    enabled: !!onArrowsVisibility,
    itemsContainerRef,
    onArrowsVisibility,
  })

  useEffect(() => {
    onInit?.(swiperRef.current)
  }, [onInit, swiperRef])

  return (
    <StyledItemsContainerWrapper className={className}>
      <StyledItemsContainer
        className="swiper-items-container"
        spaceBetween={spaceBetween}
        spaceTopBottom={spaceTopBottom}
        fullWidthSlides={fullWidthSlides}
        ref={itemsContainerRef}>
        {slides}
      </StyledItemsContainer>
      {children}
    </StyledItemsContainerWrapper>
  )
}

const MemoizedSwiper = memo(Swiper)

MemoizedSwiper.displayName = 'MemoizedSwiper'

export default MemoizedSwiper
