import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent } from 'react'
import config from '@config'
import EmbeddedContent from '@components/EmbeddedContent'

const {
  datawrapper: { staticPageUrl },
} = config

interface DataWrapperProps {
  id: string
  name: string
}

const DataWrapper: FunctionComponent<DataWrapperProps> = ({ id, name }) => (
  <EmbeddedContent
    // The title for the DataWrapper can include spaces which would have an impact
    // on the height adjustment script, so we encode the URI before
    url={encodeURI(
      `${staticPageUrl}?dataWrapperId=${id}&dataWrapperTitle=${name}`
    )}
  />
)

const widget = {
  kind: ['widget', 'tool', 'datawrapper'],
  component: DataWrapper,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
