import { default as Catchword } from './Catchword'
import { default as LiveLabel } from './LiveLabel'
import { default as TextWrapper } from './TextWrapper'
import { default as Title } from './Title'
import { default as Wrapper } from './Wrapper'

const exports = {
  Catchword,
  LiveLabel,
  TextWrapper,
  Title,
  Wrapper,
}

export default exports
