import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'

export interface TitleProps {
  children: ReactNode
}

const StyledTitle = styled.div`
  ${({
    theme: {
      color: {
        primary: { primary02: primary02Color },
      },
      typography: {
        headings: {
          xsmall: { bundledCSS: xsmallHeadingCSS },
        },
      },
      utils: { ellipsis },
    },
  }) => {
    return css`
      ${ellipsis('100%', 2)};
      ${xsmallHeadingCSS};
      color: ${primary02Color};
    `
  }}
`

const Title: FunctionComponent<TitleProps> = ({ children }) => {
  return <StyledTitle>{children}</StyledTitle>
}

export default Title
