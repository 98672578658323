import { FunctionComponent } from 'react'
import useClientViewportType from '@hooks/useClientViewportType'
import { JSONWidgetType as RelatedMatchesJSONWidgetType } from '@widgets/RelatedMatches'
import JSONRenderer from '@components/JSONRenderer'

export interface RelatedMatchesMobileAndTabletOnlyProps {
  children?: RelatedMatchesJSONWidgetType
}

const RelatedMatchesMobileAndTabletOnly: FunctionComponent<
  RelatedMatchesMobileAndTabletOnlyProps
> = ({ children }) =>
  useClientViewportType() === 'desktop' || !children ? null : (
    <JSONRenderer>{children}</JSONRenderer>
  )

export default RelatedMatchesMobileAndTabletOnly
