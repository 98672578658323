import { FunctionComponent } from 'react'
import styled from 'styled-components'
import ScoreboardMatchStatus from './ScoreboardMatchStatus'
import ScoreboardMatchResultTennis from '@components/Scoreboard/ScoreboardMatchResultTennis'

const StyledScoreboardMatchResult = styled.div`
  align-items: center;
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
`

const ScoreboardTennis: FunctionComponent = () => {
  return (
    <StyledScoreboardMatchResult>
      <ScoreboardMatchStatus />
      <ScoreboardMatchResultTennis />
    </StyledScoreboardMatchResult>
  )
}

export default ScoreboardTennis
