import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { mobileCSS } from 'measures/responsive'
import { printCSS } from '@utils/style'
import usePageMetadata from '@hooks/usePageMetadata'
import SvgIcon from '@components/SvgIcon'
import useIsInHerotellingPage from '@hooks/useIsInHerotellingPage'
import useHasInvertedColors from '@hooks/useHasInvertedColors'

interface CatchwordPros {
  catchword?: string
}

type StyledCatchwordProps = {
  isInHerotellingPage: boolean
}

const StyledCatchword = styled.span<
  StyledCatchwordProps & { hasInvertedColors: boolean }
>`
  ${({
    theme: {
      color: {
        primary: { blickRed, primary02 },
      },
      typography: {
        subheads: {
          medium1: { bundledCSS: medium1SubheadCSS },
          large1: { bundledCSS: large1SubheadCSS },
        },
      },
    },
    isInHerotellingPage,
    hasInvertedColors,
  }) => css`
    margin: 0;
    display: block;
    color: ${hasInvertedColors || isInHerotellingPage ? primary02 : blickRed};
    width: fit-content;
    ${large1SubheadCSS};
    text-align: ${isInHerotellingPage ? 'inherit' : 'left'};
    align-self: ${isInHerotellingPage ? 'center' : 'start'};
    ${mobileCSS(css`
      align-self: start;
    `)}
    ${printCSS(css`
      ${medium1SubheadCSS};
    `)}
  `}
`

const BlickPlusIcon = styled(SvgIcon)<StyledCatchwordProps>`
  ${({
    theme: {
      spacing: { spacing8 },
    },
    isInHerotellingPage,
  }) => css`
    vertical-align: top;
    margin-right: ${spacing8};
    ${isInHerotellingPage &&
    css`
      vertical-align: middle;
    `}
    height: 22px;
    width: calc((19px * 48) / 30);
  `}
`

const Catchword: FunctionComponent<CatchwordPros> = ({ catchword }) => {
  const { isPlus } = usePageMetadata()
  const isInHerotellingPage = useIsInHerotellingPage()
  const hasInvertedColors = useHasInvertedColors()

  return catchword ? (
    <StyledCatchword
      isInHerotellingPage={isInHerotellingPage}
      hasInvertedColors={hasInvertedColors}>
      {isPlus && (
        <BlickPlusIcon
          iconName="blick-plus-logo"
          size={48}
          isInHerotellingPage={isInHerotellingPage}
        />
      )}
      {`${catchword} `}
    </StyledCatchword>
  ) : isPlus ? (
    <BlickPlusIcon
      iconName="blick-plus-logo"
      size={48}
      isInHerotellingPage={isInHerotellingPage}
    />
  ) : null
}

export default Catchword
