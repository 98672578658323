import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { GraphicsObject } from '../../types'
import { desktopCSS } from '@measures/responsive'
import { splitOnLines } from '../../utils/string'

export type GraphicsElementsProps = {
  graphicsObject: GraphicsObject
}

const GraphicsWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing4 },
    },
  }) => {
    return css`
      margin-top: ${spacing4};
      width: 100%;
    `
  }}
`

const TextWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing4, spacing8, spacing12 },
    },
  }) => {
    return css`
      text-align: center;
      margin-top: ${spacing4};

      .jw-flag-fullscreen & {
        margin-top: ${spacing4};
      }

      ${desktopCSS(css`
        margin-top: ${spacing8};

        .jw-flag-fullscreen & {
          margin-top: ${spacing12};
        }
      `)}
    `
  }}
`

const GraphicsHeadline = styled.div`
  ${({
    theme: {
      typography: {
        headings: {
          xxxlarge: { bundledCSS: xxxlargeHeadingCSS },
          xlarge: { bundledCSS: xlargeHeadingCSS },
          small: { bundledCSS: smallHeadingCSS },
        },
      },
      color: {
        primary: { primary01: primary01Color, primary02: primary02Color },
      },
      spacing: { spacing2, spacing4, spacing8 },
    },
  }) => {
    const xxxxlargeHeadingCSS = css`
      ${xxxlargeHeadingCSS}

      font-size: 48px;
      font-weight: 850;
      line-height: 1.2;
    `
    const xsmallHeadingCSS = css`
      ${smallHeadingCSS}

      font-size: 20px;
      font-weight: 850;
      line-height: 1.2;
    `

    const desktopStyles = css`
      ${xlargeHeadingCSS}
      padding: ${spacing2} 6px;

      .jw-flag-fullscreen & {
        ${xxxxlargeHeadingCSS}
        padding: ${spacing4} ${spacing8};
      }
    `

    const mobileStyles = css`
      ${xsmallHeadingCSS}
      padding: ${spacing2} ${spacing4};

      .jw-flag-fullscreen & {
        ${xsmallHeadingCSS}
        padding: ${spacing2} ${spacing4};
      }
    `

    return css`
      ${mobileStyles};

      display: inline;
      box-decoration-break: clone;

      color: ${primary01Color};
      background-color: ${primary02Color};

      ${desktopCSS(desktopStyles)}
    `
  }}
`

const GraphicsName = styled.div`
  ${({
    theme: {
      typography: {
        headings: {
          xlarge: { bundledCSS: xlargeHeadingCSS },
          medium: { bundledCSS: mediumHeadingCSS },
        },
      },
      color: {
        primary: { blickRed: blickRedColor, primary02: primary02Color },
      },
      spacing: { spacing2, spacing4, spacing8 },
    },
  }) => {
    const xmediumHeadingCSS = css`
      ${mediumHeadingCSS}

      font-size: 24px;
      font-weight: 850;
      line-height: 1.2;
    `

    const xxmediumHeadingCSS = css`
      ${mediumHeadingCSS}

      font-size: 16px;
      font-weight: 850;
      line-height: 1.2;
    `

    const desktopStyles = css`
      ${xmediumHeadingCSS}
      padding: ${spacing2} 6px;

      .jw-flag-fullscreen & {
        ${xlargeHeadingCSS}
        padding: ${spacing4} ${spacing8};
      }
    `

    const mobileStyles = css`
      ${xxmediumHeadingCSS}
      padding: ${spacing2} ${spacing4};

      .jw-flag-fullscreen & {
        ${xxmediumHeadingCSS}
        padding: ${spacing2} ${spacing4};
      }
    `

    return css`
      ${mobileStyles};

      display: inline;
      box-decoration-break: clone;

      color: ${primary02Color};
      background-color: ${blickRedColor};
      text-transform: uppercase;

      ${desktopCSS(desktopStyles)}
    `
  }}
`

const GraphicsSource = styled.div`
  ${({
    theme: {
      typography: {
        bodycopy: {
          xlarge: { bundledCSS: xlargeBodycopyCSS },
          small2: { bundledCSS: small2BodycopyCSS },
          xxsmall: { bundledCSS: xxsmallBodycopyCSS },
        },
      },
      color: {
        primary: { primary01: primary01Color, primary02: primary02Color },
      },
      spacing: { spacing2, spacing4 },
    },
  }) => {
    const desktopStyles = css`
      ${small2BodycopyCSS}
      padding: ${spacing2} ${spacing4};

      .jw-flag-fullscreen & {
        ${xlargeBodycopyCSS}
        padding: ${spacing4} 6px;
      }
    `

    const mobileStyles = css`
      ${xxsmallBodycopyCSS}
      padding: ${spacing2} ${spacing4};

      .jw-flag-fullscreen & {
        ${xxsmallBodycopyCSS}
        padding: ${spacing2} ${spacing4};
      }
    `

    return css`
      ${mobileStyles};

      display: inline;
      box-decoration-break: clone;

      background-color: ${primary02Color};
      color: ${primary01Color};

      ${desktopCSS(desktopStyles)}
    `
  }}
`

const GraphicsFunction = GraphicsSource

const GraphicsElements: FunctionComponent<GraphicsElementsProps> = ({
  graphicsObject,
}) => {
  return Object.keys(graphicsObject).length ? (
    <GraphicsWrapper>
      {graphicsObject.headline ? (
        <TextWrapper>
          {splitOnLines(graphicsObject.headline).map((text, index) =>
            text ? (
              <GraphicsHeadline key={index}>{text}</GraphicsHeadline>
            ) : null
          )}
        </TextWrapper>
      ) : null}
      {graphicsObject.name ? (
        <TextWrapper>
          {splitOnLines(graphicsObject.name).map((text, index) =>
            text ? <GraphicsName key={index}>{text}</GraphicsName> : null
          )}
        </TextWrapper>
      ) : null}
      {graphicsObject.source ? (
        <TextWrapper>
          {splitOnLines(graphicsObject.source).map((text, index) =>
            text ? <GraphicsSource key={index}>{text}</GraphicsSource> : null
          )}
        </TextWrapper>
      ) : null}
      {graphicsObject.function ? (
        <TextWrapper>
          {splitOnLines(graphicsObject.function).map((text, index) =>
            text ? (
              <GraphicsFunction key={index}>{text}</GraphicsFunction>
            ) : null
          )}
        </TextWrapper>
      ) : null}
    </GraphicsWrapper>
  ) : null
}

export default GraphicsElements
