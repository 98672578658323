import { ConfigProps } from '@config/types'

const config = ({ staticAssetPageUrl, staticPageUrlSuffix }: ConfigProps) =>
  ({
    heimspielLineupStaticPageUrl: `${staticAssetPageUrl}/heimspielLineup${staticPageUrlSuffix}`,
    heimspielLivetickerStaticPageUrl: `${staticAssetPageUrl}/heimspielLiveticker${staticPageUrlSuffix}`,
    heimspielLiveCenterStaticPageUrl: `${staticAssetPageUrl}/heimspielLiveCenter${staticPageUrlSuffix}`,
    heimspielRankingStaticPageUrl: `${staticAssetPageUrl}/heimspielRanking${staticPageUrlSuffix}`,
    heimspielResultStaticPageUrl: `${staticAssetPageUrl}/heimspielResult${staticPageUrlSuffix}`,
    heimspielScheduleStaticPageUrl: `${staticAssetPageUrl}/heimspielSchedule${staticPageUrlSuffix}`,
    heimspielStartingLineupStaticPageUrl: `${staticAssetPageUrl}/heimspielStartingLineup${staticPageUrlSuffix}`,
    heimspielStartingListStaticPageUrl: `${staticAssetPageUrl}/heimspielStartingList${staticPageUrlSuffix}`,
    heimspielStatisticsStaticPageUrl: `${staticAssetPageUrl}/heimspielStatistics${staticPageUrlSuffix}`,
    heimspielStatisticsPlayerStaticPageUrl: `${staticAssetPageUrl}/heimspielStatisticsPlayer${staticPageUrlSuffix}`,
    heimspielWorldCupStaticPageUrl: `${staticAssetPageUrl}/heimspielWorldCup${staticPageUrlSuffix}`,
  }) as const satisfies Record<string, unknown>

export default config
