import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

export type UseViewportTracking = ({
  threshold,
  onInViewChange,
}: {
  threshold?: number
  track?: boolean
  delay?: number
  onInViewChange: (inView: boolean) => void
}) => (node?: Element | null | undefined) => void

const useInViewChange: UseViewportTracking = ({
  threshold,
  track = true,
  delay,
  onInViewChange,
}) => {
  const { ref, inView } = useInView({ threshold, delay, skip: !track })

  useEffect(() => {
    onInViewChange(inView)
  }, [inView, onInViewChange])

  return ref
}

export default useInViewChange
