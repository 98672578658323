import { FunctionComponent } from 'react'
import FreeRatioImage from '@components/FreeRatioImage'
import { AuthorAPIProps, BrandLogoProps } from '../types'
import {
  CONSTRAINTS_FOR_BRAND_LOGOS,
  CONSTRAINTS_FOR_SCHWEIZERILLUSTRIERTE,
} from '@widgets/Author/utils'
import styled, { css } from 'styled-components'

const StyledWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing4 },
    },
  }) => css`
    margin-top: ${spacing4};
  `}
`

const BrandLogo: FunctionComponent<
  BrandLogoProps & Pick<AuthorAPIProps, 'contentOrigin'>
> = ({ contentOrigin, ...logo }) => {
  const { src, alt, height, width, crops } = logo
  const constraints =
    contentOrigin === 'schweizerillustrierte'
      ? CONSTRAINTS_FOR_SCHWEIZERILLUSTRIERTE
      : CONSTRAINTS_FOR_BRAND_LOGOS
  return (
    <StyledWrapper>
      <FreeRatioImage
        src={src}
        alt={alt ?? `${contentOrigin}-logo`}
        originalDimensions={{ width, height }}
        constraints={constraints}
        crops={crops ?? {}}
      />
    </StyledWrapper>
  )
}

export default BrandLogo
