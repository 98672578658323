import { PlaylistItem } from './types'

const PLAYLIST_DESKTOP_ITEM_HEIGHT = 134 + 16

interface VideoInPlaylistProps {
  items: PlaylistItem[]
  currentId: string
  direction?: 'next' | 'previous'
  startFromTheFirst?: boolean
}

const getVideoInPlaylist = ({
  items,
  currentId,
  direction = 'next',
  startFromTheFirst = false,
}: VideoInPlaylistProps): PlaylistItem | null => {
  const currentIndex = items?.findIndex(
    (item) => item?.link?.targetArticleId === currentId
  )

  const searchIndex = direction === 'next' ? currentIndex + 1 : currentIndex - 1

  // if the video isn't in the playlist, just play the first one
  if (currentIndex === -1 && direction === 'next') {
    return items[0]
  }

  // the last item in the playlist
  if (searchIndex === items?.length) {
    return startFromTheFirst ? items[0] : null
  }

  return items?.[searchIndex] || null
}

const hasVideoInPlaylist = ({
  items,
  currentId,
  direction,
  startFromTheFirst = false,
}: Partial<VideoInPlaylistProps>): boolean => {
  const playlistItem =
    items && currentId
      ? getVideoInPlaylist({ items, currentId, direction, startFromTheFirst })
      : null

  return !!playlistItem?.link?.href
}

export { PLAYLIST_DESKTOP_ITEM_HEIGHT, getVideoInPlaylist, hasVideoInPlaylist }
