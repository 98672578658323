import { useQueryClient } from '@tanstack/react-query'

type UseDisableThirdPartyScriptsType = () => boolean

const useDisableThirdPartyScripts: UseDisableThirdPartyScriptsType = () => {
  const queryClient = useQueryClient()

  return !!queryClient.getQueryData<boolean>(['disableThirdPartyScripts'])
}

export default useDisableThirdPartyScripts
