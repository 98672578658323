import useLeserreporterContext from '@hooks/useLeserreporterContext'
import { mobileCSS } from '@measures/responsive'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import ErrorMessage from './ErrorMessage'

const StyledDescriptionTextarea = styled.div`
  ${({
    theme: {
      spacing: { spacing20 },
    },
  }) => css`
    margin-top: ${spacing20};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  `}
`

const StyledTextarea = styled.textarea<{ isError: boolean }>`
  ${({
    isError,
    theme: {
      typography: {
        bodycopy: {
          small2: { bundledCSS: small2BodyCopyCSS },
        },
      },
      color: {
        primary: { blickRed: blickRedColor },
        tertiary: { grey400: grey400color },
      },
      spacing: { spacing96, spacing4, spacing16 },
    },
  }) => css`
    border: 1px solid ${isError ? blickRedColor : grey400color};
    height: ${spacing96};
    border-radius: ${spacing4};
    padding: ${spacing16};
    resize: none;
    ${mobileCSS(css`
      height: 97px;
    `)}

    &:focus-visible {
      outline-color: ${blickRedColor};
    }
    ${small2BodyCopyCSS};
  `}
`

const TextareaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledLabel = styled.label`
  ${({
    theme: {
      typography: {
        subheads: {
          medium1: { bundledCSS: subheadsMedium1 },
        },
      },
      spacing: { spacing8 },
    },
  }) => css`
    ${subheadsMedium1};
    margin-bottom: ${spacing8};
  `}
`

const CharactersIndicator = styled.span`
  ${({
    theme: {
      color: {
        tertiary: { grey600: grey600color },
      },
      typography: {
        subheads: {
          xsmall2: { bundledCSS: xsmall2SubheadsCSS },
        },
      },
      spacing: { spacing4, spacing8 },
    },
  }) => css`
    color: ${grey600color};
    ${xsmall2SubheadsCSS};
    margin-top: ${spacing4};
    margin-bottom: ${spacing8};
  `}
`

const DescriptionTextarea: FunctionComponent = () => {
  const {
    data: { description, category },
    validation: { description: validation },
    validate,
    handleChange,
  } = useLeserreporterContext()
  const passedValidation = validation === true
  const labelContent =
    category === 'tip'
      ? 'Erzähle deine Geschichte/Thema'
      : 'Beschreibe dein Bild/Video'
  return (
    <StyledDescriptionTextarea>
      <StyledLabel>{labelContent}</StyledLabel>
      <TextareaWrapper>
        <StyledTextarea
          value={description}
          onChange={handleChange}
          name="description"
          onBlur={validate}
          isError={!passedValidation}
        />
        {passedValidation ? (
          <CharactersIndicator>(min. 10 Zeichen)</CharactersIndicator>
        ) : (
          <ErrorMessage>{validation as string}</ErrorMessage>
        )}
      </TextareaWrapper>
    </StyledDescriptionTextarea>
  )
}

export default DescriptionTextarea
