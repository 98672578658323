import { Link } from '@utils/cook/types'
import { useQueryClient } from '@tanstack/react-query'

export type NavigationSkeleton = [NavigationSkeletonItem]

export interface MainMenuItemLevel3 {
  kind: ['navigation', 'main-menu-item', 'level3']
  title: string
  titleCh: string
  primaryColor: string
  link: Link
}

export interface MainMenuItemLevel2 {
  kind: ['navigation', 'main-menu-item', 'level2']
  title: string
  titleCh: string
  primaryColor: string
  link: Link
  content?: MainMenuItemLevel3[]
}

export interface MainMenuItem {
  kind: ['navigation', 'main-menu-item']
  title: string
  titleCh: string
  primaryColor: string
  link: Link
  sectionElement: {
    name: string
    uniqueName: string
  }
  content?: MainMenuItemLevel2[]
}

export interface NavigationSkeletonItem {
  kind: ['document', 'navigation']
  content: [{ kind: ['navigation', 'main-menu']; content: MainMenuItem[] }]
}

export type UseNavigation = () => MainMenuItem[]

const useNavigation: UseNavigation = () => {
  const queryClient = useQueryClient()

  const navigationSkeleton = queryClient.getQueryData<NavigationSkeleton>([
    'navigationSkeleton',
  ]) as NavigationSkeleton

  return navigationSkeleton[0].content[0].content
}

export default useNavigation
