import { FunctionComponent } from 'react'
import styled, { useTheme } from 'styled-components'
import SvgIcon from '@components/SvgIcon'

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  line-height: 0;
  text-align: center;
  overflow: hidden;
`

const AnswerIndicator: FunctionComponent = () => {
  const theme = useTheme()
  return (
    <Wrapper>
      <SvgIcon
        iconName="comment-arrow"
        size={18}
        color={theme.color.primary.blickRed}
      />
    </Wrapper>
  )
}

export default AnswerIndicator
