import { FunctionComponent, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import translate from '@i18n'
import { JWPlayer, OverlayEventParam } from '../../types'
import svgSprite from '@assets/images/video-player-sprite.svg'
import { mobileCSS } from '@measures/responsive'
import { isUserActive } from '../../utils/player'

export type UnmuteCTAButtonProps = {
  player: JWPlayer
  onClick?: () => void
  onImpression?: () => void
}

const ButtonWrapper = styled.div<{
  showAnimation: boolean
}>`
  position: absolute;

  left: 16px;
  bottom: 16px;

  width: 100px;
  height: 26px;
  padding: 10px;

  opacity: 0;
  background: #f9fbff;
  border: 1px solid #f9fbff;
  box-shadow:
    0px 1px 2px rgba(0, 0, 0, 0.3),
    0px 2px 4px 1px rgba(0, 0, 0, 0.15);
  border-radius: 32px;

  cursor: pointer;

  ${mobileCSS(css`
    left: 8px;
    bottom: 8px;

    width: 90px;
    height: 20px;

    padding: 5px;
    border-radius: 24px;
  `)}

  ${({ showAnimation }) => {
    return (
      showAnimation &&
      css`
        opacity: 1;
        width: 26px;
        background-color: transparent;
        box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.15);
        filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3));
        transition:
          opacity 400ms ease-out 200ms,
          width 400ms ease-in 3s,
          background-color 400ms ease-in 5s,
          filter 400ms ease-in 5s;

        ${mobileCSS(css`
          width: 19px;
        `)}
      `
    )
  }}
`

const ButtonContent = styled.div`
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
`

const ButtonIcon = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 26px;
  height: 26px;

  ${mobileCSS(css`
    width: 19px;
    height: 18px;
  `)}
`

const VolumeMutedOutlinedIcon = styled.div<{
  showAnimation: boolean
}>`
  position: absolute;
  width: 100%;
  height: 100%;

  opacity: 0;
  background: transparent url(${svgSprite}#volume-slash) no-repeat center/cover;

  ${({ showAnimation }) => {
    return (
      showAnimation &&
      css`
        opacity: 1;
        transition: opacity 400ms ease-in 5s;
      `
    )
  }}
`

const VolumeMutedFilledIcon = styled.div<{
  showAnimation: boolean
}>`
  position: absolute;
  width: 100%;
  height: 100%;

  opacity: 1;
  background: transparent url(${svgSprite}#volume-slash-filled) no-repeat
    center/cover;

  ${({ showAnimation }) => {
    return (
      showAnimation &&
      css`
        opacity: 0;
        transition: opacity 400ms ease-in 5s;
      `
    )
  }}
`

const ButtonText = styled.div`
  ${({
    theme: {
      typography: {
        subheads: {
          small1: { bundledCSS: small1SubheadingCSS },
        },
      },
    },
  }) => css`
    ${small1SubheadingCSS}
    display: inline-block;
    vertical-align: middle;

    width: 74px;

    text-align: center;

    ${mobileCSS(css`
      width: 70px;
    `)}
  `}
`

const UnmuteCTAButton: FunctionComponent<UnmuteCTAButtonProps> = ({
  player,
  onClick,
  onImpression,
}) => {
  const [buttonDismissed, setButtonDismissed] = useState<boolean>(false)
  const [buttonShowed, setButtonShowed] = useState<boolean>(false)

  useEffect(() => {
    if (buttonShowed) {
      onImpression?.()
    }
  }, [onImpression, buttonShowed])

  useEffect(() => {
    const showUnmuteCTAButton = () => {
      if (player.getMute()) {
        setButtonShowed(true)
      }
    }

    const dismissUnmuteCTAButton = () => {
      setButtonDismissed(true)
    }

    const onMute = (muteValue: jwplayer.MuteParam) => {
      if (!muteValue.mute) {
        dismissUnmuteCTAButton()
      }
    }

    const onOverlay = (param: OverlayEventParam) => {
      if (param.overlay) {
        dismissUnmuteCTAButton()
      }
    }

    const onFullscreen = (param: jwplayer.FullscreenParam) => {
      if (param.fullscreen) {
        dismissUnmuteCTAButton()
      }
    }

    function onFloat({ floating }: { floating: boolean }) {
      if (floating) {
        dismissUnmuteCTAButton()
      }
    }

    function onPip({ pip }: { pip: boolean }) {
      if (pip) {
        dismissUnmuteCTAButton()
      }
    }

    const onFirstFrame = () => {
      if (isUserActive(player)) {
        player.on('userInactive', showUnmuteCTAButton)
      } else {
        showUnmuteCTAButton()
      }

      player.on('userActive', dismissUnmuteCTAButton)
      player.on('adImpression', dismissUnmuteCTAButton)
      player.on('complete', dismissUnmuteCTAButton)
      player.on('error', dismissUnmuteCTAButton)
    }

    function addEventListeners() {
      player.on('firstFrame', onFirstFrame)
      player.on('mute', onMute)
      player.on('overlay', onOverlay)
      player.on('fullscreen', onFullscreen)
      player.on('float', onFloat)
      player.on('pip', onPip)
    }

    function removeEventListeners() {
      player.off('firstFrame', onFirstFrame)

      player.off('userInactive', showUnmuteCTAButton)
      player.off('userActive', dismissUnmuteCTAButton)
      player.off('adImpression', dismissUnmuteCTAButton)
      player.off('complete', dismissUnmuteCTAButton)
      player.off('error', dismissUnmuteCTAButton)

      player.off('mute', onMute)
      player.off('overlay', onOverlay)
      player.off('fullscreen', onFullscreen)
      player.off('float', onFloat)
      player.off('pip', onPip)
    }

    if (buttonDismissed) {
      removeEventListeners()
    } else {
      addEventListeners()
    }

    return () => {
      removeEventListeners()
    }
  }, [player, buttonDismissed])

  return buttonDismissed ? null : (
    <ButtonWrapper showAnimation={buttonShowed} onClick={onClick}>
      <ButtonContent>
        <ButtonIcon>
          <VolumeMutedOutlinedIcon showAnimation={buttonShowed} />
          <VolumeMutedFilledIcon showAnimation={buttonShowed} />
        </ButtonIcon>
        <ButtonText>{translate('video.unmuteCTAButton')}</ButtonText>
      </ButtonContent>
    </ButtonWrapper>
  )
}

export default UnmuteCTAButton
