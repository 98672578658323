import { FunctionComponent } from 'react'
import ScoreboardMatchScore from './ScoreboardMatchScore'
import ScoreboardMatchAdditionalScore from './ScoreboardMatchAdditionalScore'
import ScoreboardAggregate from '@components/Scoreboard/ScoreboardAggregate'
import useScoreboard from '@hooks/useScoreboard'
import { LIVE_EVENT_DATA_SPORT_TYPES } from '@utils/formatters/common'
import ScoreboardOpponentSeparator from './ScoreboardOpponentSeparator'

const ScoreboardMatchResultSoccer: FunctionComponent = () => {
  const { formattedData } =
    useScoreboard<typeof LIVE_EVENT_DATA_SPORT_TYPES.SOCCER>()

  const {
    aggregateIndicator,
    scoreExplanation,
    isScheduled,
    hasPenalties,
    teams,
  } = formattedData

  const scoreKey = hasPenalties ? 'scorePenalties' : 'scoreBeforePenalties'
  const awayScore = teams[1][scoreKey]
  const homeScore = teams[0][scoreKey]

  return (
    <>
      {!isScheduled && (
        <>
          <ScoreboardMatchScore
            awayScore={awayScore}
            homeScore={homeScore}
            explanation={scoreExplanation}
          />
          {hasPenalties && <ScoreboardMatchAdditionalScore />}
        </>
      )}
      {!!aggregateIndicator && <ScoreboardAggregate />}
      {isScheduled && <ScoreboardOpponentSeparator />}
    </>
  )
}

export default ScoreboardMatchResultSoccer
