import { createContext } from 'react'

export interface ArticleTextboxContextType {
  hasControls: boolean
  imageAlignment: 'full-width' | 'half-width'
  isExpanded: boolean
  isPromo: boolean
}

const ArticleTextboxContext = createContext<ArticleTextboxContextType>({
  hasControls: true,
  imageAlignment: 'full-width',
  isExpanded: false,
  isPromo: false,
})

const exports = {
  ArticleTextboxContext,
}

export default exports
