import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent } from 'react'
import TikTokComponent from '@components/SocialEmbed/TikTok'

interface TikTokProps {
  url: string
}

const TikTok: FunctionComponent<TikTokProps> = ({ url }) => {
  return <TikTokComponent url={url} />
}

const widget = {
  kind: ['widget', 'social-embed', 'tiktok'],
  component: TikTok,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
