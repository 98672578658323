import useBlickPlusSkeleton from '@hooks/useBlickPlusSkeleton'
import translate from '@i18n'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

interface TitleProps {
  availableShares?: number
  updatedAvailableShares?: number
}

const StyledTitle = styled.div`
  ${({
    theme: {
      typography: {
        headings: {
          xxsmall: { bundledCSS: xxsmallHeadingsCSS },
        },
      },
      color: {
        primary: { primary01 },
      },
    },
  }) => css`
    ${xxsmallHeadingsCSS};
    color: ${primary01};
    align-self: flex-start;
    max-width: calc(100% - 34px);
  `}
`

const Title: FunctionComponent<TitleProps> = ({
  availableShares,
  updatedAvailableShares,
}) => {
  const { plusSharingLinkGenerationAmount } = useBlickPlusSkeleton()
  return (
    <StyledTitle>
      {typeof availableShares === 'number' && (
        <span>
          {updatedAvailableShares ?? availableShares}/
          {plusSharingLinkGenerationAmount}&nbsp;
        </span>
      )}
      {translate('blickPlus.linkSharing.title')}
    </StyledTitle>
  )
}

export default Title
