import { forwardRef, useMemo } from 'react'
import styled, { useTheme } from 'styled-components'
import Blink, { BlinkProps } from '@components/Blink'
import {
  getColorVariantsMapForSecondaryCTAButton,
  SecondaryCTAButtonProps,
  secondaryCTAButtonStyles,
  StyledSecondaryCTAButtonType,
} from './SecondaryCTAButton'
import {
  CommonCTAProps,
  commonCTAStyles,
  TextWithIconWrapper,
} from './CommonCTA'

export type SecondaryCTABlinkProps = CommonCTAProps &
  BlinkProps & {
    colorVariant?: SecondaryCTAButtonProps['colorVariant']
  }

const StyledSecondaryCTABlink = styled(Blink)<StyledSecondaryCTAButtonType>`
  ${commonCTAStyles}
  ${secondaryCTAButtonStyles}
`

const SecondaryCTABlink = forwardRef<HTMLAnchorElement, SecondaryCTABlinkProps>(
  (
    {
      colorVariant = 'red',
      size = 'large',
      disabled = false,
      iconName,
      iconLeft = false,
      className,
      children,
      ...BlinkProps
    },
    ref
  ) => {
    const theme = useTheme()

    const buttonStateColors = useMemo(
      () => getColorVariantsMapForSecondaryCTAButton(theme.color)[colorVariant],
      [theme.color, colorVariant]
    )

    return (
      <StyledSecondaryCTABlink
        ref={ref}
        className={className}
        size={size}
        disabled={disabled}
        buttonStateColors={buttonStateColors}
        iconLeft={iconLeft}
        {...(BlinkProps satisfies BlinkProps)}>
        {iconName ? (
          <TextWithIconWrapper iconName={iconName} size={size}>
            {children}
          </TextWithIconWrapper>
        ) : (
          children
        )}
      </StyledSecondaryCTABlink>
    )
  }
)

SecondaryCTABlink.displayName = 'SecondaryCTABlink'

export default SecondaryCTABlink
