import { FunctionComponent, ReactNode } from 'react'
import ResponsiveImageV2 from '@components/ResponsiveImageV2'
import { ImageRatios, ImageWidths } from '@components/ResponsiveImageV2/types'
import { HeroModuleAPIProps } from '@widgets/HeroModule'

export type ImageProps = Pick<HeroModuleAPIProps, 'image' | 'isImmersive'> & {
  children?: ReactNode
  className?: string
}

export const RATIOS_IMMERSIVE = {
  desktop: '3_2',
  tablet: '5_8',
  mobile: '5_8',
} as const satisfies ImageRatios

export const RATIOS_NO_IMMERSIVE = {
  desktop: '3_2',
  tablet: '3_4',
  mobile: '3_4',
} as const satisfies ImageRatios

export const WIDTHS = {
  desktop: 994,
  tablet: 632,
  mobile: 430,
  mobileSmall: 320,
} as const satisfies ImageWidths

const Image: FunctionComponent<ImageProps> = ({
  image,
  isImmersive,
  children,
  className,
}) => {
  const { src, alt, crops } = image

  return (
    <ResponsiveImageV2
      src={src}
      alt={alt}
      crops={crops}
      ratios={isImmersive ? RATIOS_IMMERSIVE : RATIOS_NO_IMMERSIVE}
      widths={WIDTHS}
      className={className}
      loading="eager">
      {children}
    </ResponsiveImageV2>
  )
}

export default Image
