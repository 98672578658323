import { FunctionComponent } from 'react'
import PaginationItem from '@components/Pagination/PaginationItem'
import {
  ButtonPaginationProps,
  LinkPaginationProps,
  PaginationProps,
} from '@components/Pagination/index'
import styled from 'styled-components'

type PaginationLinksContainerProps = PaginationProps & {
  pageNumbers: (number | '...')[]
  className?: string
}

const Container = styled.div`
  display: flex;
`

const PaginationLinksContainer: FunctionComponent<
  PaginationLinksContainerProps
> = ({ type, activePage, pageNumbers, className, ...restProps }) => {
  return (
    <Container className={className}>
      {pageNumbers.map((numberOrEllipsis, index) => {
        if (numberOrEllipsis === '...') {
          return (
            <PaginationItem key={`ellipsis-${index}`} type="inactive">
              {numberOrEllipsis}
            </PaginationItem>
          )
        } else if (type === 'button') {
          const isSelected = numberOrEllipsis === activePage
          const { setPage } = restProps as ButtonPaginationProps
          if (isSelected) {
            return (
              <PaginationItem
                key={numberOrEllipsis}
                type="inactive"
                isSelected={true}>
                {numberOrEllipsis}
              </PaginationItem>
            )
          } else {
            return (
              <PaginationItem
                key={numberOrEllipsis}
                type="button"
                onClick={() => setPage?.(numberOrEllipsis)}>
                {numberOrEllipsis}
              </PaginationItem>
            )
          }
        } else {
          const isSelected = numberOrEllipsis === activePage
          const { firstPageHref, paginationHref } =
            restProps as LinkPaginationProps
          if (isSelected) {
            return (
              <PaginationItem
                key={numberOrEllipsis}
                type="inactive"
                isSelected={true}>
                {numberOrEllipsis}
              </PaginationItem>
            )
          } else {
            return (
              <PaginationItem
                key={numberOrEllipsis}
                type="link"
                href={`${
                  //! page 1 doesn't have a url segment
                  numberOrEllipsis === 1
                    ? `${firstPageHref}`
                    : `${paginationHref}${numberOrEllipsis}/`
                }`}>
                {numberOrEllipsis}
              </PaginationItem>
            )
          }
        }
      })}
    </Container>
  )
}

export default PaginationLinksContainer
