import translate from '@i18n'
import { isMobileUserAgent } from '@measures/responsive'

import { JWPlayer, BlickTVInputProps } from '../types'

import { getPlayerHTMLElement, setOverlay } from './player'
import { setElementClass } from './element'

import svgSprite from '@assets/images/video-player-sprite.svg'

const isMobile = isMobileUserAgent()

export const showPlaylistVideosNavigationButtons = (jwplayer: JWPlayer) => {
  const playerContainer = jwplayer.getContainer()
  setElementClass(playerContainer, 'show-playlist-navigation-buttons', true)
}

export const addPlaylistPreviousButtonDisabled = (jwplayer: JWPlayer) => {
  const playerContainer = jwplayer.getContainer()
  setElementClass(
    playerContainer,
    'show-playlist-navigation-buttons-disabled',
    true
  )
}

export function removePlaylistPreviousButtonDisable(jwplayer: JWPlayer) {
  const playerContainer = jwplayer.getContainer()
  setElementClass(
    playerContainer,
    'show-playlist-navigation-buttons-disabled',
    false
  )
}

export const addCaptionsUnavailableButton = (jwplayer: JWPlayer) => {
  jwplayer.addButton(
    `${svgSprite}#subtitles-slash`,
    translate('video.captionsDisabled'),
    () => {},
    'jw-icon-captions-disabled',
    'jw-icon-captions jw-icon-captions-disabled'
  )
}

export const removeCaptionsUnavailableButton = (jwplayer: JWPlayer) => {
  jwplayer.removeButton('jw-icon-captions-disabled')
}

export const addActivateCaptionsButton = (jwplayer: JWPlayer) => {
  jwplayer.addButton(
    `${svgSprite}#subtitles-slash`,
    translate('video.captionsOn'),
    () => {
      jwplayer.setCurrentCaptions(1)
    },
    'jw-icon-captions-on',
    'jw-icon-captions'
  )

  jwplayer.removeButton('jw-icon-captions-off')
}

export const addDeactivateCaptionsButton = (jwplayer: JWPlayer) => {
  jwplayer.addButton(
    `${svgSprite}#subtitles`,
    translate('video.captionsOff'),
    () => {
      jwplayer.setCurrentCaptions(0)
    },
    'jw-icon-captions-off',
    'jw-icon-captions'
  )

  jwplayer.removeButton('jw-icon-captions-on')
}

export const toggleCaptionsButtons = (
  player: JWPlayer,
  {
    areCaptionsEnabled,
  }: {
    areCaptionsEnabled: boolean
  }
) => {
  if (areCaptionsEnabled) {
    // captions are enabled -> show `Off` button to disable them
    addDeactivateCaptionsButton(player)
  } else {
    // captions are disabled -> show `On` button to enable them
    addActivateCaptionsButton(player)
  }
}

const FULLSCREEN_ENTER_BUTTON_ID = 'btv-icon-fullscreen-overlay-on'
const FULLSCREEN_EXIT_BUTTON_ID = 'btv-icon-fullscreen-overlay-off'

export const addFullScreenEnterButton = (jwplayer: JWPlayer) => {
  jwplayer.addButton(
    `${svgSprite}#expand`,
    translate('video.fullscreenOn'),
    () => {
      setOverlay(jwplayer, true)
    },
    FULLSCREEN_ENTER_BUTTON_ID,
    'btv-icon-fullscreen-overlay'
  )

  jwplayer.removeButton(FULLSCREEN_EXIT_BUTTON_ID)
}

export const addFullScreenExitButton = (jwplayer: JWPlayer) => {
  jwplayer.addButton(
    `${svgSprite}#compress`,
    translate('video.fullscreenOff'),
    () => {
      setOverlay(jwplayer, false)
    },
    FULLSCREEN_EXIT_BUTTON_ID,
    'btv-icon-fullscreen-overlay'
  )

  jwplayer.removeButton(FULLSCREEN_ENTER_BUTTON_ID)
}

export function removeRecommendationsUnavailableButton(jwplayer: JWPlayer) {
  jwplayer.removeButton('btv-icon-more-videos-unavailable')
}

export function removeActivateRecommendationsButton(jwplayer: JWPlayer) {
  jwplayer.removeButton('btv-icon-more-videos-on')
  const container = jwplayer.getContainer()
  container.classList.remove('btv-flag-recommendations-overlay-disabled')
}

export function removeDeactivateRecommendationsButton(jwplayer: JWPlayer) {
  jwplayer.removeButton('btv-icon-more-videos-off')
  const container = jwplayer.getContainer()
  container.classList.remove('btv-flag-recommendations-overlay-enabled')
}

export function addActivateRecommendationsButton(
  jwplayer: JWPlayer,
  onRecommendationsOverlay: BlickTVInputProps['onRecommendationsOverlay']
) {
  jwplayer.addButton(
    `${svgSprite}#more-videos-on`,
    translate('video.moreVideosOn'),
    () => {
      addDeactivateRecommendationsButton(jwplayer, onRecommendationsOverlay)
      onRecommendationsOverlay?.({
        showRecommendations: true,
        reason: 'clicked',
      })
    },
    'btv-icon-more-videos-on',
    'btv-icon-more-videos'
  )
  removeDeactivateRecommendationsButton(jwplayer)
  removeRecommendationsUnavailableButton(jwplayer)
  const container = jwplayer.getContainer()
  setElementClass(container, 'btv-flag-recommendations-overlay-disabled', true)

  if (isMobile) {
    jwplayer.setControls(true)
  }
}

export function addRecommendationsUnavailableButton(jwplayer: JWPlayer) {
  jwplayer.addButton(
    `${svgSprite}#more-videos-off`,
    translate('video.moreVideosUnavailable'),
    () => {},
    'btv-icon-more-videos-unavailable',
    'btv-icon-more-videos btv-icon-more-videos-unavailable'
  )
  removeActivateRecommendationsButton(jwplayer)
  removeDeactivateRecommendationsButton(jwplayer)
}

export function addDeactivateRecommendationsButton(
  jwplayer: JWPlayer,
  onRecommendationsOverlay: BlickTVInputProps['onRecommendationsOverlay']
) {
  jwplayer.addButton(
    `${svgSprite}#more-videos-off`,
    translate('video.moreVideosOff'),
    () => {
      addActivateRecommendationsButton(jwplayer, onRecommendationsOverlay)
      onRecommendationsOverlay?.({
        showRecommendations: false,
      })
    },
    'btv-icon-more-videos-off',
    'btv-icon-more-videos'
  )
  removeActivateRecommendationsButton(jwplayer)
  removeRecommendationsUnavailableButton(jwplayer)

  const container = jwplayer.getContainer()
  setElementClass(container, 'btv-flag-recommendations-overlay-enabled', true)

  if (isMobile) {
    jwplayer.setControls(false)
  }
}

export const removeReplayButton = (jwplayer: JWPlayer) => {
  const playButton = getPlayerHTMLElement(jwplayer, '.jw-icon-playback', false)
  jwplayer.removeButton('replay-button')
  if (playButton) playButton.style.display = ''
}

export const addReplayButton = (
  jwplayer: JWPlayer,
  onRecommendationsOverlay: BlickTVInputProps['onRecommendationsOverlay'],
  isVideoRecommendationsEnabled?: BlickTVInputProps['isVideoRecommendationsEnabled']
) => {
  const playButton = getPlayerHTMLElement(jwplayer, '.jw-icon-playback', false)
  jwplayer.addButton(
    `${svgSprite}#arrow-rotate-left`,
    translate('video.replay'),
    () => {
      removeReplayButton(jwplayer)
      jwplayer.play()
      removeDeactivateRecommendationsButton(jwplayer)
      if (isVideoRecommendationsEnabled?.()) {
        addActivateRecommendationsButton(jwplayer, onRecommendationsOverlay)
        onRecommendationsOverlay?.({
          showRecommendations: false,
        })
      }
    },
    'replay-button',
    'replay-button'
  )
  if (playButton) playButton.style.display = 'none'
}
