import config from '@config'
import { BlickPlusSkeleton } from '@hooks/useBlickPlusSkeleton'
import { FooterSkeleton } from '@hooks/useFooter'
import { NavigationSkeleton } from '@hooks/useNavigation'
import { PageMetadata } from '@hooks/usePageMetadata'
import { VideoSkeleton } from '@hooks/useVideoSkeleton'
import { TargetContentType } from './types'

export type PageType = 'section' | 'article' | 'dossier'

export type RSSPathType = 'rss' | 'mod_rss' | 'msn_rss' | 'bplus_feed'

export interface ValidSkeletonData {
  kind: ['skeleton', 'api', 'web']
  skeletonData: [
    {
      adSDK: {
        version: string
        environment: 'staging' | 'prod'
      }
      navigation: NavigationSkeleton
      footer: FooterSkeleton
      video: VideoSkeleton
      disableThirdPartyScripts: boolean
      blickPlus: BlickPlusSkeleton
    },
  ]
}

const RSS_PATHS: RSSPathType[] = ['rss', 'mod_rss', 'msn_rss', 'bplus_feed']

const {
  publication: { publication },
  backend: { skeletonUrl, versionedApiUrl, useLocalhostApiUrl },
  subscriptions: { offerPagePath },
} = config

const fetchSkeleton = async () => {
  const result = await fetch(skeletonUrl)
  return await result.json()
}

const fetchPage = async ({
  pageUrl,
  token,
  pathUrlParam,
  foxCookies,
  isTeaserPreviewPage,
}: {
  pageUrl: string
  token: string
  pathUrlParam: string
  foxCookies: {
    au_seg?: string
    au_seg_beta?: string
    au_seg_sport?: string
    ea_uuid?: string
  }
  isTeaserPreviewPage: boolean
}): Promise<{
  headers: Response['headers']
  jsonFeed: Record<string, unknown>
}> => {
  const adjustedPageUrl =
    publication === 'romandie' && useLocalhostApiUrl
      ? pageUrl.replace('/fr/', '/')
      : pageUrl

  const Cookie =
    adjustedPageUrl === '/'
      ? Object.entries(foxCookies)
          .reduce(
            (acc, [key, value]) => (value ? `${acc} ${key}=${value}; ` : acc),
            ''
          )
          .trim()
      : undefined

  let response: Response = new Response()

  try {
    response = await fetch(
      `${versionedApiUrl}${adjustedPageUrl}${
        token || pathUrlParam || isTeaserPreviewPage ? '?' : ''
      }${token ? `token=${token}` : ''}${token ? '&' : ''}${
        pathUrlParam ? `path=${pathUrlParam}` : ''
      }${token || pathUrlParam ? '&' : ''}${
        isTeaserPreviewPage ? 'layout=teaser-preview' : ''
      }`,
      {
        headers: {
          'User-Agent': 'next',
          ...(Cookie ? { Cookie } : {}),
        },
      }
    )

    let jsonFeed = await response.json()

    if (isTeaserPreviewPage) {
      if (isValidPageMetadata(jsonFeed.metadata)) {
        jsonFeed = {
          ...jsonFeed,
          metadata: {
            ...jsonFeed.metadata,
            context: 'section',
            type: 'section',
          },
        }
      }
    }

    return { headers: response.headers, jsonFeed }
  } catch {
    return { headers: response.headers, jsonFeed: {} }
  }
}

const getFullPageUrl = (rawPageUrl: string | string[]) => {
  const isArticle =
    Array.isArray(rawPageUrl) &&
    rawPageUrl.length > 0 &&
    rawPageUrl[rawPageUrl.length - 1].endsWith('.html')

  const isXML =
    Array.isArray(rawPageUrl) &&
    rawPageUrl.length > 0 &&
    rawPageUrl[rawPageUrl.length - 1].endsWith('.xml')

  if (isArticle || isXML) {
    return `/${rawPageUrl.join('/')}`
  } else {
    const url = `/${
      Array.isArray(rawPageUrl) ? rawPageUrl.join('/') : (rawPageUrl ?? '')
    }`
    return `${url}${url.endsWith('/') ? '' : '/'}`
  }
}

const extractPageType = (metadata: any): PageType => {
  if (!isValidPageMetadata(metadata)) {
    return 'section'
  }

  if (metadata.context === 'dossier') {
    return 'dossier'
  }

  if (['section', 'article'].includes(metadata.type)) {
    return metadata.type
  }

  return 'section'
}

const isValidSkeletonData = (
  skeletonData: any
): skeletonData is ValidSkeletonData =>
  Object.keys(skeletonData?.skeletonData?.[0]?.navigation || {}).length > 0 &&
  Object.keys(skeletonData?.skeletonData?.[0]?.footer || {}).length > 0 &&
  Object.keys(skeletonData?.skeletonData?.[0]?.video || {}).length > 0

const isValidPageMetadata = (pageMetadata: any): pageMetadata is PageMetadata =>
  !!Object.keys(pageMetadata ?? {})?.length

// RSS redirects
// /rss => /rss.xml
// /mod_rss => /mod_rss.xml
// /msn_rss => /msn_rss.xml
const getRedirectToRSSInfo = (requestUrl: string) => ({
  shouldRedirect: RSS_PATHS.some((rssPath) =>
    requestUrl.endsWith(`/${rssPath}/`)
  ),
  redirectPath: `${
    requestUrl.endsWith('/') ? requestUrl.slice(0, -1) : requestUrl
  }.xml`,
})

const getRSSContentInfo = (requestUrl: string) => {
  const rssPath: RSSPathType | undefined = RSS_PATHS.find((path) =>
    requestUrl.endsWith(`/${path}.xml`)
  )

  const rssRequestUrl = requestUrl
    ?.replace('mod_rss', 'rss')
    ?.replace('msn_rss', 'rss')
    ?.replace('bplus_feed', 'rss')

  return {
    shouldServeRSSCONTENT: !!rssPath,
    rssPath,
    rssRequestUrl,
  }
}

const isBlickPlusOfferPage = (url: string) => url.includes(offerPagePath)

const hasImmersiveHero = (pageMetadata: PageMetadata): boolean =>
  pageMetadata?.hasImmersiveHero?.[0] === true

const isVideoOnlyArticle = (targetContentType?: TargetContentType) =>
  targetContentType === 'video'

export {
  fetchSkeleton,
  fetchPage,
  getFullPageUrl,
  extractPageType,
  isValidSkeletonData,
  isValidPageMetadata,
  getRedirectToRSSInfo,
  getRSSContentInfo,
  isBlickPlusOfferPage,
  hasImmersiveHero,
  isVideoOnlyArticle,
}
