import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

interface ListicleProps {
  titleNumber?: string
  titleText: string
}

const StyledWrapper = styled.div`
  ${({
    theme: {
      color: {
        primary: { blickRed: blickRedColor },
      },
    },
  }) => css`
    display: grid;
    grid-template-rows: auto auto;
    border-left: 3px solid ${blickRedColor};
  `}
`

const StyledText = styled.h3`
  ${({
    theme: {
      spacing: { spacing12 },
      typography: {
        headings: {
          small: { bundledCSS: smallHeadingCSS },
        },
      },
    },
  }) => css`
    ${smallHeadingCSS};
    text-transform: uppercase;
    &&& {
      margin-left: ${spacing12};
    }
    align-self: flex-end;
  `}
`

const StyledNumber = styled.span`
  ${({
    theme: {
      color: {
        primary: { blickRed: blickRedColor },
      },
      typography: {
        headings: {
          xxlarge: { bundledCSS: xxlargeHeadingCSS },
        },
      },
      spacing: { spacing12 },
    },
  }) => css`
    ${xxlargeHeadingCSS};
    color: ${blickRedColor};
    margin: 0 ${spacing12};
    align-self: flex-start;
  `}
`

const Listicle: FunctionComponent<ListicleProps> = ({
  titleNumber,
  titleText,
}) => {
  return (
    <StyledWrapper>
      {!!titleNumber && <StyledNumber>{titleNumber}</StyledNumber>}
      <StyledText>{titleText}</StyledText>
    </StyledWrapper>
  )
}

const widget = {
  kind: ['widget', 'listicle-title'],
  component: Listicle,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
