export const setElementClass = (
  element: Element,
  className: string,
  state: boolean
): void => {
  const hasClassName = element?.classList.contains(className)

  if (state) {
    if (!hasClassName) {
      element?.classList.add(className)
    }
  } else if (hasClassName) {
    element?.classList.remove(className)
  }
}
