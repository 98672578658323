import { createSoftwareApplicationSchema } from '@utils/schema'
import usePageMetadata from '@hooks/usePageMetadata'
import config from '@config'
import SchemaHTMLElement from '@components/SchemaHTMLElement'

const {
  schema: { androidUrl, iosUrl },
} = config

const SoftwareApplicationSchema = () => {
  const pageMetadata = usePageMetadata()
  const { metaDescription } = pageMetadata
  return (
    <>
      <SchemaHTMLElement
        scriptKey="softwareApplicationAndroid"
        schemaObject={createSoftwareApplicationSchema({
          operatingSystem: 'ANDROID',
          description: metaDescription,
          url: androidUrl,
        })}
      />
      <SchemaHTMLElement
        scriptKey="softwareApplicationIOS"
        schemaObject={createSoftwareApplicationSchema({
          operatingSystem: 'iOS',
          description: metaDescription,
          url: iosUrl,
        })}
      />
    </>
  )
}

export default SoftwareApplicationSchema
