import { FunctionComponent } from 'react'
import { TextTrackProps } from '../../types'
import { isLiveStream } from '../../utils/player'
import LiveGraphics from './LiveGraphics'
import VodGraphics from './VodGraphics'

type GraphicsProps = Omit<TextTrackProps, 'onCaptionsCueChange'>

const Graphics: FunctionComponent<GraphicsProps> = ({
  player,
  onGraphicsCueChange,
}) => {
  const isLivestreamPlayer = isLiveStream(player)

  if (isLivestreamPlayer) {
    return (
      <LiveGraphics player={player} onGraphicsCueChange={onGraphicsCueChange} />
    )
  }

  return (
    <VodGraphics player={player} onGraphicsCueChange={onGraphicsCueChange} />
  )
}

export default Graphics
