import ArticleHeader from './ArticleHeader'
import ArticleLead from './ArticleLead'
import ArticleMetadata from './ArticleMetadata'
import CommentButton from './CommentButton'
import SharingAndCommentWrapper from './ArticleMetadata/SharingAndCommentWrapper'
import TitleCatchword from './TitleCatchword'
import HeadlineHeroImage from './HeadlineHeroImage'

const exports = {
  ArticleHeader,
  ArticleLead,
  ArticleMetadata,
  CommentButton,
  SharingAndCommentWrapper,
  TitleCatchword,
  HeadlineHeroImage,
}

export default exports
