import { createContext } from 'react'

export interface GenericCommentingContextType {
  articleId: string
  commentsEnabled: boolean
  postingEnabled: boolean
  showPrompts: boolean
  showBlickPlusInfoBox: boolean
  title: string
  requireValidUser: (action: () => void) => () => void
  requireValidUserForCommentReactions: (action: () => void) => () => void
}

const GenericCommentingContext = createContext<GenericCommentingContextType>({
  articleId: '',
  commentsEnabled: false,
  postingEnabled: false,
  showPrompts: false,
  showBlickPlusInfoBox: false,
  title: '',
  requireValidUser: () => () => {},
  requireValidUserForCommentReactions: () => () => {},
})

export default GenericCommentingContext
