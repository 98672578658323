import useTracking, { TrackingFnType } from '@hooks/useTracking'
import { FunctionComponent, useCallback, useEffect } from 'react'
import { getPageViewType } from './utils'
import config from '@config'
import { getURLParam } from '@utils/urlParams'
import { paywallByPassReason } from '@components/Piano/utils'
import useExecuteOnClientNavigation from '@hooks/useExecuteOnClientNavigation'
import { useQueryClient } from '@tanstack/react-query'

const {
  subscriptions: { offerPagePath },
} = config

const TrackPageView: FunctionComponent = () => {
  const queryClient = useQueryClient()

  const onImpression = useCallback<TrackingFnType>(
    ({ pageMetadata, queryClient }) => {
      const { sectionUniqueNames, ressort, url, personalizationInfo } =
        pageMetadata
      const sharingPlusQueryParam = getURLParam('sharingPlus', queryClient)

      const type = getPageViewType(pageMetadata)
      let eventData: ReturnType<TrackingFnType>

      switch (type) {
        case 'home': {
          eventData = { event: 'home_page_view' }
          break
        }
        case 'section': {
          eventData = {
            ...(url === offerPagePath ? { isPlus: 1 } : {}),
            event: 'section_page_view',
          }
          break
        }
        case 'article': {
          eventData = {
            ...{
              pageRessort: sectionUniqueNames?.[sectionUniqueNames.length - 1],
            },
            analyticsRessort: ressort,
            paywall_bypass: paywallByPassReason(pageMetadata.id),
            eventLabel: `${!!sharingPlusQueryParam}`,
            event: 'article_page_view',
          }
          break
        }
        case 'dossier': {
          eventData = { event: 'dossier_page_view' }
          break
        }
        default: {
          return
        }
      }

      return {
        ...eventData,
        ...(personalizationInfo?.experimentVariant
          ? { experimentVariant: personalizationInfo.experimentVariant }
          : {}),
      }
    },
    []
  )

  const trackOnImpression = useTracking(onImpression)

  useExecuteOnClientNavigation(
    useCallback(() => {
      trackOnImpression()
    }, [trackOnImpression])
  )

  useEffect(() => {
    if (!queryClient.getQueryData<boolean>(['initial-page-view-tracked'])) {
      queryClient.setQueryData(['initial-page-view-tracked'], true)
      trackOnImpression()
    }
  }, [queryClient, trackOnImpression])

  return null
}

export default TrackPageView
