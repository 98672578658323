import config from '@config'
import { QueryClient } from '@tanstack/react-query'

const {
  abTest: { windowKey },
} = config

const MESSAGE_PREFIX = 'VideoPlayer::'

const logVideoPlayer = (
  {
    queryClient,
    method,
  }: {
    queryClient: QueryClient
    method?: 'info' | 'log' | 'warn' | 'error'
  },
  ...args: any
): void => {
  const showVideoPlayerLogs =
    queryClient.getQueryData<'show'>([windowKey, 'showVideoPlayerLogs']) ===
    'show'

  if (showVideoPlayerLogs) {
    console[method ?? 'log'](`${MESSAGE_PREFIX}`, ...args)
  }
}

export { logVideoPlayer }
