import useHeaderTopics from '@hooks/useHeaderTopics'
import usePageMetadata from '@hooks/usePageMetadata'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import HeaderTopicsSwiper from '../HeaderTopicsSwiper'
import Divider from '../Divider'
import { desktopCSS } from '@measures/responsive'

const Container = styled.div`
  ${({
    theme: {
      spacing: { spacing16, spacing24 },
    },
  }) => css`
    position: relative;
    display: grid;
    grid-template-columns: auto 1px 1fr;
    align-items: center;
    grid-gap: ${spacing16};

    ${desktopCSS(css`
      grid-gap: ${spacing24};
    `)}
  `}
`

const StyledTitle = styled.div`
  ${({
    theme: {
      typography: {
        labels: {
          label2: { bundledCSS: label2LabelsCSS },
        },
      },
      color: {
        primary: { blickRed: blickRedColor },
      },
    },
  }) => css`
    ${label2LabelsCSS}
    color: ${blickRedColor};
  `}
`

const FirstLevelSection: FunctionComponent = () => {
  const { title } = usePageMetadata()
  const headerTopics = useHeaderTopics()

  return (
    <Container>
      <StyledTitle>{title}</StyledTitle>
      {headerTopics.length > 0 && (
        <>
          <Divider />
          <HeaderTopicsSwiper />
        </>
      )}
    </Container>
  )
}

export default FirstLevelSection
