import { desktopCSS, mobileCSS, tabletCSS } from '@measures/responsive'
import { css } from 'styled-components'

// Padding in WrapAll component
const outerPadding = {
  desktop: '15px',
  tablet: '68px',
  mobile: '16px',
}

// Total space in single column articles
const totalSingleColumnSpacing = {
  desktop: '181px',
  tablet: '68px',
  mobile: '16px',
}

// Padding in LayoutCard component
const layoutCardPadding = {
  desktop: '32px',
  tablet: '32px',
  mobile: '16px',
}

const stretchWidth = (fullwidth?: boolean) => css`
  ${desktopCSS(css`
    // 994px - desktop content width, 632px - element width
    margin-left: ${fullwidth
      ? 'calc((632px - 994px) / 2)'
      : `-${outerPadding.desktop}`};
    margin-right: ${fullwidth
      ? 'calc((632px - 994px) / 2)'
      : `-${outerPadding.desktop}`};
  `)}
  ${tabletCSS(css`
    margin-left: -${outerPadding.tablet};
    margin-right: -${outerPadding.tablet};
  `)}
  ${mobileCSS(css`
    margin-left: -${outerPadding.mobile};
    margin-right: -${outerPadding.mobile};
  `)}
`

const stretchWidthOnMobile = () => css`
  ${mobileCSS(css`
    margin-left: -${outerPadding.mobile};
    margin-right: -${outerPadding.mobile};
  `)}
`

const stretchToLayoutCardWidth = () => css`
  ${desktopCSS(css`
    margin-left: -${layoutCardPadding.desktop};
    margin-right: -${layoutCardPadding.desktop};
  `)};
  ${tabletCSS(css`
    margin-left: -${layoutCardPadding.tablet};
    margin-right: -${layoutCardPadding.tablet};
  `)};
  ${mobileCSS(css`
    margin-left: -${layoutCardPadding.mobile};
    margin-right: -${layoutCardPadding.mobile};
  `)};
`

// Stretch background to the full width of WrapAll component
const stretchBackground = () => {
  const commonStyles = (outerPadding: string) => css`
    margin-left: -${outerPadding};
    margin-right: -${outerPadding};
    padding-left: ${outerPadding};
    padding-right: ${outerPadding};
  `

  return css`
    ${desktopCSS(commonStyles(outerPadding.desktop))};
    ${tabletCSS(commonStyles(outerPadding.tablet))};
    ${mobileCSS(commonStyles(outerPadding.mobile))};
  `
}

export type MeasuresType = {
  outerPadding: typeof outerPadding
  totalSingleColumnSpacing: typeof totalSingleColumnSpacing
  layoutCardPadding: typeof outerPadding
  stretchWidth: typeof stretchWidth
  stretchWidthOnMobile: typeof stretchWidthOnMobile
  stretchToLayoutCardWidth: typeof stretchToLayoutCardWidth
  stretchBackground: typeof stretchBackground
}

const measures = {
  outerPadding,
  totalSingleColumnSpacing,
  layoutCardPadding,
  stretchWidth,
  stretchWidthOnMobile,
  stretchToLayoutCardWidth,
  stretchBackground,
} as const satisfies MeasuresType

export default measures
