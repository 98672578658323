import { css } from 'styled-components'

export const wrapper = css`
  position: relative;
  height: 100%;

  .jw-title {
    display: none;
  }
`
