import { useContext } from 'react'
import GalleryFullScreenContext, {
  GalleryFullScreenContextType,
} from '@contexts/fullscreen/fullscreenContext'

const useFullScreenGallery = (): GalleryFullScreenContextType => {
  const context = useContext(GalleryFullScreenContext)
  if (context === undefined) {
    console.error(
      'useFullScreenGallery must be used within a GalleryFullScreenProvider'
    )
  }
  return context
}

export { useFullScreenGallery }
