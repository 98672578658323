import styled, { css } from 'styled-components'
import { desktopCSS } from '@measures/responsive'

const SideAd = styled.div`
  ${desktopCSS(css`
    width: 100%;
    height: 100%;
  `)};
`

export default SideAd
