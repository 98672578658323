import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import BulletpointsItem from './Item'

const BulletList = styled.ul`
  ${({
    theme: {
      typography: {
        bodycopy: {
          small2: { bundledCSS: small2BodycopyCSS },
        },
      },
      color: {
        primary: { primary01 },
      },
    },
  }) => css`
    list-style: none;
    margin: 0;
    padding-left: 0;
    ${small2BodycopyCSS};
    color: ${primary01};
  `}
`

const Bulletpoints: FunctionComponent<{ children?: ReactNode }> = ({
  children,
}) => <BulletList>{children}</BulletList>

export { Bulletpoints, BulletpointsItem }
