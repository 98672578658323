import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent } from 'react'
import EmbeddedContent, {
  EmbeddedContentWebProps,
} from '@components/EmbeddedContent'

export interface EmbeddedContentWidgetProps extends EmbeddedContentWebProps {
  contentId: string
  title: string
}

const EmbeddedContentWidget: FunctionComponent<EmbeddedContentWidgetProps> = (
  props
) => <EmbeddedContent {...props} trackImpression />

const widget = {
  kind: ['widget', 'embedded-content'],
  component: EmbeddedContentWidget,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
