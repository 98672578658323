import { FunctionComponent, useContext, useState } from 'react'
import CommentingConfirmationMessage from './CommentingConfirmationMessage'
import PostedCommentingItem from './PostedCommentingItem'
import CommentingItemWrappper from './CommentingItemWrapper'
import CommentingActionPopup from './CommentingActionPopup'
import APIContext from '@contexts/commenting/apiContext'
import { PostedCommentProps } from './types'
import translate from '@i18n'
import { getTitle } from './utils'

const PostedComment: FunctionComponent<PostedCommentProps> = ({
  comment: {
    id,
    author_type,
    created,
    body,
    user: { first_name, last_name, gender },
  },
}) => {
  const { deletePostedComment } = useContext(APIContext)

  const [deletingComment, setDeletingComment] = useState(false)
  const [confirmationMessageShown, setConfirmationMessageShown] = useState(true)

  return (
    <CommentingItemWrappper type="comment" itemId={id}>
      <PostedCommentingItem
        type="comment"
        authorType={author_type}
        gender={gender}
        deleting={deletingComment}
        authorName={getTitle({
          name: `${first_name} ${last_name}`,
        })}
        datePublished={created}
        textContent={body}
        onDelete={() => setDeletingComment(true)}
      />
      {confirmationMessageShown && (
        <CommentingConfirmationMessage
          onTimeout={() => setConfirmationMessageShown(false)}>
          {translate('commenting.postCommentSentMessage')}
        </CommentingConfirmationMessage>
      )}
      {deletingComment && (
        <CommentingActionPopup
          type="comment"
          actionType="delete"
          onConfirm={() => {
            setDeletingComment(false)
            deletePostedComment({ commentId: id })
          }}
          onCancel={() => setDeletingComment(false)}
        />
      )}
    </CommentingItemWrappper>
  )
}

export default PostedComment
