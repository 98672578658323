import { css } from 'styled-components'
import { printCSS } from '@utils/style'

const bodyStyle = css`
  ${({
    theme: {
      spacing: { spacing16 },
      typography: {
        bodycopy: {
          small2: { bundledCSS: small2BodyCopyCSS },
        },
        headings: {
          small: { bundledCSS: smallHeadingCSS },
          xsmall: { bundledCSS: xsmallHeadingCSS },
        },
      },
    },
  }) => css`
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-row-gap: ${spacing16};
    align-items: flex-start;

    ul {
      padding-left: 1em;
    }

    ol {
      padding-left: 1.7em;
    }

    li,
    p {
      ${small2BodyCopyCSS}

      ${printCSS(css`
        font-size: 14px;
      `)}
    }

    p {
      &:empty {
        display: none;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul,
    ol {
      margin: 0;
    }

    h1,
    h2,
    h3 {
      ${smallHeadingCSS}
      ${printCSS(css`
        font-size: 16px;
      `)}
    }

    h4,
    h5 {
      ${xsmallHeadingCSS}
      ${printCSS(css`
        font-size: 14px;
      `)}
    }

    > *:not(div):not(aside) + h1,
    > *:not(div):not(aside) + h2,
    > *:not(div):not(aside) + h3,
    > *:not(div):not(aside) + h4,
    > *:not(div):not(aside) + h5 {
      margin-top: ${spacing16};
    }

    > div:not(.paywallWrapper):not(.paywallContent),
    > a[href^="https://embed.zattoo.com"]
    {
      margin-top: ${spacing16};
      margin-bottom: ${spacing16};

      &:last-child {
        margin-bottom: 0;
      }
    }

    > aside {
      margin-top: ${spacing16};
      margin-bottom: ${spacing16};

      &:last-child {
        margin-bottom: 0;
      }
    }

    > div + div,
    > div + aside > div + h1,
    > div + h2,
    > div + h3,
    > div + h4,
    > div + h5 {
      margin-top: 0;
    }

    > aside + div,
    > aside + aside > aside + h1,
    > aside + h2,
    > aside + h3,
    > aside + h4,
    > aside + h5 {
      margin-top: 0;
    }
  `}
`

export { bodyStyle }
