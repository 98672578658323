import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import Title from './Title'
import Text from '@components/PlusLinkShare/Text'
import TextAndButtonContainer from './TextAndButtonContainer'
import SpinnerAndText from './Loading/SpinnerAndText'
import useSubscriptionStatus from '@hooks/useSubscriptionStatus'
import config from '@config'
import usePageMetadata from '@hooks/usePageMetadata'
import translate from '@i18n'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import { getUTMParameters } from '@components/Social/utils'

const {
  plusLinkShare: { getSharesApiUrl, linkGenerationApiUrl },
  socialembeds: {
    share: {
      types: {
        pluslinkshare: { utmSource },
      },
    },
  },
} = config

const ErrorText = styled(Text)`
  ${({
    theme: {
      color: {
        primary: { blickRed },
      },
    },
  }) => css`
    color: ${blickRed};
  `}
`

const SubscribedPopover: FunctionComponent = () => {
  const { id: articleId } = usePageMetadata()
  const { subscriptionStatus } = useSubscriptionStatus()
  const [availableShares, setAvailableShares] = useState<number | undefined>()
  const [isLinkLoading, setIsLinkLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [linkToCopy, setLinkToCopy] = useState<string | undefined>(undefined)
  const [updatedAvailableShares, setUpdatedAvailableShares] = useState<
    number | undefined
  >()

  const popoverShownTrackingFn = useCallback<
    TrackingFnType<{ availableShares?: number }>
  >(
    ({ extraData }) => ({
      event: 'element_impression',
      element: 'article_share_top',
      method: 'blickplus_gift',
      element_status: `${extraData.availableShares}`,
    }),
    []
  )

  const buttonClickTrackingFn = useCallback<
    TrackingFnType<{ updatedAvailableShares?: number }>
  >(
    ({ extraData }) => ({
      event: 'element_click',
      element: 'article_share_top',
      method: 'blickplus_gift',
      element_status: `${extraData.updatedAvailableShares}`,
    }),
    []
  )

  const onPopoverShownTracking = useTracking(popoverShownTrackingFn)
  const onButtonClickTracking = useTracking(buttonClickTrackingFn)

  const getShares = useCallback(() => {
    fetch(getSharesApiUrl, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        const { availableShares } = response
        setAvailableShares(availableShares)
        onPopoverShownTracking({ availableShares })
      })
      .catch((err) => {
        console.log('There was an error in the GET:', err)
      })
  }, [onPopoverShownTracking])

  useEffect(() => {
    if (subscriptionStatus === 'subscribed') {
      getShares()
    }
  }, [subscriptionStatus, getShares])

  const onGenerateButtonClick = useCallback(() => {
    setIsLinkLoading(true)
    fetch(linkGenerationApiUrl, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ articleId }),
    })
      .then((response) => response.json())
      .then((response) => {
        setIsLinkLoading(false)
        const { availableShares, shareLink } = response
        setUpdatedAvailableShares(availableShares)
        onButtonClickTracking({ updatedAvailableShares: availableShares })
        const utm = getUTMParameters({ utmSource, fullSuffix: false })
        setLinkToCopy(`${shareLink}${utm}`)
      })
      .catch((err) => {
        setIsLinkLoading(false)
        setIsError(true)
        console.log('There was an error in the POST:', err)
      })
  }, [articleId, onButtonClickTracking])

  return (
    <>
      <Title
        availableShares={availableShares}
        updatedAvailableShares={updatedAvailableShares}
      />
      {typeof availableShares !== 'number' ? (
        <SpinnerAndText />
      ) : (
        <>
          <TextAndButtonContainer
            onGenerateButtonClick={onGenerateButtonClick}
            linkToCopy={linkToCopy}
            availableShares={availableShares}
            updatedAvailableShares={updatedAvailableShares}
            isLinkLoading={isLinkLoading}
            isError={isError}
          />
          {isError && (
            <ErrorText>
              {translate('blickPlus.linkSharing.errorText')}
            </ErrorText>
          )}
        </>
      )}
    </>
  )
}

export default SubscribedPopover
