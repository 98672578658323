import { useQueryClient } from '@tanstack/react-query'
import { FunctionComponent, useCallback, useEffect, useRef } from 'react'
import { DEBOUNCE_TIMEOUT, getViewportType } from '@hooks/useViewport/utils'
import debounce from 'lodash.debounce'

const ViewportManager: FunctionComponent = () => {
  const queryClient = useQueryClient()
  const windowDimensionsFirstRun = useRef(false)
  const orientationMatchFirstRun = useRef(false)
  const orientationMatch = useRef<MediaQueryList | null>(null)
  const onSizeChange = useCallback(() => {
    const calculatedSize = {
      width: window.innerWidth,
      height: window.innerHeight,
    }

    queryClient.setQueryData(
      ['viewportType'],
      getViewportType(calculatedSize.width)
    )
    queryClient.setQueryData(
      ['clientViewportType'],
      getViewportType(calculatedSize.width)
    )
    queryClient.setQueryData(['viewportDimensions'], calculatedSize)
    queryClient.invalidateQueries({ queryKey: ['viewportType'] })
    queryClient.invalidateQueries({ queryKey: ['clientViewportType'] })
    queryClient.invalidateQueries({ queryKey: ['viewportDimensions'] })
  }, [queryClient])

  const onOrientationChange = useCallback(
    (e: any) => {
      queryClient.setQueryData(
        ['viewportOrientation'],
        e.matches ? 'landscape' : 'portrait'
      )
      queryClient.invalidateQueries({ queryKey: ['viewportOrientation'] })
    },
    [queryClient]
  )
  useEffect(() => {
    if (!windowDimensionsFirstRun.current) {
      onSizeChange()
      windowDimensionsFirstRun.current = true
    }
    if (!orientationMatchFirstRun.current) {
      orientationMatchFirstRun.current = true
      orientationMatch.current = matchMedia('(orientation: landscape)')
      onOrientationChange(orientationMatch.current)
    }
    const debouncedSizeChangeCallback = debounce(onSizeChange, DEBOUNCE_TIMEOUT)
    window.addEventListener('resize', debouncedSizeChangeCallback)
    orientationMatch.current?.addListener(onOrientationChange)
    return () => {
      window.removeEventListener('resize', debouncedSizeChangeCallback)
      orientationMatch.current?.removeListener(onOrientationChange)
    }
  }, [queryClient, onSizeChange, onOrientationChange])
  return null
}

export default ViewportManager
