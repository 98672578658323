import { CarouselAd, CarouselItem } from '@widgets/Carousel'

const getSlidesWithAds = (
  slides: (CarouselItem | CarouselAd)[],
  adSlideIndex: number,
  adSlide: CarouselAd
): (CarouselItem | CarouselAd)[] => {
  const slidesLength = slides.length
  const currentSlides: (CarouselItem | CarouselAd)[] = [...slides]

  if (slidesLength > adSlideIndex) {
    currentSlides.splice(adSlideIndex, 0, adSlide)
  }

  return currentSlides
}

const isAdSlide = (slide: CarouselItem | CarouselAd): slide is CarouselAd => {
  return (slide as CarouselAd).placement !== undefined
}

export { getSlidesWithAds, isAdSlide }
