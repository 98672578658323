import { useState, useRef, useCallback, useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import config from '@config'

const {
  abTest: { windowKey },
} = config

export type FeatureFlagsEntries = (typeof config.abTest)['featureFlagsEntries']

function useABTestValue<T extends keyof FeatureFlagsEntries>(
  abTestKey: T
): FeatureFlagsEntries[T]['values'][number]['name'] | undefined {
  const queryClient = useQueryClient()
  const queryCache = queryClient.getQueryCache()

  const [abTestValue, setABTestValue] = useState<
    FeatureFlagsEntries[T]['values'][number]['name'] | undefined
  >(() =>
    queryClient.getQueryData<FeatureFlagsEntries[T]['values'][number]['name']>([
      windowKey,
      abTestKey,
    ])
  )

  const unsubscribeFnRef = useRef<() => void>()

  const updateABTestValue = useCallback(
    (args: any) => {
      if (
        args.query.queryKey[0] === windowKey &&
        args.query.queryKey[1] === abTestKey
      ) {
        if (args?.action?.type === 'invalidate') {
          setABTestValue(
            queryClient.getQueryData<
              FeatureFlagsEntries[T]['values'][number]['name']
            >([windowKey, abTestKey])
          )
        } else if (args?.type === 'removed') {
          setABTestValue(undefined)
        }
      }
    },
    [abTestKey, queryClient]
  )

  useEffect(() => {
    setABTestValue(
      queryClient.getQueryData<
        FeatureFlagsEntries[T]['values'][number]['name']
      >([windowKey, abTestKey])
    )

    unsubscribeFnRef.current = queryCache.subscribe(updateABTestValue)

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [updateABTestValue, queryCache, queryClient, abTestKey])

  return abTestValue
}

export default useABTestValue
