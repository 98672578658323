import { FunctionComponent } from 'react'
import AnimatedPreview from '@components/AnimatedPreview'

interface TeaserAnimatedPreviewProps {
  video?: { jwVideoId: string }
  articleUrl: string
}

const TeaserAnimatedPreview: FunctionComponent<TeaserAnimatedPreviewProps> = ({
  articleUrl,
  video,
}) => {
  return video ? (
    <AnimatedPreview videoId={video.jwVideoId} articleUrl={articleUrl} />
  ) : null
}

export default TeaserAnimatedPreview
