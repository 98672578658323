import { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'

export interface VideoBackgroundProps {
  children?: ReactNode
  className?: string
}

const StyledVideoBackground = styled.div`
  user-select: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const VideoBackground: FunctionComponent<VideoBackgroundProps> = ({
  children,
  className,
}) => {
  return (
    <StyledVideoBackground className={className}>
      {children}
    </StyledVideoBackground>
  )
}

export default VideoBackground
