import { FunctionComponent } from 'react'
import styled, { css, useTheme } from 'styled-components'
import { bodyStyle } from '@widgets/Body/bodyStyle'
import JSONRenderer from '@components/JSONRenderer'
import { CueLivePost } from '@utils/cueLive'
import { FormattedDate, formatDate } from '@utils/date'
import FreeRatioImage from '@components/FreeRatioImage'
import translate from '@i18n'
import SvgIcon from '@components/SvgIcon'
import { desktopCSS } from '@measures/responsive'
import VerticalLine from './VerticalLine'

interface PostProps extends Pick<CueLivePost, 'id' | 'publishedAt' | 'icon'> {
  isPinned?: boolean
  accentColor?: string
  children: CueLivePost['values']
}

const imageConstraint = { width: 200, height: 24 }

export const StyledPost = styled.div<
  Pick<PostProps, 'isPinned' | 'accentColor'>
>`
  ${({
    theme: {
      color: {
        primary: { blickRed },
      },
      spacing: { spacing12, spacing16, spacing24, spacing32 },
    },
    isPinned,
    accentColor,
  }) => css`
    position: relative;

    padding-bottom: ${spacing24};
    padding-left: ${spacing12};

    ${desktopCSS(css`
      padding-bottom: ${spacing32};
      padding-left: ${spacing16};
    `)};

    &:first-child > ${VerticalLine} {
      top: 8px;

      height: calc(100% - 8px);
    }

    ${isPinned &&
    css`
      border: 2px solid ${accentColor ?? blickRed};
      border-radius: 4px;

      padding: ${spacing16};
      margin-bottom: ${spacing24};

      ${desktopCSS(css`
        padding: ${spacing24};
        margin-bottom: ${spacing32};
      `)};
    `}
  `}
`

const PinWrapper = styled.div<Pick<PostProps, 'accentColor'>>`
  ${({
    theme: {
      color: {
        primary: { blickRed },
      },
      spacing: { spacing4 },
    },
    accentColor,
  }) => css`
    position: absolute;
    right: -2px;
    top: -2px;
    display: flex;

    padding: ${spacing4};
    border-radius: 0px ${spacing4};

    background-color: ${accentColor ?? blickRed};
  `}
`

const StyledDate = styled.div<Pick<PostProps, 'accentColor'>>`
  ${({
    theme: {
      typography: {
        eyebrows: {
          medium2: { bundledCSS: medium2Eyebrow },
        },
      },
      color: {
        primary: { blickRed },
      },
      spacing: { spacing8 },
    },
    accentColor,
  }) => css`
    color: ${accentColor ?? blickRed};
    ${medium2Eyebrow};
    display: flex;
    align-items: center;
    margin-bottom: ${spacing8};
  `}
`

const StyledIconWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => css`
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    grid-column-gap: ${spacing8};
    align-items: center;
    justify-items: flex-start;
    margin-bottom: ${spacing8};
  `}
`

const IconImage = styled.div``

const IconLabel = styled.div`
  ${({
    theme: {
      typography: {
        eyebrows: {
          medium2: { bundledCSS: medium2Eyebrow },
        },
      },
    },
  }) => css`
    ${medium2Eyebrow};
  `}
`

const StyledWidgetsWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing24 },
    },
  }) => css`
    ${bodyStyle}

    ${desktopCSS(css`
      grid-row-gap: ${spacing24};
    `)};
  `}
`

export const StyledDot = styled.div<Pick<PostProps, 'accentColor'>>`
  ${({
    theme: {
      color: {
        primary: { blickRed: blickRedColor },
      },
    },
    accentColor,
  }) => css`
    position: absolute;
    left: -3px;

    background-color: ${accentColor ?? blickRedColor};
    width: 8px;
    height: 8px;
    border-radius: 50%;
  `};
`

const Post: FunctionComponent<PostProps> = ({
  isPinned,
  icon,
  publishedAt,
  accentColor,
  children,
}) => {
  const theme = useTheme()

  const hasIcon = !!icon?.image?.src

  return (
    <StyledPost isPinned={isPinned} accentColor={accentColor}>
      {!!isPinned && (
        <PinWrapper
          accentColor={accentColor}
          title={translate('cueLive.pinnedPost')}>
          <SvgIcon
            iconName="thumbtack"
            size={24}
            color={theme.color.primary.primary02}
          />
        </PinWrapper>
      )}
      {!isPinned && <VerticalLine accentColor={accentColor} />}
      <StyledDate accentColor={accentColor} suppressHydrationWarning={true}>
        {!isPinned && <StyledDot accentColor={accentColor} />}
        {(formatDate(new Date(publishedAt)) as FormattedDate)?.dateOrTime}
      </StyledDate>
      {hasIcon && (
        <StyledIconWrapper>
          <IconImage>
            <FreeRatioImage
              src={icon.image.src}
              alt={icon.label}
              originalDimensions={{
                width: icon.image.width,
                height: icon.image.height,
              }}
              constraints={{
                desktop: imageConstraint,
                tablet: imageConstraint,
                mobile: imageConstraint,
                mobileSmall: imageConstraint,
              }}
              crops={{}}
            />
          </IconImage>
          <IconLabel>{icon.label}</IconLabel>
        </StyledIconWrapper>
      )}
      <StyledWidgetsWrapper>
        <JSONRenderer>{children}</JSONRenderer>
      </StyledWidgetsWrapper>
    </StyledPost>
  )
}

export default Post
