import { FunctionComponent, useEffect, useState } from 'react'
import { TextTrackProps } from '../../types'
import { isLiveStream } from '../../utils/player'
import LiveCaptions from './LiveCaptions'
import VodCaptions from './VodCaptions'

type CaptionsProps = Omit<TextTrackProps, 'onGraphicsCueChange'>

const Captions: FunctionComponent<CaptionsProps> = ({
  player,
  onCaptionsCueChange,
}) => {
  const isLivestreamPlayer = isLiveStream(player)
  const [captionsDisabled, setCaptionsDisabled] = useState<boolean>(
    () => !player.getCurrentCaptions()
  )

  useEffect(() => {
    function onCaptionsChanged() {
      setCaptionsDisabled(!player.getCurrentCaptions())
    }

    player.on('captionsChanged', onCaptionsChanged)

    return () => {
      player.off('captionsChanged', onCaptionsChanged)
    }
  }, [player])

  if (isLivestreamPlayer) {
    return (
      <LiveCaptions
        player={player}
        disabled={captionsDisabled}
        onCaptionsCueChange={onCaptionsCueChange}
      />
    )
  }

  return (
    <VodCaptions
      player={player}
      disabled={captionsDisabled}
      onCaptionsCueChange={onCaptionsCueChange}
    />
  )
}

export default Captions
