import styled, { css } from 'styled-components'
import { desktopCSS, mobileCSS } from '@measures/responsive'
import SwiperComponent from '@components/Swiper'

const Swiper = styled(SwiperComponent)<{
  amountOfTilesToShow: number
  itemsCount: number
}>`
  ${({
    theme: {
      measures: { outerPadding },
    },
    amountOfTilesToShow,
    itemsCount,
  }) => css`
    ${desktopCSS(css`
      > .swiper-items-container {
        grid-auto-flow: column;
        grid-template-rows: repeat(
          ${itemsCount > amountOfTilesToShow
            ? amountOfTilesToShow
            : itemsCount},
          auto
        );
        grid-auto-columns: 100%;
      }
    `)}

    ${mobileCSS(css`
      margin-right: -${outerPadding.mobile}; // + stretch to right for mobile
      width: auto;

      > .swiper-items-container > *:last-child {
        margin-right: ${outerPadding.mobile};
        scroll-margin-right: ${outerPadding.mobile};
      }
    `)}
  `}
`

export default Swiper
