import useTracking, { TrackingFnType } from '@hooks/useTracking'
import { useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'
import {
  getAccountUrl,
  getLoginUrl,
  getLogoutUrl,
  setLoginCookie,
  setLogoutCookie,
} from './utils'
import { calculateSSOTrackingSource } from '@utils/sso'

export type Login = (options: { prefix: string; loginCase?: string }) => void
export type LoginWithSource = (options: {
  source: string
  loginCase?: string
}) => void

export type DoLogin = (
  options:
    | {
        prefix: undefined
        source: string
        loginCase?: string
      }
    | {
        prefix: string
        source: undefined
        loginCase?: string
      }
) => void

export type Logout = () => void
export type VisitUserAccount = () => void

export type UseAuthentication = () => {
  login: Login
  loginWithSource: LoginWithSource
  logout: Logout
  visitUserAccount: VisitUserAccount
}

const useAuthentication: UseAuthentication = () => {
  const queryClient = useQueryClient()
  const router = useRouter()

  const trackLoginEvent = useCallback<TrackingFnType>(
    () => ({
      event: 'login',
      eventCategory: 'sso',
      eventLabel: 'profil',
      eventAction: 'login',
    }),
    []
  )

  const trackLogin = useTracking(trackLoginEvent)

  const trackLogoutEvent = useCallback<TrackingFnType>(
    () => ({
      event: 'logout',
      eventCategory: 'sso',
      eventLabel: 'profil',
      eventAction: 'logout',
    }),
    []
  )

  const trackLogout = useTracking(trackLogoutEvent)

  const doLogin = useCallback<DoLogin>(
    ({ prefix, source, loginCase }) => {
      const extraParams = {
        source:
          source ??
          calculateSSOTrackingSource(
            {
              prefix,
            },
            queryClient
          ),
        ...(loginCase ? { login_case: loginCase } : {}),
      }

      setLoginCookie()
      trackLogin()

      router.push(getLoginUrl(queryClient, extraParams))
    },
    [queryClient, trackLogin, router]
  )

  const login = useCallback<Login>(
    ({ prefix, loginCase }) => {
      doLogin({ source: undefined, prefix, loginCase })
    },
    [doLogin]
  )

  const loginWithSource = useCallback<LoginWithSource>(
    ({ source, loginCase }) => {
      doLogin({ prefix: undefined, source, loginCase })
    },
    [doLogin]
  )

  const logout = useCallback<Logout>(() => {
    setLogoutCookie()
    trackLogout()

    router.push(getLogoutUrl())
  }, [router, trackLogout])

  const visitUserAccount = useCallback<VisitUserAccount>(() => {
    window.open(getAccountUrl(), '_blank', 'noopener')
  }, [])

  return useMemo(
    () => ({ login, loginWithSource, logout, visitUserAccount }),
    [login, loginWithSource, logout, visitUserAccount]
  )
}

export default useAuthentication
