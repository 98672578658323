import { FunctionComponent, memo, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'

import nanoid from '@utils/random'
import { JSONWidgetType as AdRingierJSONWidgetType } from '@widgets/AdRingier'
import JSONRenderer from '@components/JSONRenderer'

const AdSlideWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const AdSlide: FunctionComponent<AdRingierJSONWidgetType> = (props) => {
  const { containerId, ...adSlide } = props
  const [adKey, setAdKey] = useState<string>('')

  const { ref, inView } = useInView({
    triggerOnce: false,
  })

  useEffect(() => {
    if (inView) {
      setAdKey(nanoid())
    } else {
      setAdKey('')
    }
  }, [inView])

  return (
    <AdSlideWrapper ref={ref}>
      {inView && adKey && (
        <JSONRenderer key={adKey}>
          {{
            ...adSlide,
            autoloadSlot: true,
            containerId: `${containerId}-${adKey}`,
          }}
        </JSONRenderer>
      )}
    </AdSlideWrapper>
  )
}

const MemoizedAdSlide = memo(AdSlide)

MemoizedAdSlide.displayName = 'MemoizedAdSlide'

export default MemoizedAdSlide
