import { FunctionComponent, SyntheticEvent } from 'react'
import { useTheme } from 'styled-components'
import translate from '@i18n'
import IconButton from '@components/Buttons/IconButton'

interface MenuCloseIconProps {
  onClick: (event?: SyntheticEvent) => void
  className?: string
}

const MenuCloseIcon: FunctionComponent<MenuCloseIconProps> = ({
  onClick,
  className,
}) => {
  const theme = useTheme()
  return (
    <IconButton
      className={className}
      ariaLabel={translate('close')}
      buttonSize={48}
      onClick={onClick}
      iconName="xmark-large"
      iconSize={24}
      iconColor={theme.color.primary.primary01}
    />
  )
}

export default MenuCloseIcon
