import { QueryClient } from '@tanstack/react-query'
import { PageData, PageMetadata } from '@hooks/usePageMetadata'
import config from '@config'

export type CalculateSSOTrackingSource = (
  options: {
    prefix: string
    omitPublication?: boolean
  },
  queryClient: QueryClient
) => string

const {
  auth: { ssoSourceSuffix },
} = config

const composePageSuffix = (queryClient: QueryClient): string => {
  const {
    title: sectionSSO,
    id: articleIdSSO,
    ressort: articleSectionSSO,
  } = queryClient.getQueryData<PageData>(['page'])?.metadata as PageMetadata

  return articleIdSSO
    ? `${articleSectionSSO}_ARTICLE_${articleIdSSO}`
    : `${sectionSSO}`
}

const composePublicationSuffix = (): string => `_${ssoSourceSuffix}`

const calculateSSOTrackingSource: CalculateSSOTrackingSource = (
  { prefix, omitPublication },
  queryClient
) => {
  const pageSuffix = composePageSuffix(queryClient)

  const publicationSuffix = omitPublication ? '' : composePublicationSuffix()

  return `${prefix}_${pageSuffix}${publicationSuffix}`
}

export { calculateSSOTrackingSource }
