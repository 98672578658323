import { useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'
import { AnimatedPreviewValue } from './types'

type UseSetActiveAnimatedPreviewReturnType = (
  value:
    | AnimatedPreviewValue
    | ((value: AnimatedPreviewValue) => AnimatedPreviewValue | null)
) => void

type UseSetActiveAnimatedPreviewType =
  () => UseSetActiveAnimatedPreviewReturnType

const useSetActiveAnimatedPreview: UseSetActiveAnimatedPreviewType = () => {
  const queryClient = useQueryClient()

  const getActiveAnimatedPreviewQuery = useCallback(
    () =>
      queryClient.getQueryData<AnimatedPreviewValue>([
        'active-animated-preview',
      ]),
    [queryClient]
  )

  const setActiveAnimatedPreviewQuery = useCallback(
    (value: AnimatedPreviewValue | null) => {
      queryClient.setQueryData(['active-animated-preview'], value)
      queryClient.invalidateQueries({
        queryKey: ['active-animated-preview'],
        exact: true,
      })
    },
    [queryClient]
  )

  const setActiveAnimatedPreview =
    useCallback<UseSetActiveAnimatedPreviewReturnType>(
      (value) => {
        setActiveAnimatedPreviewQuery(
          typeof value === 'function'
            ? value(getActiveAnimatedPreviewQuery())
            : value
        )
      },
      [getActiveAnimatedPreviewQuery, setActiveAnimatedPreviewQuery]
    )

  return setActiveAnimatedPreview
}

export default useSetActiveAnimatedPreview
