import { useRef, useState, useCallback, useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'

export type UseIsRiAdTagManagerScriptLoadError = () => boolean

const useIsRiAdTagManagerScriptLoadError: UseIsRiAdTagManagerScriptLoadError =
  () => {
    const queryClient = useQueryClient()
    const queryCache = queryClient.getQueryCache()
    const unsubscribeFnRef = useRef<() => void>()

    const [isScriptError, setIsScriptError] = useState<boolean>(
      () => !!queryClient.getQueryData<boolean>(['RiAdTagManagerScriptError'])
    )

    const updateIsScriptError = useCallback(
      (args: any) => {
        if (
          args?.action?.type === 'invalidate' &&
          args.query.queryKey[0] === 'RiAdTagManagerScriptError'
        ) {
          setIsScriptError(
            !!queryClient.getQueryData<boolean>(['RiAdTagManagerScriptError'])
          )
        }
      },
      [queryClient]
    )

    useEffect(() => {
      setIsScriptError(
        !!queryClient.getQueryData<boolean>(['RiAdTagManagerScriptError'])
      )
      unsubscribeFnRef.current = queryCache.subscribe(updateIsScriptError)

      return () => {
        if (unsubscribeFnRef.current) {
          unsubscribeFnRef.current()
        }
      }
    }, [updateIsScriptError, queryCache, queryClient])

    return isScriptError
  }

export default useIsRiAdTagManagerScriptLoadError
