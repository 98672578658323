import { FunctionComponent } from 'react'
import { createPortal } from 'react-dom'
import { BlickBitesItem } from '@widgets/Video/BlickBites'
import BlickBitesFastlane from './BlickBitesFastlane'

export interface FullScreenVideoPortalProps {
  onClose: () => void
  data: BlickBitesItem[]
  activeIndex: number
  biteId?: string
}

const FullScreenVideoPortal: FunctionComponent<FullScreenVideoPortalProps> = ({
  data,
  activeIndex,
  biteId,
  onClose,
}) => {
  return createPortal(
    <BlickBitesFastlane
      data={data}
      activeIndex={activeIndex}
      biteId={biteId}
      onClose={onClose}
    />,
    document.body
  )
}

export default FullScreenVideoPortal
