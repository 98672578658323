// Define whether the error (depending on error code) is recoverable from or non-recoverable for the user and assign correct translation.
// Depending on this we can decide if we want to display the reload button or not.

export type CategorizedErrorBehaviour = 'recoverable' | 'non-recoverable'
export type CategorizedErrorTranslation =
  | 'browserNonRecoverable'
  | 'networkRecoverable'
  | 'networkNonRecoverable'
  | 'loadingRecoverable'
  | 'loadingNonRecoverable'
  | 'geolocationNonRecoverable'
  | 'playingNonRecoverable'
  | 'defaultNonRecoverable'
const recoverable = 'recoverable'
const nonRecoverable = 'non-recoverable'
const browserNonRecoverable = 'browserNonRecoverable'
const networkRecoverable = 'networkRecoverable'
const networkNonRecoverable = 'networkNonRecoverable'
const loadingRecoverable = 'loadingRecoverable'
const loadingNonRecoverable = 'loadingNonRecoverable'
const geolocationNonRecoverable = 'geolocationNonRecoverable'
const playingNonRecoverable = 'playingNonRecoverable'
const defaultNonRecoverable = 'defaultNonRecoverable'

export const GeoBlockedErrorCode = 232403

export type ErrorCategoryMapValue = {
  behaviour: CategorizedErrorBehaviour
  translationCategory: CategorizedErrorTranslation
} & ({ [key: string]: ErrorCategoryMapValue } | Record<string, unknown>)

// Here we can define the categories of the error based on error code digits. With this approach
// we can go as granular or as generic as we want. The more digits we define, the more specific it gets.
const errorCategoryMap: ErrorCategoryMapValue = {
  behaviour: nonRecoverable,
  translationCategory: defaultNonRecoverable,
  100: {
    behaviour: nonRecoverable,
    translationCategory: loadingNonRecoverable,
    0: {
      behaviour: nonRecoverable,
      translationCategory: loadingNonRecoverable,
      0: {
        behaviour: nonRecoverable,
        translationCategory: loadingNonRecoverable,
        0: {
          behaviour: recoverable,
          translationCategory: loadingRecoverable,
        },
        1: {
          behaviour: recoverable,
          translationCategory: loadingRecoverable,
        },
      },
    },
  },
  101: {
    behaviour: recoverable,
    translationCategory: loadingRecoverable,
  },
  102: {
    behaviour: nonRecoverable,
    translationCategory: loadingNonRecoverable,
    0: {
      behaviour: nonRecoverable,
      translationCategory: loadingNonRecoverable,
      0: {
        behaviour: nonRecoverable,
        translationCategory: loadingNonRecoverable,
        0: {
          behaviour: recoverable,
          translationCategory: loadingRecoverable,
        },
        1: {
          behaviour: recoverable,
          translationCategory: loadingRecoverable,
        },
        2: {
          behaviour: nonRecoverable,
          translationCategory: browserNonRecoverable,
        },
        3: {
          behaviour: nonRecoverable,
          translationCategory: browserNonRecoverable,
        },
        4: {
          behaviour: nonRecoverable,
          translationCategory: browserNonRecoverable,
        },
      },
    },
  },
  104: {
    behaviour: recoverable,
    translationCategory: loadingRecoverable,
  },
  200: {
    behaviour: recoverable,
    translationCategory: loadingRecoverable,
  },
  202: {
    behaviour: nonRecoverable,
    translationCategory: loadingNonRecoverable,
    0: {
      behaviour: nonRecoverable,
      translationCategory: loadingNonRecoverable,
      0: {
        behaviour: nonRecoverable,
        translationCategory: loadingNonRecoverable,
        0: {
          behaviour: recoverable,
          translationCategory: loadingRecoverable,
        },
        1: {
          behaviour: recoverable,
          translationCategory: loadingRecoverable,
        },
        2: {
          behaviour: nonRecoverable,
          translationCategory: browserNonRecoverable,
        },
      },
    },
  },
  203: {
    behaviour: recoverable,
    translationCategory: loadingRecoverable,
    6: {
      behaviour: nonRecoverable,
      translationCategory: loadingNonRecoverable,
    },
  },
  204: {
    behaviour: recoverable,
    translationCategory: loadingRecoverable,
  },
  220: {
    behaviour: recoverable,
    translationCategory: networkRecoverable,
  },
  221: {
    behaviour: recoverable,
    translationCategory: networkRecoverable,
  },
  224: {
    behaviour: recoverable,
    translationCategory: networkRecoverable,
    0: {
      behaviour: recoverable,
      translationCategory: networkRecoverable,
      0: {
        behaviour: recoverable,
        translationCategory: networkRecoverable,
        2: {
          behaviour: nonRecoverable,
          translationCategory: playingNonRecoverable,
        },
        3: {
          behaviour: nonRecoverable,
          translationCategory: browserNonRecoverable,
        },
        5: {
          behaviour: nonRecoverable,
          translationCategory: playingNonRecoverable,
        },
      },
    },
  },
  230: {
    behaviour: recoverable,
    translationCategory: networkRecoverable,
  },
  232: {
    behaviour: nonRecoverable,
    translationCategory: loadingNonRecoverable,
    0: {
      behaviour: recoverable,
      translationCategory: networkRecoverable,
      0: {
        behaviour: recoverable,
        translationCategory: networkRecoverable,
        0: {
          behaviour: recoverable,
          translationCategory: networkRecoverable,
        },
        1: {
          behaviour: recoverable,
          translationCategory: networkRecoverable,
        },
        2: {
          behaviour: recoverable,
          translationCategory: networkRecoverable,
        },
      },
    },
    4: {
      behaviour: nonRecoverable,
      translationCategory: loadingNonRecoverable,
      0: {
        behaviour: nonRecoverable,
        translationCategory: loadingNonRecoverable,
        3: {
          behaviour: nonRecoverable,
          translationCategory: geolocationNonRecoverable,
        },
      },
    },
  },
  233: {
    behaviour: nonRecoverable,
    translationCategory: networkNonRecoverable,
    0: {
      behaviour: nonRecoverable,
      translationCategory: networkNonRecoverable,
      0: {
        behaviour: nonRecoverable,
        translationCategory: networkNonRecoverable,
        0: {
          behaviour: recoverable,
          translationCategory: loadingRecoverable,
        },
        1: {
          behaviour: recoverable,
          translationCategory: networkRecoverable,
        },
        6: {
          behaviour: recoverable,
          translationCategory: networkRecoverable,
        },
      },
    },
  },
  234: {
    behaviour: recoverable,
    translationCategory: loadingRecoverable,
  },
  235: {
    behaviour: recoverable,
    translationCategory: networkRecoverable,
  },
  239: {
    behaviour: recoverable,
    translationCategory: networkRecoverable,
  },
  240: {
    behaviour: recoverable,
    translationCategory: networkRecoverable,
  },
  241: {
    behaviour: nonRecoverable,
    translationCategory: networkNonRecoverable,
    0: {
      behaviour: nonRecoverable,
      translationCategory: networkNonRecoverable,
      0: {
        behaviour: nonRecoverable,
        translationCategory: networkNonRecoverable,
        0: {
          behaviour: recoverable,
          translationCategory: networkRecoverable,
        },
        1: {
          behaviour: recoverable,
          translationCategory: networkRecoverable,
        },
      },
    },
  },
  242: {
    behaviour: nonRecoverable,
    translationCategory: playingNonRecoverable,
  },
  244: {
    behaviour: nonRecoverable,
    translationCategory: playingNonRecoverable,
  },
  246: {
    behaviour: nonRecoverable,
    translationCategory: playingNonRecoverable,
    0: {
      behaviour: nonRecoverable,
      translationCategory: playingNonRecoverable,
      0: {
        behaviour: nonRecoverable,
        translationCategory: playingNonRecoverable,
        5: {
          behaviour: recoverable,
          translationCategory: loadingRecoverable,
        },
        7: {
          behaviour: recoverable,
          translationCategory: loadingRecoverable,
        },
      },
    },
  },
  249: {
    behaviour: recoverable,
    translationCategory: loadingRecoverable,
  },
  340: {
    behaviour: recoverable,
    translationCategory: loadingRecoverable,
  },
}

export default errorCategoryMap
