import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import useClickOutside from '@hooks/useClickOutside'

export type PopoverProps = {
  position:
    | 'top'
    | 'right'
    | 'left'
    | 'bottom'
    | 'top-left'
    | 'top-right'
    | 'top-center'
  children: ReactNode
  className?: string
  trackClickOutside?: boolean
  wrapperRef?: React.RefObject<HTMLDivElement>
  onClickOutside?: () => void
}

const OuterWrapper = styled.div<Pick<PopoverProps, 'position'>>`
  ${({ position }) => css`
    position: absolute;
    z-index: 2; /* the swiper gallery has 1 */

    ${position.includes('top') && 'top: 0;'}
    ${position.includes('left') && 'left: -12px;'}
    ${position.includes('right') && 'right: 0;'}

    ${position === 'top' &&
    css`
      right: 0;
    `}

    ${position === 'right' &&
    css`
      top: 0;
    `}

    ${position === 'bottom' &&
    css`
      bottom: 0;
      right: 0;
    `}

    ${position === 'left' &&
    css`
      top: 0;
    `}
  `}
`

const Wrapper = styled.div<Pick<PopoverProps, 'position'>>`
  ${({ position }) => css`
    position: absolute;

    background-color: #ffffff;
    box-shadow:
      0px 4px 8px 0px #616c7726,
      0px -1px 3px 0px #2e363d1a;

    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #ffffff;

      filter: drop-shadow(0 2px 2px #616c7726);
    }

    ${(position === 'top-right' || position === 'top') &&
    css`
      bottom: 12px;
      right: -12px;

      &:after {
        bottom: -10px;
        right: 14px;
        transform: rotate(0deg);
      }
    `}

    ${position === 'right' &&
    css`
      left: 12px;

      &:after {
        top: 10px;
        left: -14px;

        transform: rotate(90deg);
      }
    `}

    ${position === 'bottom' &&
    css`
      top: 12px;
      right: 0;

      &:after {
        top: -10px;
        right: 14px;

        transform: rotate(180deg);
      }
    `}

    ${position === 'top-left' &&
    css`
      bottom: 12px;
      right: initial;
      left: 0;

      &:after {
        bottom: -10px;
        left: 14px;
        transform: rotate(0deg);
      }
    `}

    ${position === 'left' &&
    css`
      right: 12px;

      &:after {
        top: 10px;
        right: -14px;

        transform: rotate(270deg);
      }
    `}

    ${position === 'top-center' &&
    css`
      bottom: 12px;
      right: calc(50% - 12px);
      transform: translateX(50%);

      &:after {
        bottom: -10px;

        right: calc(50% - 10px);
        transform: rotate(0deg);
      }
    `}
  `}
`

const Popover: FunctionComponent<PopoverProps> = ({
  className,
  position,
  children,
  trackClickOutside = false,
  wrapperRef,
  onClickOutside,
}) => {
  useClickOutside({
    track: trackClickOutside,
    elementRef: wrapperRef,
    onClickOutside,
  })

  return (
    <OuterWrapper position={position}>
      <Wrapper className={className} position={position}>
        {children}
      </Wrapper>
    </OuterWrapper>
  )
}

export default Popover
