import { FunctionComponent } from 'react'
import styled, { css, useTheme } from 'styled-components'
import SvgIcon from '@components/SvgIcon'

export interface PlayIconProps {
  isVisible: boolean
}

const IconWrapper = styled.div<PlayIconProps>`
  ${({ isVisible }) => css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 64px;
    height: 64px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    background: rgba(17, 17, 17, 0.5);

    ${isVisible
      ? css`
          transition: all 100ms ease-in-out;
          visibility: visible;
        `
      : css`
          opacity: 0;
          visibility: hidden;
          transition: all 200ms ease-in-out;
        `}
  `}
`

const PlayIcon: FunctionComponent<PlayIconProps> = ({ isVisible }) => {
  const theme = useTheme()

  return (
    <IconWrapper isVisible={isVisible}>
      <SvgIcon
        iconName="play-solid"
        size={32}
        color={theme.color.primary.primary02}
      />
    </IconWrapper>
  )
}

export default PlayIcon
