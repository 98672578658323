import { useQuery } from '@tanstack/react-query'
import config from '@config'

import { fetchCueLiveTeaser } from '@utils/cueLive'
import { FormattedDate, formatDate } from '@utils/date'

import { ProcessedPost } from './types'
import { useMemo } from 'react'

type UseCueLiveTeaserProps = (props: { cueLiveId?: string }) => ProcessedPost[]

const {
  cueLive: { defaultRefetchInterval },
} = config

const useCueLiveTeaser: UseCueLiveTeaserProps = ({ cueLiveId }) => {
  const { data: cueLiveData } = useQuery({
    queryKey: ['cue-live-teaser', cueLiveId],
    queryFn: () => fetchCueLiveTeaser(cueLiveId as string),
    enabled: !!cueLiveId,
    ...(process.env.STORYBOOK ? {} : { refetchOnMount: 'always' }),
    refetchInterval: (query) =>
      (query.state.data?.refreshRate ?? defaultRefetchInterval) * 1000,
  })

  const posts = useMemo(
    () =>
      cueLiveData?.entries?.reduce((acc, cueLivePost) => {
        acc.push({
          id: cueLivePost.id,
          dateInfo: formatDate(
            new Date(cueLivePost.publishedAt)
          ) as FormattedDate,
          text: cueLivePost.title,
        })
        return acc
      }, [] as ProcessedPost[]) ?? [],
    [cueLiveData]
  )

  return posts
}

export default useCueLiveTeaser
