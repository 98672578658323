import {
  FunctionComponent,
  useCallback,
  memo,
  useState,
  useEffect,
} from 'react'
import { VideoRecommendedItem } from '@hooks/useRecommendations'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import useVideoRecommendations from '@hooks/useVideoRecommendations'
import VideoRecommendationsComponent from '@components/Video/VideoRecommendations'
import {
  changePlayerRecommendationsState,
  getJWPlayer,
  setPlayerRecommendationsAvailable,
} from '@utils/videoPlayer'
import { UseVideoPlayerPropsOutput } from '../types'

export type VideoRecommendationsProps = Pick<
  UseVideoPlayerPropsOutput,
  | 'isBlickTV'
  | 'isOverlay'
  | 'widgetId'
  | 'showRecommendationsOverlay'
  | 'onRecommendationsOverlay'
  | 'hasNextPlaylistItem'
> & {
  isInArticle?: boolean
  hasPlaylist?: boolean
}

const VideoRecommendations: FunctionComponent<VideoRecommendationsProps> = ({
  widgetId,
  isOverlay,
  showRecommendationsOverlay,
  isBlickTV,
  onRecommendationsOverlay,
  hasNextPlaylistItem,
}) => {
  const videoRecommendations = useVideoRecommendations({ isBlickTV })

  const [hideUntilPlayingStatus, setHideUntilPlayingStatus] = useState(false)

  useEffect(() => {
    if (showRecommendationsOverlay?.showRecommendations) {
      setHideUntilPlayingStatus(false)
    }
  }, [showRecommendationsOverlay?.showRecommendations])

  useEffect(() => {
    if (!isBlickTV) {
      const jwPlayer = getJWPlayer(widgetId)

      if (jwPlayer) {
        setPlayerRecommendationsAvailable(
          jwPlayer,
          videoRecommendations?.length > 0
        )
      }
    }
  }, [isBlickTV, videoRecommendations, widgetId])

  const onItemClickTracking = useCallback<
    TrackingFnType<{ item: VideoRecommendedItem }>
  >(
    ({ extraData }) => ({
      event: 'select_content',
      placement: 'article-recommendations-video-embedded',
      link_id: extraData.item.link.href,
    }),
    []
  )

  const onImpressionTracking = useCallback<TrackingFnType>(
    ({ pageMetadata }) => ({
      event: 'content_impression',
      placement: 'article-recommendations-video-embedded',
      link_id: pageMetadata.url,
    }),
    []
  )

  const onImpression = useTracking(onImpressionTracking)
  const onClickItem = useTracking(onItemClickTracking)

  const handleOnClickItem = useCallback(
    (item: VideoRecommendedItem) => {
      onClickItem({ item })
    },
    [onClickItem]
  )

  const handleClickClose = useCallback(() => {
    const jwPlayer = getJWPlayer(widgetId)

    if (jwPlayer) {
      changePlayerRecommendationsState(jwPlayer, 'hidden')
    }
    onRecommendationsOverlay?.({
      showRecommendations: false,
    })
  }, [onRecommendationsOverlay, widgetId])

  const isHidden =
    (hasNextPlaylistItem && showRecommendationsOverlay?.reason === 'end') ||
    !showRecommendationsOverlay?.showRecommendations ||
    hideUntilPlayingStatus

  return !isBlickTV && videoRecommendations?.length ? (
    <VideoRecommendationsComponent
      widgetId={widgetId}
      hidden={isHidden}
      data={videoRecommendations}
      onClickItem={handleOnClickItem}
      onClickClose={handleClickClose}
      onImpression={onImpression}
      isFullScreen={isOverlay}
    />
  ) : null
}

const MemoizedVideoRecommendations = memo(VideoRecommendations)

// it's important here to set the displayName in memo, because it shows better on React Devtools
MemoizedVideoRecommendations.displayName = 'MemoizedVideoRecommendations'

export default MemoizedVideoRecommendations
