import { useCallback, useRef } from 'react'
import styled, { css, useTheme } from 'styled-components'
import { mobileCSS } from '@measures/responsive'
import SvgIcon from '../SvgIcon'
import JSONRenderer from '../JSONRenderer'

interface InlineFormProps {
  setIsVisible?: (isVisible: boolean) => void
  projectApiKey: string
}

const UserSnapWrapper = styled.div`
  position: relative;
`

const UserSnapContainer = styled.div`
  background-color: transparent;
  min-width: 400px;
  min-height: 209px;

  ${mobileCSS(css`
    min-width: 355px;
  `)}
`

const CloseHandler = styled.button`
  ${({
    theme: {
      spacing: { spacing12 },
      color: {
        primary: { primary02 },
      },
    },
  }) => css`
    position: absolute;
    top: ${spacing12};
    right: ${spacing12};
    border: none;
    cursor: pointer;
    padding: 0;
    background-color: ${primary02};
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    animation: fadeIn 0.3s forwards;
    animation-delay: 0.5s;

    @keyframes fadeIn {
      100% {
        opacity: 1;
      }
    }
  `}
`

const InlineForm = ({ setIsVisible, projectApiKey }: InlineFormProps) => {
  const userSnapElementRef = useRef<HTMLDivElement | null>(null)
  const theme = useTheme()

  const closeModal = useCallback(() => {
    setIsVisible?.(false)
  }, [setIsVisible])

  return (
    <UserSnapWrapper>
      <UserSnapContainer ref={userSnapElementRef}>
        <JSONRenderer>
          {{ kind: ['widget', 'tool', 'usersnap'], id: projectApiKey }}
        </JSONRenderer>
      </UserSnapContainer>
      {setIsVisible && (
        <CloseHandler onClick={closeModal}>
          <SvgIcon
            iconName="xmark-large"
            color={theme.color.primary.primary01}
            size={24}
          />
        </CloseHandler>
      )}
    </UserSnapWrapper>
  )
}

export default InlineForm
