import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.li`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    display: flex;
    flex-direction: row;
    margin-top: ${spacing16};
    margin-bottom: ${spacing16};

    &:first-child {
      margin-top: 0;
    }
  `}
`

const Bullet = styled.div`
  ${({
    theme: {
      color: {
        primary: { blickRed },
      },
      spacing: { spacing12 },
    },
  }) => css`
    margin-right: ${spacing12};
    margin-top: 6px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${blickRed};
  `}
`

const Text = styled.div`
  ${({
    theme: {
      typography: {
        bodycopy: {
          small2: { bundledCSS: small2BodycopyCSS },
        },
      },
      color: {
        primary: { primary01 },
      },
    },
  }) => css`
    flex: 1;
    ${small2BodycopyCSS};
    color: ${primary01};
  `}
`

const BulletpointsItem: FunctionComponent<{ children?: ReactNode }> = ({
  children,
}) => (
  <Wrapper>
    <Bullet />
    <Text>{children}</Text>
  </Wrapper>
)

export default BulletpointsItem
