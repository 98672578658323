import { ConfigProps } from '@config/types'

const config = ({}: ConfigProps) => {
  return {
    answserUrl: '/answer',
    questionsUrl: '/questions',
    moreInfoLink: 'https://www.blick.ch/id19711657',
    userSnapFeedbackKey: '898cbe6e-db86-4719-9024-f2c72d9f8163',
  } as const
}

export default config
