import { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'

const StyledTextAndTimestampWrapper = styled.div`
  position: relative;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
`

const TextAndTimestampWrapper: FunctionComponent<{ children?: ReactNode }> = ({
  children,
}) => <StyledTextAndTimestampWrapper>{children}</StyledTextAndTimestampWrapper>

export default TextAndTimestampWrapper
