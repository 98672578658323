import { desktopCSS } from '@measures/responsive'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

export interface TitleProps {
  isFullScreen?: boolean
  children: string
}

const StyledTitle = styled.div<Pick<TitleProps, 'isFullScreen'>>`
  ${({
    theme: {
      color: {
        primary: { primary02: primary02Color },
      },
      typography: {
        headings: {
          small: { bundledCSS: smallHeadingCSS },
          xxsmall: { bundledCSS: xxsmallHeadingCSS },
        },
      },
      utils: { ellipsis },
    },
    isFullScreen,
  }) => css`
    position: relative;
    ${xxsmallHeadingCSS};
    color: ${primary02Color};

    ${isFullScreen &&
    desktopCSS(css`
      ${smallHeadingCSS};
    `)};

    ${ellipsis('100%', 3)};
  `}
`

const Title: FunctionComponent<TitleProps> = ({ isFullScreen, children }) => (
  <StyledTitle isFullScreen={isFullScreen}>{children}</StyledTitle>
)

export default Title
