import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import useViewportTracking from '@hooks/useViewportTracking'
import {
  videoElementClickHandler,
  videoElementImpressionHandler,
} from '@hooks/useVideoTracking/eventMapper'
import { VideoElementTrackingParams } from '@widgets/Video/types'
import useScrollToCliffhanger from '@hooks/useScrollToCliffhanger'
import {
  BlickTVInputProps,
  JWPlayer,
} from '@components/Video/VideoPlayer/JwLibrary/types'
import TrailerVideoOverlayComponent from '@components/Video/Trailer/TrailerVideoOverlay/TrailerVideoOverlayComponent'

interface TrailerVideoOverlayProps {
  player?: JWPlayer
  trackingInfo: {
    videoTitle: string
    videoId: string
  }
  onRecommendationOverlay: BlickTVInputProps['onRecommendationsOverlay']
}

const TrailerVideoOverlay: FunctionComponent<TrailerVideoOverlayProps> = ({
  player,
  trackingInfo,
  onRecommendationOverlay,
}) => {
  const [showTrailerVideoOverlay, setShowTrailerVideoOverlay] =
    useState<boolean>(false)

  useEffect(() => {
    const onVideoComplete = () => {
      setShowTrailerVideoOverlay(true)
    }

    player?.on('complete', onVideoComplete)

    return () => {
      player?.off('complete', onVideoComplete)
    }
  }, [player])

  const onCloseClick = useCallback(() => {
    setShowTrailerVideoOverlay(false)
    onRecommendationOverlay?.({
      reason: 'end',
      showRecommendations: true,
    })
  }, [onRecommendationOverlay])

  const onImpression = useCallback<TrackingFnType>(
    () =>
      videoElementImpressionHandler({
        extraData: {
          ...trackingInfo,
          element: 'endof_trailer',
        },
      }),
    [trackingInfo]
  )

  const trackedOnImpression = useTracking(onImpression)

  const viewportRef = useViewportTracking({
    onImpression: trackedOnImpression,
    track: true,
  })

  const onClick = useCallback<
    TrackingFnType<Pick<VideoElementTrackingParams, 'buttonText'>>
  >(
    ({ extraData }) =>
      videoElementClickHandler({
        extraData: {
          element: 'endof_trailer',
          buttonText: extraData.buttonText,
          ...trackingInfo,
        },
      }),
    [trackingInfo]
  )

  const trackedOnClick = useTracking(onClick)

  const handleScroll = useScrollToCliffhanger(trackedOnClick)

  const handleCloseClick = useCallback(() => {
    trackedOnClick({ buttonText: 'close' })
    onCloseClick?.()
  }, [trackedOnClick, onCloseClick])

  return showTrailerVideoOverlay ? (
    <TrailerVideoOverlayComponent
      ref={viewportRef}
      onCloseClick={handleCloseClick}
      onScroll={handleScroll}
    />
  ) : null
}

export default TrailerVideoOverlay
