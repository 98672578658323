import { FunctionComponent, ReactNode } from 'react'
import ResponsiveImageV2 from '@components/ResponsiveImageV2'
import { ImageRatios, ImageWidths } from '@components/ResponsiveImageV2/types'
import { TeaserToiAPIProps } from '@widgets/TeaserToi'

export type ImageProps = TeaserToiAPIProps['image'] & {
  children?: ReactNode
  className?: string
}

export const RATIOS = {
  desktop: '1_1',
  tablet: '3_4',
  mobile: '3_4',
} as const satisfies ImageRatios

export const WIDTHS = {
  desktop: 632,
  tablet: 632,
  mobile: 430,
  mobileSmall: 320,
} as const satisfies ImageWidths

const Image: FunctionComponent<ImageProps> = ({
  src,
  alt,
  crops,
  children,
  className,
}) => {
  return (
    <ResponsiveImageV2
      src={src}
      alt={alt}
      crops={crops}
      ratios={RATIOS}
      widths={WIDTHS}
      className={className}>
      {children}
    </ResponsiveImageV2>
  )
}

export default Image
