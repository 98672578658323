import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import translate from '@i18n'
import { FunctionComponent, useCallback } from 'react'
import config from '@config'
import useUser from '@hooks/useUser'
import EmbeddedContent from '@components/EmbeddedContent'
import LoginOverlay from '@components/Tool/LoginOverlay'
import useAuthentication from '@hooks/useAuthentication'
import useDisableThirdPartyScripts from '@hooks/useDisableThirdPartyScripts'

const {
  formio: { projectId, formioBaseUrl, formioWidgetUrl },
} = config

interface FormIOProps {
  id: string
  loginRequired: boolean
}

const getFormIOUrl = (formId: string) => {
  const formIOProjectPath = encodeURIComponent(
    formioBaseUrl.replace('{{projectId}}', projectId)
  )

  const formUrl = `${formIOProjectPath}/${formId}`
  const submissionMessage = encodeURIComponent(
    translate('formIO.submissionMessage')
  )

  const formIOUrlParameters =
    formId && `?formId=${formUrl}&submissionMessage=${submissionMessage}`

  return `${formioWidgetUrl}${formIOUrlParameters}`
}

const FormIO: FunctionComponent<FormIOProps> = ({
  id: formId,
  loginRequired,
}) => {
  const userData = useUser()
  const disableThirdPartyScripts = useDisableThirdPartyScripts()

  const { login } = useAuthentication()
  const onLogin = useCallback(
    () => login({ prefix: `FOR_${formId}` }),
    [formId, login]
  )

  if (disableThirdPartyScripts) {
    return null
  }

  const formUrl = getFormIOUrl(formId)
  const isLocked = loginRequired && userData?.email_only !== false

  return isLocked ? (
    <LoginOverlay
      text={`${
        userData?.email_only
          ? translate('formIO.noEmailOnlyRequiredText')
          : translate('formIO.loginRequiredText')
      }`}
      buttonText={`${
        userData?.email_only
          ? translate('formIO.noEmailOnlyRequiredButtonText')
          : translate('formIO.loginRequiredButtonText')
      }`}
      onClick={onLogin}
    />
  ) : (
    <EmbeddedContent
      url={formUrl}
      withSSO={true}
      max-height={500}
      max-height-mobile={510}
    />
  )
}

const widget = {
  kind: ['widget', 'tool', 'formio'],
  component: FormIO,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
