import { ConfigProps } from '@config/types'

const config = ({ commentingUrl }: ConfigProps) =>
  ({
    commentsPerPage: 5,
    textInputCharacterLimit: 750,
    messageDisplayTimeout: 3500,
    baseUrl: commentingUrl,
    reactionsUrl: '/user/article/{articleId}/reactions',
    postUrl: '/community/comment',
    getUrl: '/community/comment',
    getUrlPlain: '/community/comments',
    deleteUrl: '/community/comment/{id}',
    reportUrl: '/community/comment/report',
    reactUrl: '/community/comment/{id}/reaction',
    tokenInfoUrl: '/community/user/tokeninfo',
    getAnswersUrl: '/community/comment/{id}/answers',
    commentingRuleUrl:
      '/community/netiquette-kommentieren-auf-blick-ch-id76037.html',
  }) as const satisfies Record<string, unknown>

export default config
