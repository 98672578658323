import { useRecommendations } from '@hooks/useRecommendations'

import { UseVideoPlayerPropsInput } from '@widgets/Video/types'

export type useVideoRecommendationsInputProps = Partial<
  Pick<UseVideoPlayerPropsInput, 'isBlickTV'>
>

export type useVideoRecommendationsOutputProps = ReturnType<
  typeof useRecommendations
>['embedded-videos']

const useVideoRecommendations = ({
  isBlickTV,
}: useVideoRecommendationsInputProps): useVideoRecommendationsOutputProps =>
  useRecommendations({
    forceDisable: !!isBlickTV,
  })['embedded-videos']

export default useVideoRecommendations
