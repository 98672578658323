import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import translate from '@i18n'
import PrimaryCTAButton from '@components/Buttons/PrimaryCTAButton'
import SecondaryCTAButton from '@components/Buttons/SecondaryCTAButton'

interface VideoSuspendedOverlayProps {
  onReplay: () => void
  onContinue: () => void
}

const StyledVideoSuspendedOverlay = styled.div`
  position: absolute;
  z-index: 2;

  height: 100%;
  width: 100%;
  background: rgba(8, 8, 8, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const VideoSuspendedText = styled.div`
  ${({
    theme: {
      typography: {
        headings: {
          xsmall: { bundledCSS: xsmallHeadingCSS },
        },
      },
      color: {
        primary: { primary02 },
      },
    },
  }) => css`
    color: ${primary02};
    text-align: center;
    ${xsmallHeadingCSS}

    margin-bottom: 24px;
  `}
`

const VideoSuspendedButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
`

const VideoSuspendedOverlay: FunctionComponent<VideoSuspendedOverlayProps> = ({
  onReplay,
  onContinue,
}) => {
  return (
    <StyledVideoSuspendedOverlay>
      <div>
        <VideoSuspendedText>
          {translate('video.resumePlayback')}
        </VideoSuspendedText>
        <VideoSuspendedButtonsContainer>
          <SecondaryCTAButton
            colorVariant="white"
            size="large"
            iconName="arrow-rotate-left"
            onClick={onReplay}>
            {translate('video.resumePlayback.replay')}
          </SecondaryCTAButton>
          <PrimaryCTAButton
            colorVariant="white"
            size="large"
            iconName="play-solid"
            onClick={onContinue}>
            {translate('video.resumePlayback.continue')}
          </PrimaryCTAButton>
        </VideoSuspendedButtonsContainer>
      </div>
    </StyledVideoSuspendedOverlay>
  )
}

export default VideoSuspendedOverlay
