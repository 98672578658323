import { FunctionComponent, useRef, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { ActionPopupTextInputProps } from './types'

interface StyledTextareaProps {
  invalid: boolean
}

const StyledTextarea = styled.textarea<StyledTextareaProps>`
  ${({
    theme: {
      typography: {
        bodycopy: {
          small2: { bundledCSS: bodycopySmall2CSS },
        },
      },
      color: {
        primary: { blickRed },
      },
    },
    invalid,
  }) => {
    return css`
      display: block;
      width: 100%;
      height: 172px;

      padding: 0;
      outline: 0;
      box-sizing: border-box;
      resize: none;
      border: 1px solid ${invalid ? blickRed : 'transparent'};
      background-color: transparent;
      ${bodycopySmall2CSS};
    `
  }}
`

const ActionPopupTextInput: FunctionComponent<ActionPopupTextInputProps> = ({
  invalid,
  onChange,
  textValue,
}) => {
  const ref = useRef<HTMLTextAreaElement | null>(null)
  useEffect(() => {
    ref?.current?.focus()
  }, [])

  return (
    <StyledTextarea
      ref={ref}
      value={textValue}
      invalid={invalid}
      onChange={(e) => onChange(e.target.value)}
    />
  )
}

export default ActionPopupTextInput
