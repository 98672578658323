import { useQueryClient } from '@tanstack/react-query'
import { useState, useCallback, useEffect, useRef } from 'react'
import { AnimatedPreviewValue } from './types'

type UseActiveAnimatedPreviewType = () => AnimatedPreviewValue

const useActiveAnimatedPreview: UseActiveAnimatedPreviewType = () => {
  const queryClient = useQueryClient()
  const unsubscribeFnRef = useRef<() => void>()
  const queryCache = queryClient.getQueryCache()

  const [activeAnimatedPreview, setActiveAnimatedPreview] =
    useState<AnimatedPreviewValue>(() =>
      queryClient.getQueryData<AnimatedPreviewValue>([
        'active-animated-preview',
      ])
    )

  const updateActiveAnimatedPreviewValue = useCallback(
    (args: any) => {
      if (
        args?.action?.type === 'invalidate' &&
        args.query.queryKey[0] === 'active-animated-preview'
      ) {
        setActiveAnimatedPreview(
          queryClient.getQueryData<AnimatedPreviewValue>([
            'active-animated-preview',
          ])
        )
      }
    },
    [queryClient]
  )

  useEffect(() => {
    unsubscribeFnRef.current = queryCache.subscribe(
      updateActiveAnimatedPreviewValue
    )

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [queryCache, updateActiveAnimatedPreviewValue])

  return activeAnimatedPreview
}

export default useActiveAnimatedPreview
