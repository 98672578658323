import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled, { css, useTheme } from 'styled-components'
import { useVirtualizer } from '@tanstack/react-virtual'

import { mobileAndTabletCSS } from '@measures/responsive'
import usePageMetadata from '@hooks/usePageMetadata'
import useTracking from '@hooks/useTracking'
import { videoPlaylistClickHandler } from '@hooks/useVideoTracking/eventMapper'
import JSONRenderer from '@components/JSONRenderer'
import SectionHeader from '@components/SectionHeader'

import { PLAYLIST_DESKTOP_ITEM_HEIGHT } from './utils'
import { PlaylistItem } from './types'
import useHasInvertedColors from '@hooks/useHasInvertedColors'

export interface PlaylistProps {
  title?: string
  items: PlaylistItem[]
}

const StyledContainer = styled.div`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    position: relative;
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    position: relative;
    height: 355.5px;
    gap: ${spacing16};

    ${mobileAndTabletCSS(css`
      display: none;
    `)};
  `}
`

const StyledPlaylistWrapper = styled.div`
  ${({
    theme: {
      color: {
        tertiary: { grey300 },
      },
    },
  }) => css`
    height: 100%;
    width: 100%;
    overflow: auto;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: ${grey300};
      border-radius: 40px;
      height: 100px;
    }
  `}
`

const StyledPlaylistItemWrapper = styled.div<{
  isActive: boolean
}>`
  ${({
    theme: {
      color: {
        tertiary: { grey900: gray900Color },
      },
      spacing: { spacing16 },
    },
    isActive,
  }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${PLAYLIST_DESKTOP_ITEM_HEIGHT - 16};
    margin-bottom: ${spacing16};

    &:hover {
      background-color: ${gray900Color};
    }

    ${isActive &&
    css`
      background-color: ${gray900Color};

      &:hover {
        background-color: ${gray900Color};
      }
    `}
  `}
`

const GradientOverlay = styled.div<{ hasInvertedColors: boolean }>`
  ${({
    theme: {
      color: {
        primary: { primary01: primary01Color, primary02: primary02Color },
      },
    },
    hasInvertedColors,
  }) => css`
    position: absolute;
    bottom: 0;
    height: 40px;
    width: 100%;
    pointer-events: none;
    background: linear-gradient(
      179.62deg,
      ${hasInvertedColors ? primary01Color : primary02Color}00 0.33%,
      ${hasInvertedColors ? primary01Color : primary02Color} 99.67%
    );
  `}
`

const Playlist: FunctionComponent<PlaylistProps> = ({
  title: playlistTitle,
  items,
}) => {
  const pageMetadata = usePageMetadata()
  const hasInvertedColors = useHasInvertedColors()
  const playlistWrapperRef = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const indexOfActiveItem = useMemo(
    () => items?.findIndex((item) => item.articleId === pageMetadata.id) ?? 0,
    [items, pageMetadata.id]
  )
  const offsetForActiveItem = indexOfActiveItem * PLAYLIST_DESKTOP_ITEM_HEIGHT
  const theme = useTheme()

  const [isServer, setIsServer] = useState<boolean>(
    () => typeof window === 'undefined'
  )

  useEffect(() => {
    setIsServer(false)
  }, [])

  useEffect(() => {
    const currentWrapperRef = wrapperRef.current

    if (!isServer && currentWrapperRef) {
      currentWrapperRef.style.top = ''
    }

    return () => {
      if (currentWrapperRef) {
        currentWrapperRef.style.top = ''
      }
    }
  }, [isServer])

  const trackPlaylistClick = useTracking(videoPlaylistClickHandler)

  const onPlaylistItemClick = useCallback(() => {
    trackPlaylistClick({ playlistTitle })
  }, [playlistTitle, trackPlaylistClick])

  const rowVirtualizer = useVirtualizer({
    count: items.length ?? 0,
    getScrollElement: () => playlistWrapperRef.current,
    estimateSize: () => PLAYLIST_DESKTOP_ITEM_HEIGHT,
    overscan: 3,
    initialOffset: offsetForActiveItem,
    initialRect: { width: 318, height: 333 },
  })

  return (
    <StyledContainer>
      <SectionHeader
        title={playlistTitle}
        accentColor={theme.color.primary.blickRed}
        isSubheader={true}
      />
      <StyledPlaylistWrapper ref={playlistWrapperRef}>
        <div
          ref={wrapperRef}
          suppressHydrationWarning={true}
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            width: '100%',
            position: 'relative',
            ...(isServer && indexOfActiveItem > 0
              ? {
                  top: `${-offsetForActiveItem}px`,
                }
              : {}),
          }}>
          {rowVirtualizer.getVirtualItems().map((virtualRow) => {
            const item = items[virtualRow.index]
            const { articleId } = item

            const isActive = articleId === pageMetadata?.id

            return (
              <StyledPlaylistItemWrapper
                key={virtualRow.index}
                style={{
                  transform: `translateY(${virtualRow.start}px)`,
                }}
                isActive={isActive}
                onClick={onPlaylistItemClick}>
                <JSONRenderer>
                  {{
                    ...item,
                    kind: ['teaser-standard-horizontal'],
                  }}
                </JSONRenderer>
              </StyledPlaylistItemWrapper>
            )
          })}
        </div>
      </StyledPlaylistWrapper>
      <GradientOverlay hasInvertedColors={hasInvertedColors} />
    </StyledContainer>
  )
}

export default Playlist
