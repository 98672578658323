import { FunctionComponent, useContext } from 'react'
import styled, { css, useTheme } from 'styled-components'
import WebarchiveCalendarContext, {
  WebarchiveCalendarContextType,
} from '@contexts/webarchiveCalendar'
import SvgIcon from '@components/SvgIcon'
import type { Dayjs } from '@utils/date'

interface ButtonProps {
  isSelectable: boolean
}

const CalendarHeader = styled.div`
  ${({
    theme: {
      color: {
        tertiary: { grey200 },
      },
    },
  }) => css`
    display: flex;
    justify-content: space-between;
    height: 30px;
    background-color: ${grey200};
  `}
`

const Button = styled.div<ButtonProps>`
  ${({
    theme: {
      color: {
        primary: { blickRed },
      },
    },
    isSelectable,
  }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    ${isSelectable &&
    css`
      background-color: ${blickRed};
      cursor: pointer;
    `}
  `}
`

const SelectedMonth = styled.div`
  ${({
    theme: {
      color: {
        primary: { primary01 },
      },
      typography: {
        subheads: {
          small1: { bundledCSS: subheadsSmallCSS },
        },
      },
    },
  }) => css`
    ${subheadsSmallCSS};
    color: ${primary01};
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`

const Header: FunctionComponent = () => {
  const theme = useTheme()
  const calendarProps = useContext(WebarchiveCalendarContext)

  const onMonthChange = (month: Dayjs | null) => () => {
    if (month) {
      return onMonthSelect(month)
    }
  }

  if (!calendarProps || Object.keys(calendarProps).length === 0) {
    return null
  }

  const { nextMonth, previousMonth, selectedMonth, onMonthSelect } =
    calendarProps as WebarchiveCalendarContextType

  return (
    <CalendarHeader>
      <Button isSelectable={true} onClick={onMonthChange(previousMonth)}>
        <SvgIcon
          iconName="chevron-left"
          size={19}
          color={theme.color.primary.primary02}
        />
      </Button>
      <SelectedMonth>
        <span>{`${selectedMonth.month} ${selectedMonth.year}`}</span>
      </SelectedMonth>
      <Button isSelectable={!!nextMonth} onClick={onMonthChange(nextMonth)}>
        <SvgIcon
          iconName="chevron-right"
          size={19}
          color={theme.color.primary.primary02}
        />
      </Button>
    </CalendarHeader>
  )
}

export default Header
