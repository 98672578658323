import debounce from 'lodash.debounce'
import { JWPlayer } from '../types'
import { getPlayerHTMLElement } from '../utils/player'

/**
 * Hides the controls while the user starts scrubbing
 * @param jwplayer JWPlayer
 */
export const initFocusedScrubbingMode = (player: JWPlayer) => {
  const toggleControlBar = debounce(() => {
    const buttonContainer = getPlayerHTMLElement(player, '.jw-button-container')

    if (buttonContainer) {
      buttonContainer.style.visibility = 'visible'
    }
  }, 750)

  player.on('seek', () => {
    const buttonContainer = getPlayerHTMLElement(player, '.jw-button-container')

    if (buttonContainer) {
      buttonContainer.style.visibility = 'hidden'
      toggleControlBar()
    }
  })
}
