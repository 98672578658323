import useTracking, { TrackingFnType } from '@hooks/useTracking'
import { useCallback } from 'react'

type useTrackingUtilsType = ({
  title,
  url,
}: {
  title: string
  url: string
}) => {
  burgerMenuClickTrackingFn: () => void
  navbarClickTrackingFn: () => void
}

const useTrackingUtils: useTrackingUtilsType = ({ title, url }) => {
  const trackingOnBurgerMenuItemClick = useCallback<TrackingFnType>(
    () => ({
      event: 'navigation_click',
      eventCategory: 'Navigation',
      eventLabel: url,
      eventAction: `${title}_expand`,
    }),
    [title, url]
  )

  const trackingOnNavbarMenuItemClick = useCallback<TrackingFnType>(
    () => ({
      event: 'navigation_click',
      eventCategory: 'Navigation',
      eventLabel: url,
      eventAction: `${title}_navbar`,
    }),
    [title, url]
  )

  const burgerMenuClickTrackingFn = useTracking(trackingOnBurgerMenuItemClick)
  const navbarClickTrackingFn = useTracking(trackingOnNavbarMenuItemClick)
  return {
    burgerMenuClickTrackingFn,
    navbarClickTrackingFn,
  }
}

export default useTrackingUtils
