import { css } from 'styled-components'

export const captions = css`
  .jw-captions {
    display: none;
  }
  video::-webkit-media-text-track-container {
    display: none;
  }
`
