import JSONRenderer from '@components/JSONRenderer'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import useViewportTracking from '@hooks/useViewportTracking'
import translate from '@i18n'
import { FunctionComponent, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { LayoutCardStepsType } from '@widgets/LayoutCardSteps/types'
import { bodyStyle } from '@widgets/Body/bodyStyle'

export interface LayoutStepWidget {
  kind: ['layout', 'step']
  content: LayoutCardStepsType[] | LayoutCardStepsType
}

interface LayoutStepProps {
  children: LayoutStepWidget['content']
  stepNumber: number
  totalSteps: number
}

const StyledLayoutStep = styled.div``

const StepTitle = styled.div`
  ${({
    theme: {
      typography: {
        bodycopy: {
          medium2: { bundledCSS: bodycopyMedium2CSS },
        },
      },
      spacing: { spacing8 },
    },
  }) => {
    return css`
      ${bodycopyMedium2CSS};
      line-height: 1.2;
      margin-bottom: ${spacing8};
    `
  }}
`
const Step = styled.span`
  ${({
    theme: {
      typography: {
        headings: {
          small: { bundledCSS: subheadinSmallCSS },
        },
      },
    },
  }) => {
    return css`
      ${subheadinSmallCSS};
    `
  }}
`

const StepTextWrapper = styled.div`
  ${bodyStyle}
`

const LayoutStep: FunctionComponent<LayoutStepProps> = ({
  children,
  stepNumber,
  totalSteps,
}) => {
  const stepImpressionTrackingFn = useCallback<TrackingFnType>(
    () => ({
      event: 'recipe_impression',
      eventCategory: 'recipe',
      eventLabel: stepNumber === 1 ? 'firstStep' : 'lastStep',
      eventAction: 'impression',
    }),
    [stepNumber]
  )

  const handleImpressionTrack = useTracking(stepImpressionTrackingFn)

  const viewportRef = useViewportTracking({
    onImpression: handleImpressionTrack,
    // Track impression only for first and last recipe step
    track: [1, totalSteps].includes(stepNumber),
  })

  return (
    <StyledLayoutStep ref={viewportRef}>
      <StepTitle>
        <Step>
          {translate('recipe.step')} {stepNumber}
        </Step>
        /{totalSteps}
      </StepTitle>
      <StepTextWrapper>
        <JSONRenderer>{children}</JSONRenderer>
      </StepTextWrapper>
    </StyledLayoutStep>
  )
}

export default LayoutStep
