import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { ItemContentProps } from './types'

type ContainerProps = Pick<ItemContentProps, 'type'>

const Container = styled.div<ContainerProps>`
  ${({ type }) => css`
    position: relative;

    &:not(:last-child) {
      ${type === 'comment' &&
      css`
        > div {
          border-bottom: none;
        }
      `}
    }
  `}
`

const Content = styled.div`
  ${({
    theme: {
      color: {
        tertiary: { grey300 },
      },
      spacing: { spacing24 },
    },
  }) => css`
    position: relative;
    box-sizing: border-box;
    border-bottom: 1px solid ${grey300};
    padding-bottom: ${spacing24};
  `}
`

const ItemContent: FunctionComponent<ItemContentProps> = ({
  children,
  type,
}) => (
  <Container type={type}>
    <Content>{children}</Content>
  </Container>
)

export default ItemContent
