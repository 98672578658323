import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { mobileCSS } from '@measures/responsive'
import translate from '@i18n'
import getErrorCategory from './utils'
import SecondaryCTAButton from '@components/Buttons/SecondaryCTAButton'

interface ErrorOverlayProps {
  errorCode: number
  onReload: () => void
}

const StyledErrorOverlayWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 6;
  width: 100%;
  height: 100%;
  background-color: black;
`
const StyledErrorOverlayContainer = styled.div`
  max-height: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
`

const StyledErrorTitle = styled.h1`
  ${mobileCSS(css`
    font-size: 18px;
  `)}
  font-size: 24px;
  font-weight: 700;
  text-align: center;
`
const StyledErrorText = styled.div`
  ${mobileCSS(css`
    font-size: 14px;
  `)}
  font-size: 18px;
  text-align: center;
  font-weight: 400;
  margin-bottom: 24px;
`

const StyledErrorCode = styled.div`
  ${mobileCSS(css`
    font-size: 14px;
  `)}
  font-size: 18px;
  color: white;
  opacity: 50%;
  font-weight: 400;
`

const StyledErrorOverlayButton = styled(SecondaryCTAButton)`
  margin-bottom: 12px;
`

const ErrorOverlay: FunctionComponent<ErrorOverlayProps> = ({
  errorCode,
  onReload,
}) => {
  const isRecoverable = getErrorCategory(errorCode).behaviour === 'recoverable'
  const errorTranslationCategory =
    getErrorCategory(errorCode).translationCategory
  const errorText = {
    __html: translate(`video.error.${errorTranslationCategory}.text`),
  }
  const errorTitle = translate(`video.error.${errorTranslationCategory}.title`)
  const reload = translate('video.reload')
  return (
    <StyledErrorOverlayWrapper>
      <StyledErrorOverlayContainer>
        <StyledErrorTitle>{errorTitle}</StyledErrorTitle>
        <StyledErrorText dangerouslySetInnerHTML={errorText} />
        {isRecoverable ? (
          <StyledErrorOverlayButton
            colorVariant="white"
            size="large"
            iconName="arrow-rotate-left"
            onClick={onReload}>
            {reload}
          </StyledErrorOverlayButton>
        ) : null}
        <StyledErrorCode>ERRORCODE {errorCode}</StyledErrorCode>
      </StyledErrorOverlayContainer>
    </StyledErrorOverlayWrapper>
  )
}

export default ErrorOverlay
