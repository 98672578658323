import { FunctionComponent, ReactNode, useState } from 'react'
import { Date as CookDate } from '@utils/cook/types'
import WebarchiveCalendarContext, {
  WebarchiveCalendarContextType,
} from '@contexts/webarchiveCalendar'
import {
  getCalendarStartEndDate,
  isBeforeTomorrow,
  getFormattedDateWebarchive,
  getNextDay,
  getToday,
  convertToDayjsObj,
  getNextMonth,
  getPreviousMonth,
} from '@utils/date'
import type { Dayjs } from '@utils/date'

interface CalendarContextProviderProps {
  date: CookDate
  children?: ReactNode
}

type CalendarProps = Omit<WebarchiveCalendarContextType, 'onMonthSelect'>

const getMonthDays = (date: Dayjs) => {
  const calendarStartEndDate = getCalendarStartEndDate(date.clone())
  const endDay = calendarStartEndDate.calendarEnd
  const days = []
  let day = calendarStartEndDate.calendarStart
  while (day <= endDay) {
    const isSelectable = isBeforeTomorrow(day.clone())
    const isOfSelectedMonth = day.month() === date.month()
    const dayProps = {
      day: day,
      isSelectable,
      isOfSelectedMonth,
      isSelected: false,
      dayUrl: getFormattedDateWebarchive(day.clone()),
    }
    days.push(dayProps)
    day = getNextDay(day.clone())
  }
  return days
}

const getCalendarByMonth = (elementDate: CookDate, monthClicked?: Dayjs) => {
  const elementDateObject = convertToDayjsObj(elementDate.value)
  const today = getToday()

  const selectedDate = monthClicked || elementDateObject.clone()

  const nextMonth = getNextMonth(selectedDate.clone())
  const previousMonth = getPreviousMonth(selectedDate.clone())

  return {
    nextMonth: nextMonth > today ? null : nextMonth,
    previousMonth: previousMonth,

    selectedMonth: {
      month: selectedDate.format('MMMM'),
      year: selectedDate.format('YYYY'),
    },
    days: getMonthDays(selectedDate.clone()),
    selectedDay: elementDateObject,
  }
}

const CalendarContextProvider: FunctionComponent<
  CalendarContextProviderProps
> = ({ children, date }) => {
  const [calendarProps, setCalendarProps] = useState<CalendarProps>(() =>
    getCalendarByMonth(date)
  )

  const onMonthSelect = (month: Dayjs) => {
    const newProps = getCalendarByMonth(date, month)
    setCalendarProps(newProps)
  }

  if (Object.keys(calendarProps).length === 0) {
    return null
  }

  return (
    <WebarchiveCalendarContext.Provider
      value={{ ...calendarProps, onMonthSelect }}>
      {children}
    </WebarchiveCalendarContext.Provider>
  )
}

export default CalendarContextProvider
