import config from '@config'

const { jwplayer: jwplayerConfig } = config

export const getPlaylistUrl = (
  videoId: string,
  isDaiEnabled: boolean,
  daiAssetKey?: string,
  liveFallbackSource?: string
): string | Record<string, unknown> => {
  if (isDaiEnabled) {
    return {
      file: liveFallbackSource,
      daiSetting: {
        assetKey: daiAssetKey,
      },
    }
  }

  if (videoId && videoId.length > 0) {
    return `${jwplayerConfig.baseUrl.media}${videoId}`
  }

  return `${jwplayerConfig.baseUrl.media}`
}
