import { Link } from '@utils/cook/types'
import useExecuteOnClientNavigation from '@hooks/useExecuteOnClientNavigation'
import { useCallback, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { PageData } from './usePageMetadata'

interface HeaderTopic {
  text: string
  link: Link
}

export type HeaderTopics = HeaderTopic[]

const useHeaderTopics = (): HeaderTopics => {
  const queryClient = useQueryClient()

  const [headerTopics, setHeaderTopics] = useState<HeaderTopics>(
    () =>
      (queryClient.getQueryData<PageData>(['page'])?.topics ||
        []) as HeaderTopics
  )

  const updateHeaderTopics = useCallback((_: any, topics: any) => {
    setHeaderTopics(topics || [])
  }, [])

  useExecuteOnClientNavigation(updateHeaderTopics)

  return headerTopics
}

export default useHeaderTopics
