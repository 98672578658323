import { BlickTVInputProps, JWPlayer } from '../types'
import {
  addPlaylistPreviousButtonDisabled,
  removePlaylistPreviousButtonDisable,
  showPlaylistVideosNavigationButtons,
} from '../utils/controls'
import { getPlayerNextButtons, getPlayerPreviousButtons } from '../utils/player'

/**
 * Init the navigation controls in playlist videos
 * @param player JWPlayer
 * @param shouldPlaylistNextButtonBeShown boolean
 * @param shouldPlaylistPreviousButtonBeShown boolean
 */
export const initPlaylistVideoNavigationControls = (
  player: JWPlayer,
  shouldPlaylistNextButtonBeShown: boolean,
  shouldPlaylistPreviousButtonBeShown: boolean,
  onEnd: BlickTVInputProps['onEnd'],
  onPlayPrevious: BlickTVInputProps['onPlayPrevious']
): void => {
  player.on('firstFrame', () => {
    showPlaylistVideosNavigationButtons(player)
    addPlaylistPreviousButtonDisabled(player)
    getPlayerPreviousButtons(player).forEach((previousButton) => {
      const clone = previousButton.cloneNode(true)
      if (shouldPlaylistPreviousButtonBeShown) {
        clone.addEventListener('click', () => {
          onPlayPrevious?.()
        })
      }
      previousButton.replaceWith(clone)
    })

    if (shouldPlaylistNextButtonBeShown) {
      getPlayerNextButtons(player).forEach((nextButton) => {
        nextButton.addEventListener('click', () => {
          onEnd?.()
        })
      })
    }
    if (shouldPlaylistPreviousButtonBeShown) {
      removePlaylistPreviousButtonDisable(player)
    }
  })
}
