import { User } from '@hooks/useUser'
import * as Sentry from '@sentry/nextjs'
import { QueryClient } from '@tanstack/react-query'
import { FunctionComponent } from 'react'

const captureException = Sentry.captureException

const captureExceptionWithUser = (
  exception: Parameters<typeof captureException>[0],
  queryClient: QueryClient
): ReturnType<typeof captureException> => {
  const user = queryClient.getQueryData<User>(['user'])
  const isUserSwissGeolocated = queryClient.getQueryData<boolean>([
    'isSwissGeolocation',
  ])

  const captureContext = !user
    ? undefined
    : isUserSwissGeolocated
      ? { user: { id: user.sub } }
      : undefined

  return Sentry.captureException(exception, captureContext)
}

const enhanceComponentWithErrorBoundary = (component: FunctionComponent<any>) =>
  Sentry.withErrorBoundary(component, {})

export {
  captureException,
  captureExceptionWithUser,
  enhanceComponentWithErrorBoundary,
}
