import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'

const StyledLive = styled.span`
  ${({
    theme: {
      typography: {
        subheads: {
          xsmall1: { bundledCSS: xsmall1Subhead },
        },
      },
      color: {
        primary: { blickRed, primary02 },
      },
      spacing: { spacing2 },
    },
  }) => {
    return css`
      ${xsmall1Subhead};
      text-transform: uppercase;
      color: ${primary02};
      background-color: ${blickRed};
      padding: 0 ${spacing2} 0 ${spacing2};
      margin: 0;
    `
  }}
`

const LiveLabel: FunctionComponent<{ children?: ReactNode }> = ({
  children,
}) => <StyledLive>{children}</StyledLive>

export default LiveLabel
