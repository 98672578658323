import config from '@config'

const {
  publication: { publication },
} = config

export const isVideoRecommendationsEnabled = (): boolean => {
  const isRomandie = publication === 'romandie'

  return !isRomandie
}
