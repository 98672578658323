import SummaryHeader from '@components/Summary/SummaryHeader'
import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'

const BetaBadge = styled.div`
  ${({
    theme: {
      color: {
        primary: { primary02, blickRed },
      },
      typography: {
        subheads: {
          xsmall2: { bundledCSS: xsmall2 },
        },
      },
      spacing: { spacing4 },
    },
  }) => css`
    color: ${primary02};
    background-color: ${blickRed};
    text-align: center;
    padding: 0 ${spacing4};
    text-transform: none;
    border-radius: ${spacing4};
    height: fit-content;
    position: relative;
    top: -1px;
    ${xsmall2}
  `}
`

const QuestionSummaryRequestView: FunctionComponent<{
  title: string
  children: ReactNode
}> = ({ title, children }) => {
  return (
    <>
      <SummaryHeader prefixIconName="stars">
        {title}
        <BetaBadge>Beta</BetaBadge>
      </SummaryHeader>
      {children}
    </>
  )
}

export default QuestionSummaryRequestView
