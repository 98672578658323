import { useEffect, useMemo, useRef, useCallback, useState } from 'react'
import throttle from 'lodash.throttle'
import { useQuery } from '@tanstack/react-query'

import config from '@config'
import { dayjs } from '@utils/date'
import {
  getJWPlayer,
  playerSeekTo,
  setPlayerGeoBlockedError,
} from '@utils/videoPlayer'
import {
  CurrentTimeParam,
  MetadataParam,
} from '@components/Video/VideoPlayer/JwLibrary/types'
import useIsSwissGeolocation from '@hooks/useIsSwissGeolocation'

import {
  convertPlayerCurrentTimeToTimestamp,
  calculateChapterTimelinePosition,
  normalizePlayerCurrentTimeValue,
  getTimelinePointer,
  newFilteredTimelinePointersArray,
  findActiveChapter,
} from './utils'
import { Chapter, TimelinePointer, ChapterAPIResponse } from './types'

const {
  video: {
    chaptersApiUrl,
    chapters: { dvrWindow, pollingIntervalInMilliseconds },
  },
} = config

export const emptyChapter: Chapter = {
  id: '-1',
  headline: '',
  timestamp: '',
  filename: '',
  length: -1,
  is_geoblocked: false,
}

type UseLivestreamGeoBlockingType = {
  enabled: boolean
  isPlayerReady: boolean
  widgetId: string
}

const getChapters = async (): Promise<Chapter[]> => {
  const res = await fetch(`${chaptersApiUrl}?dvr=${dvrWindow}`)
  const data: ChapterAPIResponse = await res.json()

  return data ? data.reverse() : []
}

const useLivestreamGeoBlocking = ({
  enabled,
  isPlayerReady,
  widgetId,
}: UseLivestreamGeoBlockingType) => {
  const firstTimePlaySuccess = useRef<boolean>(true)
  const [activeChapter, setActiveChapter] = useState<Chapter>()
  const [chapters, setChapters] = useState<Chapter[]>([])
  const [adPlaying, setAdPlaying] = useState<boolean>(false)
  const [timelinePointers, setTimelinePointers] = useState<TimelinePointer[]>(
    []
  )
  const isGeoBlockedError = useRef<boolean>(false)

  const isSwissGeolocation = useIsSwissGeolocation()

  const { data: chaptersData } = useQuery<Chapter[]>({
    enabled: enabled,
    queryKey: ['chapters'],
    queryFn: () => getChapters(),
    refetchInterval: pollingIntervalInMilliseconds,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: true,
  })

  useEffect(() => {
    if (chaptersData) {
      setChapters(chaptersData)
    }
  }, [chaptersData])

  const seekToChapterPosition = useCallback(
    (chapterData?: Chapter) => {
      if (chapterData) {
        const jwPlayer = getJWPlayer(widgetId)

        if (!jwPlayer || !chapterData.timestamp) {
          return
        }

        const playerNewCurrentTimeValue = calculateChapterTimelinePosition(
          chapterData.timestamp,
          timelinePointers
        )

        const seekToPosition = normalizePlayerCurrentTimeValue(
          playerNewCurrentTimeValue,
          jwPlayer
        )

        playerSeekTo(jwPlayer, seekToPosition)
      }
    },
    [widgetId, timelinePointers]
  )

  const setLastChapterAsActive = useCallback(() => {
    if (chapters?.length) {
      const lastChapter = chapters[chapters.length - 1]

      if (lastChapter) {
        setActiveChapter(lastChapter)
      }
    }
  }, [chapters])

  const setGeoBlockedError = useCallback(
    (showError: boolean) => {
      const jwPlayer = getJWPlayer(widgetId)

      if (jwPlayer) {
        setPlayerGeoBlockedError(jwPlayer, showError)

        if (showError) {
          jwPlayer.pause()
        } else {
          seekToChapterPosition(activeChapter)
          jwPlayer.play()
        }
      }

      isGeoBlockedError.current = showError
    },
    [activeChapter, widgetId, seekToChapterPosition]
  )

  // set last chapter as initial value for activeChapter
  useEffect(() => {
    if (!activeChapter?.id) {
      setLastChapterAsActive()
    }
  }, [activeChapter?.id, setLastChapterAsActive])

  useEffect(() => {
    if (!isSwissGeolocation && activeChapter?.id) {
      if (activeChapter?.is_geoblocked) {
        setGeoBlockedError(true)
      } else {
        if (isGeoBlockedError.current) {
          setGeoBlockedError(false)
        }
      }
    }
  }, [
    isSwissGeolocation,
    activeChapter?.id,
    activeChapter?.is_geoblocked,
    setGeoBlockedError,
  ])

  const onPlaySuccess = useCallback(() => {
    if (!firstTimePlaySuccess.current || !enabled) {
      return
    }

    firstTimePlaySuccess.current = false

    const player = getJWPlayer(widgetId)

    if (!player) {
      return
    }

    /** setting the initial pointer based on real
     * world measurements but slightly innacurate
     * - will be replaced when the 1st ID3 tag is parsed*/
    const initialPointer: TimelinePointer = {
      timeString: dayjs().subtract(34, 'seconds').toISOString(),
      timelineSecond: player.getCurrentTime?.() ?? 0,
      source: 'program-date-time',
    }
    setTimelinePointers([initialPointer])

    /**
     * The function looks for the TCOD sent from the studio
     * along with the ID3 tags and updates the existing pointers
     */
    player.on('metadataCueParsed', (event: MetadataParam) => {
      setTimelinePointers((oldArray: TimelinePointer[]) => {
        if (event?.metadataType === 'id3') {
          const timelinePointer = getTimelinePointer(event)
          if (timelinePointer) {
            const existingPointer = oldArray.find(
              (t: TimelinePointer) => t.timelineSecond === event.metadataTime
            )
            if (!existingPointer) {
              return newFilteredTimelinePointersArray(oldArray, timelinePointer)
            }
          }
        }

        return oldArray
      })
    })
  }, [enabled, widgetId])

  const findAndSetActiveChapter = useCallback(
    (currentTime: number) => {
      if (timelinePointers.length > 0) {
        const timestamp = convertPlayerCurrentTimeToTimestamp(
          currentTime,
          timelinePointers
        )

        if (timestamp) {
          const activeChapter = findActiveChapter(timestamp, chapters)

          // if no active chapter found, set the empty chapter as active
          setActiveChapter(activeChapter || emptyChapter)
        }
      }
    },
    [chapters, timelinePointers]
  )

  const onProgress = useCallback(
    (data: CurrentTimeParam) => {
      const player = getJWPlayer(widgetId)

      if (!player || adPlaying || !enabled) {
        return
      }

      const currentTime = player?.getCurrentTime?.() || data.currentTime

      findAndSetActiveChapter(currentTime)
    },
    [adPlaying, enabled, findAndSetActiveChapter, widgetId]
  )

  const onTimeHandler = useMemo(
    () =>
      throttle(
        ({ position }: jwplayer.TimeParam) => {
          onProgress?.({
            currentTime: position,
          })
        },
        1000,
        { trailing: false }
      ),
    [onProgress]
  )

  useEffect(() => {
    // cleanup function
    return () => {
      onTimeHandler?.cancel()
    }
  }, [onTimeHandler])

  useEffect(() => {
    const player = getJWPlayer(widgetId)

    if (!enabled || !isPlayerReady || !player) {
      return
    }

    player.on('time', onTimeHandler)

    return () => {
      player.off('time', onTimeHandler)
    }
  }, [enabled, isPlayerReady, onTimeHandler, widgetId])

  useEffect(() => {
    const player = getJWPlayer(widgetId)

    if (!enabled || !isPlayerReady || !player) {
      return
    }

    player.on('firstFrame', onPlaySuccess)

    return () => {
      player.off('firstFrame', onPlaySuccess)
    }
  }, [enabled, isPlayerReady, onPlaySuccess, widgetId])

  const onAdStarted = useCallback(() => {
    setAdPlaying(true)
  }, [])

  const onAdSkipped = useCallback(() => {
    setAdPlaying(false)
  }, [])

  const onAdEnded = useCallback(() => {
    setAdPlaying(false)
  }, [])

  useEffect(() => {
    const player = getJWPlayer(widgetId)

    if (!enabled || !isPlayerReady || !player) {
      return
    }

    player.on('adImpression', onAdStarted)
    player.on('adSkipped', onAdSkipped)
    player.on('adComplete', onAdEnded)

    return () => {
      player.off('adImpression', onAdStarted)
      player.off('adSkipped', onAdSkipped)
      player.off('adComplete', onAdEnded)
    }
  }, [enabled, isPlayerReady, onAdEnded, onAdSkipped, onAdStarted, widgetId])
}

export default useLivestreamGeoBlocking
