import config from '@config'
import { FunctionComponent } from 'react'
import styled from 'styled-components'

const {
  subscriptions: { footerBannerClassname },
} = config

const StyledPianoFixedDiv = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  z-index: 10000000;
`

const PianoFixedDiv: FunctionComponent = () => (
  // We need a classname to tell Piano where to display the content of the banner
  <StyledPianoFixedDiv className={`${footerBannerClassname}`} />
)

export default PianoFixedDiv
