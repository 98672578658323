import EmbeddedContent from '@components/EmbeddedContent'
import config from '@config'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent } from 'react'
import styled from 'styled-components'
import {
  HeimspielStartingLineupProps,
  ValidHeimspielStartingLineupProps,
} from './types'
import { isValidHeimspielStartingLineupProps } from './utils'

type CreateEmbedUrlType = (
  ...args: [matchId: ValidHeimspielStartingLineupProps['matchId']]
) => string

const {
  heimspiel: { heimspielStartingLineupStaticPageUrl },
} = config

const StyledHeimspielStartingLineup = styled.div`
  min-height: 512px;
`

const createEmbeddedUrl: CreateEmbedUrlType = (matchId) =>
  `${heimspielStartingLineupStaticPageUrl}?matchId=${matchId}`

const HeimspielStartingLineup: FunctionComponent<
  HeimspielStartingLineupProps
> = (props) => {
  if (!isValidHeimspielStartingLineupProps(props)) {
    return null
  }

  const { matchId } = props

  return (
    <StyledHeimspielStartingLineup>
      <EmbeddedContent
        bypassCMP={true}
        maxHeight={500}
        url={createEmbeddedUrl(matchId)}
      />
    </StyledHeimspielStartingLineup>
  )
}

const widget = {
  kind: ['widget', 'heimspiel-startingLineup'],
  component: HeimspielStartingLineup,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
