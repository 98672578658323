import EmbeddedContent from '@components/EmbeddedContent'
import config from '@config'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { HeimspielLineupProps, ValidHeimspielLineupProps } from './types'
import { isValidHeimspielLineupProps } from './utils'

type CreateEmbedUrlType = (
  ...args: [matchId: ValidHeimspielLineupProps['matchId']]
) => string

const {
  heimspiel: { heimspielLineupStaticPageUrl },
} = config

const StyledHeimspielLineup = styled.div`
  min-height: 512px;
`

const createEmbeddedUrl: CreateEmbedUrlType = (matchId) =>
  `${heimspielLineupStaticPageUrl}?matchId=${matchId}`

const HeimspielLineup: FunctionComponent<HeimspielLineupProps> = (props) => {
  if (!isValidHeimspielLineupProps(props)) {
    return null
  }

  const { matchId } = props

  return (
    <StyledHeimspielLineup>
      <EmbeddedContent
        bypassCMP={true}
        maxHeight={500}
        url={createEmbeddedUrl(matchId)}
      />
    </StyledHeimspielLineup>
  )
}

const widget = {
  kind: ['widget', 'heimspiel-lineup'],
  component: HeimspielLineup,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
