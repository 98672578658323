import { JWPlayer, TrackType, CaptionsTrack } from '../types'
import { ConfigParam, VideoMetadataPlaylist } from '../types/jwplayer-extended'
import config from '@config'
import { setElementClass } from './element'

const {
  publication: { publication },
} = config

const STREAM_TYPES = {
  VOD: 'VOD',
  LIVE: 'LIVE',
  DVR: 'DVR',
} as const

const getPlayerHTMLElement = (
  jwplayer: JWPlayer,
  attribute: string,
  strict = true
): HTMLElement => {
  const htmlElement = jwplayer
    .getContainer()
    .querySelector(attribute) as HTMLElement

  if (!htmlElement && strict) {
    throw new Error('Cant find HTML element!')
  }

  return htmlElement
}

const getPlayerHTMLCollection = (
  jwplayer: JWPlayer,
  className: string
): HTMLCollectionOf<HTMLElement> => {
  const playerHTMLCollection = jwplayer
    .getContainer()
    .getElementsByClassName(className) as HTMLCollectionOf<HTMLElement>

  if (!playerHTMLCollection) {
    throw new Error('Cant find HTML collection!')
  }

  return playerHTMLCollection
}

const getPlayerHTMLVideoElement = (jwplayer: JWPlayer): HTMLVideoElement => {
  return getPlayerHTMLElement(jwplayer, 'video.jw-video') as HTMLVideoElement
}

const getPlayerPlaylistTrack = (
  jwplayer: JWPlayer,
  kind: string
): TrackType | undefined => {
  const playlistItem = jwplayer.getPlaylistItem()
  const playlistItemTracks = playlistItem?.tracks?.filter(
    (track: TrackType) => track.kind === kind
  )
  const trackLanguage = publication === 'blick' ? 'de' : 'fr'

  return (
    playlistItemTracks?.find(
      (track: TrackType) => track?.language === trackLanguage
    ) || playlistItemTracks?.[0]
  )
}

const getPlayerHTMLTrackElement = (
  jwplayer: JWPlayer,
  kind: string
): HTMLTrackElement | null => {
  const video = getPlayerHTMLVideoElement(jwplayer)

  return video.querySelector(`track[kind='${kind}']`)
}

const getPlayerNextButtons = (jwplayer: JWPlayer) => {
  return Array.from(getPlayerHTMLCollection(jwplayer, 'jw-icon-next'))
}

const getPlayerPreviousButtons = (jwplayer: JWPlayer) => {
  return Array.from(getPlayerHTMLCollection(jwplayer, 'jw-icon-rewind'))
}

/**
 * Changes overlay fullscreen state
 * @param jwplayer JWPlayer
 * @param overlay boolean
 */
const setOverlay = (jwplayer: JWPlayer, overlay: boolean): void => {
  const container = jwplayer.getContainer()
  const config = jwplayer.getConfig?.()

  if (config?.allowFullscreen) {
    jwplayer.setFullscreen(overlay)
    return
  }

  if (overlay) {
    container.classList.add('btv-flag-fullscreen-overlay', 'jw-flag-fullscreen')
  } else {
    container.classList.remove(
      'btv-flag-fullscreen-overlay',
      'jw-flag-fullscreen'
    )
  }

  jwplayer.trigger('overlay', { overlay })
}

const isOverlay = (jwplayer: JWPlayer): boolean => {
  const container = jwplayer.getContainer()

  return (
    jwplayer.getFullscreen() ||
    container.classList.contains('btv-flag-fullscreen-overlay')
  )
}

const isUserActive = (jwplayer: JWPlayer): boolean => {
  const container = jwplayer.getContainer()

  return !container.classList.contains('jw-flag-user-inactive')
}

const removeUserInactiveClass = (jwplayer: JWPlayer): void => {
  const container = jwplayer.getContainer()
  setElementClass(container, 'jw-flag-user-inactive', false)
}

export type StreamType = (typeof STREAM_TYPES)[keyof typeof STREAM_TYPES]

export type GetConfigValueType = <T = unknown>(
  jwplayer: JWPlayer,
  property: keyof ConfigParam
) => T | undefined

const getConfigValue: GetConfigValueType = (jwplayer, property) => {
  const config = jwplayer.getConfig?.()

  return config?.[property]
}

const getStreamType = (jwplayer: JWPlayer): StreamType | undefined =>
  getConfigValue<StreamType>(jwplayer, 'streamType')

const getDVRSeekLimit = (jwplayer: JWPlayer): number =>
  getConfigValue<number>(jwplayer, 'dvrSeekLimit') || 0

const getMinDvrWindow = (jwplayer: JWPlayer): number =>
  getConfigValue<number>(jwplayer, 'minDvrWindow') || 0

const getCaptionsTrack = (jwplayer: JWPlayer): CaptionsTrack | undefined =>
  getConfigValue<CaptionsTrack>(jwplayer, 'captionsTrack')

const getStreamTypeValue = (jwplayer: JWPlayer): StreamType => {
  //  "meta" event sets "duration" value -> setStreamType
  const duration = jwplayer.getDuration()
  const minDvrWindowConfigValue = getMinDvrWindow(jwplayer)
  const minDvrWindow =
    typeof minDvrWindowConfigValue === 'undefined'
      ? 120
      : minDvrWindowConfigValue

  if (duration === Infinity) {
    return STREAM_TYPES.LIVE
  }

  if (duration < 0) {
    return Math.abs(duration) >= Math.max(minDvrWindow, 0)
      ? STREAM_TYPES.DVR
      : STREAM_TYPES.LIVE
  }

  return STREAM_TYPES.VOD
}

const isLiveStream = (jwplayer: JWPlayer): boolean => {
  const duration = jwplayer.getDuration()

  return duration === Infinity || duration < 0
}

const isDVRStreamType = (jwplayer: JWPlayer): boolean => {
  const streamType = getStreamType(jwplayer)

  return streamType === STREAM_TYPES.DVR
}

const getDVRLive = (jwplayer: JWPlayer): boolean => {
  if (isDVRStreamType(jwplayer)) {
    const position = jwplayer.getPosition()
    const dvrSeekLimit = getDVRSeekLimit(jwplayer)

    return Math.ceil(position) >= -dvrSeekLimit
  }

  return false
}

const goToLiveEdge = (jwplayer: JWPlayer): void => {
  if (isDVRStreamType(jwplayer)) {
    const dvrSeekLimit = getDVRSeekLimit(jwplayer)
    const position = Math.min(jwplayer.getPosition(), -1)

    jwplayer.seek(Math.max(-dvrSeekLimit, position)) // { reason: 'interaction', }
    jwplayer.play() // { reason: 'interaction', }
  }
}

const transformPlaylistItem = (
  item: VideoMetadataPlaylist,
  posterUrl?: string
) => {
  const newPlaylistItem = { ...item }

  if (posterUrl) {
    newPlaylistItem.image = posterUrl
    newPlaylistItem.images = []
  } else {
    newPlaylistItem.images = item.images?.filter((i) => i.type !== 'video/mp4')
  }

  return newPlaylistItem
}

export {
  getPlayerHTMLElement,
  getPlayerNextButtons,
  getPlayerPreviousButtons,
  getPlayerHTMLVideoElement,
  getPlayerPlaylistTrack,
  getPlayerHTMLTrackElement,
  setOverlay,
  isOverlay,
  isUserActive,
  getConfigValue,
  getStreamType,
  getDVRSeekLimit,
  getMinDvrWindow,
  getCaptionsTrack,
  getStreamTypeValue,
  isLiveStream,
  isDVRStreamType,
  getDVRLive,
  goToLiveEdge,
  transformPlaylistItem,
  removeUserInactiveClass,
  STREAM_TYPES,
}
