import { FunctionComponent, useCallback, useRef, useState } from 'react'
import { useTheme } from 'styled-components'

import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { Link } from '@utils/cook/types'
import { LiveEventDataSportTypes } from '@utils/formatters/liveEvent'

import SectionHeader from '@components/SectionHeader'
import { ArrowsVisibilityType, SwiperClass } from '@components/Swiper'
import useViewportType from '@hooks/useViewport/useViewportType'

import CTAButton from '@components/RelatedMatches/CTAButton'
import Tile from '@components/RelatedMatches/Tile'
import useHasBlurryBackground from '@hooks/useHasBlurryBackground'
import RelatedMatchesWrapper from '@components/RelatedMatches/Wrapper'
import Swiper from '@components/RelatedMatches/Swiper'
import NavigationArrows from '@components/RelatedMatches/NavigationArrows'

interface RelatedMatchCommonProps {
  matchId: string
  link: Link
}

export interface RelatedMatchSoccer extends RelatedMatchCommonProps {
  typeOfSport: Extract<LiveEventDataSportTypes, 'soccer'>
}

export interface RelatedMatchHockey extends RelatedMatchCommonProps {
  typeOfSport: Extract<LiveEventDataSportTypes, 'hockey'>
}

export interface RelatedMatchesWidgetApiProps {
  title?: string
  accentColor?: string
  items: RelatedMatchSoccer[] | RelatedMatchHockey[]
  cta: {
    text: string
    link: Link
    backgroundColor: string
  }
}

const RelatedMatches: FunctionComponent<RelatedMatchesWidgetApiProps> = ({
  title,
  accentColor,
  items,
  cta,
}) => {
  const theme = useTheme()
  const viewportType = useViewportType()
  const isDesktop = viewportType === 'desktop'
  const hasBlurryBackground = useHasBlurryBackground()
  const sectionHeaderAccentColor = hasBlurryBackground
    ? theme.color.secondary.green
    : (accentColor ?? theme.color.secondary.green)
  const amountOfTilesToShow = hasBlurryBackground ? 2 : 3

  const swiperRef = useRef<SwiperClass | null>(null)
  const [arrowsVisibility, setArrowsVisibility] =
    useState<ArrowsVisibilityType>()

  const onInit = useCallback(
    (swiper: SwiperClass) => {
      swiperRef.current = swiper
    },
    [swiperRef]
  )

  const onArrowsVisibility = useCallback((arrow: ArrowsVisibilityType) => {
    setArrowsVisibility(arrow)
  }, [])

  if (!title) {
    return null
  }

  return (
    <RelatedMatchesWrapper>
      <SectionHeader
        title={title}
        accentColor={sectionHeaderAccentColor}
        isSubheader={true}>
        <NavigationArrows
          swiperRef={swiperRef}
          arrowsVisibility={arrowsVisibility}
          itemsCount={items?.length ?? 0}
          amountOfTilesToShow={amountOfTilesToShow}
        />
      </SectionHeader>
      <Swiper
        spaceBetween={8}
        spaceTopBottom={0}
        slidesPerGroup={isDesktop ? amountOfTilesToShow : 1}
        onInit={onInit}
        onArrowsVisibility={onArrowsVisibility}
        itemsCount={items?.length ?? 0}
        amountOfTilesToShow={amountOfTilesToShow}
        slides={items?.map((item) => (
          <Tile
            key={`${item.typeOfSport}-${item.matchId}`}
            matchId={item.matchId}
            typeOfSport={item.typeOfSport}
            link={item.link}
          />
        ))}
      />
      {!hasBlurryBackground && <CTAButton {...cta} />}
    </RelatedMatchesWrapper>
  )
}

const widget = {
  kind: ['related-matches'],
  component: RelatedMatches,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
