import { useQueryClient } from '@tanstack/react-query'
import { useState, useCallback, useEffect, useRef } from 'react'

type UseVideoOverlayQueryType = () => {
  isOverlay?: boolean
  showOverlay: () => void
  hideOverlay: () => void
}

const useVideoOverlayQuery: UseVideoOverlayQueryType = () => {
  const queryClient = useQueryClient()

  const [isOverlay, setIsOverlay] = useState<boolean | undefined>(false)
  const unsubscribeFnRef = useRef<() => void>()

  const queryCache = queryClient.getQueryCache()

  const updateIsOverlayValue = useCallback(
    (args: any) => {
      if (
        args?.action?.type === 'invalidate' &&
        args.query.queryKey[0] === 'video-overlay-shown'
      ) {
        setIsOverlay(queryClient.getQueryData<boolean>(['video-overlay-shown']))
      }
    },
    [queryClient]
  )

  useEffect(() => {
    unsubscribeFnRef.current = queryCache.subscribe(updateIsOverlayValue)

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [queryCache, updateIsOverlayValue])

  const setOverlayQuery = useCallback(
    (value: boolean) => {
      queryClient.setQueryData(['video-overlay-shown'], value)
      queryClient.invalidateQueries({
        queryKey: ['video-overlay-shown'],
        exact: true,
      })
    },
    [queryClient]
  )

  const showOverlay = useCallback(() => {
    setOverlayQuery(true)
  }, [setOverlayQuery])

  const hideOverlay = useCallback(() => {
    setOverlayQuery(false)
  }, [setOverlayQuery])

  return { isOverlay, showOverlay, hideOverlay }
}

export default useVideoOverlayQuery
