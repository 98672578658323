import styled, { css } from 'styled-components'

const RelatedMatchesWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    display: grid;
    grid-row-gap: ${spacing16};
  `}
`

export default RelatedMatchesWrapper
