import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { desktopCSS } from '@measures/responsive'
import ItemContainer from './ItemContainer'
import LoadingSpinner from './LoadingSpinner'
import CloseButton from './CloseButton'

export interface PlaceholderProps {
  isLoading?: boolean
  showCloseButton?: boolean
  onClose: () => void
}

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  z-index: 6;
  top: 16px;
  right: 16px;

  ${desktopCSS(css`
    display: none;
  `)}
`

const Placeholder: FunctionComponent<PlaceholderProps> = ({
  isLoading,
  onClose,
}) => {
  return (
    <ItemContainer>
      <StyledCloseButton onClick={onClose} />
      {isLoading && <LoadingSpinner />}
    </ItemContainer>
  )
}

export default Placeholder
