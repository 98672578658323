import { FunctionComponent } from 'react'
import EmbeddedContent from '@components/EmbeddedContent'
import config from '@config'

const {
  socialembeds: { facebookStaticPageUrl },
} = config

interface FacebookComponentProps {
  url: string
}

const FacebookComponent: FunctionComponent<FacebookComponentProps> = ({
  url,
}) => <EmbeddedContent url={`${facebookStaticPageUrl}${url}`} />

export default FacebookComponent
