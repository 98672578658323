import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { CommonLottoData, EuromillionsData, SwisslottoData } from './types'
import starImage from '@assets/images/lotto/star.png'
import replayLogo from '@assets/images/lotto/replay-logo.png'
import { mobileCSS } from '@measures/responsive'

interface NumbersProps {
  numbers: CommonLottoData['numbers']
  adjunctNumbers?: EuromillionsData['adjunct-numbers']
  luckyNumber?: SwisslottoData['luckynumber']
  replayNumber?: SwisslottoData['replay-number']
}

const StyledNumbers = styled.div`
  display: flex;
  margin: 17px 0 13.5px -5px;
  flex-direction: row;
  ${mobileCSS(css`
    flex-direction: column;
    margin-bottom: 21px;
  `)}
`

const FirstRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${mobileCSS(css`
    margin-bottom: 21px;
  `)}
`

interface NumberProps {
  isLucky?: boolean
  isReplay?: boolean
}

const Number = styled.div<NumberProps>`
  ${({
    theme: {
      color: {
        lotto: { lottoLucky, lottoJackpot },
        primary: { primary02 },
      },
      typography: {
        headings: {
          xsmall: { bundledCSS: headingXSmallCSS },
        },
      },
    },
    isLucky,
    isReplay,
  }) => css`
    ${headingXSmallCSS};
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: ${isReplay
      ? lottoJackpot
      : isLucky
        ? lottoLucky
        : primary02};
    margin-left: 5px;
    ${isReplay &&
    css`
      color: ${primary02};
      margin-left: 30px;
      ${mobileCSS(css`
        margin-left: 5px;
      `)}
    `}
  `}
`

const AdjunctNumber = styled.div`
  ${({
    theme: {
      color: {
        tertiary: { grey100 },
      },
      typography: {
        headings: {
          xsmall: { bundledCSS: headingXSmallCSS },
        },
      },
    },
  }) => css`
    ${headingXSmallCSS};
    display: flex;
    width: 34px;
    height: 34px;
    justify-content: center;
    align-items: center;
    margin-left: 7px;
    background-color: ${grey100};
    background-image: url(${starImage});
    background-repeat: no-repeat;
    background-size: 33px;
  `}
`

const StyledReplayLogo = styled.img`
  margin-left: 7px;
`

const Numbers: FunctionComponent<NumbersProps> = ({
  numbers,
  adjunctNumbers,
  luckyNumber,
  replayNumber,
}) => {
  return (
    <StyledNumbers>
      <FirstRowContainer>
        {numbers.map((number, index) => (
          <Number key={`number-${index}`}>{number}</Number>
        ))}
        {adjunctNumbers?.map((adjunctNumber, index) => (
          <AdjunctNumber key={`adjunctNumber-${index}`}>
            {adjunctNumber}
          </AdjunctNumber>
        ))}
        {luckyNumber && <Number isLucky>{luckyNumber}</Number>}
      </FirstRowContainer>
      {replayNumber && (
        <FirstRowContainer>
          <Number isReplay>{replayNumber}</Number>
          <StyledReplayLogo
            width="60"
            height="26"
            src={replayLogo}
            alt="Replay logo"
          />
        </FirstRowContainer>
      )}
    </StyledNumbers>
  )
}

export default Numbers
