import { FunctionComponent } from 'react'
import styled, { css, useTheme } from 'styled-components'
import SvgIcon from '@components/SvgIcon'
import { TargetContentType } from '@utils/cook/types'
import translate from '@i18n'
import { formatDuration, isLiveStream } from '@widgets/Video/utils'

export interface VideoLabelProps {
  isInVideoPoster?: boolean
  targetContentType?: TargetContentType
  duration: number
  className?: string
}

const StyledVideoLabel = styled.div`
  ${({
    theme: {
      color: {
        primary: { blickRed: blickRedColor, primary02: primary02Color },
      },
      spacing: { spacing4, spacing8, spacing96 },
      typography: {
        subheads: {
          xsmall1: { bundledCSS: xsmall1SubheadingCSS },
        },
      },
    },
  }) => css`
    position: relative;
    user-select: none;
    display: inline-flex;
    background-color: ${blickRedColor};
    border-radius: ${spacing96};
    padding: 6px ${spacing8};
    ${xsmall1SubheadingCSS};
    color: ${primary02Color};
    text-transform: uppercase;
    column-gap: ${spacing4};
  `}
`

const VideoLabel: FunctionComponent<VideoLabelProps> = ({
  isInVideoPoster = false,
  targetContentType,
  duration,
  className,
}) => {
  const theme = useTheme()
  const isLive = isLiveStream(duration)
  const isVideoOnly =
    targetContentType === 'video' || targetContentType === 'bite'
  const shouldShowPlayIcon = !isInVideoPoster && (isLive || isVideoOnly)

  return (
    <StyledVideoLabel className={className}>
      {shouldShowPlayIcon && (
        <SvgIcon
          iconName="video-play-sharp-filled"
          color={theme.color.primary.primary02}
          size={14}
        />
      )}
      {isLive
        ? translate('video.liveLabel')
        : !isVideoOnly && !isInVideoPoster
          ? translate('video.withVideoLabel')
          : formatDuration(duration)}
    </StyledVideoLabel>
  )
}

export default VideoLabel
