import IconButton from '@components/Buttons/IconButton'
import useBurgerMenuVisibility from '@hooks/useBurgerMenuVisibility'
import useNavigation from '@hooks/useNavigation'
import useToggleMenuVisibility from '@hooks/useToggleMenuVisibility'
import { desktopCSS, mobileAndTabletCSS } from '@measures/responsive'
import { FunctionComponent } from 'react'
import styled, { css, useTheme } from 'styled-components'
import NavigationHeader from './NavigationHeader'
import FirstLevelItem from './FirstLevelItem'
import GDPRLink from './GDPRLink'
import useIsImmersiveHeaderActive from '@hooks/useIsImmersiveHeaderActive'

interface NavigationProps {
  isVisible: boolean
}

const Navigation = styled.nav<NavigationProps>`
  ${({
    isVisible,
    theme: {
      color: {
        primary: { primary02 },
      },
      animation: {
        transition: { default: defaultTransition },
      },
    },
  }) => css`
    z-index: 2;
    position: fixed;
    top: 0;
    bottom: 0;
    width: calc(100% - 78px);
    ${desktopCSS(css`
      width: 400px;
    `)}
    overflow-y: auto;
    background-color: ${primary02};
    padding-top: 0;
    left: 0;
    transition: ${defaultTransition};
    transform: translate3d(${isVisible ? 0 : '-100%'}, 0, 0);
  `}
`

const MenuContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
`

interface OverlayProps {
  isVisible: boolean
}

const Overlay = styled.div<OverlayProps>`
  ${({
    theme: {
      animation: {
        transition: { fast: fastTransition, default: defaultTransition },
      },
      color: {
        primary: { primary01 },
      },
    },
    isVisible,
  }) => css`
    position: absolute;
    top: 0;
    opacity: 0;
    transition: opacity ${fastTransition};
    width: 100vw;
    height: 100vh;
    left: -100vw;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: ${primary01};

    ${isVisible &&
    css`
      opacity: 0.7;
      left: 0;
      transition: opacity ${defaultTransition};
    `}
  `}
`

const StyledIconButton = styled(IconButton)<{
  isImmersiveHeaderActive: boolean
}>`
  ${({
    theme: {
      color: {
        primary: { primary02: primary02Color, blickRed: blickRedColor },
      },
    },
    isImmersiveHeaderActive,
  }) => css`
    ${desktopCSS(css`
      width: 40px;
      height: 40px;
    `)};

    ${isImmersiveHeaderActive &&
    css`
      ${mobileAndTabletCSS(css`
        > svg {
          fill: ${primary02Color};
        }
      `)}
    `}

    &:hover {
      > svg {
        fill: ${blickRedColor};
      }
    }
  `}
`

const BurgerMenu: FunctionComponent = () => {
  const theme = useTheme()
  const navigationItems = useNavigation()
  const burgerMenuVisible = useBurgerMenuVisibility()
  const isImmersiveHeaderActive = useIsImmersiveHeaderActive()

  const { openBurgerMenu, closeBurgerMenu } = useToggleMenuVisibility()

  return (
    <>
      <StyledIconButton
        ariaLabel="Menu"
        onClick={openBurgerMenu}
        iconName="bars"
        iconSize={24}
        buttonSize={48}
        iconColor={theme.color.primary.primary01}
        isImmersiveHeaderActive={isImmersiveHeaderActive}
      />
      <Navigation isVisible={burgerMenuVisible}>
        <MenuContainer>
          <NavigationHeader onClose={() => closeBurgerMenu(false)} />
          <div>
            {navigationItems.map((item) => (
              <FirstLevelItem key={item.title} {...item} />
            ))}
          </div>
          <GDPRLink />
        </MenuContainer>
      </Navigation>
      <Overlay
        isVisible={burgerMenuVisible}
        onClick={() => closeBurgerMenu(false)}
      />
    </>
  )
}

export default BurgerMenu
