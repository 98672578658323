import styled, { css } from 'styled-components'

const TextContent = styled.div`
  ${({
    theme: {
      typography: {
        bodycopy: {
          xsmall: { bundledCSS: bodycopyXSmallCSS },
        },
      },
      spacing: { spacing8 },
    },
  }) => css`
    ${bodycopyXSmallCSS};
    margin-top: ${spacing8};
  `}
`

export default TextContent
