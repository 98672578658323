import { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import Blink from '@components/Blink'
import { Link } from '@utils/cook/types'
import { isLink } from '@widgets/Author/utils'

interface ClickableContainerProps {
  link?: Link
  children: ReactNode
  className?: string
}

const StyledBlink = styled(Blink)`
  text-decoration: unset;
  color: unset;
`

const ClickableContainer: FunctionComponent<ClickableContainerProps> = ({
  link,
  className,
  children,
}) => {
  const shouldDisplayLink = !!link && isLink(link)
  if (!shouldDisplayLink) {
    return <>{children}</>
  }
  return (
    <StyledBlink className={className} {...link}>
      {children}
    </StyledBlink>
  )
}

export default ClickableContainer
