import config from '@config'

const {
  socialembeds: { blickplusTitleAddition },
} = config

export const getFullHtmlTitle = (htmlTitle: string, type: string) =>
  htmlTitle &&
  `${htmlTitle}${type === 'article' && htmlTitle.length < 68 ? ' - Blick' : ''}`

export const getSocialMediaTitle = ({
  openGraphTitle,
  teaserTitle,
  htmlTitle,
  isPlus,
}: {
  openGraphTitle: string | undefined
  teaserTitle: string | undefined
  htmlTitle: string
  isPlus: boolean
}) => {
  return `${openGraphTitle || teaserTitle || htmlTitle}${
    isPlus ? ` ${blickplusTitleAddition}` : ''
  }`
}
