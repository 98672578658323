import { useCallback, useEffect, useState } from 'react'
import { JWPlayer, TrackType } from '../../types'
import { getPlayerPlaylistTrack } from '../../utils/player'
import { loadVTTCuesFromFile } from './vttCuesFileLoader'

export type UseVTTCuesFromPlaylistTrack = ({
  trackKind,
  player,
}: {
  trackKind: 'captions' | 'metadata'
  player: JWPlayer
}) => VTTCue[] | undefined

const useVTTCuesFromPlaylistTrack: UseVTTCuesFromPlaylistTrack = ({
  trackKind,
  player,
}) => {
  const [vttCues, setVttCues] = useState<VTTCue[]>()
  const [playlistTrack, setPlaylistTrack] = useState<TrackType>()

  const initPlaylistTrack = useCallback(() => {
    const track = getPlayerPlaylistTrack(player, trackKind)

    if (track) {
      setPlaylistTrack(track)
    }
  }, [player, trackKind])

  useEffect(() => {
    // playlist track might be already available on mount
    // otherwise load it on 'captionsList' event
    initPlaylistTrack()
  }, [initPlaylistTrack])

  useEffect(() => {
    player.on('captionsList', initPlaylistTrack)

    return () => {
      player.off('captionsList', initPlaylistTrack)
    }
  }, [initPlaylistTrack, player])

  useEffect(() => {
    async function fetchData() {
      if (playlistTrack?.file) {
        const vttCuesFromFile = await loadVTTCuesFromFile({
          fileUrl: playlistTrack.file,
        })

        if (vttCuesFromFile) {
          setVttCues(vttCuesFromFile)
        }
      }
    }

    fetchData()
  }, [playlistTrack?.file])

  return vttCues
}

export default useVTTCuesFromPlaylistTrack
