import { FunctionComponent, ReactNode, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

export type TextTracksPortalProps = {
  children?: ReactNode
  hidden?: boolean
  jwCaptionsNode: HTMLElement
}

function insertAfter(referenceNode: HTMLElement, newNode: HTMLElement) {
  referenceNode.parentNode?.insertBefore(newNode, referenceNode.nextSibling)
}

const initContainer = () => {
  const container = document.createElement('div')

  container.style.cssText = `
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    overflow: hidden;
  `

  return container
}

const setContainerVisibility = (
  container: HTMLDivElement,
  visible: boolean
) => {
  container.style.visibility = visible ? 'visible' : 'hidden'
}

const TextTracksPortal: FunctionComponent<TextTracksPortalProps> = ({
  children,
  jwCaptionsNode,
  hidden,
}) => {
  const [textTracksContainerNode] = useState(initContainer)
  const [renderPortal, setRenderPortal] = useState(false)

  useEffect(() => {
    if (jwCaptionsNode) {
      insertAfter(jwCaptionsNode, textTracksContainerNode)

      setRenderPortal(true)
    }

    return () => {
      if (jwCaptionsNode?.parentNode?.contains(textTracksContainerNode)) {
        jwCaptionsNode?.parentNode?.removeChild(textTracksContainerNode)
      }
    }
  }, [textTracksContainerNode, jwCaptionsNode])

  useEffect(() => {
    setContainerVisibility(textTracksContainerNode, !hidden)
  }, [textTracksContainerNode, hidden])

  return renderPortal
    ? ReactDOM.createPortal(children, textTracksContainerNode)
    : null
}

export default TextTracksPortal
