import Blink from '@components/Blink'
import useCurrentSection from '@hooks/useCurrentSection'
import { MainMenuItemLevel3 } from '@hooks/useNavigation'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import useTrackingUtils from '../../useTrackingUtils'

const ItemWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing20, spacing40 },
    },
  }) => css`
    height: 48px;
    padding: 14px ${spacing20} 14px ${spacing40};
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
  `}
`

interface StyledBlinkProps {
  isActive: boolean
}

const StyledBlink = styled(Blink)<StyledBlinkProps>`
  ${({
    theme: {
      color: {
        primary: { blickRed, primary01 },
      },
      typography: {
        bodycopy: {
          small2: { bundledCSS: small2BodycopyCSS },
        },
      },
    },
    isActive,
  }) => css`
    color: ${isActive ? blickRed : primary01};
    text-decoration: none;
    box-sizing: border-box;
    flex: 1 auto;
    ${small2BodycopyCSS}
  `}
`

const ThirdLevelItem: FunctionComponent<MainMenuItemLevel3> = ({
  title,
  link,
}) => {
  const { isActive } = useCurrentSection(link.title || '')

  const { burgerMenuClickTrackingFn } = useTrackingUtils({
    title,
    url: link.href,
  })

  return (
    <ItemWrapper>
      <StyledBlink
        {...link}
        isActive={isActive}
        onClick={burgerMenuClickTrackingFn}>
        {title}
      </StyledBlink>
    </ItemWrapper>
  )
}

export default ThirdLevelItem
