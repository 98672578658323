import useHasInvertedColors from '@hooks/useHasInvertedColors'
import useIsInHerotellingPage from '@hooks/useIsInHerotellingPage'
import { useContext } from 'react'
import EndOfArticleContext from '@contexts/endOfArticle'

const useIsDarkModeInSocialButton = () => {
  const isEndOfArticle = useContext(EndOfArticleContext)
  const isHerotellingArticle = useIsInHerotellingPage()
  const hasInvertedColors = useHasInvertedColors()

  return hasInvertedColors || (isHerotellingArticle && !isEndOfArticle)
}

export default useIsDarkModeInSocialButton
