import { FunctionComponent, useCallback, useState } from 'react'
import { TextTrackProps, GraphicsObject } from '../../types'
import { parseGraphicsValue } from '../../utils/graphics'
import useOnMetaCueChange from '../TextTracks/useOnMetaCueChange'
import GraphicsElements from './GraphicsElements'

type LiveGraphicsProps = Omit<TextTrackProps, 'onCaptionsCueChange'>

const LiveGraphics: FunctionComponent<LiveGraphicsProps> = ({
  player,
  onGraphicsCueChange,
}) => {
  const [graphicsObject, setGraphicsObject] = useState<GraphicsObject>({})

  const onCueChange = useCallback<(text?: string) => void>(
    (text) => {
      const graphics = parseGraphicsValue(text)

      setGraphicsObject(graphics)
      onGraphicsCueChange?.(graphics)
    },
    [onGraphicsCueChange]
  )

  useOnMetaCueChange({ player, onCueChange })

  return <GraphicsElements graphicsObject={graphicsObject} />
}

export default LiveGraphics
