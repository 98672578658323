import { FunctionComponent } from 'react'
import config from '@config'
import SchemaHTMLElement from '@components/SchemaHTMLElement'

const {
  schema: { personSchemaUrl, organizationId },
  backend: { imageUrl },
} = config

interface PersonSchemaProps {
  url?: string
  name: string
  src?: string
  description?: string
  socialMediaLinks?: string[]
}

const PersonSchema: FunctionComponent<PersonSchemaProps> = (props) => {
  const { url, name, src, description, socialMediaLinks } = props

  return (
    <SchemaHTMLElement
      scriptKey={`person-schema-${url}`}
      schemaObject={{
        '@type': 'Person',
        id: `${personSchemaUrl}${url || ''}`,
        name,
        ...(src ? { image: `${imageUrl}${src}` } : {}),
        ...(description ? { description } : {}),
        worksFor: {
          '@id': organizationId,
        },
        ...(socialMediaLinks ? { sameAs: socialMediaLinks } : {}),
      }}
    />
  )
}

export default PersonSchema
