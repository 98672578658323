import { useCallback, useEffect, useRef, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'

export interface BrandingDayOptionsType {
  clickUrl: string
  bgColor?: string
  bgImgUrl?: string
  bgFixed?: boolean
  paddingTop?: number
  bgCover?: boolean
  hideHpa?: boolean
  siteShift?: boolean
}

export interface BrandingDayQueryData {
  options: BrandingDayOptionsType
}

type UseBrandingDayType = () => {
  isVisible: boolean
  options?: BrandingDayOptionsType
}

const useBrandingDay: UseBrandingDayType = () => {
  const queryClient = useQueryClient()
  const unsubscribeFnRef = useRef<() => void>()
  const queryCache = queryClient.getQueryCache()
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [options, setOptions] = useState<BrandingDayOptionsType | undefined>()

  const updateBrandingDayVisibility = useCallback(
    (args: any) => {
      if (args.query.queryKey[0] === 'branding-day-data') {
        if (args.type === 'updated') {
          setIsVisible(true)
          const data = queryClient.getQueryData<BrandingDayQueryData>([
            'branding-day-data',
          ])
          setOptions(data?.options)
        } else if (args.type === 'removed') {
          setIsVisible(false)
          setOptions(undefined)
        }
      }
    },
    [queryClient]
  )

  useEffect(() => {
    unsubscribeFnRef.current = queryCache.subscribe(updateBrandingDayVisibility)

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [queryCache, updateBrandingDayVisibility])

  return {
    isVisible,
    options,
  }
}

export default useBrandingDay
