import { useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import useTracking, { TrackingFnType } from './useTracking'

type UseToggleMenuVisibilityType = () => {
  openBurgerMenu: () => void
  toggleUserMenu: () => void
  closeBurgerMenu: (resetScrollPosition: boolean) => void
}

const useToggleMenuVisibility: UseToggleMenuVisibilityType = () => {
  const queryClient = useQueryClient()

  const openBurgerMenuTrackingFn = useCallback<TrackingFnType>(
    () => ({
      event: 'navigation_expand',
      eventCategory: 'Navigation',
      eventAction: 'expand',
      eventLabel: '',
    }),
    []
  )

  const onOpenBurgerMenuClick = useTracking(openBurgerMenuTrackingFn)

  const openBurgerMenu = useCallback(() => {
    // We must close the user menu...
    queryClient.setQueryData(['userMenuVisible'], false)
    queryClient.invalidateQueries({ queryKey: ['userMenuVisible'] })

    // ...and track the opening of the burger menu
    onOpenBurgerMenuClick()

    queryClient.setQueryData(['burgerMenuVisibility'], {
      isVisible: true,
      resetScrollPosition: true,
    })
    queryClient.invalidateQueries({ queryKey: ['burgerMenuVisibility'] })
  }, [queryClient, onOpenBurgerMenuClick])

  const closeBurgerMenu = useCallback(() => {
    queryClient.setQueryData(['burgerMenuVisibility'], {
      isVisible: false,
      resetScrollPosition: false,
    })
    queryClient.invalidateQueries({ queryKey: ['burgerMenuVisibility'] })
  }, [queryClient])

  const toggleUserMenu = useCallback(() => {
    const userMenuVisible = queryClient.getQueryData(['userMenuVisible'])
    queryClient.setQueryData(['userMenuVisible'], !userMenuVisible)
    queryClient.invalidateQueries({ queryKey: ['userMenuVisible'] })
  }, [queryClient])

  return {
    openBurgerMenu,
    toggleUserMenu,
    closeBurgerMenu,
  }
}

export default useToggleMenuVisibility
