import styled, { css } from 'styled-components'
import { mobileCSS } from '@measures/responsive'
import Blink from '@components/Blink'

const TeaserToiWrapper = styled(Blink)`
  display: block;
  appearance: none;
  text-decoration: none;

  ${mobileCSS(css`
    margin-left: -16px;
    margin-right: -16px;
  `)};
`

export default TeaserToiWrapper
