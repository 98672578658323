import { FunctionComponent, useEffect } from 'react'
import styled, { css } from 'styled-components'
import config from '@config'
import { CommentingConfirmationMessageProps } from './types'

const {
  community: { messageDisplayTimeout },
} = config

const ConfirmationMessage = styled.div`
  ${({
    theme: {
      color: {
        tertiary: { grey100 },
      },
      spacing: { spacing16 },
      typography: {
        bodycopy: {
          small2: { bundledCSS: bodycopySmall2CSS },
        },
      },
    },
  }) => {
    return css`
      box-sizing: border-box;
      position: relative;
      margin-top: -1px;
      padding: ${spacing16};
      background-color: ${grey100};
      ${bodycopySmall2CSS};
    `
  }}
`

const CommentingConfirmationMessage: FunctionComponent<
  CommentingConfirmationMessageProps
> = ({ onTimeout, children }) => {
  useEffect(() => {
    const timeout = setTimeout(onTimeout, messageDisplayTimeout)
    return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <ConfirmationMessage>{children}</ConfirmationMessage>
}

export default CommentingConfirmationMessage
