import translate from '@i18n'

const getSeparatorChar = (index: number, lastIndex: number) =>
  index === lastIndex
    ? ' '
    : index === lastIndex - 1
      ? ` ${translate('and')} `
      : ', '

export { getSeparatorChar }
