import styled, { css } from 'styled-components'
import SvgIcon from '@components/SvgIcon'
import { FunctionComponent } from 'react'
import translate from '@i18n'

interface ArrowButtonProps {
  onClick: () => void
  isExpanded: boolean
  iconColor: string
}

type StyledSvgIconProps = Pick<ArrowButtonProps, 'isExpanded'>

const StyledButton = styled.button`
  ${({
    theme: {
      spacing: { spacing12 },
    },
  }) => css`
    border: none;
    cursor: pointer;
    background-color: transparent;
    padding: ${spacing12};
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`

const StyledSvgIcon = styled(SvgIcon)<StyledSvgIconProps>`
  ${({
    isExpanded,
    theme: {
      animation: {
        transition: { fast: fastTransition },
      },
    },
  }) => css`
    transform: rotate(${!isExpanded ? 0 : 180}deg);
    transition: transform ${fastTransition} ease;
  `}
`

const ArrowButton: FunctionComponent<ArrowButtonProps> = ({
  isExpanded,
  onClick,
  iconColor,
}) => (
  <StyledButton
    onClick={onClick}
    aria-label={translate(
      `arrowButton.${isExpanded ? 'collapse' : 'expand'}Menu`
    )}>
    <StyledSvgIcon
      isExpanded={isExpanded}
      iconName="chevron-down"
      size={24}
      color={iconColor}
    />
  </StyledButton>
)

export default ArrowButton
