import { useState, useCallback, useEffect, useMemo } from 'react'
import config from '@config'

const {
  video: { videoMetadataUrl },
} = config

interface VideoSource {
  file: string
  type: string
  width: number
}

interface VideoMetadata {
  title: string
  description?: string
  kind?: string
  playlist?: VideoMetadataPlaylist[]
}

interface VideoMetadataPlaylist {
  sources: VideoSource[]
}

type VideoMetadataResponse = VideoMetadata

type UseVideoSourceFetchingProps = (args: {
  videoId: string
  enabled?: boolean
  width?: number
}) => {
  fetchedSourceUrl?: string
}

const fetchVideoMetadata = (jwVideoId: string) =>
  fetch(`${videoMetadataUrl}${[jwVideoId]}`)
    .then((response) => response.json())
    .catch((error) => console.error(error))

const getFileSource = (
  sources: VideoSource[],
  width: number
): string | undefined => {
  if (sources.length === 1) {
    return sources[0].file
  }

  const videoSources = sources?.filter(
    (s: VideoSource) => s.type === 'video/mp4'
  )
  const exactWidthSource = videoSources?.find(
    (source) => source.width === width
  )
  const smallerWidthSource = videoSources
    ?.sort((a, b) => a.width - b.width)
    .find((source) => source.width < width)

  return (
    exactWidthSource?.file || smallerWidthSource?.file || videoSources[0]?.file
  )
}

const useVideoSourceFetching: UseVideoSourceFetchingProps = ({
  videoId,
  enabled = true,
  width = 640,
}) => {
  const [videoMetadataPlaylist, setVideoMetadataPlaylist] = useState<
    VideoMetadataPlaylist | undefined
  >()

  const getVideoMetadata = useCallback(() => {
    fetchVideoMetadata(videoId).then((res) => {
      setVideoMetadataPlaylist((res as VideoMetadataResponse)?.playlist?.[0])
    })
  }, [videoId])

  useEffect(() => {
    if (enabled && videoId && !videoMetadataPlaylist) {
      getVideoMetadata()
    }
  }, [videoMetadataPlaylist, getVideoMetadata, enabled, videoId])

  const fetchedSourceUrl = useMemo(
    () =>
      videoMetadataPlaylist?.sources
        ? getFileSource(videoMetadataPlaylist.sources, width)
        : undefined,
    [videoMetadataPlaylist?.sources, width]
  )

  return {
    fetchedSourceUrl,
  }
}

export default useVideoSourceFetching
