import dynamic from 'next/dynamic'
import { FunctionComponent, useContext } from 'react'
import styled, { css } from 'styled-components'
import contextArticleTextbox, {
  ArticleTextboxContextType,
} from '@contexts/articleTextbox'
import { TextWrapperProps } from '@widgets/ArticleTextbox/types'
import { desktopCSS, tabletCSS, mobileCSS } from '@measures/responsive'
import { bodyStyle } from '@widgets/Body/bodyStyle'
import useHasInvertedColors from '@hooks/useHasInvertedColors'

type StyledTextWrapperProps = Pick<TextWrapperProps, 'hideOnMobile'> &
  Pick<ArticleTextboxContextType, 'isPromo'> & {
    hasInvertedColors: boolean
  }

const HTMLView = dynamic(() => import('@components/HTMLView'))

const { ArticleTextboxContext } = contextArticleTextbox

const allowedHtmlTags = [
  'h2',
  'h3',
  'br',
  'p',
  'ul',
  'ol',
  'li',
  'em',
  'strong',
  'a',
  'sub',
  'sup',
]

const StyledTextWrapper = styled.div<StyledTextWrapperProps>`
  ${({
    theme: {
      color: {
        primary: { primary01, primary02, blickRed },
      },
      spacing: { spacing8, spacing16, spacing20 },
    },
    hideOnMobile,
    isPromo,
    hasInvertedColors,
  }) => css`
    &&& {
      ${bodyStyle};
      color: ${hasInvertedColors ? primary02 : primary01};
      display: unset;

      ${isPromo &&
      css`
        margin-top: ${spacing20};
      `}
      &:empty {
        display: none;
      }

      p {
        margin: 0 0 ${spacing16} 0;
        overflow: initial;
      }

      p:last-child,
      ul:last-child {
        margin-bottom: 0;
      }

      ul {
        margin: ${spacing8} 0;
      }

      ol {
        margin: ${spacing8} 0;
        padding-left: 1.7em;
      }

      strong {
        font-weight: bold;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: ${spacing16} 0 ${spacing8} 0;
      }

      em {
        font-style: italic;
      }

      a,
      a:active,
      a:visited,
      a:active {
        color: ${isPromo
          ? blickRed
          : hasInvertedColors
            ? primary02
            : primary01};
        font-weight: normal;
        text-decoration: underline;
      }

      a:hover {
        color: ${blickRed};
        text-decoration: underline;
      }

      ${desktopCSS(css`
        ${!hideOnMobile &&
        css`
          display: none;
        `}
      `)}

      ${tabletCSS(css`
        ${!hideOnMobile &&
        css`
          display: none;
        `}
      `)}

      ${mobileCSS(css`
        ${hideOnMobile &&
        css`
          display: none;
        `}
      `)}
    }
  `}
`

const TextWrapper: FunctionComponent<TextWrapperProps> = ({
  hideOnMobile,
  children,
}) => {
  const { isPromo } = useContext(ArticleTextboxContext)
  const hasInvertedColors = useHasInvertedColors()

  return (
    <StyledTextWrapper
      hideOnMobile={hideOnMobile}
      isPromo={isPromo}
      hasInvertedColors={hasInvertedColors}>
      <HTMLView
        content={children}
        allowedHtmlTags={allowedHtmlTags}
        inTextBox={true}
        brandedBox={isPromo}
      />
    </StyledTextWrapper>
  )
}

export default TextWrapper
