import { FunctionComponent, ReactNode, useEffect, useState } from 'react'
import FullScreenContext from './fullscreenContext'
import useBodyScroll from '@hooks/useBodyScroll'

const GalleryFullScreenContextProvider: FunctionComponent<{
  children?: ReactNode
}> = ({ children }) => {
  const [isGalleryWidget, setIsGalleryWidget] = useState<boolean>(false)
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false)
  const [hasControls, setHasControls] = useState<boolean>(true)
  const hideFullScreenGalleryControls =
    isGalleryWidget && isFullScreen && !hasControls
  const { lockBodyScroll, unlockBodyScroll } = useBodyScroll()

  useEffect(() => {
    if (isFullScreen) {
      lockBodyScroll()
    } else {
      unlockBodyScroll()
    }
  }, [lockBodyScroll, unlockBodyScroll, isFullScreen])

  return (
    <FullScreenContext.Provider
      value={{
        isGalleryWidget,
        setIsGalleryWidget,
        isFullScreen,
        setIsFullScreen,
        hasControls,
        setHasControls,
        hideFullScreenGalleryControls,
      }}>
      {children}
    </FullScreenContext.Provider>
  )
}

export default GalleryFullScreenContextProvider
