import { FunctionComponent, useContext, useState } from 'react'
import CommentingConfirmationMessage from './CommentingConfirmationMessage'
import PostedCommentingItem from './PostedCommentingItem'
import CommentingItemWrappper from './CommentingItemWrapper'
import CommentingActionPopup from './CommentingActionPopup'
import AnswerAPIContext from '@contexts/commenting/answerApiContext'
import { PostedAnswerProps } from './types'
import translate from '@i18n'
import { getTitle } from './utils'

const PostedAnswer: FunctionComponent<PostedAnswerProps> = ({
  answer: {
    id,
    author_type,
    father_id,
    created,
    body,
    user: { first_name, last_name, gender },
  },
}) => {
  const { deletePostedAnswer } = useContext(AnswerAPIContext)

  const [deletingAnswer, setDeletingAnswer] = useState(false)

  const [confirmationMessageShown, setConfirmationMessageShown] = useState(true)

  return (
    <CommentingItemWrappper type="answer" itemId={id}>
      <PostedCommentingItem
        type="answer"
        authorType={author_type}
        gender={gender}
        deleting={deletingAnswer}
        authorName={getTitle({
          name: `${first_name} ${last_name}`,
        })}
        datePublished={created}
        textContent={body}
        onDelete={() => setDeletingAnswer(true)}
      />
      {confirmationMessageShown && (
        <CommentingConfirmationMessage
          onTimeout={() => setConfirmationMessageShown(false)}>
          {translate('commenting.postCommentSentMessage')}
        </CommentingConfirmationMessage>
      )}
      {deletingAnswer && (
        <CommentingActionPopup
          type="answer"
          actionType="delete"
          onConfirm={() => {
            setDeletingAnswer(false)
            deletePostedAnswer({
              answerId: id,
              fatherId: father_id,
            })
          }}
          onCancel={() => setDeletingAnswer(false)}
        />
      )}
    </CommentingItemWrappper>
  )
}

export default PostedAnswer
