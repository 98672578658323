import dynamic from 'next/dynamic'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

interface SourceProps {
  text: string
}

const HTMLView = dynamic(() => import('@components/HTMLView'))

const allowedHtmlTags = ['p', 'em', 'strong', 'a', 'sub', 'sup']

const StyledSource = styled.div`
  ${({
    theme: {
      typography: {
        bodycopy: {
          xsmall: { bundledCSS: bodycopyXSmallCSS },
        },
      },
      color: {
        primary: { primary01 },
      },
    },
  }) => css`
    p {
      ${bodycopyXSmallCSS};
      margin: 0;
      color: ${primary01};
    }
  `}
`

const Source: FunctionComponent<SourceProps> = ({ text }) => {
  if (!text) {
    return null
  }

  return (
    <StyledSource>
      <HTMLView content={text} allowedHtmlTags={allowedHtmlTags} />
    </StyledSource>
  )
}

const widget = {
  kind: ['widget', 'source'],
  component: Source,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
