import { FunctionComponent } from 'react'
import FeatureFlagIndicator from '@components/FeatureFlagIndicator'
import FullScreenImagePortal from '@components/FullScreenImagePortal'
import ModalOverlayPortal from '@components/ModalOverlayPortal'
import GiftedArticleBanner from '@components/GiftedArticleBanner'
import PianoFixedDiv from '@components/PianoFixedDiv'
import ProlitterisPixelTracker from '@components/ProlitterisPixelTracker'
import VideoOverlayBackground from '@components/Video/VideoOverlayBackground'
import { AppProps } from 'next/app'
import { CatchAllPageServerProps } from '../../pages/[[...pageUrl]]'
import { StoryWrapType } from '@stories/utils/types'
import WrapperOfAll from '@components/WrapperOfAll'

export interface DefaultPageProps {
  Component: AppProps['Component']
  restOfPageProps?: Partial<CatchAllPageServerProps>
  storybookWrapType?: StoryWrapType
}

const DefaultPage: FunctionComponent<DefaultPageProps> = ({
  Component,
  restOfPageProps,
  storybookWrapType,
}) => (
  <>
    <WrapperOfAll storybookWrapType={storybookWrapType}>
      <Component {...(storybookWrapType ? {} : restOfPageProps)} />
    </WrapperOfAll>
    <FullScreenImagePortal />
    <ModalOverlayPortal />
    <VideoOverlayBackground />
    {!storybookWrapType && (
      <>
        <ProlitterisPixelTracker />
        <PianoFixedDiv />
        <GiftedArticleBanner />
        <FeatureFlagIndicator />
      </>
    )}
  </>
)

export default DefaultPage
