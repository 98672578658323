export type TQuestionData = { id: string; text: string; isCached: boolean }

export type TAnswerData = { text: string; link?: string; category?: string }

export type TSourceData = {
  url: string
  title: string
  isPlus: boolean
}

export type TLinkData = Record<string, TSourceData>

export type TQuestionResponse = {
  spanId: string
  traceId: string
  version: string
  questions: TQuestionData[]
}

export type TAnswerResponse = {
  id: string
  spanId: string
  traceId: string
  version: string
  content: TAnswerData[]
  links: TLinkData
  conversationId: string
}

export const WidgetIdValues = {
  ArticleQuestion: 'articleQuestion',
  SectionSummary: 'dailyBriefing',
  QuestionWidget: 'questionWidget',
} as const

export type WidgetId = (typeof WidgetIdValues)[keyof typeof WidgetIdValues]
