import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent } from 'react'
import config from '@config'
import EmbeddedContent from '@components/EmbeddedContent'

const {
  podigee: { staticPageUrl },
} = config

interface PodigeeProps {
  url: string
}

const Podigee: FunctionComponent<PodigeeProps> = ({ url }) => (
  <EmbeddedContent
    url={`${staticPageUrl}${url}/embed?context=external`}
    scrollingEnabled={true}
    max-height={600}
    max-height-mobile={600}
  />
)

const widget = {
  kind: ['widget', 'tool', 'podigee'],
  component: Podigee,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
