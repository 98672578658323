import config from '@config'
import { TAnswerResponse, TQuestionResponse, WidgetId } from './types'

const {
  chatbot: { chatbotQuestionsEndpoint, chatbotChatEndpoint },
  sectionSummary: { answserUrl, questionsUrl },
} = config

export const HTTP_METHOD_VALUES = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
} as const

export type HTTP_METHOD =
  (typeof HTTP_METHOD_VALUES)[keyof typeof HTTP_METHOD_VALUES]

type ChatbotQueryApi = {
  method?: HTTP_METHOD
  widgetId?: WidgetId
  context?: string | number // number = articleId or special context e.g. "home"
  body?: Record<string, any> | null
}

type ChatbotAnswerPayloadApi = {
  text: string
  isSuggestedQuestion: boolean
  context?: string | number // number = articleId or special context e.g. "home"
  conversationId?: string
}

type ChatbotQuery = ChatbotQueryApi & { question: boolean } // question = true -> get questions, false -> get answers

export const chatbotRequest = ({
  question,
  widgetId,
  context,
}: ChatbotQuery) => {
  const url = new URL(
    `${chatbotQuestionsEndpoint}${question ? questionsUrl : answserUrl}`
  )

  if (widgetId) {
    url.searchParams.append('widgetId', widgetId)
  }
  if (context) {
    url.searchParams.append('context', `${context}`)
  }

  return url
}

export const chatbotChatRequest = () => {
  const url = new URL(`${chatbotChatEndpoint}/chat`)

  return url
}

/**
 * wraps an url into a request fn to be used in react-query
 */
const queryFn =
  <T>(
    url: URL,
    method: HTTP_METHOD = HTTP_METHOD_VALUES.GET,
    bodyObj?: Record<string, any> | null
  ): (() => Promise<T>) =>
  async () => {
    const body = bodyObj
      ? JSON.stringify({ publication: 'blick', ...bodyObj })
      : undefined
    const response = await fetch(url, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      method,
      body,
    })

    if (response.status >= 400 && response.status < 600) {
      throw new Error(`Server responded with ${response.status}`)
    }

    return await response.json()
  }

export const getQuestions = ({
  method = HTTP_METHOD_VALUES.GET,
  widgetId,
  context,
  body,
}: ChatbotQueryApi) => {
  const url = chatbotRequest({ widgetId, context, question: true })

  if (method === HTTP_METHOD_VALUES.GET) {
    url.searchParams.append('publication', 'blick')
  }
  return queryFn<TQuestionResponse>(url, method, body)
}

export const getAnswer = ({
  widgetId,
  context,
  questionId,
}: ChatbotQueryApi & { questionId: string }) => {
  const url = chatbotRequest({ widgetId, context, question: false })
  url.searchParams.append('questionId', questionId)
  url.searchParams.append('publication', 'blick')

  return queryFn<TAnswerResponse>(url)
}

export const getChat = ({
  context,
  isSuggestedQuestion,
  text,
  conversationId,
}: ChatbotAnswerPayloadApi) => {
  return queryFn<TAnswerResponse>(
    chatbotChatRequest(),
    HTTP_METHOD_VALUES.POST,
    {
      text,
      context,
      isSuggestedQuestion,
      ...(conversationId && { conversationId }),
    }
  )
}
