import { ConfigProps, DeploymentEnvType } from '@config/types'

const API_VERSION = 'latest'

const config = ({
  useLocalhostApiUrl,
  publication,
  apiUrl,
  imageUrl,
  baseUrl,
  cueLiveUrl,
  deploymentEnv,
  teamcityEnv,
  hotModuleReloading,
  releaseVersion,
}: ConfigProps) => {
  const versionedApiUrl = `${
    useLocalhostApiUrl
      ? (`http://localhost:8101/${
          publication === 'romandie' ? 'romandie' : 'blick'
        }` as const)
      : (`${apiUrl}/${API_VERSION}` as const)
  }` as const

  const cueLiveEventBaseUrl = `${cueLiveUrl}/entries/` as const

  const processedReleaseVersion =
    teamcityEnv !== 'prod' ? releaseVersion.slice(0, 7) : releaseVersion

  let apiEnvironment: DeploymentEnvType = 'dev'

  if (!useLocalhostApiUrl) {
    try {
      const parsedApiUrlDomain = new URL(apiUrl).hostname
        .split('.')
        .slice(-3)[0]

      if (['api'].includes(parsedApiUrlDomain)) {
        apiEnvironment = 'prod'
      } else if (['api-stg'].includes(parsedApiUrlDomain)) {
        apiEnvironment = 'stg'
      } else if (parsedApiUrlDomain === 'api-test') {
        apiEnvironment = 'test'
      } else if (parsedApiUrlDomain === 'escenic') {
        apiEnvironment = 'pr'
      }
    } catch {
      // do nothing
    }
  }

  return {
    useLocalhostApiUrl,
    deploymentEnv,
    teamcityEnv,
    hotModuleReloading,
    imageUrl,
    apiUrl,
    apiEnvironment,
    versionedApiUrl,
    skeletonUrl: `${versionedApiUrl}${
      publication === 'romandie' && !useLocalhostApiUrl ? '/fr' : ''
    }/skeleton-web/`,
    rootUrl: `/${publication === 'romandie' ? 'fr/' : ''}`,
    baseUrl,
    cueLiveEventBaseUrl,
    cueLiveTeaserEventBaseUrl: `${cueLiveEventBaseUrl}teasers/`,
    blickReleaseVersion: `next-${teamcityEnv}-${processedReleaseVersion}-${publication}`,
  } as const satisfies Record<string, unknown>
}

export default config
