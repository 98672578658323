import usePageMetadata from '@hooks/usePageMetadata'
import config from '@config'
import blickSchemaOrgLogo from '@assets/images/logo_schemaorg.png'
import { getSameAsInfo } from '@utils/schema'
import SchemaHTMLElement from '@components/SchemaHTMLElement'
import AddressSchema from '@components/Schema/AddressSchema'

const {
  backend: { baseUrl },
  schema: { organizationId, organizationAddressId, parentOrganizationId },
} = config

const NewsMediaOrganizationSchema = () => {
  const pageMetadata = usePageMetadata()
  const { url, metaDescription } = pageMetadata
  const blickEmail = 'redaktion@blick.ch'
  const blickPhone = '+41442596262'
  const addressProps = {
    id: organizationAddressId,
    streetAddress: 'Dufourstrasse 23',
    addressLocality: 'Zürich',
    addressRegion: 'ZH',
    postalCode: '8008',
    addressCountry: 'Schweiz',
  }
  return (
    <>
      <AddressSchema {...addressProps} />
      <SchemaHTMLElement
        scriptKey="newsMediaOrganization"
        schemaObject={{
          '@type': 'NewsMediaOrganization',
          id: organizationId,
          url: `${baseUrl}${url}`,
          name: 'Blick',
          legalName: 'Blick',
          alternateName: 'blick.ch',
          description: metaDescription,
          logo: {
            '@id': `${baseUrl}${blickSchemaOrgLogo}`,
          },
          image: [{ '@id': `${baseUrl}${blickSchemaOrgLogo}` }],
          email: blickEmail,
          telephone: blickPhone,
          address: {
            '@id': organizationAddressId,
          },
          contactPoint: {
            '@type': 'ContactPoint',
            telephone: blickPhone,
            contactType: 'Customer support',
            email: blickEmail,
          },
          parentOrganization: { '@id': parentOrganizationId },
          ...getSameAsInfo(),
        }}
      />
    </>
  )
}

export default NewsMediaOrganizationSchema
