import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

import { Credit as CreditType } from '@utils/cook/types'
import { Slide } from '@widgets/Gallery/types'
import { desktopCSS } from '@measures/responsive'
import { useFullScreenGallery } from '@hooks/useFullScreenGallery'

import Caption from './Caption'
import Credit from './Credit'
import useShouldStretch from '@hooks/useShouldStretch'

type SwiperSlideItemProps = Pick<Slide, 'caption'> & {
  isSingleSlide?: boolean
  isPlaceholder?: boolean
  hasPortalNode?: boolean
  hasExtraPadding?: boolean
  credit?: CreditType
  children?: React.ReactNode
}

type StyledCounterProps = Pick<
  SwiperSlideItemProps,
  'isPlaceholder' | 'hasPortalNode'
> & {
  isFullScreen: boolean
  isHidden: boolean
  shouldStretch?: boolean
}

const SlideFooterWrapper = styled.div<StyledCounterProps>`
  ${({
    theme: {
      color: {
        primary: { primary02: primary02Color },
      },
      spacing: { spacing8, spacing16 },
    },
    isFullScreen,
    isPlaceholder,
    hasPortalNode,
    shouldStretch,
    isHidden,
  }) => {
    if (isPlaceholder && !isFullScreen && hasPortalNode) {
      return css`
        display: none;
      `
    }

    return css`
      ${isHidden &&
      css`
        visibility: hidden;
      `}

      ${shouldStretch &&
      css`
        padding: 0 ${spacing16};
        ${desktopCSS(css`
          padding: 0;
        `)}
      `};

      ${isFullScreen &&
      !isPlaceholder &&
      css`
        padding: ${spacing8} ${spacing16} ${spacing16} ${spacing16};

        ${desktopCSS(css`
          background-color: ${primary02Color};
        `)}
      `}
    `
  }}
`

type SlideInfoContainerType = Pick<
  SwiperSlideItemProps,
  'isSingleSlide' | 'hasExtraPadding'
> & {
  hasDivider: boolean
}

const SlideInfoContainer = styled.div<SlideInfoContainerType>`
  ${({
    theme: {
      color: {
        tertiary: { grey300: grey300Color },
      },
      spacing: { spacing8, spacing16 },
    },
    isSingleSlide,
    hasDivider,
    hasExtraPadding,
  }) => {
    const captionLineSize = 12 * 1.6 // $caption-block-font-size * $caption-block-line-height
    const creditLineSize = 12 * 1.6 // $credit-block-font-size * $credit-block-line-height
    const paddingOffset = 1 + 8 + 8 + 8 // $bottom-border-width + $padding-top + $padding-bottom + $gap

    const minHeight = 3 * captionLineSize + 1 * creditLineSize + paddingOffset

    return css`
      padding: ${spacing8} ${hasExtraPadding ? spacing16 : '0px'};
      gap: ${spacing8};
      display: grid;
      grid-auto-rows: 1fr auto;

      ${!isSingleSlide &&
      css`
        min-height: ${minHeight}px;
      `}

      ${hasDivider
        ? css`
            border-bottom: 1px solid ${grey300Color};
          `
        : css`
            padding-bottom: 0;
          `};

      box-sizing: border-box;
    `
  }}
`

const SlideFooter: FunctionComponent<SwiperSlideItemProps> = ({
  caption,
  isSingleSlide,
  isPlaceholder,
  hasPortalNode,
  hasExtraPadding,
  credit,
  children,
}) => {
  const { isFullScreen, hideFullScreenGalleryControls } = useFullScreenGallery()
  const { shouldStretch } = useShouldStretch()

  const hasElements = !!caption || !!children || !!credit?.text
  const isHidden = isPlaceholder || hideFullScreenGalleryControls

  if (!hasElements) {
    return null
  }

  return (
    <SlideFooterWrapper
      isHidden={isHidden}
      isFullScreen={isFullScreen}
      isPlaceholder={isPlaceholder}
      hasPortalNode={hasPortalNode}
      shouldStretch={shouldStretch}>
      {children}
      <SlideInfoContainer
        isSingleSlide={isSingleSlide}
        hasDivider={!!(caption || credit) && !isFullScreen}
        hasExtraPadding={hasExtraPadding}>
        {!!caption && <Caption isFullScreen={isFullScreen}>{caption}</Caption>}
        {!!credit && <Credit isFullScreen={isFullScreen} {...credit} />}
      </SlideInfoContainer>
    </SlideFooterWrapper>
  )
}

export default SlideFooter
