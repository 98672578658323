import { RefObject, useCallback, useEffect, useMemo, useRef } from 'react'
import { SwiperClass } from './types'

export type { DirectionType, SwiperClass, ArrowsVisibilityType } from './types'

export type UseSwiperKeyboardType = (props: {
  swiperRef: RefObject<SwiperClass>
}) => {
  enableKeyboard: () => void
  disableKeyboard: () => void
}

export const useSwiperKeyboard: UseSwiperKeyboardType = ({ swiperRef }) => {
  const isKeyboardEnabled = useRef(false)

  const keydownHandler = useCallback(
    (event: KeyboardEvent) => {
      switch (event.code) {
        case 'PageUp':
        case 'ArrowLeft':
        case 'ArrowUp':
          swiperRef.current?.slideToDirection('prev')
          break
        case 'PageDown':
        case 'ArrowRight':
        case 'ArrowDown':
          swiperRef.current?.slideToDirection('next')
          break
      }
    },
    [swiperRef]
  )

  const enableKeyboard = useCallback(() => {
    document.addEventListener('keydown', keydownHandler)
    isKeyboardEnabled.current = true
  }, [keydownHandler])

  const disableKeyboard = useCallback(() => {
    document.removeEventListener('keydown', keydownHandler)
    isKeyboardEnabled.current = false
  }, [keydownHandler])

  useEffect(() => {
    return () => {
      if (isKeyboardEnabled.current) {
        disableKeyboard()
      }
    }
  }, [disableKeyboard])

  return useMemo(
    () => ({
      enableKeyboard,
      disableKeyboard,
    }),
    [enableKeyboard, disableKeyboard]
  )
}
