import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

interface PromoLabelProps {
  children: string
  className?: string
}

const StyledPromoLabel = styled.div`
  ${({
    theme: {
      color: {
        primary: { primary01: primary01Color, primary02: primary02Color },
      },
      typography: {
        eyebrows: {
          small2: { bundledCSS: small2EyebrowCSS },
        },
      },
    },
  }) => css`
    display: inline-block;
    ${small2EyebrowCSS};
    color: ${primary01Color};
    background-color: ${primary02Color};
    padding: 0.2em 0.4em 0.12em;
    text-transform: uppercase;
  `}
`

const PromoLabel: FunctionComponent<PromoLabelProps> = ({
  children,
  className,
}) => {
  return <StyledPromoLabel className={className}>{children}</StyledPromoLabel>
}

export default PromoLabel
