import { getJWPlayer, playerSeekToLive } from '@utils/videoPlayer'
import {
  useState,
  useCallback,
  useEffect,
  useRef,
  MutableRefObject,
} from 'react'

export type UseIsInViewportInputProps = {
  enabled: boolean
  isInViewport: boolean | null
  widgetId: string
  isCastingRef: MutableRefObject<boolean>
}

const useVideoAutoPause = ({
  enabled,
  isInViewport,
  widgetId,
  isCastingRef,
}: UseIsInViewportInputProps): void => {
  const [isPip, setIsPip] = useState<boolean>(false)
  const wasPlayerAlreadyVisibleRef = useRef<boolean>(false)
  const isVisibilePrevValueRef = useRef<boolean | null>(null)

  const onVisibilityChange = useCallback(
    (isInViewport: boolean) => {
      const jwPlayer = getJWPlayer(widgetId)

      if (jwPlayer) {
        if (isInViewport) {
          playerSeekToLive(jwPlayer)
        } else {
          jwPlayer.pause()
          jwPlayer.setMute(true)
        }
      }
    },
    [widgetId]
  )

  useEffect(() => {
    if (enabled) {
      const isCasting = isCastingRef.current

      const trackVisibilityChange =
        // do not track visibility when casting
        !isCasting &&
        // do not track vilibility when pip (player is always visible)
        !isPip &&
        // start tracking only when player was in the viewport previoulsy
        wasPlayerAlreadyVisibleRef.current &&
        // track only if visibility state was changed
        isVisibilePrevValueRef.current !== isInViewport

      if (trackVisibilityChange) {
        onVisibilityChange(!!isInViewport)

        isVisibilePrevValueRef.current = !!isInViewport
      }

      if (isInViewport && !wasPlayerAlreadyVisibleRef.current) {
        wasPlayerAlreadyVisibleRef.current = true
      }
    }
  }, [enabled, isInViewport, onVisibilityChange, isPip, isCastingRef])

  const onPip = useCallback(({ pip }: { pip: boolean }) => {
    setIsPip(pip)
  }, [])

  useEffect(() => {
    if (enabled) {
      const jwPlayer = getJWPlayer(widgetId)

      jwPlayer?.on('pip', onPip)

      return () => {
        jwPlayer?.off('pip', onPip)
      }
    }
  }, [enabled, onPip, widgetId])
}

export default useVideoAutoPause
