import { useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { getDeviceType } from '@utils/deviceType'

type BodyScrollType = {
  isBodyScrollLocked: boolean
  scrollPosition: number
}

const useBodyScroll = () => {
  const queryClient = useQueryClient()

  const lockBodyScroll = useCallback(() => {
    const { isBodyScrollLocked } =
      queryClient.getQueryData<BodyScrollType>(['body-scroll']) || {}
    if (!isBodyScrollLocked) {
      let scrollY = 0
      const scrollBarWidth = window.innerWidth - document.body.clientWidth
      document.body.style.overflow = 'hidden'
      // We need this to avoid content is shifted to the right when the burger menu is opened and the scrollbar disappears
      if (scrollBarWidth > 0) {
        document.body.style.paddingRight = `${scrollBarWidth}px`
      }
      const isIos = getDeviceType() === 'ios'
      if (isIos) {
        scrollY = window.pageYOffset
        document.body.style.position = 'fixed'
        document.body.style.top = `-${scrollY}px`
        document.body.style.width = '100%'
      }
      queryClient.setQueryData<BodyScrollType>(['body-scroll'], {
        isBodyScrollLocked: true,
        scrollPosition: scrollY,
      })
      queryClient.invalidateQueries({
        queryKey: ['body-scroll'],
        exact: true,
      })
    }
  }, [queryClient])

  const unlockBodyScroll = useCallback(
    (resetScrollPosition?: boolean) => {
      const { isBodyScrollLocked, scrollPosition } =
        queryClient.getQueryData<BodyScrollType>(['body-scroll']) || {}
      if (isBodyScrollLocked) {
        document.body.style.removeProperty('overflow')
        document.body.style.paddingRight = ''
        const isIos = getDeviceType() === 'ios'
        if (isIos) {
          document.body.style.removeProperty('position')
          document.body.style.removeProperty('top')
          document.body.style.removeProperty('width')
          window.scrollTo(0, resetScrollPosition ? 0 : (scrollPosition ?? 0))
        }
        queryClient.setQueryData<BodyScrollType>(['body-scroll'], {
          isBodyScrollLocked: false,
          scrollPosition: 0,
        })
        queryClient.invalidateQueries({
          queryKey: ['body-scroll'],
          exact: true,
        })
      }
    },
    [queryClient]
  )

  return { lockBodyScroll, unlockBodyScroll }
}

export default useBodyScroll
