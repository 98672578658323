import { FunctionComponent, useCallback } from 'react'

import { getKropkaConfigObject } from '@utils/kropka'
import useExecuteOnClientNavigation from '@hooks/useExecuteOnClientNavigation'
import useIsSwissGeolocation from '@hooks/useIsSwissGeolocation'

const Kropka: FunctionComponent = () => {
  const isSwissGeolocation = useIsSwissGeolocation()

  const sendKropkaPageView = useCallback(
    (pageMetadata: any) => {
      // Tells Kropka, that it should track a page view
      window.dlApi.changeView?.(
        getKropkaConfigObject({
          metadata: pageMetadata,
          isSwissGeolocation,
        })
      )
    },
    [isSwissGeolocation]
  )

  useExecuteOnClientNavigation(sendKropkaPageView)

  return null
}

export default Kropka
