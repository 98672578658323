import styled from 'styled-components'
import Blink from '@components/Blink'

const TeaserToiCommercialWrapper = styled(Blink)`
  display: block;
  appearance: none;
  text-decoration: none;
`

export default TeaserToiCommercialWrapper
