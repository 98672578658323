import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Image } from '@utils/cook/types'
import ResponsiveImageV2 from '@components/ResponsiveImageV2'
import { IMAGE_RATIOS, IMAGE_WIDTHS } from '@widgets/AuthorHeadline/utils'

interface ImageWrapperProps {
  children: Image<'1_1'>
}

const StyledWrapper = styled.div`
  width: 182px;
  height: 182px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;

  /* Needed for Safari 14.1 */
  transform: translateZ(0);
`

const ImageWrapper: FunctionComponent<ImageWrapperProps> = ({ children }) => {
  const { src, alt, crops } = children

  return (
    <StyledWrapper>
      <ResponsiveImageV2
        src={src}
        alt={alt}
        ratios={IMAGE_RATIOS}
        crops={crops ?? {}}
        widths={IMAGE_WIDTHS}
      />
    </StyledWrapper>
  )
}

export default ImageWrapper
