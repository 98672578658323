import { FunctionComponent } from 'react'
import EmbeddedContent from '@components/EmbeddedContent'
import config from '@config'

const {
  socialembeds: { tiktokEmbedStaticPageUrl },
} = config

interface TikTokComponentProps {
  url: string
}

const TikTokComponent: FunctionComponent<TikTokComponentProps> = ({ url }) => (
  <EmbeddedContent url={`${tiktokEmbedStaticPageUrl}${url}`} />
)

export default TikTokComponent
