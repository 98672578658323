import { FunctionComponent, useEffect } from 'react'
import config from '@config'
import { QueryClient, useQueryClient } from '@tanstack/react-query'
import { getLocalStorageItem, setLocalStorageItem } from '@utils/localStorage'
import { captureException, captureExceptionWithUser } from '@utils/sentry'
import { User } from '@hooks/useUser'

export type GetSubscriptionDataToTrackFn = (
  subscriptions: SubscriptionAPIValidResponse['subscriptions']
) => SubscriptionTransformedData | NotSubscribedSubscriptionData

export type FetchAuthDataFn = () => Promise<User>

export type Subscription = {
  active: boolean
  typeId: string
  typeName: string
  startDate: string
  expirationDate: string
  subscriptionId: string
  isInTrial: boolean
  isPromotion?: boolean
}

export interface SubscriptionAPIValidResponse {
  subscriptions: Subscription[]
}

export interface SubscriptionTransformedData {
  user_status: 'subscribed'
  product_name: Subscription['typeName']
  product_id: Subscription['typeId']
  subscription_valid_until: Subscription['expirationDate']
  is_trial: Subscription['isInTrial']
}

export interface NotSpecifiedSubscriptionData {
  user_status: 'notSpecified'
}

export interface NotSubscribedSubscriptionData {
  user_status: 'notSubscribed'
}

export type SubscriptionData =
  | SubscriptionTransformedData
  | NotSpecifiedSubscriptionData
  | NotSubscribedSubscriptionData
  | undefined

export type FetchSubscriptionDataFn = (
  queryClient: QueryClient
) => Promise<SubscriptionData>

const {
  ads: {
    admeira: { storageKey: admeiraStorageKey },
  },
  auth: { authURL, metadataPath },
  subscriptions: { subscriptionsUrl },
} = config

const getSubscriptionDataToTrack: GetSubscriptionDataToTrackFn = (
  subscriptions
) => {
  // Discard inactive subscriptions, they are not relevant for tracking
  const activeSubscriptions = subscriptions.filter(
    (subscription) => subscription.active
  )

  if (activeSubscriptions.length === 0) {
    return {
      user_status: 'notSubscribed',
      product_name: '',
      product_id: '',
      is_trial: false,
    }
  }

  // In case user has more than one active subscription, we should track the one with the most recent startDate
  const { typeName, typeId, expirationDate, isInTrial } =
    activeSubscriptions.length > 1
      ? activeSubscriptions.sort(
          (subA, subB) =>
            new Date(subA.startDate).getTime() -
            new Date(subB.startDate).getTime()
        )[0]
      : activeSubscriptions[0]

  return {
    user_status: 'subscribed',
    product_name: typeName,
    product_id: typeId,
    subscription_valid_until: expirationDate,
    is_trial: isInTrial,
  }
}

const notSubscribedPayload: NotSubscribedSubscriptionData = {
  user_status: 'notSubscribed',
}

const notSpecifiedPayload: NotSpecifiedSubscriptionData = {
  user_status: 'notSpecified',
}

const fetchAuthData: FetchAuthDataFn = async () => {
  try {
    const response = await fetch(`${authURL}${metadataPath}`, {
      credentials: 'include',
    })

    if (!response.ok && response.status !== 401) {
      captureException(`HTTP ${response.status} error fetching user`)
      return null
    }

    const userMetadata: User =
      ((await response.json()) as { metadata?: User })?.metadata ?? null

    if (!!userMetadata?.admeira_id) {
      setLocalStorageItem(admeiraStorageKey, {
        ...getLocalStorageItem<{ admeiraId: string }>(admeiraStorageKey),
        admeiraId: userMetadata.admeira_id,
      })
    }

    return userMetadata
  } catch {
    return null
  }
}

const fetchSubscriptionData: FetchSubscriptionDataFn = async (queryClient) => {
  const userData = queryClient.getQueryData<User>(['user'])

  // In case of error when we fetch user metadata, we don't want to end up with inconsistent state
  // where user is undefined but plus content is shown, so we should avoid calling the subscription checker in this case
  if (!userData) {
    return notSubscribedPayload
  }

  try {
    const response = await fetch(subscriptionsUrl, {
      credentials: 'include',
    })

    if (!response.ok) {
      if (response.status !== 401) {
        captureExceptionWithUser(
          `[SUB. CHECKER ERROR] GET subscriptions returned a HTTP error: ${response.status}`,
          queryClient
        )
      }
      // In case of error on the subscription checker, the user should be able to see the "plus" content
      return notSpecifiedPayload
    }

    const subscriptionData = (
      (await response.json()) as SubscriptionAPIValidResponse
    ).subscriptions

    return getSubscriptionDataToTrack(subscriptionData)
  } catch (err) {
    captureExceptionWithUser(
      `[SUB. CHECKER ERROR] GET subscriptions returned an error: ${err}`,
      queryClient
    )
    // In case of error on the subscription checker, the user should be able to see the "plus" content
    return notSpecifiedPayload
  }
}

const Auth: FunctionComponent = () => {
  const queryClient = useQueryClient()

  useEffect(() => {
    const isAuthResolved = queryClient.getQueryData<boolean>(['auth-resolved'])
    if (!isAuthResolved) {
      Promise.all([
        queryClient.prefetchQuery({
          queryKey: ['user'],
          queryFn: fetchAuthData,
        }),
        queryClient.prefetchQuery({
          queryKey: ['auth-resolved'],
          queryFn: async () => true,
        }),
      ])
        .then(() =>
          queryClient.prefetchQuery({
            queryKey: ['subscription-data'],
            queryFn: () => fetchSubscriptionData(queryClient),
          })
        )
        .then(() => {
          queryClient.invalidateQueries({ queryKey: ['auth-resolved'] })
          queryClient.invalidateQueries({ queryKey: ['subscription-data'] })
          queryClient.invalidateQueries({ queryKey: ['user'] })
        })
    }
  }, [queryClient])

  return null
}

export default Auth
