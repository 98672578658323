import { ConfigProps } from '@config/types'

const config = ({ chatbotChatUrl, chatbotQuestionsUrl }: ConfigProps) =>
  ({
    chatbotChatEndpoint: chatbotChatUrl,
    chatbotQuestionsEndpoint: chatbotQuestionsUrl,
    chatbotFooterUserSnapFeedbackKey: '937d9bec-2615-4e79-abc3-363ffcad262a',
    moreInfoLink: 'https://chatbot.blick.ch/',
  }) as const satisfies Record<string, unknown>

export default config
