import { FunctionComponent, useCallback, useState } from 'react'
import { TextTrackProps } from '../../types'
import useOnVTTCueChange, {
  UseOnVTTCueChange,
} from '../TextTracks/useOnVTTCueChange'
import useVTTCuesFromCaptionsTrack from '../TextTracks/useVTTCuesFromCaptionsTrack'
import CaptionsElements from './CaptionsElements'

type LiveGraphicsProps = Omit<TextTrackProps, 'onGraphicsCueChange'> & {
  disabled?: boolean
}

const LiveCaptions: FunctionComponent<LiveGraphicsProps> = ({
  player,
  disabled,
  onCaptionsCueChange,
}) => {
  const [captions, setCaptions] = useState<string[]>()

  const onCueChange = useCallback<
    Parameters<UseOnVTTCueChange>[0]['onCueChange']
  >(
    (text) => {
      setCaptions(text)
      onCaptionsCueChange?.(text)
    },
    [onCaptionsCueChange]
  )

  const vttCues = useVTTCuesFromCaptionsTrack({ player, onCueChange })

  useOnVTTCueChange({ player, vttCues, onCueChange })

  return disabled ? null : <CaptionsElements captions={captions} />
}

export default LiveCaptions
