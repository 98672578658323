import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import translate from '@i18n'
import { RecipeInfoType } from '@widgets/Ingredients/types'
import { mobileCSS } from '@measures/responsive'
import { printCSS } from '@utils/style'

interface RecipeInfoItemProps {
  title: RecipeInfoType
  children?: ReactNode
}

const ItemWrapper = styled.div`
  ${({
    theme: {
      color: {
        tertiary: { grey400 },
      },
      spacing: { spacing16, spacing8 },
    },
  }) => {
    return css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-items: center;
      padding: ${spacing8} ${spacing16};
      box-sizing: border-box;
      border-right: 1px solid ${grey400};

      &:last-child {
        border-right: none;
        border-bottom: none;
      }

      ${mobileCSS(css`
        border-bottom: 1px solid ${grey400};

        &:nth-child(2n) {
          border-right: none;
        }

        &:nth-last-child(2):not(:nth-child(2)) {
          border-bottom: none;
        }

        &:last-child:nth-child(odd) {
          grid-column: 1 / 3;
        }
      `)}

      ${printCSS(css`
        &:nth-child(2n) {
          border-right: 1px solid ${grey400};
        }

        &:last-child {
          border-right: none;
        }

        &:last-child:nth-child(odd) {
          grid-column: unset;
        }

        border-bottom: none;
      `)}
    `
  }}
`

const StyledItemText = styled.span`
  ${({
    theme: {
      typography: {
        bodycopy: {
          small2: { bundledCSS: bodycopySmall2CSS },
          xsmall: { bundledCSS: bodycopyXSmallCSS },
        },
      },
    },
  }) => {
    return css`
      ${bodycopySmall2CSS};
      text-align: center;
      ${mobileCSS(css`
        ${bodycopyXSmallCSS}
      `)}
    `
  }}
`

const StyledItemTitle = styled.span`
  ${({
    theme: {
      typography: {
        headings: {
          xsmall: { bundledCSS: headingXSmallCSS },
          xxsmall: { bundledCSS: headingXXSmallCSS },
        },
      },
    },
  }) => {
    return css`
      ${headingXSmallCSS};
      text-align: center;
      ${mobileCSS(css`
        ${headingXXSmallCSS}
      `)}
    `
  }}
  font-weight: 700;
`

const RecipeInfoItem: FunctionComponent<RecipeInfoItemProps> = ({
  title,
  children,
}) => (
  <>
    {children && (
      <ItemWrapper>
        <StyledItemTitle>{translate(`recipe.${title}`)}</StyledItemTitle>
        <StyledItemText>{children}</StyledItemText>
      </ItemWrapper>
    )}
  </>
)

export default RecipeInfoItem
