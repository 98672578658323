import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

import { BlinkProps } from '@components/Blink'
import PrimaryCTABlink from '@components/Buttons/PrimaryCTABlink'

interface CTAButtonProps {
  text: string
  link: BlinkProps
  backgroundColor?: string
  className?: string
}

const ButtonWrapper = styled(PrimaryCTABlink)<
  Pick<CTAButtonProps, 'backgroundColor'>
>`
  ${({ backgroundColor }) => css`
    ${backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `};
  `}
`

const CTAButton: FunctionComponent<CTAButtonProps> = ({
  className,
  text,
  link,
  backgroundColor,
}) => {
  return (
    <ButtonWrapper
      size="large"
      className={className}
      colorVariant="green"
      backgroundColor={backgroundColor}
      iconName="arrow-right"
      {...link}>
      {text}
    </ButtonWrapper>
  )
}

export default CTAButton
