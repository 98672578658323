import usePageMetadata from '@hooks/usePageMetadata'
import config from '@config'
import SchemaHTMLElement from '@components/SchemaHTMLElement'

const {
  schema: { webSiteId, urlTemplate, organizationId },
} = config

const WebSiteSchema = () => {
  const pageMetadata = usePageMetadata()
  const { context } = pageMetadata
  const isHomePage = context === 'home'

  const potentialAction = {
    '@type': 'SearchAction',
    target: {
      '@type': 'EntryPoint',
      urltemplate: urlTemplate,
    },
    'query-input': 'required name=Schweiz',
  }
  return (
    <SchemaHTMLElement
      scriptKey="webSite"
      schemaObject={{
        '@type': 'WebSite',
        id: webSiteId,
        url: 'https://www.blick.ch',
        name: 'Blick',
        alternateName: 'Blick.ch',
        publisher: {
          '@id': organizationId,
        },
        ...(isHomePage ? { potentialAction } : {}),
      }}
    />
  )
}

export default WebSiteSchema
