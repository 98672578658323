import { FunctionComponent } from 'react'
import translate from '@i18n'

import { CommentingItemListProps } from './types'
import styled, { css } from 'styled-components'
import PrimaryCTAButton from '@components/Buttons/PrimaryCTAButton'

const ItemList = styled.div`
  ${({
    theme: {
      spacing: { spacing24 },
    },
  }) => css`
    margin-top: ${spacing24};
  `}
`

interface ItemListLoadMoreProps {
  isAnswer: boolean
}

const ItemListLoadMore = styled.div<ItemListLoadMoreProps>`
  ${({
    isAnswer,
    theme: {
      spacing: { spacing20, spacing24 },
    },
  }) => css`
    margin-top: ${spacing24};

    ${isAnswer &&
    css`
      box-sizing: border-box;
      padding-left: ${spacing20};
    `}
  `}
`

const LoadMoreButton = styled(PrimaryCTAButton)`
  width: 100%;
`

//! The reason why we need "rerenderKey" is because we need to force unmount/remount
//! the "show more comments" button on every page changes, since this is solving a bug
//! that Chrome 84 has with not maintaining the scroll position of the newly added content.
const CommentingItemList: FunctionComponent<CommentingItemListProps> = ({
  type,
  moreToShow,
  onShowMore,
  rerenderKey,
  children,
}) => {
  const isAnswer = type === 'answer'
  return (
    <>
      <ItemList>{children}</ItemList>
      {moreToShow && onShowMore && (
        <ItemListLoadMore key={rerenderKey} isAnswer={isAnswer}>
          <LoadMoreButton
            colorVariant={isAnswer ? 'grey' : 'red'}
            size="small"
            onClick={onShowMore}>
            {type === 'comment'
              ? translate('commenting.loadMoreComments')
              : translate('commenting.loadMoreAnswers')}
          </LoadMoreButton>
        </ItemListLoadMore>
      )}
    </>
  )
}

export default CommentingItemList
