import { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'

export const StyledVideoPlayerWrapper = styled.div`
  background-color: #000000;
  padding-top: 56.25%;
  width: 100%;
  position: relative;
  overflow: hidden;
`

export const StyledVideoPlayerContainer = styled.div`
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: absolute;
`

const VideoPlayerWrapper: FunctionComponent<{
  children: ReactNode
}> = ({ children }) => {
  return (
    <StyledVideoPlayerWrapper>
      <StyledVideoPlayerContainer>{children}</StyledVideoPlayerContainer>
    </StyledVideoPlayerWrapper>
  )
}

export default VideoPlayerWrapper
