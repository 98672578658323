import { QueryClient } from '@tanstack/react-query'
import config from '@config'

const {
  urlParams: { clientOnlyURLParams, clientOnlyURLParamsCommonKey },
} = config

export type GetURLParam = (
  param: (typeof clientOnlyURLParams)[number]['name'],
  queryClient: QueryClient
) => string | undefined

const getURLParam: GetURLParam = (param, queryClient) =>
  queryClient.getQueryData<string>([clientOnlyURLParamsCommonKey, param])

export { getURLParam }
