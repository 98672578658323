import {
  FunctionComponent,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import {
  SliderGradient,
  SliderWrapper,
  StyledNavigationArrows,
  StyledSwiper,
  StyledSwiperHeader,
  StyledSwiperHeadline,
  StyledSwiperText,
} from './SummarySliderComponents'
import { ArrowsVisibilityType, SwiperClass } from '@hooks/useSwiper'
import useViewportType from '@hooks/useViewport/useViewportType'

export const SummarySlider: FunctionComponent<{
  count: number
  slides: any[]
  activeIndex?: number
  headline?: string
  subheadline?: string
}> = ({ count, slides, headline, subheadline, activeIndex }) => {
  const viewportType = useViewportType()
  const swiperHeaderRef = useRef<HTMLDivElement>(null)
  const swiperRef = useRef<SwiperClass | null>(null)

  const [arrowsVisibility, setArrowsVisibility] =
    useState<ArrowsVisibilityType>('none')

  const onInit = useCallback(
    (swiper: SwiperClass) => {
      if (swiperRef) {
        swiperRef.current = swiper
      }
    },
    [swiperRef]
  )

  const onArrowsVisibility = useCallback((arrow: ArrowsVisibilityType) => {
    setArrowsVisibility(arrow)
  }, [])

  useLayoutEffect(() => {
    // scroll to the top of the slider and slide to the correct slide when activeIndex changes
    if (swiperHeaderRef.current && activeIndex !== undefined) {
      window.scrollTo({
        top:
          swiperHeaderRef.current?.getBoundingClientRect().top +
          document.documentElement.scrollTop -
          200,
        behavior: 'smooth',
      })
      swiperRef.current?.slideTo(activeIndex)
    }
  }, [swiperHeaderRef, swiperRef, activeIndex])

  const isDesktop = viewportType === 'desktop'

  return (
    <>
      <StyledSwiperHeader ref={swiperHeaderRef}>
        <StyledSwiperHeadline>{headline}</StyledSwiperHeadline>

        {arrowsVisibility !== 'none' && (
          <StyledNavigationArrows
            swiperRef={swiperRef}
            arrowsVisibility={arrowsVisibility}
            itemsCount={count}
          />
        )}
      </StyledSwiperHeader>
      <StyledSwiperText>{subheadline}</StyledSwiperText>
      <SliderWrapper>
        <StyledSwiper
          initialSlide={0}
          spaceTopBottom={0}
          slidesPerGroup={isDesktop ? 4 : 2}
          spaceBetween={8}
          onInit={onInit}
          onArrowsVisibility={onArrowsVisibility}
          slides={slides}
        />
        <SliderGradient />
        <SliderGradient pullRight />
      </SliderWrapper>
    </>
  )
}
