import { ConfigProps } from '@config/types'

const config = ({
  publication,
  deploymentEnv,
  staticAssetPageUrl,
  staticPageUrlSuffix,
}: ConfigProps) =>
  ({
    projectId: `${deploymentEnv === 'prod' ? '' : 'stage-'}${
      publication === 'romandie' ? 'epqqqrlhaarjsnb' : 'znbofpciiqccdkq'
    }`,

    formioBaseUrl: 'https://{{projectId}}.form.io',
    formioWidgetUrl: `${staticAssetPageUrl}/formio${staticPageUrlSuffix}`,
  }) as const satisfies Record<string, unknown>

export default config
