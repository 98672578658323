import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import AdRingierWidget from '@widgets/AdRingier'

const widget = {
  kind: ['ad-teads'],
  component: AdRingierWidget.component,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
