import { css } from 'styled-components'

export const printStyles = css`
  @media print {
    footer,
    form,
    iframe,
    video {
      display: none;
    }
    img {
      break-before: auto;
      break-after: auto;
      break-inside: avoid;
    }
    blockquote,
    ul {
      margin: 0;
    }
    a[href^="https://"]:after
    {
      content: ' (' attr(href) ')';
      font-size: 90%;
    }
    div,
    span,
    p {
      font-size: 14px;
      line-height: 18px;
    }
    #onesignal-slidedown-container {
      display: none;
    }
  }
`
