import useLeserreporterContext from '@hooks/useLeserreporterContext'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import ErrorMessage from './ErrorMessage'

const StyledField = styled.label`
  ${({
    theme: {
      spacing: { spacing20, spacing8 },
    },
  }) => css`
    margin: ${spacing20} 0 ${spacing8} 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  `}
`

const StyledLabel = styled.label`
  ${({
    theme: {
      typography: {
        subheads: {
          medium1: { bundledCSS: subheadsMedium1 },
        },
      },
      spacing: { spacing8 },
    },
  }) => css`
    ${subheadsMedium1};
    margin-bottom: ${spacing8};
  `}
`

const InputWrapper = styled.div`
  ${({
    theme: {
      color: {
        primary: { blickRed: blickRedcolor },
      },
    },
  }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;

    :focus {
      outline-color: ${blickRedcolor};
    }
  `}
`

const StyledInput = styled.input<{ isValid: boolean }>`
  ${({
    isValid,
    theme: {
      typography: {
        bodycopy: {
          small2: { bundledCSS: bodycopySmall2CSS },
        },
      },
      color: {
        primary: { blickRed: blickRedcolor },
        tertiary: { grey400: grey400color },
      },
      spacing: { spacing40, spacing24, spacing8 },
    },
  }) => css`
    padding: ${spacing8} ${spacing24};
    height: ${spacing40};
    border: 1px solid ${isValid ? grey400color : blickRedcolor};
    border-radius: ${spacing40};
    ${bodycopySmall2CSS};
  `}
`

const UserData: FunctionComponent = () => {
  const {
    data: { name, phone },
    handleChange,
    validation: { name: nameValidation, phone: phoneValidation },
    validate,
  } = useLeserreporterContext()
  const isValidName = nameValidation === true
  const isValidPhone = phoneValidation === true
  return (
    <>
      <StyledField>
        <StyledLabel>Vorname und Name</StyledLabel>
        <InputWrapper>
          <StyledInput
            type="text"
            value={name}
            name="name"
            onChange={handleChange}
            isValid={isValidName}
            onBlur={validate}
          />
          {!isValidName && nameValidation && (
            <ErrorMessage>{nameValidation as string}</ErrorMessage>
          )}
        </InputWrapper>
      </StyledField>
      <StyledField>
        <StyledLabel>Telefonnummer</StyledLabel>
        <InputWrapper>
          <StyledInput
            type="text"
            value={phone}
            name="phone"
            onChange={handleChange}
            isValid={isValidPhone}
            onBlur={validate}
          />
          {!isValidPhone && phoneValidation && (
            <ErrorMessage>{phoneValidation as string}</ErrorMessage>
          )}
        </InputWrapper>
      </StyledField>
    </>
  )
}

export default UserData
