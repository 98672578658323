import { useCallback } from 'react'
import { QueryClient, useQueryClient } from '@tanstack/react-query'
import { PageData, PageMetadata } from '@hooks/usePageMetadata'
import {
  getDefaultDataLayerObjectValues,
  getPageParameters,
  getTrackingMetadata,
} from './utils'

export type TrackingFnType<T = void> = (args: {
  pageMetadata: PageMetadata
  extraData: T
  defaultTrackingData: Record<string, unknown>
  queryClient: QueryClient
}) =>
  | {
      event: string
      [key: string]: unknown
    }
  | undefined

export type UseTracking = <T = void>(
  trackingFn: TrackingFnType<T>
) => (extraData: T) => void

const useTracking: UseTracking = (trackingFn) => {
  const queryClient = useQueryClient()

  const enhancedTrackingFunction = useCallback<
    (extraData: Parameters<typeof trackingFn>[0]['extraData']) => void
  >(
    (extraData) => {
      const defaultTrackingData = {
        ...getDefaultDataLayerObjectValues(queryClient),
        ...getPageParameters(queryClient),
        ...getTrackingMetadata(queryClient),
      }

      const customTrackingData = trackingFn({
        pageMetadata: queryClient.getQueryData<PageData>(['page'])
          ?.metadata as PageMetadata,
        extraData,
        defaultTrackingData,
        queryClient,
      })

      if (customTrackingData) {
        window.eventQueueDataLayer.push({
          ...defaultTrackingData,
          ...customTrackingData,
        })
      }
    },
    [queryClient, trackingFn]
  )

  return enhancedTrackingFunction
}

export default useTracking
