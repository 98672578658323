import { FunctionComponent } from 'react'
import Blink from '@components/Blink'
import styled, { css } from 'styled-components'
import { CreditProps } from '@widgets/ArticleTextbox/types'
import useIsInHerotellingPage from '@hooks/useIsInHerotellingPage'
import { desktopCSS, mobileAndTabletCSS } from '@measures/responsive'

type StyledCreditProps = {
  isInHerotellingPage: boolean
}

const StyledCredit = styled.div<StyledCreditProps>`
  ${({
    theme: {
      color: {
        tertiary: { grey800, grey100 },
      },
      typography: {
        bodycopy: {
          xxsmall: { bundledCSS: xxsmallBodycopyCSS },
        },
      },
      spacing: { spacing24 },
    },
    isInHerotellingPage,
  }) => css`
    font-weight: normal;
    color: ${grey800};
    ${xxsmallBodycopyCSS}

    ${isInHerotellingPage &&
    css`
      text-align: center;
      color: ${grey100};
      ${desktopCSS(css`
        margin-top: 14px;
      `)};
      ${mobileAndTabletCSS(css`
        order: -1;
        margin-bottom: ${spacing24};
      `)};
    `}
  `}
`

const StyledBlink = styled(Blink)`
  ${({
    theme: {
      typography: {
        bodycopy: {
          xxsmall: { bundledCSS: xxsmallBodycopyCSS },
        },
      },
      color: {
        primary: { primary01 },
      },
    },
  }) => css`
    font-weight: normal;
    color: ${primary01};
    text-decoration: underline;
    cursor: pointer;

    ${xxsmallBodycopyCSS}
  `}
`

const Credit: FunctionComponent<CreditProps> = ({ blinkProps, children }) => {
  const isInHerotellingPage = useIsInHerotellingPage()
  if (blinkProps?.href) {
    return <StyledBlink {...blinkProps}>{children}</StyledBlink>
  }

  return (
    <StyledCredit isInHerotellingPage={isInHerotellingPage}>
      {children}
    </StyledCredit>
  )
}

export default Credit
