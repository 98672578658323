import SvgIcon from '@components/SvgIcon'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { SpinnerProps } from '../types'

const StyledIcon = styled(SvgIcon)`
  ${({ color }) => css`
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    stroke: ${color};
  `}
`

const Spinner: FunctionComponent<SpinnerProps> = ({ size, color }) => {
  return <StyledIcon iconName="spinner" size={size} color={color} />
}

export default Spinner
