import { FunctionComponent } from 'react'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { JSONWidgetType as TeaserStandardHybridJSONWidgetType } from '@widgets/TeaserStandardHybrid'
import styled, { css } from 'styled-components'
import JSONRenderer from '@components/JSONRenderer'
import { desktopCSS } from '@measures/responsive'

type LayoutTemplateType = 'author-page' | 'dossier-page' | 'webarchive-page'

interface RelatedItemsProps {
  layoutTemplate: LayoutTemplateType
  items: TeaserStandardHybridJSONWidgetType[]
}

const StyledWrapper = styled.div<Pick<RelatedItemsProps, 'layoutTemplate'>>`
  ${({
    theme: {
      spacing: { spacing16, spacing32 },
    },
    layoutTemplate,
  }) => {
    return css`
      display: grid;
      ${desktopCSS(css`
        grid-template-columns: repeat(
          ${layoutTemplate === 'author-page' ? 2 : 3},
          minmax(0, 1fr)
        );
        grid-gap: ${spacing32};
      `)}
      grid-gap: ${spacing16};
      margin-bottom: ${spacing16};
    `
  }}
`
const RelatedItems: FunctionComponent<RelatedItemsProps> = ({
  items,
  layoutTemplate,
}) => {
  return (
    <StyledWrapper layoutTemplate={layoutTemplate}>
      <JSONRenderer>{items}</JSONRenderer>
    </StyledWrapper>
  )
}

const widget = {
  kind: ['related-items'],
  component: RelatedItems,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
