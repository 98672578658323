import { FunctionComponent } from 'react'

import BlickVariable from '@assets/fonts/BlickVariable.woff2'
import InterVariable from '@assets/fonts/InterVariable.woff2'
import InterItalic from '@assets/fonts/Inter-Italic.woff2'

const FontPreloadWeb: FunctionComponent = () => (
  <>
    <link
      rel="preload"
      href={BlickVariable}
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
    />
    <link
      rel="preload"
      href={InterVariable}
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
    />
  </>
)

const FontFacesWeb = `
  @font-face {
    font-family: 'BlickVariable';
    src: url('${BlickVariable}') format('woff2');
    font-display: swap;
    font-weight: 100 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'InterVariable';
    src: url('${InterVariable}') format("woff2");
    font-display: swap;
    font-weight: 100 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'InterVariable';
    src: url('${InterItalic}') format("woff2");
    font-display: swap;
    font-weight: normal;
    font-style: italic;
  }
`

export { FontPreloadWeb, FontFacesWeb }
