import { useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'
import { AnimatedPreviewType } from './types'

type UseSetAnimatedPreviewsReturnType = (
  value:
    | AnimatedPreviewType[]
    | ((value: AnimatedPreviewType[]) => AnimatedPreviewType[])
) => void

type useAnimatedPreviewsListType = () => {
  getAnimatedPreviewsList: () => AnimatedPreviewType[]
  setAnimatedPreviewsList: UseSetAnimatedPreviewsReturnType
}

const useAnimatedPreviewsList: useAnimatedPreviewsListType = () => {
  const queryClient = useQueryClient()

  const getAnimatedPreviewsList = useCallback(
    () =>
      queryClient.getQueryData<AnimatedPreviewType[]>([
        'animated-previews-list',
      ]) ?? [],
    [queryClient]
  )

  const setAnimatedPreviewsListQuery = useCallback(
    (value: AnimatedPreviewType[]) => {
      queryClient.setQueryData(['animated-previews-list'], value)
      queryClient.invalidateQueries({
        queryKey: ['animated-previews-list'],
        exact: true,
      })
    },
    [queryClient]
  )

  const setAnimatedPreviewsList = useCallback<UseSetAnimatedPreviewsReturnType>(
    (value) => {
      setAnimatedPreviewsListQuery(
        typeof value === 'function' ? value(getAnimatedPreviewsList()) : value
      )
    },
    [getAnimatedPreviewsList, setAnimatedPreviewsListQuery]
  )

  return {
    getAnimatedPreviewsList,
    setAnimatedPreviewsList,
  }
}

export default useAnimatedPreviewsList
