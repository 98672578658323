import { Fragment, FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { AuthorAPIProps, AuthorProps } from '@widgets/Author/types'
import ClickableContainer from '@widgets/Author/Common/ClickableContainer'
import Name from '@widgets/Author/Common/AuthorsWithoutImages/Name'
import { getSeparatorChar } from '@widgets/Author/Common/AuthorsWithoutImages/utils'
import { isLink } from '@widgets/Author/utils'
import BrandLogo from '@widgets/Author/Common/BrandLogo'
import useHasInvertedColors from '@hooks/useHasInvertedColors'

type AuthorWithoutImages = Pick<AuthorProps, 'name' | 'link'>

type AuthorsWithoutImagesProps = Pick<
  AuthorAPIProps,
  'contentOriginImage' | 'contentOrigin'
> & {
  children: AuthorWithoutImages[]
}

const AuthorsWrapper = styled.div``

const SeparatorChar = styled.span<{ hasInvertedColors: boolean }>`
  ${({
    theme: {
      color: {
        primary: { primary01, primary02 },
      },
      typography: {
        subheads: {
          small1: { bundledCSS: small1SubheadsCSS },
        },
      },
    },
    hasInvertedColors,
  }) => css`
    ${small1SubheadsCSS};
    color: ${hasInvertedColors ? primary02 : primary01};
  `}
`

const StyledAuthorsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const AuthorsWithoutImages: FunctionComponent<AuthorsWithoutImagesProps> = ({
  children,
  contentOriginImage,
  contentOrigin,
}) => {
  const hasInvertedColors = useHasInvertedColors()
  const lastIndex = children.length - 1

  return (
    <StyledAuthorsWrapper>
      <AuthorsWrapper>
        {children.map((author, index) => {
          const link = author.link
          const isClickable = isLink(link)
          return (
            <Fragment key={author.name}>
              <ClickableContainer link={link}>
                <Name
                  isClickable={isClickable}
                  hasInvertedColors={hasInvertedColors}>
                  {author.name}
                  {/*This space is needed for so that the authors correctly wrap to another line*/}
                </Name>
              </ClickableContainer>
              <SeparatorChar
                hasInvertedColors={
                  hasInvertedColors
                }>{`${getSeparatorChar(index, lastIndex)} `}</SeparatorChar>
            </Fragment>
          )
        })}
      </AuthorsWrapper>
      {!!contentOriginImage && (
        <BrandLogo contentOrigin={contentOrigin} {...contentOriginImage} />
      )}
    </StyledAuthorsWrapper>
  )
}

export default AuthorsWithoutImages
