import { PageMetadata } from '@hooks/usePageMetadata'
import {
  getJWPlayer,
  isPlayerCaptionsEnabled,
  isPlayerInOverlay,
} from '@utils/videoPlayer'

import {
  VideoPlayerStatusTracking,
  PlayerType,
  TrackingGetPlayerStatusProps,
} from '@widgets/Video/types'

import { PLAYER_MODES, getPlayerType } from '@widgets/Video/utils'

interface VideoMainElementTrackingValues {
  videoId: string
  duration: number
  videoTitle: string
  playerType: PlayerType
}

export const getVideoMainElementTrackingValues = (
  pageMetadata: PageMetadata
): VideoMainElementTrackingValues | object => {
  const videoMainElement = pageMetadata?.schema?.video

  if (!videoMainElement) {
    return {}
  }

  return {
    videoId: videoMainElement?.videoId,
    duration: videoMainElement?.duration,
    videoTitle: videoMainElement?.title,
    playerType: getPlayerType({
      isBlickTV: false,
      duration: videoMainElement?.duration,
    }),
  }
}

export const getPlayerStatus = ({
  widgetId,
  isInBlickBitesFastlane,
}: TrackingGetPlayerStatusProps): VideoPlayerStatusTracking | null => {
  const jwPlayer = getJWPlayer(widgetId)

  if (!jwPlayer) {
    console.error('Could not get player status: player is not ready!')
    return null
  }

  return {
    audio: !jwPlayer.getMute(),
    captions: isPlayerCaptionsEnabled(jwPlayer),
    playerMode: isInBlickBitesFastlane
      ? PLAYER_MODES.FASTLANE
      : isPlayerInOverlay(jwPlayer)
        ? PLAYER_MODES.OVERLAY
        : PLAYER_MODES.INLINE,
    floating: !!jwPlayer.getPip?.(),
  }
}

export const extractErrorObj = ({ error }: any) => error
