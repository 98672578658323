import { FunctionComponent, useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { mobileCSS } from '@measures/responsive'
import { calculateSingleConstraintDimensions } from '@components/FreeRatioImage/utils'
import { TextboxImageProps } from './types'
import FreeRatioImage from '@components/FreeRatioImage'
import useViewportType from '@hooks/useViewport/useViewportType'
import useViewportDimensions from '@hooks/useViewport/useViewportDimensions'

interface StyledTextboxImageProps {
  mobileWidth: number
  mobileHeight: number
}

const StyledTextboxImage = styled.div<StyledTextboxImageProps>`
  ${({ mobileWidth, mobileHeight }) => {
    return css`
      width: auto;
      height: auto;

      ${mobileCSS(css`
        width: ${mobileWidth}px;
        height: ${mobileHeight}px;
        > * {
          width: 100%;
          height: 100%;
        }
        picture > img {
          width: 100%;
          height: 100%;
        }
      `)}
    `
  }}
`

const TextboxImage: FunctionComponent<TextboxImageProps> = ({
  children: { src, alt, width, height, crops, alignment },
  isPromo,
}) => {
  const freeCrop = crops?.['free']
  const originalDimensions = freeCrop
    ? { width: freeCrop.width, height: freeCrop.height }
    : { width, height }
  const maxWidthDesktop =
    alignment === 'full-width' ? (isPromo ? 580 : 528) : isPromo ? 290 : 264
  const maxWidthTablet =
    alignment === 'full-width' ? (isPromo ? 584 : 532) : isPromo ? 292 : 266
  const maxWidthMobile = 412
  const maxWidthMobileSmall = 320

  const viewportType = useViewportType()
  const { width: viewportWidth } = useViewportDimensions()

  const [actualMobileWidth, setActualMobileWidth] = useState<number>(0)
  const [actualMobileHeight, setActualMobileHeight] = useState<number>(0)

  useEffect(() => {
    if (viewportType === 'mobile') {
      // Brand box has no border of 4px
      const spacingAround = isPromo ? 2 * (20 + 12) : 2 * (20 + 12) + 4
      const constraintWidth = viewportWidth - spacingAround
      const { width: calculatedMobileWidth, height: calculatedMobileHeight } =
        calculateSingleConstraintDimensions({
          constraint: {
            width: constraintWidth,
            height: originalDimensions.height,
          },
          originalDimensions,
        })
      setActualMobileWidth(calculatedMobileWidth)
      setActualMobileHeight(calculatedMobileHeight)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewportType, viewportWidth])

  return (
    <StyledTextboxImage
      mobileWidth={actualMobileWidth}
      mobileHeight={actualMobileHeight}>
      <FreeRatioImage
        src={src ?? ''}
        alt={alt ?? ''}
        originalDimensions={{ width, height }}
        constraints={{
          desktop: {
            width: maxWidthDesktop,
            height: originalDimensions.height,
          },
          tablet: { width: maxWidthTablet, height: originalDimensions.height },
          mobile: { width: maxWidthMobile, height: originalDimensions.height },
          mobileSmall: {
            width: maxWidthMobileSmall,
            height: originalDimensions.height,
          },
        }}
        crops={crops ?? {}}
      />
    </StyledTextboxImage>
  )
}

export default TextboxImage
