import { FunctionComponent } from 'react'
import dynamic from 'next/dynamic'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { SportTableInlineProps } from './types'
import useSportsStandings from '@hooks/useSportsStandings'
import styled, { css } from 'styled-components'

const SportTable = dynamic(() => import('@components/SportTableInline/Table'))

const StyledTableContainer = styled.div`
  ${({
    theme: {
      spacing: { spacing40 },
    },
  }) => css`
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    align-items: flex-start;
    grid-row-gap: ${spacing40};
    margin: 0;
  `}
`

const SportTableInline: FunctionComponent<SportTableInlineProps> = ({
  currentSeasonId: seasonId,
  typeOfSport,
  numOfTeams,
  customTitle,
  sponsor,
}) => {
  const sportsStandingsData = useSportsStandings({
    customTitle,
    numOfTeams,
    seasonId,
    typeOfSport,
  })

  if (!sportsStandingsData) {
    return null
  }

  return (
    <StyledTableContainer>
      {sportsStandingsData.tables.map(({ title, dataRows, legend }, index) => (
        <SportTable
          key={`table-of-${typeOfSport}-season-${seasonId}-table-${index}`}
          title={title}
          dataRows={dataRows}
          legend={legend}
          header={sportsStandingsData.header}
          outcomeTypeColors={sportsStandingsData.outcomeTypeColors}
          sponsor={sponsor}
        />
      ))}
    </StyledTableContainer>
  )
}

const widget = {
  kind: ['widget', 'sport', 'table-inline'],
  component: SportTableInline,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
