import { useCallback, useEffect, useRef, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'

type UseIsBlickPlusOfferShown = () => boolean

const useIsBlickPlusOfferShown: UseIsBlickPlusOfferShown = () => {
  const queryClient = useQueryClient()
  const queryCache = queryClient.getQueryCache()
  const unsubscribeFnRef = useRef<() => void>()

  const [isBlickPlusOfferShown, setIsBlickPlusOfferShown] = useState<boolean>(
    () => !!queryClient.getQueryData<boolean>(['isBlickPlusOfferShown'])
  )

  const updateIsBlickPlusOfferShown = useCallback(
    (args: any) => {
      if (
        ['invalidate'].includes(args?.action?.type) &&
        args.query.queryKey[0] === 'isBlickPlusOfferShown'
      ) {
        setIsBlickPlusOfferShown(
          !!queryClient.getQueryData<boolean>(['isBlickPlusOfferShown'])
        )
      }
    },
    [queryClient]
  )

  useEffect(() => {
    unsubscribeFnRef.current = queryCache.subscribe(updateIsBlickPlusOfferShown)

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [updateIsBlickPlusOfferShown, queryCache])

  return isBlickPlusOfferShown
}

export default useIsBlickPlusOfferShown
