import { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'

const StyledPlaceholder = styled.div`
  width: 100%;
  height: 100%;

  background: #000000;
`

const Placeholder: FunctionComponent<{ children?: ReactNode }> = ({
  children,
}) => <StyledPlaceholder>{children}</StyledPlaceholder>

export default Placeholder
