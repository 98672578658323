import { FunctionComponent, useContext } from 'react'

import { CommentingPromptProps } from './types'
import GenericContext from '@contexts/commenting/genericContext'
import translate from '@i18n'
import useUser from '@hooks/useUser'
import styled, { css } from 'styled-components'

const PromptContentButton = styled.button`
  ${({
    theme: {
      color: {
        tertiary: { grey400, grey600 },
      },
      spacing: { spacing8, spacing24 },
      typography: {
        bodycopy: {
          small2: { bundledCSS: bodycopySmall2CSS },
        },
      },
    },
  }) => css`
    display: block;
    width: 100%;
    box-sizing: border-box;
    border-radius: 74px;
    border: 1px solid ${grey400};
    background: transparent;
    padding: 14px ${spacing8} 14px ${spacing24};
    color: ${grey600};
    text-align: left;
    ${bodycopySmall2CSS};
    -moz-appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
  `}
`

const CommentingPrompt: FunctionComponent<CommentingPromptProps> = ({
  onClick,
  type,
}) => {
  const userData = useUser()

  const { requireValidUser } = useContext(GenericContext)

  return (
    <PromptContentButton
      type="button"
      onClick={requireValidUser(() => onClick())}>
      {!!userData
        ? userData.email_only
          ? type === 'comment'
            ? translate('commenting.commentPlaceholderEmailOnly')
            : translate('commenting.answerPlaceholderEmailOnly')
          : type === 'comment'
            ? translate('commenting.commentPlaceholder')
            : translate('commenting.answerPlaceholder')
        : type === 'comment'
          ? translate('commenting.commentPlaceholderNotLoggedIn')
          : translate('commenting.answerPlaceholderNotLoggedIn')}
    </PromptContentButton>
  )
}

export default CommentingPrompt
