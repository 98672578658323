import { LocalReaction, Reaction } from './types'

export const reactionMapping: {
  [reaction in LocalReaction]: Reaction
} = {
  heart: 'like',
  bolt: 'dislike',
  happy: 'reaction1',
  sad: 'reaction2',
  critical: 'reaction3',
}
