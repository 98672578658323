import { LIVE_EVENT_DATA_SPORT_TYPES } from '@utils/formatters/common'
import { FunctionComponent } from 'react'
import HockeySoccer from './HockeySoccer'
import { TransformedData } from '../utils'
import SkiFormulaOne from './SkiFormulaOne'
import Tennis from './Tennis'

interface AllSportsProps {
  sportsData: TransformedData
  className?: string
}

const AllSports: FunctionComponent<AllSportsProps> = ({
  sportsData,
  className,
}) => {
  if (
    LIVE_EVENT_DATA_SPORT_TYPES.SOCCER === sportsData.typeOfSport ||
    LIVE_EVENT_DATA_SPORT_TYPES.HOCKEY === sportsData.typeOfSport
  ) {
    return (
      <HockeySoccer
        sportsData={sportsData}
        viewType="full"
        className={className}
      />
    )
  }

  if (
    LIVE_EVENT_DATA_SPORT_TYPES.FORMULA1 === sportsData.typeOfSport ||
    LIVE_EVENT_DATA_SPORT_TYPES.SKI === sportsData.typeOfSport
  ) {
    return <SkiFormulaOne sportsData={sportsData} className={className} />
  }

  if (LIVE_EVENT_DATA_SPORT_TYPES.TENNIS === sportsData.typeOfSport) {
    return <Tennis sportsData={sportsData} className={className} />
  }
  return
}

export default AllSports
