const ALLOWED_TAB_TYPES = {
  editorial: 'editorial',
  lineup: 'lineup',
  ranking: 'ranking',
  result: 'result',
  schedule: 'schedule',
  sportstable: 'sportstable',
  statistics: 'statistics',
  statisticsPlayer: 'statisticsPlayer',
  startingLineup: 'startingLineup',
  startingList: 'startingList',
  worldCup: 'worldCup',
} as const

export { ALLOWED_TAB_TYPES }
