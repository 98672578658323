import { FunctionComponent, useCallback } from 'react'
import styled, { css } from 'styled-components'
import useIsDarkModeInSocialButton from '@hooks/useIsDarkModeInSocialButton'
import { printCSS } from '@utils/style'
import { useQueryClient } from '@tanstack/react-query'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import { stripHtml } from '@hooks/useTracking/utils'
import { scrollToCommentingWidget } from '@widgets/Commenting/utils'
import useCommentsNumber from '@hooks/useCommentsNumber'
import PrimaryCTAButton from '@components/Buttons/PrimaryCTAButton'
import SecondaryCTAButton from '@components/Buttons/SecondaryCTAButton'

const StyledPrimaryCTAButton = styled(PrimaryCTAButton)`
  ${({
    theme: {
      color: {
        primary: { primary02 },
        tertiary: { grey800 },
      },
    },
  }) => css`
    background-color: ${grey800};
    color: ${primary02};

    & > svg {
      fill: ${primary02};
    }

    ${printCSS(css`
      display: none;
    `)}
  `}
`

const StyledSecondaryCTAButton = styled(SecondaryCTAButton)`
  ${printCSS(css`
    display: none;
  `)}
`

const CommentButton: FunctionComponent = () => {
  const isDarkMode = useIsDarkModeInSocialButton()
  const queryClient = useQueryClient()
  const commentsNumber = useCommentsNumber()

  const articleClickShowCommentHandler = useCallback<TrackingFnType>(
    ({ pageMetadata }) => ({
      event: 'article_show_comments',
      eventCategory: 'article',
      eventAction: 'show_comments',
      eventLabel: `${pageMetadata.id}:${stripHtml(pageMetadata.title)}`,
    }),
    []
  )

  const handleArticleClickShowCommentTracking = useTracking(
    articleClickShowCommentHandler
  )

  const handleClick = useCallback(() => {
    handleArticleClickShowCommentTracking()
    scrollToCommentingWidget(queryClient)
  }, [handleArticleClickShowCommentTracking, queryClient])

  return isDarkMode ? (
    <StyledPrimaryCTAButton
      colorVariant="grey"
      size="small"
      iconName="message-lines"
      iconLeft={true}
      onClick={handleClick}>
      {commentsNumber}
    </StyledPrimaryCTAButton>
  ) : (
    <StyledSecondaryCTAButton
      size="small"
      iconName="message-lines"
      iconLeft={true}
      onClick={handleClick}>
      {commentsNumber}
    </StyledSecondaryCTAButton>
  )
}

export default CommentButton
