import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

interface ErrorMessageProps {
  children: string
}

const StyledErrorMessage = styled.div`
  ${({
    theme: {
      color: {
        primary: { blickRed: blickRedColor },
      },
      typography: {
        bodycopy: {
          xxsmall: { bundledCSS: xxsmallBodycopyCSS },
        },
      },
    },
  }) => css`
    ${xxsmallBodycopyCSS};
    color: ${blickRedColor};
  `}
`

const ErrorMessage: FunctionComponent<ErrorMessageProps> = ({ children }) => (
  <StyledErrorMessage>{children}</StyledErrorMessage>
)

export default ErrorMessage
