import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { desktopCSS } from '@measures/responsive'
import useIsInHerotellingPage from '@hooks/useIsInHerotellingPage'
import useHasInvertedColors from '@hooks/useHasInvertedColors'

type WrapperProps = {
  isInHerotellingPage: boolean
  hasInvertedColors: boolean
}

const Wrapper = styled.div<WrapperProps>`
  ${({
    theme: {
      color: {
        primary: { primary01, primary02 },
      },
      spacing: { spacing16 },
      typography: {
        bodycopy: {
          medium1: { bundledCSS: medium1BodyCopyCSS },
          small1: { bundledCSS: small1BodyCopyCSS },
        },
      },
    },
    isInHerotellingPage,
    hasInvertedColors,
  }) => css`
    ${small1BodyCopyCSS};
    margin-top: ${spacing16};
    color: ${hasInvertedColors || isInHerotellingPage ? primary02 : primary01};
    text-align: left;

    ${isInHerotellingPage && 'text-align: inherit'};
    ${desktopCSS(css`
      ${medium1BodyCopyCSS};
    `)}
  `}
`

const ArticleLead: FunctionComponent<{ children?: ReactNode }> = ({
  children,
}) => {
  const isInHerotellingPage = useIsInHerotellingPage()
  const hasInvertedColors = useHasInvertedColors()

  return (
    <Wrapper
      isInHerotellingPage={isInHerotellingPage}
      hasInvertedColors={hasInvertedColors}>
      {children}
    </Wrapper>
  )
}

export default ArticleLead
