import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import ReactJWPlayer from './JwLibrary/lib'
import { JWPlayer } from './JwLibrary/types'

type ToiBackgroundLiveStreamVideoProps = {
  src: string
  className?: string
  onPlay?: () => void
  onError?: () => void
}

const ToiBackgroundLiveStreamVideo: FunctionComponent<
  ToiBackgroundLiveStreamVideoProps
> = ({ src, className, onPlay, onError }) => {
  const [setupFinished, setSetupFinished] = useState(false)
  const playerRef = useRef<JWPlayer>()

  const onSetup = useCallback((playerInstance: JWPlayer) => {
    playerRef.current = playerInstance

    setSetupFinished(true)
  }, [])

  const onPlayHandler = useCallback(() => {
    // disable captions
    playerRef.current?.setCurrentCaptions(0)
    onPlay?.()
  }, [onPlay])

  useEffect(() => {
    const player = playerRef.current

    if (setupFinished && player && onPlayHandler) {
      player.on('play', onPlayHandler)

      return () => {
        player.off('play', onPlayHandler)
      }
    }
  }, [onPlayHandler, setupFinished])

  useEffect(() => {
    const player = playerRef.current

    if (setupFinished && player && onError) {
      player.on('error', onError)

      return () => {
        player.off('error', onError)
      }
    }
  }, [onError, setupFinished])

  const playlist = useMemo(
    () => [{ file: src } as jwplayer.PlaylistItem],
    [src]
  )

  useEffect(() => {
    return () => {
      playerRef.current?.remove()
    }
  }, [])

  return (
    <ReactJWPlayer
      autostart={true}
      mute={true}
      controls={false}
      className={className}
      playlist={playlist}
      onSetup={onSetup}
      aspectratio="16:9"
      stretching="exactfit"
      height={320}
      width="100%"
      displaytitle={false}
      pipIcon="disabled"
      allowFullscreen={false}
      renderCaptionsNatively={false}
    />
  )
}

export default ToiBackgroundLiveStreamVideo
