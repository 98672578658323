import { FunctionComponent } from 'react'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { Bulletpoints, BulletpointsItem } from '@components/Bulletpoints'

interface BulletpointsWidgetProps {
  bulletpoints?: string[]
}

type BulletpointsType = string[]

const isValidBulletpoints = (
  bulletpoints: any
): bulletpoints is BulletpointsType => bulletpoints?.length > 0

const BulletpointsWidget: FunctionComponent<BulletpointsWidgetProps> = (
  props
) => {
  const { bulletpoints } = props

  if (!isValidBulletpoints(bulletpoints)) {
    return null
  }

  return (
    <Bulletpoints>
      {bulletpoints.map((bulletpoint, index) => (
        <BulletpointsItem key={index}>{bulletpoint}</BulletpointsItem>
      ))}
    </Bulletpoints>
  )
}

const widget = {
  kind: ['widget', 'bulletpoints'],
  component: BulletpointsWidget,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
