import { FunctionComponent, useMemo } from 'react'
import styled, { css } from 'styled-components'
import VodCaptions from '@components/Video/VideoPlayer/JwLibrary/components/Captions/VodCaptions'
import { getJWPlayer } from '@utils/videoPlayer'

interface CaptionsProps {
  widgetId: string
  captionsEnabled: boolean
  onClick?: () => void
}

const CaptionsWrapper = styled.div`
  ${({
    theme: {
      typography: {
        bodycopy: {
          small2: { bundledCSS: small2BodycopyCSS },
        },
      },
    },
  }) => css`
    position: relative;

    width: 100%;
    height: 200px;

    display: grid;
    align-items: flex-end;
    justify-content: center;

    div {
      ${small2BodycopyCSS};
    }
  `}
`

const Captions: FunctionComponent<CaptionsProps> = ({
  widgetId,
  captionsEnabled,
  onClick,
}) => {
  const player = useMemo(() => getJWPlayer(widgetId), [widgetId])

  return (
    player && (
      <CaptionsWrapper onClick={onClick}>
        <VodCaptions player={player} disabled={!captionsEnabled} />
      </CaptionsWrapper>
    )
  )
}

export default Captions
