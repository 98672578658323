import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import useScoreboard from '@hooks/useScoreboard'
import { LIVE_EVENT_DATA_SPORT_TYPES } from '@utils/formatters/common'

const StyledScoreboardMatchAdditionalTime = styled.div`
  ${({
    theme: {
      typography: {
        eyebrows: {
          small1: { bundledCSS: small1EyebrowCSS },
        },
      },
      color: {
        tertiary: { grey700: grey700Color },
      },
    },
  }) => css`
    align-items: center;
    color: ${grey700Color};
    display: flex;
    text-align: center;
    ${small1EyebrowCSS};
  `}
`

const ScoreboardAggregate: FunctionComponent = () => {
  const { formattedData } =
    useScoreboard<typeof LIVE_EVENT_DATA_SPORT_TYPES.SOCCER>()

  const { aggregateIndicator, teams } = formattedData

  const awayAggregationScore = teams[1].aggregationScore
  const homeAggregationScore = teams[0].aggregationScore

  return (
    <StyledScoreboardMatchAdditionalTime>
      {aggregateIndicator} {homeAggregationScore}:{awayAggregationScore}
    </StyledScoreboardMatchAdditionalTime>
  )
}

export default ScoreboardAggregate
