import FadeOverlay from '@components/FadeOverlay'
import contextArticleTextbox from '@contexts/articleTextbox'
import useHasInvertedColors from '@hooks/useHasInvertedColors'
import { FunctionComponent, useContext } from 'react'

const { ArticleTextboxContext } = contextArticleTextbox

const TextboxFadeOverlay: FunctionComponent = () => {
  const { isExpanded } = useContext(ArticleTextboxContext)
  const hasInvertedColors = useHasInvertedColors()

  return (
    <FadeOverlay
      isExpanded={isExpanded}
      hasInvertedColors={hasInvertedColors}
    />
  )
}

export default TextboxFadeOverlay
