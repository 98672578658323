import useAuthentication from '@hooks/useAuthentication'
import useToggleMenuVisibility from '@hooks/useToggleMenuVisibility'
import useUser from '@hooks/useUser'
import useUserMenuVisibility from '@hooks/useUserMenuVisibility'
import translate from '@i18n'
import { desktopCSS, mobileAndTabletCSS } from '@measures/responsive'
import { FunctionComponent } from 'react'
import styled, { css, keyframes, useTheme } from 'styled-components'
import IconButton from '@components/Buttons/IconButton'
import UserMenuContent from './UserMenuContent'
import SvgIcon from '@components/SvgIcon'
import useIsImmersiveHeaderActive from '@hooks/useIsImmersiveHeaderActive'

const fadeAnimation = keyframes`
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`

const Overlay = styled.div`
  display: flex;
  flex-direction: column;

  position: fixed;
  top: 48px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;

  background-color: transparent;
  animation: ${fadeAnimation} ${({ theme }) => theme.animation.transition.fast}
    ease-out;
  animation-iteration-count: 1;

  ${desktopCSS(css`
    top: 60px;
  `)}
`

interface StyledUserMenuProps {
  isVisible: boolean
}

const StyledUserMenu = styled.div<StyledUserMenuProps>`
  ${({ isVisible }) =>
    !isVisible &&
    css`
      display: none;
    `}
`

const UserLoginIconButton = styled(IconButton)<{
  isImmersiveHeaderActive: boolean
}>`
  ${({
    theme: {
      color: {
        primary: { primary02: primary02Color, blickRed: blickRedColor },
      },
    },
    isImmersiveHeaderActive,
  }) => css`
    ${desktopCSS(css`
      width: 40px;
      height: 40px;
    `)};

    ${isImmersiveHeaderActive &&
    css`
      ${mobileAndTabletCSS(css`
        > svg {
          fill: ${primary02Color};
        }
      `)}
    `}

    &:hover {
      svg {
        fill: ${blickRedColor};
      }
    }
  `}
`

const UserLoggedInButton = styled.button<{ isImmersiveHeaderActive: boolean }>`
  ${({
    theme: {
      color: {
        primary: { primary02: primary02Color, blickRed: blickRedColor },
      },
    },
    isImmersiveHeaderActive,
  }) => css`
    width: 48px;
    height: 48px;

    background-color: transparent;
    border: 0;
    cursor: pointer;
    padding: 0;
    line-height: 0;

    display: grid;
    align-items: center;
    justify-content: center;

    ${desktopCSS(css`
      width: 40px;
      height: 40px;
    `)};

    ${isImmersiveHeaderActive &&
    css`
      ${mobileAndTabletCSS(css`
        > svg {
          fill: ${primary02Color};
        }
      `)}
    `}

    &:hover {
      > svg {
        fill: ${blickRedColor};
      }

      > div {
        background-color: ${blickRedColor};
      }
    }
  `}
`

const StyledSvgIcon = styled(SvgIcon)<{ isImmersiveHeaderActive: boolean }>`
  ${({
    theme: {
      color: {
        primary: { primary02: primary02Color },
      },
    },
    isImmersiveHeaderActive,
  }) => css`
    ${isImmersiveHeaderActive &&
    css`
      ${mobileAndTabletCSS(css`
        fill: ${primary02Color};
      `)}
    `}
  `}
`

const UserInitialsIcon = styled.div<{ isImmersiveHeaderActive: boolean }>`
  ${({
    theme: {
      typography: {
        eyebrows: { small2: small2EyebrowsCSS },
      },
      color: {
        primary: { primary02: primary02Color, primary01: primary01Color },
      },
    },
    isImmersiveHeaderActive,
  }) => css`
    ${small2EyebrowsCSS}

    width: 28px;
    height: 28px;
    color: ${primary02Color};
    background-color: #000000;
    border-radius: 50%;

    text-transform: uppercase;

    display: flex;
    align-items: center;
    justify-content: center;

    ${isImmersiveHeaderActive &&
    css`
      ${mobileAndTabletCSS(css`
        color: ${primary01Color};
        background-color: ${primary02Color};
      `)}
    `}
  `}
`

const ButtonAndMenuContainer = styled.div`
  ${desktopCSS(css`
    position: relative;
  `)}
`

const UserMenu: FunctionComponent = () => {
  const theme = useTheme()
  const isImmersiveHeaderActive = useIsImmersiveHeaderActive()
  const userMenuVisible = useUserMenuVisibility()
  const { toggleUserMenu } = useToggleMenuVisibility()
  const { login } = useAuthentication()
  const user = useUser()

  const onLogin = () => login({ prefix: 'DIR', loginCase: 'email_only' })

  return (
    <Wrapper>
      {!!user ? (
        <ButtonAndMenuContainer>
          <UserLoggedInButton
            aria-label={translate('login.myAccount')}
            onClick={toggleUserMenu}
            isImmersiveHeaderActive={isImmersiveHeaderActive}>
            {!user.email_only && user.initials ? (
              <UserInitialsIcon
                isImmersiveHeaderActive={isImmersiveHeaderActive}>
                {user.initials.substring(0, 2)}
              </UserInitialsIcon>
            ) : (
              <StyledSvgIcon
                iconName="signed-in"
                size={24}
                color={theme.color.primary.primary01}
                isImmersiveHeaderActive={isImmersiveHeaderActive}
              />
            )}
          </UserLoggedInButton>
          <StyledUserMenu isVisible={userMenuVisible}>
            <Overlay onClick={toggleUserMenu} />
          </StyledUserMenu>
          <UserMenuContent isVisible={userMenuVisible} user={user} />
        </ButtonAndMenuContainer>
      ) : (
        <UserLoginIconButton
          ariaLabel="login"
          buttonSize={48}
          onClick={onLogin}
          iconName="sign-in"
          iconSize={24}
          iconColor={theme.color.primary.primary01}
          isImmersiveHeaderActive={isImmersiveHeaderActive}
        />
      )}
    </Wrapper>
  )
}

export default UserMenu
