import { MainMenuItem } from '@hooks/useNavigation'
import { FunctionComponent } from 'react'
import styled from 'styled-components'
import SecondLevelItem from './SecondLevelItem'

interface SecondLevelMenuProps {
  children: MainMenuItem['content']
  isVisible: boolean
}

const ItemAndSubmenuWrapper = styled.div`
  overflow: hidden;
  height: auto;
`

const SecondLevelMenu: FunctionComponent<SecondLevelMenuProps> = ({
  children,
  isVisible,
}) => {
  if (!children?.length || !isVisible) {
    return null
  }

  return (
    <ItemAndSubmenuWrapper>
      {children?.map((item) => <SecondLevelItem key={item.title} {...item} />)}
    </ItemAndSubmenuWrapper>
  )
}

export default SecondLevelMenu
