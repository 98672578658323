import { MainMenuItemLevel2 } from '@hooks/useNavigation'
import { FunctionComponent } from 'react'
import styled from 'styled-components'
import ThirdLevelItem from './ThirdLevelItem'

interface ThirdLevelMenuProps {
  children: MainMenuItemLevel2['content']
  isVisible: boolean
}

const ItemAndSubmenuWrapper = styled.div`
  overflow: hidden;
`

const ThirdLevelMenu: FunctionComponent<ThirdLevelMenuProps> = ({
  children,
  isVisible,
}) => {
  if (!children?.length || !isVisible) {
    return null
  }

  return (
    <ItemAndSubmenuWrapper>
      {children?.map((item) => <ThirdLevelItem key={item.title} {...item} />)}
    </ItemAndSubmenuWrapper>
  )
}

export default ThirdLevelMenu
