import { ConfigProps } from '@config/types'

const config = ({}: ConfigProps) =>
  ({
    baseUrl: {
      media: 'https://cdn.jwplayer.com/v2/media/',
    },
    staticPlayerParams: {
      horizontalVolumeSlider: true,
      renderCaptionsNatively: false,
      // default values
      aspectratio: '16:9',
      displayHeading: false,
      displaydescription: false,
      displaytitle: false,
      height: 270,
      preload: 'none',
      stretching: 'exactfit',
      width: '100%',
      floating: {
        dismissible: true,
        mode: 'never',
      },
    },
  }) as const satisfies Record<string, unknown>

export default config
