import { FunctionComponent } from 'react'
import { SocialItemType } from '@widgets/AuthorHeadline/types'
import styled, { useTheme, css } from 'styled-components'
import SvgIcon from '@components/SvgIcon'
import Blink from '@components/Blink'

interface SocialItemProps {
  type: SocialItemType
  src?: string
}

const StyledBlink = styled(Blink)`
  ${({
    theme: {
      color: {
        tertiary: { grey300, grey400 },
      },
    },
  }) => {
    return css`
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      border-radius: 64px;
      cursor: pointer;
      background-color: ${grey300};
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      user-select: none;

      &:hover {
        background-color: ${grey400};
      }
    `
  }}
`

const additionalStyles = css`
  width: 28px;
`

const SocialItem: FunctionComponent<SocialItemProps> = ({ type, src }) => {
  const theme = useTheme()
  const iconColor = theme.color.primary.primary01

  if (!src) {
    return null
  }

  const preparedLink = `${type === 'mail' ? 'mailto:' : ''}${src}`

  const linkProps = {
    href: preparedLink,
    target: '_blank',
    rel: 'nofollow noopener',
  }

  return (
    <StyledBlink {...linkProps}>
      <SvgIcon
        iconName={`${type}-plain`}
        size={20}
        color={iconColor}
        additionalStyles={additionalStyles}
      />
    </StyledBlink>
  )
}

export default SocialItem
