import { FunctionComponent, useCallback, useContext } from 'react'
import SocialButton from './Button'
import EndOfArticleContext from '@contexts/endOfArticle'
import { stripHtml } from '@hooks/useTracking/utils'
import useTracking, { TrackingFnType } from '@hooks/useTracking'

interface PrintProps {
  label?: string
}

const Print: FunctionComponent<PrintProps> = ({ label }) => {
  const endOfArticle = useContext(EndOfArticleContext)
  const trackingOnClick = useCallback<TrackingFnType>(
    () => ({
      event: 'element_click',
      element: `article_share_${endOfArticle ? 'bottom' : 'top'}`,
      method: 'print',
    }),
    [endOfArticle]
  )
  const trackingOnClickLegacy = useCallback<TrackingFnType>(
    ({ pageMetadata }) => ({
      event: 'article_share',
      eventCategory: 'article',
      eventAction: `share_print_${endOfArticle ? 'bottom' : 'top'}`,
      eventLabel: `${pageMetadata.id}:${stripHtml(pageMetadata.title)}`,
    }),
    [endOfArticle]
  )
  const trackingFunction = useTracking(trackingOnClick)
  const trackingFunctionLegacy = useTracking(trackingOnClickLegacy)

  const handlePrint = useCallback(() => {
    trackingFunction()
    // The *Legacy tracking functions will be deleted when the tracking team is ready to switch to the new events
    trackingFunctionLegacy()
    print()
  }, [trackingFunction, trackingFunctionLegacy])

  return (
    <SocialButton onClick={handlePrint} type="print">
      {label}
    </SocialButton>
  )
}

export default Print
