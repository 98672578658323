import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { JSONWidgetType as CommentingJSONWidgetType } from '@widgets/Commenting'
import { JSONWidgetType as ToplistJSONWidgetType } from '@widgets/Toplist'
import { JSONWidgetType as AdRingierJSONWidgetType } from '@widgets/AdRingier'
import JSONRenderer from '@components/JSONRenderer'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { desktopCSS } from '@measures/responsive'
import usePageMetadata from '@hooks/usePageMetadata'
import { isVideoOnlyArticle } from '@utils/cook'

export interface CommentingSectionAPIProps {
  commenting: CommentingJSONWidgetType
  toplist: ToplistJSONWidgetType
  ad?: AdRingierJSONWidgetType
}

const StyledWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing32 },
    },
  }) => css`
    display: grid;
    grid-gap: ${spacing32};
  `}
`
const StyledToplistWrapper = styled.div<{ positionToplistAbsolutely: boolean }>`
  ${({ positionToplistAbsolutely }) => css`
    ${positionToplistAbsolutely &&
    desktopCSS(css`
      position: absolute;
      left: calc(632px + 15px + 32px);
    `)}
    &:empty {
      display: none;
    }
  `}
`

const CommentingSection: FunctionComponent<CommentingSectionAPIProps> = ({
  commenting,
  toplist,
  ad,
}) => {
  const { hasSideBar, targetContentType } = usePageMetadata()
  // TODO: Adjust this when we add the article-sidebar-content widget in VO
  const isVideoOnly = isVideoOnlyArticle(targetContentType)
  const positionToplistAbsolutely = hasSideBar || isVideoOnly

  return (
    <StyledWrapper>
      <JSONRenderer>{commenting}</JSONRenderer>
      <JSONRenderer>{ad}</JSONRenderer>
      <StyledToplistWrapper
        positionToplistAbsolutely={!!positionToplistAbsolutely}>
        <JSONRenderer>{toplist}</JSONRenderer>
      </StyledToplistWrapper>
    </StyledWrapper>
  )
}

const widget = {
  kind: ['commenting-section'],
  component: CommentingSection,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
