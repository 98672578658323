import { FunctionComponent, useCallback } from 'react'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import config from '@config'
import styled, { css, useTheme } from 'styled-components'
import translate from '@i18n'
import { mobileCSS } from '@measures/responsive'
import SvgIcon from '@components/SvgIcon'

const {
  googleNews: { googleNewsUrl },
} = config

const StyledText = styled.span`
  ${({
    theme: {
      color: {
        primary: { primary01 },
      },
      typography: {
        subheads: {
          small1: { bundledCSS: small1HeadingCSS },
        },
      },
    },
  }) => css`
    color: ${primary01};
    ${small1HeadingCSS}
  `}
`

const StyledSvgIcon = styled(SvgIcon)`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => css`
    margin-right: ${spacing8};
  `}
`

const Wrapper = styled.button`
  ${({
    theme: {
      color: {
        primary: { primary01, blickRed },
      },
      spacing: { spacing4, spacing16 },
    },
  }) => {
    return css`
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      border: 1px solid ${primary01};
      border-radius: 50px;
      cursor: pointer;
      background-color: transparent;
      padding: ${spacing4} ${spacing16};
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      user-select: none;

      &:hover {
        border-color: ${blickRed};

        span {
          color: ${blickRed};
        }

        svg {
          fill: ${blickRed};
        }
      }

      ${mobileCSS(css`
        width: fit-content;
      `)}
    `
  }}
`

const GoogleNews: FunctionComponent = () => {
  const theme = useTheme()

  const trackingOnClick = useCallback<TrackingFnType>(
    () => ({
      method: 'google news',
      event: 'follow',
    }),
    []
  )
  const trackingFunction = useTracking(trackingOnClick)

  const handleClick = useCallback(() => {
    trackingFunction()

    return window.open(googleNewsUrl, '_blank', 'noopener noreferrer nofollow')
  }, [trackingFunction])

  return (
    <Wrapper onClick={handleClick} role="button">
      <StyledSvgIcon
        iconName="google-news"
        size={16}
        color={theme.color.primary.primary01}
      />
      <StyledText>{translate('googleNewsTitle')}</StyledText>
    </Wrapper>
  )
}

export default GoogleNews
