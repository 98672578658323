import { Date as CookDate } from '@utils/cook/types'
import { mobileCSS, tabletCSS } from '@measures/responsive'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import Content from './Content'
import CalendarContextProvider from './ContextProvider'
import Header from './Header'

interface CalendarWidgetProps {
  date: CookDate
}

const Wrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => css`
    width: 300px;
    height: auto;
    padding-bottom: ${spacing8};

    ${mobileCSS(css`
      width: 92vw;
      margin: 0 auto;
    `)}

    ${tabletCSS(css`
      width: 612px;
      margin: 0 auto;
    `)}
  `}
`

const isValidCalendar = (date: any): date is Date => date?.value && date?.format

const CalendarWidget: FunctionComponent<CalendarWidgetProps> = ({ date }) => {
  if (!isValidCalendar(date)) {
    return null
  }

  return (
    <Wrapper>
      <CalendarContextProvider date={date}>
        <Header />
        <Content />
      </CalendarContextProvider>
    </Wrapper>
  )
}

const widget = {
  kind: ['widget', 'calendar'],
  component: CalendarWidget,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
