import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'

const ErrorWrapper = styled.div`
  ${({
    theme: {
      typography: {
        bodycopy: {
          small2: { bundledCSS: small2BodyCopy },
        },
      },
      spacing: { spacing16 },
    },
  }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${spacing16};

    ${small2BodyCopy}
  `}
`

export const ErrorView: FunctionComponent<{ children: ReactNode }> = ({
  children,
}) => {
  return <ErrorWrapper>{children}</ErrorWrapper>
}
