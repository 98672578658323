import { FunctionComponent, useCallback } from 'react'
import styled, { css, useTheme } from 'styled-components'

import usePageMetadata from '@hooks/usePageMetadata'
import SectionHeader from '@components/SectionHeader'
import JSONRenderer from '@components/JSONRenderer'
import useTracking from '@hooks/useTracking'
import { videoPlaylistClickHandler } from '@hooks/useVideoTracking/eventMapper'
import { desktopCSS } from '@measures/responsive'
import { PlaylistItem } from './types'

export interface PlaylistProps {
  title?: string
  items: PlaylistItem[]
}

type StyledPlaylistItemWrapperProps = {
  isActive: boolean
}

const StyledContainer = styled.div`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    display: grid;
    gap: ${spacing16};
    position: relative;

    ${desktopCSS(css`
      display: none;
    `)}
  `}
`

const StyledPlaylistWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    display: grid;
    gap: ${spacing16};
  `}
`

const StyledPlaylistItemWrapper = styled.div<StyledPlaylistItemWrapperProps>`
  ${({
    theme: {
      color: {
        tertiary: { grey900: gray900Color },
      },
    },
    isActive,
  }) => css`
    &:hover {
      background-color: ${gray900Color};
    }

    ${isActive &&
    css`
      background-color: ${gray900Color};

      &:hover {
        background-color: ${gray900Color};
      }
    `}
  `}
`

const Playlist: FunctionComponent<PlaylistProps> = ({
  title: playlistTitle,
  items,
}) => {
  const pageMetadata = usePageMetadata()

  const trackPlaylistClick = useTracking(videoPlaylistClickHandler)

  const onPlaylistItemClick = useCallback(() => {
    trackPlaylistClick({ playlistTitle })
  }, [playlistTitle, trackPlaylistClick])

  const theme = useTheme()

  if (!items) {
    return null
  }

  return (
    <StyledContainer>
      <SectionHeader
        title={playlistTitle}
        accentColor={theme.color.primary.blickRed}
        isSubheader={true}
      />
      <StyledPlaylistWrapper>
        {items.map((item) => (
          <StyledPlaylistItemWrapper
            key={item.articleId}
            isActive={item.articleId === pageMetadata.id}
            onClick={onPlaylistItemClick}>
            <JSONRenderer>
              {{ ...item, kind: ['teaser-standard-horizontal'] }}
            </JSONRenderer>
          </StyledPlaylistItemWrapper>
        ))}
      </StyledPlaylistWrapper>
    </StyledContainer>
  )
}

export default Playlist
