import { LIVE_EVENT_DATA_SPORT_TYPES } from '@utils/formatters/common'
import { ScoreboardProps } from './types'
import { UseScoreboardReturnValue } from '@hooks/useScoreboard'

const { SOCCER, HOCKEY, SKI, FORMULA1, TENNIS } = LIVE_EVENT_DATA_SPORT_TYPES

const isValidScoreboardProps = (props: any): props is ScoreboardProps =>
  [SOCCER, HOCKEY, SKI, FORMULA1, TENNIS].includes(props.typeOfSport)

const createPlaceholderData = <T extends ScoreboardProps['typeOfSport']>(
  typeOfSport: T
) =>
  ({
    isPlaceholderData: true,
    sportsEventType: typeOfSport,
    formattedData: {
      competitors: [],
      statusTitle: '\u00a0'.repeat(17),
      isScheduled: true,
      hasPenalties: false,
      teams: [{}, {}],
    },
  }) as unknown as UseScoreboardReturnValue<T>

export { isValidScoreboardProps, createPlaceholderData }
