import { FunctionComponent, RefCallback } from 'react'
import styled, { css } from 'styled-components'
import PrimaryCTAButton from '@components/Buttons/PrimaryCTAButton'

interface MoreButtonSegmentProps {
  accentColor?: string
  children: string
  viewportRef: RefCallback<Element>
  onLoadMoreItems: () => void
}

const StyledMoreButtonSegment = styled.div<
  Pick<MoreButtonSegmentProps, 'accentColor'>
>`
  ${({
    theme: {
      color: {
        primary: { blickRed },
      },
      spacing: { spacing16 },
    },
    accentColor,
  }) => css`
    display: grid;
    align-items: center;
    justify-items: center;
    padding-top: ${spacing16};

    border-left: 2px solid ${accentColor ?? blickRed};
  `}
`

const MoreButtonSegment: FunctionComponent<MoreButtonSegmentProps> = ({
  accentColor,
  children,
  viewportRef,
  onLoadMoreItems,
}) => (
  <StyledMoreButtonSegment accentColor={accentColor}>
    <PrimaryCTAButton ref={viewportRef} size="small" onClick={onLoadMoreItems}>
      {children}
    </PrimaryCTAButton>
  </StyledMoreButtonSegment>
)

export default MoreButtonSegment
