import { FunctionComponent, ReactNode } from 'react'
import VideoPlayerWrapper from '@components/Video/VideoPlayer/VideoPlayerWrapper'

interface WrapperProps {
  children: ReactNode
}

const TrailerBlockerOverlayWrapper: FunctionComponent<WrapperProps> = ({
  children,
}) => <VideoPlayerWrapper>{children}</VideoPlayerWrapper>

export default TrailerBlockerOverlayWrapper
