import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { mobileCSS } from '@measures/responsive'

interface ResultsHeaderProps {
  children: ReactNode[]
}

const StyledResultHeader = styled.div`
  ${({
    theme: {
      spacing: { spacing16, spacing32 },
      color: {
        tertiary: { grey300 },
      },
    },
  }) => css`
    display: flex;
    flex-direction: column;
    margin-bottom: ${spacing16};
    ${mobileCSS(css`
      margin-bottom: ${spacing32};
      border-bottom: 1px solid ${grey300};
    `)}
  `}
`

const ResultsHeader: FunctionComponent<ResultsHeaderProps> = ({ children }) => (
  <StyledResultHeader>{children}</StyledResultHeader>
)

export default ResultsHeader
