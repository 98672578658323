import { FunctionComponent, useMemo, useState } from 'react'
import styled, { css, useTheme } from 'styled-components'
import translate from '@i18n'
import SummaryHeader from '@components/Summary/SummaryHeader'
import SvgIcon from '@components/SvgIcon'
import { SummarySlider } from '@components/Summary/SummarySlider'
import SummaryPill from '@components/Summary/SummaryPill'
import SummaryList from '@components/Summary/SummaryList'
import SummaryDisclaimer, {
  type SummaryDisclaimerProps,
} from '@components/Summary/SummaryDisclaimer'
import { SwiperSlide } from '@components/Summary/SummarySliderComponents'
import { trackClickHandler, TrackingClickType } from './utils'
import useTracking from '@hooks/useTracking'
import {
  TAnswerData,
  TLinkData,
  TQuestionData,
  WidgetIdValues,
} from '@utils/chatbot/types'
import useABTestValue from '@hooks/useABTestValue'
import { useQuery } from '@tanstack/react-query'
import { getQuestions, HTTP_METHOD_VALUES } from '@utils/chatbot'
import useIsUserLoggedIn from '@hooks/useIsUserLoggedIn'
import { QuestionContainer, QuestionMessage } from '../ArticleQuestion/Question'
import useChatbot from '@hooks/useChatbot'
import useAuthentication from '@hooks/useAuthentication'
import {
  SkeletonSeedValues,
  SkeletonView,
} from '@components/Summary/SummaryLoadingSkeleton'
import ChatbotInput from '@components/Chatbot/ChatbotInput'
import PrimaryCTAButton from '@components/Buttons/PrimaryCTAButton'
import usePageMetadata from '@hooks/usePageMetadata'

const IconPullRight = styled.div`
  flex: 1;
  display: flex;
  justify-content: right;
  width: 100%;
  cursor: pointer;
`

const StyledPlusIcon = styled(SvgIcon)`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => {
    return css`
      vertical-align: text-bottom;
      margin-right: ${spacing8};
    `
  }}
`

const AdditionQuestionsWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing12 },
    },
  }) => css`
    display: flex;
    flex-direction: column;
    gap: ${spacing12};
    margin-bottom: ${spacing12};
  `}
`

const StyledPrimaryButton = styled(PrimaryCTAButton)`
  display: inline-block;
`

const SummaryResultView: FunctionComponent<{
  title: string
  activeIndex?: number
  onCloseHandler: () => void
  links?: TLinkData
  content?: TAnswerData[]
  disclaimer: SummaryDisclaimerProps
  trackingClickType: TrackingClickType
}> = ({
  title,
  links,
  content,
  disclaimer,
  onCloseHandler,
  activeIndex: initialActiveIndex,
  trackingClickType,
}) => {
  const isLoggedIn = useIsUserLoggedIn()
  const isChatbotEnabled = useABTestValue('showCUI') === 'show'
  const theme = useTheme()
  const pageMetadata = usePageMetadata()
  const { onQuestionClick, isLastQuestion } = useChatbot()
  const [activeIndex, setActiveIndex] = useState<number | undefined>(
    initialActiveIndex
  )
  const { login } = useAuthentication()
  const linksCount = useMemo(() => Object.keys(links ?? {}).length, [links])
  const trackClick = useTracking(trackClickHandler)
  const onLogin = () =>
    login({
      prefix: WidgetIdValues.SectionSummary,
      loginCase: 'email_only',
    })

  const openChatbot = (question: TQuestionData) => {
    if (!isLastQuestion(question.id)) {
      onQuestionClick({
        context: pageMetadata.context,
        isSuggestedQuestion: true,
        id: question.id,
        isCached: false,
        text: question.text,
      })
    }
  }

  const text = content?.map((contentItem) => contentItem.text)?.join(' ')

  const {
    data: nextQuestionData,
    isLoading: nextQuestionIsLoading,
    isError: nextQuestionIsError,
  } = useQuery({
    enabled: !!text && isLoggedIn && isChatbotEnabled,
    queryKey: ['questionSummary.nextQuestion', pageMetadata.context, text],
    queryFn: async () => {
      const data = await getQuestions({
        method: HTTP_METHOD_VALUES.POST,
        body: {
          text,
          context: pageMetadata.context,
        },
      })()

      return data.questions
    },
  })

  const isNextQuestionLoaded =
    nextQuestionData && !nextQuestionIsLoading && !nextQuestionIsError
  return (
    <>
      <SummaryHeader
        prefixIconName="stars"
        after={
          <IconPullRight onClick={onCloseHandler}>
            <SvgIcon
              iconName="xmark-large"
              size={24}
              color={theme.color.primary.primary01}
            />
          </IconPullRight>
        }>
        {title}
      </SummaryHeader>

      {Array.isArray(content) && content?.length > 0 && (
        <SummaryList
          summaries={content ?? []}
          links={links}
          clickHandler={setActiveIndex}
          trackingClickType={trackingClickType}
        />
      )}

      {links && (
        <SummarySlider
          activeIndex={activeIndex}
          headline={translate('summary.sources.title')}
          subheadline={
            linksCount > 1
              ? translate('summary.sources.subtitle.plural', {
                  totalElements: linksCount.toString(),
                })
              : translate('summary.sources.subtitle.singular')
          }
          slides={Object.keys(links).map((key) => {
            const item = links[key]
            const url = new URL(item.url)
            return (
              <SwiperSlide
                onClick={() =>
                  trackClick({
                    event: trackingClickType,
                    label: 'article_source',
                    linkUrl: url.pathname,
                  })
                }
                href={url.pathname}
                key={`source-item-${item.url}-${key}`}>
                <span>
                  {item.isPlus && (
                    <StyledPlusIcon
                      iconName="blick-plus-logo"
                      size={19}
                      color={theme.color.primary.primary01}
                    />
                  )}
                  {item.title}
                </span>
                <SummaryPill isActive={activeIndex === parseInt(key) - 1}>
                  {key}
                </SummaryPill>
              </SwiperSlide>
            )
          })}
          count={linksCount}
        />
      )}

      {isChatbotEnabled && (
        <AdditionQuestionsWrapper>
          {(isNextQuestionLoaded && (
            <QuestionContainer>
              {nextQuestionData.slice(0, 2).map((data) => (
                <QuestionMessage
                  onClick={() => {
                    openChatbot(data)
                  }}
                  key={data.id}>
                  {data.text}
                </QuestionMessage>
              ))}
            </QuestionContainer>
          )) ||
            (isLoggedIn && (
              <SkeletonView
                seed={[
                  { type: SkeletonSeedValues.QuestionButton, multiplier: 2 },
                ]}
              />
            ))}
          {(isLoggedIn && <ChatbotInput />) || (
            <StyledPrimaryButton size="large" onClick={() => onLogin()}>
              {translate('chatbot.footer.loginButton')}
            </StyledPrimaryButton>
          )}
        </AdditionQuestionsWrapper>
      )}
      <SummaryDisclaimer {...disclaimer} />
    </>
  )
}

export default SummaryResultView
