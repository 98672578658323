import { ConfigProps } from '@config/types'

const config = ({}: ConfigProps) =>
  ({
    conversation: 'none',
    cards: 'visible',
    theme: 'light',
    width: 'auto',
    dnt: false,
  }) as const satisfies Record<string, unknown>

export default config
