import styled, { css } from 'styled-components'
import { desktopCSS, mobileAndTabletCSS } from '@measures/responsive'
import TeaserToiCommercialWrapper from '@components/TeaserToiCommercial/Wrapper'
import TeaserToiWrapper from '@components/TeaserToi/Wrapper'
import RelatedMatchesWrapper from '@components/RelatedMatches/Wrapper'

export interface MainFlowWrapperProps {
  hasPartner: boolean
}

const MainFlowWrapper = styled.div<MainFlowWrapperProps>`
  ${({
    theme: {
      spacing: { spacing8, spacing16, spacing32 },
    },
    hasPartner,
  }) => css`
    display: grid;
    width: 100%;

    ${desktopCSS(css`
      grid-column: span 8;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: ${spacing32};
      align-items: flex-start;

      ${TeaserToiCommercialWrapper}, ${TeaserToiWrapper} {
        grid-column: 1 / -1;
      }

      ${RelatedMatchesWrapper} {
        display: none;
      }
    `)};

    ${mobileAndTabletCSS(css`
      ${TeaserToiCommercialWrapper}, ${TeaserToiWrapper} {
        &:first-child {
          ${!hasPartner &&
          css`
            margin-bottom: ${spacing8};
          `};
        }
      }

      grid-row-gap: ${spacing16};
    `)};
  `}
`

export default MainFlowWrapper
