import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { mobileCSS } from '@measures/responsive'
import useHasInvertedColors from '@hooks/useHasInvertedColors'

const Wrapper = styled.div<{ hasInvertedColors: boolean }>`
  ${({
    theme: {
      color: {
        tertiary: { grey700, grey400 },
      },
      typography: {
        bodycopy: {
          xxsmall: { bundledCSS: xxsmallBodycopyCSS },
        },
      },
    },
    hasInvertedColors,
  }) => css`
    ${xxsmallBodycopyCSS};

    display: flex;
    align-items: center;
    color: ${hasInvertedColors ? grey400 : grey700};

    /* On mobile, dates must be always displayed in 2 lines, without any divider */
    ${mobileCSS(css`
      width: 100%;
    `)}
  `}
`

const ArticleDate: FunctionComponent<{ children?: ReactNode }> = ({
  children,
}) => {
  const hasInvertedColors = useHasInvertedColors()

  return (
    <Wrapper
      suppressHydrationWarning={true}
      hasInvertedColors={hasInvertedColors}>
      {children}
    </Wrapper>
  )
}

export default ArticleDate
