import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { FeatureFlagEntryProps } from './types'
import CheckBox from './CheckBox'
import SelectBox from './SelectBox'

const StyledFeatureFlagEntry = styled.li`
  ${({
    theme: {
      color: {
        tertiary: { grey300, grey700 },
      },
      spacing: { spacing20 },
    },
  }) => css`
    padding: ${spacing20};
    border-radius: 10px;
    background-color: ${grey300};
    box-shadow: ${grey700} 8px 11px 7px;
  `}
`

const Title = styled.h3`
  margin: 0;
  padding: 0;
`

const Description = styled.p`
  ${({
    theme: {
      spacing: { spacing20 },
    },
  }) => css`
    margin: ${spacing20} 0 0 0;
    padding: 0;
  `}
`

const FeatureFlagEntry: FunctionComponent<FeatureFlagEntryProps> = ({
  name,
  description,
  values,
  updateKey,
}) => {
  return !values.length ? null : (
    <StyledFeatureFlagEntry>
      <Title>{name}</Title>
      <Description>{description}</Description>
      {values.length > 1 ? (
        <SelectBox variantName={name} updateKey={updateKey}>
          {values}
        </SelectBox>
      ) : (
        <CheckBox variantName={name} updateKey={updateKey}>
          {values[0]}
        </CheckBox>
      )}
    </StyledFeatureFlagEntry>
  )
}

export default FeatureFlagEntry

export { StyledFeatureFlagEntry, Title, Description }
