import isEqual from 'lodash.isequal'
import { QueryClient } from '@tanstack/react-query'
import { CMPCookieCategories } from './types'
import config from '@config'

const {
  abTest: { windowKey },
} = config

const logCMP = (queryClient: QueryClient, ...args: any): void => {
  const showCMPLogs =
    queryClient.getQueryData<'show'>([windowKey, 'showCMPLogs']) === 'show'

  if (showCMPLogs) {
    console.log('CMP: ', ...args)
  }
}

const getHasUserConcludedConsent = (): boolean => {
  let hasConsentConcluded = false

  try {
    hasConsentConcluded = !!document.cookie
      .split(';')
      .some((item) => item.trim().startsWith('OptanonAlertBoxClosed='))
  } catch {
    //! We are not allowed to read cookies from JS (due to browser security settings)
  }

  return hasConsentConcluded
}

const triggerGMTConsentEvent = (
  queryClient: QueryClient,
  cookieCategories: CMPCookieCategories
) => {
  const activeGroups = Object.entries(cookieCategories)
    .reduce<string[]>((acc, item) => {
      if (item[1]) {
        acc.push(item[0])
      }
      return acc
    }, [])
    .join(',')

  logCMP(queryClient, `GTM event fired! ActiveGroups: ${activeGroups}`)

  window.dataLayer.push({
    event: 'consent_provided',
    eventAction: 'consent_provided',
    eventCategory: 'CMP',
    onetrustActiveGroups: activeGroups,
  })
}

const getCookieCategories = (): CMPCookieCategories => {
  let consentInfoFromCookie

  try {
    consentInfoFromCookie = document?.cookie
      ?.split('; ')
      .find((cookieValue) => cookieValue.includes('OptanonConsent='))
      ?.trim()
      ?.replace('OptanonConsent=', '')
  } catch {
    //! We are not allowed to read cookies from JS (due to browser security settings)
  }

  if (!consentInfoFromCookie) {
    return {}
  }

  try {
    const parsedConsentInfoFromCookie = new URLSearchParams(
      consentInfoFromCookie
    )
      .get('groups')
      ?.split(',')

    if (!parsedConsentInfoFromCookie) {
      return {}
    }

    const formattedCookieCategories: CMPCookieCategories = {}

    parsedConsentInfoFromCookie.forEach((parsedCategory) => {
      try {
        const [categoryName, categoryEnabledFlag] = parsedCategory
          .trim()
          .split(':')
        formattedCookieCategories[categoryName] = categoryEnabledFlag === '1'
      } catch {}
    })

    return formattedCookieCategories
  } catch {
    return {}
  }
}

const updateCMPCookieCategories = (queryClient: QueryClient) => {
  const newCategories = getCookieCategories()
  const currentCategories = queryClient.getQueryData<
    ReturnType<typeof getCookieCategories>
  >(['cmp-cookie-categories'])

  if (!isEqual(currentCategories, newCategories)) {
    triggerGMTConsentEvent(queryClient, newCategories)
    queryClient.setQueryData(['cmp-cookie-categories'], newCategories)
    queryClient.invalidateQueries({
      queryKey: ['cmp-cookie-categories'],
      exact: true,
    })
  } else {
    logCMP(queryClient, 'Categories are the same!')
  }
}

const updateCMPHasUserInteracted = (queryClient: QueryClient) => {
  const hasUserInteracted = getHasUserConcludedConsent()
  const hasUserInteractedBefore = !!queryClient.getQueryData<boolean>([
    'cmp-has-user-interacted',
  ])

  if (hasUserInteracted) {
    if (!hasUserInteractedBefore) {
      queryClient.setQueryData(['cmp-has-user-interacted'], true)
      queryClient.invalidateQueries({
        queryKey: ['cmp-has-user-interacted'],
        exact: true,
      })
    }
  }
}

//! This function mocks CMP so that we can get ads / analytics working in localhost (outside of local.blick.ch domain)
const mockCMP = (queryClient: QueryClient, window: any) => {
  window.__tcfapi = function (command: any, _version: any, callback: any) {
    if (command === 'getTCData') {
      const tcData = {
        gdprApplies: true, // Indicates GDPR is in effect
        tcString: 'some-sample-tc-string', // Sample Transparency and Consent string
        eventStatus: 'tcloaded',
        cmpStatus: 'loaded',
        addtlConsent: 'some-additional-consent-string', // Optional, for Google
        purpose: {
          consents: {
            1: true, // Store and/or access information on a device
            2: true, // Select basic ads
            3: true, // Create a personalized ads profile
            4: true, // Select personalized ads
            5: true, // Create a personalized content profile
            6: true, // Select personalized content
            7: true, // Measure ad performance
            8: true, // Measure content performance
            9: true, // Apply market research to generate audience insights
            10: true, // Develop and improve products
          },
        },
        vendor: {
          consents: {}, // Approve all vendors as needed
        },
        specialFeatureOptIns: {}, // Enable any special features
      }

      // Return consent data via callback
      callback(tcData, true)
    } else if (command === 'addEventListener') {
      // Handle any event listeners that might be added by the CMP
      callback({ eventStatus: 'tcloaded', cmpStatus: 'loaded' }, true)
    }
  }

  queryClient.setQueryData(['cmp-has-user-interacted'], true)

  queryClient.setQueryData(['cmp-cookie-categories'], {
    C0001: true,
    C0002: true,
    C0003: true,
    C0004: true,
    C0005: true,
    V2STACK42: true,
  })

  queryClient.invalidateQueries({
    queryKey: ['cmp-has-user-interacted'],
    exact: true,
  })

  queryClient.invalidateQueries({
    queryKey: ['cmp-cookie-categories'],
    exact: true,
  })
}

export {
  getHasUserConcludedConsent,
  updateCMPCookieCategories,
  updateCMPHasUserInteracted,
  logCMP,
  mockCMP,
}
