import { FunctionComponent } from 'react'
import { SocialMediaType } from '@widgets/AuthorHeadline/types'
import styled, { css } from 'styled-components'
import SocialItem from '@widgets/AuthorHeadline/SocialMedia/Item'
import { desktopCSS } from '@measures/responsive'

interface SocialMediaProps {
  children: SocialMediaType[]
}

const ALLOWED_SOCIAL_MEDIA = [
  'mail',
  'twitter',
  'linkedin',
  'facebook',
  'instagram',
  'whatsapp',
  'youtube',
  'xing',
  'bluesky',
  'crunchbase',
  'muckrack',
  'tiktok',
  'buzzsumo',
  'threads',
] as const

const getValidSocialMedia = (socialMedia: any): SocialMediaType[] =>
  socialMedia?.filter?.((socialItem: SocialMediaType) =>
    ALLOWED_SOCIAL_MEDIA.includes(socialItem.type)
  ) ?? []

const Wrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing24, spacing16, spacing12 },
    },
  }) => {
    return css`
      display: flex;
      align-items: flex-start;
      margin-top: ${spacing24};
      flex-wrap: wrap;
      gap: ${spacing12};
      padding: 0 ${spacing16};
      ${desktopCSS(css`
        padding: 0;
      `)};
    `
  }}
`

const SocialMedia: FunctionComponent<SocialMediaProps> = ({ children }) => {
  const validSocialMedia = getValidSocialMedia(children)

  if (!(validSocialMedia.length > 0)) {
    return null
  }

  return (
    <Wrapper>
      {validSocialMedia.map(({ type, src }) => (
        <SocialItem key={type} type={type} src={src} />
      ))}
    </Wrapper>
  )
}

export default SocialMedia
