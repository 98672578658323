import translate from '@i18n'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

const InfoBox = styled.div`
  ${({
    theme: {
      color: {
        tertiary: { grey100 },
      },
      spacing: { spacing16, spacing24 },
      typography: {
        bodycopy: {
          small2: { bundledCSS: bodycopySmall2CSS },
        },
      },
    },
  }) => {
    return css`
      box-sizing: border-box;
      padding: ${spacing16};
      margin-top: ${spacing24};
      background-color: ${grey100};
      ${bodycopySmall2CSS};
    `
  }}
`

const CommentingInfoBox: FunctionComponent = () => {
  return <InfoBox>{translate('commenting.noAvailableComments')}</InfoBox>
}

export default CommentingInfoBox
