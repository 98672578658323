export type ColorType = {
  primary: {
    blickRed: string
    primary01: string
    primary02: string
  }

  secondary: {
    darkRed: string
    green: string
    greenAccessible: string
    darkGreen: string
  }

  tertiary: {
    grey100: string
    grey200: string
    grey300: string
    grey400: string
    grey500: string
    grey600: string
    grey700: string
    grey800: string
    grey900: string
    grey1000: string
  }

  transparency: {
    red5: string
    red50: string
    primary01Color60: string
    primary01Color80: string
  }

  gradients: {
    bottom75: string
    bottom50: string
    hero: string
    sport: string
  }

  lotto: {
    lottoJackpot: string
    lottoLucky: string
  }
}

const color = {
  primary: {
    blickRed: '#E20000',
    primary01: '#111111',
    primary02: '#FFFFFF',
  },

  secondary: {
    darkRed: '#A70000',
    green: '#62A01A',
    greenAccessible: '#508216',
    darkGreen: '#385912',
  },

  tertiary: {
    grey100: '#F8F8F8',
    grey200: '#EEEEEE',
    grey300: '#E3E3E3',
    grey400: '#CDCDCD',
    grey500: '#BBBBBB',
    grey600: '#A1A1A1',
    grey700: '#838383',
    grey800: '#5C5C5C',
    grey900: '#494949',
    grey1000: '#202020',
  },

  transparency: {
    red5: '#E3051B0C',
    red50: '#E3051B7F',
    primary01Color60: '#11111199',
    primary01Color80: '#111111CC',
  },

  gradients: {
    bottom75: '',
    bottom50: '',
    hero: '',
    sport: '',
  },

  lotto: {
    lottoJackpot: '#D6081C',
    lottoLucky: '#F8E71C',
  },
} as const satisfies ColorType

export default color
