import { FooterSkeletonLinkItem } from '@hooks/useFooter'
import { FillerItem, GetFooterItemsType } from './types'

const getFooterItems: GetFooterItemsType = (items, toXEqualColumns) => {
  const columnToFillAfter = items.length % toXEqualColumns
  const itemsToFill = toXEqualColumns - columnToFillAfter

  if (itemsToFill === toXEqualColumns) {
    return {
      columns: toXEqualColumns,
      rows: items.length / toXEqualColumns,
      items: [...items],
    }
  }

  const normalizedColumnSize = (items.length + itemsToFill) / toXEqualColumns
  const indicesToFillAfter: number[] = []

  for (let i = 1; i <= itemsToFill; i += 1) {
    indicesToFillAfter.push(
      columnToFillAfter * normalizedColumnSize +
        i * (normalizedColumnSize - 1) -
        1
    )
  }

  return {
    columns: toXEqualColumns,
    rows: normalizedColumnSize,
    items: items.reduce(
      (acc, item, index) => {
        acc.push(item)

        if (indicesToFillAfter.includes(index)) {
          acc.push('filler')
        }

        return acc
      },
      [] as (FooterSkeletonLinkItem | FillerItem)[]
    ),
  }
}

export { getFooterItems }
