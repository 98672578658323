import styled, { css } from 'styled-components'
import Blink, { BlinkProps } from '@components/Blink'
import { desktopCSS, mobileCSS } from '@measures/responsive'
import { HeroModuleAPIProps } from '@widgets/HeroModule'
import { FunctionComponent, PropsWithChildren } from 'react'
import useClientViewportType from '@hooks/useClientViewportType'

type ImmersiveDynamicContentWrapperProps = PropsWithChildren<BlinkProps> & {
  typeOfSport: HeroModuleAPIProps['typeOfSport']
  isSportDataLoaded: boolean
}

const StyledImmersiveDynamicContentWrapper = styled(Blink)<{
  typeOfSport: HeroModuleAPIProps['typeOfSport']
  isSportDataLoaded: boolean
}>`
  display: block;
  appearance: none;
  text-decoration: none;

  ${desktopCSS(css`
    display: none;
  `)}

  ${mobileCSS(css`
    margin-left: -16px;
    margin-right: -16px;
  `)};
`

const ImmersiveDynamicContentWrapper: FunctionComponent<
  ImmersiveDynamicContentWrapperProps
> = ({ typeOfSport, isSportDataLoaded, children, ...restOfProps }) =>
  useClientViewportType() === 'desktop' ? null : (
    <StyledImmersiveDynamicContentWrapper
      typeOfSport={typeOfSport}
      isSportDataLoaded={isSportDataLoaded}
      {...restOfProps}>
      {children}
    </StyledImmersiveDynamicContentWrapper>
  )

export default ImmersiveDynamicContentWrapper
