import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent } from 'react'
import InstagramComponent from '@components/SocialEmbed/Instagram'

interface InstagramProps {
  url: string
}

const Instagram: FunctionComponent<InstagramProps> = ({ url }) => {
  return <InstagramComponent url={url} />
}

const widget = {
  kind: ['widget', 'social-embed', 'instagram'],
  component: Instagram,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
