import { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'

const StyledTextWrapper = styled.div``

const TextWrapper: FunctionComponent<{ children?: ReactNode }> = ({
  children,
}) => <StyledTextWrapper>{children}</StyledTextWrapper>

export default TextWrapper
