import { desktopCSS } from '@measures/responsive'
import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'

const StyledScoreboardMainSection = styled.div`
  ${({
    theme: {
      spacing: { spacing16, spacing24 },
    },
  }) => css`
    display: flex;
    justify-content: space-between;
    padding: ${spacing16};

    ${desktopCSS(css`
      padding: ${spacing16} ${spacing24};
    `)}
  `}
`

export type ScoreboardMainSectionProps = { children: ReactNode }

const ScoreboardMainSection: FunctionComponent<ScoreboardMainSectionProps> = ({
  children,
}) => <StyledScoreboardMainSection>{children}</StyledScoreboardMainSection>

export default ScoreboardMainSection
