import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { desktopCSS, tabletCSS, mobileCSS } from '@measures/responsive'
import { ActualImageDimensions } from './types'

interface PlaceholderProps {
  dimensions: ActualImageDimensions
  children?: ReactNode
  className?: string
}

type StyledPlaceholderProps = Pick<PlaceholderProps, 'dimensions'>

const StyledPlaceholder = styled.div<StyledPlaceholderProps>`
  ${({ dimensions }) => {
    return css`
      position: relative;

      ${desktopCSS(css`
        width: ${dimensions['desktop']['width']}px;
        height: ${dimensions['desktop']['height']}px;
      `)}

      ${tabletCSS(css`
        width: ${dimensions['tablet']['width']}px;
        height: ${dimensions['tablet']['height']}px;
      `)}

      ${mobileCSS(css`
        width: ${dimensions['mobile']['width']}px;
        height: ${dimensions['mobile']['height']}px;
      `)}
    `
  }}
`

const Placeholder: FunctionComponent<PlaceholderProps> = ({
  dimensions,
  children,
  className,
}) => (
  <StyledPlaceholder className={className} dimensions={dimensions}>
    {children}
  </StyledPlaceholder>
)

export default Placeholder
