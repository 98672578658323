import { FunctionComponent } from 'react'
import styled from 'styled-components'

export interface LoadingSpinnerProps {
  isVisible: boolean
}

const Spinner = styled.div`
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  width: 36px;
  height: 36px;
  border: 4px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
`

const IconWrapper = styled.div`
  @keyframes fadeIn {
    99% {
      visibility: hidden;
    }
    100% {
      visibility: visible;
    }
  }

  // delay the visibility change to prevent flickering
  animation: 1s fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;

  z-index: 7;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 48px;
  height: 48px;

  display: flex;
  justify-content: center;
  align-items: center;
`

const LoadingSpinner: FunctionComponent = () => (
  <IconWrapper>
    <Spinner />
  </IconWrapper>
)

export default LoadingSpinner
