import { FunctionComponent } from 'react'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import VideoYoutube from '@components/Video/Youtube'
import { YoutubeVideoAPIProps } from '@components/Video/Youtube/types'

const Youtube: FunctionComponent<YoutubeVideoAPIProps> = ({
  videoId,
  uuid,
}) => {
  return <VideoYoutube videoId={videoId} widgetId={uuid} />
}

const widget = {
  kind: ['widget', 'video', 'youtube'],
  component: Youtube,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
