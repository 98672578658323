import config from '@config'
import usePageMetadata from '@hooks/usePageMetadata'
import Head from 'next/head'
import { FunctionComponent } from 'react'

const {
  publication: { publication },
  backend: { baseUrl },
} = config

const HrefLangLinks: FunctionComponent = () => {
  const { context, url, de_link: linkDE, fr_link: linkFR } = usePageMetadata()

  const isHomePage = context === 'home'
  const hasAlternate =
    (publication === 'romandie' && !!linkDE) ||
    (publication !== 'romandie' && !!linkFR)

  const urlFR = `${baseUrl}${publication === 'romandie' ? url : linkFR}`
  const urlDE = `${baseUrl}${publication === 'romandie' ? linkDE : url}`

  const sectionUrl = url.substring(0, url.lastIndexOf('/')).toLowerCase()
  const rssUrl = `${baseUrl}${sectionUrl}/rss.xml`

  return (
    <Head>
      {isHomePage ? (
        <link
          rel="alternate"
          hrefLang="de-CH"
          href={`${baseUrl}/`}
          key="alternateDE"
        />
      ) : null}
      {isHomePage ? (
        <link
          rel="alternate"
          hrefLang="fr-CH"
          href={`${baseUrl}/fr/`}
          key="alternateFR"
        />
      ) : null}
      <link
        rel="alternate"
        href={rssUrl}
        key="alternateRSS"
        type="application/rss+xml"
      />
      {hasAlternate ? (
        <link rel="alternate" hrefLang="de-CH" href={urlDE} key="alternateDE" />
      ) : null}
      {hasAlternate ? (
        <link rel="alternate" hrefLang="fr-CH" href={urlFR} key="alternateFR" />
      ) : null}
    </Head>
  )
}

export default HrefLangLinks
