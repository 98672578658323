import useSubscriptionStatus from '@hooks/useSubscriptionStatus'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { useRouter } from 'next/router'
import { FunctionComponent, useCallback, useEffect } from 'react'
import {
  TemplatesExtraDataTrackingType,
  PianoTrackMessage,
  RecommendationsExtraDataTrackingType,
} from './types'
import useURLParam from '@hooks/useURLParam'
import config from '@config'
import { useQueryClient } from '@tanstack/react-query'
import { OnShowOfferParams } from '@components/Piano/types'
import { getTrackingTemplateParams } from '@components/Piano/utils'

const {
  subscriptions: { blickPlusSectionUrl },
} = config

const OfferPageWithRecommendations: FunctionComponent = () => {
  const queryClient = useQueryClient()
  const router = useRouter()
  const isMarketingLandingPage = useURLParam('mlp') === 'true'

  const { subscriptionStatus } = useSubscriptionStatus()
  const isSubscriber = subscriptionStatus === 'subscribed'

  const trackingRecommendationsClick = useCallback<
    TrackingFnType<RecommendationsExtraDataTrackingType>
  >(({ extraData: { labelOrArticleId } }) => {
    return {
      event: 'element_click',
      element: 'blickplus_highlights_widget',
      element_label: labelOrArticleId,
    }
  }, [])

  const trackingOffersImpression = useCallback<
    TrackingFnType<TemplatesExtraDataTrackingType>
  >(
    ({ extraData: { trackingId } }) => {
      const pianoTemplateParamsArray = queryClient.getQueryData<
        OnShowOfferParams[]
      >(['pianoTemplateParams'])

      // As there are multiple templates on the offer page, we need to find the right parameters
      // for tracking. We are using the trackingId set by Piano in the template to be able to
      // re-identify the templates for the impression tracking
      const pianoTemplateParams = pianoTemplateParamsArray?.find(
        (el) => el.trackingId === trackingId
      )

      const pianoTrackingTemplateNameParams = getTrackingTemplateParams(
        pianoTemplateParams?.experienceActionId
      )

      return {
        event: 'piano_show',
        piano_offer_id: pianoTemplateParams?.offerId || '',
        piano_template_id: pianoTemplateParams?.templateId || 'MAIN',
        piano_template_variant_id:
          pianoTemplateParams?.templateVariantId || 'MAIN',
        piano_experience_id: pianoTemplateParams?.experienceId || '',
        ...pianoTrackingTemplateNameParams,
      }
    },
    [queryClient]
  )

  const handleTrackRecommendationsClick = useTracking(
    trackingRecommendationsClick
  )

  const handleTrackOffersImpression = useTracking(trackingOffersImpression)

  const onHandlePostMessage = useCallback(
    (event: MessageEvent<PianoTrackMessage>) => {
      const { data } = event

      if (!data) {
        return
      }

      const { blick_event_type } = data

      if (blick_event_type) {
        if (blick_event_type === 'PIANO_TRACK_AND_NAVIGATE') {
          if (typeof data.item === 'string') {
            handleTrackRecommendationsClick({ labelOrArticleId: data.item })
            router.push(blickPlusSectionUrl)
          } else {
            const { 'nff-articleid': articleId, url } = data.item
            handleTrackRecommendationsClick({ labelOrArticleId: articleId })
            router.push(url)
          }
        }

        if (blick_event_type === 'PIANO_TRACK_IMPRESSION') {
          if (data.eventName === 'piano_show') {
            handleTrackOffersImpression({ trackingId: data.trackingId })
          }
        }
      }
    },
    [router, handleTrackRecommendationsClick, handleTrackOffersImpression]
  )

  useEffect(() => {
    window.addEventListener('message', onHandlePostMessage)

    return () => {
      window.removeEventListener('message', onHandlePostMessage)
    }
  }, [onHandlePostMessage])

  return isMarketingLandingPage ? (
    <div className="marketing-landingpage-container" />
  ) : (
    <>
      <div className="offer-page-header-container" />
      {isSubscriber ? (
        <>
          <div className="piano-recommendations-container" />
          <div className="offer-page-reasons-container" />
        </>
      ) : (
        <>
          <div className="offer-page-reasons-container" />
          <div className="piano-recommendations-container" />
        </>
      )}
      <div className="offer-page-footer-container" />
    </>
  )
}

const widget = {
  kind: ['offer-page-with-recommendations'],
  component: OfferPageWithRecommendations,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
