import Head from 'next/head'
import { FunctionComponent, useMemo } from 'react'
import styled, { css, useTheme } from 'styled-components'
import PaginationItem from './PaginationItem'
import { generatePageNumbers } from './utils'
import SvgIcon from '@components/SvgIcon'
import PaginationLinksContainer from '@components/Pagination/PaginationLinksContainer'
import { desktopCSS, mobileCSS, tabletCSS } from '@measures/responsive'

const PaginationContainer = styled.div`
  ${({
    theme: {
      color: {
        tertiary: { grey300 },
      },
      spacing: { spacing24 },
    },
  }) => css`
    display: flex;
    grid-column: span 6;
    align-items: center;
    justify-content: space-between;
    padding: ${spacing24} 0;
    width: 100%;

    box-sizing: border-box;
    border-style: solid;
    border-width: 1px 0;
    border-color: ${grey300};
  `}
`

export interface CommonPaginationProps {
  activePage: number
  totalPages: number
}

export interface ButtonPaginationProps extends CommonPaginationProps {
  type: 'button'
  setPage?: (page: number) => void
}

export interface LinkPaginationProps extends CommonPaginationProps {
  type: 'link'
  firstPageHref: string
  paginationHref: string
}

const PaginationLinksContainerDesktop = styled(PaginationLinksContainer)`
  ${mobileCSS(css`
    display: none;
  `)}
`
const PaginationLinksContainerMobile = styled(PaginationLinksContainer)`
  ${desktopCSS(css`
    display: none;
  `)}
  ${tabletCSS(css`
    display: none;
  `)}
`

export type PaginationProps = ButtonPaginationProps | LinkPaginationProps

const Pagination: FunctionComponent<PaginationProps> = (props) => {
  const { activePage, totalPages, type } = props
  const theme = useTheme()

  const pageNumbersDesktop = useMemo(
    () => generatePageNumbers(totalPages, activePage, 'desktop'),
    [totalPages, activePage]
  )

  const pageNumbersMobile = useMemo(
    () => generatePageNumbers(totalPages, activePage, 'mobile'),
    [totalPages, activePage]
  )

  const hasPrevPage = activePage > 1
  const hasNextPage = activePage < totalPages

  const prevPageItem = !hasPrevPage ? (
    <PaginationItem type="inactive" direction="prev">
      <SvgIcon
        iconName="arrow-left"
        color={theme.color.primary.primary01}
        size={32}
      />
    </PaginationItem>
  ) : type === 'button' ? (
    <PaginationItem
      type="button"
      onClick={() => {
        props.setPage?.(activePage - 1)
      }}
      direction="prev">
      <SvgIcon
        iconName="arrow-left"
        color={theme.color.primary.primary01}
        size={32}
      />
    </PaginationItem>
  ) : (
    <PaginationItem
      type="link"
      href={`${
        //! page 1 doesn't have a url segment
        activePage - 1 === 1
          ? `${props.firstPageHref}`
          : `${props.paginationHref}${activePage - 1}/`
      }`}
      direction="prev">
      <SvgIcon
        iconName="arrow-left"
        color={theme.color.primary.primary01}
        size={32}
      />
    </PaginationItem>
  )

  const nextPageItem = !hasNextPage ? (
    <PaginationItem type="inactive" direction="prev">
      <SvgIcon
        iconName="arrow-right"
        color={theme.color.primary.primary01}
        size={32}
      />
    </PaginationItem>
  ) : type === 'button' ? (
    <PaginationItem
      type="button"
      onClick={() => {
        props.setPage?.(activePage + 1)
      }}
      direction="next">
      <SvgIcon
        iconName="arrow-right"
        color={theme.color.primary.primary01}
        size={32}
      />
    </PaginationItem>
  ) : (
    <PaginationItem
      type="link"
      href={`${props.paginationHref}${activePage + 1}/`}
      direction="next">
      <SvgIcon
        iconName="arrow-right"
        color={theme.color.primary.primary01}
        size={32}
      />
    </PaginationItem>
  )

  return (
    <>
      {type === 'link' && activePage > 1 && (
        <Head>
          <link
            key="pagination-prev"
            rel="prev"
            href={
              activePage - 1 === 1
                ? props.firstPageHref
                : `${props.paginationHref}${activePage - 1}/`
            }
          />
        </Head>
      )}
      {type === 'link' && activePage < totalPages && (
        <Head>
          <link
            key="pagination-next"
            rel="next"
            href={`${props.paginationHref}${activePage + 1}/`}
          />
        </Head>
      )}
      <PaginationContainer>
        {prevPageItem}
        <PaginationLinksContainerDesktop
          {...props}
          pageNumbers={pageNumbersDesktop}
        />
        <PaginationLinksContainerMobile
          {...props}
          pageNumbers={pageNumbersMobile}
        />
        {nextPageItem}
      </PaginationContainer>
    </>
  )
}

export default Pagination
