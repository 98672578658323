import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

interface LabelProps {
  labelColor: string
  children: string
  className?: string
}

type StyledLabelProps = Pick<LabelProps, 'labelColor'>

const StyledLabel = styled.div<StyledLabelProps>`
  ${({
    theme: {
      color: {
        primary: { primary02: primary02Color },
      },
      typography: {
        eyebrows: {
          small2: { bundledCSS: small2EyebrowCSS },
        },
      },
    },
    labelColor,
  }) => css`
    display: inline-block;
    ${small2EyebrowCSS};
    color: ${primary02Color};
    background-color: ${labelColor};
    padding: 0.28em 0.4em 0.2em;
    text-transform: uppercase;
  `}
`

const Label: FunctionComponent<LabelProps> = ({
  labelColor,
  children,
  className,
}) => {
  return (
    <StyledLabel labelColor={labelColor} className={className}>
      {children}
    </StyledLabel>
  )
}

export default Label
