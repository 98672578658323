import { useCallback, useEffect, useRef, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'

type UseRiAdTagManagerInit = () => string

const useRiAdTagManagerInit: UseRiAdTagManagerInit = () => {
  const queryClient = useQueryClient()
  const queryCache = queryClient.getQueryCache()
  const unsubscribeFnRef = useRef<() => void>()

  const [initCode, setInitCode] = useState<string>(
    () => queryClient.getQueryData<string>(['RiAdTagManagerInitCode']) ?? ''
  )

  const updateRiAdTagManagerInitCode = useCallback(
    (args: any) => {
      if (
        args?.action?.type === 'invalidate' &&
        args.query.queryKey[0] === 'RiAdTagManagerInitCode'
      ) {
        setInitCode(
          queryClient.getQueryData<string>(['RiAdTagManagerInitCode']) as string
        )
      }
    },
    [queryClient]
  )

  useEffect(() => {
    setInitCode(
      queryClient.getQueryData<string>(['RiAdTagManagerInitCode']) ?? ''
    )

    unsubscribeFnRef.current = queryCache.subscribe(
      updateRiAdTagManagerInitCode
    )

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [updateRiAdTagManagerInitCode, queryCache, queryClient])

  return initCode
}

export default useRiAdTagManagerInit
