import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import useScoreboard from '@hooks/useScoreboard'
import { LIVE_EVENT_DATA_SPORT_TYPES } from '@utils/formatters/common'
import { ScoreboardMatchScoreProps } from './ScoreboardMatchScore'

type StyledScoreboardMatchAdditionalScoreProps = Pick<
  ScoreboardMatchScoreProps,
  'explanation'
>

const StyledScoreboardMatchAdditionalScore = styled.div<StyledScoreboardMatchAdditionalScoreProps>`
  ${({
    theme: {
      color: {
        tertiary: { grey800: grey800color },
      },
      typography: {
        eyebrows: {
          medium2: { bundledCSS: medium2EyebrowCSS },
          small1: { bundledCSS: small1EyebrowCSS },
        },
      },
      spacing: { spacing12 },
    },
    explanation,
  }) => css`
    align-items: center;
    color: ${grey800color};
    display: flex;
    text-align: center;
    margin-bottom: ${spacing12};
    ${explanation ? small1EyebrowCSS : medium2EyebrowCSS};
  `}
`

const ScoreboardMatchAdditionalScore: FunctionComponent = () => {
  const { formattedData } =
    useScoreboard<typeof LIVE_EVENT_DATA_SPORT_TYPES.SOCCER>()

  const { extraTimeIndicator, teams, scoreExplanation } = formattedData

  const homeScoreBeforePenalties = teams[0].scoreBeforePenalties
  const awayScoreBeforePenalties = teams[1].scoreBeforePenalties

  return (
    <StyledScoreboardMatchAdditionalScore explanation={scoreExplanation}>
      {homeScoreBeforePenalties}:{awayScoreBeforePenalties}{' '}
      {!!extraTimeIndicator && extraTimeIndicator}
    </StyledScoreboardMatchAdditionalScore>
  )
}

export default ScoreboardMatchAdditionalScore
