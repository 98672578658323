import {
  Slide,
  AdSlide,
  MultiImageGallery,
  SingleImageGallery,
  LinkCredit,
  TextOnlyCredit,
} from './types'
import config from '@config'
import { isValidAd } from '@utils/ads'

const {
  ads: {
    gallery: { firstAdAfterXSlides, showAdAfterXSlides },
  },
} = config

const getTotalNumberOfSlides = (
  slidesLength: number,
  firstAdAfterXSlides: number
) => {
  // In case slides are less then the first ad position required, no ad will be dsiplayed
  if (firstAdAfterXSlides > slidesLength) return slidesLength

  // Calculate number of ads after the "firstAdAfterXSlides"th slide
  const quotient = Math.floor(
    (slidesLength - firstAdAfterXSlides) / showAdAfterXSlides
  )
  const remainder = (slidesLength - firstAdAfterXSlides) % showAdAfterXSlides

  // An ad must never be the last slide (happens when remainder is 0)
  const numberOfAdSlides: number = remainder === 0 ? quotient - 1 : quotient

  // Total slides will be: the images + the "earlier" ad + one ad every "showAdAfterXSlides"
  return slidesLength + 1 + numberOfAdSlides
}

const getSlidesWithAds = (slides: Array<Slide | AdSlide>, adSlide: AdSlide) => {
  const slidesLength = slides.length
  const currentSlides: Array<Slide | AdSlide> = [...slides]

  // Insert first ad "earlier"
  if (slidesLength > firstAdAfterXSlides) {
    currentSlides.splice(firstAdAfterXSlides, 0, adSlide)

    const numberOfTotalSlides = getTotalNumberOfSlides(
      slidesLength,
      firstAdAfterXSlides
    )

    // Then one every "showAdAfterXSlides" slides, but ad is never the last slide
    if (slidesLength > firstAdAfterXSlides + showAdAfterXSlides) {
      for (
        let i = firstAdAfterXSlides + showAdAfterXSlides + 1;
        i < numberOfTotalSlides;
        i += showAdAfterXSlides + 1
      ) {
        currentSlides.splice(i, 0, adSlide)
      }
    }
  }

  return currentSlides
}

// todo: add the propsForClickable
/**
 * Get credit for components which images have credit
 * @param {object} element - element containing the data.
 * @param {function} dispatch - Function for dispatching actions
 * @returns {*}
 */
const getCredit = (element: any) => {
  if (!element) return

  const text = element.credit && element.credit.text

  if (!text) return

  return {
    text,
    // propsForClickable: navigationPropsForClickable(element.credit, dispatch),
  }
}

const isSingleImageGallery = (props: any): props is SingleImageGallery =>
  props?.slides?.length === 1

const isMultiImageGallery = (props: any): props is MultiImageGallery =>
  props?.slides?.length > 1

const isValidTextOnlyCredit = (credit: any): credit is TextOnlyCredit =>
  !!credit?.text && !credit?.link?.href

const isValidLinkCredit = (credit: any): credit is LinkCredit =>
  !!credit?.text && !!credit?.link?.href

const isAdSlide = (slide: Slide | AdSlide): slide is AdSlide => isValidAd(slide)

const getTransformedSlides = ({
  slides,
  adSlide,
}: {
  slides: Slide[]
  adSlide: AdSlide
}): (Slide | AdSlide)[] => {
  if (isValidAd(adSlide) && adSlide?.isAdDisabled !== true) {
    return getSlidesWithAds(slides ?? [], adSlide)
  }

  return slides
}

export {
  getCredit,
  getSlidesWithAds,
  getTotalNumberOfSlides,
  isSingleImageGallery,
  isMultiImageGallery,
  isValidTextOnlyCredit,
  isValidLinkCredit,
  isAdSlide,
  getTransformedSlides,
}
