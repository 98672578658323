import { FunctionComponent, useCallback, useState } from 'react'
import styled, { css, useTheme } from 'styled-components'
import { desktopCSS } from '@measures/responsive'
import translate from '@i18n'
import config from '@config'

import SvgIcon from '@components/SvgIcon'
import PrimaryCTAButton from '@components/Buttons/PrimaryCTAButton'

import { getLocalStorageItem, setLocalStorageItem } from '@utils/localStorage'
import useViewportType from '@hooks/useViewport/useViewportType'

const {
  blickBites: { localStorageHideOnboardingOverlayKey },
} = config

const StyledMobileOnboardingOverlay = styled.div`
  ${({
    theme: {
      color: {
        transparency: { primary01Color60: primary01Color60Color },
      },
    },
  }) => css`
    width: 100%;
    height: 100%;
    background: ${primary01Color60Color};
    position: absolute;
    top: 0;
    z-index: 8;

    display: grid;
    align-items: center;
    justify-items: center;

    ${desktopCSS(css`
      display: none;
    `)}
  `}
`

const MobileOnboardingOverlayInner = styled.div`
  ${({
    theme: {
      spacing: { spacing40 },
    },
  }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${spacing40};
  `}
`

const TextContainer = styled.div``

const Title = styled.div`
  ${({
    theme: {
      typography: {
        headings: {
          small: { bundledCSS: smallHeadingsCSS },
        },
      },
      color: {
        primary: { primary02: primary02Color },
      },
    },
  }) => css`
    ${smallHeadingsCSS};
    color: ${primary02Color};
    text-align: center;
  `}
`

const Text = styled.div`
  ${({
    theme: {
      typography: {
        subheads: {
          medium1: { bundledCSS: medium1SubheadsCSS },
        },
      },
      color: {
        primary: { primary02: primary02Color },
      },
    },
  }) => css`
    ${medium1SubheadsCSS};
    color: ${primary02Color};
    text-align: center;
  `}
`

const IconContainer = styled.div`
  position: relative;
`

const PhoneAndArrowIcon = styled(SvgIcon)`
  width: 150px;
  height: 300px;
`

const HandUpIcon = styled(SvgIcon)`
  position: absolute;
  bottom: 18px;
  right: -38px;

  transform: rotate(-60deg);

  /**
  * NOTE: SWIPE ANIMATION (0.2s delay + 1.4s duration)
  *
  * 0.2s - wait
  * 0.8s - animate rotate up
  * 0.2s - wait
  * 0.2s - animate opacity to 0
  * Reset rotation
  * 0.2s animate opacity to 1
  * Start from the beginning 
  */

  @keyframes swipeAnimation {
    0% {
      transform: rotate(-60deg);
      opacity: 1;
    }
    // 0 - 57% -> animate rotate up (duration 0.8s of 1.4s)
    57% {
      transform: rotate(-15deg);
      opacity: 1;
    }
    // 57% - 71% -> wait (duration 0.2s)
    71% {
      transform: rotate(-15deg);
      opacity: 1;
    }
    // 71% - 85% -> animate opacity to 0 (duration 0.2s)
    85% {
      transform: rotate(-15deg);
      opacity: 0;
    }
    // 85% - 86% -> reset rotation
    86% {
      transform: rotate(-60deg);
      opacity: 0;
    }
    // 86% - 100% -> animate opacity to 1 (duration 0.2s)
    100% {
      transform: rotate(-60deg);
      opacity: 1;
    }
  }

  animation: swipeAnimation 1.4s ease 0.2s infinite;
`

const MobileOnboardingOverlay: FunctionComponent = () => {
  const theme = useTheme()
  const viewportType = useViewportType()
  const isDesktop = viewportType === 'desktop'

  const [showOnboarding, setShowOnboarding] = useState<boolean>(
    () => !getLocalStorageItem(localStorageHideOnboardingOverlayKey)
  )

  const onCloseHandler = useCallback(() => {
    setShowOnboarding(false)
    setLocalStorageItem(localStorageHideOnboardingOverlayKey, 'true')
  }, [])

  return !isDesktop && showOnboarding ? (
    <StyledMobileOnboardingOverlay onTouchMove={onCloseHandler}>
      <MobileOnboardingOverlayInner>
        <TextContainer>
          <Title>{translate('blickBites.onboarding.title')}</Title>
          <Text>{translate('blickBites.onboarding.text')}</Text>
        </TextContainer>
        <IconContainer>
          <PhoneAndArrowIcon
            iconName="phone-and-arrow"
            size={300}
            color={theme.color.primary.primary02}
          />
          <HandUpIcon
            iconName="hand-back-point-up"
            size={140}
            color={theme.color.primary.primary02}
          />
        </IconContainer>
        <PrimaryCTAButton
          colorVariant="white"
          iconName="check"
          onClick={onCloseHandler}>
          {translate('blickBites.onboarding.letsGo')}
        </PrimaryCTAButton>
      </MobileOnboardingOverlayInner>
    </StyledMobileOnboardingOverlay>
  ) : null
}

export default MobileOnboardingOverlay
