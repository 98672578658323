import { FunctionComponent, memo } from 'react'
import Head from 'next/head'
import isEqual from 'fast-deep-equal'
import { withValidSchemaContext } from '@utils/schema'

interface SchemaHTMLElementProps {
  scriptKey: string
  schemaObject: Record<string, unknown>
}

const SchemaHTMLElement: FunctionComponent<SchemaHTMLElementProps> = ({
  scriptKey,
  schemaObject,
}) => (
  <Head>
    <script
      key={scriptKey}
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(withValidSchemaContext(schemaObject)),
      }}
    />
  </Head>
)

const MemoizedSchemaHTMLElement = memo(
  SchemaHTMLElement,
  (prevProps, nextProps) => isEqual(prevProps, nextProps)
)

MemoizedSchemaHTMLElement.displayName = 'MemoizedSchemaHTMLElement'

export default MemoizedSchemaHTMLElement
