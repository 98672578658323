import JSONRenderer from '@components/JSONRenderer'
import { desktopCSS } from '@measures/responsive'
import nanoid from '@utils/random'
import { AdSlide } from '@widgets/Gallery/types'
import { FunctionComponent, memo, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

type GalleryAdSlideProps = {
  adSlide: AdSlide
  galleryUUID: string
  index: number
  isActive: boolean
  isFullScreen: boolean
}

const GalleryAdSlideWrapper = styled.div<
  Pick<GalleryAdSlideProps, 'isFullScreen'>
>`
  ${({
    theme: {
      color: {
        primary: { primary02: primary02Color },
      },
    },
    isFullScreen,
  }) => css`
    width: 100%;
    height: 100%;
    display: grid;
    justify-items: center;
    align-items: center;

    ${!isFullScreen &&
    css`
      background-color: ${primary02Color};
    `}

    ${desktopCSS(css`
      background-color: ${primary02Color};
    `)}
  `}
`

const GalleryAdSlide: FunctionComponent<GalleryAdSlideProps> = (props) => {
  const { adSlide, galleryUUID, index, isActive, isFullScreen } = props
  const [adKey, setAdKey] = useState<string>('')

  useEffect(() => {
    if (isActive) {
      setAdKey(nanoid())
    } else {
      setAdKey('')
    }
  }, [isActive])

  return (
    <GalleryAdSlideWrapper isFullScreen={isFullScreen}>
      {isActive && adKey && (
        <JSONRenderer key={adKey}>
          {{
            ...adSlide,
            autoLoadSlot: true,
            containerId: `${galleryUUID}-${index}-${adKey}`,
          }}
        </JSONRenderer>
      )}
    </GalleryAdSlideWrapper>
  )
}

const MemoizedGalleryAdSlide = memo(GalleryAdSlide)

MemoizedGalleryAdSlide.displayName = 'MemoizedGalleryAdSlide'

export default MemoizedGalleryAdSlide
