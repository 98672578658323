import SvgIcon from '@components/SvgIcon'
import { FunctionComponent } from 'react'
import styled, { css, useTheme } from 'styled-components'

interface SubmittedOverlayProps {
  submitted: boolean
}

const StyledSubmittedOverlay = styled.div<{ isVisible: boolean }>`
  ${({ isVisible }) =>
    isVisible
      ? css`
          z-index: 1;
          display: block;
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          right: 0;
          display: flex;
          flex-direction: column;
          justify-content: end;
        `
      : css`
          display: none;
        `}
`

const Feedback = styled.div`
  ${({
    theme: {
      color: {
        tertiary: { grey100: grey100Color },
        primary: { primary01: primary01Color },
      },
      typography: {
        eyebrows: {
          medium2: { bundledCSS: medium2EyebrowsCSS },
        },
      },
      spacing: { spacing40, spacing20, spacing8, spacing12 },
    },
  }) => css`
    padding: 0 ${spacing12};
    background-color: ${grey100Color};
    height: ${spacing40};
    display: flex;
    align-items: center;
    margin-left: -${spacing20};
    color: ${primary01Color};
    ${medium2EyebrowsCSS};

    svg {
      margin-right: ${spacing8};
    }
  `}
`

const SubmittedOverlay: FunctionComponent<SubmittedOverlayProps> = ({
  submitted,
}) => {
  const theme = useTheme()
  return (
    <StyledSubmittedOverlay isVisible={submitted}>
      {submitted && (
        <Feedback>
          <SvgIcon
            iconName={'circle-check'}
            size={32}
            color={theme.color.primary.primary01}
          />
          Vielen Dank für deinen Beitrag!
        </Feedback>
      )}
    </StyledSubmittedOverlay>
  )
}

export default SubmittedOverlay
