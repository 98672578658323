import { FunctionComponent } from 'react'
import styled from 'styled-components'
import usePageMetadata from '@hooks/usePageMetadata'
import config from '@config'
import useDisableThirdPartyScripts from '@hooks/useDisableThirdPartyScripts'

const {
  backend: { deploymentEnv },
  prolitteris: { normalArticlesUrlPrefix, blickPlusArticlesUrlPrefix },
} = config

// Prolitteris should only be used in a production environment
const isProd = deploymentEnv === 'prod'

const ProLitterisPixel = styled.img`
  width: 1px;
  height: 1px;
  display: none;
`

const ProLitterisPixelTracker: FunctionComponent = () => {
  const { type, id: articleId, isPlus } = usePageMetadata()
  const disableThirdPartyScripts = useDisableThirdPartyScripts()

  return isProd &&
    type === 'article' &&
    articleId &&
    !disableThirdPartyScripts ? (
    <ProLitterisPixel
      src={`${isPlus ? blickPlusArticlesUrlPrefix : normalArticlesUrlPrefix}${articleId}`}
      referrerPolicy="no-referrer-when-downgrade"
    />
  ) : null
}

export default ProLitterisPixelTracker
