import config from '@config'
import { FunctionComponent } from 'react'
import { formatDate } from '@utils/date'
import { formatTimestampString, isValidTimestamp } from './utils'
import translate from '@i18n'
import ArticleDates from './ArticleDates'
import ArticleDate from './ArticleDate'
import Divider from './Divider'
import { Date } from '@utils/cook/types'

interface TimestampsProps {
  publishedDate?: Date | string
  updatedDate?: Date | string
  className?: string
}

const {
  date: { dateFormat },
} = config

const Timestamps: FunctionComponent<TimestampsProps> = ({
  publishedDate,
  updatedDate,
  className,
}) => {
  let publishedTime = ''
  let updatedTime = ''

  const formattedPublishedDate =
    typeof publishedDate === 'string'
      ? formatDate(publishedDate, dateFormat)
      : formatDate(publishedDate?.value, publishedDate?.format)

  const formattedUpdatedDate =
    typeof updatedDate === 'string'
      ? formatDate(updatedDate, dateFormat)
      : formatDate(updatedDate?.value, updatedDate?.format)

  const areTimesDifferent =
    !!formattedPublishedDate &&
    !!formattedUpdatedDate &&
    formattedPublishedDate.dateOrTime !== formattedUpdatedDate.dateOrTime

  if (isValidTimestamp(formattedPublishedDate)) {
    publishedTime = formatTimestampString(
      formattedPublishedDate,
      translate('published')
    )
  }

  if (isValidTimestamp(formattedUpdatedDate)) {
    updatedTime = formatTimestampString(
      formattedUpdatedDate,
      translate('lastUpdated')
    )
  }

  return (
    <ArticleDates className={className}>
      <ArticleDate>{publishedTime}</ArticleDate>
      {areTimesDifferent && (
        <>
          <Divider />
          <ArticleDate>{updatedTime}</ArticleDate>
        </>
      )}
    </ArticleDates>
  )
}

export default Timestamps
