import { FunctionComponent } from 'react'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import PublishedUpdatedTimestamps from '@components/Timestamps'
import { Date } from '@utils/cook/types'

interface TimestampsWidgetProps {
  publishedDate?: Date
  updatedDate?: Date
}

const isValidTimestamp = (publishedDate: any): publishedDate is Date =>
  !!publishedDate

const TimestampsWidget: FunctionComponent<TimestampsWidgetProps> = ({
  publishedDate,
  updatedDate,
}) => {
  if (!isValidTimestamp(publishedDate)) {
    return null
  }
  return (
    <PublishedUpdatedTimestamps
      publishedDate={publishedDate}
      updatedDate={updatedDate}
    />
  )
}

const widget = {
  kind: ['widget', 'timestamp'],
  component: TimestampsWidget,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
