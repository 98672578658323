import { forwardRef, useMemo } from 'react'
import styled, { css, DefaultTheme, useTheme } from 'styled-components'
import {
  CommonCTAProps,
  ButtonProps,
  commonCTAStyles,
  TextWithIconWrapper,
} from './CommonCTA'

type SecondaryCTAButtonColorVariant = 'red' | 'green' | 'white' | 'black'

export type SecondaryCTAButtonProps = CommonCTAProps &
  ButtonProps & {
    colorVariant?: SecondaryCTAButtonColorVariant
  }

export const getColorVariantsMapForSecondaryCTAButton = (
  themeColor: DefaultTheme['color']
): Record<
  SecondaryCTAButtonColorVariant,
  {
    border: {
      default: string
      hover: string
      focus: string
      disabled: string
    }
    text: {
      default: string
      hover: string
      focus: string
      disabled: string
    }
  }
> => ({
  red: {
    border: {
      default: themeColor.primary.blickRed,
      hover: themeColor.secondary.darkRed,
      focus: themeColor.secondary.darkRed,
      disabled: themeColor.tertiary.grey400,
    },
    text: {
      default: themeColor.primary.blickRed,
      hover: themeColor.secondary.darkRed,
      focus: themeColor.secondary.darkRed,
      disabled: themeColor.tertiary.grey400,
    },
  },
  green: {
    border: {
      default: themeColor.secondary.greenAccessible,
      hover: themeColor.secondary.darkGreen,
      focus: themeColor.secondary.darkGreen,
      disabled: themeColor.tertiary.grey400,
    },
    text: {
      default: themeColor.secondary.greenAccessible,
      hover: themeColor.secondary.darkGreen,
      focus: themeColor.secondary.darkGreen,
      disabled: themeColor.tertiary.grey400,
    },
  },
  white: {
    border: {
      default: themeColor.primary.primary02,
      hover: themeColor.tertiary.grey400,
      focus: themeColor.tertiary.grey400,
      disabled: themeColor.tertiary.grey400,
    },
    text: {
      default: themeColor.primary.primary02,
      hover: themeColor.tertiary.grey100,
      focus: themeColor.tertiary.grey100,
      disabled: themeColor.tertiary.grey400,
    },
  },
  black: {
    border: {
      default: themeColor.primary.primary01,
      hover: themeColor.tertiary.grey500,
      focus: themeColor.tertiary.grey500,
      disabled: themeColor.tertiary.grey400,
    },
    text: {
      default: themeColor.primary.primary01,
      hover: themeColor.primary.primary01,
      focus: themeColor.primary.primary01,
      disabled: themeColor.tertiary.grey400,
    },
  },
})

export type StyledSecondaryCTAButtonType = Required<
  Pick<SecondaryCTAButtonProps, 'size' | 'iconLeft' | 'disabled'>
> & {
  buttonStateColors: ReturnType<
    typeof getColorVariantsMapForSecondaryCTAButton
  >[SecondaryCTAButtonColorVariant]
}

export const secondaryCTAButtonStyles = css<StyledSecondaryCTAButtonType>`
  ${({ buttonStateColors, disabled }) => css`
    background-color: transparent;
    border: 1px solid ${buttonStateColors.border.default};
    color: ${buttonStateColors.text.default};

    & > svg {
      color: ${buttonStateColors.text.default};
      fill: ${buttonStateColors.text.default};
    }

    @media (hover: hover) {
      &:hover:not([disabled]) {
        border: 1px solid ${buttonStateColors.border.hover};
        color: ${buttonStateColors.text.hover};

        & > svg {
          color: ${buttonStateColors.text.hover};
          fill: ${buttonStateColors.text.hover};
        }
      }
    }

    &:focus-visible {
      border: 1px solid ${buttonStateColors.border.focus};
      color: ${buttonStateColors.text.focus};

      & > svg {
        color: ${buttonStateColors.text.focus};
        fill: ${buttonStateColors.text.focus};
      }
    }

    ${disabled &&
    css`
      pointer-events: none;
      border: 1px solid ${buttonStateColors.border.disabled};
      color: ${buttonStateColors.text.disabled};

      & > svg {
        color: ${buttonStateColors.text.disabled};
        fill: ${buttonStateColors.text.disabled};
      }
    `};
  `}
`

const StyledSecondaryCTAButton = styled.button<StyledSecondaryCTAButtonType>`
  ${commonCTAStyles}
  ${secondaryCTAButtonStyles}
`

const SecondaryCTAButton = forwardRef<
  HTMLButtonElement,
  SecondaryCTAButtonProps
>(
  (
    {
      colorVariant = 'red',
      size = 'large',
      disabled = false,
      type = 'button',
      iconName,
      iconLeft = false,
      className,
      children,
      onClick,
    },
    ref
  ) => {
    const theme = useTheme()

    const buttonStateColors = useMemo(
      () => getColorVariantsMapForSecondaryCTAButton(theme.color)[colorVariant],
      [theme.color, colorVariant]
    )

    return (
      <StyledSecondaryCTAButton
        ref={ref}
        className={className}
        size={size}
        disabled={disabled}
        type={type}
        onClick={onClick}
        buttonStateColors={buttonStateColors}
        iconLeft={iconLeft}>
        {iconName ? (
          <TextWithIconWrapper iconName={iconName} size={size}>
            {children}
          </TextWithIconWrapper>
        ) : (
          children
        )}
      </StyledSecondaryCTAButton>
    )
  }
)

SecondaryCTAButton.displayName = 'SecondaryCTAButton'

export default SecondaryCTAButton
