import ControlledCheckbox from '@components/ControlledCheckbox'
import useLeserreporterContext from '@hooks/useLeserreporterContext'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

const StyledAnonymousCheckbox = styled.div`
  ${({
    theme: {
      spacing: { spacing20, spacing28 },
      typography: {
        bodycopy: {
          small2: { bundledCSS: bodycopySmall2CSS },
        },
      },
      color: {
        primary: { primary01: primary01Color },
      },
    },
  }) => css`
    padding: ${spacing20} 0 ${spacing28} 0;
    color: ${primary01Color};
    ${bodycopySmall2CSS};
  `}
`

const AnonymousCheckbox: FunctionComponent = () => {
  const {
    data: { anonymous },
    handleChange,
  } = useLeserreporterContext()
  return (
    <StyledAnonymousCheckbox>
      <label>
        <ControlledCheckbox
          name="anonymous"
          checked={anonymous}
          onChange={handleChange}>
          Meinen Beitrag anonym veröffentlichen (optional)
        </ControlledCheckbox>
      </label>
    </StyledAnonymousCheckbox>
  )
}

export default AnonymousCheckbox
