import { FunctionComponent, ReactNode } from 'react'
import contextArticleTextbox from '@contexts/articleTextbox'
import { ImageAlignment } from './types'

interface ArticleTextboxContextProvider {
  hasControls: boolean
  imageAlignment: ImageAlignment
  isExpanded: boolean
  isPromo: boolean
  children?: ReactNode
}

const { ArticleTextboxContext } = contextArticleTextbox

const ArticleTextboxContextProvider: FunctionComponent<
  ArticleTextboxContextProvider
> = ({ hasControls, imageAlignment, isExpanded, isPromo, children }) => (
  <ArticleTextboxContext.Provider
    value={{
      hasControls,
      imageAlignment,
      isExpanded,
      isPromo,
    }}>
    {children}
  </ArticleTextboxContext.Provider>
)

export default ArticleTextboxContextProvider
