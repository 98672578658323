import { ConfigProps } from '@config/types'

const config = ({ publication }: ConfigProps) => {
  const GA4CookieNameSegment =
    publication === 'romandie' ? 'NKTZ1Z6QY0' : '0E04YP6LZS'

  return {
    appName: 'news-web',
    gtm: {
      id: 'GTM-T3MW3GR',
    },
    ga: {
      id: 'UA-54102511-26',
    },
    GACookieName: '_ga',
    GA4CookieNameSegment,
    GA4CookieName: `_ga_${GA4CookieNameSegment}`,
    optimize: {
      id: 'GTM-PCS5LP3',
    },
    comscore: {
      publisherId: '28229342',
      brand: `Blick${publication === 'romandie' ? ' | fr' : ''}`,
      site: `blick.ch${publication === 'romandie' ? '/fr' : ''}`,
    },
    impressionThreshold: 500,
    metadataFields: [
      'articleFeatures',
      'author',
      'context',
      'contextId',
      'creator',
      'firstPublishedDate',
      'hasLivescore',
      'hasLivestream',
      'hasLiveticker',
      'hasPlaylist',
      'id',
      'lastModifiedDate',
      'sectionUniqueNames',
      'tags',
      'teaserTitle',
      'thirdPartyId',
      'title',
    ],
    sectionsToBeTrackedOnHomePage:
      publication === 'romandie'
        ? ([] as const)
        : ([
            'videos',
            'sport',
            'people',
            'sport videos',
            'spiele & rätsel',
            'wettbewerbe',
          ] as const),
  } as const satisfies Record<string, unknown>
}

export default config
