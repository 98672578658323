import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { desktopCSS, tabletCSS } from '@measures/responsive'

interface UserMenuItemProps {
  title: string
  children?: ReactNode
  onClick?: () => void
}

const StyledUserMenuItem = styled.li`
  ${({
    onClick,
    theme: {
      color: {
        tertiary: { grey300 },
      },
      typography: {
        bodycopy: {
          small2: { bundledCSS: small2BodycopyCSS },
        },
      },
      spacing: { spacing4, spacing12, spacing20 },
    },
  }) => css`
    display: grid;
    grid-row-gap: ${spacing4};

    padding: ${spacing12} ${spacing20};

    cursor: ${!!onClick ? 'pointer' : 'default'};
    list-style: none;
    border-bottom: 1px solid ${grey300};
    ${small2BodycopyCSS};
    line-height: 24px;

    animation: fall-down 125ms;
    animation-iteration-count: 1;

    ${tabletCSS(css`
      animation: none;
    `)}

    ${desktopCSS(css`
      &:last-child {
        border-bottom: none;
      }
    `)}
  `}
`

const Title = styled.div<{ isClickable: boolean }>`
  ${({
    theme: {
      typography: {
        headings: {
          small: { bundledCSS: smallHeadingsCSS },
        },
      },
    },
    isClickable,
  }) => css`
    ${smallHeadingsCSS}
    line-height: 24px;

    &:hover {
      text-decoration: ${isClickable ? 'underline' : 'none'};
    }
  `}
`

const UserMenuItem: FunctionComponent<UserMenuItemProps> = ({
  children,
  onClick,
  title,
}) => {
  return (
    <StyledUserMenuItem onClick={onClick}>
      <Title isClickable={!!onClick}>{title}</Title>
      {!!children && <div>{children}</div>}
    </StyledUserMenuItem>
  )
}

export default UserMenuItem
