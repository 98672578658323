import ResponsiveImageV2 from '@components/ResponsiveImageV2'
import { ImageRatios, ImageWidths } from '@components/ResponsiveImageV2/types'
import { FunctionComponent, ReactNode } from 'react'
import { TeaserStandardVerticalVideoAPIProps } from '@widgets/TeaserStandardVerticalVideo'

export type TeaserImageProps = TeaserStandardVerticalVideoAPIProps['image'] & {
  className?: string
  children?: ReactNode
}

const RATIOS = {
  desktop: '16_9',
  tablet: '16_9',
  mobile: '16_9',
} as const satisfies ImageRatios

const WIDTHS = {
  desktop: 227,
  tablet: 250,
  mobile: 250,
  mobileSmall: 250,
} as const satisfies ImageWidths

const TeaserImage: FunctionComponent<TeaserImageProps> = ({
  src,
  alt,
  crops,
  children,
  className,
}) => (
  <ResponsiveImageV2
    className={className}
    src={src}
    alt={alt}
    crops={crops}
    ratios={RATIOS}
    widths={WIDTHS}>
    {children}
  </ResponsiveImageV2>
)

export default TeaserImage
