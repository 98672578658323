import IconButton from '@components/Buttons/IconButton'
import { FunctionComponent, SyntheticEvent } from 'react'
import styled, { css, useTheme } from 'styled-components'

export interface CloseButtonProps {
  isFullScreen?: boolean
  onClick: (event?: SyntheticEvent) => void
}

const StyledCloseButtonWrapper = styled.div<{ isFullScreen?: boolean }>`
  ${({
    theme: {
      spacing: { spacing8, spacing16 },
    },
    isFullScreen,
  }) => css`
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    right: ${spacing8};

    ${isFullScreen &&
    css`
      top: ${spacing16};
      right: ${spacing16};
    `};
  `}
`

const CloseButton: FunctionComponent<CloseButtonProps> = ({
  isFullScreen,
  onClick,
}) => {
  const theme = useTheme()

  return (
    <StyledCloseButtonWrapper isFullScreen={isFullScreen}>
      <IconButton
        ariaLabel="Close"
        buttonSize={48}
        onClick={onClick}
        iconName="xmark-large-solid"
        iconSize={24}
        iconColor={theme.color.primary.primary02}
      />
    </StyledCloseButtonWrapper>
  )
}

export default CloseButton
