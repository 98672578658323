import config from '@config'
import { FunctionComponent, useCallback, useRef, useState } from 'react'
import styled, { css, useTheme } from 'styled-components'
import IconButton from '@components/Buttons/IconButton'
import Popover from '@components/Popover'
import Facebook from '@components/Social/Facebook'
import Twitter from '@components/Social/Twitter'
import Whatsapp from '@components/Social/Whatsapp'
import Email from '@components/Social/Email'
import Copy from '@components/Social/Copy'
import translate from '@i18n'
import { getUTMParameters } from '@components/Social/utils'
import { isMobileUserAgent } from '@measures/responsive'

const {
  backend: { baseUrl, rootUrl },
  blickBites: { urlHashParam, utmContent },
} = config

const popoverContentWidth = 244
const shareButtonSize = 40

interface ShareCTAButtonProps {
  title: string
  articleId: string
}

const StyledIconButton = styled(IconButton)`
  border-radius: 50%;
  background: rgba(17, 17, 17, 0.5);
`

const Wrapper = styled.div`
  position: relative;
`

const StyledPopover = styled(Popover)`
  margin-right: -${popoverContentWidth / 2 - shareButtonSize / 2}px; // center the popover content with the share button

  &:after {
    right: 50%;
    margin-right: -10px;
  }
`

const PopoverContent = styled.div`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    width: ${popoverContentWidth}px;
    padding: ${spacing16};
    box-sizing: border-box;
  `}
`

const PopoverHeader = styled.div`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${spacing16};
  `}
`

const PopoverTitle = styled.div`
  ${({
    theme: {
      typography: {
        headings: {
          small: { bundledCSS: smallHeadingsCSS },
        },
      },
      color: {
        primary: { primary01: primary01Color },
      },
    },
  }) => css`
    ${smallHeadingsCSS};
    color: ${primary01Color};
  `}
`

const PopoverBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const ShareCTAButton: FunctionComponent<ShareCTAButtonProps> = ({
  title,
  articleId,
}) => {
  const theme = useTheme()
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)

  const urlHash = `#${urlHashParam}=${articleId}`
  const urlEncodedHash = `%23${urlHashParam}%3D${articleId}`
  const shareUrl = `${baseUrl}${rootUrl}`

  const togglePopover = useCallback(() => {
    setIsPopoverOpen((prevValue) => !prevValue)
  }, [])

  const onShareButtonClick = useCallback(async () => {
    if (navigator.share && isMobileUserAgent()) {
      try {
        const utmSuffix = getUTMParameters({
          utmSource: 'native-share',
          utmContent,
          fullSuffix: true,
        })

        await navigator.share({
          text: `${translate('shareMessage')}\n\n${title}\n\n`,
          url: `${shareUrl}${utmSuffix}${urlHash}`,
        })
      } catch (err) {
        console.error('Error sharing Blick Bite:', err)
      }
    } else {
      togglePopover()
    }
  }, [shareUrl, title, togglePopover, urlHash])

  return (
    <Wrapper ref={wrapperRef}>
      <StyledIconButton
        ariaLabel="Share"
        buttonSize={shareButtonSize}
        onClick={onShareButtonClick}
        iconName="share-solid"
        iconSize={20}
        iconColor={theme.color.primary.primary02}
      />

      {isPopoverOpen && (
        <StyledPopover
          position="top"
          trackClickOutside={true}
          wrapperRef={wrapperRef}
          onClickOutside={togglePopover}>
          <PopoverContent>
            <PopoverHeader>
              <PopoverTitle>{translate('blickBites.share')}</PopoverTitle>
              <IconButton
                ariaLabel="Close"
                buttonSize={24}
                onClick={togglePopover}
                iconName="xmark-large"
                iconSize={16}
                iconColor={theme.color.primary.primary01}
              />
            </PopoverHeader>
            <PopoverBody>
              <Facebook
                shareUrl={shareUrl}
                utmContent={utmContent}
                shareUrlHash={urlEncodedHash}
                label="Facebook"
              />
              <Twitter
                shareUrl={shareUrl}
                shareTitle={title}
                utmContent={utmContent}
                shareUrlHash={urlEncodedHash}
                label="X"
              />
              <Whatsapp
                shareUrl={shareUrl}
                shareTitle={title}
                utmContent={utmContent}
                shareUrlHash={urlEncodedHash}
                label="WhatsApp"
              />
              <Email
                shareUrl={shareUrl}
                shareTitle={title}
                utmContent={utmContent}
                shareUrlHash={urlEncodedHash}
                label="E-Mail"
              />
              <Copy
                shareUrl={shareUrl}
                utmContent={utmContent}
                shareUrlHash={urlHash}
                label={translate('social.copy')}
              />
            </PopoverBody>
          </PopoverContent>
        </StyledPopover>
      )}
    </Wrapper>
  )
}

export default ShareCTAButton
