import usePageMetadata from '@hooks/usePageMetadata'
import { FunctionComponent, useContext } from 'react'
import DesktopGamificationButton from '@components/Buttons/GamificationButton/DesktopGamificationButton'
import MobileGamificationButton from '@components/Buttons/GamificationButton/MobileGamificationButton'
import EndOfArticleContext from '@contexts/endOfArticle'

const GamificationButton: FunctionComponent = () => {
  const { gamification } = usePageMetadata()
  const endOfArticle = useContext(EndOfArticleContext)

  if (gamification?.active) {
    return (
      <>
        <DesktopGamificationButton {...gamification} />
        {!endOfArticle && <MobileGamificationButton {...gamification} />}
      </>
    )
  }

  return null
}

export default GamificationButton
