import { useState, useCallback } from 'react'

import useInViewChange from '@hooks/useInViewChange'

export type UseVideoIsInViewportOutputProps = {
  isInViewport: boolean | null
  viewportRef: (node?: Element | null | undefined) => void
}

const useVideoIsInViewport = ({
  isOverlay,
}: {
  isOverlay: boolean
}): UseVideoIsInViewportOutputProps => {
  const [isInViewport, setIsInViewport] = useState<boolean | null>(null)

  const onInViewChangeHandler = useCallback(
    (inView: any) => {
      // Workaround for full screen video issue in Safari / macOS (https://jira.ringieraxelspringer.ch/browse/BTV-109)
      // Root Cause: Waypoint fires useless `onEnter` and `onLeave` when video is in the fullscreen mode (Safari ONLY)
      // The following fix will prevent incorrect state of `isInViewport` when page is in full screen mode
      if (document.webkitCurrentFullScreenElement || isOverlay) {
        return
      }

      setIsInViewport(inView)
    },
    [isOverlay]
  )

  const viewportRef = useInViewChange({
    onInViewChange: onInViewChangeHandler,
  })

  return { isInViewport, viewportRef }
}

export default useVideoIsInViewport
