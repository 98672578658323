import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { CaptionProps } from '@widgets/ArticleTextbox/types'
import useHasInvertedColors from '@hooks/useHasInvertedColors'

const StyledCaption = styled.div<{ hasInvertedColors: boolean }>`
  ${({
    theme: {
      color: {
        primary: { primary01, primary02 },
      },
      typography: {
        bodycopy: {
          xxsmall: { bundledCSS: bodyCopyXXSmallCSS },
        },
      },
    },
    hasInvertedColors,
  }) => css`
    color: ${hasInvertedColors ? primary02 : primary01};

    p {
      margin: 0;
    }

    ${bodyCopyXXSmallCSS}
  `}
`

const Caption: FunctionComponent<CaptionProps> = ({ children }) => {
  const hasInvertedColors = useHasInvertedColors()

  return (
    <StyledCaption
      hasInvertedColors={hasInvertedColors}
      dangerouslySetInnerHTML={{
        __html: children,
      }}
    />
  )
}

export default Caption
