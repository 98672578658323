import { FunctionComponent } from 'react'
import ScoreboardMatchScore from './ScoreboardMatchScore'
import ScoreboardMatchPeriods from '@components/Scoreboard/ScoreboardMatchPeriods'
import useScoreboard from '@hooks/useScoreboard'
import { LIVE_EVENT_DATA_SPORT_TYPES } from '@utils/formatters/common'
import ScoreboardOpponentSeparator from './ScoreboardOpponentSeparator'

const ScoreboardMatchResultHockey: FunctionComponent = () => {
  const { formattedData } =
    useScoreboard<typeof LIVE_EVENT_DATA_SPORT_TYPES.HOCKEY>()

  const { isScheduled, scoreExplanation, teams } = formattedData

  const awayScore = teams[1].score
  const homeScore = teams[0].score

  return isScheduled ? (
    <ScoreboardOpponentSeparator />
  ) : (
    <>
      <ScoreboardMatchScore
        awayScore={awayScore}
        homeScore={homeScore}
        explanation={scoreExplanation}
      />
      <ScoreboardMatchPeriods />
    </>
  )
}

export default ScoreboardMatchResultHockey
