import ABTestManager from '@components/ABTestManager'
import AdManager from '@components/AdManager'
import ArticleSchema from '@components/Schema/ArticleSchema'
import RecipeSchema from '@components/Schema/RecipeSchema'
import CueLiveSchema from '@components/Schema/CueLiveSchema'
import AuthManager from '@components/AuthManager'
import HrefLangLinks from '@components/HrefLangLinks'
import HTMLLinks from '@components/HTMLLinks'
import Kropka from '@components/Kropka'
import ArticleMetaTags from '@components/MetaTags/ArticleMetaTags'
import CommonMetaTags from '@components/MetaTags/CommonMetaTags'
import OneSignalManager from '@components/OneSignalManager'
import Papi from '@components/Papi'
import SchemaInjector from '@components/SchemaInjector'
import NewsMediaOrganizationSchema from '@components/Schema/NewsMediaOrganizationSchema'
import OrganizationSchema from '@components/Schema/OrganizationSchema'
import TrackingManager from '@components/TrackingManager'
import TrackPageView from '@components/TrackPageView'
import TrackStartScrolling from '@components/TrackStartScrolling'
import { FunctionComponent } from 'react'
import { CatchAllPageServerProps } from '../../pages/[[...pageUrl]]'
import { enhanceComponentWithErrorBoundary } from '@utils/sentry'
import PianoManager from '@components/Piano/PianoManager'
import URLParamManager from '@components/URLParamManager'
import AuthorSchema from '@components/Schema/AuthorSchema'
import WebSiteSchema from '@components/Schema/WebSiteSchema'
import CMPManager from '@components/CMPManager'
import AnimatedPreviewManager from '@components/AnimatedPreviewManager'
import ViewportManager from '@components/ViewportManager'
import PianoMetaTags from '@components/PianoMetaTags'
import DebugManager from '@components/DebugManager'
import WebPageSchema from '@components/Schema/WebPageSchema'
import SoftwareApplicationSchema from '@components/Schema/SoftwareApplicationSchema'
import VideoSchema from '@components/Schema/VideoSchema'
import useGetPageIdentity from '@hooks/useGetPageIdentity'
import VideoManager from '@components/VideoManager'
import ChatbotManager from '@components/Chatbot/ChatbotManager'
import GTMManager from '@components/GTMManager'

export type NonErrorPagePreCSSComponentsProps = Pick<
  CatchAllPageServerProps,
  'isBlickPlusOfferPage'
> & {
  isStorybook?: boolean
}

const [
  SchemaInjectorWithErrorBoundary,
  ArticleSchemaWithErrorBoundary,
  RecipeSchemaWithErrorBoundary,
  AuthorSchemaWithErrorBoundary,
  CueLiveSchemaWithErrorBoundary,
  URLParamManagerWithErrorBoundary,
  ABTestManagerWithErrorBoundary,
  AuthManagerWithErrorBoundary,
  PapiWithErrorBoundary,
  PianoManagerWithErrorBoundary,
  AdManagerWithErrorBoundary,
  DebugManagerWithErrorBoundary,
  TrackPageViewWithErrorBoundary,
  TrackStartScrollingWithErrorBoundary,
  KropkaWithErrorBoundary,
  OneSignalManagerWithErrorBoundary,
  CMPManagerWithErrorBoundary,
  ViewportManagerWithErrorBoundary,
  AnimatedPreviewManagerWithErrorBoundary,
  PianoMetaTagsWithErrorBoundary,
  WebSiteSchemaWithErrorBoundary,
  WebPageSchemaWithErrorBoundary,
  SoftwareApplicationSchemaWithErrorBoundary,
  NewsMediaOrganizationSchemaWithErrorBoundary,
  OrganizationSchemaWithErrorBoundary,
  VideoSchemaWithErrorBoundary,
  VideoManagerWithErrorBoundary,
  ChatbotManagerWithErrorBoundary,
  GTMManagerWithErrorBoundary,
] = [
  SchemaInjector,
  ArticleSchema,
  RecipeSchema,
  AuthorSchema,
  CueLiveSchema,
  URLParamManager,
  ABTestManager,
  AuthManager,
  Papi,
  PianoManager,
  AdManager,
  DebugManager,
  TrackPageView,
  TrackStartScrolling,
  Kropka,
  OneSignalManager,
  CMPManager,
  ViewportManager,
  AnimatedPreviewManager,
  PianoMetaTags,
  WebSiteSchema,
  WebPageSchema,
  SoftwareApplicationSchema,
  NewsMediaOrganizationSchema,
  OrganizationSchema,
  VideoSchema,
  VideoManager,
  ChatbotManager,
  GTMManager,
].map((Component) => enhanceComponentWithErrorBoundary(Component))

const NonErrorPagePreCSSComponents: FunctionComponent<
  NonErrorPagePreCSSComponentsProps
> = ({ isBlickPlusOfferPage, isStorybook }) => {
  const { isArticle, isRecipe, isAuthor } = useGetPageIdentity()

  return (
    <>
      {!isStorybook && <HrefLangLinks />}
      {!isStorybook && <CommonMetaTags />}
      {!isStorybook && <ArticleMetaTags />}
      {!isStorybook && <SchemaInjectorWithErrorBoundary />}
      {!isStorybook && <VideoSchemaWithErrorBoundary />}
      {!isStorybook && <NewsMediaOrganizationSchemaWithErrorBoundary />}
      {!isStorybook && <OrganizationSchemaWithErrorBoundary />}
      {!isStorybook && <WebSiteSchemaWithErrorBoundary />}
      {!isStorybook && <WebPageSchemaWithErrorBoundary />}
      {!isStorybook && <SoftwareApplicationSchemaWithErrorBoundary />}
      {!isStorybook &&
        isArticle &&
        !isRecipe &&
        !isAuthor &&
        !isBlickPlusOfferPage && <ArticleSchemaWithErrorBoundary />}
      {!isStorybook && <PianoMetaTagsWithErrorBoundary />}
      {!isStorybook && isRecipe && !isBlickPlusOfferPage && (
        <RecipeSchemaWithErrorBoundary />
      )}
      {!isStorybook && isAuthor && !isBlickPlusOfferPage && (
        <AuthorSchemaWithErrorBoundary />
      )}
      {!isStorybook && <CueLiveSchemaWithErrorBoundary />}
      {!isStorybook && <HTMLLinks />}

      <ViewportManagerWithErrorBoundary />
      <URLParamManagerWithErrorBoundary />
      <ABTestManagerWithErrorBoundary />
      <DebugManagerWithErrorBoundary />
      <PapiWithErrorBoundary />
      <AnimatedPreviewManagerWithErrorBoundary />
      <VideoManagerWithErrorBoundary />
      <TrackingManager />
      <KropkaWithErrorBoundary />

      <ChatbotManagerWithErrorBoundary />

      <AuthManagerWithErrorBoundary />

      <CMPManagerWithErrorBoundary isStorybook={isStorybook} />
      {!isStorybook && <PianoManagerWithErrorBoundary />}
      {!isStorybook && <AdManagerWithErrorBoundary />}

      <TrackPageViewWithErrorBoundary />
      <TrackStartScrollingWithErrorBoundary />
      {!isStorybook && <OneSignalManagerWithErrorBoundary />}
      {!isStorybook && <GTMManagerWithErrorBoundary />}
    </>
  )
}

export default NonErrorPagePreCSSComponents
