import { FunctionComponent, useCallback, useMemo } from 'react'
import styled, { css } from 'styled-components'
import SlideImage from '../Common/SlideImage'
import { GalleryWidget, Slide } from '@widgets/Gallery/types'
import { useFullScreenGallery } from '@hooks/useFullScreenGallery'
import useFullScreenImagePortal from '@hooks/useFullScreenImagePortal'
import CloseButton from '../Common/CloseButton'
import SlideFooter from '../Common/SlideFooter'
import { useGallerySlideClickTracking } from '../utils/useGalleryTracking'
import useShouldStretch from '@hooks/useShouldStretch'
import { StretchType } from '@contexts/storyTellingFullWidth'
import { printCSS } from '@utils/style'
import {
  getHeroImageInvertedAspectRatio,
  shouldUseHeroImageRatio,
} from '../Common/SlideImage/utils'
import useViewportType from '@hooks/useViewport/useViewportType'
import useIsInHerotellingPage from '@hooks/useIsInHerotellingPage'
import { desktopCSS, mobileCSS, mobileLandscapeCSS } from '@measures/responsive'

type SingleImageGalleryPlaceholderProps = StretchType & {
  isNarrow: boolean
}

const Wrapper = styled.div<{ aspectRatio: number; isFullScreen: boolean }>`
  ${({ aspectRatio, isFullScreen }) => css`
    padding-top: ${(isFullScreen ? 2 / 3 : aspectRatio) * 100}%;
    position: relative;
    ${printCSS(css`
      padding-top: 0;
    `)}

    ${isFullScreen &&
    css`
      ${mobileCSS(css`
        padding-top: 0;
      `)}

      ${mobileLandscapeCSS(css`
        padding-top: 0;
      `)}
    `}
  `}
`

const SingleImageGalleryContainer = styled.div<{ isFullScreen: boolean }>`
  ${({ isFullScreen }) => css`
    ${isFullScreen &&
    css`
      max-width: 994px;
      width: 100%;

      ${mobileCSS(css`
        box-sizing: border-box;
        display: grid;
        grid-template-rows: auto 1fr auto;
        height: 100%;
      `)}

      ${mobileLandscapeCSS(css`
        box-sizing: border-box;
        display: grid;
        grid-template-rows: auto 1fr auto;
        height: 100%;
      `)}
    `}
  `}
`

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${printCSS(css`
    height: 234px;
    object-fit: cover;
    position: relative;
  `)}
`

const SingleImageGalleryPlaceholder = styled.div<SingleImageGalleryPlaceholderProps>`
  ${({
    shouldStretchToHeaderWidth,
    shouldStretch,
    shouldStretchToCardWidth,
    isNarrow,
    theme: {
      measures: { stretchWidth, stretchToLayoutCardWidth },
    },
  }) => css`
    height: 100%;
    position: relative;
    ${printCSS(css`
      width: 50%;
      display: inline-block;
      break-inside: avoid;
      overflow: hidden;
    `)}

    ${shouldStretch && stretchWidth(shouldStretchToHeaderWidth)}

    ${shouldStretchToCardWidth && stretchToLayoutCardWidth()}
    
    ${desktopCSS(css`
      ${isNarrow &&
      css`
        padding: 0 120px;
      `}
    `)}
  `}
`

const PlaceholderContent = styled.div<{
  aspectRatio: number
  isFullscreen: boolean
  hasPortalNode: boolean
}>`
  ${({ aspectRatio, isFullscreen, hasPortalNode }) => css`
    ${!isFullscreen &&
    hasPortalNode &&
    css`
      display: none;
    `}

    width: 100%;
    padding-top: ${100 * aspectRatio}%;
  `}
`

type SingleImageGalleryProps = Pick<
  GalleryWidget,
  'title' | 'id' | 'imageWidth'
> & {
  slide: Slide
}

const SingleImageGallery: FunctionComponent<SingleImageGalleryProps> = ({
  slide,
  title: galleryTitle,
  id: galleryId,
  imageWidth,
}) => {
  const { image, credit, caption } = slide

  const freeCrop = image.crops?.['free']
  const viewportType = useViewportType()
  const isInHerotellingPage = useIsInHerotellingPage()

  const herotellingSingleImageAspectRatio = getHeroImageInvertedAspectRatio(
    imageWidth,
    viewportType
  )
  const shouldUseHeroImageRatioValue =
    isInHerotellingPage &&
    shouldUseHeroImageRatio(imageWidth, viewportType) &&
    herotellingSingleImageAspectRatio

  const aspectRatio = useMemo<number>(() => {
    const originalDimensions = freeCrop
      ? { width: freeCrop.width, height: freeCrop.height }
      : { width: image.width, height: image.height }
    return originalDimensions.height / originalDimensions.width
  }, [freeCrop, image.height, image.width])

  const derivedAspectRatio = shouldUseHeroImageRatioValue
    ? herotellingSingleImageAspectRatio
    : aspectRatio

  const { isFullScreen, setIsFullScreen } = useFullScreenGallery()
  const { portalNode, enterFullScreen, exitFullScreen, InPortal, OutPortal } =
    useFullScreenImagePortal()
  const stretch = useShouldStretch()

  const trackSlideClick = useGallerySlideClickTracking(
    1,
    galleryId,
    galleryTitle
  )

  const handleEnterFullScreen = useCallback(() => {
    if (!isFullScreen) {
      setIsFullScreen(enterFullScreen())
      trackSlideClick()
    }
  }, [setIsFullScreen, enterFullScreen, isFullScreen, trackSlideClick])

  const handleLeaveFullScreen = useCallback(() => {
    setIsFullScreen(!exitFullScreen())
  }, [setIsFullScreen, exitFullScreen])
  const isNarrow = imageWidth === 'narrow'
  return (
    <SingleImageGalleryPlaceholder {...stretch} isNarrow={isNarrow}>
      <PlaceholderContent
        aspectRatio={derivedAspectRatio}
        isFullscreen={isFullScreen}
        hasPortalNode={!!portalNode}>
        <SlideFooter
          caption={caption}
          isSingleSlide={true}
          credit={credit}
          isPlaceholder={true}
          hasPortalNode={!!portalNode}
        />
      </PlaceholderContent>
      {!!portalNode && (
        <>
          <InPortal node={portalNode}>
            <SingleImageGalleryContainer isFullScreen={isFullScreen}>
              <CloseButton onClose={handleLeaveFullScreen} />
              <Wrapper
                aspectRatio={derivedAspectRatio}
                isFullScreen={isFullScreen}>
                <ImageWrapper>
                  <SlideImage
                    image={image}
                    onClick={handleEnterFullScreen}
                    shouldPreload={true}
                    imageWidth={imageWidth}
                  />
                </ImageWrapper>
              </Wrapper>
              <SlideFooter
                caption={caption}
                isSingleSlide={true}
                credit={credit}
                hasExtraPadding={stretch.shouldStretchToCardWidth}
              />
            </SingleImageGalleryContainer>
          </InPortal>
          {!isFullScreen && <OutPortal node={portalNode} />}
        </>
      )}
    </SingleImageGalleryPlaceholder>
  )
}

export default SingleImageGallery
