import { GraphicsObject } from '../types'

export const parseGraphicsValue = (value?: string): GraphicsObject => {
  if (!value) {
    return {}
  }

  if (value.includes('gfx_headline')) {
    return {
      headline: value.match(/>([\s\S]*?)</)?.[1],
    }
  }

  if (value.includes('gfx_source')) {
    return {
      source: value.match(/>([\s\S]*?)</)?.[1],
    }
  }

  if (value.includes('gfx_function')) {
    return {
      name: value.match(/<c.gfx_name>([\s\S]*?)<\/c>/)?.[1],
      function: value.match(/<c.gfx_function>([\s\S]*?)<\/c>/)?.[1],
    }
  }

  return {}
}
