import { mobileCSS } from '@measures/responsive'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { SearchMessageProps } from '@widgets/Search/types'

const Wrapper = styled.div`
  ${({
    theme: {
      typography: {
        subheads: {
          small2: { bundledCSS: smallSubheadingCSS },
          xsmall2: { bundledCSS: xsmallSubheadingCSS },
        },
      },
      color: {
        tertiary: { grey800 },
      },
      spacing: { spacing16, spacing24 },
    },
  }) => css`
    text-align: left;
    ${smallSubheadingCSS};
    color: ${grey800};
    margin-bottom: ${spacing16};
    ${mobileCSS(css`
      text-align: center;
      margin-top: ${spacing16};
      margin-bottom: ${spacing24};
      ${xsmallSubheadingCSS};
    `)}
  `}
`

const ResultsStatus: FunctionComponent<SearchMessageProps> = ({ children }) => (
  <Wrapper>{children}</Wrapper>
)

export default ResultsStatus
