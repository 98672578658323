import translate from '@i18n'
import { FormattedDate } from '@utils/date'

const formatTimestampString = (
  timestampDateTime: FormattedDate,
  label: string
) => {
  const { time, date, sameDateTime } = timestampDateTime
  const timestampDetail =
    sameDateTime || `${date} ${translate('published.at')} ${time}`
  return `${label}: ${timestampDetail}`
}

const isValidTimestamp = (
  timestamp: FormattedDate | ''
): timestamp is FormattedDate =>
  !!(timestamp as FormattedDate).date && !!(timestamp as FormattedDate).time

export { formatTimestampString, isValidTimestamp }
