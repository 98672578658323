import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { createHtmlPortalNode, OutPortal } from 'react-reverse-portal'
import styled, { css } from 'styled-components'
import {
  desktopCenteredContentCSS,
  desktopCSS,
  desktopLeftAlignedCSS,
  mobileCSS,
  mobileAndTabletCSS,
  tabletCSS,
} from '@measures/responsive'
import useIsContentForceCentered from '@hooks/useIsContentForceCentered'
import useGetPageIdentity from '@hooks/useGetPageIdentity'

export interface StyledModalOverlayPortalProps {
  isVisible: boolean
}

const LeftSidebar = styled.div`
  position: relative;
  position: sticky;
  box-sizing: border-box;
  z-index: 1;
`

const MainContentWrapper = styled.div`
  position: relative;
  grid-area: mcw;
  min-height: 100vh;
  max-width: 994px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${desktopCSS(css`
    padding: 0 15px;
  `)};

  ${tabletCSS(css`
    padding: 0 68px;
  `)};

  ${mobileCSS(css`
    padding: 0 16px;
  `)};
`

const InnerWrapper = styled.div<{
  isContentForceCentered: boolean
  isArticle: boolean
}>`
  ${({ isContentForceCentered }) => css`
    ${desktopCenteredContentCSS(css`
      grid-template-columns: minmax(0, 1fr) 994px minmax(0, 1fr);
      grid-template-rows: repeat(3, auto);
      grid-template-areas: 'lsb mcw';
      display: grid;
    `)}

    > ${LeftSidebar} {
      ${mobileAndTabletCSS(css`
        display: none;
      `)};
    }

    > ${LeftSidebar} {
      grid-area: lsb;

      ${desktopLeftAlignedCSS(css`
        ${!isContentForceCentered &&
        css`
          display: none;
        `};
      `)};
    }
  `}
`

const ModalOverlayWrapper = styled.div<{ isVisible: boolean }>`
  ${({ isVisible }) => css`
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    overflow: auto;
    position: fixed;
    transition: opacity 0.3s ease-in-out;
    z-index: -1;

    ${isVisible &&
    css`
      animation: fade-in 0.3s forwards;
      /* Header should be behand the overlay */
      /* the Header z-index is 10000 */
      z-index: 70000;

      @keyframes fade-in {
        100% {
          opacity: 1;
        }
      }
    `}
  `}
`

const ModalOverlayPortal: FunctionComponent = () => {
  const [nodeToRender, setNodeToRender] = useState<ReturnType<
    typeof createHtmlPortalNode
  > | null>(null)
  const isContentForceCentered = useIsContentForceCentered()
  const { isArticle } = useGetPageIdentity()
  const queryClient = useQueryClient()
  const unsubscribeFnRef = useRef<() => void>()
  const queryCache = queryClient.getQueryCache()

  const updateNodeToRender = useCallback(
    (args: any) => {
      if (
        args?.action?.type === 'invalidate' &&
        args.query.queryKey[0] === 'modal-overlay-portal-rendered-node-ref'
      ) {
        setNodeToRender(
          queryClient.getQueryData<ReturnType<typeof createHtmlPortalNode>>([
            'modal-overlay-portal-rendered-node-ref',
          ]) ?? null
        )
      }
    },
    [queryClient]
  )

  useEffect(() => {
    unsubscribeFnRef.current = queryCache.subscribe(updateNodeToRender)

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [queryCache, updateNodeToRender])

  useEffect(() => {
    const bodyElement = document.querySelector('body')
    if (nodeToRender) {
      if (bodyElement) {
        bodyElement.style.overflowY = 'hidden'
      }
      queryClient.setQueryData(['modal-overlay-shown'], true)
    } else {
      queryClient.setQueryData(['modal-overlay-shown'], false)
      if (bodyElement) {
        bodyElement.style.overflowY = 'auto'
      }
    }
    queryClient.invalidateQueries({
      queryKey: ['modal-overlay-shown'],
      exact: true,
    })
  }, [nodeToRender, queryClient])

  return (
    <ModalOverlayWrapper isVisible={!!nodeToRender}>
      {(nodeToRender && (
        <InnerWrapper
          isArticle={isArticle}
          isContentForceCentered={isContentForceCentered}>
          <LeftSidebar />
          <MainContentWrapper>
            <OutPortal node={nodeToRender} />
          </MainContentWrapper>
        </InnerWrapper>
      )) ||
        null}
    </ModalOverlayWrapper>
  )
}

export default ModalOverlayPortal
