import { useEffect, useRef, useCallback } from 'react'

const useMount = () => {
  const isMountedRef = useRef<boolean>(false)

  const isMounted = useCallback(() => {
    return isMountedRef.current
  }, [])

  useEffect(() => {
    isMountedRef.current = true
    return () => {
      isMountedRef.current = false
    }
  }, [])

  return isMounted
}

export default useMount
