import { FunctionComponent } from 'react'
import {
  CookWidget,
  JSONTypeForCookWidget,
  CookWidgetJSON,
} from '@widgets/types'
import JSONRenderer from '@components/JSONRenderer'

interface TabsContainer {
  content: CookWidgetJSON[]
}

const TabsContainer: FunctionComponent<TabsContainer> = ({ content }) => (
  <JSONRenderer>{content}</JSONRenderer>
)

const widget = {
  kind: ['widget', 'tabs', 'container'],
  component: TabsContainer,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
