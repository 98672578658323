import { ConfigProps } from '@config/types'

const config = ({ plusLinkShareUrl }: ConfigProps) => ({
  linkGenerationApiUrl: `${plusLinkShareUrl}/generateLink`,
  linkConsumptionApiUrl: `${plusLinkShareUrl}/consumeLink`,
  getSharesApiUrl: `${plusLinkShareUrl}/shares`,
})

export type configType = ReturnType<typeof config>
export default config
