import debounce from 'lodash.debounce'
import { css } from 'styled-components'
import { QueryClient } from '@tanstack/react-query'
import { PageMetadata } from '@hooks/usePageMetadata'
import { StyledAdContainer as AdContainer } from '@components/Ad'
import { SlotCodePlaceholderConfig } from '@config/items/ads'
import { StyledWrapper as AdWrapper } from '@components/Ad/Wrapper'
import { StyledArticleMainContent as MainContentWrapper } from '@components/ArticleMainContent'
import { desktopCSS, tabletCSS, mobileCSS, layout } from '@measures/responsive'
import config from '@config'

type AnyObject = { [key: string]: any }

const {
  abTest: { windowKey },
  ads: { adPlaceholderTitle, slotCodePlaceholderConfig },
} = config

const {
  header: {
    desktop: { offsetFirstRow: headerOffsetFirstRowDesktop },
    tablet: { offsetFirstRow: headerOffsetFirstRowTablet },
    mobile: { offsetFirstRow: headerOffsetFirstRowMobile },
  },
} = layout

function deepCopy<T>(obj: T, hash = new WeakMap()): T {
  // Return if obj is null or not an object
  if (obj === null || typeof obj !== 'object') {
    return obj
  }

  // Handle circular references
  if (hash.has(obj)) {
    return hash.get(obj)
  }

  // Create an array or object to hold the values
  const result: AnyObject = Array.isArray(obj) ? [] : {}

  // Store the reference in the hash map
  hash.set(obj, result)

  // Recursively copy each property
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      result[key] = deepCopy(obj[key], hash)
    }
  }

  // Copy the prototype of the object
  if (Object.getPrototypeOf) {
    Object.setPrototypeOf(result, Object.getPrototypeOf(obj))
  }

  return result as T
}

const loadSlots = (queryClient: QueryClient): void => {
  window.admTagMan.q.push((admTagMan) => {
    admTagMan.loadSlots()
  })

  updateAreAdSlotsLoaded(queryClient, true)
}

const debouncedLoadSlots: typeof loadSlots = debounce(loadSlots, 50)

const refreshSlot = (slotObj: any): void => {
  window.admTagMan.q.push((admTagMan) => {
    admTagMan.refreshSlot(slotObj)
  })
}

const showSlot = (container: string): void => {
  window.admTagMan.q.push((admTagMan) => {
    admTagMan.showSlot(container)
  })
}

const areAdSlotsLoaded = (queryClient: QueryClient): boolean =>
  !!queryClient.getQueryData<boolean>(['areAdSlotsLoaded'])

const updateAreAdSlotsLoaded = (
  queryClient: QueryClient,
  areAdSlotsLoadedValue: boolean
): void => {
  queryClient.setQueryData(['areAdSlotsLoaded'], areAdSlotsLoadedValue)
  queryClient.invalidateQueries({
    queryKey: ['areAdSlotsLoaded'],
    exact: true,
  })
}

const getPageType = (type: PageMetadata['type']) => {
  switch (type) {
    case 'section':
      return 'overview'
    case 'article':
      return 'article'
    default:
      return 'overview'
  }
}

const logAd = (
  {
    queryClient,
    method,
  }: {
    queryClient: QueryClient
    method?: 'info' | 'log' | 'warn' | 'error'
  },
  ...args: any
): void => {
  const showAdLogs =
    queryClient.getQueryData<'show'>([windowKey, 'showAdLogs']) === 'show'

  if (showAdLogs) {
    console[method ?? 'log'](`ADS: ${args[0]}`, ...args.slice(1))
  }
}

const isValidAd = (props?: Record<string, any>): boolean =>
  !!props?.placement &&
  ['slotCodeDesktop', 'slotCodeTablet', 'slotCodeMobile'].some(
    (slot) => props?.hasOwnProperty(slot) && !!props?.[slot]
  )

const isLastHighPriorityPlacement = (placement: string): boolean =>
  ['belowHeroAd', 'sectionTopAd', 'articleTopAd'].includes(placement)

const globalAdStyles = css`
  ${({
    theme: {
      spacing: { spacing16, spacing32 },
      typography: {
        bodycopy: {
          xxsmall: { bundledCSS: bodycopyXXSmallBundledCSS },
        },
      },
      color: {
        tertiary: { grey200: grey200Color, grey800: grey800Color },
      },
      measures: {
        outerPadding: {
          desktop: desktopOuterPadding,
          tablet: tabletOuterPadding,
          mobile: mobileOuterPadding,
        },
        totalSingleColumnSpacing: { desktop: desktopTotalSingleColumnSpacing },
      },
    },
  }) => css`
    ${Object.entries(slotCodePlaceholderConfig).reduce(
      (acc, [slotCodeKey, slotCodeValue]) => {
        const typedSlotCodeValue =
          slotCodeValue as SlotCodePlaceholderConfig[string]

        return css`
          ${acc};

          ${slotCodeKey === 'IAV_1' &&
          css`
            ${AdWrapper} {
              &.slot-code-desktop-${slotCodeKey},
                &.slot-code-tablet-${slotCodeKey},
                &.slot-code-mobile-${slotCodeKey} {
                position: relative;
                display: grid;
                grid-template-columns: minmax(0, 1fr);
                grid-template-rows: minmax(0, 1fr);
                align-items: center;
                text-align: center;
              }
            }
          `};

          ${typedSlotCodeValue.desktop &&
          css`
            ${AdWrapper} {
              &.slot-code-desktop-${slotCodeKey} {
                ${desktopCSS(css`
                  position: relative;
                  display: grid;
                  grid-template-columns: minmax(0, 1fr);
                  grid-template-rows: minmax(0, 1fr);
                  background-color: ${grey200Color};
                  text-align: center;
                  align-items: center;
                  ${!typedSlotCodeValue.desktop.specialStickyVertical &&
                  css`
                    min-height: ${typedSlotCodeValue.desktop.height}px;
                  `};

                  &:before {
                    content: '${adPlaceholderTitle}';
                    ${bodycopyXXSmallBundledCSS};
                    color: ${grey800Color};
                    position: absolute;
                    left: 50%;
                  }

                  .section & {
                    ${typedSlotCodeValue.desktop.specialStickyVertical &&
                    css`
                      align-items: flex-start;
                      min-height: 100%;
                      > ${AdContainer} {
                        position: sticky;
                        top: ${headerOffsetFirstRowDesktop}px;
                      }
                    `};

                    ${typedSlotCodeValue.desktop.stretchToFullSectionWidth &&
                    css`
                      min-width: calc(100% + 2 * ${desktopOuterPadding});
                      margin-left: -${desktopOuterPadding};
                    `};

                    &:before {
                      top: 50%;
                      transform: translate(-50%, -50%);
                    }

                    &.next-adm-loaded {
                      &:before {
                        display: none;
                      }
                    }
                  }

                  .single-column-article &,
                  .two-column-article & {
                    padding-top: ${spacing32};
                    padding-bottom: ${spacing16};

                    &:before {
                      line-height: 1;
                      top: 10px;
                      transform: translate(-50%);
                    }

                    ${typedSlotCodeValue.desktop
                      .noExtendedPlaceholderOnArticle &&
                    css`
                      padding-top: 0;
                      padding-bottom: 0;

                      &:before {
                        line-height: unset;
                        top: 50%;
                        transform: translate(-50%, -50%);
                      }

                      &.next-adm-loaded {
                        &:before {
                          display: none;
                        }
                      }
                    `};
                  }

                  ${typedSlotCodeValue.desktop
                    .stretchToFullSingleColumnArticleWidth &&
                  css`
                    .single-column-article ${MainContentWrapper} & {
                      min-width: calc(
                        100% + 2 * ${desktopTotalSingleColumnSpacing}
                      );
                      margin-left: -${desktopTotalSingleColumnSpacing};
                    }
                  `};
                `)};
              }
            }
          `};

          ${typedSlotCodeValue.tablet &&
          css`
            ${AdWrapper} {
              &.slot-code-tablet-${slotCodeKey} {
                ${tabletCSS(css`
                  position: relative;
                  display: grid;
                  grid-template-columns: minmax(0, 1fr);
                  grid-template-rows: minmax(0, 1fr);
                  align-items: flex-start;
                  text-align: center;
                  background-color: ${grey200Color};
                  min-height: ${typedSlotCodeValue.tablet.height}px;
                  margin-left: -${tabletOuterPadding};
                  min-width: calc(100% + 2 * ${tabletOuterPadding});

                  > ${AdContainer} {
                    position: sticky;
                    top: ${headerOffsetFirstRowTablet}px;
                  }

                  &:before {
                    content: '${adPlaceholderTitle}';
                    ${bodycopyXXSmallBundledCSS};
                    color: ${grey800Color};
                    position: absolute;
                    left: 50%;
                  }

                  .section & {
                    &:before {
                      top: 50%;
                      transform: translate(-50%, -50%);
                    }

                    &.next-adm-loaded {
                      &:before {
                        display: none;
                      }
                    }

                    ${typedSlotCodeValue.tablet.noStretchOnSection &&
                    css`
                      min-width: 100%;
                      margin-left: 0;
                    `};
                  }

                  .single-column-article &,
                  .two-column-article & {
                    padding-top: ${spacing32};
                    padding-bottom: ${spacing16};

                    &:before {
                      line-height: 1;
                      top: 10px;
                      transform: translate(-50%);
                    }

                    ${typedSlotCodeValue.tablet
                      .noExtendedPlaceholderOnArticle &&
                    css`
                      padding-top: 0;
                      padding-bottom: 0;

                      &:before {
                        line-height: unset;
                        top: 50%;
                        transform: translate(-50%, -50%);
                      }

                      &.next-adm-loaded {
                        &:before {
                          display: none;
                        }
                      }
                    `};
                  }
                `)};
              }
            }
          `};

          ${typedSlotCodeValue.mobile &&
          css`
            ${AdWrapper} {
              &.slot-code-mobile-${slotCodeKey} {
                ${mobileCSS(css`
                  position: relative;
                  display: grid;
                  grid-template-columns: minmax(0, 1fr);
                  grid-template-rows: minmax(0, 1fr);
                  align-items: flex-start;
                  text-align: center;
                  background-color: ${grey200Color};
                  min-height: ${typedSlotCodeValue.mobile.height}px;
                  margin-left: -${mobileOuterPadding};
                  min-width: calc(100% + 2 * ${mobileOuterPadding});

                  > ${AdContainer} {
                    position: sticky;
                    top: ${headerOffsetFirstRowMobile}px;
                  }

                  &:before {
                    content: '${adPlaceholderTitle}';
                    ${bodycopyXXSmallBundledCSS};
                    color: ${grey800Color};
                    position: absolute;
                    left: 50%;
                  }

                  .section & {
                    &:before {
                      top: 50%;
                      transform: translate(-50%, -50%);
                    }

                    &.next-adm-loaded {
                      &:before {
                        display: none;
                      }
                    }

                    ${typedSlotCodeValue.mobile.noStretchOnSection &&
                    css`
                      min-width: 100%;
                      margin-left: 0;
                    `};
                  }

                  .single-column-article &,
                  .two-column-article & {
                    padding-top: ${spacing32};
                    padding-bottom: ${spacing16};

                    &:before {
                      line-height: 1;
                      top: 10px;
                      transform: translate(-50%);
                    }

                    ${typedSlotCodeValue.mobile
                      .noExtendedPlaceholderOnArticle &&
                    css`
                      padding-top: 0;
                      padding-bottom: 0;

                      &:before {
                        line-height: unset;
                        top: 50%;
                        transform: translate(-50%, -50%);
                      }

                      &.next-adm-loaded {
                        &:before {
                          display: none;
                        }
                      }
                    `};
                  }
                `)};
              }
            }
          `};
        `
      },
      css``
    )};
  `}
`

export {
  deepCopy,
  loadSlots,
  debouncedLoadSlots,
  getPageType,
  refreshSlot,
  showSlot,
  logAd,
  isValidAd,
  isLastHighPriorityPlacement,
  areAdSlotsLoaded,
  updateAreAdSlotsLoaded,
  globalAdStyles,
}
