import Head from 'next/head'
import { FunctionComponent } from 'react'
import { extractPageType } from '@utils/cook'
import usePageMetadata from '@hooks/usePageMetadata'

const PianoMetaTag: FunctionComponent = () => {
  const pageMetadata = usePageMetadata()
  const isArticle = extractPageType(pageMetadata) === 'article'

  // Piano recommendations crawler removes query parameters before crawling pages,
  // so the only way to detect if it's a preview page is to check the URL
  const regex = /article\d+\.html$/
  const isPreview = regex.test(pageMetadata.url)

  if (isPreview) {
    return (
      // This tag tells Piano's recommendations crawler to avoid crawling teaser preview and 404 pages.
      // We will not need this anymore when we fix the issue with the preview pages.
      <Head>
        <meta
          key="cXenseParse"
          name="cXenseParse:recs:recommendable"
          content="false"
        />
      </Head>
    )
  }

  if (isArticle) {
    const { teaserCatchword, teaserTitle, id } = pageMetadata
    return (
      // The following tags are used by Piano to display recommendations.
      // The only way to pass this data to Piano is through these nff-* tags.
      <Head>
        <meta
          key="cXenseParseCatchword"
          name="cXenseParse:nff-catchword"
          content={teaserCatchword || ''}
        />
        <meta
          key="cXenseParseTeaserTitle"
          name="cXenseParse:nff-teaserTitle"
          content={teaserTitle || ''}
        />
        <meta
          key="cXenseParseArticleId"
          name="cXenseParse:nff-articleId"
          content={id || ''}
        />
      </Head>
    )
  }
}

export default PianoMetaTag
