//* https://cdn.ringier-advertising.ch/doc/docs/tagman-api.html

import config from '@config'
import usePageMetadata from '@hooks/usePageMetadata'
import useRiAdTagManagerInit from '@hooks/useRiAdTagManagerInit'
import { getCurrentViewportType } from '@measures/responsive'
import {
  areAdSlotsLoaded,
  debouncedLoadSlots,
  deepCopy,
  getPageType,
  isLastHighPriorityPlacement,
  loadSlots,
  logAd,
  showSlot,
} from '@utils/ads'
import {
  FunctionComponent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import styled from 'styled-components'
import Wrapper from './Wrapper'
import { PlainAdEvents, PlainAdOptions } from './types'
import { registerSlot, updateADMClass } from './utils'
import { useQueryClient } from '@tanstack/react-query'
import { AdRingierProps } from '@widgets/AdRingier'

export interface Targeting {
  articleid?: string
  articleid_fr?: string
  subsectionid: string[]
  pagetype: 'article' | 'overview'
  syndication?: string
}

export interface AdProps extends AdRingierProps {
  onAdLoaded?: Required<PlainAdEvents>['adLoaded']
  onAdEmpty?: Required<PlainAdEvents>['adEmpty']
  onAdError?: Required<PlainAdEvents>['adError']
}

const {
  ads: {
    admeira: {
      sdk: { articleIdKeyName: adSDKArticleIdKeyName },
    },
    slotCodeMap,
  },
} = config

const StyledAdContainer = styled.div``

const Ad: FunctionComponent<AdProps> = ({
  placement,
  isAdDisabled,
  slotCodeDesktop: originalSlotCodeDesktop,
  slotCodeTablet: originalSlotCodeTablet,
  slotCodeMobile: originalSlotCodeMobile,
  containerId,
  autoLoadSlot,
  className,
  onAdLoaded,
  onAdEmpty,
  onAdError,
}) => {
  const slotCodeDesktop =
    slotCodeMap[originalSlotCodeDesktop as keyof typeof slotCodeMap] ??
    originalSlotCodeDesktop
  const slotCodeTablet =
    slotCodeMap[originalSlotCodeTablet as keyof typeof slotCodeMap] ??
    originalSlotCodeTablet
  const slotCodeMobile =
    slotCodeMap[originalSlotCodeMobile as keyof typeof slotCodeMap] ??
    originalSlotCodeMobile

  const wrapperRef = useRef<HTMLDivElement>(null)
  const queryClient = useQueryClient()
  const tagManagerInitCode = useRiAdTagManagerInit()
  const pageMetadata = usePageMetadata()
  const {
    syndication,
    targetContentType: contentType,
    type,
    id,
    sectionIds,
  } = pageMetadata

  const getCurrentSlot = useCallback(() => {
    const currentViewportType = getCurrentViewportType()
    switch (currentViewportType) {
      case 'desktop': {
        return slotCodeDesktop
      }

      case 'tablet': {
        return slotCodeTablet
      }

      case 'mobile': {
        return slotCodeMobile
      }

      default: {
        return ''
      }
    }
  }, [slotCodeDesktop, slotCodeTablet, slotCodeMobile])

  const onLoaded = useCallback<Required<PlainAdEvents>['adLoaded']>(
    (...args) => {
      updateADMClass('next-adm-loaded', wrapperRef)
      onAdLoaded?.(...args)
    },
    [onAdLoaded]
  )

  const onEmpty = useCallback<Required<PlainAdEvents>['adEmpty']>(
    (...args) => {
      updateADMClass('next-adm-empty', wrapperRef)
      onAdEmpty?.(...args)
    },
    [onAdEmpty]
  )

  const onError = useCallback<Required<PlainAdEvents>['adError']>(
    (...args) => {
      updateADMClass('next-adm-error', wrapperRef)
      onAdError?.(...args)
    },
    [onAdError]
  )

  const container = `ad-placement-${placement}${
    containerId ? `-${containerId}` : ''
  }`

  const registrationOptions = useMemo<Omit<PlainAdOptions, 'slot'>>(
    () => ({
      container,
      targeting: {
        placement,
        pagetype: getPageType(type),
        ...(id ? { [adSDKArticleIdKeyName]: id } : {}),
        ...(contentType ? { contenttype: contentType } : {}),
        ...(syndication ? { syndication } : {}),
        subsectionid: sectionIds,
      },
      events: {
        adLoaded: onLoaded,
        adEmpty: onEmpty,
        adError: onError,
      },
    }),
    [
      container,
      contentType,
      id,
      onEmpty,
      onError,
      onLoaded,
      placement,
      sectionIds,
      syndication,
      type,
    ]
  )

  useEffect(() => {
    const slot = getCurrentSlot()
    if (tagManagerInitCode && slot) {
      const deepCopiedRegistrationOptions = deepCopy(registrationOptions)

      registerSlot({ ...registrationOptions, slot })

      const singleLogAd = (loadingBehaviorDescription?: string) => {
        logAd(
          { queryClient },
          `Slot Registered: %c${slot}\n${' '.repeat(5)}%cplacement:${' '.repeat(7)}%c${placement}\n${' '.repeat(5)}%ccontainer:${' '.repeat(7)}%c${container}\n${' '.repeat(5)}%cloading state:${' '.repeat(3)}%c${loadingBehaviorDescription}\n${' '.repeat(5)}%cconfig:`,
          'font-weight: bold;',
          '',
          'font-weight: bold;',
          '',
          'font-weight: bold;',
          '',
          'font-weight: bold;',
          '',
          deepCopiedRegistrationOptions
        )
      }

      if (autoLoadSlot) {
        //! Some ads have to be loaded & shown manually,
        //! because they are part of galleries etc, where
        //! the ad is not shown until the user performs
        //! an action (e.g. sliding to the ad slide).
        loadSlots(queryClient)
        showSlot(container)
        singleLogAd(`loaded and shown manually because of "autoLoadSlot"`)
      } else if (!autoLoadSlot && areAdSlotsLoaded(queryClient)) {
        debouncedLoadSlots(queryClient)
        singleLogAd(`loaded manually, as batch loading has already happened`)
      } else if (!autoLoadSlot && isLastHighPriorityPlacement(placement)) {
        loadSlots(queryClient)
        singleLogAd(
          `loaded manually, as it is the last high priority placement in the page`
        )
        logAd(
          { queryClient },
          `%cLoadAds called\n${' '.repeat(5)}%cSlots that were registered until this point will now be batch loaded.\n${' '.repeat(5)}%cAds registered after this point in time will have to be loaded manually.`,
          'font-weight: bold; color: #FFD700;',
          '',
          'font-weight: bold; color: #FFA500;'
        )
      } else {
        singleLogAd(`not loaded yet, as batch loading is pending`)
      }
    }
  }, [
    autoLoadSlot,
    container,
    getCurrentSlot,
    placement,
    queryClient,
    registrationOptions,
    tagManagerInitCode,
  ])

  const unmountAd =
    isAdDisabled === true || (!getCurrentSlot() && tagManagerInitCode)

  useEffect(() => {
    if (unmountAd) {
      updateADMClass('', wrapperRef)
    }
  }, [unmountAd])

  const generateSlotCodeClassNames = Object.entries({
    'slot-code-desktop': slotCodeDesktop ?? '',
    'slot-code-tablet': slotCodeTablet ?? '',
    'slot-code-mobile': slotCodeMobile ?? '',
  }).reduce(
    (acc, [platformKey, platformValue]) =>
      platformValue ? `${acc} ${platformKey}-${platformValue}` : acc,
    ''
  )

  return (
    <Wrapper
      ref={wrapperRef}
      slotCodeDesktop={slotCodeDesktop}
      slotCodeTablet={slotCodeTablet}
      slotCodeMobile={slotCodeMobile}
      className={`${className ? `${className} ` : ''}${generateSlotCodeClassNames}`}>
      {unmountAd ? null : (
        <StyledAdContainer
          key={`${getCurrentSlot()}-${getCurrentViewportType()}`}
          id={container}
        />
      )}
    </Wrapper>
  )
}

const MemoizedAd = memo(Ad)

MemoizedAd.displayName = 'MemoizedAd'

export default MemoizedAd

export { StyledAdContainer }
