import { FunctionComponent, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { LinkCarouselProps } from '@widgets/LinkCarousel/types'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import PrimaryCTABlink from '@components/Buttons/PrimaryCTABlink'

export interface LinkCarouselLabelProps {
  label: LinkCarouselProps['label']
  link: LinkCarouselProps['link']
}

const StyledPrimaryCTABlink = styled(PrimaryCTABlink)`
  ${({
    theme: {
      spacing: { spacing16 },
      utils: { ellipsis },
    },
  }) => css`
    margin-top: ${spacing16};
    max-width: 100%;

    & > div {
      ${ellipsis('100%', 1)};
    }
  `}
`

const LinkCarouselLabel: FunctionComponent<LinkCarouselLabelProps> = ({
  label,
  link,
}) => {
  const onClickTrackingFn = useCallback<TrackingFnType>(
    () => ({
      event: 'linkcarousel_click',
      eventAction: 'click',
      eventCategory: 'linkcarousel_widget',
      eventLabel: label,
    }),
    [label]
  )

  const onClick = useTracking(onClickTrackingFn)

  return (
    <>
      <StyledPrimaryCTABlink
        colorVariant="green"
        size="large"
        iconName="arrow-right"
        onClick={onClick}
        {...link}>
        {label}
      </StyledPrimaryCTABlink>
    </>
  )
}

export default LinkCarouselLabel
