export type DeviceType = 'ios' | 'android'
const getDeviceType = (): DeviceType | null => {
  const userAgent = typeof navigator !== 'undefined' ? navigator.userAgent : ''

  if (userAgent.match(/iPhone|iPad|iPod/i)) {
    return 'ios'
  } else if (userAgent.match(/Android/i)) {
    return 'android'
  }
  return null
}

export { getDeviceType }
