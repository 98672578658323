import { QueryClient } from '@tanstack/react-query'
import config, { ConfigType } from '@config'
import { PageData } from '@hooks/usePageMetadata'

const {
  publication: { publication },
} = config

const getApiEnvironment: (
  queryClient: QueryClient
) => ConfigType['backend']['apiEnvironment'] = (queryClient) =>
  queryClient.getQueryData<ConfigType['backend']['apiEnvironment']>([
    'debug',
    'api',
    'environment',
  ]) as ConfigType['backend']['apiEnvironment']

const getApiEndpoint: (
  queryClient: QueryClient
) => ConfigType['backend']['versionedApiUrl'] = (queryClient) =>
  queryClient.getQueryData<ConfigType['backend']['versionedApiUrl']>([
    'debug',
    'api',
    'endpoint',
  ]) as ConfigType['backend']['versionedApiUrl']

const getPageFeed: (queryClient: QueryClient) => PageData = (queryClient) =>
  JSON.parse(
    JSON.stringify(queryClient.getQueryData<PageData>(['page']) as PageData)
  ) as unknown as PageData

const getSkeletonEndpoint: (
  queryClient: QueryClient
) => ConfigType['backend']['skeletonUrl'] = (queryClient) =>
  queryClient.getQueryData<ConfigType['backend']['skeletonUrl']>([
    'debug',
    'api',
    'skeletonUrl',
  ]) as ConfigType['backend']['skeletonUrl']

const getServerVersion: (queryClient: QueryClient) => string = (queryClient) =>
  queryClient.getQueryData<string>(['debug', 'server', 'version']) as string

const getClientVersion: (queryClient: QueryClient) => string = (queryClient) =>
  queryClient.getQueryData<string>(['debug', 'client', 'version']) as string

const generateHelpers = (queryClient: QueryClient) =>
  ({
    getNextJSPageFeed: () => getPageFeed(queryClient),
    getApiInfo: () => ({
      environment: getApiEnvironment(queryClient),
      endpoint: getApiEndpoint(queryClient),
    }),
    getFeedUrl: () => {
      const apiEnvironment = getApiEnvironment(queryClient)
      const apiEndpoint = getApiEndpoint(queryClient)

      let pathname = window.location.pathname

      if (publication === 'romandie' && apiEnvironment === 'dev') {
        pathname = pathname.replace('/fr/', '/')
      }

      return `${apiEndpoint}${pathname}` as const
    },
    getSkeletonUrl: () => getSkeletonEndpoint(queryClient),
    getAppVersion: () => ({
      server: getServerVersion(queryClient),
      client: getClientVersion(queryClient),
    }),
  }) as const

export type HelpersType = ReturnType<typeof generateHelpers>

export { generateHelpers }
