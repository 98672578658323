import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import translate from '@i18n'

import { Credit as CreditType } from '@utils/cook/types'

import Blink from '@components/Blink'
import { desktopCSS } from '@measures/responsive'

const Wrapper = styled.div<{ isFullScreen?: boolean }>`
  ${({
    theme: {
      typography: {
        bodycopy: {
          xxsmall: { bundledCSS: xxsmallBodycopyCSS },
        },
      },
      color: {
        primary: { primary02: primary02Color },
        tertiary: { grey800: grey800Color },
      },
      utils: { ellipsis },
    },
    isFullScreen,
  }) => css`
    ${xxsmallBodycopyCSS};
    color: ${isFullScreen ? primary02Color : grey800Color};

    ${ellipsis('100%', 1)}

    ${desktopCSS(css`
      ${isFullScreen &&
      css`
        color: ${grey800Color};
      `}
    `)}
  `}
`

const StyledBlink = styled(Blink)<{ isFullScreen?: boolean }>`
  ${({
    theme: {
      color: {
        primary: { primary02: primary02Color },
        tertiary: { grey800: grey800Color },
      },
    },
    isFullScreen,
  }) => css`
    color: ${isFullScreen ? primary02Color : grey800Color};

    ${desktopCSS(css`
      ${isFullScreen &&
      css`
        color: ${grey800Color};
      `}
    `)}
  `}
`

const Credit: FunctionComponent<CreditType & { isFullScreen?: boolean }> = ({
  isFullScreen,
  text,
  link,
}) => {
  if (!text) return null

  return (
    <Wrapper isFullScreen={isFullScreen}>
      {`${translate('gallery.photo')}: `}
      {link?.href ? (
        <StyledBlink {...link} isFullScreen={isFullScreen}>
          {text}
        </StyledBlink>
      ) : (
        text
      )}
    </Wrapper>
  )
}

export default Credit
