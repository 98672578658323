import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

interface BlinkingDotProps {
  className?: string
}

const StyledBlinkingDot = styled.div`
  ${({
    theme: {
      color: {
        primary: { primary02 },
      },
    },
  }) => css`
    animation: blink 2s infinite;
    display: inline-flex;
    align-items: center;
    vertical-align: top;

    @keyframes blink {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    &:after {
      content: '';
      width: 6px;
      height: 6px;
      background-color: ${primary02};
      border-radius: 50%;
      display: inline-block;
    }
  `}
`

const BlinkingDot: FunctionComponent<BlinkingDotProps> = ({ className }) => (
  <StyledBlinkingDot className={className} />
)

export default BlinkingDot
