import useTracking, { TrackingFnType } from '@hooks/useTracking'
import useViewportTracking from '@hooks/useViewportTracking'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent, useCallback } from 'react'
import styled from 'styled-components'

interface CountingPixelsProps {
  countingPixels: string[]
}

const Wrapper = styled.div`
  height: 0;
  overflow: hidden;
`

const targetClassName = 'counting-pixel-container'

const CountingPixels: FunctionComponent<CountingPixelsProps> = ({
  countingPixels,
}) => {
  const trackingOnImpression = useCallback<TrackingFnType>(
    () => ({
      event: 'countingpixel_impression',
      eventCategory: 'countingpixel',
      eventAction: 'impression',
      countingPixelUrls: countingPixels,
      countingPixelTargetDiv: targetClassName,
    }),
    [countingPixels]
  )

  const handleImpressionTrack = useTracking(trackingOnImpression)

  const viewportRef = useViewportTracking({
    onImpression: handleImpressionTrack,
    track: true,
  })

  if (!countingPixels) {
    return null
  }

  return <Wrapper ref={viewportRef} className={targetClassName} />
}

const widget = {
  kind: ['widget', 'counting-pixels'],
  component: CountingPixels,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
