const playerIntl = {
  de: {
    airplay: 'AirPlay',
    audioTracks: 'Audiospuren',
    auto: 'Auto',
    buffer: 'Ladevorgang',
    cast: 'Chromecast',
    cc: 'Untertitel',
    close: 'Schliessen',
    exitFullscreen: 'Vollbild beenden',
    fullscreen: 'Vollbild',
    hd: 'Qualität',
    liveBroadcast: 'Live',
    logo: 'Logo',
    mute: 'Stummschalten',
    next: 'Weiter',
    nextUp: 'Nächstes Video',
    notLive: 'Nicht Live',
    off: 'Aus',
    pause: 'Pause',
    pipIcon: 'Bild-in-Bild',
    play: 'Abspielen',
    playback: 'Abspielen',
    playbackRates: 'Abspielrate',
    player: 'Videoplayer',
    poweredBy: 'Unterstützt von',
    prev: 'Zurück',
    replay: 'Wiederholen',
    rewind: 'Vorheriges Video',
    settings: 'Einstellungen',
    slider: 'Positionsanzeige',
    stop: 'Stopp',
    unmute: 'Ton an',
    videoInfo: 'Über Dieses Video',
    volume: 'Lautstärke',
    volumeSlider: 'Lautstärkeregler',
    disabled: 'Deaktiviert',
    enabled: 'Aktiviert',
    reset: 'Zurücksetzen',

    shortcuts: {
      playPause: 'Abspielen/Pause',
      volumeToggle: 'Lautstärke an/aus',
      fullscreenToggle: 'Vollbild an/aus',
      seekPercent: 'Gehe zu %',
      keyboardShortcuts: 'Tastaturkürzel',
      increaseVolume: 'Lautstärke erhöhen',
      decreaseVolume: 'Lautstärke reduzieren',
      seekForward: 'Vorspringen',
      seekBackward: 'Zurückspringen',
      spacebar: 'Leertaste',
      captionsToggle: 'Untertitel an/aus',
    },

    advertising: {
      admessage: 'Diese Werbung endet in xx Sekunden',
      cuetext: 'Werbung',
      displayHeading: 'Werbung',
      loadingAd: 'Werbung lädt',
      podmessage: 'Werbung __AD_POD_CURRENT__ von __AD_POD_LENGTH__',
      skipmessage: 'Werbung überspringen\nin xx Sekunden',
      skiptext: 'Überspringen',
    },
  },

  fr: {
    airplay: 'AirPlay',
    audioTracks: 'Pistes audio',
    auto: 'Automatique',
    buffer: 'Chargement',
    cast: 'Chromecast',
    cc: 'Sous-titres',
    close: 'Fermer',
    exitFullscreen: 'Sortir du mode plein écran',
    fullscreen: 'Plein écran',
    hd: 'Qualité',
    liveBroadcast: 'Live',
    logo: 'Logo',
    mute: 'Désactiver le son',
    next: 'Suivant',
    nextUp: 'Vidéo suivante',
    notLive: 'Différé',
    off: 'Désactiver',
    pause: 'Pause',
    pipIcon: 'Lecteur réduit',
    play: 'Play',
    playback: 'Lecture',
    playbackRates: 'Vitesse de lecture',
    player: 'Video Player',
    poweredBy: 'Fourni par',
    prev: 'Précédent',
    replay: 'Replay',
    rewind: 'Vidéo précédente',
    settings: 'Paramètres',
    slider: 'Barre de lecture',
    stop: 'Stop',
    unmute: 'Activer le son',
    videoInfo: 'À propos de cette vidéo',
    volume: 'Volume',
    volumeSlider: 'Barre du volume',
    disabled: 'Désactivé',
    enabled: 'Activé',
    reset: 'Réinitialiser',

    shortcuts: {
      playPause: 'Play/Pause',
      volumeToggle: 'Activer/désactiver le son',
      fullscreenToggle: 'Activer/désactiver le mode plein écran',
      seekPercent: 'Avancer %',
      keyboardShortcuts: 'Raccourcis clavier',
      increaseVolume: 'Augmenter le volume',
      decreaseVolume: 'Diminuer le volume',
      seekForward: 'Avancer',
      seekBackward: 'Reculer',
      spacebar: 'Barre d’espace',
      captionsToggle: 'Activer/désactiver les sous-titres',
    },
    advertising: {
      admessage: 'La publicité prendra fin dans xx',
      cuetext: 'Publicité',
      displayHeading: 'Publicité',
      loadingAd: 'Chargement de la publicité',
      podmessage: 'Publicité __AD_POD_CURRENT__ sur __AD_POD_LENGTH__.',
      skipmessage: "Passer l'annonce dans xx",
      skiptext: "Passer l'annonce",
    },
  },
}

export default playerIntl
