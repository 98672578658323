import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { createHtmlPortalNode, OutPortal } from 'react-reverse-portal'
import styled, { css } from 'styled-components'
import { desktopCSS } from '@measures/responsive'

export interface StyledFullScreenImagePortalProps {
  isVisible: boolean
}

const StyledFullScreenImagePortal = styled.div<StyledFullScreenImagePortalProps>`
  ${({
    theme: {
      color: {
        primary: { primary01: primary01Color },
      },
    },
    isVisible,
  }) => css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 7000000;
    background-color: ${primary01Color};

    ${!isVisible &&
    css`
      display: none;
    `}

    > div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: grid;
      align-items: center;
      justify-items: center;
    }

    ${desktopCSS(css`
      background-color: rgba(0, 0, 0, 0.8);
    `)}
  `}
`

const FullScreenImagePortal: FunctionComponent = () => {
  const [nodeToRender, setNodeToRender] = useState<ReturnType<
    typeof createHtmlPortalNode
  > | null>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const queryClient = useQueryClient()
  const unsubscribeFnRef = useRef<() => void>()
  const queryCache = queryClient.getQueryCache()

  const updateNodeToRender = useCallback(
    (args: any) => {
      if (
        args?.action?.type === 'invalidate' &&
        args.query.queryKey[0] === 'full-screen-portal-rendered-node-ref'
      ) {
        setNodeToRender(
          queryClient.getQueryData<ReturnType<typeof createHtmlPortalNode>>([
            'full-screen-portal-rendered-node-ref',
          ]) ?? null
        )
      }
    },
    [queryClient]
  )

  useEffect(() => {
    unsubscribeFnRef.current = queryCache.subscribe(updateNodeToRender)

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [queryCache, updateNodeToRender])

  useEffect(() => {
    if (nodeToRender) {
      queryClient.setQueryData(['full-screen-image-shown'], true)
    } else {
      queryClient.setQueryData(['full-screen-image-shown'], false)
    }
    queryClient.invalidateQueries({
      queryKey: ['full-screen-image-shown'],
      exact: true,
    })
  }, [nodeToRender, queryClient])

  return (
    <StyledFullScreenImagePortal isVisible={!!nodeToRender} ref={wrapperRef}>
      {nodeToRender ? <OutPortal node={nodeToRender} /> : null}
    </StyledFullScreenImagePortal>
  )
}

export default FullScreenImagePortal
