import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import SvgIcon from '@components/SvgIcon'
import { SvgIconName } from '@components/SvgIcon/types'
import { ButtonProps } from './CommonCTA'

export type IconButtonProps = ButtonProps & {
  ariaLabel: string
  buttonSize?: number
  iconName: SvgIconName
  iconSize: number | string
  iconColor?: string
  className?: string
}

export type StyledIconButtonProps = Pick<IconButtonProps, 'buttonSize'>

const StyledIconButton = styled.button<StyledIconButtonProps>`
  ${({ buttonSize }) => css`
    width: ${buttonSize}px;
    height: ${buttonSize}px;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    line-height: 0;
  `}
`

const IconButton: FunctionComponent<IconButtonProps> = ({
  buttonSize = 48,
  type,
  ariaLabel,
  iconName,
  iconColor,
  iconSize,
  className,
  onClick,
}) => {
  return (
    <StyledIconButton
      onClick={onClick}
      aria-label={ariaLabel}
      buttonSize={buttonSize}
      className={className}
      type={type}>
      <SvgIcon iconName={iconName} color={iconColor} size={iconSize} />
    </StyledIconButton>
  )
}

export default IconButton
