import { FunctionComponent, useCallback, useMemo } from 'react'
import styled, { css } from 'styled-components'
import SvgIcon from '@components/SvgIcon'
import color from '@themes/default/color'
import { ScoreboardAPIProps } from '@widgets/Scoreboard/types'
import FreeRatioImage from '@components/FreeRatioImage'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import useViewportTracking from '@hooks/useViewportTracking'
import SecondaryCTABlink from '@components/Buttons/SecondaryCTABlink'

export type ScoreboardStreamingLinkProps = Required<
  Pick<ScoreboardAPIProps, 'streaming'>
>

const StyledLinkContainer = styled('div')`
  display: flex;
  justify-content: center;
`

const StyledSecondaryCTABlink = styled(SecondaryCTABlink)`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    margin: 0 ${spacing16} ${spacing16} ${spacing16};
  `}
`

const StyledTitle = styled('span')`
  ${({
    theme: {
      utils: { ellipsis },
    },
  }) => css`
    ${ellipsis('100%', 2)};
  `};
`

const StyledImageContainer = styled('span')`
  flex-shrink: 0;
`

const StyledArrow = styled(SvgIcon)`
  flex-shrink: 0;
`

const ScoreboardStreamingLink: FunctionComponent<
  ScoreboardStreamingLinkProps
> = ({ streaming: { logo, link, title } }) => {
  const onClickTrackingFn = useCallback<TrackingFnType>(
    () => ({
      element: 'streaming_partner',
      event: 'element_click',
      link_url: link?.href,
    }),
    [link?.href]
  )

  const onImpressionTrackingFn = useCallback<TrackingFnType>(
    () => ({
      element: 'streaming_partner',
      event: 'element_impression',
      link_url: link?.href,
    }),
    [link?.href]
  )

  const onClick = useTracking(onClickTrackingFn)
  const onImpression = useTracking(onImpressionTrackingFn)

  const viewportRef = useViewportTracking({
    onImpression,
    track: true,
  })

  const { src, alt, height, width, crops } = logo

  const constraints = useMemo(() => {
    const constraint = { width: 150, height: 22 }

    return {
      desktop: constraint,
      tablet: constraint,
      mobile: constraint,
      mobileSmall: constraint,
    }
  }, [])

  return (
    <StyledLinkContainer ref={viewportRef}>
      <StyledSecondaryCTABlink
        colorVariant="black"
        size="small"
        {...link}
        onClick={onClick}>
        <StyledTitle>{title}</StyledTitle>
        <StyledImageContainer>
          <FreeRatioImage
            src={src}
            alt={alt}
            originalDimensions={{ width, height }}
            constraints={constraints}
            crops={crops ?? {}}
          />
        </StyledImageContainer>
        <StyledArrow
          color={color.primary.primary01}
          iconName="chevron-right"
          size={20}
        />
      </StyledSecondaryCTABlink>
    </StyledLinkContainer>
  )
}

export default ScoreboardStreamingLink
