import { FunctionComponent, useCallback } from 'react'
import { CommentingActionPopupProps } from './types'
import CommentingActionsPopupForm from './CommentingActionPopupForm'
import translate from '@i18n'
import useUser from '@hooks/useUser'
import styled, { css } from 'styled-components'
import AnswerIndicator from './AnswerIndicator'
import { getTitle } from './utils'
import { stripHtml } from '@hooks/useTracking/utils'
import useTracking, { TrackingFnType } from '@hooks/useTracking'

interface ActionPopupWrapperProps {
  isEmbed: boolean
  isAnswer: boolean
}

const answerStyle = css`
  ${({
    theme: {
      spacing: { spacing20 },
    },
  }) => {
    return css`
      position: relative;
      box-sizing: border-box;
      padding-left: ${spacing20};
    `
  }}
`

const ActionPopupWrapper = styled.div<ActionPopupWrapperProps>`
  ${({
    isAnswer,
    isEmbed,
    theme: {
      spacing: { spacing24 },
    },
  }) => {
    return css`
      margin-top: ${spacing24};
      ${isAnswer && answerStyle};
      ${isEmbed &&
      css`
        margin-top: 0;
      `}
      ${isEmbed &&
      isAnswer &&
      css`
        padding-left: 0;
      `}
    `
  }}
`

const ActionPopupContent = styled.div`
  ${({
    theme: {
      spacing: { spacing16, spacing4 },
      color: {
        tertiary: { grey400 },
      },
    },
  }) => {
    return css`
      box-sizing: border-box;
      padding: ${spacing16};
      border: 1px solid ${grey400};
      border-radius: ${spacing4};
    `
  }}
`

const ActionPopupTitle = styled.div`
  ${({
    theme: {
      spacing: { spacing8 },
      color: {
        tertiary: { grey400 },
      },
      typography: {
        headings: {
          xxsmall: { bundledCSS: headingXXSmallCSS },
        },
      },
    },
  }) => {
    return css`
      padding-bottom: ${spacing8};
      border-bottom: 1px solid ${grey400};
      ${headingXXSmallCSS};
    `
  }}
`

const CommentingActionPopup: FunctionComponent<CommentingActionPopupProps> = ({
  onConfirm,
  onCancel,
  type,
  actionType,
  initialText = '',
}) => {
  const userData = useUser()

  const isEmbed = actionType === 'delete'

  const articleAddCommentHandler = useCallback<TrackingFnType>(
    ({ pageMetadata }) => ({
      event:
        type === 'comment'
          ? 'article_add_comment'
          : 'article_add_comment_answer',
      eventCategory: 'article',
      eventLabel: `${pageMetadata.id}:${stripHtml(pageMetadata.title)}`,
      eventAction: type === 'comment' ? 'add_comment' : 'add_comment_answer',
    }),
    [type]
  )

  const handleArticleAddCommentTracking = useTracking(articleAddCommentHandler)

  const handleConfirm = (textValue = '') => {
    handleArticleAddCommentTracking()
    onConfirm(textValue)
  }

  return (
    <ActionPopupWrapper isAnswer={type === 'answer'} isEmbed={isEmbed}>
      {!isEmbed && type === 'answer' && <AnswerIndicator />}
      <ActionPopupContent>
        <ActionPopupTitle>
          {actionType === 'delete' &&
            translate('commenting.deleteConfirmation')}
          {actionType === 'report' && translate('commenting.reportTitle')}
          {actionType === 'reply' &&
            getTitle({
              name: `${userData?.first_name} ${userData?.last_name}`,
            })}
        </ActionPopupTitle>
        <CommentingActionsPopupForm
          actionType={actionType}
          onConfirm={handleConfirm}
          onCancel={onCancel}
          textInput={initialText}
        />
      </ActionPopupContent>
    </ActionPopupWrapper>
  )
}

export default CommentingActionPopup
