import config from '@config'
import { BlickBitesData } from '@widgets/Video/BlickBites'

export type FetchBlickBites = (options?: {
  id?: string
  bl?: string
  size?: number
  before?: number
  pub?: string
}) => Promise<BlickBitesData>

const base64Options = ['bl']
const base64Encode = (value: string) => btoa(value)

const {
  publication: { publication },
  blickBites: { blickBitesApiUrl, urlHashParam },
} = config

const isInServerBundle = typeof window === 'undefined'

const fetchBlickBites: FetchBlickBites = async (options = {}) => {
  const requestUrl = new URL(`${blickBitesApiUrl}`)
  const defaultOptions = { pub: publication }

  Object.entries({ ...defaultOptions, ...options }).forEach(([key, value]) => {
    if (value !== undefined) {
      requestUrl.searchParams.append(
        key,
        base64Options.includes(key) ? base64Encode(`${value}`) : `${value}`
      )
    }
  })

  try {
    return (
      await fetch(
        requestUrl,
        isInServerBundle ? { headers: { 'User-Agent': 'next' } } : {}
      )
    ).json()
  } catch (err) {
    console.error('err fetching blick bites', err)
    // TODO: handle error state
  }
}

const getBiteIdFromHash = (path: string): string => {
  if (path.includes('#')) {
    const [, hash] = path.split('#')
    const params = new URLSearchParams(hash)
    return params.get(urlHashParam) ?? ''
  }

  return ''
}

const hashWithoutBiteId = (hash: string): string => {
  const params = new URLSearchParams(hash)
  params.delete(urlHashParam)
  return params.toString()
}

const hashWithBiteId = (hash: string, bid: string): string => {
  const cleanedUpHash = hashWithoutBiteId(hash)
  const params = new URLSearchParams(cleanedUpHash)
  params.set(urlHashParam, bid)
  return params.toString()
}

const pathWithBiteId = (path: string, bid: string): string => {
  if (path.includes('#')) {
    const [beforeHash, hash] = path.split('#')
    return `${beforeHash}#${hashWithBiteId(hash, bid)}`
  } else {
    return `${path}#${hashWithBiteId('', bid)}`
  }
}

const pathWithoutBiteId = (path: string): string => {
  if (path.includes('#')) {
    const [beforeHash, hash] = path.split('#')
    const cleanedUpHash = hashWithoutBiteId(hash)
    return `${beforeHash}${cleanedUpHash ? `#${cleanedUpHash}` : cleanedUpHash}`
  } else {
    return path
  }
}

export { fetchBlickBites, getBiteIdFromHash, pathWithBiteId, pathWithoutBiteId }
