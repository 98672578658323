import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'

const StyledCaptionCreditWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing4 },
    },
  }) => css`
    margin-top: ${spacing4};
    line-height: 1;
  `}
`

const CaptionCreditWrapper: FunctionComponent<{ children?: ReactNode }> = ({
  children,
}) => {
  return <StyledCaptionCreditWrapper>{children}</StyledCaptionCreditWrapper>
}

export default CaptionCreditWrapper
