import config from '@config'
import { breakpoints } from '@measures/responsive'
import {
  GeneratePictureSourcesProps,
  GeneratedPictureSources,
  HandleCropsProps,
  StringifiedCrops,
} from './types'

const sourceMediaQueryDesktop = breakpoints.desktop
const sourceMediaQueryTablet = breakpoints.tablet
const sourceMediaQueryMobileRegular = breakpoints.regularMobile
const sourceMediaQueryMobileSmall = breakpoints.smallMobile

const {
  backend: { imageUrl: backendImageUrl },
} = config

const handleCrops = ({
  desktop,
  tablet,
  mobile,
}: HandleCropsProps): StringifiedCrops => ({
  desktop: desktop
    ? `&x=${desktop.x}&y=${desktop.y}&width=${desktop.width}&height=${desktop.height}`
    : '',
  tablet: tablet
    ? `&x=${tablet.x}&y=${tablet.y}&width=${tablet.width}&height=${tablet.height}`
    : '',
  mobile: mobile
    ? `&x=${mobile.x}&y=${mobile.y}&width=${mobile.width}&height=${mobile.height}`
    : '',
})

const generatePictureSources = ({
  src,
  ratios,
  crops,
  widths,
}: GeneratePictureSourcesProps): GeneratedPictureSources => {
  const baseImageUrl = `${backendImageUrl}${src}`

  const {
    desktop: ratioDesktop,
    tablet: ratioTablet,
    mobile: ratioMobile,
  } = ratios

  const cropDesktop = crops[ratioDesktop]
  const cropTablet = crops[ratioTablet]
  const cropMobile = crops[ratioMobile]

  const {
    desktop: stringifiedCropDesktop,
    tablet: stringifiedCropTablet,
    mobile: stringifiedCropMobile,
  } = handleCrops({
    desktop: cropDesktop,
    tablet: cropTablet,
    mobile: cropMobile,
  })

  const {
    desktop: imageWidthDesktop,
    tablet: imageWidthTablet,
    mobile: imageWidthMobile,
    mobileSmall: imageWidthMobileSmall = imageWidthMobile,
  } = widths

  return {
    desktop: {
      mediaQuery: sourceMediaQueryDesktop,
      dpr1: `${baseImageUrl}?imdensity=1&ratio=${ratioDesktop}${stringifiedCropDesktop}&imwidth=${imageWidthDesktop}`,
      dpr2: `${baseImageUrl}?imdensity=2&ratio=${ratioDesktop}${stringifiedCropDesktop}&imwidth=${imageWidthDesktop}`,
      dpr3: `${baseImageUrl}?imdensity=3&ratio=${ratioDesktop}${stringifiedCropDesktop}&imwidth=${imageWidthDesktop}`,
    },
    tablet: {
      mediaQuery: sourceMediaQueryTablet,
      dpr1: `${baseImageUrl}?imdensity=1&ratio=${ratioTablet}${stringifiedCropTablet}&imwidth=${imageWidthTablet}`,
      dpr2: `${baseImageUrl}?imdensity=2&ratio=${ratioTablet}${stringifiedCropTablet}&imwidth=${imageWidthTablet}`,
      dpr3: `${baseImageUrl}?imdensity=3&ratio=${ratioTablet}${stringifiedCropTablet}&imwidth=${imageWidthTablet}`,
    },
    mobileRegular: {
      mediaQuery: sourceMediaQueryMobileRegular,
      dpr1: `${baseImageUrl}?imdensity=1&ratio=${ratioMobile}${stringifiedCropMobile}&imwidth=${imageWidthMobile}`,
      dpr2: `${baseImageUrl}?imdensity=2&ratio=${ratioMobile}${stringifiedCropMobile}&imwidth=${imageWidthMobile}`,
      dpr3: `${baseImageUrl}?imdensity=3&ratio=${ratioMobile}${stringifiedCropMobile}&imwidth=${imageWidthMobile}`,
    },
    mobileSmall: {
      mediaQuery: sourceMediaQueryMobileSmall,
      dpr1: `${baseImageUrl}?imdensity=1&ratio=${ratioMobile}${stringifiedCropMobile}&imwidth=${imageWidthMobileSmall}`,
      dpr2: `${baseImageUrl}?imdensity=2&ratio=${ratioMobile}${stringifiedCropMobile}&imwidth=${imageWidthMobileSmall}`,
      dpr3: `${baseImageUrl}?imdensity=3&ratio=${ratioMobile}${stringifiedCropMobile}&imwidth=${imageWidthMobileSmall}`,
    },
  }
}

export { generatePictureSources }
