import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent } from 'react'
import Tag from '@components/Tags/Item'
import Tags from '@components/Tags'

interface TagsWidgetProps {
  tags?: string[]
}

type TagsType = string[]

const isValidTags = (tags: any): tags is TagsType => tags?.length > 0

const TagsWidget: FunctionComponent<TagsWidgetProps> = (props) => {
  const { tags } = props

  if (!isValidTags(tags)) {
    return null
  }

  return (
    <Tags>
      {tags.map((tag, index) => (
        <Tag key={index} text={tag} />
      ))}
    </Tags>
  )
}

const widget = {
  kind: ['widget', 'tags', 'recipe'],
  component: TagsWidget,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
