import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { desktopCSS } from '@measures/responsive'
import useClientViewportType from '@hooks/useClientViewportType'

export interface RelatedItemsWrapperMobileProps {
  children: ReactNode
}

const StyledRelatedItemsWrapperMobile = styled.div`
  ${({
    theme: {
      spacing: { spacing16, spacing24 },
    },
  }) => css`
    margin-top: ${spacing24};
    display: grid;
    width: 100%;
    grid-template-columns: minmax(0, 1fr);
    grid-row-gap: ${spacing16};
    ${desktopCSS(css`
      display: none;
    `)};
  `}
`

const RelatedItemsWrapperMobile: FunctionComponent<
  RelatedItemsWrapperMobileProps
> = ({ children }) =>
  useClientViewportType() === 'desktop' ? null : (
    <StyledRelatedItemsWrapperMobile>
      {children}
    </StyledRelatedItemsWrapperMobile>
  )

export default RelatedItemsWrapperMobile
