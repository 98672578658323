import { ScoreboardProps } from '@widgets/Scoreboard/types'
import useSportsEvent, {
  UseSportsEventPropsHockey,
  UseSportsEventPropsSoccer,
  UseSportsEventPropsSki,
  UseSportsEventPropsFormula1,
  UseSportsEventPropsTennis,
} from '@hooks/useSportsEvent'
import ScoreboardHockeyOrSoccer from '@components/Scoreboard/ScoreboardHockeyOrSoccer'
import ScoreboardSkiOrFormula1 from '@components/Scoreboard/ScoreboardSkiOrFormula1'
import ScoreboardTennis from '@components/Scoreboard/ScoreboardTennis'
import { createPlaceholderData } from '@widgets/Scoreboard/utils'
import { FunctionComponent, useEffect, useMemo } from 'react'
import ScoreboardMainSection from './ScoreboardMainSection'
import ScoreboardContext from '@contexts/scoreboard'
import { LIVE_EVENT_DATA_SPORT_TYPES } from '@utils/formatters/common'
import ScoreboardStreamingLink from '@components/Scoreboard/ScoreboardStreamingLink'

const { SOCCER, HOCKEY, SKI, FORMULA1, TENNIS } = LIVE_EVENT_DATA_SPORT_TYPES

const Scoreboard: FunctionComponent<ScoreboardProps> = (props) => {
  const { matchId, typeOfSport, streaming, updateDisplaySportsPartner } = props

  const placeholderData = useMemo(
    () => createPlaceholderData(typeOfSport),
    [typeOfSport]
  )

  const { data: sportsEventData } = useSportsEvent<
    | UseSportsEventPropsHockey
    | UseSportsEventPropsSoccer
    | UseSportsEventPropsSki
    | UseSportsEventPropsFormula1
    | UseSportsEventPropsTennis
  >({
    matchId,
    typeOfSport,
    shouldRefetch: true,
    placeholderData,
  })

  useEffect(() => {
    if (
      !sportsEventData?.isPlaceholderData &&
      sportsEventData?.formattedData?.isFinished !== undefined
    ) {
      updateDisplaySportsPartner(sportsEventData?.formattedData?.isFinished)
    }
  }, [
    sportsEventData?.formattedData?.isFinished,
    sportsEventData?.isPlaceholderData,
    updateDisplaySportsPartner,
  ])

  return (
    <ScoreboardContext.Provider value={sportsEventData ?? placeholderData}>
      <ScoreboardMainSection>
        {([HOCKEY, SOCCER] as ScoreboardProps['typeOfSport'][]).includes(
          typeOfSport
        ) && <ScoreboardHockeyOrSoccer />}
        {([FORMULA1, SKI] as ScoreboardProps['typeOfSport'][]).includes(
          typeOfSport
        ) && <ScoreboardSkiOrFormula1 />}
        {(TENNIS as ScoreboardProps['typeOfSport']) === typeOfSport && (
          <ScoreboardTennis />
        )}
      </ScoreboardMainSection>
      {!!streaming &&
        !sportsEventData?.isPlaceholderData &&
        !sportsEventData?.formattedData?.isFinished && (
          <ScoreboardStreamingLink streaming={streaming} />
        )}
    </ScoreboardContext.Provider>
  )
}

export default Scoreboard
