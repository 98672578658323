import Ad from '@components/Ad'
import { isValidAd } from '@utils/ads'
import { FunctionComponent } from 'react'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'

export interface AdRingierProps {
  placement: string
  isAdDisabled?: boolean
  slotCodeDesktop?: string
  slotCodeTablet?: string
  slotCodeMobile?: string
  containerId?: string
  autoLoadSlot?: boolean
  className?: string
}

const AdRingierWidget: FunctionComponent<AdRingierProps> = (props) =>
  props.isAdDisabled !== true && isValidAd(props) ? <Ad {...props} /> : null

const widget = {
  kind: ['ad-ringier'],
  component: AdRingierWidget,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
