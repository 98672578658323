import Blink from '@components/Blink'
import useCurrentSection from '@hooks/useCurrentSection'
import { MainMenuItem } from '@hooks/useNavigation'
import usePageMetadata from '@hooks/usePageMetadata'
import { FunctionComponent, useEffect, useState } from 'react'
import styled, { css, useTheme } from 'styled-components'
import SecondLevelMenu from './SecondLevelMenu'
import useTrackingUtils from '../../useTrackingUtils'
import ArrowButton from '@components/Header/FirstRow/BurgerMenu/ArrowButton'

const ItemWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing20, spacing16 },
      color: {
        tertiary: { grey300 },
      },
    },
  }) => css`
    height: 48px;
    padding: ${spacing16} ${spacing20} ${spacing16} ${spacing20};
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    border-top: 1px solid ${grey300};
  `}
`

interface StyledBlinkProps {
  isActive: boolean
  sectionColor: string
}

const StyledBlink = styled(Blink)<StyledBlinkProps>`
  ${({
    theme: {
      color: {
        primary: { primary01, blickRed },
      },
      typography: {
        headings: {
          small: { bundledCSS: smallHeadingCSS },
        },
      },
    },
    isActive,
  }) => css`
    color: ${isActive ? blickRed : primary01};
    text-decoration: none;
    flex: 1 auto;
    ${smallHeadingCSS}
  `}
`

const StyledItem = styled.button<StyledBlinkProps>`
  ${({
    theme: {
      color: {
        primary: { blickRed, primary01 },
      },
      typography: {
        headings: {
          small: { bundledCSS: smallHeadingCSS },
        },
      },
    },
    isActive,
  }) => css`
    color: ${isActive ? blickRed : primary01};
    background-color: transparent;
    border: 0;
    cursor: pointer;
    padding: 0;
    text-align: left;
    flex-grow: 1;
    ${smallHeadingCSS}
  `}
`

const ItemAndSubmenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const FirstLevelItem: FunctionComponent<MainMenuItem> = ({
  title,
  primaryColor,
  link,
  content,
}) => {
  const { isActive } = useCurrentSection(link.title || '')
  const theme = useTheme()
  const [isSubmenuVisible, setIsSubMenuVisible] = useState(isActive)
  const [mountChildren, setMountChildren] = useState(false)
  const pageMetadata = usePageMetadata()

  const { burgerMenuClickTrackingFn } = useTrackingUtils({
    title,
    url: link.href,
  })

  useEffect(() => {
    if (isSubmenuVisible) {
      setMountChildren(true)
    }
  }, [isSubmenuVisible])

  useEffect(() => {
    setIsSubMenuVisible(isActive)
  }, [isActive, pageMetadata])

  const toggleSubmenu = () => setIsSubMenuVisible((prevValue) => !prevValue)

  return (
    <ItemAndSubmenuWrapper>
      <ItemWrapper>
        {!link.href ? (
          <StyledItem
            onClick={toggleSubmenu}
            aria-label={title}
            isActive={isSubmenuVisible}
            sectionColor={primaryColor}>
            {title}
          </StyledItem>
        ) : (
          <StyledBlink
            {...link}
            isActive={isSubmenuVisible}
            sectionColor={primaryColor}
            onClick={burgerMenuClickTrackingFn}>
            {title}
          </StyledBlink>
        )}
        {content?.length && (
          <ArrowButton
            isExpanded={isSubmenuVisible}
            iconColor={theme.color.primary.primary01}
            onClick={toggleSubmenu}
          />
        )}
      </ItemWrapper>
      {mountChildren && (
        <SecondLevelMenu isVisible={isSubmenuVisible}>
          {content}
        </SecondLevelMenu>
      )}
    </ItemAndSubmenuWrapper>
  )
}

export default FirstLevelItem
