import usePageMetadata from '@hooks/usePageMetadata'
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled, { css, useTheme } from 'styled-components'
import SocialButton from '@components/Social/Button'
import SubscribedPopover from './SubscribedPopover'
import NotSubscribedPopover from './NotSubscribedPopover'
import useSubscriptionStatus from '@hooks/useSubscriptionStatus'
import Popover from '@components/Popover'
import debounce from 'lodash.debounce'
import IconButton from '@components/Buttons/IconButton'

const Container = styled.div`
  position: relative;
`

const IconButtonWrapper = styled(IconButton)`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    position: absolute;
    top: ${spacing16};
    right: ${spacing16};
  `}
`

const TooltipWrapper = styled(Popover)`
  ${({
    theme: {
      spacing: { spacing16 },
    },
    position,
  }) => css`
    align-items: start;
    display: flex;
    flex-direction: row;
    gap: ${spacing16};
    justify-content: space-between;
    padding: ${spacing16};
    text-align: start;

    ${position === 'top' &&
    css`
      bottom: ${spacing16};
      left: -36px;
      right: initial;

      &:after {
        left: 8px;
      }
    `}

    ${position === 'bottom' &&
    css`
      bottom: initial;
      left: -36px;
      right: initial;
      top: 18px;

      &:after {
        left: 8px;
      }
    `}
  `}
`

const InnerWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 148px;
  gap: 24px;
`

const Button: FunctionComponent = () => {
  const { isPlus } = usePageMetadata()
  const wrapperRef = useRef<HTMLDivElement>(null)
  const { subscriptionStatus } = useSubscriptionStatus()
  const theme = useTheme()
  const [tooltipPosition, setTooltipPosition] = useState<'top' | 'bottom'>(
    'bottom'
  )
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)
  const togglePopover = useCallback(() => {
    setIsPopoverOpen((prevValue) => !prevValue)
  }, [])

  useEffect(() => {
    const percentage = 55
    const handleScroll = () => {
      if (wrapperRef.current && tooltipPosition) {
        const { top } = wrapperRef.current.getBoundingClientRect()
        const windowHeight = window.innerHeight
        const calculatedItemPosition = windowHeight - Math.floor(top)
        const reachedPercentage = Math.floor(
          (calculatedItemPosition / windowHeight) * 100
        )
        if (
          reachedPercentage < percentage &&
          tooltipPosition === 'bottom' &&
          calculatedItemPosition < 300
        ) {
          setTooltipPosition('top')
        } else if (
          reachedPercentage > percentage &&
          tooltipPosition === 'top'
        ) {
          setTooltipPosition('bottom')
        }
      }
    }
    handleScroll()
    const debouncedScrollChange = debounce(handleScroll, 60)
    window.addEventListener('scroll', debouncedScrollChange)

    return () => {
      window.removeEventListener('scroll', debouncedScrollChange)
    }
  }, [tooltipPosition])

  if (!isPlus) {
    return null
  }

  return (
    <Container ref={wrapperRef}>
      <SocialButton
        onClick={togglePopover}
        type="pluslinkshare"
        isActive={isPopoverOpen}
      />
      {isPopoverOpen && (
        <TooltipWrapper
          position={tooltipPosition}
          trackClickOutside={true}
          wrapperRef={wrapperRef}
          onClickOutside={togglePopover}>
          <>
            <InnerWrapper>
              {subscriptionStatus === 'subscribed' ? (
                <SubscribedPopover />
              ) : (
                <NotSubscribedPopover toogleTooltip={togglePopover} />
              )}
            </InnerWrapper>
            <IconButtonWrapper
              ariaLabel="Close"
              buttonSize={24}
              onClick={togglePopover}
              iconName="xmark-large"
              iconSize={16}
              iconColor={theme.color.primary.primary01}
            />
          </>
        </TooltipWrapper>
      )}
    </Container>
  )
}

export default Button
