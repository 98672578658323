import usePageMetadata from '@hooks/usePageMetadata'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { fetchPlaylist } from '@utils/playlist'
import { getURLParam } from '@utils/urlParams'
import { PlaylistData } from '@widgets/Video/playlist/types'

export type UsePlaylist = () => PlaylistData | undefined

const usePlaylist = () => {
  const queryClient = useQueryClient()
  const pageMetadata = usePageMetadata()
  const playlistSrc = pageMetadata?.playlistSrc ?? ''
  return useQuery({
    queryKey: ['playlist-videos', playlistSrc],
    queryFn: () =>
      fetchPlaylist({
        playlistSrc,
        token: getURLParam('token', queryClient) ?? '',
      }),
    enabled: !!playlistSrc,
  }).data
}

export default usePlaylist
