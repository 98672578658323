import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { printCSS } from '@utils/style'

const TagsList = styled.div`
  ${({
    theme: {
      spacing: { spacing12, spacing24, spacing16 },
    },
  }) => css`
    flex-wrap: wrap;
    display: inline-flex;
    gap: ${spacing12};
    justify-content: center;
    justify-self: center;
    width: 100%;
    margin-top: ${spacing24};
    ${printCSS(css`
      margin-top: ${spacing16};
    `)}
  `}
`

const Tags: FunctionComponent<{ children?: ReactNode }> = ({ children }) => {
  return <TagsList>{children}</TagsList>
}

export default Tags
