import { useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'

import {
  JWPlayer,
  BlickTVInputProps,
  OverlayEventParam,
} from '@components/Video/VideoPlayer/JwLibrary/types'
import { logVideoPlayer } from '@components/Video/VideoPlayer/JwLibrary/utils/logger'

import {
  useOnProgressEvent,
  useQuartileEvents,
  usePlayerEvent,
  useOnPipEvent,
} from './utils'

type UseVideoPlayerEventsType = (
  options: {
    player?: JWPlayer
    onAll?: (event: string) => void
    onError?: (error: jwplayer.ErrorParam) => void
    onSetupError?: (error: jwplayer.ErrorParam) => void
  } & Pick<
    BlickTVInputProps,
    | 'onAdPlay'
    | 'onAdRequest'
    | 'onAdStarted'
    | 'onAdSkipped'
    | 'onAdEnded'
    | 'onVolumeChange'
    | 'onMute'
    | 'onUnmute'
    | 'onPlaySuccess'
    | 'onPlay'
    | 'onSeek'
    | 'onProgress'
    | 'onEnd'
    | 'onEnterOverlay'
    | 'onExitOverlay'
    | 'onRecommendationsOverlay'
    | 'onQuartileCompletion'
    | 'onCast'
    | 'onPip'
  >
) => void

const useVideoPlayerEvents: UseVideoPlayerEventsType = ({
  player,
  onAll,
  onAdPlay,
  onAdRequest,
  onAdStarted,
  onAdSkipped,
  onAdEnded,
  onVolumeChange,
  onMute,
  onUnmute,
  onPlaySuccess,
  onPlay,
  onSeek,
  onProgress,
  onEnd,
  onEnterOverlay,
  onExitOverlay,
  onQuartileCompletion,
  onPip,
  onError,
  onSetupError,
  onCast,
}) => {
  const queryClient = useQueryClient()

  const onAdErrorHandler = useCallback(
    (adError: jwplayer.AdErrorParam) => {
      logVideoPlayer(
        { queryClient, method: 'error' },
        'Failed to play ad!!!',
        adError
      )
    },
    [queryClient]
  )

  const onVolumeHandler = useCallback(
    (param: jwplayer.VolumeParam) => {
      if (player) {
        onVolumeChange?.({ volume: param.volume, muted: player.getMute() })
      }
    },
    [onVolumeChange, player]
  )

  const onMuteHandler = useCallback(
    (muteValue: jwplayer.MuteParam) => {
      if (muteValue.mute) {
        onMute?.()
      } else {
        onUnmute?.()
      }
    },
    [onMute, onUnmute]
  )

  const onOverlayHandler = useCallback(
    (param: OverlayEventParam) => {
      if (param.overlay) {
        onEnterOverlay?.()
      } else {
        onExitOverlay?.()
      }
    },
    [onEnterOverlay, onExitOverlay]
  )

  const onFullscreenHandler = useCallback(
    (param: jwplayer.FullscreenParam) => {
      if (param.fullscreen) {
        onEnterOverlay?.()
      } else {
        onExitOverlay?.()
      }
    },
    [onEnterOverlay, onExitOverlay]
  )

  const onCastHandler = useCallback(
    ({ active }: jwplayer.CastParam) => {
      onCast?.(active)
    },
    [onCast]
  )

  usePlayerEvent({ player, event: 'all', listener: onAll })

  useQuartileEvents({ player, onQuartileCompletion })
  useOnProgressEvent({ player, onProgress })
  useOnPipEvent({ player, onPip })

  /** AD events */
  usePlayerEvent({ player, event: 'adPlay', listener: onAdPlay })
  usePlayerEvent({ player, event: 'adRequest', listener: onAdRequest })
  usePlayerEvent({ player, event: 'adImpression', listener: onAdStarted })
  usePlayerEvent({ player, event: 'adSkipped', listener: onAdSkipped })
  usePlayerEvent({ player, event: 'adComplete', listener: onAdEnded })
  usePlayerEvent({ player, event: 'adError', listener: onAdErrorHandler })

  usePlayerEvent({ player, event: 'volume', listener: onVolumeHandler })
  usePlayerEvent({ player, event: 'mute', listener: onMuteHandler })
  usePlayerEvent({ player, event: 'firstFrame', listener: onPlaySuccess })
  usePlayerEvent({ player, event: 'play', listener: onPlay })
  usePlayerEvent({ player, event: 'seek', listener: onSeek })
  usePlayerEvent({ player, event: 'complete', listener: onEnd })
  usePlayerEvent({ player, event: 'overlay', listener: onOverlayHandler })
  usePlayerEvent({ player, event: 'fullscreen', listener: onFullscreenHandler })

  /** Error events */
  usePlayerEvent({ player, event: 'error', listener: onError })
  usePlayerEvent({ player, event: 'setupError', listener: onSetupError })

  /** Cast event */
  usePlayerEvent({ player, event: 'cast', listener: onCastHandler })
}

export default useVideoPlayerEvents
