import { FunctionComponent, useCallback } from 'react'
import styled, { css } from 'styled-components'
import Blink from './Blink'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import useViewportTracking from '@hooks/useViewportTracking'
import FreeRatioImage from './FreeRatioImage'
import { Image } from '@utils/cook/types'
import { ImageConstraints } from './FreeRatioImage/types'

const SPORTS_PARTNER_CONSTRAINTS = {
  desktop: {
    width: 156,
    height: 16,
  },
  tablet: {
    width: 156,
    height: 16,
  },
  mobile: {
    width: 156,
    height: 16,
  },
  mobileSmall: {
    width: 156,
    height: 16,
  },
} as const satisfies ImageConstraints

const StyledSportsPartner = styled(Blink)`
  ${({
    theme: {
      spacing: { spacing16 },
      color: {
        primary: { primary01: primary01Color, primary02: primary02Color },
        tertiary: { grey900 },
      },
      typography: {
        subheads: {
          small1: { bundledCSS: small1SubheadingCSS },
        },
      },
    },
  }) => css`
    appearance: none;
    text-decoration: none;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 0 ${spacing16};
    height: 48px;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid ${grey900};
    ${small1SubheadingCSS};
    color: ${primary02Color};
    background-color: ${primary01Color};
  `}
`

export type SportsPartnerProps = {
  className?: string
  partner?: {
    logo: Image<'free'>
    title: string
    url: string
  }
}

const SportsPartnerLogo = styled.div``

const SportsPartner: FunctionComponent<SportsPartnerProps> = ({
  className,
  partner,
}) => {
  const onImpression = useCallback<TrackingFnType>(
    () => ({
      event: 'sporttip_impression',
      eventCategory: 'sporttip_widget',
      eventAction: 'impression',
      eventLabel: 'Livescore',
    }),
    []
  )

  const onClick = useCallback<TrackingFnType>(
    () => ({
      event: 'sporttip_click',
      eventCategory: 'sporttip_widget',
      eventAction: 'click',
      eventLabel: 'Livescore',
    }),
    []
  )

  const trackedOnImpression = useTracking(onImpression)
  const trackedOnClick = useTracking(onClick)

  const viewportRef = useViewportTracking({
    track: true,
    onImpression: trackedOnImpression,
  })

  if (!partner) {
    return null
  }

  const clickableProps = {
    href: partner.url,
    target: '_blank',
    rel: 'nofollow',
  }

  return (
    <StyledSportsPartner
      className={className}
      ref={viewportRef}
      onClick={trackedOnClick}
      {...clickableProps}>
      {partner.title}
      <SportsPartnerLogo>
        <FreeRatioImage
          src={partner.logo.src}
          alt={partner.logo.alt}
          crops={partner.logo.crops}
          originalDimensions={{
            width: partner.logo.width,
            height: partner.logo.height,
          }}
          constraints={SPORTS_PARTNER_CONSTRAINTS}
        />
      </SportsPartnerLogo>
    </StyledSportsPartner>
  )
}

export default SportsPartner
