import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { SwiperClass, ArrowsVisibilityType } from '@components/Swiper'

import NavigationArrows from '@components/Swiper/NavigationArrows'
import Counter from './Counter'
import { desktopCSS } from '@measures/responsive'

const SwiperNavigationWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${spacing8} 0 0 0;
  `}
`

const StyledNavigationArrows = styled(NavigationArrows)<{
  isFullScreen?: boolean
}>`
  ${({
    theme: {
      color: {
        primary: { primary01: primary01Color, primary02: primary02Color },
      },
    },
    isFullScreen,
  }) => css`
    ${isFullScreen &&
    css`
      button > svg {
        color: ${primary02Color};
        fill: ${primary02Color};
      }

      ${desktopCSS(css`
        button > svg {
          color: ${primary01Color};
          fill: ${primary01Color};
        }
      `)}
    `};
  `}
`

type SwiperNavigationProps = {
  swiperRef: React.RefObject<SwiperClass>
  itemsCount: number
  activeIndex: number
  arrowsVisibility?: ArrowsVisibilityType
  isFullScreen?: boolean
}

const SwiperNavigation: FunctionComponent<SwiperNavigationProps> = ({
  itemsCount,
  swiperRef,
  activeIndex,
  arrowsVisibility,
  isFullScreen,
}) => {
  const counterLabel = `${activeIndex + 1}/${itemsCount}`

  return (
    <SwiperNavigationWrapper>
      <Counter counterLabel={counterLabel} />
      <StyledNavigationArrows
        swiperRef={swiperRef}
        arrowsVisibility={arrowsVisibility}
        isFullScreen={isFullScreen}
      />
    </SwiperNavigationWrapper>
  )
}

export default SwiperNavigation
