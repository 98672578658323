import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import SvgIcon from '@components/SvgIcon'

const StyledBlickPlusLogo = styled(SvgIcon)`
  ${({
    theme: {
      spacing: { spacing4 },
      typography: {
        subheads: {
          small1: { fontSize, lineHeight },
        },
      },
    },
  }) => css`
    vertical-align: top;
    margin-right: ${spacing4};
    // catchword line height (in px)
    height: calc(${fontSize} * ${lineHeight});
    // catchword line height (in px) * blick plus logo aspect ratio
    width: calc(${fontSize} * ${lineHeight} * 54 / 40);
  `}
`

const BlickPlusLogo: FunctionComponent = () => {
  return <StyledBlickPlusLogo iconName="blick-plus-logo" size={18} />
}

export default BlickPlusLogo
