import { FunctionComponent } from 'react'
import styled, { css, useTheme } from 'styled-components'
import { Data } from '../types'
import { desktopCSS } from '@measures/responsive'
import IconButton from '@components/Buttons/IconButton'

interface UploadedFilesProps {
  uploadedFiles: Data['files']
  removeFile: (index: number) => void
}

const Wrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing96, spacing8 },
    },
  }) => css`
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    height: auto;
    margin-top: ${spacing8};
    margin-left: 0;
    flex-wrap: nowrap;
    width: 100%;
    ${desktopCSS(css`
      height: ${spacing96};
      margin-left: ${spacing8};
      width: auto;
      flex-wrap: wrap;
    `)}
  `}
`

const UploadedFile = styled.div`
  ${({
    theme: {
      spacing: { spacing2, spacing20 },
      color: {
        primary: { primary01: primary01color },
      },
    },
  }) => css`
    color: ${primary01color};
    display: flex;
    flex-direction: row;
    padding: ${spacing2};
    ${desktopCSS(css`
      margin-right: ${spacing20};
    `)};
    justify-content: space-between;
  `}
`

const FileName = styled.div`
  ${({
    theme: {
      typography: {
        bodycopy: {
          small2: { bundledCSS: small2BodycopyCSS },
        },
      },
      spacing: { spacing96, spacing24 },
      utils: { ellipsis },
    },
  }) => css`
    ${small2BodycopyCSS};
    width: ${spacing96};
    margin-right: ${spacing24};
    ${ellipsis('100%', 1)}
  `}
`

const UploadedFiles: FunctionComponent<UploadedFilesProps> = ({
  uploadedFiles,
  removeFile,
}) => {
  const theme = useTheme()

  return (
    <Wrapper>
      {uploadedFiles.map(({ name }, index) => (
        <UploadedFile key={`${name}-${index}`}>
          <FileName>{name}</FileName>
          <IconButton
            ariaLabel="Close"
            buttonSize={24}
            onClick={() => removeFile(index)}
            iconName="xmark-large"
            iconSize={20}
            iconColor={theme.color.primary.primary01}
          />
        </UploadedFile>
      ))}
    </Wrapper>
  )
}

export default UploadedFiles
