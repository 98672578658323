import { createContext } from 'react'

export interface HeaderSearchBarContextType {
  isSearchBarCollapsed: boolean
  updateIsSearchBarCollapsed: (
    value: boolean | ((curValue: boolean) => boolean)
  ) => void
}

const HeaderSearchBarContext = createContext<HeaderSearchBarContextType>({
  isSearchBarCollapsed: true,
  updateIsSearchBarCollapsed: () => {},
})

export default HeaderSearchBarContext
