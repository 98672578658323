import {
  LiveEventDataTennis,
  LiveEventDataTennisCompetitor,
  LiveEventDataTennisGameState,
  LiveEventDataTennisPeriodScores,
} from './liveEvent'
import {
  LIVE_EVENT_DATA_STATUS_TYPES,
  LIVE_EVENT_DATA_TENNIS_MATCH_STATUS,
  formatScheduledAt,
} from '@utils/formatters/common'
import translate from '@i18n'

export interface LiveEventTransformed {
  isLive: boolean
  isScheduled: boolean
  isFinished: boolean
  isCancelled: boolean
}

export type LiveEventTransformedTennis = LiveEventTransformed & {
  tournamentName: string
  statusTitle: string
  subStatusTitle: string
  competitors: LiveEventDataTennisCompetitor[]
  countryLogoSrc: string
  location: string
  periodScores: LiveEventDataTennisPeriodScores[]
  gameState?: LiveEventDataTennisGameState
}

export function transformLiveEvent(
  match: LiveEventDataTennis
): LiveEventTransformedTennis {
  const { sdpStatus, sdpIsLive, sport_event, sport_event_status } = match

  const isFinished = sdpStatus === LIVE_EVENT_DATA_STATUS_TYPES.FINISHED
  const isScheduled = sdpStatus === LIVE_EVENT_DATA_STATUS_TYPES.PENDING
  const isCancelled = sdpStatus === LIVE_EVENT_DATA_STATUS_TYPES.CANCELLED

  const statusTitle = isScheduled
    ? formatScheduledAt(match.sdpScheduledAt)
    : sdpIsLive
      ? translate('liveEvent.live')
      : isCancelled
        ? translate('liveEvent.cancelled')
        : ''

  let subStatusTitle = ''
  switch (sport_event_status.match_status) {
    case LIVE_EVENT_DATA_TENNIS_MATCH_STATUS.INTERRUPTED:
      subStatusTitle = translate('matchStatus.interrupted')
      break
    case LIVE_EVENT_DATA_TENNIS_MATCH_STATUS.CANCELLED:
      subStatusTitle = translate('matchStatus.calledOff')
      break
    case LIVE_EVENT_DATA_TENNIS_MATCH_STATUS.CANCELED:
      subStatusTitle = translate('matchStatus.calledOff')
      break
    case LIVE_EVENT_DATA_TENNIS_MATCH_STATUS.ABANDONED:
      subStatusTitle = translate('matchStatus.cancelled')
      break
    case LIVE_EVENT_DATA_TENNIS_MATCH_STATUS.RETIRED:
      subStatusTitle = translate('matchStatus.cancelled')
      break
    default:
      subStatusTitle = sdpIsLive
        ? translate('liveEvent.live')
        : isFinished
          ? translate('matchStatus.ended')
          : formatScheduledAt(match.sdpScheduledAt)
  }

  return JSON.parse(
    JSON.stringify({
      tournamentName: match.sdpTournamentName,
      countryLogoSrc: match.sdpCountryLogoSrc,
      location: match.sdpLocation,
      isFinished,
      isCancelled,
      isScheduled,
      isLive: sdpIsLive,
      statusTitle,
      subStatusTitle,
      competitors: sport_event.competitors ? sport_event.competitors : [],
      periodScores: sport_event_status.period_scores
        ? sport_event_status.period_scores
        : [],
      gameState: sport_event_status.game_state,
    })
  )
}
