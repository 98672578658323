import { useQueryClient } from '@tanstack/react-query'

export interface VideoSkeletonItem {
  kind: ['widget', 'video', 'skeleton-video-parameters']
  videoTypeThresholdInSeconds: number
}

export type VideoSkeleton = [VideoSkeletonItem]

export type UseVideoSkeleton = () => Omit<VideoSkeletonItem, 'kind'>

const useVideoSkeleton: UseVideoSkeleton = () => {
  const queryClient = useQueryClient()

  const videoSkeleton = queryClient.getQueryData<VideoSkeleton>([
    'videoSkeleton',
  ]) as VideoSkeleton

  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const { kind, ...restOfProps } = videoSkeleton?.[0] || {}

  return restOfProps
}

export default useVideoSkeleton
