import { useRef, useState, useCallback, useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'

export type UseIsGiftedArticle = () => boolean

const useIsGiftedArticle: UseIsGiftedArticle = () => {
  const queryClient = useQueryClient()
  const queryCache = queryClient.getQueryCache()
  const unsubscribeFnRef = useRef<() => void>()

  const [isGiftedArticle, setIsGiftedArticle] = useState<boolean>(
    () => !!queryClient.getQueryData<boolean>(['isGiftedArticle'])
  )

  const updateIsGiftedArticle = useCallback(
    (args: any) => {
      if (
        args?.action?.type === 'invalidate' &&
        args.query.queryKey[0] === 'isGiftedArticle'
      ) {
        setIsGiftedArticle(
          !!queryClient.getQueryData<boolean>(['isGiftedArticle'])
        )
      }
    },
    [queryClient]
  )

  useEffect(() => {
    setIsGiftedArticle(!!queryClient.getQueryData<boolean>(['isGiftedArticle']))
    unsubscribeFnRef.current = queryCache.subscribe(updateIsGiftedArticle)

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [updateIsGiftedArticle, queryCache, queryClient])

  return isGiftedArticle
}

export default useIsGiftedArticle
