import config from '@config'
import { PlaylistData } from '@widgets/Video/playlist/types'

export type FetchPlaylist = (options: {
  playlistSrc: string
  token: string
}) => Promise<PlaylistData | undefined>

const {
  backend: { versionedApiUrl },
} = config

const isInServerBundle = typeof window === 'undefined'

const getIsPlaylistResource = (pageData: any) =>
  pageData?.children?.[0]?.kind?.[0] === 'widget' &&
  pageData?.children?.[0]?.kind?.[1] === 'playlist-videos-items'

const fetchPlaylist: FetchPlaylist = async ({ playlistSrc, token }) => {
  const result = await (
    await fetch(
      `${versionedApiUrl}${playlistSrc}${token ? `?token=${token}` : ''}`,
      isInServerBundle ? { headers: { 'User-Agent': 'next' } } : {}
    )
  ).json()

  const playlistObj = result?.children?.find(
    (item: Record<string, any>) =>
      item?.kind?.[0] === 'widget' &&
      item?.kind?.[1] === 'playlist-videos-items'
  )

  const { items, title } = playlistObj ?? {}

  if (items?.length > 0) {
    return {
      title,
      items,
    } as PlaylistData
  } else {
    return
  }
}

export { fetchPlaylist, getIsPlaylistResource }
