import { useCallback, useEffect, useRef, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'

export type UseIsImmersiveHeaderActive = () => boolean

export type IsImmersiveHeaderActiveType = boolean | null

const useIsImmersiveHeaderActive: UseIsImmersiveHeaderActive = () => {
  const queryClient = useQueryClient()
  const queryCache = queryClient.getQueryCache()
  const unsubscribeFnRef = useRef<() => void>()

  const [isImmersiveHeaderActive, setIsImmersiveHeaderActive] =
    useState<boolean>(
      () =>
        !!queryClient.getQueryData<IsImmersiveHeaderActiveType>([
          'is-immersive-header-active',
        ])
    )

  const updateIsImmersiveHeaderActive = useCallback(
    (args: any) => {
      if (
        ['invalidate'].includes(args?.action?.type) &&
        args.query.queryKey[0] === 'is-immersive-header-active'
      ) {
        setIsImmersiveHeaderActive(
          !!queryClient.getQueryData<IsImmersiveHeaderActiveType>([
            'is-immersive-header-active',
          ])
        )
      }
    },
    [queryClient]
  )

  useEffect(() => {
    setIsImmersiveHeaderActive(
      !!queryClient.getQueryData<IsImmersiveHeaderActiveType>([
        'is-immersive-header-active',
      ])
    )

    unsubscribeFnRef.current = queryCache.subscribe(
      updateIsImmersiveHeaderActive
    )

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [queryCache, queryClient, updateIsImmersiveHeaderActive])

  return isImmersiveHeaderActive
}

export default useIsImmersiveHeaderActive
