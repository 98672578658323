import { FunctionComponent } from 'react'
import translate from '@i18n'
import { Link } from '@utils/cook/types'
import PrimaryCTABlink from '@components/Buttons/PrimaryCTABlink'

interface ReadMoreCTAButtonProps {
  clickableProps?: Link
  onClick?: () => void
}

const ReadMoreCTAButton: FunctionComponent<ReadMoreCTAButtonProps> = ({
  clickableProps,
  onClick,
}) => {
  if (clickableProps?.href) {
    const modifiedClickableProps = {
      ...clickableProps,
      target: '_blank',
    }

    return (
      <PrimaryCTABlink
        size="small"
        iconName="arrow-right"
        onClick={onClick}
        {...modifiedClickableProps}>
        {translate('blickBites.readMore')}
      </PrimaryCTABlink>
    )
  }

  return null
}

export default ReadMoreCTAButton
