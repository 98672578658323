import { FunctionComponent } from 'react'
import EmbeddedContent from '@components/EmbeddedContent'
import config from '@config'

const {
  socialembeds: { instagramEmbedStaticPageUrl },
} = config

interface InstagramComponentProps {
  url: string
}

const InstagramComponent: FunctionComponent<InstagramComponentProps> = ({
  url,
}) => <EmbeddedContent url={`${instagramEmbedStaticPageUrl}${url}`} />

export default InstagramComponent
