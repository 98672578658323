import { createContext } from 'react'

export interface GalleryFullScreenContextType {
  isGalleryWidget: boolean
  setIsGalleryWidget: (value: boolean) => void
  isFullScreen: boolean
  setIsFullScreen: (value: boolean) => void
  hasControls: boolean
  setHasControls: (value: boolean) => void
  hideFullScreenGalleryControls: boolean
}

const GalleryFullScreenContext = createContext<GalleryFullScreenContextType>({
  isGalleryWidget: false,
  setIsGalleryWidget: () => {},
  isFullScreen: false,
  setIsFullScreen: () => {},
  hasControls: true,
  setHasControls: () => {},
  hideFullScreenGalleryControls: false,
})

export default GalleryFullScreenContext
