import { ConfigProps } from '@config/types'

const config = ({ sentryDebugMode }: ConfigProps) => {
  return {
    DSN: 'https://bf1d13534a6e41b0b20546fce4a793e5@o101405.ingest.us.sentry.io/6194957',
    debugModeEnabled: sentryDebugMode,
  } as const satisfies Record<string, unknown>
}

export default config
