import { Data } from '../types'

const encodeFormData = (data: Data) => {
  const formBody: string[] = []
  const { files, ...dataWithoutFiles } = data
  let dataKey: keyof Omit<Data, 'files'>
  for (dataKey in dataWithoutFiles) {
    const encodedKey = encodeURIComponent(dataKey)
    const encodedValue = encodeURIComponent(data[dataKey])
    formBody.push(encodedKey + '=' + encodedValue)
  }

  files.forEach(({ name }) => {
    const lowecasedFileName = name.toLowerCase()
    const fileName = encodeURIComponent(lowecasedFileName)
    formBody.push(`files=${fileName}`)
  })
  return formBody.join('&')
}

export { encodeFormData }
