import { FunctionComponent } from 'react'
import styled, { css, useTheme } from 'styled-components'
import IconButton from '@components/Buttons/IconButton'
import { desktopCSS } from '@measures/responsive'

interface CloseButtonProps {
  className?: string
  onClick: () => void
}

const StyledIconButton = styled(IconButton)`
  ${({
    theme: {
      color: {
        primary: { primary01: primary01Color, primary02: primary02Color },
      },
    },
  }) => css`
    border-radius: 50%;
    background: rgba(17, 17, 17, 0.5);

    ${desktopCSS(css`
      background: ${primary02Color};

      svg {
        fill: ${primary01Color};
        color: ${primary01Color};
      }
    `)}
  `}
`

const CloseButton: FunctionComponent<CloseButtonProps> = ({
  className,
  onClick,
}) => {
  const theme = useTheme()

  return (
    <StyledIconButton
      className={className}
      ariaLabel="Close"
      buttonSize={40}
      onClick={onClick}
      iconName="xmark-large"
      iconSize={20}
      iconColor={theme.color.primary.primary02}
    />
  )
}

export default CloseButton
