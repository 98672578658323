import { forwardRef, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { desktopCSS, mobileCSS, tabletCSS } from '@measures/responsive'
import { printCSS } from '@utils/style'

interface WrapperProps {
  children: ReactNode
  className?: string
  slotCodeDesktop?: string
  slotCodeTablet?: string
  slotCodeMobile?: string
}

type StyledWrapperProps = Pick<
  WrapperProps,
  'slotCodeDesktop' | 'slotCodeTablet' | 'slotCodeMobile'
>

const StyledWrapper = styled.aside<StyledWrapperProps>`
  ${({ slotCodeDesktop, slotCodeTablet, slotCodeMobile }) => css`
    ${desktopCSS(css`
      ${!slotCodeDesktop &&
      css`
        display: none;
      `};
    `)};

    ${tabletCSS(css`
      ${!slotCodeTablet &&
      css`
        display: none;
      `};
    `)};

    ${mobileCSS(css`
      ${!slotCodeMobile &&
      css`
        display: none;
      `};
    `)};

    ${printCSS(css`
      display: none !important;
    `)};
  `}
`

const Wrapper = forwardRef<HTMLDivElement, WrapperProps>(
  (
    { children, className, slotCodeDesktop, slotCodeTablet, slotCodeMobile },
    ref
  ) => {
    return (
      <StyledWrapper
        ref={ref}
        className={className}
        slotCodeDesktop={slotCodeDesktop}
        slotCodeTablet={slotCodeTablet}
        slotCodeMobile={slotCodeMobile}>
        {children}
      </StyledWrapper>
    )
  }
)

Wrapper.displayName = 'Wrapper'

export default Wrapper

export { StyledWrapper }
