import { css } from 'styled-components'

export const ads = css`
  &.jw-flag-ads {
    // Goodle DAI ads styles
    &.jw-flag-ads-dai {
      &.btv-fullscreen-overlay-enabled {
        .jw-controlbar .jw-fullscreen-ima {
          display: none;
        }

        .jw-controlbar .jw-icon-playback {
          order: -3;
        }

        .jw-controlbar .jw-icon-volume {
          order: -2;
        }

        .jw-controlbar .jw-icon-inline.btv-icon-fullscreen-overlay {
          display: flex;
          order: -1;
        }
      }
    }

    // IMA ads styles
    &.jw-flag-ads-googleima {
      // hide fullscreen button
      .jw-controlbar .jw-fullscreen-ima {
        display: none;
      }

      // relocate 'admessage' on the right of mute button (to have the same as for desktop)
      // those styles overrides JW player native 'jw-flag-small-player' styles to ignore them
      &.jw-flag-small-player {
        .jw-controlbar {
          /* flex-flow: row wrap; */
          height: auto;
          max-height: 72px;
        }

        .jw-button-container {
          align-items: center;
          /* flex-wrap: nowrap; */
          /* margin-bottom: 0; */
        }

        .jw-text {
          align-self: auto;
          margin: 0;
          order: 0;
          width: auto;
        }
      }
    }

    // common styles for Goodle DAI and IMA
    .jw-controlbar .jw-slider-time .jw-progress {
      background-color: #ffce00;
    }
  }
`
