import { createContext } from 'react'
import { createHtmlPortalNode } from 'react-reverse-portal'

export interface BlickBitesFastlaneContextType {
  muted: boolean
  captionsEnabled: boolean
  widgetId: string
  videoId?: string
  isPlayerReady: boolean
  isLoading: boolean
  isPaused: boolean
  areCaptionsAvailable: boolean
  VideoPlayerPortalNode: ReturnType<typeof createHtmlPortalNode> | null
  setMuted: (value: boolean) => void
  setCaptionsEnabled: (value: boolean) => void
  loadVideo: (videoId: string) => void
}

const BlickBitesFastlaneContext = createContext<BlickBitesFastlaneContextType>({
  muted: false,
  captionsEnabled: false,
  widgetId: '',
  videoId: '',
  isPlayerReady: false,
  isLoading: false,
  isPaused: false,
  areCaptionsAvailable: false,
  VideoPlayerPortalNode: null,
  setMuted: () => {},
  setCaptionsEnabled: () => {},
  loadVideo: () => {},
})

export default BlickBitesFastlaneContext
