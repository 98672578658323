import SvgIcon from '@components/SvgIcon'
import { mobileCSS, mobileAndTabletCSS } from '@measures/responsive'
import { FunctionComponent } from 'react'
import styled, { css, useTheme } from 'styled-components'

interface LeftArrowFaderProps {
  isImmersiveHeaderActive: boolean
  scrollToStart: () => void
}

interface RightArrowFaderProps {
  isImmersiveHeaderActive: boolean
  scrollToEnd: () => void
}

const WrapperCommonStyle = css`
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, auto);

  align-items: center;
  justify-items: center;
  position: absolute;
`

const LeftWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    left: 0;
    z-index: 1;
    ${WrapperCommonStyle}

    ${mobileCSS(css`
      left: -${spacing16};
    `)}
  `}
`

const RightWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    bottom: 0;
    right: 0;
    ${WrapperCommonStyle}

    ${mobileCSS(css`
      right: -${spacing16};
    `)}
  `}
`

interface FaderProps {
  leftOrRight: 'left' | 'right'
  isImmersiveHeaderActive: boolean
}

const Fader = styled.div<FaderProps>`
  ${({
    theme: {
      color: {
        primary: { primary02: primary02Color },
      },
    },
    leftOrRight,
    isImmersiveHeaderActive,
  }) => css`
    width: 16px;
    height: 100%;

    background: linear-gradient(
      ${leftOrRight === 'left' ? '90deg' : '270deg'},
      ${primary02Color} 37.78%,
      rgba(255, 255, 255, 0) 95.56%
    );

    ${isImmersiveHeaderActive &&
    css`
      ${mobileAndTabletCSS(css`
        opacity: 0;
      `)}
    `}

    ${!isImmersiveHeaderActive &&
    css`
      ${mobileAndTabletCSS(css`
        transition: opacity 0.2s 0.3s linear;
      `)}
    `}
  `}
`
const IconWrapper = styled.button<{ isImmersiveHeaderActive: boolean }>`
  ${({
    theme: {
      color: {
        primary: { primary02: primary02Color },
      },
    },
    isImmersiveHeaderActive,
  }) => css`
    height: 100%;
    background: ${primary02Color};

    display: grid;
    align-items: center;
    justify-items: center;
    border: 0;
    cursor: pointer;
    padding: 0;
    line-height: 0;

    ${isImmersiveHeaderActive &&
    css`
      ${mobileAndTabletCSS(css`
        background: transparent;
      `)}
    `}

    ${!isImmersiveHeaderActive &&
    css`
      ${mobileAndTabletCSS(css`
        transition: background-color 0.2s 0.3s linear;
      `)}
    `}
  `}
`

const StyledSvgIcon = styled(SvgIcon)<{ isImmersiveHeaderActive: boolean }>`
  ${({
    theme: {
      color: {
        primary: { primary02: primary02Color },
      },
    },
    isImmersiveHeaderActive,
  }) => css`
    ${isImmersiveHeaderActive &&
    css`
      ${mobileAndTabletCSS(css`
        fill: ${primary02Color};
      `)}
    `}
  `}
`

const LeftArrowFader: FunctionComponent<LeftArrowFaderProps> = ({
  isImmersiveHeaderActive,
  scrollToStart,
}) => {
  const theme = useTheme()

  return (
    <LeftWrapper>
      <IconWrapper
        aria-label="Scroll left"
        isImmersiveHeaderActive={isImmersiveHeaderActive}
        onClick={scrollToStart}>
        <StyledSvgIcon
          size={24}
          iconName="chevron-left"
          color={theme.color.primary.primary01}
          isImmersiveHeaderActive={isImmersiveHeaderActive}
        />
      </IconWrapper>
      <Fader
        leftOrRight="left"
        isImmersiveHeaderActive={isImmersiveHeaderActive}
      />
    </LeftWrapper>
  )
}

const RightArrowFader: FunctionComponent<RightArrowFaderProps> = ({
  isImmersiveHeaderActive,
  scrollToEnd,
}) => {
  const theme = useTheme()

  return (
    <RightWrapper>
      <Fader
        leftOrRight="right"
        isImmersiveHeaderActive={isImmersiveHeaderActive}
      />
      <IconWrapper
        aria-label="Scroll right"
        isImmersiveHeaderActive={isImmersiveHeaderActive}
        onClick={scrollToEnd}>
        <StyledSvgIcon
          size={24}
          iconName="chevron-right"
          color={theme.color.primary.primary01}
          isImmersiveHeaderActive={isImmersiveHeaderActive}
        />
      </IconWrapper>
    </RightWrapper>
  )
}

const ArrowFader = { Left: LeftArrowFader, Right: RightArrowFader }

export default ArrowFader
