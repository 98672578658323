import {
  LiveEventDataFormula1,
  LiveEventDataFormula1Competitor,
  LiveEventDataFormula1SubStage,
} from './liveEvent'
import {
  formatScheduledAt,
  LIVE_EVENT_DATA_FORMULA1_EVENT_STATUS,
  LIVE_EVENT_DATA_FORMULA1_STAGE_TYPES,
  LIVE_EVENT_DATA_STATUS_TYPES,
  LiveEventTransformedCompetitor,
} from '@utils/formatters/common'
import translate from '@i18n'

function getFirstSubstageWithResults(
  subStages: LiveEventDataFormula1SubStage[] = [],
  index: number
): LiveEventDataFormula1SubStage {
  // Approach: Go from current sub stage to the previous one until you find results.
  // Stop at Q1, i.e. index 0
  const curr = subStages[index]
  if (index === 0) return curr
  if (!curr || !curr.sdpStageHasResults) {
    return getFirstSubstageWithResults(subStages, index - 1)
  }
  return curr
}

// If current stage has no results, return the previous one including results
function getQualifyingCompetitorListWithResults(
  stage: LiveEventDataFormula1
): LiveEventDataFormula1Competitor[] {
  const { sdpQualificationCurrentRound, subStages } = stage
  if (!sdpQualificationCurrentRound || !subStages || !subStages.length) {
    return []
  }
  const subStage = getFirstSubstageWithResults(
    stage.subStages,
    sdpQualificationCurrentRound - 1 // index starts from 0
  )
  return subStage.sdpStageHasResults ? subStage.competitors : []
}

export interface LiveEventTransformed {
  isLive: boolean
  willStart: boolean
  isFinished: boolean
  statusTitle: string
  statusInfo?: string
}

export type LiveEventTransformedFormula1 = LiveEventTransformed & {
  countryLogoSrc: string
  isCancelled: boolean
  isRace: boolean
  isQualifying: boolean
  raceTypeName: string // eg. Rennen or Qualifying
  raceGPName: string // eg. GP von Ungarn
  raceLocation: string // eg. Miami
  competitors: LiveEventTransformedCompetitor[]
}

export function formatCompetitor(
  competitor: LiveEventDataFormula1Competitor,
  i: number
): LiveEventTransformedCompetitor {
  return {
    logo: competitor.countryLogoSrc,
    name: competitor.name,
    position: competitor.result ? competitor.result.position : i + 1,
    time: competitor.result ? competitor.result.time : '',
    teamname: competitor.teamname ? competitor.teamname : '',
  }
}

export function transformLiveEvent(
  stage: LiveEventDataFormula1
): LiveEventTransformedFormula1 {
  const {
    type,
    sdpStatus,
    sdpCountryLogoSrc,
    competitors,
    status,
    sdpLocation,
  } = stage

  const isCancelled = status === LIVE_EVENT_DATA_STATUS_TYPES.CANCELLED
  const isQualifying = type === LIVE_EVENT_DATA_FORMULA1_STAGE_TYPES.QUALIFYING
  const isRace = type === LIVE_EVENT_DATA_FORMULA1_STAGE_TYPES.RACE
  const isFinished = sdpStatus === LIVE_EVENT_DATA_STATUS_TYPES.FINISHED

  const isNotStarted =
    status === LIVE_EVENT_DATA_FORMULA1_EVENT_STATUS.NOT_STARTED
  const isWarmup = status === LIVE_EVENT_DATA_FORMULA1_EVENT_STATUS.WARMUP
  const isInGrid = status === LIVE_EVENT_DATA_FORMULA1_EVENT_STATUS.IN_GRID
  const isClearGrid =
    status === LIVE_EVENT_DATA_FORMULA1_EVENT_STATUS.CLEAR_GRID

  const isLive = stage.sdpIsLive || false

  const willStart =
    (!status || isNotStarted || isWarmup || isInGrid || isClearGrid) && !isLive

  const statusTitle = willStart
    ? formatScheduledAt(stage.sdpScheduledAt)
    : isLive
      ? translate('liveEvent.live')
      : translate('liveEvent.finished')

  const raceTypeName = stage.sdpTypeName
  const raceGPName = stage.sdpGPName

  // create top alternative status label
  let statusInfo
  if (isLive) {
    if (isQualifying) {
      statusInfo = `${stage.sdpTypeName} ${stage.sdpQualificationCurrentRound}/${stage.sdpQualificationRounds}`
    } else {
      statusInfo = `${translate('liveEvent.lap')} ${
        stage.laps_completed || 0
      }/${stage.laps}`
    }
  }

  // select the competitors but take the correct ones for qualifying
  let qualifiedCompetitors = competitors
  if (isQualifying && stage.subStages && stage.subStages.length) {
    qualifiedCompetitors = getQualifyingCompetitorListWithResults(stage)
  }

  return JSON.parse(
    JSON.stringify({
      isCancelled,
      isQualifying,
      isFinished,
      isRace,
      countryLogoSrc: sdpCountryLogoSrc,
      raceLocation: sdpLocation,
      willStart,
      isLive,
      raceTypeName,
      raceGPName,
      statusTitle,
      statusInfo,
      competitors: qualifiedCompetitors
        ? qualifiedCompetitors.map(formatCompetitor)
        : [],
    })
  )
}
