import useTracking, { TrackingFnType } from '@hooks/useTracking'
import { stripHtml } from '@hooks/useTracking/utils'
import { FunctionComponent, useCallback, useContext } from 'react'
import SocialButton from './Button'
import translate from '@i18n'
import { getSocialEmbedConfig, getUrlEncodedUTMParameters } from './utils'
import EndOfArticleContext from '@contexts/endOfArticle'

interface MailProps {
  shareUrl: string
  shareTitle: string
  shareUrlHash?: string
  utmContent?: string
  label?: string
}

const Email: FunctionComponent<MailProps> = ({
  shareUrl,
  shareTitle,
  shareUrlHash,
  utmContent,
  label,
}) => {
  const endOfArticle = useContext(EndOfArticleContext)
  const trackingOnClick = useCallback<TrackingFnType>(
    () => ({
      event: 'element_click',
      element: `article_share_${endOfArticle ? 'bottom' : 'top'}`,
      method: 'email',
      utm_content: utmContent,
    }),
    [endOfArticle, utmContent]
  )
  const trackingOnClickLegacy = useCallback<TrackingFnType>(
    ({ pageMetadata }) => ({
      event: 'article_share',
      eventCategory: 'article',
      eventAction: `share_mail_${endOfArticle ? 'bottom' : 'top'}`,
      eventLabel: `${pageMetadata.id}:${stripHtml(pageMetadata.title)}`,
      utm_content: utmContent,
    }),
    [endOfArticle, utmContent]
  )
  const trackingFunction = useTracking(trackingOnClick)
  const trackingFunctionLegacy = useTracking(trackingOnClickLegacy)

  const onClick = useCallback(() => {
    trackingFunction()
    // The *Legacy tracking functions will be deleted when the tracking team is ready to switch to the new events
    trackingFunctionLegacy()
    const { utmSource, utmMedium, utmCampaign } = getSocialEmbedConfig('mail')

    const utm = getUrlEncodedUTMParameters(
      utmMedium,
      utmCampaign,
      utmSource,
      utmContent
    )
    const shareMessage = translate('shareMessage')

    return window.open(
      `mailto:?subject=${shareTitle}&body=${shareMessage}%0A${shareTitle}%0A${shareUrl}${utm}${shareUrlHash ?? ''}`,
      '_self'
    )
  }, [
    trackingFunction,
    trackingFunctionLegacy,
    utmContent,
    shareTitle,
    shareUrl,
    shareUrlHash,
  ])

  return (
    <SocialButton type="mail" onClick={onClick}>
      {label}
    </SocialButton>
  )
}

export default Email
