import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { CommentingPromptWrapperProps } from './types'

const PromptWrapper = styled.div<CommentingPromptWrapperProps>`
  ${({
    theme: {
      spacing: { spacing24 },
    },
  }) => css`
    box-sizing: border-box;
    margin: ${spacing24} 0;
  `}
`

const CommentingPromptWrapper: FunctionComponent<
  CommentingPromptWrapperProps
> = ({ children }) => <PromptWrapper>{children}</PromptWrapper>

export default CommentingPromptWrapper
