import EmbeddedContent from '@components/EmbeddedContent'
import config from '@config'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent } from 'react'
import styled from 'styled-components'
import {
  HeimspielStatisticsProps,
  ValidHeimspielStatisticsProps,
} from './types'
import { isValidHeimspielStatisticsProps } from './utils'

type CreateEmbedUrlType = (
  ...args: [matchId: ValidHeimspielStatisticsProps['matchId']]
) => string

const {
  heimspiel: { heimspielStatisticsStaticPageUrl },
} = config

const StyledHeimspielStatistics = styled.div`
  min-height: 512px;
`

const createEmbeddedUrl: CreateEmbedUrlType = (matchId) =>
  `${heimspielStatisticsStaticPageUrl}?matchId=${matchId}`

const HeimspielStatistics: FunctionComponent<HeimspielStatisticsProps> = (
  props
) => {
  if (!isValidHeimspielStatisticsProps(props)) {
    return null
  }

  const { matchId } = props

  return (
    <StyledHeimspielStatistics>
      <EmbeddedContent
        bypassCMP={true}
        maxHeight={500}
        url={createEmbeddedUrl(matchId)}
      />
    </StyledHeimspielStatistics>
  )
}

const widget = {
  kind: ['widget', 'heimspiel-statistics'],
  component: HeimspielStatistics,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
