import { useCallback, useEffect, useRef, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'

export type ClientViewportType = 'server' | 'desktop' | 'tablet' | 'mobile'

export type UseClientViewportType = () => ClientViewportType

const useClientViewportType: UseClientViewportType = () => {
  const queryClient = useQueryClient()
  const queryCache = queryClient.getQueryCache()
  const unsubscribeFnRef = useRef<() => void>()

  const [clientViewportType, setClientViewportType] =
    useState<ClientViewportType>(
      queryClient.getQueryData<ClientViewportType>([
        'clientViewportType',
      ]) as ClientViewportType
    )

  const updateType = useCallback(
    (args: any) => {
      if (
        ['invalidate'].includes(args?.action?.type) &&
        args.query.queryKey[0] === 'clientViewportType'
      ) {
        setClientViewportType(
          queryClient.getQueryData<ClientViewportType>([
            'clientViewportType',
          ]) as ClientViewportType
        )
      }
    },
    [queryClient]
  )

  useEffect(() => {
    setClientViewportType(
      queryClient.getQueryData<ClientViewportType>([
        'clientViewportType',
      ]) as ClientViewportType
    )
    unsubscribeFnRef.current = queryCache.subscribe(updateType)

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [queryCache, queryClient, updateType])

  return clientViewportType
}

export default useClientViewportType
