import styled, { css } from 'styled-components'
import { mobileAndTabletCSS } from '@measures/responsive'
import Blink from '@components/Blink'

export interface StyledTeaserStandardVerticalWrapperProps {
  isHybridTeaser: boolean
}

const StyledTeaserStandardVerticalWrapper = styled(
  Blink
)<StyledTeaserStandardVerticalWrapperProps>`
  ${({ isHybridTeaser }) => css`
    display: block;
    appearance: none;
    text-decoration: none;

    ${isHybridTeaser &&
    css`
      ${mobileAndTabletCSS(css`
        display: none;
      `)};
    `}
  `}
`

export default StyledTeaserStandardVerticalWrapper
