import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import translate from '@i18n'
import { printCSS } from '@utils/style'
import PrimaryCTAButton, { PrimaryCTAButtonProps } from './PrimaryCTAButton'

interface ShowMoreButtonProps {
  isMore: boolean
  onClick: (buttonText: string) => void
  size?: PrimaryCTAButtonProps['size']
  className?: string
}

const StyledPrimaryCTAButton = styled(PrimaryCTAButton)`
  ${printCSS(css`
    display: none;
  `)}
`

const ShowMoreButton: FunctionComponent<ShowMoreButtonProps> = ({
  isMore,
  onClick,
  size = 'small',
  className,
}) => {
  const buttonText = isMore ? translate('more') : translate('less')

  return (
    <StyledPrimaryCTAButton
      className={className}
      onClick={() => onClick(buttonText)}
      size={size}
      iconName={isMore ? 'chevron-down' : 'chevron-up'}>
      {buttonText}
    </StyledPrimaryCTAButton>
  )
}

export default ShowMoreButton
