import {
  FunctionComponent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import styled, { css, useTheme } from 'styled-components'

import SvgIcon from '@components/SvgIcon'
import Blink from '@components/Blink'
import { desktopCSS, tabletCSS } from '@measures/responsive'

import useTracking, { TrackingFnType } from '@hooks/useTracking'
import useViewportTracking from '@hooks/useViewportTracking'

import { PlatformSpecificButtonProps } from './types'

interface StyledButtonProps {
  isExpanded: boolean
}

const StyledGamificationButton = styled(Blink)<StyledButtonProps>`
  ${({
    theme: {
      spacing: { spacing16, spacing24, spacing12 },
      color: {
        primary: { blickRed },
        secondary: { darkRed },
      },
    },
    isExpanded,
  }) => css`
    position: fixed;
    bottom: ${spacing16};
    right: ${spacing16};
    z-index: 11;
    border-radius: 50px;
    text-decoration: none;
    height: 48px;
    background-color: ${blickRed};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: ${!isExpanded ? `${spacing12}` : `${spacing12} ${spacing24}`};
    box-sizing: border-box;
    border: unset;
    cursor: pointer;
    ${tabletCSS(css`
      display: none;
    `)}
    ${desktopCSS(css`
      display: none;
    `)};
    @media (hover: hover) {
      &:hover:not([disabled]) {
        background-color: ${darkRed};
      }
    }
  `}
`

const StyledText = styled.span`
  ${({
    theme: {
      color: {
        primary: { primary02 },
      },
      spacing: { spacing8 },
      typography: {
        subheads: {
          small1: { bundledCSS: subheadingSmall1CSS },
        },
      },
    },
  }) => {
    return css`
      color: ${primary02};
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0 ${spacing8};
      ${subheadingSmall1CSS};
    `
  }}
`

const IconContainer = styled.div`
  display: flex;
`

const MobileGamificationButton: FunctionComponent<
  PlatformSpecificButtonProps
> = ({ text, link, icon }) => {
  const theme = useTheme()
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [isHidden, setIsHidden] = useState<boolean>(false)
  const [actionName, setActionName] = useState<string>('')

  const trackingOnClick = useCallback<TrackingFnType>(() => {
    return {
      event: 'game_interaction',
      eventCategory: 'Game',
      eventLabel: text,
      eventAction: actionName,
    }
  }, [actionName, text])

  const trackingOnImpression = useCallback<TrackingFnType>(() => {
    return {
      event: 'game_impression',
      eventCategory: 'Game',
      eventLabel: text,
      eventAction: 'impression_mobile',
    }
  }, [text])

  const handleTrack = useTracking(trackingOnClick)
  const handleImpressionTrack = useTracking(trackingOnImpression)

  const viewportRef = useViewportTracking({
    onImpression: handleImpressionTrack,
    track: true,
  })

  useEffect(() => {
    if (actionName) {
      handleTrack()
    }
  }, [actionName, handleTrack])

  const handleExpandChange = () => {
    if (!isExpanded) {
      setIsExpanded(!isExpanded)
      setActionName('expand')
    }
  }

  const handleHideButton = (e: SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setActionName('close')
    setIsHidden(true)
  }

  const handleClick = () => {
    if (isExpanded) {
      setActionName('click')
    } else {
      handleExpandChange()
    }
  }

  return (
    <>
      {!isHidden && (
        <StyledGamificationButton
          onClick={handleClick}
          isExpanded={isExpanded}
          ref={viewportRef}
          as={!isExpanded ? 'button' : Blink}
          {...link}>
          {/* eslint-disable-next-line @next/next/no-img-element  */}
          <img src={icon} alt="icon" width="24" height="24" loading="lazy" />
          {isExpanded && (
            <>
              <StyledText>{text}</StyledText>
              <IconContainer role="button" onClick={handleHideButton}>
                <SvgIcon
                  iconName="xmark-large"
                  size={24}
                  color={theme.color.primary.primary02}
                />
              </IconContainer>
            </>
          )}
        </StyledGamificationButton>
      )}
    </>
  )
}

export default MobileGamificationButton
