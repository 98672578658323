import Input from '@components/Input'
import SvgIcon from '@components/SvgIcon'
import useChatbot from '@hooks/useChatbot'
import translate from '@i18n'
import { FunctionComponent, useRef } from 'react'
import styled, { css } from 'styled-components'

const StyledChatbotInput = styled.div`
  ${({
    theme: {
      color: {
        primary: { primary02 },
        tertiary: { grey400 },
      },
      spacing: { spacing8, spacing24 },
    },
  }) => css`
    position: relative;
    width: 100%;
    padding: ${spacing8} ${spacing24};
    background: ${primary02};
    border: 1px solid ${grey400};
    border-radius: 50px;
    box-sizing: border-box;
  `}
`

const StyledInput = styled(Input)`
  ${({
    theme: {
      typography: {
        bodycopy: {
          xsmall: { bundledCSS: xsmallBodyCopyCSS },
        },
      },
      spacing: { spacing24 },
    },
  }) => css`
    height: 32px;
    width: 100%;
    border: 0;
    padding: 0;
    padding-right: ${spacing24};

    input {
      ${xsmallBodyCopyCSS}

      &::placeholder {
        ${xsmallBodyCopyCSS}
      }
    }
  `}
`

const SubmitButton = styled.button`
  ${({
    theme: {
      color: {
        primary: { blickRed },
        secondary: { darkRed },
      },
    },
  }) => css`
    background: ${blickRed};
    border: 0;
    cursor: pointer;
    height: 36px;
    width: 36px;
    border-radius: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 6px;
    right: 6px;

    &:hover {
      background: ${darkRed};
    }

    &:disabled {
      pointer-events: none;
    }
  `}
`

const ChatbotInput: FunctionComponent = () => {
  const {
    onQuestionSubmit,
    chatbotAnswerLoading,
    chatbotInitialLoading,
    chatbotConversationId,
  } = useChatbot()
  const inputRef = useRef<HTMLInputElement>(null)

  const onSubmit = () => {
    const question = inputRef?.current?.value
    if (question) {
      onQuestionSubmit(question, chatbotConversationId)
      inputRef.current.value = ''
    }
  }

  const onEnterSubmit = (event: any) => {
    if (chatbotAnswerLoading || chatbotInitialLoading) {
      return
    }

    if (event?.key === 'Enter' || event?.keyCode === 13) {
      onSubmit()
    }
  }

  return (
    <StyledChatbotInput>
      <StyledInput
        placeholder={translate('chatbot.footer.inputPlaceholder')}
        ref={inputRef}
        onKeyUp={(event: any) => onEnterSubmit(event)}
      />
      <SubmitButton
        onClick={onSubmit}
        disabled={chatbotAnswerLoading || chatbotInitialLoading}>
        <SvgIcon iconName="chatbot-paper-plane-stars" size="24px" />
      </SubmitButton>
    </StyledChatbotInput>
  )
}

export default ChatbotInput
