import { FunctionComponent, useCallback, useContext, useState } from 'react'
import Answer from './Answer'
import CommentingActionPopup from './CommentingActionPopup'
import CommentingItemList from './CommentingItemList'
import { Answer as AnswerType } from './types'
import GenericContext from '@contexts/commenting/genericContext'
import AnswerAPIContext from '@contexts/commenting/answerApiContext'
import PostedAnswer from './PostedAnswer'
import { stripHtml } from '@hooks/useTracking/utils'
import useTracking, { TrackingFnType } from '@hooks/useTracking'

interface ListOfAnswersProps {
  answers: AnswerType[]
  isPostingAnswer: boolean
  setIsPostingAnswer: (isPostingAnswer: boolean) => void
  moreToShow: boolean
  fatherCommentId: number
}

const ListOfAnswers: FunctionComponent<ListOfAnswersProps> = ({
  answers,
  isPostingAnswer,
  setIsPostingAnswer,
  moreToShow,
  fatherCommentId,
}) => {
  const { articleId, postingEnabled } = useContext(GenericContext)
  const { postedAnswer, postAnswer, getMoreAnswers } =
    useContext(AnswerAPIContext)
  const [postingAnswerInitialText, setPostingAnswerInitialText] = useState('')
  const [nextPage, setNextPage] = useState(0)
  const articleClickMoreAnswerHandler = useCallback<TrackingFnType>(
    ({ pageMetadata }) => ({
      event: 'article_click_more_answer',
      eventCategory: 'article',
      eventLabel: `${pageMetadata.id}:${stripHtml(pageMetadata.title)}`,
      eventAction: 'more_answer',
    }),
    []
  )
  const handleArticleClickMoreAnswerTracking = useTracking(
    articleClickMoreAnswerHandler
  )
  if (!answers) return null

  const onShowMore = moreToShow
    ? () => {
        getMoreAnswers({
          articleId,
          page: nextPage,
          fatherId: fatherCommentId,
        })
        setNextPage((nextPage) => nextPage + 1)
        handleArticleClickMoreAnswerTracking()
      }
    : () => {}

  return (
    <>
      {!!answers.length && (
        <CommentingItemList
          type="answer"
          moreToShow={moreToShow}
          onShowMore={onShowMore}
          rerenderKey={`${articleId}-answers`}>
          {answers.map((answer) => (
            <Answer
              key={`${answer.id}`}
              postingEnabled={postingEnabled}
              answer={answer}
              onReply={(initialText: string) => {
                if (!postedAnswer) {
                  setPostingAnswerInitialText(initialText)
                  setIsPostingAnswer(true)
                }
              }}
            />
          ))}
        </CommentingItemList>
      )}
      {isPostingAnswer && (
        <CommentingActionPopup
          type="answer"
          actionType="reply"
          onConfirm={(textValue = '') => {
            setIsPostingAnswer(false)
            postAnswer({
              body: textValue,
              fatherId: fatherCommentId,
              articleId,
            })
          }}
          onCancel={() => {
            setPostingAnswerInitialText('')
            setIsPostingAnswer(false)
          }}
          initialText={postingAnswerInitialText}
        />
      )}
      {postedAnswer && <PostedAnswer answer={postedAnswer} />}
    </>
  )
}

export default ListOfAnswers
