import translate from '@i18n'
import styled, { css } from 'styled-components'
import { desktopCSS, mobileCSS } from '@measures/responsive'
import { forwardRef } from 'react'
import MainText from '@components/Video/Trailer/MainText'
import PrimaryCTAButton from '@components/Buttons/PrimaryCTAButton'

const StyledOverlayWrapper = styled.div`
  ${({
    theme: {
      animation: {
        transition: { fast: animationTransitionFast },
      },
    },
  }) => {
    return css`
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 8;
      width: 100%;
      height: 100%;
      background-color: black;
      @keyframes fadeIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
      animation: ${animationTransitionFast} fadeIn ease-in-out;
    `
  }}
`
const StyledOverlayContainer = styled.div`
  ${({
    theme: {
      spacing: { spacing24 },
      color: {
        primary: { primary02 },
      },
    },
  }) => {
    return css`
      max-width: 500px;
      padding: 0 ${spacing24};
      color: ${primary02};
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: black;
      ${mobileCSS(css`
        max-width: unset;
        width: 100%;
      `)}
    `
  }}
`

const StyledTitle = styled.h1`
  ${({
    theme: {
      typography: {
        headings: {
          small: { bundledCSS: headingSmallCSS },
          xsmall: { bundledCSS: headingXSmallCSS },
        },
      },
    },
  }) => {
    return css`
      ${headingXSmallCSS};
      width: 100%;
      text-align: center;
      ${desktopCSS(css`
        ${headingSmallCSS};
      `)}
      margin: 0;
    `
  }}
`

interface TrailerVideoOverlayComponentProps {
  onScroll: () => void
  onCloseClick: () => void
}

const TrailerVideoOverlayComponent = forwardRef<
  HTMLDivElement | null,
  TrailerVideoOverlayComponentProps
>(({ onScroll }, ref) => (
  <StyledOverlayWrapper ref={ref}>
    <StyledOverlayContainer>
      <StyledTitle>{translate('video.trailer.blickPlus.title')}</StyledTitle>
      <MainText>{translate(`video.trailer.blickPlus.mainText`)}</MainText>
      <PrimaryCTAButton size="large" onClick={onScroll}>
        {translate('video.blickPlus.buttonLabel')}
      </PrimaryCTAButton>
    </StyledOverlayContainer>
  </StyledOverlayWrapper>
))

TrailerVideoOverlayComponent.displayName = 'TrailerVideoOverlayComponent'

export default TrailerVideoOverlayComponent
