import EmbeddedContent from '@components/EmbeddedContent'
import config from '@config'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { HeimspielWorldCupProps, ValidHeimspielWorldCupProps } from './types'
import { isValidHeimspielWorldCupProps } from './utils'

type CreateEmbedUrlType = (
  ...args: [matchId: ValidHeimspielWorldCupProps['matchId']]
) => string

const {
  heimspiel: { heimspielWorldCupStaticPageUrl },
} = config

const StyledHeimspielWorldCup = styled.div`
  min-height: 512px;
`

const createEmbeddedUrl: CreateEmbedUrlType = (matchId) =>
  `${heimspielWorldCupStaticPageUrl}?matchId=${matchId}`

const HeimspielWorldCup: FunctionComponent<HeimspielWorldCupProps> = (
  props
) => {
  if (!isValidHeimspielWorldCupProps(props)) {
    return null
  }

  const { matchId } = props

  return (
    <StyledHeimspielWorldCup>
      <EmbeddedContent
        bypassCMP={true}
        maxHeight={500}
        url={createEmbeddedUrl(matchId)}
      />
    </StyledHeimspielWorldCup>
  )
}

const widget = {
  kind: ['widget', 'heimspiel-worldCup'],
  component: HeimspielWorldCup,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
