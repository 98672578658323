import { useCallback, useEffect, useState, useMemo } from 'react'
import throttle from 'lodash.throttle'
import {
  BlickTVInputProps,
  JWPlayer,
} from '@components/Video/VideoPlayer/JwLibrary/types'
import { EventsExtended } from '@components/Video/VideoPlayer/JwLibrary/types/jwplayer-extended'
import { isLiveStream } from '@components/Video/VideoPlayer/JwLibrary/utils/player'

type UsePlayerEventType = (options: {
  player?: JWPlayer
  event: Parameters<EventsExtended['on']>[0]
  listener?: Parameters<EventsExtended['on']>[1]
}) => void

type UseOnProgressEvent = (options: {
  player?: JWPlayer
  onProgress?: BlickTVInputProps['onProgress']
}) => void

type UseQuartileEventsType = (options: {
  player?: JWPlayer
  onQuartileCompletion?: BlickTVInputProps['onQuartileCompletion']
}) => void

const usePlayerEvent: UsePlayerEventType = ({ player, event, listener }) => {
  useEffect(() => {
    if (player && listener) {
      player?.on(event, listener)

      return () => {
        player?.off(event, listener)
      }
    }
  }, [event, listener, player])
}

const useOnProgressEvent: UseOnProgressEvent = ({ player, onProgress }) => {
  const onTimeHandler = useMemo(
    () =>
      throttle(
        ({ position }: jwplayer.TimeParam) => {
          onProgress?.({
            currentTime: player?.getCurrentTime?.() || position,
          })
        },
        1000,
        { trailing: false }
      ),
    [onProgress, player]
  )

  usePlayerEvent({ player, event: 'time', listener: onTimeHandler })

  useEffect(() => {
    // cleanup function
    return () => {
      onTimeHandler?.cancel()
    }
  }, [onTimeHandler])
}

const quartiles = [5, 25, 50, 75, 95]

const useQuartileEvents: UseQuartileEventsType = ({
  player,
  onQuartileCompletion,
}) => {
  const [isVOD, setIsVOD] = useState(false)

  // player can detect if it is livestream only when get video duration
  const onFirstFrameHandler = useCallback(() => {
    if (player) {
      setIsVOD(!isLiveStream(player))
    }
  }, [player])

  usePlayerEvent({ player, event: 'firstFrame', listener: onFirstFrameHandler })

  useEffect(() => {
    // Quartile events should work only in VOD
    if (isVOD && player) {
      let currentQuartile = 0

      const onTimeHandler = throttle(
        () => {
          const percentageCover =
            (player.getPosition() * 100) / player.getDuration()

          if (
            quartiles[currentQuartile] &&
            percentageCover > quartiles[currentQuartile]
          ) {
            onQuartileCompletion?.({
              percent: quartiles[currentQuartile],
            })

            currentQuartile += 1
          }
        },
        1000,
        { trailing: false }
      )

      player?.on('time', onTimeHandler)

      return () => {
        player?.off('time', onTimeHandler)
        onTimeHandler?.cancel()
      }
    }
  }, [isVOD, onQuartileCompletion, player])
}

type UseOnPipEvent = (options: {
  player?: JWPlayer
  onPip?: BlickTVInputProps['onPip']
}) => void

const useOnPipEvent: UseOnPipEvent = ({ player, onPip }) => {
  const onEnterPictureInPicture = useCallback(() => {
    player?.trigger('pip', { pip: true })
    onPip?.({
      pip: true,
    })
  }, [onPip, player])

  const onLeavePictureInPicture = useCallback(() => {
    player?.trigger('pip', { pip: false })
    onPip?.({
      pip: false,
    })
  }, [onPip, player])

  useEffect(() => {
    window.addEventListener('enterpictureinpicture', onEnterPictureInPicture)

    return () => {
      window.removeEventListener(
        'enterpictureinpicture',
        onEnterPictureInPicture
      )
    }
  }, [onEnterPictureInPicture])

  useEffect(() => {
    window.addEventListener('leavepictureinpicture', onLeavePictureInPicture)

    return () => {
      window.removeEventListener(
        'leavepictureinpicture',
        onLeavePictureInPicture
      )
    }
  }, [onLeavePictureInPicture])
}

export { useOnProgressEvent, usePlayerEvent, useQuartileEvents, useOnPipEvent }
