import { JWPlayer, OverlayEventParam } from '../types'
import {
  addFullScreenEnterButton,
  addFullScreenExitButton,
} from '../utils/controls'

export const enableCustomFullScreenOverlay = (jwplayer: JWPlayer): void => {
  addFullScreenEnterButton(jwplayer)
  jwplayer.getContainer().classList.add('btv-fullscreen-overlay-enabled')
  jwplayer.setAllowFullscreen?.(false)

  jwplayer?.on('overlay', (param: OverlayEventParam) => {
    if (param?.overlay) {
      addFullScreenExitButton(jwplayer)
    } else {
      addFullScreenEnterButton(jwplayer)
    }
  })
}

/**
 * Init the fullscreen overlay for web mobile / tablet
 * @param jwplayer JWPlayer
 */
export const initCustomFullScreenOverlay = (jwplayer: JWPlayer): void => {
  const env = jwplayer.getEnvironment()

  if (env.OS.iPhone || env.OS.iPad || env.OS.android) {
    jwplayer.once('firstFrame', () => {
      enableCustomFullScreenOverlay(jwplayer)
    })
  }
}
