import useTracking, { TrackingFnType } from '@hooks/useTracking'
import { stripHtml } from '@hooks/useTracking/utils'
import { FunctionComponent, useCallback, useContext } from 'react'
import SocialButton from './Button'
import { getSocialEmbedConfig, getUrlEncodedUTMParameters } from './utils'
import config from '@config'
import EndOfArticleContext from '@contexts/endOfArticle'

const { socialembeds } = config

interface FacebookProps {
  shareUrl: string
  shareUrlHash?: string
  utmContent?: string
  label?: string
}

const Facebook: FunctionComponent<FacebookProps> = ({
  shareUrl,
  shareUrlHash,
  utmContent,
  label,
}) => {
  const endOfArticle = useContext(EndOfArticleContext)
  const trackingOnClick = useCallback<TrackingFnType>(
    () => ({
      event: 'element_click',
      element: `article_share_${endOfArticle ? 'bottom' : 'top'}`,
      method: 'facebook',
      utm_content: utmContent,
    }),
    [endOfArticle, utmContent]
  )
  const trackingOnClickLegacy = useCallback<TrackingFnType>(
    ({ pageMetadata }) => ({
      event: 'article_share',
      eventCategory: 'article',
      eventAction: `share_facebook_${endOfArticle ? 'bottom' : 'top'}`,
      eventLabel: `${pageMetadata.id}:${stripHtml(pageMetadata.title)}`,
      utm_content: utmContent,
    }),
    [endOfArticle, utmContent]
  )
  const trackingFunction = useTracking(trackingOnClick)
  const trackingFunctionLegacy = useTracking(trackingOnClickLegacy)

  const onClick = useCallback(() => {
    trackingFunction()
    // The *Legacy tracking functions will be deleted when the tracking team is ready to switch to the new events
    trackingFunctionLegacy()
    const { utmSource, utmMedium, utmCampaign } =
      getSocialEmbedConfig('facebook')

    const facebookAppId = socialembeds.facebookAppId
    const utm = getUrlEncodedUTMParameters(
      utmMedium,
      utmCampaign,
      utmSource,
      utmContent
    )
    return window.open(
      `https://www.facebook.com/dialog/share?app_id=${facebookAppId}&display=popup&e2e=%7B%7D&href=${shareUrl}${utm}${shareUrlHash ?? ''}`,
      '_blank',
      'noopener noreferrer nofollow'
    )
  }, [
    trackingFunction,
    trackingFunctionLegacy,
    utmContent,
    shareUrl,
    shareUrlHash,
  ])

  return (
    <SocialButton type="facebook" onClick={onClick}>
      {label}
    </SocialButton>
  )
}

export default Facebook
