import { forwardRef } from 'react'
import { desktopCSS } from '@measures/responsive'
import styled, { css } from 'styled-components'

interface SummaryContainerProps {
  children: React.ReactNode
  isExpanded?: boolean
  height?: number
  id?: string
  isLoading?: boolean
}

type SectionSummaryWrapperProps = Pick<
  SummaryContainerProps,
  'isExpanded' | 'height' | 'isLoading'
>

const SummaryWrapper = styled.div<SectionSummaryWrapperProps>`
  ${({
    isExpanded,
    height,
    isLoading,
    theme: {
      color: {
        primary: { blickRed: blickRedColor },
        tertiary: { grey100 },
      },
      spacing: { spacing16, spacing24 },
    },
  }) => css`
    position: relative;
    background-color: ${grey100};
    height: ${height ? `${height}px` : 'auto'};
    overflow: hidden;

    ${isExpanded &&
    css`
      height: auto;
    `}
    ${height &&
    css`
      min-height: ${height}px;
    `}

    ${!isLoading &&
    css`
      border-left: 2px solid ${blickRedColor};
      padding: ${spacing24} ${spacing16};

      ${desktopCSS(css`
        padding: ${spacing24};
        border-left: 4px solid ${blickRedColor};
      `)}
    `}
  `}
`

const SummaryContainer = forwardRef<HTMLDivElement, SummaryContainerProps>(
  ({ children, isExpanded = true, height, id, isLoading }, ref) => {
    return (
      <SummaryWrapper
        isExpanded={isExpanded}
        height={height}
        ref={ref}
        id={id}
        isLoading={isLoading}>
        {children}
      </SummaryWrapper>
    )
  }
)

SummaryContainer.displayName = 'SummaryContainer'

export default SummaryContainer
