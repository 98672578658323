import { FunctionComponent } from 'react'
import SchemaHTMLElement from '@components/SchemaHTMLElement'

interface AddressSchemaProps {
  id: string
  streetAddress: string
  addressLocality: string
  addressRegion: string
  postalCode: string
  addressCountry: string
}

const AddressSchema: FunctionComponent<AddressSchemaProps> = (props) => {
  const { id, ...restProps } = props

  return (
    <SchemaHTMLElement
      scriptKey={`address-schema-${id}`}
      schemaObject={{
        '@type': 'PostalAddress',
        '@id': id,
        ...restProps,
      }}
    />
  )
}

export default AddressSchema
