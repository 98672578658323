import { useRef, useState, useCallback, useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'

export type UseIsContentForceCentered = () => boolean

const useIsContentForceCentered: UseIsContentForceCentered = () => {
  const queryClient = useQueryClient()

  const queryCache = queryClient.getQueryCache()
  const unsubscribeFnRef = useRef<() => void>()

  const [isContentForceCentered, setIsContentForceCentered] = useState<boolean>(
    () => !!queryClient.getQueryData<boolean>(['force-center-content'])
  )

  const updateIsContentForceCentered = useCallback(
    (args: any) => {
      if (
        ['invalidate'].includes(args?.action?.type) &&
        args.query.queryKey[0] === 'force-center-content'
      ) {
        setIsContentForceCentered(
          !!queryClient.getQueryData<boolean>(['force-center-content'])
        )
      }
    },
    [queryClient]
  )

  useEffect(() => {
    unsubscribeFnRef.current = queryCache.subscribe(
      updateIsContentForceCentered
    )

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [updateIsContentForceCentered, queryCache])

  return isContentForceCentered
}

export default useIsContentForceCentered
