import { FunctionComponent, ReactNode, useContext } from 'react'
import styled, { css } from 'styled-components'
import contextArticleTextbox, {
  ArticleTextboxContextType,
} from '@contexts/articleTextbox'
import { desktopCSS } from '@measures/responsive'

const { ArticleTextboxContext } = contextArticleTextbox

type StyledWrapperProps = Pick<
  ArticleTextboxContextType,
  'isPromo' | 'isExpanded'
>

const StyledWrapper = styled.div<StyledWrapperProps>`
  ${({
    theme: {
      spacing: { spacing2, spacing4, spacing32 },
      color: {
        primary: { blickRed },
      },
    },
    isPromo,
    isExpanded,
  }) => {
    return css`
      margin: ${spacing32} 0;
      ${!isExpanded &&
      !isPromo &&
      css`
        border-left: ${spacing2} solid ${blickRed};
        ${desktopCSS(css`
          border-left: ${spacing4} solid ${blickRed};
        `)};
      `};
    `
  }}
`

const Wrapper: FunctionComponent<{ children?: ReactNode }> = ({ children }) => {
  const { isPromo, isExpanded } = useContext(ArticleTextboxContext)
  return (
    <StyledWrapper isPromo={isPromo} isExpanded={isExpanded}>
      {children}
    </StyledWrapper>
  )
}

export default Wrapper
