import { FunctionComponent } from 'react'
import styled, { CSSProp } from 'styled-components'
import svgSprite from '@assets/images/sprite.svg'

import { SvgIconName } from './types'

export interface SvgIconProps {
  className?: string
  iconName: SvgIconName
  size: number | string
  color?: string
  additionalStyles?: CSSProp
}

/**
 * Icon component, referencing a symbol in the svgSprite
 */
interface StyledSvgProps extends Pick<SvgIconProps, 'additionalStyles'> {
  iconSize: number | string
  iconColor?: string
}

const StyledSvg = styled.svg<StyledSvgProps>`
  width: ${({ iconSize }) =>
    typeof iconSize === 'string' ? `${iconSize}` : `${iconSize}px`};
  height: ${({ iconSize }) =>
    typeof iconSize === 'string' ? `${iconSize}` : `${iconSize}px`};

  fill: ${({ theme, iconColor }) => iconColor ?? theme.color.primary.primary01};

  color: ${({ theme, iconColor }) =>
    iconColor ?? theme.color.primary.primary01};

  ${({ additionalStyles }) => additionalStyles}
`

const SvgIcon: FunctionComponent<SvgIconProps> = (props) => {
  const { className, iconName, size, color, additionalStyles } = props
  return (
    <StyledSvg
      className={className}
      iconSize={size}
      iconColor={color}
      additionalStyles={additionalStyles}>
      <use href={`${svgSprite}#${iconName}`} />
    </StyledSvg>
  )
}

export default SvgIcon
