import { LayoutCardContent, LayoutCardStepsType } from './types'

interface GetStepsFromContentResult {
  steps: LayoutCardContent[]
  restContent: LayoutCardStepsType[]
}
const getStepsFromContent = (content: LayoutCardContent[]) => {
  const initialState: GetStepsFromContentResult = { steps: [], restContent: [] }
  return content.reduce((previousValue, child) => {
    if (child.kind.join('.') === 'layout.step') {
      previousValue?.steps.push(child)
    } else {
      previousValue?.restContent.push(child)
    }

    return previousValue
  }, initialState)
}

export { getStepsFromContent }
