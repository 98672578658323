import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { mobileCSS } from '@measures/responsive'

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  ${mobileCSS(css`
    align-self: flex-start;
  `)}
`

interface ArticleDatesProps {
  children?: ReactNode
  className?: string
}

const ArticleDates: FunctionComponent<ArticleDatesProps> = ({
  children,
  className,
}) => (
  <Wrapper className={className} suppressHydrationWarning={true}>
    {children}
  </Wrapper>
)

export default ArticleDates
