import { FunctionComponent } from 'react'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { ArticleTextBoxAPIProps } from './types'
import BrandBox from './layouts/BrandBox'
import DefaultArticleTextbox from './layouts/DefaultArticleTextbox'
import useArticleSchema from '@hooks/useArticleSchema'

const ArticleTextbox: FunctionComponent<ArticleTextBoxAPIProps> = ({
  body,
  title,
  buttonLabel,
  showButton,
  showExpanded,
  link,
  image,
  type,
}) => {
  const isPromo = type === 'promo'

  const { alignment: imageAlignment = 'full-width' } = image ?? {}

  const textForSchema = `${title ? `<h3>${title}</h3>` : ''}${body}`
  useArticleSchema({ text: textForSchema })

  if (!body && !isPromo) {
    return null
  }

  return (
    <>
      {isPromo ? (
        <BrandBox
          body={body}
          title={title}
          buttonLabel={buttonLabel}
          showButton={showButton}
          link={link}
          image={image}
          imageAlignment={imageAlignment}
        />
      ) : (
        <DefaultArticleTextbox
          body={body}
          title={title}
          buttonLabel={buttonLabel}
          showButton={showButton}
          showExpanded={showExpanded}
          link={link}
          image={image}
          imageAlignment={imageAlignment}
        />
      )}
    </>
  )
}

const widget = {
  kind: ['widget', 'article-textbox'],
  component: ArticleTextbox,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
