import { FunctionComponent, useContext, useState } from 'react'
import translate from '@i18n'
import GenericContext from '@contexts/commenting/genericContext'
import CommentingConfirmationMessage from './CommentingConfirmationMessage'
import CommentingItem from './CommentingItem'
import CommentingItemWrappper from './CommentingItemWrapper'
import CommentingActionPopup from './CommentingActionPopup'
import { getTitle } from './utils'
import useUser from '@hooks/useUser'
import { AnswerProps } from './types'
import AnswerAPIContext from '@contexts/commenting/answerApiContext'

const Answer: FunctionComponent<AnswerProps> = ({
  postingEnabled,
  onReply,
  answer,
}) => {
  const {
    id,
    author_type,
    created,
    body,
    father_id,
    user: {
      first_name,
      last_name,
      status_id: commentAuthorStatusId,
      sub: commentAuthorSub,
      gender,
    },
  } = answer

  const userData = useUser()
  const currentUserSub = userData?.sub || ''

  const { requireValidUser } = useContext(GenericContext)
  const { deleteAnswer, reportAnswer } = useContext(AnswerAPIContext)

  const isOwnItem = currentUserSub === commentAuthorSub
  const [deletingAnswer, setDeletingAnswer] = useState(false)
  const [reportingAnswer, setReportingAnswer] = useState(false)
  const [
    reportingConfirmationMessageShown,
    setReportingConfirmationMessageShown,
  ] = useState(false)

  return (
    <CommentingItemWrappper type="answer" itemId={id}>
      <CommentingItem
        type="answer"
        authorType={author_type}
        gender={gender}
        deleting={deletingAnswer}
        allowReplies={postingEnabled}
        isOwnItem={isOwnItem}
        authorName={getTitle({
          name: `${first_name} ${last_name}`,
          statusId: commentAuthorStatusId,
        })}
        datePublished={created}
        textContent={body}
        allowReports
        onDelete={requireValidUser(() => setDeletingAnswer(true))}
        onReport={requireValidUser(() => setReportingAnswer(true))}
        onReply={requireValidUser(() =>
          onReply(`${translate('commenting.to')} ${first_name} ${last_name}: `)
        )}
        commentOrAnswer={answer}
      />
      {reportingConfirmationMessageShown && (
        <CommentingConfirmationMessage
          onTimeout={() => setReportingConfirmationMessageShown(false)}>
          {translate('commenting.reportedMessage')}
        </CommentingConfirmationMessage>
      )}
      {deletingAnswer && (
        <CommentingActionPopup
          type="comment"
          actionType="delete"
          onConfirm={() => {
            setDeletingAnswer(false)
            deleteAnswer({ answerId: id, fatherId: father_id })
          }}
          onCancel={() => setDeletingAnswer(false)}
        />
      )}
      {reportingAnswer && (
        <CommentingActionPopup
          type="comment"
          actionType="report"
          onConfirm={(textValue = '') => {
            setReportingAnswer(false)
            setReportingConfirmationMessageShown(true)
            reportAnswer({ answerId: id, reason: textValue })
          }}
          onCancel={() => setReportingAnswer(false)}
        />
      )}
    </CommentingItemWrappper>
  )
}

export default Answer
