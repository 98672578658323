import { useCallback, useRef } from 'react'
import { AnimatedPreviewType } from './types'
import useAnimatedPreviewsList from './useAnimatedPreviewsList'
import useSetActiveAnimatedPreview from './useSetActiveAnimatedPreview'

import { isTheSameInstance, getNextCircularItem } from './utils'

type UseAnimatedPreviewsType = () => {
  registerAnimatedPreview: (animatedPreview: AnimatedPreviewType) => void
  deRegisterAnimatedPreview: (animatedPreview: AnimatedPreviewType) => void
  playAnimatedPreview: (animatedPreview: AnimatedPreviewType) => void
  playNextAnimatedPreview: () => void
}

const useAnimatedPreviews: UseAnimatedPreviewsType = () => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const setActiveAnimatedPreview = useSetActiveAnimatedPreview()
  const { getAnimatedPreviewsList, setAnimatedPreviewsList } =
    useAnimatedPreviewsList()

  const getNextAnimatedPreview = useCallback(
    (startIndex = -1) =>
      getNextCircularItem<AnimatedPreviewType>(
        getAnimatedPreviewsList(),
        startIndex
      ),
    [getAnimatedPreviewsList]
  )

  const autoPlayAnimatedPreview = useCallback(() => {
    // proceed only if we have animated previews on the page
    if (getAnimatedPreviewsList()?.length) {
      setActiveAnimatedPreview((activeAnimatedPreview) => {
        const animatedPreview =
          activeAnimatedPreview || getNextAnimatedPreview()

        if (animatedPreview) {
          return animatedPreview
        }
      })
    }
  }, [
    getAnimatedPreviewsList,
    getNextAnimatedPreview,
    setActiveAnimatedPreview,
  ])

  const registerAnimatedPreview = useCallback(
    (animatedPreview: AnimatedPreviewType) => {
      setAnimatedPreviewsList((animatedPreviews) => [
        ...animatedPreviews,
        animatedPreview,
      ])

      autoPlayAnimatedPreview()
    },
    [autoPlayAnimatedPreview, setAnimatedPreviewsList]
  )

  const deRegisterAnimatedPreview = useCallback(
    (animatedPreviewToRemove: AnimatedPreviewType) => {
      setAnimatedPreviewsList((animatedPreviews) =>
        animatedPreviews.filter(
          (animatedPreview) =>
            !isTheSameInstance(animatedPreview, animatedPreviewToRemove)
        )
      )

      autoPlayAnimatedPreview()
    },
    [autoPlayAnimatedPreview, setAnimatedPreviewsList]
  )

  const playAnimatedPreview = useCallback(
    (animatedPreview: AnimatedPreviewType) => {
      setActiveAnimatedPreview(animatedPreview)
    },
    [setActiveAnimatedPreview]
  )

  const playAnimatedPreviewWithDelay = useCallback(
    (animatedPreviewToPlay: AnimatedPreviewType) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
        timeoutRef.current = undefined
      }

      timeoutRef.current = setTimeout(() => {
        const animatedPreviewsList = getAnimatedPreviewsList()

        if (
          animatedPreviewsList.length === 1 &&
          isTheSameInstance(animatedPreviewsList[0], animatedPreviewToPlay)
        ) {
          setActiveAnimatedPreview((activeAnimatedPreview) => {
            if (!activeAnimatedPreview) {
              return animatedPreviewToPlay
            }

            return activeAnimatedPreview
          })
        }

        timeoutRef.current = undefined
      }, 5000)
    },
    [getAnimatedPreviewsList, setActiveAnimatedPreview]
  )

  const playNextAnimatedPreview = useCallback(() => {
    setActiveAnimatedPreview((activeAnimatedPreview) => {
      if (activeAnimatedPreview) {
        const animatedPreviewsList = getAnimatedPreviewsList()
        const activeAnimatedPreviewIndex = animatedPreviewsList.findIndex(
          (animatedPreview) =>
            isTheSameInstance(animatedPreview, activeAnimatedPreview)
        )

        const nextAnimatedPreview = getNextAnimatedPreview(
          activeAnimatedPreviewIndex
        )

        if (nextAnimatedPreview) {
          // prohibit previews from looping directly (should have a pause of about 5s
          // before the preview of the same teaser shows again).
          // this applies only to when we have one teaser in the viewport
          if (
            animatedPreviewsList.length === 1 &&
            isTheSameInstance(nextAnimatedPreview, activeAnimatedPreview)
          ) {
            playAnimatedPreviewWithDelay(nextAnimatedPreview)
            return null
          }

          return nextAnimatedPreview
        }
      }
    })
  }, [
    getAnimatedPreviewsList,
    getNextAnimatedPreview,
    playAnimatedPreviewWithDelay,
    setActiveAnimatedPreview,
  ])

  return {
    registerAnimatedPreview,
    deRegisterAnimatedPreview,
    playAnimatedPreview,
    playNextAnimatedPreview,
  }
}

export default useAnimatedPreviews
