import Blink from '@components/Blink'
import config from '@config'
import translate from '@i18n'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

const {
  subscriptions: { faqsLink },
} = config

const StyledLinkToFaqs = styled(Blink)`
  ${({
    theme: {
      typography: {
        bodycopy: {
          xxsmall: { bundledCSS: bodycopyXXSmallCSS },
        },
      },
      color: {
        primary: { primary01 },
      },
    },
  }) => css`
    ${bodycopyXXSmallCSS};
    color: ${primary01};
    display: block;
    align-self: flex-start;
  `}
`

const LinkToFaqs: FunctionComponent = () => (
  <StyledLinkToFaqs target="blank" href={faqsLink}>
    {translate('blickPlus.linkSharing.linkToFaqs')}
  </StyledLinkToFaqs>
)

export default LinkToFaqs
