import { FunctionComponent } from 'react'
import styled from 'styled-components'
import logoZattoo from '@assets/images/zattoo_brand.png'

const StyledZattooLogoImage = styled.div`
  position: absolute;
  background: url(${logoZattoo}) no-repeat center;
  background-size: contain;
  width: 75px;
  height: 29px;
  right: 10px;
  bottom: 10px;
  display: block;
`

const ZattooLogoImage: FunctionComponent = () => <StyledZattooLogoImage />

export default ZattooLogoImage
