import { ConfigProps } from '@config/types'

const config = ({}: ConfigProps) => {
  const prolitterisBaseUrl = 'https://pl02.owen.prolitteris.ch'
  return {
    normalArticlesUrlPrefix: `${prolitterisBaseUrl}/na/vzm.13447-`,
    blickPlusArticlesUrlPrefix: `${prolitterisBaseUrl}/pw/vzm.13447-`,
  } as const satisfies Record<string, unknown>
}

export default config
