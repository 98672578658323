import useIsSwissGeolocation from '@hooks/useIsSwissGeolocation'
import { VideoWidgetAPIProps } from '@widgets/Video/types'

type UseIsVideoGeoblockedType = (
  geoblocking?: VideoWidgetAPIProps['geoblocking']
) => boolean

const GEOBLOCKED_VALUES = [
  true,
  'Nur in der Schweiz zu sehen',
  'Nicht in Deutschland zu sehen',
  'block_all_except_switzerland',
  'block_germany',
] as const

const getIsGeoblocked = (
  geoblockingValue: Parameters<UseIsVideoGeoblockedType>[0]
) => GEOBLOCKED_VALUES.some((value) => geoblockingValue === value)

const useIsVideoGeoblocked: UseIsVideoGeoblockedType = (geoblocking) =>
  !useIsSwissGeolocation() && getIsGeoblocked(geoblocking)

export default useIsVideoGeoblocked
