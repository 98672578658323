import { FunctionComponent } from 'react'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import translate, { Translations } from '@i18n'
import SectionHeader from '@components/SectionHeader'
import { useTheme } from 'styled-components'

interface TitleProps {
  id: keyof Translations
}

const Title: FunctionComponent<TitleProps> = ({ id }) => {
  const theme = useTheme()
  return (
    <SectionHeader
      title={translate(id)}
      isSubheader={true}
      accentColor={theme.color.primary.blickRed}
    />
  )
}

const widget = {
  kind: ['widget', 'title'],
  component: Title,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
