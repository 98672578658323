import {
  ChatbotLastQuestion,
  ChatbotMessage,
  ChatbotQuestionData,
} from '@components/Chatbot/types'
import { trackHandler } from '@components/Chatbot/utils'
import useBodyScroll from '@hooks/useBodyScroll'
import useExecuteOnClientNavigation from '@hooks/useExecuteOnClientNavigation'
import useIsUserLoggedIn from '@hooks/useIsUserLoggedIn'
import usePageMetadata from '@hooks/usePageMetadata'
import useTracking from '@hooks/useTracking'
import useViewportType from '@hooks/useViewport/useViewportType'
import { useQueryClient } from '@tanstack/react-query'
import {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import useChatbotVisible from './useChatbotVisible'
import useChatbotLastQuestion from './useChatbotLastQuestion'

const SCROLL_CONTAINER_CLASS_NAME = 'chatbot-scroll-container'

const useChatbot = () => {
  const queryClient = useQueryClient()
  const queryCache = queryClient.getQueryCache()
  const unsubscribeFnRef = useRef<(() => void)[]>([])
  const isChatbotVisible = useChatbotVisible()
  const chatbotLastQuestion = useChatbotLastQuestion()
  const loggedIn = useIsUserLoggedIn()
  const pageMetadata = usePageMetadata()
  const viewportType = useViewportType()
  const isMobile = viewportType === 'mobile'
  const { lockBodyScroll, unlockBodyScroll } = useBodyScroll()
  const handleTracking = useTracking(trackHandler)

  const [chatbotInitialLoading, setChatbotInitialLoading] = useState(
    queryClient.getQueryData<boolean>(['chatbotInitialLoading'])
  )

  const [chatbotConversationId, setChatbotConversationId] = useState(
    queryClient.getQueryData<string | undefined>(['chatbotConversationId'])
  )

  const [chatbotInitialQuestions, setChatbotInitialQuestions] = useState(
    queryClient.getQueryData<ChatbotQuestionData[]>([
      'chatbotInitialQuestions',
    ]) || []
  )

  const [chatbotMessages, setChatbotMessages] = useState<ChatbotMessage[]>(
    queryClient.getQueryData<ChatbotMessage[]>(['chatbotMessages']) || []
  )

  const [chatbotAnswerLoading, setChatbotAnswerLoading] = useState(
    queryClient.getQueryData<boolean>(['chatbotAnswerLoading'])
  )
  const showLoginBox = useMemo(
    () => !loggedIn && !chatbotAnswerLoading && chatbotMessages.length > 1,
    [loggedIn, chatbotAnswerLoading, chatbotMessages]
  )

  const openChatbot = useCallback(() => {
    queryClient.setQueryData(['chatbotVisible'], true)
    queryClient.invalidateQueries({
      queryKey: ['chatbotVisible'],
    })

    handleTracking({ event: 'chatbot_open' })

    if (isMobile) {
      lockBodyScroll()
    }

    if (chatbotMessages.length) {
      const question = JSON.parse(
        sessionStorage.getItem('question-old') || 'null'
      )
      if (question) {
        sessionStorage.setItem('question', JSON.stringify(question))
        sessionStorage.removeItem('question-old')
      }
    }
  }, [queryClient, chatbotMessages, isMobile, lockBodyScroll, handleTracking])

  const chatbotCloseCleanup = () => {
    if (isMobile) {
      unlockBodyScroll()
    }

    const question = JSON.parse(sessionStorage.getItem('question') || 'null')
    if (question) {
      sessionStorage.setItem('question-old', JSON.stringify(question))
      sessionStorage.removeItem('question')
    }
  }

  const closeChatbot = () => {
    queryClient.setQueryData(['chatbotVisible'], false)
    queryClient.invalidateQueries({
      queryKey: ['chatbotVisible'],
    })

    chatbotCloseCleanup()
  }

  useExecuteOnClientNavigation(chatbotCloseCleanup)

  const onQuestionClick = (
    selectedQuestion: ChatbotQuestionData,
    conversationId?: string
  ) => {
    queryClient.setQueryData<ChatbotLastQuestion>(['chatbotLastQuestion'], {
      data: selectedQuestion,
      chatbotConversationId: conversationId,
    })
    queryClient.invalidateQueries({
      queryKey: ['chatbotLastQuestion'],
    })

    if (!isChatbotVisible) {
      openChatbot()
    }

    if (
      sessionStorage &&
      !loggedIn &&
      selectedQuestion?.isSuggestedQuestion &&
      selectedQuestion?.isCached
    ) {
      sessionStorage.setItem('question', JSON.stringify(selectedQuestion))
    }
  }

  const onQuestionSubmit = (question: string, conversationId?: string) => {
    queryClient.setQueryData<ChatbotLastQuestion>(['chatbotLastQuestion'], {
      data: {
        id: `user-question-${new Date().getTime()}`,
        text: question,
        isCached: false,
        isSuggestedQuestion: false,
        context: pageMetadata?.id || 'home',
      },
      chatbotConversationId: conversationId,
    })
    queryClient.invalidateQueries({
      queryKey: ['chatbotLastQuestion'],
    })

    if (!isChatbotVisible) {
      openChatbot()
    }
  }

  const resetMessages = () => {
    queryClient.setQueryData(['chatbotMessages'], [])
    queryClient.invalidateQueries({
      queryKey: ['chatbotMessages'],
    })
  }

  const isLastQuestion = (id: string) => {
    return chatbotLastQuestion?.data.context === id
  }

  const updateChabotCommon = useCallback(
    (
      args: any,
      key: string,
      setState: (value: SetStateAction<any | undefined>) => void
    ) => {
      if (
        args?.action?.type === 'invalidate' &&
        args.query.queryKey[0] === key
      ) {
        setState(queryClient.getQueryData([key]))
      }
    },
    [queryClient]
  )

  useEffect(() => {
    const unsubscribeFnRefCurrent = unsubscribeFnRef.current

    unsubscribeFnRefCurrent?.push(
      queryCache.subscribe((args: any) =>
        updateChabotCommon(
          args,
          'chatbotInitialLoading',
          setChatbotInitialLoading
        )
      )
    )

    unsubscribeFnRefCurrent?.push(
      queryCache.subscribe((args: any) =>
        updateChabotCommon(
          args,
          'chatbotConversationId',
          setChatbotConversationId
        )
      )
    )

    unsubscribeFnRefCurrent?.push(
      queryCache.subscribe((args: any) =>
        updateChabotCommon(
          args,
          'chatbotInitialQuestions',
          setChatbotInitialQuestions
        )
      )
    )

    unsubscribeFnRefCurrent?.push(
      queryCache.subscribe((args: any) =>
        updateChabotCommon(args, 'chatbotMessages', setChatbotMessages)
      )
    )

    unsubscribeFnRefCurrent?.push(
      queryCache.subscribe((args: any) =>
        updateChabotCommon(
          args,
          'chatbotAnswerLoading',
          setChatbotAnswerLoading
        )
      )
    )

    return () => {
      if (unsubscribeFnRefCurrent) {
        unsubscribeFnRefCurrent.forEach((unsubscribe) => unsubscribe())
      }
    }
  }, [queryCache, updateChabotCommon])

  useEffect(() => {
    if (!chatbotLastQuestion && loggedIn) {
      const question = JSON.parse(sessionStorage.getItem('question') || 'null')
      if (question) {
        openChatbot()
        queryClient.setQueryData<ChatbotLastQuestion>(['chatbotLastQuestion'], {
          data: question,
        })
        queryClient.invalidateQueries({
          queryKey: ['chatbotLastQuestion'],
        })
        sessionStorage.removeItem('question')
      }
    }
  }, [loggedIn, queryClient, chatbotLastQuestion, openChatbot])

  return {
    openChatbot,
    closeChatbot,
    chatbotInitialLoading,
    chatbotConversationId,
    chatbotInitialQuestions,
    onQuestionClick,
    onQuestionSubmit,
    chatbotMessages,
    chatbotAnswerLoading,
    showLoginBox,
    SCROLL_CONTAINER_CLASS_NAME,
    resetMessages,
    isLastQuestion,
  }
}

export default useChatbot
