import { FunctionComponent, useContext } from 'react'
import styled, { css } from 'styled-components'
import contextArticleTextbox, {
  ArticleTextboxContextType,
} from '@contexts/articleTextbox'
import { ImageTextWrapperProps } from '@widgets/ArticleTextbox/types'
import { desktopCSS, tabletCSS, mobileCSS } from '@measures/responsive'

const { ArticleTextboxContext } = contextArticleTextbox

type StyledImageTextWrapperProps = Pick<
  ImageTextWrapperProps,
  'alignment' | 'noTextHalfWidth'
> &
  Pick<ArticleTextboxContextType, 'hasControls' | 'isPromo'>

const StyledImageTextWrapper = styled.div<StyledImageTextWrapperProps>`
  ${({
    alignment,
    hasControls,
    isPromo,
    noTextHalfWidth,
    theme: {
      spacing: { spacing24, spacing4, spacing16, spacing20, spacing32 },
    },
  }) => css`
    position: relative;
    ${desktopCSS(css`
      ${alignment === 'full-width' || noTextHalfWidth
        ? css`
            width: 100%;
            padding-top: ${hasControls ? spacing24 : 0};
            padding-bottom: ${hasControls ? 0 : spacing24};
            ${noTextHalfWidth &&
            css`
              padding-bottom: 0;
              margin-bottom: ${spacing24};
            `};
          `
        : css`
            width: 50%;
            float: right;
            padding: ${spacing4} 0 ${spacing16} ${spacing32};
          `};
    `)}
    ${tabletCSS(css`
      ${alignment === 'full-width' || noTextHalfWidth
        ? css`
            width: 100%;
            padding-top: ${hasControls ? spacing24 : 0};
            padding-bottom: ${hasControls ? 0 : spacing24};
            ${noTextHalfWidth &&
            css`
              padding-bottom: 0;
              margin-bottom: ${spacing24};
            `};
          `
        : css`
            width: 50%;
            float: right;
            padding: ${spacing4} 0 ${spacing16} ${spacing32};
          `};
    `)}
    ${mobileCSS(css`
      width: 100%;
      padding-top: ${hasControls ? spacing24 : 0};
      padding-bottom: ${hasControls ? 0 : isPromo ? spacing20 : spacing24};
    `)}
  `}
`

const ImageTextWrapper: FunctionComponent<ImageTextWrapperProps> = ({
  alignment,
  noTextHalfWidth,
  children,
}) => {
  const { hasControls, isPromo } = useContext(ArticleTextboxContext)

  return (
    <StyledImageTextWrapper
      alignment={alignment}
      hasControls={hasControls}
      isPromo={isPromo}
      noTextHalfWidth={noTextHalfWidth}>
      {children}
    </StyledImageTextWrapper>
  )
}

export default ImageTextWrapper
