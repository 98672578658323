import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'

const StyledWrapper = styled.div`
  ${({
    theme: {
      color: {
        primary: { primary01 },
      },
      spacing: { spacing12, spacing16 },
    },
  }) => {
    return css`
      background-color: ${primary01};
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      padding: ${spacing12} ${spacing16};
    `
  }}
`

const Wrapper: FunctionComponent<{ children?: ReactNode }> = ({ children }) => (
  <StyledWrapper>{children}</StyledWrapper>
)

export default Wrapper
