import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import translate from '@i18n'
import SummaryHeader from '@components/Summary/SummaryHeader'
import PrimaryCTAButton from '@components/Buttons/PrimaryCTAButton'

const BetaBadge = styled.div`
  ${({
    theme: {
      color: {
        primary: { primary02, blickRed },
      },
      typography: {
        subheads: {
          xsmall2: { bundledCSS: xsmall2 },
        },
      },
      spacing: { spacing4 },
    },
  }) => css`
    color: ${primary02};
    background-color: ${blickRed};
    text-align: center;
    padding: 0 ${spacing4};
    border-radius: ${spacing4};
    height: fit-content;
    position: relative;
    top: -1px;
    ${xsmall2}
  `}
`

const Paragraph = styled.p`
  ${({
    theme: {
      spacing: { spacing24 },
      typography: {
        subheads: { large2 },
      },
    },
  }) => css`
    ${large2}
    margin: ${spacing24} 0 0 0;
  `}
`

const SummaryRequestView: FunctionComponent<{
  title: string
  text: string
  onPrimaryClick: () => void
}> = ({ title, text, onPrimaryClick }) => {
  return (
    <>
      <SummaryHeader prefixIconName="stars">
        {title}
        <BetaBadge>Beta</BetaBadge>
      </SummaryHeader>
      <Paragraph>{text}</Paragraph>
      <Paragraph>
        <PrimaryCTAButton type="button" size="large" onClick={onPrimaryClick}>
          {translate('sectionSummary.request.primaryButton')}
        </PrimaryCTAButton>
      </Paragraph>
    </>
  )
}

export default SummaryRequestView
