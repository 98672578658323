import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import ArticleTextboxComponents from '@components/ArticleTextbox'
import translate from '@i18n'
import ArticleTextboxContextProvider from '../ArticleTextboxContextProvider'
import TextBoxImageWrapper from '../TextBoxImageWrapper'
import { DefaultArticleTextboxProps } from '../types'
import ShowMoreButton from '@components/Buttons/ShowMoreButton'
import FadedExpandableTextWrapper from '@components/FadedExpandableTextWrapper'

const {
  ActionLink,
  ContentWrapper,
  ExpandableContentWrapper,
  TextboxFadeOverlay,
  ImageComponentWrapper,
  TextWrapper,
  Title,
  Wrapper,
} = ArticleTextboxComponents

const DefaultArticleTextbox: FunctionComponent<DefaultArticleTextboxProps> = ({
  body,
  title,
  buttonLabel,
  showButton,
  showExpanded,
  link,
  image,
  imageAlignment,
}) => {
  const hasControls = !showExpanded

  const isInitialExpandedValueSet = useRef<boolean>(false)
  const [isExpanded, setIsExpanded] = useState<boolean>(!hasControls)

  const onExpand = useCallback<TrackingFnType>(
    () => ({
      event: 'textbox_expand',
      eventCategory: 'textbox_expand',
      eventAction: title,
      eventLabel: translate('more'),
    }),
    [title]
  )

  const onCollapse = useCallback<TrackingFnType>(
    () => ({
      event: 'textbox_collapse',
      eventCategory: 'textbox_collapse',
      eventAction: title,
      eventLabel: translate('less'),
    }),
    [title]
  )

  const trackOnExpand = useTracking(onExpand)
  const trackOnCollapse = useTracking(onCollapse)

  const toggleExpanded = useCallback(() => {
    setIsExpanded((isExpanded) => !isExpanded)
  }, [])

  useEffect(() => {
    if (isInitialExpandedValueSet.current) {
      if (isExpanded) {
        trackOnExpand()
      } else {
        trackOnCollapse()
      }
    } else {
      isInitialExpandedValueSet.current = true
    }
  }, [isExpanded, trackOnExpand, trackOnCollapse])

  const WrapperComponent = hasControls
    ? ExpandableContentWrapper
    : ContentWrapper

  return (
    <ArticleTextboxContextProvider
      hasControls={hasControls}
      imageAlignment={imageAlignment}
      isExpanded={isExpanded}
      isPromo={false}>
      <Wrapper>
        <WrapperComponent>
          {!hasControls && imageAlignment === 'full-width' ? (
            <ImageComponentWrapper>
              <TextBoxImageWrapper image={image} />
            </ImageComponentWrapper>
          ) : null}
          {!hasControls && imageAlignment === 'half-width' ? (
            <ImageComponentWrapper hideWhenHalfWidthOnMobile={false}>
              <TextBoxImageWrapper image={image} />
            </ImageComponentWrapper>
          ) : null}
          <Title>{title}</Title>
          <TextWrapper hideOnMobile={false}>{body}</TextWrapper>
          {imageAlignment === 'half-width' ? (
            <ImageComponentWrapper hideWhenHalfWidthOnMobile={true}>
              <TextBoxImageWrapper image={image} />
            </ImageComponentWrapper>
          ) : null}
          <TextWrapper hideOnMobile={true}>{body}</TextWrapper>
          {hasControls && imageAlignment === 'full-width' ? (
            <ImageComponentWrapper>
              <TextBoxImageWrapper image={image} />
            </ImageComponentWrapper>
          ) : null}
          {showButton ? (
            <ActionLink
              blinkProps={{ ...link, inTextBox: true, brandedBox: false }}>
              {buttonLabel ? buttonLabel : translate('submit')}
            </ActionLink>
          ) : null}
        </WrapperComponent>
        {hasControls ? (
          <FadedExpandableTextWrapper>
            <TextboxFadeOverlay />
            <ShowMoreButton
              onClick={toggleExpanded}
              isMore={!isExpanded}
              size="large"
            />
          </FadedExpandableTextWrapper>
        ) : null}
      </Wrapper>
    </ArticleTextboxContextProvider>
  )
}

export default DefaultArticleTextbox
