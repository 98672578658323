import { useState, useCallback, useEffect } from 'react'
import { fetchVideoMetadata, getMotionThumbnailSource } from './utils'
import {
  UseVideoMetadataInputProps,
  UseVideoMetadataOutputProps,
  VideoMetadataPlaylist,
  VideoMetadataResponse,
} from './types'

const useVideoMetadataFetching = ({
  jwVideoId,
}: UseVideoMetadataInputProps): UseVideoMetadataOutputProps => {
  const [videoMetadataPlaylist, setVideoMetadata] = useState<
    VideoMetadataPlaylist | undefined
  >()

  const getVideoMetadata = useCallback(() => {
    fetchVideoMetadata(jwVideoId).then((res) => {
      const response = res as VideoMetadataResponse
      setVideoMetadata(response?.playlist?.[0])
    })
  }, [jwVideoId])

  useEffect(() => {
    if (jwVideoId && !videoMetadataPlaylist) {
      getVideoMetadata()
    }
  }, [videoMetadataPlaylist, getVideoMetadata, jwVideoId])

  return {
    fetchedPosterUrl: videoMetadataPlaylist?.image,
    fetchedSourceUrl: videoMetadataPlaylist?.sources[0]?.file,
    fetchedMotionThumbnailUrl: getMotionThumbnailSource(
      videoMetadataPlaylist?.images
    ),
  }
}

export default useVideoMetadataFetching
