import RecipeInfoItem from './RecipeInfo/RecipeInfoItem'
import RecipeInfoWrapper from './RecipeInfo/RecipeInfoWrapper'
import IngredientItem from './IngredientsItems/IngredientItem'
import IngredientsWrapper from './IngredientsItems/IngredientsWrapper'

const exports = {
  RecipeInfoWrapper,
  RecipeInfoItem,
  IngredientItem,
  IngredientsWrapper,
}

export default exports
