import { FunctionComponent } from 'react'
import ArticleTextboxComponents from '@components/ArticleTextbox'
import { ArticleTextBoxImage } from './types'
import TextboxImage from './TextboxImage'

const { Caption, CaptionCreditWrapper, Credit, ImageTextWrapper } =
  ArticleTextboxComponents

interface TextBoxImageWrapperProps {
  image?: ArticleTextBoxImage
  noTextHalfWidth?: boolean
  isPromo?: boolean
}

const TextBoxImageWrapper: FunctionComponent<TextBoxImageWrapperProps> = ({
  image,
  noTextHalfWidth = false,
  isPromo = false,
}) => {
  const {
    caption: imageCaption,
    credit: imageCredit,
    alignment: imageAlignment = 'full-width',
  } = image ?? {}

  if (!image) return null

  return (
    <ImageTextWrapper
      alignment={imageAlignment}
      noTextHalfWidth={noTextHalfWidth}>
      <TextboxImage isPromo={isPromo}>{image}</TextboxImage>
      <CaptionCreditWrapper>
        {imageCaption ? <Caption>{imageCaption}</Caption> : null}
        {imageCredit?.text && image?.credit?.link?.href ? (
          <Credit
            blinkProps={{
              ...image.credit.link,
              inTextBox: true,
              brandedBox: false,
            }}>
            {imageCredit.text}
          </Credit>
        ) : null}
        {imageCredit?.text ? <Credit>{imageCredit.text}</Credit> : null}
      </CaptionCreditWrapper>
    </ImageTextWrapper>
  )
}

export default TextBoxImageWrapper
