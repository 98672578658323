import { FunctionComponent, ReactNode, useContext, useEffect } from 'react'
import CommentingContext from './apiContext'
import GenericCommentingContext from './genericContext'
import config from '@config'
import {
  APICommentingData,
  Reactions,
  Reaction,
  QueryCommentingData,
} from '@widgets/Commenting/types'
import {
  InfiniteData,
  QueryFunctionContext,
  QueryKey,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import useUser from '@hooks/useUser'

const {
  community: {
    baseUrl,
    getUrl,
    postUrl,
    deleteUrl,
    reportUrl,
    reactionsUrl,
    reactUrl,
  },
} = config

interface FetchParams extends Omit<QueryFunctionContext, 'pageParam'> {
  pageParam?: number
  id: string
}

const getCommentingData = async (
  fetchParams: FetchParams
): Promise<APICommentingData> => {
  const articleId = fetchParams.id
  const page = fetchParams.pageParam || 0
  const response = await fetch(
    `${baseUrl}${getUrl}/?page=${page}&discussion_type_id=${articleId}`
  )
  if (response.status >= 400 && response.status < 600) {
    throw new Error(`Server responded with ${response.status}`)
  }
  return await response.json()
}

const getUserReactions = async ({
  articleId,
}: {
  articleId: string
}): Promise<Reactions> => {
  const getReactionsFullUrl = reactionsUrl.replace('{articleId}', articleId)
  const response = await fetch(`${baseUrl}${getReactionsFullUrl}`, {
    credentials: 'include',
  })
  if (response.status >= 400 && response.status < 600) {
    throw new Error(`Server responded with ${response.status}`)
  }
  return await response.json()
}

const postComment = async ({
  articleId,
  body,
}: {
  articleId: string
  body: string
}) => {
  const bodyPayload = {
    discussion_type_id: articleId,
    body,
  }
  const response = await fetch(`${baseUrl}${postUrl}`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyPayload),
  })
  if (response.status >= 400 && response.status < 600) {
    throw new Error(`Server responded with ${response.status}`)
  }
  return await response.json()
}

const reportComment = async ({
  commentId,
  reason,
}: {
  commentId: number
  reason: string
}) => {
  const response = await fetch(`${baseUrl}${reportUrl}`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      text: reason,
      comment_id: commentId,
    }),
  })
  if (response.status >= 400 && response.status < 600) {
    throw new Error(`Server responded with ${response.status}`)
  }
  return await response.json()
}

const deleteComment = async ({ commentId }: { commentId: number }) => {
  const deleteFullUrl = deleteUrl.replace('{id}', commentId.toString())
  const response = await fetch(`${baseUrl}${deleteFullUrl}`, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  if (response.status >= 400 && response.status < 600) {
    throw new Error(`Server responded with ${response.status}`)
  }
  return response
}

const reactOnComment = async ({
  commentId,
  reaction,
  set,
}: {
  commentId: number
  reaction: Reaction
  set: boolean
}) => {
  const reactFullUrl = reactUrl.replace('{id}', commentId.toString())
  const bodyPayload = {
    value: reaction,
    set,
  }

  let response
  try {
    response = await fetch(`${baseUrl}${reactFullUrl}`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bodyPayload),
    })
  } catch (err) {
    console.error('An error happened while deleting the comment:', err)
  }

  return response
}

const deleteCommentFromCommentingData = (
  commentId: number,
  oldCommentingData: { pages: APICommentingData[] }
) => {
  const oldPages = oldCommentingData.pages

  const newPages = oldPages.map((page) => {
    if (page.content.find((comment) => comment.id === commentId)) {
      const newPageContent = page.content.filter(
        (comment) => comment.id !== commentId
      )
      return { ...page, content: newPageContent }
    }
    return page
  })

  return { ...oldCommentingData, pages: newPages }
}

const CommentingContextProvider: FunctionComponent<{
  children?: ReactNode
}> = ({ children }) => {
  const queryClient = useQueryClient()
  const { articleId, commentsEnabled } = useContext(GenericCommentingContext)
  const userData = useUser()

  useEffect(() => {
    return () => {
      queryClient.removeQueries({
        queryKey: ['commentingData', articleId],
        exact: true,
      })
      queryClient.removeQueries({
        queryKey: ['reactions', articleId],
        exact: true,
      })
    }
  }, [articleId, queryClient])

  const {
    data: apiCommentingData,
    fetchNextPage,
    error: apiCommentingError,
  } = useInfiniteQuery<
    APICommentingData,
    Error,
    InfiniteData<APICommentingData>,
    QueryKey,
    number
  >({
    queryKey: ['commentingData', articleId],
    queryFn: (fetchParams) =>
      getCommentingData({ ...fetchParams, id: articleId }),
    getNextPageParam: (lastResponse) => lastResponse.number + 1,
    initialPageParam: 0,
    enabled: !!commentsEnabled,
  })

  const { data: reactionsData, error: reactionsError } = useQuery<Reactions>({
    queryKey: ['reactions', articleId],
    queryFn: () => getUserReactions({ articleId }),
    enabled: !!commentsEnabled && !!userData,
  })

  const postCommentMutation = useMutation({ mutationFn: postComment })

  const { mutate: deleteCommentMutate } = useMutation({
    mutationFn: deleteComment,
    onMutate: async ({ commentId }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: ['commentingData', articleId],
      })

      // Snapshot the previous value
      const previousCommentingData = queryClient.getQueryData<
        QueryCommentingData | undefined
      >(['commentingData', articleId])

      // Optimistically update to the new value
      queryClient.setQueryData<QueryCommentingData | undefined>(
        ['commentingData', articleId],
        (oldData) => {
          if (!oldData) {
            return
          }
          return deleteCommentFromCommentingData(commentId, oldData)
        }
      )

      // Return a context object with the snapshotted value
      return { previousCommentingData }
    },
    onError: (error) => {
      console.error('An error happened while deleting the comment:', error)
    },
  })

  const deletePostedComment = (payload: { commentId: number }) => {
    postCommentMutation.reset()
    deleteComment(payload)
  }

  return (
    <CommentingContext.Provider
      value={{
        comments: apiCommentingError
          ? []
          : (apiCommentingData as QueryCommentingData)?.pages.reduce(
              (acc, page) => [...acc, ...page.content] as Comment[],
              [] as Comment[]
            ),
        deleteComment: deleteCommentMutate,
        deletePostedComment,
        fetchNextPage: () => fetchNextPage(),
        moreToShow:
          !apiCommentingData?.pages[apiCommentingData?.pages.length - 1].last,
        postedComment: postCommentMutation.data,
        postComment: postCommentMutation.mutate,
        reportComment,
        reactions: reactionsError ? {} : reactionsData,
        initialReactions: reactionsData,
        reactOnComment,
      }}>
      {children}
    </CommentingContext.Provider>
  )
}

export default CommentingContextProvider
