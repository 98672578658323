import { FunctionComponent } from 'react'
import styled, { css, useTheme } from 'styled-components'
import SvgIcon from '../SvgIcon'
import { SvgIconName } from '../SvgIcon/types'

const SummaryHeaderStyled = styled.div`
  ${({
    theme: {
      spacing: { spacing8, spacing16 },
    },
  }) => css`
    align-items: center;
    column-gap: ${spacing8};
    display: flex;
    margin: 0 0 ${spacing16} 0;
    text-transform: uppercase;
  `}
`

const SummaryTitleStyled = styled.h3<{ isCollapsed: boolean }>`
  ${({
    theme: {
      typography: {
        headings: {
          small: { bundledCSS: smallHeading },
        },
      },
      spacing: { spacing8 },
    },
  }) => css`
    display: flex;
    align-items: center;
    text-transform: none;
    gap: ${spacing8};
    line-height: 1;
    margin: 0;
    text-transform: uppercase;

    ${smallHeading}
    height: auto;
  `}
`

const SummaryHeader: FunctionComponent<{
  isCollapsed?: boolean
  prefixIconName?: SvgIconName
  after?: React.ReactNode
  children?: React.ReactNode
}> = ({ prefixIconName, after, isCollapsed = false, children }) => {
  const theme = useTheme()
  return (
    <SummaryHeaderStyled>
      {prefixIconName && (
        <SvgIcon
          iconName={prefixIconName}
          size={24}
          color={theme.color.primary.primary01}
        />
      )}

      <SummaryTitleStyled isCollapsed={isCollapsed}>
        {children}
      </SummaryTitleStyled>

      {after}
    </SummaryHeaderStyled>
  )
}

export default SummaryHeader
