import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'

const StyledTitle = styled.span`
  ${({
    theme: {
      typography: {
        headings: {
          xsmall: { bundledCSS: xsmallHeading },
        },
      },
      color: {
        primary: { primary02 },
      },
      spacing: { spacing8 },
    },
  }) => {
    return css`
      ${xsmallHeading};
      color: ${primary02};
      margin-right: ${spacing8};
    `
  }}
`

const Title: FunctionComponent<{ children?: ReactNode }> = ({ children }) => (
  <StyledTitle>{children}</StyledTitle>
)

export default Title
