import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent, useCallback, useState } from 'react'

import ArticleTextboxContextProvider from '@widgets/ArticleTextbox/ArticleTextboxContextProvider'
import ArticleTextboxComponents from '@components/ArticleTextbox'
import FadedExpandableTextWrapper from '@components/FadedExpandableTextWrapper'
import ShowMoreButton from '@components/Buttons/ShowMoreButton'
import Form from './Form'
import styled, { css } from 'styled-components'

const { ExpandableContentWrapper, TextboxFadeOverlay, Title, Wrapper } =
  ArticleTextboxComponents

const DescriptionText = styled.div`
  ${({
    theme: {
      typography: {
        bodycopy: {
          small2: { bundledCSS: bodyCopySmall2CSS },
        },
      },
    },
  }) => css`
    ${bodyCopySmall2CSS};
  `}
`

const RewardText = styled.div`
  ${({
    theme: {
      typography: {
        bodycopy: {
          small2: { bundledCSS: bodyCopySmall2CSS },
        },
      },
      spacing: { spacing8, spacing20 },
    },
  }) => css`
    ${bodyCopySmall2CSS};
    font-style: italic;
    margin-top: ${spacing20};
    padding-bottom: ${spacing8};
  `}
`

const Leserreporter: FunctionComponent = () => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  const toggleExpanded = useCallback(() => {
    setIsExpanded((isExpanded) => !isExpanded)
  }, [])

  return (
    <ArticleTextboxContextProvider
      imageAlignment="full-width"
      hasControls={true}
      isExpanded={isExpanded}
      isPromo={false}>
      <Wrapper>
        <ExpandableContentWrapper>
          <Title>Werde Leserreporter - nutze das Web-Formular!</Title>
          <DescriptionText>
            Etwas gesehen oder gehört? Schicke uns dein Bild oder Video per
            WhatsApp an 079 813 80 41 oder nutze hier das Formular.
          </DescriptionText>
          <RewardText>
            Blick belohnt dich bei Veröffentlichung mit 50 Franken.
          </RewardText>
          <Form />
        </ExpandableContentWrapper>
        <FadedExpandableTextWrapper>
          <TextboxFadeOverlay />
          {!isExpanded && (
            <ShowMoreButton
              onClick={toggleExpanded}
              isMore={true}
              size="large"
            />
          )}
        </FadedExpandableTextWrapper>
      </Wrapper>
    </ArticleTextboxContextProvider>
  )
}

const widget = {
  kind: ['widget', 'tool', 'leserreporter'],
  component: Leserreporter,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
