import {
  FunctionComponent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled, { css } from 'styled-components'

import useActiveAnimatedPreview from './useActiveAnimatedPreview'
import useAnimatedPreviews from './useAnimatedPreviews'
import { getVideoPreviewURL } from './utils'
import config from '@config'

const {
  video: { transparentPoster },
} = config

type StyledVideoProps = {
  isVisible?: boolean
}

type AnimatedPreviewProps = {
  videoId: string
  articleUrl: string
}

const StyledVideo = styled.video<StyledVideoProps>`
  ${({ isVisible }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;
    background-color: black;
    opacity: ${isVisible ? 1 : 0};
  `}
`

const AnimatedPreview: FunctionComponent<AnimatedPreviewProps> = ({
  videoId,
  articleUrl,
}) => {
  const activeAnimatedPreview = useActiveAnimatedPreview()
  const { playNextAnimatedPreview } = useAnimatedPreviews()

  const [isVideoPreviewVisible, setIsVideoPreviewVisible] = useState(false)

  const videoRef = useRef<HTMLVideoElement | null>(null)
  const prevTimeRef = useRef<number>(0)

  const hasActivePreview =
    activeAnimatedPreview?.videoId === videoId &&
    activeAnimatedPreview?.articleUrl === articleUrl

  const previewUrl = useMemo(() => getVideoPreviewURL(videoId, 640), [videoId])

  useEffect(() => {
    if (!hasActivePreview) {
      setIsVideoPreviewVisible(false)
    }
  }, [hasActivePreview])

  const onPlay = useCallback(() => {
    setIsVideoPreviewVisible(true)
  }, [])

  const onVideoEnded = useCallback(() => {
    playNextAnimatedPreview()
  }, [playNextAnimatedPreview])

  const onTimeUpdate = useCallback(
    (event: SyntheticEvent<HTMLVideoElement, Event>) => {
      const videoElement = event.target as HTMLVideoElement
      const currentTime = videoElement.currentTime ?? 0

      if (prevTimeRef.current > currentTime) {
        onVideoEnded()
      }

      prevTimeRef.current = currentTime
    },
    [onVideoEnded]
  )

  const videoRefHandler = useCallback((node: HTMLVideoElement) => {
    prevTimeRef.current = 0
    videoRef.current = node
  }, [])

  if (!hasActivePreview) {
    return null
  }

  return (
    <StyledVideo
      muted
      autoPlay
      loop
      playsInline
      ref={videoRefHandler}
      src={previewUrl}
      onPlay={onPlay}
      x-webkit-airplay="deny"
      onTimeUpdate={onTimeUpdate}
      isVisible={isVideoPreviewVisible}
      poster={transparentPoster}
    />
  )
}

export default AnimatedPreview
