import { FunctionComponent, ReactNode } from 'react'
import ReactDOM from 'react-dom'

export type PortalProps = {
  parentNode: HTMLElement
  children?: ReactNode
}

const Portal: FunctionComponent<PortalProps> = ({ children, parentNode }) => {
  return parentNode ? ReactDOM.createPortal(children, parentNode) : null
}

export default Portal
