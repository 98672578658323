import { forwardRef, useCallback, useState } from 'react'
import styled from 'styled-components'

import BlickTVPlayer from './JwLibrary'
import { BlickTVInputProps } from './JwLibrary/types'
import { UseVideoPlayerPropsOutput } from '@widgets/Video/types'
import VideoPoster from './VideoPoster'
import VideoSuspendedOverlay from './VideoSuspendedOverlay'

const VideoPlayerComponentWrapper = styled.div`
  width: 100%;
  height: 100%;
`

export type VideoPlayerProps = BlickTVInputProps &
  Pick<
    UseVideoPlayerPropsOutput,
    | 'isBlickTV'
    | 'hasNextPlaylistItem'
    | 'hasPreviousPlaylistItem'
    | 'image'
    | 'fetchedPosterUrl'
    | 'fetchedSourceUrl'
    | 'fetchedMotionThumbnailUrl'
    | 'isMainElement'
    | 'mountPlayer'
    | 'videoDuration'
    | 'adContainerId'
    | 'onVideoPosterClick'
    | 'hasPlaylist'
    | 'geoblocking'
    | 'allowFullscreen'
    | 'shouldShowTrailer'
    | 'isAnimatedPreviewDisabled'
    | 'isVideoSuspended'
    | 'onVideoSuspendedContinue'
    | 'onVideoSuspendedReplay'
  >

const VideoPlayer = forwardRef<HTMLDivElement, VideoPlayerProps>(
  (props, viewportRef) => {
    const [isVideoPosterClicked, setIsVideoPosterClicked] = useState(false)

    const {
      autoplay,
      isBlickTV,
      geoblocking,
      hasNextPlaylistItem,
      hasPreviousPlaylistItem,
      image,
      fetchedPosterUrl,
      fetchedSourceUrl,
      fetchedMotionThumbnailUrl,
      isMainElement,
      mountPlayer,
      adContainerId,
      videoDuration,
      onThumbnailData,
      shouldShowTrailer,
      onVideoPosterClick,
      isAnimatedPreviewDisabled,
      isVideoSuspended,
      onVideoSuspendedContinue,
      onVideoSuspendedReplay,
      ...videoPlayerProps
    } = props

    const adjustedVideoPlayerProps = {
      ...videoPlayerProps,
      isBlickTV,
      hasNextPlaylistItem,
      hasPreviousPlaylistItem,
      showPoster: isBlickTV,
      autoplay,
      isVideoPosterClicked: isVideoPosterClicked,
      shouldShowTrailer,
      geoblocking,
    }

    const shouldShowPoster = !isBlickTV && !isVideoPosterClicked && !autoplay
    const articleUrl =
      typeof window === 'undefined' ? '' : window.location.pathname

    const videoPosterClickHandler = useCallback(() => {
      setIsVideoPosterClicked(true)
      onVideoPosterClick()
    }, [onVideoPosterClick])

    return (
      <VideoPlayerComponentWrapper ref={viewportRef}>
        {adContainerId ? <div id={adContainerId} /> : null}
        {isVideoSuspended ? (
          <VideoSuspendedOverlay
            onContinue={onVideoSuspendedContinue}
            onReplay={onVideoSuspendedReplay}
          />
        ) : null}
        {shouldShowPoster || isVideoSuspended ? (
          <VideoPoster
            image={image}
            geoblocking={geoblocking}
            fetchedSourceUrl={fetchedSourceUrl}
            fetchedPosterUrl={fetchedPosterUrl}
            fetchedMotionThumbnailUrl={fetchedMotionThumbnailUrl}
            onClick={videoPosterClickHandler}
            isMainElement={isMainElement}
            videoId={videoPlayerProps.jwVideoId}
            videoDuration={videoDuration}
            articleUrl={articleUrl}
            hasTrailer={shouldShowTrailer}
            isAnimatedPreviewDisabled={isAnimatedPreviewDisabled}
            showPlayIcon={!isVideoSuspended}
          />
        ) : mountPlayer ? (
          <BlickTVPlayer
            {...adjustedVideoPlayerProps}
            onThumbnailData={onThumbnailData}
          />
        ) : null}
      </VideoPlayerComponentWrapper>
    )
  }
)

VideoPlayer.displayName = 'VideoPlayer'

export default VideoPlayer
