import { OnShowOfferParams } from '@components/Piano/types'
import {
  getTrackingTemplateParams,
  setCustomVariables,
} from '@components/Piano/utils'
import useIsPianoSDKLoaded from '@hooks/useIsPianoSDKLoaded'
import useSubscriptionStatus from '@hooks/useSubscriptionStatus'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import { useQueryClient } from '@tanstack/react-query'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled, { css } from 'styled-components'
import useViewportTracking from '@hooks/useViewportTracking'
import { mobileCSS } from '@measures/responsive'
import config from '@config'

const {
  subscriptions: { paywallClassName },
} = config

const StyledPianoInline = styled.div`
  position: relative;

  &:empty {
    display: none;
  }
`

interface PianoInlineProps {
  title?: string
  subtitle?: string
  bulletpoints?: string
}

const StyledPianoWallWrapper = styled.div<{ isHidden: boolean }>`
  ${({ isHidden }) => css`
    display: ${isHidden ? 'none' : 'block'};
  `}

  ${mobileCSS(css`
    margin-left: -16px;
    margin-right: -16px;
  `)};
`

const PianoInline: FunctionComponent<PianoInlineProps> = ({
  title,
  subtitle,
  bulletpoints,
}) => {
  const isPianoSDKLoaded = useIsPianoSDKLoaded()
  const { subscriptionStatus } = useSubscriptionStatus()
  const [isHidden, setIsHidden] = useState<boolean>(true)
  const queryClient = useQueryClient()
  const pianoWrapperRef = useRef<HTMLDivElement>(null)

  useState(() => {
    queryClient.setQueryData(['cliffhanger-bulletpoints'], {
      title: title || '',
      subtitle: subtitle || '',
      bulletpoints: bulletpoints || '',
    })
  })

  const trackingOnImpression = useCallback<TrackingFnType>(() => {
    const pianoTemplateParamsArray = queryClient.getQueryData<
      OnShowOfferParams[]
    >(['pianoTemplateParams'])

    // As the PianoInline will always be the paywall, we will use the paywall container selector
    // as identification to get the template params
    const pianoTemplateParams = pianoTemplateParamsArray?.find(
      (el) => el.containerSelector === `.${paywallClassName}`
    )

    const pianoTrackingTemplateNameParams = getTrackingTemplateParams(
      pianoTemplateParams?.experienceActionId
    )

    if (pianoTemplateParams?.displayMode === 'inline') {
      return {
        event: 'piano_show',
        piano_offer_id: pianoTemplateParams?.offerId || '',
        piano_template_id: pianoTemplateParams?.templateId || 'MAIN',
        piano_template_variant_id:
          pianoTemplateParams?.templateVariantId || 'MAIN',
        piano_experience_id: pianoTemplateParams?.experienceId || '',
        ...pianoTrackingTemplateNameParams,
      }
    }
  }, [queryClient])

  const handleImpressionTrack = useTracking(trackingOnImpression)

  const paywallRef = useViewportTracking({
    onImpression: handleImpressionTrack,
    track: !isHidden,
  })

  useEffect(() => {
    if (subscriptionStatus === 'notSubscribed') {
      setIsHidden(false)
    }
  }, [subscriptionStatus])

  useEffect(() => {
    if (isPianoSDKLoaded) {
      setCustomVariables({
        title: title || '',
        subtitle: subtitle || '',
        bulletpoints: bulletpoints || '',
      })
    }
  }, [title, subtitle, bulletpoints, isPianoSDKLoaded])

  useEffect(() => {
    queryClient.setQueryData(['cliffhanger-widget-ref'], pianoWrapperRef)
    return () => {
      queryClient.removeQueries({
        queryKey: ['cliffhanger-widget-ref'],
        exact: true,
      })
    }
  }, [queryClient, pianoWrapperRef])

  return (
    // We need a classname to reference this div in the bodyStyle.ts
    <StyledPianoWallWrapper
      ref={pianoWrapperRef}
      className="paywallWrapper"
      isHidden={isHidden}>
      <StyledPianoInline
        ref={paywallRef}
        className={paywallClassName} // We need a classname to tell Piano where to display the content of the conversion wall
      />
    </StyledPianoWallWrapper>
  )
}

const widget = {
  kind: ['widget', 'inline-piano'],
  component: PianoInline,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
