import { useContext } from 'react'
import {
  EnrichedLiveEventTransformedSoccer,
  EnrichedLiveEventTransformedHockey,
  EnrichedLiveEventTransformedSki,
  EnrichedLiveEventTransformedFormula1,
  EnrichedLiveEventTransformedTennis,
} from './useSportsEvent'
import ScoreboardContext from '@contexts/scoreboard'
import { LIVE_EVENT_DATA_SPORT_TYPES } from '@utils/formatters/common'

export type UseScoreboardReturnValue<T> =
  T extends typeof LIVE_EVENT_DATA_SPORT_TYPES.SOCCER
    ? EnrichedLiveEventTransformedSoccer
    : T extends typeof LIVE_EVENT_DATA_SPORT_TYPES.HOCKEY
      ? EnrichedLiveEventTransformedHockey
      : T extends typeof LIVE_EVENT_DATA_SPORT_TYPES.SKI
        ? EnrichedLiveEventTransformedSki
        : T extends typeof LIVE_EVENT_DATA_SPORT_TYPES.FORMULA1
          ? EnrichedLiveEventTransformedFormula1
          : T extends typeof LIVE_EVENT_DATA_SPORT_TYPES.TENNIS
            ? EnrichedLiveEventTransformedTennis
            :
                | EnrichedLiveEventTransformedSoccer
                | EnrichedLiveEventTransformedHockey
                | EnrichedLiveEventTransformedSki
                | EnrichedLiveEventTransformedFormula1
                | EnrichedLiveEventTransformedTennis

function useScoreboard<T>(): UseScoreboardReturnValue<T> {
  const scoreboardContext = useContext(ScoreboardContext)

  if (!scoreboardContext) {
    throw new Error(
      'You should only use the useScoreboard hook inside of the ScoreboardContext!'
    )
  }

  return scoreboardContext as UseScoreboardReturnValue<T>
}

export default useScoreboard
