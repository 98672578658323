import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import translate from '@i18n'
import euromillionsLogo from '@assets/images/lotto/euromillions-logo.png'
import swisslottoLogo from '@assets/images/lotto/swisslotto-logo.png'

interface HeaderProps {
  title: 'Swiss Lotto' | 'EuroMillions'
  drawDate: string
  id: 'swisslotto' | 'euromillions'
}

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Title = styled.div`
  ${({
    theme: {
      typography: {
        headings: {
          medium: { bundledCSS: headingMediumCSS },
        },
      },
    },
  }) => css`
    ${headingMediumCSS};
  `}
`

const Date = styled.div`
  ${({
    theme: {
      typography: {
        bodycopy: {
          small2: { bundledCSS: bodycopySmall2CSS },
        },
      },
    },
  }) => css`
    ${bodycopySmall2CSS};
  `}
`

const StyledLogo = styled.img``

const Header: FunctionComponent<HeaderProps> = ({ title, drawDate, id }) => {
  return (
    <StyledHeader>
      <div>
        <Title>{title}</Title>
        <Date>
          {`${translate('lotto.drawingFrom', {
            drawDate: drawDate,
          })}`}
        </Date>
      </div>
      {id === 'swisslotto' ? (
        <StyledLogo
          width="111"
          height="36"
          src={swisslottoLogo}
          alt="Swisslotto logo"
        />
      ) : (
        <StyledLogo
          width="109"
          height="61"
          src={euromillionsLogo}
          alt="Euromillions logo"
        />
      )}
    </StyledHeader>
  )
}

export default Header
