import React, { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { mobileCSS } from '@measures/responsive'
import usePageMetadata from '@hooks/usePageMetadata'
import { isVideoOnlyArticle } from '@utils/cook'

export const Wrapper = styled.div<{ isVideoOnly: boolean }>`
  ${({
    theme: {
      color: {
        tertiary: { grey300 },
      },
      spacing: { spacing20, spacing12 },
    },
    isVideoOnly,
  }) => {
    return css`
      width: 100%;
      display: flex;
      flex-direction: column;

      ${!isVideoOnly &&
      css`
        border-bottom: 1px solid ${grey300};
        padding-bottom: ${spacing20};
        ${mobileCSS(css`
          padding-bottom: ${spacing12};
        `)}
      `}
    `
  }}
`

const SharingAndTextToSpeechWrapper: FunctionComponent<{
  children?: ReactNode
}> = ({ children }) => {
  const { targetContentType } = usePageMetadata()
  const isVideoOnly = isVideoOnlyArticle(targetContentType)

  return <Wrapper isVideoOnly={isVideoOnly}>{children}</Wrapper>
}

export default SharingAndTextToSpeechWrapper
