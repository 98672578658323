import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import {
  Author,
  AuthorAPIProps,
  MultipleAuthorsType,
} from '@widgets/Author/types'
import ImageWrapper from '@widgets/Author/Common/ImageWrapper'
import useIsInRecipeContent from '@hooks/useIsInRecipeContent'
import { mobileCSS } from '@measures/responsive'
import AuthorsWithoutImages from '@widgets/Author/Common/AuthorsWithoutImages'
import useHasInvertedColors from '@hooks/useHasInvertedColors'

type MultipleAuthorsProps = Pick<
  AuthorAPIProps,
  'contentOriginImage' | 'contentOrigin'
> & {
  children: Author[]
}
interface WrapperProps {
  isInRecipeContent?: boolean
}

const Wrapper = styled.div<WrapperProps>`
  ${({ isInRecipeContent }) => {
    return css`
      display: grid;
      align-items: center;
      grid-template-columns: auto minmax(0, 1fr);
      ${mobileCSS(css`
        grid-template-columns: unset;
        align-items: flex-start;
      `)}
      ${!isInRecipeContent &&
      css`
        margin: 32px 0 32px 0;
      `}
    `
  }}
`

const ImagesWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => css`
    display: flex;
    flex-shrink: 1;
    margin-right: ${spacing8};
    overflow: hidden;
    ${mobileCSS(css`
      margin-bottom: ${spacing8};
    `)}
  `}
`

const StyledImageWrapper = styled(ImageWrapper)<{ hasInvertedColors: boolean }>`
  ${({
    theme: {
      spacing: { spacing4 },
      color: {
        primary: { primary01, primary02 },
      },
    },

    hasInvertedColors,
  }) => css`
    margin-right: ${spacing4};
    border: 3px solid ${hasInvertedColors ? primary01 : primary02};
    margin-left: -18px;
    margin-bottom: -3px; // border width

    &:first-child {
      margin-left: -3px; // border width
    }

    &:last-child {
      margin-right: -3px; // border width
    }
  `}
`

const isValidProps = (authors: any): authors is MultipleAuthorsType[] => {
  return authors.every((author: MultipleAuthorsType) => !!author.image)
}

const MultipleAuthorsWrapper: FunctionComponent<MultipleAuthorsProps> = ({
  children,
  contentOriginImage,
  contentOrigin,
}) => {
  const isInRecipeContent = useIsInRecipeContent()
  const hasInvertedColors = useHasInvertedColors()

  if (!isValidProps(children)) {
    return null
  }

  return (
    <Wrapper isInRecipeContent={isInRecipeContent}>
      <ImagesWrapper>
        {children.map((author) => (
          <StyledImageWrapper
            key={author.name}
            hasInvertedColors={hasInvertedColors}>
            {author.image}
          </StyledImageWrapper>
        ))}
      </ImagesWrapper>
      <AuthorsWithoutImages {...{ contentOriginImage, contentOrigin }}>
        {children}
      </AuthorsWithoutImages>
    </Wrapper>
  )
}

export default MultipleAuthorsWrapper
