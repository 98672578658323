import { FunctionComponent } from 'react'
import ImageWrapper from '../Common/ImageWrapper'
import { SingleAuthorProps } from '../types'
import SingleAuthor from './SingleAuthor'
import ClickableContainer from '../Common/ClickableContainer'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const StyledImageWrapper = styled(ImageWrapper)`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => css`
    margin-right: ${spacing8};
  `}
`

const SingleAuthorWrapper: FunctionComponent<SingleAuthorProps> = ({
  image,
  name,
  link,
  role,
  type,
  contentOriginImage,
  contentOrigin,
}) => (
  <ClickableContainer link={link}>
    <Wrapper>
      {!!image && <StyledImageWrapper>{image}</StyledImageWrapper>}
      <SingleAuthor
        {...{ name, link, role, type, contentOriginImage, contentOrigin }}
      />
    </Wrapper>
  </ClickableContainer>
)

export default SingleAuthorWrapper
