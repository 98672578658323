import { ConfigProps } from '@config/types'

const config = ({ publication }: ConfigProps) =>
  ({
    dataPrivacyUrl: `${
      publication === 'romandie'
        ? '/fr/services/dispositions-de-protection-des-donnees-id18211424.html'
        : '/services/datenschutz-bestimmungen-id17911013.html'
    }#cmpOpener`,
  }) as const satisfies Record<string, unknown>

export default config
