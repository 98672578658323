import throttle from 'lodash.throttle'
import { JWPlayer } from '../types'
import { setElementClass } from '../utils/element'
import {
  getDVRLive,
  getPlayerHTMLElement,
  isDVRStreamType,
  isLiveStream,
} from '../utils/player'
import { MetadataParamExtended } from '../types/jwplayer-extended'

/**
 * Init additional logic for live stream
 * @param jwplayer JWPlayer
 */
export const initLiveStreamLogic = (
  jwplayer: JWPlayer,
  isDaiEnabled: boolean
): void => {
  const setAdAltTextVisibilityOnLiveTv = (visibility: 'visible' | 'hidden') => {
    getPlayerHTMLElement(
      jwplayer,
      '.jw-text.jw-reset-text.jw-text-alt'
    ).style.visibility = visibility
  }

  jwplayer.on('ready', () => {
    if (isDaiEnabled) {
      setAdAltTextVisibilityOnLiveTv('hidden')
    }
  })

  jwplayer.on('firstFrame', () => {
    if (isDaiEnabled) {
      setAdAltTextVisibilityOnLiveTv('visible')
    }
  })

  jwplayer.on('meta', (event: MetadataParamExtended) => {
    if (event.metadataType === 'media') {
      // apply `btv-live` class to player container if video is live (StreamType is DVR or LIVE)
      if (jwplayer && isLiveStream(jwplayer)) {
        const playerContainer = jwplayer.getContainer()

        setElementClass(playerContainer, 'btv-live', true)
      }
    }
  })

  jwplayer.on(
    'time',
    throttle(
      () => {
        // apply additional logic if stream is DVR and position is in live edge
        if (jwplayer && isDVRStreamType(jwplayer)) {
          const dvrLive = getDVRLive(jwplayer)
          const jwKnobElement = jwplayer
            .getContainer()
            .querySelector('.jw-knob')

          if (jwKnobElement) {
            setElementClass(jwKnobElement, 'btv-dvr-live', dvrLive)
          }
        }
      },
      1000,
      { trailing: false }
    )
  )
}
