import styled, { css } from 'styled-components'
import { FunctionComponent } from 'react'
import PrimaryCTAButton from '@components/Buttons/PrimaryCTAButton'
import { desktopCSS } from '@measures/responsive'

interface LoginOverlayProps {
  text: string
  buttonText: string
  onClick: () => void
}

const LoginOverlayContainer = styled.div`
  ${({
    theme: {
      spacing: { spacing24, spacing32, spacing8 },
      color: {
        tertiary: { grey400 },
      },
    },
  }) => {
    return css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-height: 164px;
      padding: ${spacing24};
      box-sizing: border-box;
      border-radius: ${spacing8};
      border: 1px solid ${grey400};
      gap: ${spacing24};
      ${desktopCSS(css`
        padding: ${spacing32};
        min-height: 158px;
      `)}
    `
  }}
`

const StyledText = styled.span`
  ${({
    theme: {
      typography: {
        headings: {
          small: { bundledCSS: headingSmallCSS },
        },
      },
      color: {
        primary: { primary01 },
      },
    },
  }) => {
    return css`
      ${headingSmallCSS};
      color: ${primary01};
      text-align: center;
    `
  }}
`

const LoginOverlay: FunctionComponent<LoginOverlayProps> = ({
  text,
  buttonText,
  onClick,
}) => (
  <LoginOverlayContainer>
    <StyledText>{text}</StyledText>
    <PrimaryCTAButton size="large" onClick={onClick}>
      {buttonText}
    </PrimaryCTAButton>
  </LoginOverlayContainer>
)

export default LoginOverlay
