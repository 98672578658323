import { useCallback, useEffect, useRef, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { ViewportTypeValues } from './types'

const useViewportType = () => {
  const queryClient = useQueryClient()
  const queryCache = queryClient.getQueryCache()
  const unsubscribeFnRef = useRef<() => void>()

  const [viewportType, setViewportType] = useState<ViewportTypeValues>(
    queryClient.getQueryData<ViewportTypeValues>([
      'viewportType',
    ]) as ViewportTypeValues
  )

  const updateType = useCallback(
    (args: any) => {
      if (
        ['invalidate'].includes(args?.action?.type) &&
        args.query.queryKey[0] === 'viewportType'
      ) {
        setViewportType(
          queryClient.getQueryData<ViewportTypeValues>([
            'viewportType',
          ]) as ViewportTypeValues
        )
      }
    },
    [queryClient]
  )

  useEffect(() => {
    setViewportType(
      queryClient.getQueryData<ViewportTypeValues>([
        'viewportType',
      ]) as ViewportTypeValues
    )
    unsubscribeFnRef.current = queryCache.subscribe(updateType)

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [queryCache, queryClient, updateType])

  return viewportType
}

export default useViewportType
