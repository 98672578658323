import { defaultValidation } from '@widgets/Tool/Leserreporter/Form/validation'
import { Data, Validation } from '@widgets/Tool/Leserreporter/types'
import { ChangeEvent, createContext } from 'react'

export interface ValidLeserreporterContextType {
  data: Data
  handleChange: (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) => void
  addFile: (event: ChangeEvent<HTMLInputElement>) => void
  removeFile: (index: number) => void
  validation: Validation
  validate: () => void
  consent: boolean
  handleChangeConsent: (event: ChangeEvent<HTMLInputElement>) => void
}

export type LeserreporterContextType = ValidLeserreporterContextType | undefined

const LeserreporterContext = createContext<LeserreporterContextType>({
  data: {
    uuid: '',
    category: 'news' as const,
    name: '',
    phone: '',
    description: '',
    files: [],
    anonymous: false,
    device: 'web' as const,
  },
  handleChange: () => null,
  addFile: () => null,
  removeFile: () => null,
  validation: defaultValidation,
  validate: () => null,
  consent: false,
  handleChangeConsent: () => null,
})

export default LeserreporterContext
