import { useState, useRef, useCallback, useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import config from '@config'
import { getURLParam } from '@utils/urlParams'

const {
  urlParams: { clientOnlyURLParamsCommonKey },
} = config

type UseURLParam = (
  param: Parameters<typeof getURLParam>[0]
) => ReturnType<typeof getURLParam>

const useURLParam: UseURLParam = (param) => {
  const queryClient = useQueryClient()
  const queryCache = queryClient.getQueryCache()

  const [URLParamValue, setURLParamValue] = useState<string | undefined>(() =>
    getURLParam(param, queryClient)
  )

  const unsubscribeFnRef = useRef<() => void>()

  const updateURLParamValue = useCallback(
    (args: any) => {
      if (
        args.query.queryKey[0] === clientOnlyURLParamsCommonKey &&
        args.query.queryKey[1] === param
      ) {
        if (args?.action?.type === 'invalidate') {
          setURLParamValue(getURLParam(param, queryClient))
        } else if (args?.type === 'removed') {
          setURLParamValue(undefined)
        }
      }
    },
    [param, queryClient]
  )

  useEffect(() => {
    setURLParamValue(getURLParam(param, queryClient))

    unsubscribeFnRef.current = queryCache.subscribe(updateURLParamValue)

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [param, queryCache, queryClient, updateURLParamValue])

  return URLParamValue
}

export default useURLParam
