export type AnimationType = {
  transition: {
    default: string
    fast: string
  }
}

const animation = {
  transition: {
    default: '500ms',
    fast: '250ms',
  },
} as const satisfies AnimationType

export default animation
