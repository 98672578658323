import { useCallback, useState, useEffect } from 'react'

const useGalleryImagePreloading = (slidesLength: number) => {
  const [preloadedImages, setPreloadedImages] = useState<boolean[]>([])

  useEffect(() => {
    setPreloadedImages(new Array(slidesLength).fill(false))
  }, [slidesLength])

  const shouldPreloadImage = useCallback(
    (index: number) => {
      return index === 0 || preloadedImages[index]
    },
    [preloadedImages]
  )

  const onPreloadNext = useCallback(
    (index: number, preloadNumber: number = 2) => {
      setPreloadedImages((oldArray: boolean[]) => {
        const prel = [...oldArray]
        const maxIndex = Math.min(index + preloadNumber, slidesLength)
        const minIndex =
          index > preloadNumber
            ? index - preloadNumber
            : Math.max(slidesLength - preloadNumber, index)
        /**
         * fetch next slides up to the preloadNumber
         */
        for (let i = index; i < maxIndex; i += 1) {
          prel[i] = true
        }
        /**
         * fetch previous slides from the end of the list
         */
        for (let i = minIndex; i < slidesLength; i += 1) {
          prel[i] = true
        }
        return prel
      })
    },
    [slidesLength]
  )

  return { shouldPreloadImage, onPreloadNext }
}

export default useGalleryImagePreloading
