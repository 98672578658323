import Blink from '@components/Blink'
import ControlledCheckbox from '@components/ControlledCheckbox'
import useLeserreporterContext from '@hooks/useLeserreporterContext'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import ErrorMessage from './ErrorMessage'

const Wrapper = styled.div`
  ${({
    theme: {
      color: {
        tertiary: { grey300: grey300color },
      },
      spacing: { spacing28 },
    },
  }) => css`
    border-top: 1px solid ${grey300color};
    padding: ${spacing28} 0;
  `}
`

const StyledLesereporterBlink = styled(Blink)`
  ${({
    theme: {
      color: {
        primary: { primary01: primary01color, blickRed: blickRedColor },
      },
    },
  }) => css`
    color: ${primary01color};

    &:hover {
      color: ${blickRedColor};
    }
  `}
`

const ConsentCheckbox: FunctionComponent = () => {
  const {
    consent: consentValue,
    handleChangeConsent,
    validation: { consent: validation },
  } = useLeserreporterContext()
  const passedValidation = validation === true
  return (
    <Wrapper>
      <ControlledCheckbox
        name="consent"
        checked={consentValue}
        onChange={handleChangeConsent}>
        Ich habe die{' '}
        <StyledLesereporterBlink href="#">
          Leserreporter-AGB gelesen
        </StyledLesereporterBlink>{' '}
        und bin einverstanden
      </ControlledCheckbox>
      {!passedValidation && validation && (
        <ErrorMessage>{validation as string}</ErrorMessage>
      )}
    </Wrapper>
  )
}

export default ConsentCheckbox
