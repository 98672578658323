import { constraints } from '@components/Gallery/Common/SlideImage/constants'
import { ImageRatios } from '@components/ResponsiveImageV2/types'
import { ViewportTypeValues } from '@hooks/useViewport/types'
import { GalleryWidget } from '@widgets/Gallery/types'

interface GetConstraintsProps {
  hasHeightConstraint?: boolean
  hasDesktopSidebar?: boolean
  isInRecipe?: boolean
}

const getConstraints = ({
  hasHeightConstraint = false,
  hasDesktopSidebar = false,
  isInRecipe = false,
}: GetConstraintsProps) => {
  return constraints[
    isInRecipe
      ? 'inRecipe'
      : !hasDesktopSidebar
        ? 'noDesktopSidebar'
        : 'default'
  ][!hasHeightConstraint ? 'noHeightConstraint' : 'default']
}

const getConstraintsForFullScreen = ({
  isSingleImage = false,
}: {
  isSingleImage: boolean
}) => {
  return {
    desktop: {
      width: 994,
      height: isSingleImage ? 663 : 730,
    },
    tablet: {
      width: 993,
      height: isSingleImage ? 662 : 729,
    },
    mobile: {
      width: 767,
      height: 512,
    },
    mobileSmall: {
      width: 374,
      height: 250,
    },
  }
}

const shouldUseHeroImageRatio = (
  imageWidth: GalleryWidget['imageWidth'],
  viewportType: ViewportTypeValues
) => {
  return viewportType === 'desktop'
    ? imageWidth === 'narrow'
    : imageWidth === 'fullwidth' || imageWidth === 'narrow'
}

const getHeroImageRatios = (
  imageWidth: GalleryWidget['imageWidth']
): ImageRatios => {
  return {
    desktop: '9_16',
    tablet: imageWidth === 'fullwidth' ? '1_1' : '9_16',
    mobile: imageWidth === 'fullwidth' ? '1_1' : '9_16',
  }
}

const getHeroImageInvertedAspectRatio = (
  imageWidth: GalleryWidget['imageWidth'],
  viewportType: ViewportTypeValues
) => {
  return viewportType === 'desktop'
    ? 16 / 9
    : viewportType === 'tablet' || viewportType === 'mobile'
      ? imageWidth === 'fullwidth'
        ? 1 / 1
        : 16 / 9
      : undefined
}

export {
  getConstraints,
  getConstraintsForFullScreen,
  shouldUseHeroImageRatio,
  getHeroImageRatios,
  getHeroImageInvertedAspectRatio,
}
