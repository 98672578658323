import { JWPlayer } from '@components/Video/VideoPlayer/JwLibrary/types'
import {
  goToLiveEdge,
  isOverlay,
} from '@components/Video/VideoPlayer/JwLibrary/utils/player'

const getJWPlayer = (widgetId: string): JWPlayer | null => {
  const player = window?.jwplayer?.(widgetId) as JWPlayer
  // TODO: find better way to detect if player is available
  return player?.id ? player : null
}

const getYoutubePlayer = (widgetId: string): YT.Player | undefined =>
  window?.YT?.get(widgetId)

const isPlayerCaptionsEnabled = (jwplayer: JWPlayer): boolean =>
  jwplayer ? jwplayer.getCurrentCaptions() !== 0 : false

const getPlayerControlbar = (jwplayer: JWPlayer): Element | undefined =>
  jwplayer?.getContainer()?.getElementsByClassName?.('jw-controlbar')?.[0]

const setPlayerGeoBlockedError = (jwplayer: JWPlayer, value: boolean) => {
  jwplayer?.trigger('geoBlockedError', { isGeoBlocked: value })
}

const playerSeekToLive = (jwplayer: JWPlayer) => {
  if (jwplayer) {
    goToLiveEdge(jwplayer)
  }
}

const playerSeekTo = (jwplayer: JWPlayer, timeStamp: number) => {
  jwplayer?.seek(timeStamp)
}

const isPlayerInOverlay = (jwplayer: JWPlayer) =>
  jwplayer ? isOverlay(jwplayer) : false

const changePlayerRecommendationsState = (
  jwplayer: JWPlayer,
  state: 'shown' | 'hidden'
) => {
  jwplayer?.trigger('recommendations', { state })
}

const setPlayerRecommendationsAvailable = (
  jwplayer: JWPlayer,
  areRecommendationsAvailable: boolean
) => {
  jwplayer?.trigger('recommendations', {
    state: areRecommendationsAvailable ? 'available' : 'unavailable',
  })
}

export {
  getJWPlayer,
  getYoutubePlayer,
  isPlayerCaptionsEnabled,
  getPlayerControlbar,
  setPlayerGeoBlockedError,
  playerSeekToLive,
  playerSeekTo,
  isPlayerInOverlay,
  changePlayerRecommendationsState,
  setPlayerRecommendationsAvailable,
}
