import { useCallback, useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { createHtmlPortalNode, InPortal, OutPortal } from 'react-reverse-portal'

export type UseFullScreenImagePortal = () => {
  portalNode: ReturnType<typeof createHtmlPortalNode> | null
  enterFullScreen: () => boolean
  exitFullScreen: () => boolean
  InPortal: typeof InPortal
  OutPortal: typeof OutPortal
}

const useFullScreenImagePortal: UseFullScreenImagePortal = () => {
  const queryClient = useQueryClient()
  const [portalNode, setPortalNode] = useState<ReturnType<
    typeof createHtmlPortalNode
  > | null>(null)

  useEffect(() => {
    setPortalNode(createHtmlPortalNode())
  }, [])

  const cleanupPortal = useCallback(() => {
    queryClient.setQueryData(['full-screen-portal-rendered-node-ref'], null)
    queryClient.invalidateQueries({
      queryKey: ['full-screen-portal-rendered-node-ref'],
      exact: true,
    })
  }, [queryClient])

  /**
   * close the fullscreen overlay on unmount
   */
  useEffect(() => {
    return () => {
      cleanupPortal()
    }
  }, [cleanupPortal])

  const enterFullScreen = useCallback(() => {
    const fullScreenImageShown =
      queryClient.getQueryData(['full-screen-image-shown']) ?? false
    if (portalNode && !fullScreenImageShown) {
      queryClient.setQueryData(
        ['full-screen-portal-rendered-node-ref'],
        portalNode
      )
      queryClient.invalidateQueries({
        queryKey: ['full-screen-portal-rendered-node-ref'],
        exact: true,
      })
      return true
    } else {
      return false
    }
  }, [portalNode, queryClient])

  const exitFullScreen = useCallback(() => {
    const fullScreenImageShown =
      queryClient.getQueryData(['full-screen-image-shown']) ?? false
    if (portalNode && fullScreenImageShown) {
      cleanupPortal()
      return true
    } else {
      return false
    }
  }, [portalNode, queryClient, cleanupPortal])

  return { portalNode, enterFullScreen, exitFullScreen, InPortal, OutPortal }
}

export default useFullScreenImagePortal
