import { getPageViewType } from '@components/TrackPageView/utils'
import {
  useState,
  useEffect,
  useCallback,
  FunctionComponent,
  useRef,
} from 'react'
import usePageMetadata from '@hooks/usePageMetadata'
import useTracking, { TrackingFnType } from '@hooks/useTracking'

const TrackStartScrolling: FunctionComponent = () => {
  const pageMetadata = usePageMetadata()
  const articleId = pageMetadata?.id
  const type = getPageViewType(pageMetadata)
  const enableHook = type === 'article'
  const [initialTimestamp, setInitialTimestamp] = useState<number>(0)
  const delay = 300
  const scrollThreshold = 100
  const hasTrackedRef = useRef<boolean>(false)

  const timestampDiff = useCallback(() => {
    return Date.now() - initialTimestamp + delay
  }, [initialTimestamp])

  useEffect(() => {
    if (enableHook && !!articleId) {
      setInitialTimestamp(Date.now())
      hasTrackedRef.current = false
    }
  }, [articleId, enableHook])

  const trackPageScrollingStartReading = useCallback<TrackingFnType>(() => {
    return {
      event: 'scrolling_start_reading',
      eventCategory: 'scrolling',
      eventValue: timestampDiff(),
      eventLabel: articleId,
      eventAction: 'start_reading',
      contextId: articleId,
    }
  }, [articleId, timestampDiff])

  const handleTrackScrolling = useTracking(trackPageScrollingStartReading)

  const handleScroll = useCallback(() => {
    if (
      !hasTrackedRef.current &&
      !!articleId &&
      window.scrollY > scrollThreshold
    ) {
      hasTrackedRef.current = true
      handleTrackScrolling()
      setInitialTimestamp(0)
    }
  }, [articleId, handleTrackScrolling])

  useEffect(() => {
    if (enableHook) {
      const timeout = setTimeout(() => {
        window.addEventListener('scroll', handleScroll, {
          passive: true,
        })
      }, delay)

      return () => {
        clearTimeout(timeout)
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [articleId, handleScroll, enableHook])

  return null
}

export default TrackStartScrolling
