import { SocialButtonType } from '@widgets/SocialShare/types'
import config from '@config'

const {
  socialembeds: {
    share: { utmCampaign, utmMedium, types },
  },
} = config

type GetSocialEmbedConfigType = {
  utmMedium: string
  utmCampaign: string
  utmSource?: string
  iconName: string
}

const getSocialEmbedConfig = (
  type: SocialButtonType
): GetSocialEmbedConfigType => {
  const socialembedsByTypes = types[type] || {}
  return {
    utmMedium,
    utmCampaign,
    ...socialembedsByTypes,
  }
}

const getUrlEncodedUTMParameters = (
  utmMedium: string,
  utmCampaign: string,
  utmSource?: string,
  utmContent?: string
): string =>
  `%3Futm_medium%3D${utmMedium}%26utm_campaign%3D${utmCampaign}${utmSource ? `%26utm_source%3D${utmSource}` : ''}${utmContent ? `%26utm_content%3D${utmContent}` : ''}`

const getUTMParameters = ({
  utmSource,
  fullSuffix,
  utmContent,
}: {
  utmSource: string
  fullSuffix: boolean
  utmContent?: string
}) => {
  const utmSuffix = `utm_medium=${utmMedium}&utm_campaign=${utmCampaign}&utm_source=${utmSource}${utmContent ? `&utm_content=${utmContent}` : ''}`
  return fullSuffix ? `?${utmSuffix}` : `&${utmSuffix}`
}

const copyToClipboard = async (text: string) => {
  if (window.isSecureContext && navigator.clipboard) {
    await navigator.clipboard.writeText(text)
  } else {
    console.error(
      'Not possible to copy, this requires a secure origin — either HTTPS or localhost'
    )
  }
}

export {
  getSocialEmbedConfig,
  getUrlEncodedUTMParameters,
  getUTMParameters,
  copyToClipboard,
}
