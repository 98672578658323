import styled, { css } from 'styled-components'

const ActionsResponding = styled.div`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => css`
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    gap: ${spacing8};
  `}
`

export default ActionsResponding
