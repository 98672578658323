import { useQueryClient } from '@tanstack/react-query'

export interface BlickPlusSkeleton {
  isPlusLive: boolean
  isPlusSharingLive: boolean
  plusSharingLinkGenerationAmount: number
}

type UseBlickPlusSkeleton = () => BlickPlusSkeleton

const useBlickPlusSkeleton: UseBlickPlusSkeleton = () => {
  const queryClient = useQueryClient()

  return queryClient.getQueryData<BlickPlusSkeleton>([
    'blickPlusSkeleton',
  ]) as BlickPlusSkeleton
}

export default useBlickPlusSkeleton
