import { FunctionComponent } from 'react'

import translate from '@i18n'
import { formatDate } from '@utils/date'

import { CommentingItemProps } from './types'
import styled, { css } from 'styled-components'
import ItemContent from './ItemContent'
import ActionReact from './ActionReact'
import AnswerIndicator from './AnswerIndicator'
import ItemSuperUserIndicator from './ItemSuperUserIndicator'
import ItemHeader from './ItemHeader'
import DatePublished from './DatePublished'
import TextContent from './TextContent'
import ActionsResponding from './ActionsResponding'
import useReactions from './useReactions'
import {
  getGenderAdjustedSuperUserTitle,
  getIsAuthorTypeSuperUser,
} from './utils'
import { desktopCSS, mobileCSS } from '@measures/responsive'
import PrimaryCTAButton from '@components/Buttons/PrimaryCTAButton'
import SecondaryCTAButton from '@components/Buttons/SecondaryCTAButton'

const AuthorName = styled.div`
  ${({
    theme: {
      typography: {
        headings: {
          xsmall: { bundledCSS: headingXSmallCSS },
        },
      },
    },
  }) => css`
    ${headingXSmallCSS};
  `}
`

const ItemActions = styled.div`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => css`
    margin-top: ${spacing8};
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    ${desktopCSS(css`
      flex-direction: row;
    `)}
  `}
`

const ActionsReacting = styled.div`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => css`
    ${mobileCSS(css`
      margin-bottom: ${spacing8};
    `)}
    display: flex;
    align-items: center;
  `}
`

const CommentingItem: FunctionComponent<CommentingItemProps> = ({
  type,
  authorType,
  gender,
  allowReplies,
  allowReports,
  isOwnItem,
  authorName,
  datePublished,
  textContent,
  onDelete,
  onReport,
  onReply,
  commentOrAnswer,
}) => {
  const [reactionProps] = useReactions(commentOrAnswer)

  const {
    heartCount,
    boltCount,
    happyCount,
    sadCount,
    criticalCount,
    ownHeart,
    ownBolt,
    ownHappy,
    ownSad,
    ownCritical,
    onReactionHeart,
    onReactionBolt,
    onReactionHappy,
    onReactionSad,
    onReactionCritical,
  } = reactionProps

  const isSuperUser = getIsAuthorTypeSuperUser(authorType)

  return (
    <>
      {type === 'answer' && <AnswerIndicator />}
      <ItemContent type={type}>
        {isSuperUser ? (
          <ItemSuperUserIndicator
            userTitle={getGenderAdjustedSuperUserTitle(authorType, gender)}
          />
        ) : null}
        <ItemHeader>
          <AuthorName>{authorName}</AuthorName>
          <DatePublished>
            {formatDate(datePublished)
              ? (formatDate(datePublished) as any)?.dateOrTime
              : ''}
          </DatePublished>
        </ItemHeader>
        <TextContent>{textContent}</TextContent>
        <ItemActions>
          <ActionsReacting>
            <ActionReact
              name="heart"
              onAction={onReactionHeart}
              hasOwnReaction={ownHeart}>
              {heartCount}
            </ActionReact>
            <ActionReact
              name="bolt"
              onAction={onReactionBolt}
              hasOwnReaction={ownBolt}>
              {boltCount}
            </ActionReact>
            <ActionReact
              name="happy"
              onAction={onReactionHappy}
              hasOwnReaction={ownHappy}>
              {happyCount}
            </ActionReact>
            <ActionReact
              name="sad"
              onAction={onReactionSad}
              hasOwnReaction={ownSad}>
              {sadCount}
            </ActionReact>
            <ActionReact
              name="critical"
              onAction={onReactionCritical}
              hasOwnReaction={ownCritical}>
              {criticalCount}
            </ActionReact>
          </ActionsReacting>
          <ActionsResponding>
            {isOwnItem ? (
              <SecondaryCTAButton
                colorVariant="black"
                onClick={onDelete}
                size="small">
                {translate('commenting.delete')}
              </SecondaryCTAButton>
            ) : (
              !isSuperUser &&
              allowReports && (
                <SecondaryCTAButton
                  colorVariant="black"
                  onClick={onReport}
                  size="small">
                  {translate('commenting.report')}
                </SecondaryCTAButton>
              )
            )}
            {allowReplies && (
              <PrimaryCTAButton
                colorVariant="grey"
                size="small"
                onClick={onReply}>
                {translate('commenting.answer')}
              </PrimaryCTAButton>
            )}
          </ActionsResponding>
        </ItemActions>
      </ItemContent>
    </>
  )
}

export default CommentingItem
